import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography, Grid, Box } from '@mui/material';

import { folder } from 'assets';
import { MIME } from 'constants';
import { withProps } from 'hooks';
import { useUpdateProjectPropertyPlanMutation } from 'store';
import {
  Center,
  Image,
  Ref,
  Dropzone,
  ProgressBox,
  useMessage,
} from 'components';

const layersList = [
  'Property Lines',
  'Obstructions',
  'Property Setbacks',
  'PV Panels',
  'Road',
  'Structure',
  'Roof',
  'Racking',
  'Fire Setbacks',
  'Attachments',
];
const template = 'https://design4pv-fs.dev.angleto.com/templates/site_plan.dwg';

const Text = withProps(Typography, {
  color: 'primary.main',
  fontWeight: 400,
  letterSpacing: 'normal',
});

const EmptyPlan = (props) => {
  const { project_id, loading, templateRef } = props;
  const { control } = useFormContext();

  const m = useMessage();
  const [updatePlan, { isLoading }] = useUpdateProjectPropertyPlanMutation({
    fixedCacheKey: 'preliminary_plan',
  });

  const handleUploadTemplate = useCallback(
    async ([file]) => {
      try {
        await updatePlan({
          project_id,
          body: { file },
        }).unwrap();
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, updatePlan, project_id]
  );

  return (
    <Controller
      name="layers"
      defaultValue={null}
      control={control}
      rules={{ required: true }}
      render={({ field }) => {
        return (
          <ProgressBox
            width={1}
            height={1}
            flexGrow={1}
            progress={isLoading}
            display={field.value || loading ? 'none' : undefined}
          >
            <Center flexGrow={1} width={1} height={1} flexDirection="column">
              <Image width={75} src={folder} />

              <Typography variant="subtitle2" mt={2}>
                Upload Preliminary Plan file
              </Typography>

              <Center mt={5}>
                <Text>Preliminary Plan file should include layers:</Text>
              </Center>

              <Center mt={3} maxWidth={450}>
                <Grid container rowSpacing={0.5}>
                  {layersList.map((layer) => (
                    <Grid item xs={6} key={layer}>
                      <Text ml={8}>— {layer}</Text>
                    </Grid>
                  ))}
                </Grid>
              </Center>

              <Center mt={5}>
                <Text>Layers should be named accordingly</Text>
              </Center>

              <Center>
                <Typography component="div">
                  <span>(</span>
                  <Typography
                    component="span"
                    variant="body2"
                    color="secondary.main"
                  >
                    <Ref absolute to={template}>
                      DWG template download
                    </Ref>
                  </Typography>
                  <span>)</span>
                </Typography>
              </Center>

              <Center mt={4} width={430}>
                <Dropzone
                  fullWidth
                  variant="box"
                  accept={[MIME.PDF]}
                  inputRef={templateRef}
                  onChange={handleUploadTemplate}
                  uploadTrigger={
                    <Box px={5} textAlign="center">
                      <Typography variant="body3" fontWeight={400}>
                        <span>Drag and drop or</span>
                        <span> </span>
                        <Ref color="secondary.main" fontWeight={700}>
                          Click here
                        </Ref>
                        <span> </span>
                        <span>to upload Preliminary Plan file</span>
                      </Typography>
                    </Box>
                  }
                />
              </Center>
            </Center>
          </ProgressBox>
        );
      }}
    />
  );
};

export default EmptyPlan;
