import { lazy, useEffect } from 'react';
import { Stack, Box, Typography } from '@mui/material';

import { useForm } from 'hooks';
import { dev } from 'constants';
import MatchPasswords from 'views/MatchPasswords';
import { Button, Form, ProgressBox, Ref, TextField } from 'components';

const SignUpForm = (props) => {
  const { open, onSubmit, loading, type = 'admin', initialValues } = props;
  const form = useForm();
  const { isValid } = form.formState;

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open, form]);

  return (
    <ProgressBox progress={loading}>
      <Form form={form} onSubmit={onSubmit}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={3}>
            <TextField.Control
              required
              name="first_name"
              label="First Name"
              initialFocus={!!open}
              placeholder="Add your first name"
            />

            <TextField.Control
              required
              name="last_name"
              label="Last Name"
              placeholder="Add your last name"
            />
          </Stack>

          <TextField.Control
            required
            type="email"
            name="email"
            label="Email"
            autoComplete="off"
            disabled={type === 'member'}
            placeholder="Type your email"
            defaultValue={initialValues?.email}
          />

          <MatchPasswords />

          {type === 'admin' && (
            <TextField.Control
              required
              name="organisation"
              label="Organization name"
              placeholder="Type your organization name"
            />
          )}

          <Box pt={1}>
            <Button type="submit" disabled={!isValid}>
              Sign Up
            </Button>
          </Box>

          <Box textAlign="center" pt={2}>
            <Typography variant="body1" component="span">
              <span>By clicking Sign Up I accept </span>
              <Ref color="secondary.main">
                <Typography variant="body2">Terms and Conditions</Typography>
              </Ref>
              <span> and confirm that I have read </span>
              <Ref color="secondary.main">
                <Typography variant="body2">Privacy Policy</Typography>
              </Ref>
            </Typography>
          </Box>
        </Stack>
      </Form>
    </ProgressBox>
  );
};

if (dev) {
  SignUpForm.Demo = lazy(() => import('./SignUpForm.demo'));
}

export default SignUpForm;
