import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Typography, Box, IconButton } from '@mui/material';

import { useGetProjectsQuery, selectors, actions } from 'store';
import { getArray, getProjectProgress, valuesToOptions } from 'utils';
import { SearchField } from 'views';
import { useAuth } from 'hooks';
import { PROJECTS_VIEW } from 'constants';
import { Icon, Select, useHandleQueryError } from 'components';

import ProjectsList from './ProjectsList';

const ALL = 'ALL';
const IN_PROGRESS = 'IN_PROGRESS';
const COMPLETED = 'COMPLETED';

const projectTypes = [ALL, IN_PROGRESS, COMPLETED];
const projectTypesOptions = valuesToOptions(projectTypes);

const { GRID, TABLE } = PROJECTS_VIEW;
const { selectProjectsView } = selectors;
const { setProjectsView } = actions;

const Projects = () => {
  const [type, setType] = useState(projectTypes[0]);
  const [search, setSearch] = useState('');
  const view = useSelector(selectProjectsView);
  const { premium } = useAuth();

  const { data, ...queryState } = useGetProjectsQuery();
  useHandleQueryError(queryState);

  const loading = queryState.isFetching;

  const projects = useMemo(() => {
    return getArray(data?.projects)
      .map((project) => ({
        project,
        progress: getProjectProgress(project, premium),
      }))
      .filter(({ project, progress }) => {
        const matchSearch = project.title
          .trim()
          .toLowerCase()
          .includes(search.trim().toLowerCase());
        const matchType =
          type === ALL ||
          (type === IN_PROGRESS && progress < 100) ||
          (type === COMPLETED && progress === 100);

        return matchSearch && matchType;
      })
      .sort((a, b) => (a.progress <= b.progress ? 1 : -1))
      .map(({ project }) => project);
  }, [data, search, type, premium]);

  return (
    <Box display="flex" justifyContent="center">
      <Box width={1} maxWidth={1168} my={5}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="subtitle1">Projects</Typography>

            <Box width={150}>
              <Select
                displayEmpty={false}
                value={type}
                onValue={setType}
                options={projectTypesOptions}
              />
            </Box>
          </Stack>

          <Box display="flex" justifyContent="flex-end" flexGrow={1} gap={2}>
            <SearchField value={search} onValue={setSearch} />

            <Stack direction="row" alignItems="center">
              <IconButton size="small" onClick={() => setProjectsView(GRID)}>
                <Icon.Grid
                  sx={{
                    fontSize: 32,
                    color: view === GRID ? 'secondary.main' : 'primary.main',
                  }}
                />
              </IconButton>

              <IconButton size="small" onClick={() => setProjectsView(TABLE)}>
                <Icon.Table
                  sx={{
                    fontSize: 32,
                    color: view === TABLE ? 'secondary.main' : 'primary.main',
                  }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>

        <Box mt={3}>
          <ProjectsList view={view} loading={loading} projects={projects} />
        </Box>
      </Box>
    </Box>
  );
};

export default Projects;
