import { useCallback, useEffect, useMemo, useState } from 'react';

import { google_api_key } from 'constants';

const apiUrl = 'https://maps.googleapis.com/maps/api/staticmap';

const objectToURLParams = (params) => {
  return Object.entries(params)
    .map((pair) => pair.join('='))
    .join('&');
};

const useGetLocationImage = (props = {}) => {
  const { location } = props;

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const lat = location?.lat;
  const lng = location?.lng;

  const refetch = useCallback(
    async (data) => {
      const result = {
        data: null,
        error: null,
      };
      setLoading(true);

      try {
        const params = {
          key: google_api_key,
          zoom: data.zoom - 1,
          scale: 2,
          language: 'en',
          size: '500x500',
          maptype: 'satellite',
          center: `${data.location.lat},${data.location.lng}`,
        };
        const url = `${apiUrl}?${objectToURLParams(params)}`;

        const response = await fetch(url, {
          method: 'GET',
        });
        const responseData = await response.blob();
        const image = new File([responseData], 'image');
        result.data = image;
      } catch (ex) {
        console.error(ex);
        result.error = ex;
      }
      setLoading(false);
      setImage(result.data);
      return result;
    },
    [setLoading]
  );

  useEffect(() => {
    if (!lat || !lng) {
      return;
    }
    refetch({ location: { lat, lng } });
  }, [lat, lng, refetch]);

  return useMemo(
    () => ({
      image,
      loading,
      refetch,
    }),
    [loading, image, refetch]
  );
};

export default useGetLocationImage;
