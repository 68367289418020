import { Collapse, Box, Typography, Grid } from '@mui/material';

import {
  LOCATIONS_LIST,
  METER_RATING_LIST,
  METER_ENCLOSURE_LIST,
  METER_TYPES_LIST,
} from 'constants';

import { useWatchField } from 'hooks';
import { Center, Delimiter, SelectField, Switch, TextField } from 'components';

const ProductionMeterFields = () => {
  const pv_meter_enabled = useWatchField('pv_meter_enabled');

  return (
    <Box mt={2}>
      <Center justifyContent="space-between">
        <Typography variant="subtitle2">PV Production Meter</Typography>

        <Switch.Control name="pv_meter_enabled" />
      </Center>

      <Collapse in={pv_meter_enabled}>
        <Box mt={2}>
          <SelectField.Control
            label="Location"
            displayEmpty={false}
            name="pv_meter_data.pv_meter_location"
            options={LOCATIONS_LIST}
          />
        </Box>

        <Grid container my={0} rowSpacing={1} columnSpacing={2}>
          <Grid item xs={6}>
            <SelectField.Control
              label="PV Meter Rating"
              name="pv_meter_data.pv_meter_rating"
              options={METER_RATING_LIST}
            />
          </Grid>

          <Grid item xs={6} />

          <Grid item xs={6}>
            <SelectField.Control
              label="Enclosure Type"
              name="pv_meter_data.pv_meter_exposure_type"
              options={METER_ENCLOSURE_LIST}
            />
          </Grid>

          <Grid item xs={6}>
            <SelectField.Control
              label="Meter Type"
              name="pv_meter_data.pv_meter_meter_type"
              options={METER_TYPES_LIST}
            />
          </Grid>
        </Grid>

        <Box mt={1}>
          <TextField.Control
            name="pv_meter_data.pv_meter_label"
            label="PV Production Meter Label"
          />
        </Box>
      </Collapse>

      <Delimiter mt={2} />
    </Box>
  );
};

export default ProductionMeterFields;
