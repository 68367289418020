import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  useGetCompanyPreferencesQuery,
  useUpdateCompanyPreferencesMutation,
} from 'store';

import { routes } from 'routes';
import { getArray } from 'utils';

import {
  Button,
  Form,
  Icon,
  ProgressBox,
  Ref,
  TinyButton,
  useMessage,
} from 'components';

import ElectricalSystemPreferences from './ElectricalSystemPreferences';
import ElectricalBalanceOfSystemPreferences from './ElectricalBalanceOfSystemPreferences';
import ElectricalWireTablePreferences from './ElectricalWireTablePreferences';
import ElectricalElectricalTablesPreferences from './ElectricalElectricalTablesPreferences';
import ElectricalPlacardsPreferences from './ElectricalPlacardsPreferences';

const volumes = [
  ElectricalSystemPreferences,
  ElectricalBalanceOfSystemPreferences,
  ElectricalWireTablePreferences,
  ElectricalElectricalTablesPreferences,
  ElectricalPlacardsPreferences,
];

const CompanyPreferences = (props) => {
  const { edit } = props;
  const { company_id } = useParams();
  const form = Form.useForm();
  const readOnly = !edit;
  const navigate = useNavigate();

  const m = useMessage();
  const { data, isFetching } = useGetCompanyPreferencesQuery({ company_id });
  const [updatePreferences, { isLoading }] =
    useUpdateCompanyPreferencesMutation();

  const preferences = useMemo(
    () =>
      getArray(data).reduce(
        (res, { key, value }) => ({
          ...res,
          [key]: value,
        }),
        {}
      ),
    [data]
  );

  const loading = isFetching || isLoading;

  useEffect(() => {
    form.reset(preferences);
  }, [preferences, form]);

  const handleSubmit = useCallback(
    async (formData) => {
      const body = Object.entries(formData)
        .map(([key, value]) => ({ key, value }))
        .filter(({ value }) => !!value);

      try {
        await updatePreferences({ company_id, body }).unwrap();
        m.success('Updated!');
        navigate(routes.company_preferences.path({ company_id }));
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [company_id, m, updatePreferences, navigate]
  );

  return (
    <ProgressBox
      mt={3}
      px={4}
      pb={4}
      flexGrow={1}
      display="flex"
      maxHeight={650}
      position="relative"
      progress={loading}
    >
      {readOnly && (
        <TinyButton
          nostyles
          variant="flat"
          component={Ref}
          to={routes.edit_company_preferences.path({ company_id })}
          sx={{
            zIndex: 100,
            right: 50,
            position: 'absolute',
          }}
        >
          <Icon.Pencil />
        </TinyButton>
      )}
      <Form
        ml={2}
        pr={2}
        form={form}
        flexGrow={1}
        display="flex"
        overflow="auto"
        flexDirection="column"
        onSubmit={handleSubmit}
      >
        {volumes.map((Volume, i) => (
          <Volume
            mb={4}
            pb={2}
            key={i}
            readOnly={readOnly}
            sx={{
              borderBottom: (theme) =>
                `1px solid ${theme.palette.border.light}`,
            }}
          />
        ))}

        <Box display="flex" gap={2} minHeight={50}>
          {!readOnly && (
            <>
              <Button
                nostyles
                component={Ref}
                fullWidth={false}
                variant="outlined"
                to={routes.company_preferences.path({ company_id })}
              >
                Cancel
              </Button>

              <Button type="submit" minWidth={200} fullWidth={false}>
                Save
              </Button>
            </>
          )}
        </Box>
      </Form>
    </ProgressBox>
  );
};

export default CompanyPreferences;
