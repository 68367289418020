import { useCallback, useMemo } from 'react';

import {
  useGetWireMaterialListQuery,
  useGetWireConduitTypeListQuery,
  useGetWireTypeListQuery,
  useGetWeatherStationQuery,
} from 'store';

import { getArray, numberValueFormatter } from 'utils';

const def = {};

const useFormatWireTableSegments = (props = def) => {
  const { station_id, segments } = props;

  const { data: station, isFetching: stationLoading } =
    useGetWeatherStationQuery(
      { station_id },
      {
        skip: !station_id,
      }
    );

  const { data: wireType, isFetching: wireTypeLoading } =
    useGetWireTypeListQuery();
  const { data: wireMaterial, isFetching: materialLoading } =
    useGetWireMaterialListQuery();
  const { data: conduitType, isFetching: conduitLoading } =
    useGetWireConduitTypeListQuery();

  const loading =
    stationLoading || wireTypeLoading || materialLoading || conduitLoading;

  const getWireLabel = useCallback(
    (wire, trunk_cable) => {
      if (!wire || !wireType || !wireMaterial) {
        return null;
      }
      const { gauge, type, material } = wire;
      const result = [`${gauge} ${wireType[type]}`, wireMaterial[material]];
      if (trunk_cable?.bom_description) {
        result.push(trunk_cable.bom_description);
      }
      return result.join(', ').toUpperCase();
    },
    [wireMaterial, wireType]
  );

  const getConduitLabel = useCallback(
    (conduit) => {
      if (!conduit || !conduitType) {
        return null;
      }
      const { size, type } = conduit;

      // TODO: constants
      if (conduitType[type] === 'FREE AIR' && size === 0) {
        return conduitType[type];
      }
      return `${size}" ${conduitType[type]}`.toUpperCase();
    },
    [conduitType]
  );

  const getTempCorrFactor = useCallback(
    (f, temp) => {
      if (station) {
        return `${f} (${temp}ºC)`;
      }
      return f;
    },
    [station]
  );

  const formatted = useMemo(() => {
    if (loading) {
      return [];
    }
    return getArray(segments).map((s) => ({
      ...s,
      conductors_count: s.trunk_cable ? 'N/A' : s.conductors_count,
      amp_terminal: `${s.amp_terminal}A`,
      base_amp: `${s.base_amp}A`,
      conductor: getWireLabel(s.conductor, s.trunk_cable),
      conduit: getConduitLabel(s.conduit),
      cont_current: `${numberValueFormatter(s.cont_current)}A`,
      derated_amp: `${numberValueFormatter(s.derated_amp)}A`,
      egc: getWireLabel(s.ground),
      length: `${s.length}FT`,
      max_current: `${numberValueFormatter(s.max_current)}A`,
      neutral: getWireLabel(s.neutral) || 'N/A',
      ocpd: s.ocpd && !s.trunk_cable ? `${s.ocpd}A` : 'N/A',
      temp_corr_factor: getTempCorrFactor(s.temp_corr_factor, s.adjusted_temp),
      voltage_drop_perc: `${numberValueFormatter(s.voltage_drop_perc)}%`,
      voltage_drop: `${numberValueFormatter(s.voltage_drop)}V`,
      voltage: `${s.voltage}V`,
      temp_rating: s.temp_rating ? `${s.temp_rating}ºC` : s.temp_rating,
    }));
  }, [getConduitLabel, getTempCorrFactor, getWireLabel, loading, segments]);

  return useMemo(() => [formatted, loading], [loading, formatted]);
};

export default useFormatWireTableSegments;
