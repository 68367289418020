import { useMemo } from 'react';
import { Navigate, useMatch, Outlet, useParams } from 'react-router-dom';
import { styled, Box, Typography, IconButton, Stack } from '@mui/material';

import { routes } from 'routes';
import { app_bar_logo_width, PROJECT_STEPS_LIST } from 'constants';
import { getProjectStep, getProjectVolume, getStepVolume } from 'utils';
import { useGetProjectDetailsQuery } from 'store';
import { useAuth } from 'hooks';
import { AppBar } from 'views';
import { Icon, ProgressBox, Ref } from 'components';

import ProjectStepsMenu from './ProjectStepsMenu';
import useMatchStep from './useMatchStep.hook';

const Root = styled(Box, {
  label: 'ProjectLayout',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  width: '100vw',
  minWidth: '100vw',
  maxWidth: '100vw',

  height: '100vh',
  minHeight: '100vh',
  maxHeight: '100vh',

  overflow: 'hidden',
  backgroundColor: theme.palette.colors.background_main,
}));

const ProjectLayout = () => {
  const { project_id } = useParams();
  const isRoot = !!useMatch(routes.project_details.path());
  const { premium } = useAuth();

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const latestStep = useMemo(() => {
    return project ? getProjectStep(project, premium) : null;
  }, [project, premium]);

  const latestVolume = useMemo(() => {
    return project ? getProjectVolume(project, premium) : null;
  }, [project, premium]);

  const currentStep = useMatchStep();
  const currentVolume = getStepVolume(currentStep);

  const step = isRoot ? latestStep : currentStep;
  const volume = isRoot ? latestVolume : currentVolume;

  const isCurrentRouteNotAvailable = useMemo(() => {
    if (latestStep && currentStep) {
      const lsIndex = PROJECT_STEPS_LIST.findIndex(({ value }) => {
        return value === latestStep;
      });
      const csIndex = PROJECT_STEPS_LIST.findIndex(({ value }) => {
        return value === currentStep;
      });
      return csIndex > lsIndex;
    }
    return false;
  }, [currentStep, latestStep]);

  if (isRoot && project) {
    if (latestStep) {
      const path = routes[latestStep].path({ project_id });

      if (path) {
        return <Navigate to={path} replace />;
      }
    }
    return <Navigate to="/not_found" replace />;
  }
  if (!currentStep && !currentVolume && latestStep && latestVolume) {
    return <Navigate to={routes[latestStep].path({ project_id })} replace />;
  }
  if (isCurrentRouteNotAvailable && !isFetching) {
    return <Navigate to={routes[latestStep].path({ project_id })} replace />;
  }
  return (
    <Root>
      <AppBar logoTheme="violet">
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            replace
            nostyles
            component={Ref}
            to={routes.projects.path()}
          >
            <Icon.ArrowBack sx={{ color: 'secondary.main' }} />
          </IconButton>

          <ProgressBox progress={isFetching} size="xsmall">
            <Typography variant="subtitle1">
              {project?.title || '. . .'}
            </Typography>
          </ProgressBox>
        </Stack>
      </AppBar>

      <Box
        height={1}
        flexGrow={1}
        display="flex"
        overflow="hidden"
        justifyContent="center"
      >
        <Box
          width={app_bar_logo_width}
          minWidth={app_bar_logo_width}
          bgcolor="violet.main"
          pt={2}
        >
          <ProjectStepsMenu
            step={step}
            volume={volume}
            latestStep={latestStep}
            latestVolume={latestVolume}
            project_id={project_id}
          />
        </Box>

        <ProgressBox
          width={1}
          flexGrow={1}
          size="xlarge"
          overflow="hidden"
          progress={isFetching}
        >
          <Outlet />
        </ProgressBox>
      </Box>
    </Root>
  );
};

export default ProjectLayout;
