import { Typography, Box } from '@mui/material';

import { Ref } from 'components';

const PageNotFound = () => {
  return (
    <Box
      width={1}
      height={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      flexDirection="column"
    >
      <Box component="span" fontSize={64} fontWeight={800}>
        404
      </Box>

      <Box mt={2}>
        <Typography variant="body2" color="secondary.main">
          <Ref to="/">Back to site</Ref>
        </Typography>
      </Box>
    </Box>
  );
};

export default PageNotFound;
