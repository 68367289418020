import { alpha } from '@mui/material';

import { fromRange } from 'utils';
import { shadow } from './colors';

const shadows = [
  () => 'none',
  (v, f = 0.2) => `0 1px 4px 0 ${alpha(v, f)};`,
  (v, f = 0.2) => `0 2px 6px 0 ${alpha(v, f)};`,
  (v, f = 0.1) => `0 4px 8px 0 ${alpha(v, f)};`,
  (v, f = 0.1) => `0 8px 16px 0 ${alpha(v, f)};`,
  (v, f = 0.2) => `0 1px 2px 0 ${alpha(v, f)};`,
];

export const makeShadow = (index, color = shadow, factor) => {
  try {
    const ind = fromRange(index, 0, shadows.length);
    const shadow = shadows[ind](color, factor);
    return shadow;
  } catch (ex) {
    console.warn(ex);
    return shadows[0]();
  }
};
