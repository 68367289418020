export const PROJECTS_VIEW = {
  GRID: 'GRID',
  TABLE: 'TABLE',
};

export const PROJECTS_CACHE_KEY = 'PROJECTS_CACHE_KEY';

const GENERAL_DETAILS = 'GENERAL_DETAILS';
const ELECTRICAL_DETAILS = 'ELECTRICAL_DETAILS';
const STRUCTURAL_DETAILS = 'STRUCTURAL_DETAILS';
const DATASHEETS = 'DATASHEETS';
const PROJECT_PREVIEW = 'PROJECT_PREVIEW';

export const PROJECT_VOLUMES = {
  GENERAL_DETAILS,
  ELECTRICAL_DETAILS,
  STRUCTURAL_DETAILS,
  DATASHEETS,
  PROJECT_PREVIEW,
};
const PROJECT_VOLUMES_NAMES = {
  GENERAL_DETAILS: 'General Details',
  ELECTRICAL_DETAILS: 'Electrical Details',
  STRUCTURAL_DETAILS: 'Structural Details',
  DATASHEETS: 'Datasheets',
  PROJECT_PREVIEW: 'Project Preview',
};
export const PROJECT_VOLUMES_LIST = [
  GENERAL_DETAILS,
  ELECTRICAL_DETAILS,
  STRUCTURAL_DETAILS,
  DATASHEETS,
  PROJECT_PREVIEW,
].map((value) => ({
  value,
  label: PROJECT_VOLUMES_NAMES[value],
}));

export const PROJECT_STEPS = {
  PROPERTY_INFORMATION: 'property_information',
  SITE_DESIGN_CRITERIA: 'site_design_criteria',
  CONTRACTOR_COMPANY: 'contractor_company',
  PRELIMINARY_PLAN: 'preliminary_plan',
  ARRAYS_PARAMETERS: 'arrays_parameters',
  PV_SYSTEM: 'pv_system',
  INTERCONNECTION: 'interconnection',
  BALANCE_OF_SYSTEM: 'balance_of_system',
  WIRE_TABLE: 'wire_table',
  LINE_DIAGRAM: 'line_diagram',
  ELECTRICAL_TABLES: 'electrical_tables',
  SITE_PLAN: 'site_plan',
  ELECTRICAL_PLAN: 'electrical_plan',
  PLACARDS: 'placards',
  STRUCTURES: 'structures',
  ATTACHMENT_PLAN: 'attachment_plan',
  DATASHEETS: 'datasheets',
  PROJECT_PREVIEW: 'project_preview',
};
const PROJECT_STEPS_NAMES = {
  [PROJECT_STEPS.PROPERTY_INFORMATION]: 'Property Information',
  [PROJECT_STEPS.SITE_DESIGN_CRITERIA]: 'Site Design Criteria',
  [PROJECT_STEPS.CONTRACTOR_COMPANY]: 'Contractor Company',
  [PROJECT_STEPS.PRELIMINARY_PLAN]: 'Preliminary Plan',
  [PROJECT_STEPS.ARRAYS_PARAMETERS]: 'Arrays Parameters',
  [PROJECT_STEPS.PV_SYSTEM]: 'PV System',
  [PROJECT_STEPS.INTERCONNECTION]: 'Interconnection',
  [PROJECT_STEPS.BALANCE_OF_SYSTEM]: 'Balance Of System',
  [PROJECT_STEPS.WIRE_TABLE]: 'Wire Table',
  [PROJECT_STEPS.LINE_DIAGRAM]: 'Line Diagram',
  [PROJECT_STEPS.ELECTRICAL_TABLES]: 'Electrical Tables',
  [PROJECT_STEPS.SITE_PLAN]: 'Site Plan',
  [PROJECT_STEPS.ELECTRICAL_PLAN]: 'Electrical Plan',
  [PROJECT_STEPS.PLACARDS]: 'Placards',
  [PROJECT_STEPS.STRUCTURES]: 'Structures',
  [PROJECT_STEPS.ATTACHMENT_PLAN]: 'Attachment Plan',
  [PROJECT_STEPS.DATASHEETS]: 'Datasheets',
  [PROJECT_STEPS.PROJECT_PREVIEW]: 'Project Preview',
};
export const PROJECT_STEPS_LIST = [
  PROJECT_STEPS.PROPERTY_INFORMATION,
  PROJECT_STEPS.SITE_DESIGN_CRITERIA,
  PROJECT_STEPS.CONTRACTOR_COMPANY,
  PROJECT_STEPS.PRELIMINARY_PLAN,
  PROJECT_STEPS.ARRAYS_PARAMETERS,
  PROJECT_STEPS.PV_SYSTEM,
  PROJECT_STEPS.INTERCONNECTION,
  PROJECT_STEPS.BALANCE_OF_SYSTEM,
  PROJECT_STEPS.WIRE_TABLE,
  PROJECT_STEPS.LINE_DIAGRAM,
  PROJECT_STEPS.ELECTRICAL_TABLES,
  PROJECT_STEPS.PLACARDS,
  PROJECT_STEPS.SITE_PLAN,
  PROJECT_STEPS.ELECTRICAL_PLAN,
  PROJECT_STEPS.STRUCTURES,
  PROJECT_STEPS.ATTACHMENT_PLAN,
  PROJECT_STEPS.DATASHEETS,
  PROJECT_STEPS.PROJECT_PREVIEW,
].map((value) => ({
  value,
  label: PROJECT_STEPS_NAMES[value],
}));

export const GENERAL_DETAILS_STEPS_LIST = [
  PROJECT_STEPS.PROPERTY_INFORMATION,
  PROJECT_STEPS.SITE_DESIGN_CRITERIA,
  PROJECT_STEPS.CONTRACTOR_COMPANY,
  PROJECT_STEPS.PRELIMINARY_PLAN,
  PROJECT_STEPS.ARRAYS_PARAMETERS,
].map((value) => ({
  value,
  label: PROJECT_STEPS_NAMES[value],
}));

export const ELECTRICAL_DETAILS_STEPS_LIST = [
  PROJECT_STEPS.PV_SYSTEM,
  PROJECT_STEPS.INTERCONNECTION,
  PROJECT_STEPS.BALANCE_OF_SYSTEM,
  PROJECT_STEPS.WIRE_TABLE,
  PROJECT_STEPS.LINE_DIAGRAM,
  PROJECT_STEPS.ELECTRICAL_TABLES,
  PROJECT_STEPS.PLACARDS,
  PROJECT_STEPS.SITE_PLAN,
  PROJECT_STEPS.ELECTRICAL_PLAN,
].map((value) => ({
  value,
  label: PROJECT_STEPS_NAMES[value],
}));

export const STRUCTURAL_DETAILS_STEPS_LIST = [
  PROJECT_STEPS.STRUCTURES,
  PROJECT_STEPS.ATTACHMENT_PLAN,
].map((value) => ({
  value,
  label: PROJECT_STEPS_NAMES[value],
}));

export const DATASHEETS_STEPS_LIST = [].map((value) => ({
  value,
  label: PROJECT_STEPS_NAMES[value],
}));

export const PROJECT_PREVIEW_STEPS_LIST = [].map((value) => ({
  value,
  label: PROJECT_STEPS_NAMES[value],
}));

export const PROJECT_VOLUMES_STEPS = {
  [`${GENERAL_DETAILS}`]: GENERAL_DETAILS_STEPS_LIST,
  [`${ELECTRICAL_DETAILS}`]: ELECTRICAL_DETAILS_STEPS_LIST,
  [`${STRUCTURAL_DETAILS}`]: STRUCTURAL_DETAILS_STEPS_LIST,
  [`${DATASHEETS}`]: DATASHEETS_STEPS_LIST,
  [`${PROJECT_PREVIEW}`]: PROJECT_PREVIEW_STEPS_LIST,
};

export const PREMIUM_VOLUMES = [STRUCTURAL_DETAILS];

export const PREMIUM_STEPS = [
  PROJECT_STEPS.PRELIMINARY_PLAN,
  PROJECT_STEPS.STRUCTURES,
  PROJECT_STEPS.ATTACHMENT_PLAN,
  PROJECT_STEPS.SITE_PLAN,
  PROJECT_STEPS.ELECTRICAL_PLAN,
];
