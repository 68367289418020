import { forwardRef, lazy, useMemo } from 'react';

import { rules as defaultRules } from 'utils';
import { dev, phone_mask_char, phone_mask } from 'constants';
import MaskField from 'components/MaskField';

const PhoneField = forwardRef((props, ref) => {
  const { ...rest } = props;

  return (
    <MaskField
      ref={ref}
      {...rest}
      mask={phone_mask}
      maskChar={phone_mask_char}
    />
  );
});

PhoneField.Control = forwardRef((props, ref) => {
  const { rules: customRules, ...rest } = props;

  const rules = useMemo(() => {
    return {
      ...customRules,
      validate: {
        ...customRules?.validate,
        phone: defaultRules.phone,
      },
    };
  }, [customRules]);

  return (
    <MaskField.Control
      ref={ref}
      rules={rules}
      ControlComponent={PhoneField}
      {...rest}
    />
  );
});

if (dev) {
  PhoneField.Demo = lazy(() => import('./PhoneField.demo'));
}

export default PhoneField;
