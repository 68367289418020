import { useMemo } from 'react';
import { Typography } from '@mui/material';

import { withProps } from 'hooks';
import { Slip, ProgressBox as _ProgressBox, Center, Image } from 'components';

const ProgressBox = withProps(_ProgressBox, {
  height: 1,
  position: 'relative',
});
const Text = withProps(Typography, {
  lineHeight: 1.5,
  variant: 'body3',
  textAlign: 'center',
  maxWidth: 200,
});
const Title = withProps(Typography, {
  variant: 'body2',
  fontSize: 14,
  mb: 1,
});

const SystemCard = (props) => {
  const {
    title,
    borders,
    active,
    loading,
    children,
    complete,
    image,
    ...rest
  } = props;

  const firstEdit = active && !complete;
  const incomplete = !active && !complete;
  const noImage = !image;

  const message = useMemo(() => {
    if (firstEdit) {
      return 'Press update after you enter all necessary information to see image';
    }
    if (incomplete) {
      return 'Image will appear after necessary information is entered';
    }
    if (noImage) {
      return 'Required configuration is not in the database yet, please reach out our support team to request an update.';
    }
    return null;
  }, [firstEdit, incomplete, noImage]);

  if (image) {
    return <Image height={720} delay={500} src={image.image} />;
  }
  return (
    <Slip
      zIndex={active ? 10 : 1}
      position="relative"
      minWidth={392}
      height={720}
      maxHeight={720}
      borderRadius={active ? 1 : 0}
      elevation={active ? 3 : 0}
      bgcolor={active ? 'common.white' : 'colors.background_main'}
      color={active ? 'primary.main' : 'border.dark'}
      sx={[
        borders && {
          borderRight: (theme) => `1px solid ${theme.palette.border.light}`,
          borderLeft: (theme) => `1px solid ${theme.palette.border.light}`,
        },
      ]}
      {...rest}
    >
      <ProgressBox progress={loading}>
        {!loading && (
          <Center flexDirection="column" pt={35} position="relative">
            {children}

            <Title>{active ? 'No Image yet' : title}</Title>

            <Text>{message}</Text>
          </Center>
        )}
      </ProgressBox>
    </Slip>
  );
};

export default SystemCard;
