import { forwardRef, lazy } from 'react';
import { Typography } from '@mui/material';

import { withProps } from 'hooks';
import { dev } from 'constants';
import Slip from 'components/Slip';

const Root = withProps(Slip, {
  px: 2,
  flexGrow: 1,
  elevation: 0,
  flexBasis: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  bgcolor: 'colors.disabled_input',
});
const Caption = withProps(Typography, {
  variant: 'caption',
  color: 'text.label',
  textAlign: 'center',
});
const Value = withProps(Typography, {
  mt: 1,
  variant: 'subtitle4',
});

const Tile = forwardRef((props, ref) => {
  const { label, value } = props;

  return (
    <Root ref={ref}>
      <Caption>{label}</Caption>

      <Value>{value}</Value>
    </Root>
  );
});

if (dev) {
  Tile.Demo = lazy(() => import('./Tile.demo'));
}

export default Tile;
