import { Grid, Typography, Box } from '@mui/material';

import { GROUNDING_ELECTRODE_CONDUCTOR_OPTIONS_LIST } from 'constants';
import { withProps } from 'hooks';
import { SelectField } from 'components';

const Item = withProps(Grid, {
  item: true,
  xs: 6,
});

const ElectricalSystemPreferences = (props) => {
  const { readOnly, ...rest } = props;
  const disabled = !readOnly;

  return (
    <Box {...rest}>
      <Typography variant="subtitle2">Electrical: System</Typography>

      <Grid container mt={1} rowSpacing={1} columnSpacing={4}>
        <Item>
          <SelectField.Control
            disabled={disabled}
            readOnly={readOnly}
            name="grounding_electrode_conductor"
            label="Grounding Electrode Conductor"
            options={GROUNDING_ELECTRODE_CONDUCTOR_OPTIONS_LIST}
          />
        </Item>
      </Grid>
    </Box>
  );
};

export default ElectricalSystemPreferences;
