import { Box } from '@mui/material';

import { ProjectPreview, SystemCards } from 'views';

const PvSystemPreview = (props) => {
  const { project_id } = props;

  return (
    <ProjectPreview fullHeight={false}>
      <Box overflow="auto" pb={5}>
        <SystemCards current="pv_system" project_id={project_id} />
      </Box>
    </ProjectPreview>
  );
};

export default PvSystemPreview;
