import {
  forwardRef,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, styled } from '@mui/material';

import { dev } from 'constants';
import { useCombineRefs, useResizeObserver } from 'hooks';
import Spinner from 'components/Spinner';

const Root = styled(Box, {
  label: 'ProgressBox',
})(() => ({
  position: 'relative',
}));

const Backdrop = styled(Box, {
  label: 'ProgressBox-backdrop',
  shouldForwardProp: (prop) => !['progress'].includes(prop),
})(({ theme, progress, width, height, type }) => ({
  zIndex: 10,
  position: 'absolute',
  left: 0,
  top: 0,
  width: !progress ? 0 : width,
  height: !progress ? 0 : height,
  opacity: progress ? 1 : 0,
  background:
    type === 'blur' ? undefined : theme.palette.colors.progress_background,
  backdropFilter: type === 'blur' ? 'blur(1px)' : undefined,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AbsoluteSpinner = styled(Spinner)(() => ({
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
}));

const defaultBackdropSize = {
  width: '100%',
  height: '100%',
};

const ProgressBox = forwardRef((props, ref) => {
  const { size, type, children, progress, overflow, ...rest } = props;

  const rootRef = useRef();
  const handleRef = useCombineRefs(ref, rootRef);
  const [backdropSize, setBackdropSize] = useState(defaultBackdropSize);

  const handleSizeChange = useCallback(() => {
    const { current: rootElement } = rootRef;

    if (rootElement) {
      const rect = rootElement.getBoundingClientRect();
      const clientWidth = Math.max(rootElement.scrollWidth, rect.width);
      const clientHeight = Math.max(rootElement.scrollHeight, rect.height);

      setBackdropSize({
        width: clientWidth || defaultBackdropSize.width,
        height: clientHeight || defaultBackdropSize.height,
      });
    }
  }, []);

  useResizeObserver(rootRef, handleSizeChange);

  useEffect(() => {
    const { current: rootElement } = rootRef;

    if (progress && rootElement) {
      const preventKeysEvent = (e) => {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
      };
      const listenFocus = (e) => {
        if (rootElement && rootElement.contains(e.target)) {
          e.preventDefault();
          e.stopPropagation();
          document.activeElement && document.activeElement.blur();
        }
      };
      document.addEventListener('focus', listenFocus, true);
      rootElement.addEventListener('keydown', preventKeysEvent, true);

      return () => {
        document.removeEventListener('focus', listenFocus, true);
        rootElement.removeEventListener('keydown', preventKeysEvent, true);
      };
    }
  }, [progress]);

  return (
    <Root
      ref={handleRef}
      {...rest}
      overflow={progress ? overflow || 'hidden' : overflow || 'initial'}
    >
      <Backdrop type={type} progress={progress} {...backdropSize} />

      {progress && (
        <Box
          zIndex={10}
          width={0}
          height={0}
          top="50%"
          left="50%"
          position="sticky"
        >
          <AbsoluteSpinner size={size} />
        </Box>
      )}

      {children}
    </Root>
  );
});

if (dev) {
  ProgressBox.Demo = lazy(() => import('./ProgressBox.demo'));
}

export default ProgressBox;
