import { useCallback, useEffect } from 'react';
import { Stack, Box, Typography } from '@mui/material';

import { routes } from 'routes';
import { useAuthoriseAccountMutation, actions } from 'store';
import {
  Form,
  TextField,
  PasswordField,
  Button,
  Ref,
  ProgressBox,
  useMessage,
} from 'components';

const { login } = actions;

const SignIn = (props) => {
  const { open } = props;
  const form = Form.useForm();

  const m = useMessage();
  const [authoriseAccount, { isLoading }] = useAuthoriseAccountMutation();

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open, form]);

  const handleSubmit = useCallback(
    async (body) => {
      try {
        const response = await authoriseAccount({ body }).unwrap();
        const { token: access_token } = response;
        login({ access_token });
      } catch (ex) {
        m.responseError(ex, 'Cannot authorize...');
      }
    },
    [authoriseAccount, m]
  );

  return (
    <ProgressBox progress={isLoading}>
      <Form form={form} onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField.Control
            required
            type="email"
            label="Email"
            name="username"
            initialFocus={!!open}
            placeholder="Add your email"
          />

          <PasswordField.Control
            required
            name="password"
            label="Password"
            innerValidation={false}
            placeholder="Type your password"
          />

          <Box pt={1}>
            <Button type="submit">Sign In</Button>
          </Box>

          <Box textAlign="center" pt={2}>
            <Ref color="secondary.main" to={routes.forgot_password.path()}>
              <Typography variant="body2">Forgot your password?</Typography>
            </Ref>
          </Box>
        </Stack>
      </Form>
    </ProgressBox>
  );
};

export default SignIn;
