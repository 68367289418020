import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetProjectDetailsQuery,
  useGetProjectPreviewQuery,
  useUpdateProjectPreviewMetadataMutation,
} from 'store';

import { getArray } from 'utils';
import { useIsLoading, useToggle } from 'hooks';
import { ProjectDetailsLayout } from 'layouts';
import { Form, useMessage } from 'components';

import PreviewProjectForm from './PreviewProjectForm';
import PreviewProjectPreview from './PreviewProjectPreview';

const PreviewProject = () => {
  const { project_id } = useParams();
  const m = useMessage();

  const [initiated, toggleInitiated] = useToggle();
  const [selectedPage, setSelectedPage] = useState(null);

  const queryProject = useGetProjectDetailsQuery({ project_id });
  const { isLoading, ...queryPreview } = useGetProjectPreviewQuery({
    project_id,
  });
  const [update, queryUpdate] = useUpdateProjectPreviewMetadataMutation();

  const loading = useIsLoading(queryProject, isLoading, queryUpdate);

  const form = Form.useForm();
  const project = queryProject.data;
  const preview = queryPreview.data;

  const project_file_url = preview?.project_file_url;
  const isFirstSaving = !project?.preview;

  useEffect(() => {
    if (!loading && !initiated && preview) {
      const projectPreview = getArray(project?.preview).reduce((res, p) => {
        if (res.includes(p)) {
          return res;
        }
        return [...res, p];
      }, []);

      const metadata = Array.isArray(project?.preview)
        ? projectPreview
        : preview?.pages;

      const formData = getArray(metadata).map((page) => {
        if (typeof page === 'string') {
          const relatedPreview = getArray(preview?.pages).find((p) => {
            const parts = p.page_url.split('/');
            const id = parts[parts.length - 1];
            return id === page;
          });
          return {
            ...relatedPreview,
            id: page,
          };
        }
        const parts = page.page_url.split('/');
        const id = parts[parts.length - 1];

        return { ...page, id };
      });
      form.reset({
        metadata: formData,
      });
      toggleInitiated.on();
    }
  }, [form, initiated, loading, preview, project?.preview, toggleInitiated]);

  const handlePageClick = useCallback((id) => {
    setSelectedPage((prev) => {
      return prev === id ? null : id;
    });
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = {
          metadata: formData.metadata.map((item) => {
            return item.id;
          }),
        };
        await update({ project_id, body }).unwrap();
        form.reset(formData);

        if (!isFirstSaving) {
          m.success('Project Preview has been updated!');
        } else {
          m.success('Congratulations! You finished your project!');
        }
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [form, isFirstSaving, m, project_id, update]
  );

  return (
    <ProjectDetailsLayout
      title="Project Preview"
      component={Form}
      onSubmit={handleSubmit}
      body={<PreviewProjectForm />}
      form={{
        ...form,
        loading,
        project_id,
        isFirstSaving,
        selectedPage,
        project_file_url,
        onPageClick: handlePageClick,
      }}
    >
      <PreviewProjectPreview />
    </ProjectDetailsLayout>
  );
};

export default PreviewProject;
