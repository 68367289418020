import { useCallback, useEffect } from 'react';
import { Box, Typography, Collapse, Stack } from '@mui/material';

import { useChangeAccountPasswordMutation } from 'store';
import { useToggle } from 'hooks';
import { MatchPasswords } from 'views';
import {
  Button,
  Form,
  PasswordField,
  ProgressBox,
  Ref,
  useMessage,
} from 'components';

const ChangePassword = () => {
  const [open, toggleOpen] = useToggle();

  const m = useMessage();
  const form = Form.useForm();
  const { isValid } = form.formState;

  const [changeAccountPassword, { isLoading }] =
    useChangeAccountPasswordMutation();

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [form, open]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = { ...formData };
        delete body.password_repeat;
        await changeAccountPassword({ body }).unwrap();
        m.success('Your password has been changed successfully!');
        toggleOpen.off();
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, changeAccountPassword, toggleOpen]
  );

  return (
    <ProgressBox progress={isLoading}>
      <Typography variant="subtitle2">Change Password</Typography>

      <Box mt={3}>
        <Collapse in={!open}>
          <Ref color="secondary.main" onClick={toggleOpen.on}>
            <Typography variant="body2">Change</Typography>
          </Ref>
        </Collapse>

        <Collapse in={open}>
          <Form form={form} onSubmit={handleSubmit} maxWidth={332}>
            <Stack spacing={2}>
              <PasswordField.Control
                required
                name="password"
                autoComplete="new-password"
                initialFocus={!!open}
                label="Current Password"
                placeholder="Your current password"
              />

              <MatchPasswords name="new_password" label="New Password" />

              <Box display="flex" gap={2}>
                <Button
                  minWidth={100}
                  fullWidth={false}
                  variant="outlined"
                  onClick={toggleOpen.off}
                >
                  Cancel
                </Button>

                <Button minWidth={100} type="submit" disabled={!isValid}>
                  Confirm
                </Button>
              </Box>
            </Stack>
          </Form>
        </Collapse>
      </Box>
    </ProgressBox>
  );
};

export default ChangePassword;
