import { forwardRef, lazy } from 'react';
import {
  FormControl as MuiFormControl,
  formControlClasses as classes,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { dev } from 'constants';
import FormLabel, { formLabelClasses as flClasses } from 'components/FormLabel';

const Root = styled(MuiFormControl)(({ theme }) => ({
  [`&.${classes.root}`]: {
    '&:hover': {
      [`${FormLabel}`]: {
        [`&:not(.${flClasses.focused})`]: {
          [`&:not(.${flClasses.error})`]: {
            color: theme.palette.primary.light,
          },
          [`&.${flClasses.error}`]: {
            color: theme.palette.error.main,
          },
        },
      },
    },
  },
}));

const FormControl = forwardRef((props, ref) => {
  const { ...rest } = props;

  return <Root ref={ref} {...rest} />;
});

if (dev) {
  FormControl.Demo = lazy(() => import('./FormControl.demo'));
}

export default FormControl;
