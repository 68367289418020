import { createSelector, createSlice } from '@reduxjs/toolkit';

import { PROJECTS_VIEW } from 'constants';

const { GRID } = PROJECTS_VIEW;

const name = 'projects';

const initialState = {
  previewCollapsed: false,
  view: localStorage.getItem('projects.view') || GRID,
};

// SELECTORS
const selectRoot = (store) => store[name];

const selectProjectsView = createSelector(selectRoot, (state) => state.view);

const selectPreviewCollapsed = createSelector(
  selectRoot,
  (state) => state.previewCollapsed
);

// SLICE
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setProjectsView: (state, { payload: view }) => {
      localStorage.setItem('projects.view', view);
      state.view = view;
    },
    toggleCollapse: (state) => {
      state.previewCollapsed = !state.previewCollapsed;
    },
  },
});

slice.selectors = {
  selectProjectsView,
  selectPreviewCollapsed,
};

export default slice;
