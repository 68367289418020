import { useMemo } from 'react';

import { getArray } from 'utils';
import { useGetMainServicePanelListQuery } from 'store';
import { BREAKER_RATING_LIST, BUS_RATING_LIST } from 'constants';
import { useWatchField } from 'hooks';
import { Center, SelectField } from 'components';

const MainServiceCurrentRatingFields = () => {
  const msp_panel_type = useWatchField('msp_panel_type');
  const msp_panel_id = useWatchField('main_service_panel_id');
  const isVendor = msp_panel_type === 'vendor';

  const { data: mspList } = useGetMainServicePanelListQuery();

  const mspPanel = useMemo(() => {
    return getArray(mspList).find((msp) => msp.id === msp_panel_id);
  }, [mspList, msp_panel_id]);

  const breakerOptions = useMemo(() => {
    return BREAKER_RATING_LIST.filter((cur) => {
      return msp_panel_type === 'generic' || cur.value >= mspPanel?.buss_rating;
    });
  }, [mspPanel, msp_panel_type]);

  return (
    <Center mt={2} justifyContent="space-between" gap={2}>
      <SelectField.Control
        label="MSP Bus"
        displayEmpty={isVendor}
        options={BUS_RATING_LIST}
        disabled={isVendor}
        name="main_service_panel_data.bus_rating"
      />
      <SelectField.Control
        name="main_service_panel_data.breaker_rating"
        label="MSP Main Breaker"
        options={breakerOptions}
        disabled={isVendor && !msp_panel_id}
      />
    </Center>
  );
};

export default MainServiceCurrentRatingFields;
