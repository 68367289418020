import { forwardRef, lazy } from 'react';
import { Typography } from '@mui/material';

import { dev } from 'constants';
import Switch from 'components/Switch';
import Center from 'components/Center';

const SwitchField = forwardRef((props, ref) => {
  const { label, disabled, ...rest } = props;

  return (
    <Center component="label" ref={ref} gap={2} justifyContent="flex-start">
      <Switch {...rest} disabled={disabled} />

      {label && (
        <Typography
          variant="body1"
          fontWeight={400}
          color={disabled ? 'border.main' : undefined}
        >
          {label}
        </Typography>
      )}
    </Center>
  );
});

SwitchField.Control = forwardRef((props, ref) => {
  const { ControlComponent = SwitchField, ...rest } = props;

  return (
    <Switch.Control ref={ref} ControlComponent={ControlComponent} {...rest} />
  );
});

if (dev) {
  SwitchField.Demo = lazy(() => import('./SwitchField.demo'));
}

export default SwitchField;
