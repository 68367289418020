import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Collapse, Box } from '@mui/material';

import { useGetMainServicePanelListQuery } from 'store';
import { getArray } from 'utils';
import { useWatchField } from 'hooks';
import { Autocomplete } from 'components';

const MainServicePanelField = (props) => {
  const { visible, ...rest } = props;
  const msp_panel_type = useWatchField('msp_panel_type');
  const isVendor = msp_panel_type === 'vendor';

  const { setValue } = useFormContext();
  const { data: mspList, isFetching } = useGetMainServicePanelListQuery();

  const options = useMemo(() => {
    return getArray(mspList).map((msp) => ({
      value: msp.id,
      label: `${msp.vendor.title} - ${msp.title}`,
    }));
  }, [mspList]);

  const handlePanelChange = useCallback(
    (e, v) => {
      const panel = getArray(mspList).find((p) => p.id === v);
      setValue('main_service_panel_data.bus_rating', panel?.buss_rating || '');
      setValue('main_service_panel_data.breaker_rating', '');
    },
    [mspList, setValue]
  );

  return (
    <Collapse in={isVendor}>
      <Box mt={2}>
        <Autocomplete.Control
          {...rest}
          required
          valueAsId
          options={options}
          loading={isFetching}
          onChange={handlePanelChange}
          rules={{ disabled: !isVendor }}
        />
      </Box>
    </Collapse>
  );
};

export default MainServicePanelField;
