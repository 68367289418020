import { useMemo } from 'react';

import { getArray } from 'utils';
import { useGetProjectWiresTableQuery, useGetProjectDetailsQuery } from 'store';
import useFormatWireTableSegments from 'hooks/useFormatWireTableSegments.hook';

const def = {};

const columns = [
  {
    field: 'id',
    name: 'Id',
  },
  {
    field: 'typical',
    name: 'Typical',
  },
  {
    field: 'conductor',
    name: 'Conductor',
  },
  {
    field: 'conduit',
    name: 'Conduit',
  },
  {
    field: 'conductors_count',
    name: ['Current-carrying', 'Conductors in conduit'],
  },
  {
    field: 'ocpd',
    name: 'OCPD',
  },
  {
    field: 'neutral',
    name: 'Neutral',
  },
  {
    field: 'egc',
    name: 'EGC',
  },
  {
    field: 'temp_corr_factor',
    name: ['Temp. Corr.', 'Factor'],
  },
  {
    field: 'conduit_fill_factor',
    name: ['Conduit Fill', 'Factor'],
  },
  {
    field: 'cont_current',
    name: ['Cont', 'Current'],
  },
  {
    field: 'max_current',
    name: ['Max Current', '(125%)'],
  },
  {
    field: 'base_amp',
    name: ['Base', 'Amp'],
  },
  {
    field: 'derated_amp',
    name: ['Derated', 'Amp'],
  },
  {
    field: 'temp_rating',
    name: ['Term. Temp.', 'Rating'],
  },
  {
    field: 'amp_terminal',
    name: ['Amp', 'Terminal'],
  },
  {
    field: 'voltage',
    name: 'Voltage',
  },
  {
    field: 'voltage_drop',
    name: ['Voltage', 'Drop, V'],
  },
  {
    field: 'voltage_drop_perc',
    name: ['Voltage', 'Drop, %'],
  },
  {
    field: 'length',
    name: 'Length',
  },
];
const voltageDropViewFilters = {
  none: ['voltage_drop_perc', 'voltage_drop', 'voltage', 'length'],
  basic: ['voltage_drop', 'voltage', 'length'],
  advanced: [],
};
const neutralFilters = {
  conductor: ['neutral'],
  separate: [],
};

const useGetProjectWireTable = (props = def) => {
  const { project_id } = props;

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const wireTableQuery = useMemo(
    () => ({
      project_id,
      params: {
        gec_strategy: project?.wires_table?.gec_strategy,
        neutral_strategy: project?.wires_table?.neutral_strategy,
        temperature_rating: project?.wires_table?.temperature_rating,
      },
    }),
    [project, project_id]
  );

  const { data: wireTable, isFetching: wireTableLoading } =
    useGetProjectWiresTableQuery(wireTableQuery, {
      skip: !project,
    });

  const segments = useMemo(
    () =>
      getArray(wireTable?.segments).map((s, i) => ({
        id: i + 1,
        ...s,
      })),
    [wireTable]
  );

  const station_id = project?.meteo_station?.id;

  const [formatted, formatLoading] = useFormatWireTableSegments({
    station_id,
    segments,
  });

  const loading = isFetching || formatLoading || wireTableLoading;

  const voltage_drop_view = project?.wires_table?.voltage_drop_view || 'none';
  const neutral_view = project?.wires_table?.neutral_view;

  const cols = useMemo(() => {
    const result = columns
      .filter(
        (col) =>
          !voltage_drop_view ||
          !voltageDropViewFilters[voltage_drop_view].includes(col.field)
      )
      .filter(
        (col) =>
          !neutral_view || !neutralFilters[neutral_view].includes(col.field)
      );

    return result;
  }, [neutral_view, voltage_drop_view]);

  const rows = useMemo(() => {
    return [
      cols.map((col) => ({
        content: col.name,
      })),
      ...formatted.map((row) => {
        return cols.map((col) => ({
          content: row[col.field],
        }));
      }),
    ];
  }, [formatted, cols]);

  return useMemo(() => [rows, loading], [loading, rows]);
};

export default useGetProjectWireTable;
