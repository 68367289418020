import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { serializeParams, toFormData } from 'utils';
import { api as baseUrl } from 'constants';

const LIST = 'LIST';

const tagTypes = {
  ME: 'ME',
  MEMBERS: 'MEMBERS',
  INVITES: 'INVITES',
  COMPANIES: 'COMPANIES',
  PREFERENCES: 'PREFERENCES',
  PROJECTS: 'PROJECTS',
  ASHRAE_TEMPS_SEARCH: 'ASHRAE_TEMPS_SEARCH',
  ASHRAE_TEMPS: 'ASHRAE_TEMPS',
  AHJ_SEARCH: 'AHJ_SEARCH',
  AHJ: 'AHJ',
  COUNTIES: 'COUNTIES',
  COUNTIES_BY_AHJ: 'COUNTIES_BY_AHJ',
  COUNTIES_BY_GROUP: 'COUNTIES_BY_GROUP',
  COUNTY_REQUIREMENT_BY_GROUP: 'COUNTY_REQUIREMENT_BY_GROUP',
  UTILITIES_SEARCH: 'UTILITIES_SEARCH',
  UTILITIES_BY_GROUP: 'UTILITIES_BY_GROUP',
  UTILITY_REQUIREMENT_BY_GROUP: 'UTILITY_REQUIREMENT_BY_GROUP',
  CONSTRUCTION_TYPE: 'CONSTRUCTION_TYPE',
  TEMPLATE_SYSTEM: 'TEMPLATE_SYSTEM',
  TEMPLATE_BOS: 'TEMPLATE_BOS',
  TEMPLATE_INTERCONNECTION: 'TEMPLATE_INTERCONNECTION',
  TEMPLATE_PLACARDS: 'TEMPLATE_PLACARDS',
  PANELS_SEARCH: 'PANELS_SEARCH',
  WTE: 'WTE',
  INVERTERS_SEARCH: 'INVERTERS_SEARCH',
  RSD: 'RSD',
  DC_OPTIMIZERS_SEARCH: 'DC_OPTIMIZERS_SEARCH',
  INVERTERS: 'INVERTERS',
  MSP: 'MSP',
  AC_SUBPANEL: 'AC_SUBPANEL',
  AC_DISCONNECTS_SEARCH: 'AC_DISCONNECTS_SEARCH',
  AC_COMBINER: 'AC_COMBINER',
  WIRES_TABLE: 'WIRES_TABLE',
  WIRE_TYPE: 'WIRE_TYPE',
  WIRE_MATERIAL: 'WIRE_MATERIAL',
  CONDUIT_TYPE: 'CONDUIT_TYPE',
  WIRE_GAUGE: 'WIRE_GAUGE',
  SEGMENTS: 'SEGMENTS',
  ELECTRICAL_SUMMARY: 'ELECTRICAL_SUMMARY',
  BILL_OF_MATERIAL: 'BILL_OF_MATERIAL',
  DATASHEETS: 'DATASHEETS', // project_id
  FINAL_PDF: 'FINAL_PDF', // project_id
  PREVIEW: 'PREVIEW', // project_id
};

const withParams = (url, params = {}) => {
  const paramsString = Object.entries(params)
    .reduce(
      (res, [key, value]) => [...res, value ? [key, value].join('=') : null],
      []
    )
    .filter(Boolean)
    .join('&');

  if (!paramsString) {
    return url;
  }
  return `${url}?${paramsString}`;
};

const secure = (query) => ({
  ...query,
  headers: {
    ...query?.headers,
    authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

const signatureFromObject = (data) => {
  const params = serializeParams(data);
  return params.toString();
};

const tagCreator = (type) => (id) => {
  if (id && typeof id === 'object') {
    return {
      type,
      id: signatureFromObject(id),
    };
  }
  if (!id) {
    return { type };
  }
  return { type, id };
};

export const tags = Object.entries(tagTypes).reduce(
  (res, [name, value]) => ({
    ...res,
    [name]: tagCreator(value),
  }),
  {}
);

const slice = createApi({
  tagTypes: [...Object.values(tagTypes)],
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    // ACCOUNT
    ...{
      // [POST] /accounts
      createAccounts: build.mutation({
        query: ({ body }) => ({
          method: 'POST',
          url: '/accounts',
          body,
        }),
        invalidatesTags: [tags.ME()],
      }),

      // [GET] /accounts/invite/{hash_str}
      getInviteInformation: build.query({
        query: ({ hash_str }) => ({
          method: 'GET',
          url: `/accounts/invite/${hash_str}`,
        }),
      }),

      // [POST] /accounts/invite
      createAccountViaInvite: build.mutation({
        query: ({ body }) => ({
          method: 'POST',
          url: '/accounts/invite',
          body,
        }),
      }),

      // [POST] /accounts/auth
      authoriseAccount: build.mutation({
        query: ({ body }) => ({
          method: 'POST',
          url: '/accounts/auth',
          body: toFormData(body),
        }),
        invalidatesTags: [tags.ME()],
      }),

      // [POST] /accounts/supervision_auth
      superviseAccount: build.mutation({
        query: ({ body }) => ({
          method: 'POST',
          url: '/accounts/supervision_auth',
          body,
        }),
      }),

      // [POST] /accounts/verify-email
      verifyEmail: build.mutation({
        query: ({ body }) => ({
          method: 'POST',
          url: '/accounts/verify-email',
          body,
        }),
        invalidatesTags: [tags.ME()],
      }),

      // [POST] /accounts/reset-password
      resetPasswordReqeust: build.mutation({
        query: ({ body }) => ({
          method: 'POST',
          url: '/accounts/reset-password',
          body,
        }),
        invalidatesTags: [tags.ME()],
      }),

      // [POST] /accounts/change-password
      changePassword: build.mutation({
        query: ({ body }) => ({
          method: 'POST',
          url: '/accounts/change-password',
          body,
        }),
        invalidatesTags: [tags.ME()],
      }),
    },

    // PROFILE
    ...{
      // [GET] /me
      getProfile: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/me',
          }),
        providesTags: [tags.ME()],
      }),

      // [PUT] /me
      updateProfile: build.mutation({
        query: ({ body }) =>
          secure({
            method: 'PUT',
            url: '/me',
            body,
          }),
        invalidatesTags: (result, error, { skipInvalidation }) =>
          skipInvalidation ? [] : [tags.ME()],
      }),

      // [GET] /me/organisation/members
      getOrganisationMembers: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/me/organisation/members',
          }),
        providesTags: [tags.MEMBERS(LIST)],
      }),

      // [POST] /me/organisation/members
      inviteOrganisationMember: build.mutation({
        query: ({ body }) =>
          secure({
            method: 'POST',
            url: '/me/organisation/members',
            body,
          }),
        invalidatesTags: [tags.MEMBERS(LIST), tags.INVITES(LIST)],
      }),

      // [GET] /me/organisation/companies
      getContractorCompanies: build.query({
        query: ({ params = {} } = {}) =>
          secure({
            method: 'GET',
            url: withParams('/me/organisation/companies', params),
          }),
        providesTags: [tags.COMPANIES(LIST)],
      }),

      // [POST] /me/organisation/companies
      createContractorCompany: build.mutation({
        query: ({ body }) =>
          secure({
            method: 'POST',
            url: '/me/organisation/companies',
            body,
          }),
        invalidatesTags: [tags.COMPANIES(LIST)],
      }),

      // [POST] /me/organisation/companies/{company_id}/notes
      addCompanyNote: build.mutation({
        query: ({ company_id, body }) =>
          secure({
            method: 'POST',
            url: `/me/organisation/companies/${company_id}/notes`,
            body,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [PUT] /me/organisation/notes/{note_id}
      updateCompanyNote: build.mutation({
        query: ({ company_id, note_id, body }) =>
          secure({
            method: 'PUT',
            url: `/me/organisation/notes/${note_id}`,
            body,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [DELETE] /me/organisation/notes/{note_id}
      deleteCompanyNote: build.mutation({
        query: ({ note_id }) =>
          secure({
            method: 'DELETE',
            url: `/me/organisation/notes/${note_id}`,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [PATCH] /me/organisation/notes/{note_id}/visibility
      toggleNoteVisibility: build.mutation({
        query: ({ note_id }) =>
          secure({
            method: 'PATCH',
            url: `/me/organisation/notes/${note_id}/visibility`,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [POST] /me/organisation/companies/{company_id}/requirements
      addCompanyRequirement: build.mutation({
        query: ({ company_id, body }) =>
          secure({
            method: 'POST',
            url: `/me/organisation/companies/${company_id}/requirements`,
            body,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [PUT] /me/organisation/requirements/{requirement_id}
      updateCompanyRequirement: build.mutation({
        query: ({ company_id, requirement_id, body }) =>
          secure({
            method: 'PUT',
            url: `/me/organisation/requirements/${requirement_id}`,
            body,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [DELETE] /me/organisation/requirements/{requirement_id}
      deleteCompanyRequirement: build.mutation({
        query: ({ requirement_id }) =>
          secure({
            method: 'DELETE',
            url: `/me/organisation/requirements/${requirement_id}`,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [PATCH] /me/organisation/requirements/{requirement_id}/visibility
      toggleRequirementVisibility: build.mutation({
        query: ({ requirement_id }) =>
          secure({
            method: 'PATCH',
            url: `/me/organisation/requirements/${requirement_id}/visibility`,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [GET] /me/organisation/companies/{company_id}
      getContractorCompanyDetails: build.query({
        query: ({ company_id }) =>
          secure({
            method: 'GET',
            url: `/me/organisation/companies/${company_id}`,
          }),
        providesTags: (result, error, { company_id }) => [
          tags.COMPANIES(company_id),
        ],
      }),

      // [PUT] /me/organisation/companies/{company_id}
      updateCompanyDetails: build.mutation({
        query: ({ company_id, body }) =>
          secure({
            method: 'PUT',
            url: `/me/organisation/companies/${company_id}`,
            body,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(LIST),
          tags.COMPANIES(company_id),
        ],
      }),

      // [DELETE] /me/organisation/companies/{company_id}
      removeContractorCompany: build.mutation({
        query: ({ company_id }) =>
          secure({
            method: 'DELETE',
            url: `/me/organisation/companies/${company_id}`,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.COMPANIES(LIST),
          tags.COMPANIES(company_id),
        ],
      }),

      // [PATCH] /me/password
      changeAccountPassword: build.mutation({
        query: ({ body }) =>
          secure({
            method: 'PATCH',
            url: '/me/password',
            body,
          }),
      }),

      // [DELETE] /me/image
      deleteUserImage: build.mutation({
        query: () =>
          secure({
            method: 'DELETE',
            url: '/me/image',
          }),
        invalidatesTags: (result, error, { skipInvalidation }) =>
          skipInvalidation ? [] : [tags.ME()],
      }),

      // [PATCH] /me/image
      uploadUserImage: build.mutation({
        query: ({ skipInvalidation, body }) =>
          secure({
            method: 'PATCH',
            url: '/me/image',
            body: toFormData(body),
          }),
        invalidatesTags: (result, error, { skipInvalidation }) =>
          skipInvalidation ? [] : [tags.ME()],
      }),

      // [GET] /me/organisation/companies/{company_id}/preferences
      getCompanyPreferences: build.query({
        query: ({ company_id }) =>
          secure({
            method: 'GET',
            url: `/me/organisation/companies/${company_id}/preferences`,
          }),
        providesTags: (result, error, { company_id }) => [
          tags.PREFERENCES(company_id),
        ],
      }),

      // [PUT] /me/organisation/companies/{company_id}/preferences
      updateCompanyPreferences: build.mutation({
        query: ({ company_id, body }) =>
          secure({
            method: 'PUT',
            url: `/me/organisation/companies/${company_id}/preferences`,
            body,
          }),
        invalidatesTags: (result, error, { company_id }) => [
          tags.PREFERENCES(company_id),
        ],
      }),

      // [POST] /me/temporary_storage
      uploadToTemporaryStorage: build.mutation({
        query: ({ body }) =>
          secure({
            method: 'POST',
            url: '/me/temporary_storage',
            body: toFormData(body),
          }),
      }),

      // [DELETE] /me/organisation/companies/{company_id}/image
      removeCompanyImage: build.mutation({
        query: ({ company_id }) =>
          secure({
            method: 'DELETE',
            url: `/me/organisation/companies/${company_id}/image`,
          }),
        invalidatesTags: (result, error, { skipInvalidation, company_id }) =>
          [
            !skipInvalidation && tags.COMPANIES(company_id),
            !skipInvalidation && tags.COMPANIES(LIST),
          ].filter(Boolean),
      }),

      // [PATCH] /me/organisation/companies/{company_id}/image
      updateCompanyProfileImage: build.mutation({
        query: ({ company_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/me/organisation/companies/${company_id}/image`,
            body: toFormData(body),
          }),
        invalidatesTags: (result, error, { company_id, skipInvalidation }) => [
          !skipInvalidation && tags.COMPANIES(company_id),
          !skipInvalidation && tags.COMPANIES(LIST),
        ],
      }),

      // [PATCH] /me/organisation/members/{account_id}/role
      changeAccountRole: build.mutation({
        query: ({ account_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/me/organisation/members/${account_id}/role`,
            body,
          }),
        invalidatesTags: (result, error, { account_id }) => [
          tags.MEMBERS(LIST),
          tags.MEMBERS(account_id),
        ],
      }),

      // [DELETE] /me/organisation/members/{account_id}
      removeOrganisationMember: build.mutation({
        query: ({ account_id }) =>
          secure({
            method: 'DELETE',
            url: `/me/organisation/members/${account_id}`,
          }),
        invalidatesTags: (result, error, { account_id }) => [
          tags.MEMBERS(LIST),
          tags.MEMBERS(account_id),
        ],
      }),

      // [GET] /me/organisation/invites
      getOrganistionInvites: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/me/organisation/invites',
          }),
        providesTags: [tags.INVITES(LIST)],
      }),

      // [DELETE] /me/organisation/invites/{invite_id}
      deleteOrganisationInvite: build.mutation({
        query: ({ invite_id }) =>
          secure({
            method: 'DELETE',
            url: `/me/organisation/invites/${invite_id}`,
          }),
        invalidatesTags: [tags.INVITES(LIST)],
      }),
    },

    // ELECTRICAL
    ...{
      // [GET] /inverters
      getInverters: build.query({
        query: ({ params }) =>
          secure({
            method: 'GET',
            url: withParams('/inverters', params),
          }),
        providesTags: (result, error, { ...params }) => [
          tags.INVERTERS_SEARCH(params),
        ],
      }),

      // [GET] /panels
      getPanels: build.query({
        query: ({ params }) =>
          secure({
            method: 'GET',
            url: withParams('/panels', params),
          }),
        providesTags: (result, error, { ...params }) => [
          tags.PANELS_SEARCH(params),
        ],
      }),

      // [GET] /dc_optimizers
      getOptimizers: build.query({
        query: ({ params }) =>
          secure({
            method: 'GET',
            url: withParams('/dc_optimizers', params),
          }),
        providesTags: [tags.DC_OPTIMIZERS_SEARCH()],
      }),

      // [GET] /inverters/{inverter_id}/panel/{panel_id}
      getInverterDetails: build.query({
        query: ({ inverter_id, panel_id, params }) =>
          secure({
            method: 'GET',
            url: withParams(
              `/inverters/${inverter_id}/panel/${panel_id}`,
              params
            ),
          }),
        providesTags: (result, error, { inverter_id, panel_id, params }) => [
          tags.INVERTERS({ inverter_id, panel_id, ...params }),
        ],
      }),

      // [GET] /wte
      getWteList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/wte',
          }),
        providesTags: [tags.WTE(LIST)],
      }),

      // [GET] /ac_combiner
      getAcCombinerList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/ac_combiner',
          }),
        providesTags: [tags.AC_COMBINER(LIST)],
      }),

      // [GET] /msp
      getMainServicePanelList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/msp',
          }),
        providesTags: [tags.MSP(LIST)],
      }),

      // [GET] /ac_subpanel
      getAcSubpanelList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/ac_subpanel',
          }),
        providesTags: [tags.AC_SUBPANEL(LIST)],
      }),

      // [GET] /ac_disconnect
      getDisconnectsList: build.query({
        query: ({ params }) =>
          secure({
            method: 'GET',
            url: withParams('/ac_disconnect', params),
          }),
        providesTags: (result, error, { ...args }) => [
          tags.AC_DISCONNECTS_SEARCH(args),
        ],
      }),

      // [GET] /wire_gauge
      getWireGaugeList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/wire_gauge',
          }),
        providesTags: [tags.WIRE_GAUGE(LIST)],
      }),

      // [GET] /wire_type
      getWireTypeList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/wire_type',
          }),
        providesTags: [tags.WIRE_TYPE(LIST)],
      }),

      // [GET] /wire_material
      getWireMaterialList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/wire_material',
          }),
        providesTags: [tags.WIRE_MATERIAL(LIST)],
      }),

      // [GET] /conduit_type
      getWireConduitTypeList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/conduit_type',
          }),
        providesTags: [tags.CONDUIT_TYPE(LIST)],
      }),

      // [GET] /rsd
      getRsdList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/rsd',
          }),
        providesTags: [tags.RSD(LIST)],
      }),
    },

    // PROJECTS
    ...{
      // [GET] /projects
      getProjects: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/projects',
          }),
        providesTags: [tags.PROJECTS(LIST)],
      }),

      // [POST] /projects
      createProjects: build.mutation({
        query: ({ body }) =>
          secure({
            method: 'POST',
            url: '/projects',
            body,
          }),
        invalidatesTags: [tags.PROJECTS(LIST)],
      }),

      // [GET] /projects/{project_id}
      getProjectDetails: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.PROJECTS(project_id),
        ],
      }),

      // [DELETE] /projects/{project_id}
      deleteProject: build.mutation({
        query: ({ project_id }) =>
          secure({
            method: 'DELETE',
            url: `/projects/${project_id}`,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}
      renameProject: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/property_information
      updateProjectsPropertyInformation: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/property_information`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/site_plan
      saveProjectSitePlanImage: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'POST',
            url: `/projects/${project_id}/site_plan`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/site_plan
      updateProjectsSitePlan: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/site_plan`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/contractor
      updateProjectsContractor: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/contractor`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/arrays
      updateProjectsArrays: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/arrays`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/duplicate
      duplicateProject: build.mutation({
        query: ({ project_id }) =>
          secure({
            method: 'POST',
            url: `/projects/${project_id}/duplicate`,
          }),
        invalidatesTags: [tags.PROJECTS(LIST)],
      }),

      // [PATCH] /projects/{project_id}/property_plan
      updateProjectPropertyPlan: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/property_plan`,
            body: toFormData(body),
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/property_plan/scale
      updateProjectPropertyPlanScale: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/property_plan/scale`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/systems
      updateProjectSystems: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'POST',
            url: `/projects/${project_id}/systems`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.TEMPLATE_SYSTEM(project_id),
          tags.TEMPLATE_BOS(project_id),
          tags.TEMPLATE_INTERCONNECTION(project_id),
          tags.ELECTRICAL_SUMMARY(project_id),
          tags.BILL_OF_MATERIAL(project_id),
          tags.WIRES_TABLE(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [DELETE] /projects/{project_id}/systems/{system_id}
      removeProjectSystems: build.mutation({
        query: ({ project_id, system_id }) =>
          secure({
            method: 'DELETE',
            url: `/projects/${project_id}/systems/${system_id}`,
          }),
        invalidatesTags: (result, error, { project_id, skipInvalidation }) =>
          skipInvalidation
            ? []
            : [
                tags.PROJECTS(project_id),
                tags.ELECTRICAL_SUMMARY(project_id),
                tags.BILL_OF_MATERIAL(project_id),
                tags.TEMPLATE_SYSTEM(project_id),
                tags.TEMPLATE_BOS(project_id),
                tags.TEMPLATE_INTERCONNECTION(project_id),
                tags.WIRES_TABLE(project_id),
                tags.DATASHEETS(project_id),
                tags.FINAL_PDF(project_id),
                tags.PREVIEW(project_id),
              ],
      }),

      // [PATCH] /projects/{project_id}/interconnection
      updateProjectInterconnection: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/interconnection`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.ELECTRICAL_SUMMARY(project_id),
          tags.BILL_OF_MATERIAL(project_id),
          tags.TEMPLATE_INTERCONNECTION(project_id),
          tags.WIRES_TABLE(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/bos
      updateProjectBos: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/bos`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.TEMPLATE_BOS(project_id),
          tags.ELECTRICAL_SUMMARY(project_id),
          tags.BILL_OF_MATERIAL(project_id),
          tags.TEMPLATE_BOS(project_id),
          tags.WIRES_TABLE(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [GET] /projects/{project_id}/wires_table
      getProjectWiresTable: build.query({
        query: ({ project_id, params }) =>
          secure({
            method: 'GET',
            url: withParams(`/projects/${project_id}/wires_table`, params),
          }),
        providesTags: (result, error, { project_id }) => [
          tags.WIRES_TABLE(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/wires_table
      saveProjectWireTableSettings: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/wires_table`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.ELECTRICAL_SUMMARY(project_id),
          tags.BILL_OF_MATERIAL(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/wires_table/segment/{segment_id}
      getRecalculatedWireSegment: build.mutation({
        query: ({ project_id, segment_id, params, body }) =>
          secure({
            method: 'POST',
            url: withParams(
              `/projects/${project_id}/wires_table/segment/${segment_id}`,
              params
            ),
            body,
          }),
      }),

      // [PATCH] /projects/{project_id}/wires_table/segment/{segment_id}
      saveRecalculatedWireSegment: build.mutation({
        query: ({ project_id, segment_id, params, body }) =>
          secure({
            method: 'PATCH',
            url: withParams(
              `/projects/${project_id}/wires_table/segment/${segment_id}`,
              params
            ),
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.WIRES_TABLE(project_id),
          tags.BILL_OF_MATERIAL(project_id),
          tags.ELECTRICAL_SUMMARY(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/templates/systems
      getSystemsTemplateImage: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/templates/systems`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.TEMPLATE_SYSTEM(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/templates/interconnection
      getInterconnectionImage: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/templates/interconnection`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.TEMPLATE_INTERCONNECTION(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/templates/bos
      getBalanceOfSystemImage: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/templates/bos`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.TEMPLATE_BOS(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/elevation
      getElevationImage: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'POST',
            url: `/projects/${project_id}/elevation`,
            body: toFormData(body),
          }),
      }),

      // [GET] /projects/{project_id}/electrical_summary
      getProjectElectricalSummary: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/electrical_summary`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.ELECTRICAL_SUMMARY(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/line_diagram
      saveProjectLineDiagramImage: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'POST',
            url: `/projects/${project_id}/line_diagram`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/electrical_tables
      saveProjectElectricalTablesImage: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'POST',
            url: `/projects/${project_id}/electrical_tables`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/electrical_plan
      saveProjectElectricalPlanImage: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'POST',
            url: `/projects/${project_id}/electrical_plan`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/placards
      saveProjectPlacardsImage: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'POST',
            url: `/projects/${project_id}/placards`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PROJECTS(LIST),
          tags.PROJECTS(project_id),
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
        ],
      }),

      // [GET] /projects/{project_id}/bom
      getProjectBillOfMaterial: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/bom`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.BILL_OF_MATERIAL(project_id),
        ],
      }),

      // [GET] /projects/{project_id}/templates/placard
      getPlacardImage: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/templates/placard`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.TEMPLATE_PLACARDS(project_id),
        ],
      }),

      // [GET] /projects/{project_id}/final-pdf
      getFinalPdf: build.mutation({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/final-pdf`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.FINAL_PDF(project_id),
        ],
      }),
    },

    // DIRECTORY
    ...{
      // [POST] /directories/ahj
      searchAhjByName: build.query({
        query: ({ body }) =>
          secure({
            method: 'POST',
            url: '/directories/ahj',
            body,
          }),
        providesTags: (result, error, { body }) => [tags.AHJ_SEARCH(body)],
      }),

      // [POST] /directories/utilities/state
      searchUtilitiesByState: build.query({
        query: ({ body }) =>
          secure({
            method: 'POST',
            url: '/directories/utilities/state',
            body,
          }),
        providesTags: (result, error, { body }) => [
          tags.UTILITIES_SEARCH(body),
        ],
      }),

      // [POST] /directories/ashrae_temps
      getClosestWeatherStation: build.query({
        query: ({ body }) =>
          secure({
            method: 'POST',
            url: '/directories/ashrae_temps',
            body,
          }),
        providesTags: (result, error, { body }) => [
          tags.ASHRAE_TEMPS_SEARCH(body),
        ],
      }),

      // [GET] /directories/ashrae_temps/{station_id}
      getWeatherStation: build.query({
        query: ({ station_id }) =>
          secure({
            method: 'GET',
            url: `/directories/ashrae_temps/${station_id}`,
          }),
        providesTags: (result, error, { station_id }) => [
          tags.ASHRAE_TEMPS(station_id),
        ],
      }),

      // [GET] /directories/construction_type
      getConstructionTypeList: build.query({
        query: () =>
          secure({
            method: 'GET',
            url: '/directories/construction_type',
          }),
        providesTags: [tags.CONSTRUCTION_TYPE(LIST)],
      }),

      // [GET] /directories/counties/{county_id}
      getCountyDetails: build.query({
        query: ({ county_id }) =>
          secure({
            method: 'GET',
            url: `/directories/counties/${county_id}`,
          }),
        providesTags: (result, error, { county_id }) => [
          tags.COUNTIES(county_id),
        ],
      }),

      // [GET] /directories/counties/by-ahj/{ahj_id}
      getCountyByAhj: build.query({
        query: ({ ahj_id }) =>
          secure({
            method: 'GET',
            url: `/directories/counties/by-ahj/${ahj_id}`,
          }),
        providesTags: (result, error, { ahj_id }) => [
          tags.COUNTIES_BY_AHJ(ahj_id),
        ],
      }),

      // [GET] /directiories/counties/{county_id}/notes
      searchCountyNotesByGroup: build.query({
        query: ({ county_id, params }) =>
          secure({
            method: 'GET',
            url: withParams(`/directories/counties/${county_id}/notes`, params),
          }),
        providesTags: (result, error, { county_id, params }) => [
          tags.COUNTIES_BY_GROUP({ county_id, ...params }),
        ],
      }),

      // [GET] /directories/counties/{county_id}/requirements
      searchCountyRequirementByGroup: build.query({
        query: ({ county_id, params }) =>
          secure({
            method: 'GET',
            url: withParams(
              `/directories/counties/${county_id}/requirements`,
              params
            ),
          }),
        providesTags: (result, error, { county_id, params }) => [
          tags.COUNTY_REQUIREMENT_BY_GROUP({ county_id, ...params }),
        ],
      }),

      // [GET] /directories/utilities/{utility_id}/notes
      searchUtilityNotesByGroup: build.query({
        query: ({ utility_id, params }) =>
          secure({
            method: 'GET',
            url: withParams(
              `/directories/utilities/${utility_id}/notes`,
              params
            ),
          }),
        providesTags: (result, error, { utility_id, params }) => [
          tags.UTILITIES_BY_GROUP({ utility_id, ...params }),
        ],
      }),

      // [GET] /directories/utilities/{utility_id}/requirements
      searchUtilityRequirementByGroup: build.query({
        query: ({ utility_id, params }) =>
          secure({
            method: 'GET',
            url: withParams(
              `/directories/utilities/${utility_id}/requirements`,
              params
            ),
          }),
        providesTags: (result, error, { utility_id, params }) => [
          tags.UTILITY_REQUIREMENT_BY_GROUP({ utility_id, ...params }),
        ],
      }),
    },

    // DATASHEETS
    ...{
      // [GET] /projects/{project_id}/datasheets
      getProjectDatasheets: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/datasheets`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.DATASHEETS(project_id),
        ],
      }),

      // [PATCH] /project/{project_id}/datasheets
      saveDatasheetMetadata: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/datasheets`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.DATASHEETS(project_id),
          tags.FINAL_PDF(project_id),
          tags.PREVIEW(project_id),
          tags.PROJECTS(project_id),
        ],
      }),

      // [POST] /projects/{project_id}/datasheets/{datasheet_title}
      uploadAdditionalDatasheet: build.mutation({
        query: ({ project_id, datasheet_title, body }) =>
          secure({
            method: 'POST',
            // TODO: check if works
            url: `/projects/${project_id}/datasheets/${datasheet_title}`,
            body: toFormData(body),
          }),
        invalidatesTags: (result, error, { project_id, skipInvalidation }) =>
          [
            !skipInvalidation && tags.DATASHEETS(project_id),
            !skipInvalidation && tags.FINAL_PDF(project_id),
            !skipInvalidation && tags.PREVIEW(project_id),
            !skipInvalidation && tags.PROJECTS(project_id),
          ].filter(Boolean),
      }),

      // [DELETE] /projects/{project_id}/datasheets/{datasheet_id}
      deleteAdditionalDatasheet: build.mutation({
        query: ({ project_id, datasheet_id }) =>
          secure({
            method: 'DELETE',
            url: `/projects/${project_id}/datasheets/${datasheet_id}`,
          }),
        invalidatesTags: (result, error, { project_id, skipInvalidation }) =>
          skipInvalidation
            ? []
            : [
                tags.DATASHEETS(project_id),
                tags.FINAL_PDF(project_id),
                tags.PREVIEW(project_id),
                tags.PROJECTS(project_id),
              ],
      }),
    },

    // PREVIEW
    ...{
      // [GET] /projects/{project_id}/preview
      getProjectPreview: build.query({
        query: ({ project_id }) =>
          secure({
            method: 'GET',
            url: `/projects/${project_id}/preview`,
          }),
        providesTags: (result, error, { project_id }) => [
          tags.PREVIEW(project_id),
        ],
      }),

      // [PATCH] /projects/{project_id}/preview
      updateProjectPreviewMetadata: build.mutation({
        query: ({ project_id, body }) =>
          secure({
            method: 'PATCH',
            url: `/projects/${project_id}/preview`,
            body,
          }),
        invalidatesTags: (result, error, { project_id }) => [
          tags.PREVIEW(project_id),
          tags.PROJECTS(project_id),
        ],
      }),
    },
  }),
});

export default slice;
