export const SCALE_TYPE = {
  ENGINEER: 'ENGINEER',
  ARCHITECT: 'ARCHITECT',
};
export const SCALE_TYPE_NAMES = {
  [SCALE_TYPE.ENGINEER]: 'Engineer',
  [SCALE_TYPE.ARCHITECT]: 'Architect',
};
export const SCALE_TYPE_LIST = [SCALE_TYPE.ENGINEER, SCALE_TYPE.ARCHITECT].map(
  (value) => ({
    value,
    label: SCALE_TYPE_NAMES[value],
  })
);

export const ENGINEER_SCALES = {
  SCALE_0: `1/2" = 1'-0"`,
  SCALE_1: `3/8" = 1'-0"`,
  SCALE_2: `1/4" = 1'-0"`,
  SCALE_3: `3/16" = 1'-0"`,
  SCALE_4: `1/8" = 1'-0"`,
  SCALE_5: `3/32" = 1'-0"`,
  SCALE_6: `1/16" = 1'-0"`,
  SCALE_7: `3/64" = 1'-0"`,
  SCALE_8: `1/32" = 1'-0"`,
  SCALE_9: `3/128" = 1'-0"`,
  SCALE_10: `1/64" = 1'-0"`,
  SCALE_11: `3/256" = 1'-0"`,
  SCALE_12: `1/128" = 1'-0"`,
  SCALE_13: `3/512" = 1'-0"`,
  SCALE_14: `1/256" = 1'-0"`,
};
export const ENGINEER_SCALES_NAMES = {
  [ENGINEER_SCALES.SCALE_0]: ENGINEER_SCALES.SCALE_0,
  [ENGINEER_SCALES.SCALE_1]: ENGINEER_SCALES.SCALE_1,
  [ENGINEER_SCALES.SCALE_2]: ENGINEER_SCALES.SCALE_2,
  [ENGINEER_SCALES.SCALE_3]: ENGINEER_SCALES.SCALE_3,
  [ENGINEER_SCALES.SCALE_4]: ENGINEER_SCALES.SCALE_4,
  [ENGINEER_SCALES.SCALE_5]: ENGINEER_SCALES.SCALE_5,
  [ENGINEER_SCALES.SCALE_6]: ENGINEER_SCALES.SCALE_6,
  [ENGINEER_SCALES.SCALE_7]: ENGINEER_SCALES.SCALE_7,
  [ENGINEER_SCALES.SCALE_8]: ENGINEER_SCALES.SCALE_8,
  [ENGINEER_SCALES.SCALE_9]: ENGINEER_SCALES.SCALE_9,
  [ENGINEER_SCALES.SCALE_10]: ENGINEER_SCALES.SCALE_10,
  [ENGINEER_SCALES.SCALE_11]: ENGINEER_SCALES.SCALE_11,
  [ENGINEER_SCALES.SCALE_12]: ENGINEER_SCALES.SCALE_12,
  [ENGINEER_SCALES.SCALE_13]: ENGINEER_SCALES.SCALE_13,
  [ENGINEER_SCALES.SCALE_14]: ENGINEER_SCALES.SCALE_14,
};
export const ENGINEER_SCALES_LIST = [
  ENGINEER_SCALES.SCALE_0,
  ENGINEER_SCALES.SCALE_1,
  ENGINEER_SCALES.SCALE_2,
  ENGINEER_SCALES.SCALE_3,
  ENGINEER_SCALES.SCALE_4,
  ENGINEER_SCALES.SCALE_5,
  ENGINEER_SCALES.SCALE_6,
  ENGINEER_SCALES.SCALE_7,
  ENGINEER_SCALES.SCALE_8,
  ENGINEER_SCALES.SCALE_9,
  ENGINEER_SCALES.SCALE_10,
  ENGINEER_SCALES.SCALE_11,
  ENGINEER_SCALES.SCALE_12,
  ENGINEER_SCALES.SCALE_13,
  ENGINEER_SCALES.SCALE_14,
].map((value) => ({
  value,
  label: ENGINEER_SCALES_NAMES[value],
}));

export const ARCHITECT_SCALES = {
  SCALE_15: `1" = 10'`,
  SCALE_16: `1" = 20'`,
  SCALE_17: `1" = 30'`,
  SCALE_18: `1" = 40'`,
  SCALE_19: `1" = 50'`,
  SCALE_20: `1" = 60`,
};
export const ARCHITECT_SCALES_NAMES = {
  [ARCHITECT_SCALES.SCALE_15]: ARCHITECT_SCALES.SCALE_15,
  [ARCHITECT_SCALES.SCALE_16]: ARCHITECT_SCALES.SCALE_16,
  [ARCHITECT_SCALES.SCALE_17]: ARCHITECT_SCALES.SCALE_17,
  [ARCHITECT_SCALES.SCALE_18]: ARCHITECT_SCALES.SCALE_18,
  [ARCHITECT_SCALES.SCALE_19]: ARCHITECT_SCALES.SCALE_19,
  [ARCHITECT_SCALES.SCALE_20]: ARCHITECT_SCALES.SCALE_20,
};
export const ARCHITECT_SCALES_LIST = [
  ARCHITECT_SCALES.SCALE_15,
  ARCHITECT_SCALES.SCALE_16,
  ARCHITECT_SCALES.SCALE_17,
  ARCHITECT_SCALES.SCALE_18,
  ARCHITECT_SCALES.SCALE_19,
  ARCHITECT_SCALES.SCALE_20,
].map((value) => ({
  value,
  label: ARCHITECT_SCALES_NAMES[value],
}));

export const SCALE_VALUES = {
  [ENGINEER_SCALES.SCALE_0]: 2 / 1,
  [ENGINEER_SCALES.SCALE_1]: 8 / 3,
  [ENGINEER_SCALES.SCALE_2]: 4 / 1,
  [ENGINEER_SCALES.SCALE_3]: 16 / 3,
  [ENGINEER_SCALES.SCALE_4]: 8 / 1,
  [ENGINEER_SCALES.SCALE_5]: 32 / 3,
  [ENGINEER_SCALES.SCALE_6]: 16 / 1,
  [ENGINEER_SCALES.SCALE_7]: 64 / 3,
  [ENGINEER_SCALES.SCALE_8]: 32 / 1,
  [ENGINEER_SCALES.SCALE_9]: 128 / 3,
  [ENGINEER_SCALES.SCALE_10]: 64 / 1,
  [ENGINEER_SCALES.SCALE_11]: 256 / 3,
  [ENGINEER_SCALES.SCALE_12]: 128 / 1,
  [ENGINEER_SCALES.SCALE_13]: 512 / 3,
  [ENGINEER_SCALES.SCALE_14]: 256 / 1,
  [ARCHITECT_SCALES.SCALE_15]: 10,
  [ARCHITECT_SCALES.SCALE_16]: 20,
  [ARCHITECT_SCALES.SCALE_17]: 30,
  [ARCHITECT_SCALES.SCALE_18]: 40,
  [ARCHITECT_SCALES.SCALE_19]: 50,
  [ARCHITECT_SCALES.SCALE_20]: 60,
};

export const SCALES = {
  ...ENGINEER_SCALES,
  ...ARCHITECT_SCALES,
};
export const SCALES_NAMES = {
  ...ENGINEER_SCALES_NAMES,
  ...ARCHITECT_SCALES_NAMES,
};
export const SCALES_LIST = [...ENGINEER_SCALES_LIST, ...ARCHITECT_SCALES_LIST];
