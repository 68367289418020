import { useMemo } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';

import { routes } from 'routes';
import { CompanyDetails } from 'views';
import { Icon, Ref } from 'components';

const AddCompany = () => {
  const backPath = useMemo(() => routes.companies.path(), []);

  return (
    <Box p={4} flexGrow={1} display="flex" flexDirection="column">
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton replace nostyles component={Ref} to={backPath}>
          <Icon.ArrowBack sx={{ color: 'secondary.main' }} />
        </IconButton>

        <Typography variant="subtitle1">Add Contractor Company</Typography>
      </Stack>

      <CompanyDetails mt={4} back={backPath} />
    </Box>
  );
};

export default AddCompany;
