import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import {
  useGetProjectDetailsQuery,
  useGetCountyDetailsQuery,
  useGetConstructionTypeListQuery,
  useUpdateProjectsSitePlanMutation,
} from 'store';

import {
  property_image_width,
  property_image_height,
  WIND_EXPOSURE_LIST,
  OCCUPANCY_LIST,
} from 'constants';

import { routes } from 'routes';
import { useIsLoading, withProps } from 'hooks';
import { getArray, getString } from 'utils';
import { ProjectDetailsLayout } from 'layouts';
import { ProjectPreview, ProjectSubmitButton } from 'views';
import {
  Center,
  Form,
  Icon,
  Image,
  ProgressBox,
  RadioButtons,
  TextField,
  Tooltip,
  useMessage,
} from 'components';

import ConstructionTypeSelect from './ConstructionTypeSelect';

const windSpeedTooltip =
  'The value used for the ground snow load should represent the snow load that has a 50 year mean recurrence interval';

const NumberField = withProps(TextField.Control, {
  type: 'number',
  inputProps: { min: 0 },
});
const InfoIcon = withProps(Icon.InfoFilled, {
  sx: {
    fontSize: (theme) => theme.pxToRem(14),
    color: 'border.main',
    '&:hover': {
      color: 'secondary.main',
    },
  },
});

const SiteDesignCriteria = () => {
  const { project_id } = useParams();

  const queryConstruction = useGetConstructionTypeListQuery();
  const queryProject = useGetProjectDetailsQuery({ project_id });

  const project = queryProject.data;
  const county_id = project?.county?.id;

  const countyQuery = useGetCountyDetailsQuery(
    { county_id },
    { skip: !county_id }
  );
  const [updateSitePlan, updateQuery] = useUpdateProjectsSitePlanMutation();

  const county = countyQuery.data;
  const constructionTypes = queryConstruction.data;
  const loading = useIsLoading(
    updateQuery,
    countyQuery,
    queryConstruction,
    queryProject
  );

  const defaultConstructionType = useMemo(() => {
    return getString(
      getArray(constructionTypes).find((ct) => ct.is_default)?.id
    );
  }, [constructionTypes]);

  const navigate = useNavigate();
  const m = useMessage();
  const form = Form.useForm();
  const isFirstSaving = !project?.occupancy;
  const nextStep = useMemo(
    () => routes.contractor_company.path({ project_id }),
    [project_id]
  );

  useEffect(() => {
    form.reset({
      snow_load: project?.snow_load || county?.snow_load || 0,
      wind_speed: project?.wind_speed || county?.wind_speed || '',
      wind_exposure_type:
        project?.wind_exposure_type || WIND_EXPOSURE_LIST[0].value,
      occupancy: project?.occupancy || OCCUPANCY_LIST[0].value,
      construction_type: project?.construction_type || defaultConstructionType,
    });
  }, [county, defaultConstructionType, form, project]);

  const handleSubmit = useCallback(
    async (body) => {
      try {
        await updateSitePlan({ project_id, body }).unwrap();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [isFirstSaving, m, navigate, nextStep, project_id, updateSitePlan]
  );

  return (
    <ProjectDetailsLayout
      form={form}
      component={Form}
      title="Site Design Criteria"
      onSubmit={handleSubmit}
      body={
        <ProgressBox progress={loading}>
          <Stack spacing={1} alignItems="flex-start">
            <Center justifyContent="space-between" gap={2}>
              <NumberField
                required
                name="snow_load"
                label="Ground Snow Load"
                initialFocus
              />

              <NumberField
                required
                hideAsterisk
                name="wind_speed"
                label={
                  <Center justifyContent="space-between" alignItems="baseline">
                    <span>Design Wind Speed *</span>

                    <Tooltip title={windSpeedTooltip}>
                      <InfoIcon />
                    </Tooltip>
                  </Center>
                }
              />
            </Center>

            <RadioButtons.Control
              minWidth={48}
              size="medium"
              defaultValue="A"
              variant="flat"
              name="wind_exposure_type"
              label="Wind Exposure Type"
              options={WIND_EXPOSURE_LIST}
            />

            <RadioButtons.Control
              minWidth={48}
              size="medium"
              defaultValue={1}
              name="occupancy"
              label="Occupancy"
              variant="flat"
              options={OCCUPANCY_LIST}
            />

            <ConstructionTypeSelect
              required
              displayEmpty={false}
              name="construction_type"
              label="Construction Type"
            />
          </Stack>

          <Box mt={4}>
            <ProjectSubmitButton
              nextStep={nextStep}
              isFirstSaving={isFirstSaving}
            />
          </Box>
        </ProgressBox>
      }
    >
      <ProjectPreview>
        <Center flexGrow={1}>
          <Image
            src={project?.property_image}
            width={property_image_width}
            height={property_image_height}
          />
        </Center>
      </ProjectPreview>
    </ProjectDetailsLayout>
  );
};

export default SiteDesignCriteria;
