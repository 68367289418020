import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Collapse } from '@mui/material';

import { useGetProjectDetailsQuery } from 'store';
import { routes } from 'routes';
import {
  getArray,
  getProjectAvailablePanelsCount,
  getProjectConfiguredPanelsCount,
} from 'utils';

import { useToggle } from 'hooks';
import { ProjectSubmitButton } from 'views';
import { ProjectDetailsLayout } from 'layouts';
import { Form, Message, ProgressBox, useMessage } from 'components';

import PvSystemPreview from './PvSystemPreview';
import CommonSystemConfigFields from './CommonSystemConfigFields';
import SystemsForm, { createEmptySystem } from './SystemsForm';

import useSubmitSystems from './useSubmitSystems.hook';
import useRefillForm from './useRefillForm.hook';

const PvSystem = () => {
  const { project_id } = useParams();
  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });
  const [submit, { isFetching: submitFetching }] = useSubmitSystems({
    project_id,
  });

  const [initated, toggleInitiated] = useToggle();
  const [outOfRange, setOutOfRange] = useState(null);
  const [refillLoading, toggleRefillLoading] = useToggle(true);
  const [arraysCountChanges, toggleArraysCountChanged] = useToggle();

  const refillData = useRefillForm(project);
  const m = useMessage();
  const form = Form.useForm();

  const loading = isFetching || submitFetching || refillLoading;
  const isFirstSaving = getArray(project?.systems).length === 0;
  const nextStep = useMemo(
    () => routes.interconnection.path({ project_id }),
    [project_id]
  );

  const availablePanels = useMemo(() => {
    return getProjectAvailablePanelsCount(project);
  }, [project]);

  const handleSystemReset = useCallback(() => {
    form.setValue('systems', [createEmptySystem()]);
  }, [form]);

  const validateSystems = useCallback(
    (data) => {
      const stringsSum = getProjectConfiguredPanelsCount(data);
      const oor = stringsSum !== availablePanels ? stringsSum : null;
      setOutOfRange(oor);
      return !oor;
    },
    [availablePanels]
  );

  useEffect(() => {
    if (outOfRange) {
      const w = form.watch((data) => {
        if (!arraysCountChanges) {
          setOutOfRange(null);
          return;
        }
        validateSystems(data);
      });
      return () => {
        w.unsubscribe();
      };
    }
  }, [outOfRange, form, arraysCountChanges, validateSystems]);

  useEffect(() => {
    if (isFetching || submitFetching || initated) {
      return;
    }
    // Workaround to actualize form data after resetting before system details
    // loading
    const tid = setTimeout(() => {
      form.reset(refillData);
      toggleRefillLoading.off();

      if (project?.interconnection) {
        const isValid = validateSystems(project);
        toggleArraysCountChanged(!isValid);
      }
      if (!isFirstSaving) {
        toggleInitiated.on();
      }
    }, 500);

    return () => {
      clearTimeout(tid);
    };
  }, [
    form,
    initated,
    isFetching,
    project,
    refillData,
    submitFetching,
    toggleArraysCountChanged,
    toggleInitiated,
    toggleRefillLoading,
    validateSystems,
    isFirstSaving,
  ]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        if (!validateSystems(formData)) {
          return;
        }
        await submit(formData);

        if (!isFirstSaving) {
          form.reset(formData);
        }
        m.success('Project has been updated!');
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [validateSystems, submit, isFirstSaving, m, form]
  );

  return (
    <ProjectDetailsLayout
      form={{
        ...form,
        availablePanels,
        station_id: project?.meteo_station?.id,
      }}
      component={Form}
      title="PV System Parameters"
      onSubmit={handleSubmit}
      body={
        <ProgressBox progress={loading}>
          <CommonSystemConfigFields onSystemReset={handleSystemReset} />

          <SystemsForm />

          <Box mt={4}>
            <Collapse in={!!outOfRange}>
              <Box mb={2}>
                <Message variant="error">
                  {outOfRange} panels configured, but only {availablePanels}{' '}
                  available! Please check your configuration, and try again.
                  Number of configured panels must be equal to available panels.
                </Message>
              </Box>
            </Collapse>

            <ProjectSubmitButton
              nextStep={nextStep}
              isFirstSaving={isFirstSaving}
            />
          </Box>
        </ProgressBox>
      }
    >
      <PvSystemPreview project_id={project_id} />
    </ProjectDetailsLayout>
  );
};

export default PvSystem;
