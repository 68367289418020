import { forwardRef, lazy } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { dev } from 'constants';

export const slipStyles = ({ theme, elevation, bgcolor }) =>
  theme.unstable_sx({
    boxShadow: theme.makeShadow(elevation),
    bgcolor: bgcolor || 'common.white',
    transition: theme.transitions.create('box-shadow'),
  });

const Root = styled(Box, {
  label: 'Slip',
  shouldForwardProp: (prop) => !['elevation', 'bgcolor'].includes(prop),
})(slipStyles);

const Slip = forwardRef((props, ref) => {
  const { p = 1, elevation = 1, borderRadius = 1, ...rest } = props;

  return (
    <Root
      p={p}
      ref={ref}
      elevation={elevation}
      borderRadius={borderRadius}
      {...rest}
    />
  );
});

if (dev) {
  Slip.Demo = lazy(() => import('./Slip.demo'));
}

export default Slip;
