import { lazy, useCallback, useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { dev } from 'constants';

const Messages = (props) => {
  const { children } = props;
  const rootRef = useRef(null);

  const handleAction = useCallback((key) => {
    const { current: notify } = rootRef;

    const handleClose = () => {
      typeof notify?.closeSnackbar === 'function' && notify.closeSnackbar(key);
    };
    return (
      <Box>
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ color: 'common.white' }} />
        </IconButton>
      </Box>
    );
  }, []);

  return (
    <SnackbarProvider
      preventDuplicate
      hideIconVariant
      ref={rootRef}
      maxSnack={5}
      autoHideDuration={7000}
      action={handleAction}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

if (dev) {
  Messages.Demo = lazy(() => import('./Messages.demo'));
}

export default Messages;
