import { forwardRef, lazy } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  DialogActions,
  Backdrop as MuiBackdrop,
  styled,
} from '@mui/material';

import { dev } from 'constants';
import { makeClasses, withProps } from 'hooks';
import Icon from 'components/Icon';

const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
  backdropFilter: 'blur(5px)',
  backgroundColor: theme.palette.colors.backdrop_background,
}));

const StyledDialog = makeClasses(Dialog)({
  paper: {
    position: 'absolute',
    top: '10%',
  },
});

const Root = withProps(StyledDialog, {
  PaperProps: {
    sx: {
      maxWidth: '100%',
    },
  },
  slots: {
    backdrop: Backdrop,
  },
});

const Modal = forwardRef((props, ref) => {
  const {
    clear,
    children,
    open = false,
    closeIcon = true,
    title,
    onClose,
    actions,
    minWidth = 544,
    maxWidth = 544,
    ...rest
  } = props;

  return (
    <Root ref={ref} {...rest} open={open} onClose={onClose}>
      {clear && children}

      {!clear && (
        <Box px={3} py={4} minWidth={minWidth} maxWidth={maxWidth}>
          {closeIcon && (
            <Box top={12} right={12} position="absolute">
              <IconButton
                size="small"
                onClick={onClose}
                sx={{ color: 'secondary.main' }}
              >
                <Icon.Close />
              </IconButton>
            </Box>
          )}

          {title && (
            <DialogTitle>
              <Typography
                component={Box}
                variant="subtitle2"
                textAlign="center"
              >
                {title}
              </Typography>
            </DialogTitle>
          )}
          <DialogContent sx={{ overflow: 'initial' }}>{children}</DialogContent>

          {actions && (
            <DialogActions
              sx={{ px: 3, py: 2, justifyContent: 'center', flexGrow: 1 }}
            >
              {actions}
            </DialogActions>
          )}
        </Box>
      )}
    </Root>
  );
});

if (dev) {
  Modal.Demo = lazy(() => import('./Modal.demo'));
}

export default Modal;
