import { useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Box, Stack } from '@mui/material';

import { useToggle } from 'hooks';
import { imageFileToBase64 } from 'utils';
import { ProjectSubmitButton } from 'views';
import { Button, FormLabel, ProgressBox, Ref } from 'components';

import DatasheetItem from './DatasheetItem';
import AddDatasheetModal from './AddDatasheetModal';

const DatasheetsForm = () => {
  const {
    control,
    loading,
    nextStep,
    setValue,
    getValues,
    isFirstSaving,
    onDatasheetClick,
    selectedDatasheet,
  } = useFormContext();

  const [add, toggleAdd] = useToggle();
  const [fileProgress, toggleFileProgress] = useToggle();
  const [replaceItem, setReplaceItem] = useState(null);

  const { fields, move, append, insert } = useFieldArray({
    control,
    name: 'metadata',
    keyName: 'field_id',
  });

  const handleDragEnd = useCallback(
    (options) => {
      const { destination, source } = options;

      if (!destination) {
        return;
      }
      move(source.index, destination.index);
    },
    [move]
  );

  const handleCloseAddModal = useCallback(() => {
    toggleAdd.off();
    setReplaceItem(null);
  }, [toggleAdd]);

  const handleUploadCustomDatasheet = useCallback(
    async (v) => {
      const replaceId = replaceItem;
      toggleFileProgress.on();
      handleCloseAddModal();
      const tempFileUrl = await imageFileToBase64(v.file);

      const newItem = {
        equipment_id: `${Date.now()}`,
        is_custom: true,
        visible: true,
        filename: v.file.name,
        equipment_title: v.title,
        file_url: tempFileUrl,
        status: 'new',
        file: v.file,
      };
      if (replaceId) {
        const allFields = getValues('metadata');
        const replaceIndex = allFields.findIndex((f) => {
          return f.equipment_id === replaceId;
        });
        if (Number.isFinite(replaceIndex)) {
          const replacedItem = allFields[replaceIndex];

          if (selectedDatasheet === replacedItem.equipment_id) {
            onDatasheetClick(newItem.equipment_id);
          }
          setValue(`metadata.${replaceIndex}.status`, 'deleted', {
            shouldDirty: true,
            shouldTouch: true,
          });
          insert(replaceIndex, newItem);
        }
      } else {
        append(newItem);
      }
      toggleFileProgress.off();
    },
    [
      replaceItem,
      toggleFileProgress,
      handleCloseAddModal,
      getValues,
      selectedDatasheet,
      setValue,
      insert,
      onDatasheetClick,
      append,
    ]
  );

  const handleReplace = useCallback(
    (id) => {
      toggleAdd.on();
      setReplaceItem(id);
    },
    [toggleAdd]
  );

  return (
    <>
      <AddDatasheetModal
        open={add}
        replace={!!replaceItem}
        onClose={handleCloseAddModal}
        onSubmit={handleUploadCustomDatasheet}
      />

      <ProgressBox progress={loading || fileProgress} minHeight={100}>
        <FormLabel>Datasheets that will be included</FormLabel>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="metadata">
            {(provided) => {
              return (
                <Stack
                  mt={2}
                  spacing={2}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {fields.map((field, index) => {
                    const { field_id, ...datasheet } = field;

                    return (
                      <DatasheetItem
                        key={field_id}
                        index={index}
                        name="metadata"
                        datasheet={datasheet}
                        selected={selectedDatasheet === datasheet.equipment_id}
                        onClick={() => onDatasheetClick(datasheet.equipment_id)}
                        onReplace={() => handleReplace(datasheet.equipment_id)}
                        onDelete={() => {
                          if (selectedDatasheet === datasheet.equipment_id) {
                            console.log('here');
                            onDatasheetClick(datasheet.equipment_id);
                          }
                          setValue(`metadata.${index}.status`, 'deleted', {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }}
                      />
                    );
                  })}

                  {provided.placeholder}
                </Stack>
              );
            }}
          </Droppable>
        </DragDropContext>

        <Box mt={2} pl={4}>
          <Ref.Container>
            <Button
              variant="dashed"
              fontColor="secondary"
              onClick={toggleAdd.on}
            >
              <Ref>Add Datasheet</Ref>
            </Button>
          </Ref.Container>
        </Box>

        <Box mt={4}>
          <ProjectSubmitButton
            nextStep={nextStep}
            isFirstSaving={isFirstSaving}
          />
        </Box>
      </ProgressBox>
    </>
  );
};

export default DatasheetsForm;
