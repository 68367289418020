import { useCallback, useMemo } from 'react';
import { Collapse, Typography } from '@mui/material';

import { routes } from 'routes';
import { ROLES } from 'constants';
import { useAuth, useToggle, useWatchField } from 'hooks';
import { Ref } from 'components';

import AccessWarningModal from './AccessWarningModal';

const CompanyEditMessage = () => {
  const [accessWarning, toggleAccessWarning] = useToggle();

  const company_id = useWatchField('contractor_id');
  const { role } = useAuth();

  const editCompanyPage = useMemo(() => {
    if (company_id) {
      return routes.edit_company.path({ company_id });
    }
  }, [company_id]);

  const handleLinkClick = useCallback(
    (e) => {
      if (role === ROLES.MEMBER) {
        e.preventDefault();
        toggleAccessWarning.on();
      }
    },
    [role, toggleAccessWarning]
  );

  return (
    <>
      <AccessWarningModal
        open={accessWarning}
        onClose={toggleAccessWarning.off}
      />

      <Collapse in={company_id !== 'new'}>
        <Typography variant="body1" component="div">
          <span>You can edit the company information in </span>

          <Typography variant="body2" color="secondary.main" component="span">
            <Ref to={editCompanyPage} onClick={handleLinkClick}>
              Settings
            </Ref>
          </Typography>
        </Typography>
      </Collapse>
    </>
  );
};

export default CompanyEditMessage;
