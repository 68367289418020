import { forwardRef, lazy, useCallback, useState } from 'react';
import {
  styled,
  InputAdornment,
  autocompleteClasses as classes,
  Autocomplete as MuiAutocomplete,
} from '@mui/material';

import { dev } from 'constants';
import { getFunc } from 'utils';
import { makeClasses, withProps } from 'hooks';

import Icon from 'components/Icon';
import Slip from 'components/Slip';
import Spinner from 'components/Spinner';
import TextField from 'components/TextField';
import { inputStyles, inputClasses } from 'components/Input';

const defOptions = [];

const ClearIcon = withProps(Icon.Close, {
  sx: {
    color: 'secondary.main',
  },
});

const BasicStyledAutocomplete = styled(MuiAutocomplete, {
  label: 'D4PV-Autocomplete',
})(({ theme, readOnly }) => ({
  [`&.${classes.root}`]: {
    [`& .${classes.endAdornment}`]: {
      top: 'calc(50% - 15px)',

      [`& .${classes.popupIndicator}`]: {
        color: theme.palette.secondary.main,
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.shortest,
        }),
      },
    },

    [`& .${inputClasses.disabled}`]: {
      [`& .${classes.endAdornment}`]: {
        [`& .${classes.popupIndicator}`]: {
          color: theme.palette.border.main,
        },
      },
    },

    [`& .${classes.inputRoot}`]: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      ...inputStyles({ theme, readOnly }),
    },
  },
}));

const StyledAutocomplete = makeClasses(BasicStyledAutocomplete)({
  popper: () => ({
    [`& .${classes.listbox}`]: {
      [`& .${classes.option}`]: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  }),
});

const Root = withProps(StyledAutocomplete, {
  clearIcon: <ClearIcon />,
  popupIcon: <Icon.ArrowDown />,
  PaperComponent: Slip,
});

const Autocomplete = forwardRef((props, ref) => {
  const {
    sx,
    loading,
    valueAsId,
    options = defOptions,
    onValue,
    onChange,
    label,
    error,
    helperText,
    placeholder,
    inputRef,
    required,
    disableClearable,
    hideAsterisk,
    readOnly,
    forcePopupIcon,
    reserveHelperTextSpace,
    onInputValue,
    onInputChange,
    getOptionLabel,
    ...rest
  } = props;

  const [inputAnchor, setInputAnchor] = useState(null);

  const handleChange = useCallback(
    (e, val, reason, details) => {
      const v = valueAsId ? val?.value || val : val;
      getFunc(onChange)(e, v, reason, details);
      getFunc(onValue)(v);
    },
    [onChange, onValue, valueAsId]
  );

  const handleInputChange = useCallback(
    (e, val, ...args) => {
      getFunc(onInputChange)(e, val, ...args);
      getFunc(onInputValue)(val);
    },
    [onInputChange, onInputValue]
  );

  const handleOptionLabel = useCallback(
    (opt, ...args) => {
      if (typeof getOptionLabel === 'function') {
        return getOptionLabel(opt, ...args);
      }
      if (!valueAsId) {
        return opt?.label || opt;
      }
      if (opt?.label) {
        return opt.label;
      }
      const targetOption = options.find((o) => o?.value === opt);

      if (targetOption?.label) {
        return targetOption.label;
      }
      return '';
    },
    [options, valueAsId, getOptionLabel]
  );

  const handleRenderOption = useCallback(
    (itemProps, itemOption) => {
      const label = handleOptionLabel(itemOption);

      return (
        <li {...itemProps} title={label}>
          {label}
        </li>
      );
    },
    [handleOptionLabel]
  );

  return (
    <Root
      ref={ref}
      {...rest}
      options={options}
      loading={loading}
      readOnly={readOnly}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={handleOptionLabel}
      renderOption={handleRenderOption}
      disableClearable={disableClearable || readOnly}
      forcePopupIcon={readOnly ? false : forcePopupIcon}
      slotProps={{
        paper: {
          p: 0,
          my: 0.5,
          elevation: 4,
          sx: {
            border: (theme) => `1px solid ${theme.palette.border.light}`,
          },
        },
        popper: {
          anchorEl: inputAnchor,
        },
      }}
      renderInput={(params) => {
        const { InputProps, inputProps, ...restParams } = params;
        const { endAdornment, ...restInputProps } = InputProps;

        return (
          <TextField
            sx={sx}
            error={error}
            label={label}
            readOnly={readOnly}
            required={required}
            inputRef={inputRef}
            helperText={helperText}
            placeholder={placeholder}
            hideAsterisk={hideAsterisk}
            reserveHelperTextSpace={reserveHelperTextSpace}
            {...restParams}
            {...restInputProps}
            inputProps={inputProps}
            InputProps={{
              ref: setInputAnchor,
            }}
            endAdornment={
              <>
                {loading && !readOnly && (
                  <InputAdornment position="end">
                    <Spinner size="xsmall" />
                  </InputAdornment>
                )}

                {endAdornment}
              </>
            }
          />
        );
      }}
    />
  );
});

Autocomplete.Control = forwardRef((props, ref) => {
  const { ControlComponent = Autocomplete, ...rest } = props;

  return (
    <TextField.Control
      ref={ref}
      ControlComponent={ControlComponent}
      controlChangeHandler={(field) => (e, val, reason, details) => {
        field.onChange(val);
      }}
      {...rest}
    />
  );
});

if (dev) {
  Autocomplete.Demo = lazy(() => import('./Autocomplete.demo'));
}

export default Autocomplete;
