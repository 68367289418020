import { useMemo } from 'react';
import { Box, Collapse } from '@mui/material';

import { useWatchField } from 'hooks';
import { SYSTEM_TYPES } from 'constants';

import Mppt, { Strings } from './Mppt';

const { ACM, STRING, DC_OPTIMIZED } = SYSTEM_TYPES;

const SystemStrings = (props) => {
  const { prefix, inverter } = props;

  const system_type = useWatchField(`${prefix}.system_type`);
  const panel_id = useWatchField(`${prefix}.panel_id`);
  const inverter_id = useWatchField(`${prefix}.inverter_id`);

  const isAcm = system_type === ACM;
  const isString = system_type === STRING;
  const isOptimizer = system_type === DC_OPTIMIZED;
  const isStringType = isString || isOptimizer;

  const visible = isAcm
    ? !!panel_id
    : !!inverter && !!panel_id && !!inverter_id;

  const renderMppt = isString && visible;
  const renderStrings = !isString && visible;

  const stringsLabel = !isStringType ? 'Branch' : 'String';
  const stringsMin = inverter?.min_string_length || 0;
  const stringsMax = inverter?.max_string_length || 0;
  const stringsCount = inverter?.max_string_count || 30;
  const mpptCount = inverter?.mppt_qty;

  const StringsComponent = useMemo(() => {
    if (renderMppt) {
      return Mppt;
    }
    if (renderStrings) {
      return Strings;
    }
    return null;
  }, [renderMppt, renderStrings]);

  return (
    <Collapse in={visible}>
      <Box mt={4}>
        {StringsComponent && (
          <StringsComponent
            min={stringsMin}
            max={stringsMax}
            count={stringsCount}
            label={stringsLabel}
            mpptCount={mpptCount}
            name={`${prefix}.strings`}
          />
        )}
      </Box>
    </Collapse>
  );
};

export default SystemStrings;
