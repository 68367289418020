import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { getArray } from 'utils';
import { useToggle } from 'hooks';
import { REQUIREMENTS_GROUPS_LIST, REQUIREMENTS_GROUPS_NAMES } from 'constants';
import { useGetContractorCompanyDetailsQuery } from 'store';
import { ConfigurationSubMenu } from 'views';
import { ProgressBox, Button } from 'components';

import Requirement, { EditRequirementModal } from './Requirement';

const CompanyRequirements = () => {
  const { company_id } = useParams();
  const [group, setGroup] = useState(REQUIREMENTS_GROUPS_LIST[0].value);
  const [addRequirement, toggleAddRequirement] = useToggle();

  const { data: company, isFetching } = useGetContractorCompanyDetailsQuery({
    company_id,
  });

  const loading = isFetching;

  const title = useMemo(
    () => `${REQUIREMENTS_GROUPS_NAMES[group]} Requirements`,
    [group]
  );

  const requirements = useMemo(
    () => getArray(company?.requirements).filter((req) => req.group === group),
    [company?.requirements, group]
  );

  const options = useMemo(
    () =>
      REQUIREMENTS_GROUPS_LIST.map((opt) => ({
        ...opt,
        selected: opt.value === group,
      })),
    [group]
  );

  const handleGroupChange = useCallback((item) => {
    setGroup(item.value);
  }, []);

  return (
    <>
      {/* MODALS */}
      <>
        <EditRequirementModal
          group={group}
          open={addRequirement}
          company_id={company_id}
          onClose={toggleAddRequirement.off}
        />
      </>

      <ProgressBox
        mt={3}
        mx={4}
        pb={4}
        flexGrow={1}
        display="flex"
        maxHeight={650}
        progress={loading}
      >
        <ConfigurationSubMenu
          options={options}
          onItemClick={handleGroupChange}
        />

        <Box
          ml={3}
          flexGrow={1}
          overflow="hidden"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="subtitle1">{title}</Typography>

          <Box mt={2}>
            <Button
              size="medium"
              minWidth={130}
              fullWidth={false}
              onClick={toggleAddRequirement.on}
            >
              Add Requirement
            </Button>
          </Box>

          <Box mt={3} pr={0.5} flexGrow={1} overflow="auto">
            <Box
              sx={{
                borderTop: (theme) => `1px solid ${theme.palette.border.light}`,
              }}
            />

            {requirements.map((req) => (
              <Requirement key={req.id} note={req} company_id={company_id} />
            ))}
          </Box>
        </Box>
      </ProgressBox>
    </>
  );
};

export default CompanyRequirements;
