import { useFormContext, useWatch } from 'react-hook-form';
import { Collapse } from '@mui/material';

import { getResponseMessage } from 'utils';
import { useGetCountyByAhjQuery } from 'store';
import { Message } from 'components';

const EmptyAhjWarning = () => {
  const { control } = useFormContext();
  const ahj = useWatch({ control, name: 'ahj' });
  const ahj_id = ahj?.value;

  const { error } = useGetCountyByAhjQuery(
    { ahj_id },
    {
      skip: !ahj_id,
    }
  );
  const message = getResponseMessage(error) || 'No linked county was found';

  return (
    <Collapse in={!!error}>
      <Message variant="error">{message}</Message>
    </Collapse>
  );
};

export default EmptyAhjWarning;
