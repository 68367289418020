import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

import {
  useSuperviseAccountMutation,
  api,
  tags,
  useGetProfileQuery,
} from 'store';
import { routes } from 'routes';
import { useAuth, useIsLoading, useToggle } from 'hooks';
import { ProgressBox, useMessage } from 'components';

const Supervise = () => {
  const { token } = useParams();
  const m = useMessage();
  const navigate = useNavigate();
  const { clear, login, logout } = useAuth();
  const dispatch = useDispatch();

  const [delay, toggleDelay] = useToggle(true);
  const [tokenReceived, toggleTokenReceived] = useToggle(false);

  const queryProfile = useGetProfileQuery(undefined, {
    skip: !tokenReceived,
  });
  const [supervise, querySupervise] = useSuperviseAccountMutation();

  const loading = useIsLoading(querySupervise, delay, queryProfile);

  const handleAuthorize = useCallback(async () => {
    try {
      // Prevent second call
      toggleDelay.off();

      // Receive user token
      const response = await supervise({
        body: { token },
      }).unwrap();

      // Login with received token
      login({ access_token: response.token });

      // Allow to make profile requests in layouts
      dispatch(api.util.invalidateTags([tags.ME()]));

      // Allow profile request on this page
      toggleTokenReceived.on();
    } catch (ex) {
      if (!token) {
        m.error('Bad link. Please try again');
      } else {
        m.responseError(ex);
      }
      navigate(routes.root.path());
    }
  }, [
    toggleDelay,
    supervise,
    token,
    login,
    dispatch,
    toggleTokenReceived,
    m,
    navigate,
  ]);

  // Start supervise login process with a little delay to make the progress more noticeable
  useEffect(() => {
    if (!delay) {
      return;
    }
    // Clear api state, logout if was logged in previously
    clear();
    logout();

    const tid = setTimeout(() => {
      // Start supervise authorization process
      handleAuthorize();
    }, 1_000);

    return () => {
      clearTimeout(tid);
    };
  }, [handleAuthorize, delay, clear, logout]);

  return (
    <Box flexGrow={1} display="flex" flexDirection="column">
      <Stack spacing={0} alignItems="center" mt={3}>
        <Typography component="h1" variant="subtitle1" fontSize={32}>
          Authorizing
        </Typography>

        <Typography variant="subtitle5">Please wait a little</Typography>
      </Stack>

      <ProgressBox
        width={1}
        height={1}
        flexGrow={1}
        progress={loading}
        size={130}
      />
    </Box>
  );
};

export default Supervise;
