import { useCallback, useMemo } from 'react';

import { getFunc } from 'utils';
import { useAddCompanyNoteMutation, useUpdateCompanyNoteMutation } from 'store';
import { EditCompanyNoteForm } from 'views';
import { Modal, HTMLEditor, useMessage } from 'components';

const EditNoteModal = (props) => {
  const { open, note, group, onClose, company_id, ...rest } = props;

  const m = useMessage();
  const [createNote, createNoteState] = useAddCompanyNoteMutation();
  const [updateNote, updateNoteState] = useUpdateCompanyNoteMutation();

  const loading = createNoteState.isLoading || updateNoteState.isLoading;

  const title = useMemo(() => (note ? 'Edit Note' : 'Add Note'), [note]);

  const handleClose = useCallback(
    (...args) => {
      getFunc(onClose)(...args);
    },
    [onClose]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      const handler = note ? updateNote : createNote;

      try {
        const { text, ...restData } = formData;
        const body = {
          ...restData,
          group: note?.group || group,
        };
        const args = { company_id };
        body.text = HTMLEditor.stateToHtml(text);

        if (note) {
          args.note_id = note.id;
        }
        await handler({ ...args, body }).unwrap();
        m.success(`Note has been ${note ? 'updated' : 'created'}!`);
        handleClose();
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, updateNote, createNote, note, company_id, handleClose, group]
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={title}
      minWidth={644}
      maxWidth={644}
      {...rest}
    >
      <EditCompanyNoteForm
        pt={2}
        note={note}
        reset={!!open}
        loading={loading}
        onSubmit={handleSubmit}
        onCancel={handleClose}
      />
    </Modal>
  );
};

export default EditNoteModal;
