import { useMemo } from 'react';

import { getArray } from 'utils';
import { useGetProjectDetailsQuery } from 'store';

const def = {};

const useGetProjectStringsAndWires = (props = def) => {
  const { project_id } = props;

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });
  const loading = isFetching;

  const strings = useMemo(() => {
    return getArray(project?.systems).reduce((res, sys) => {
      if (!Array.isArray(sys.strings)) {
        const mpptStrings = Object.values(sys.strings).reduce(
          (all, branches) => {
            const branchStrings = branches.map((n, i) => ({
              count: n,
              id: `string-${res.length + all.length + i}`,
              text: (10 + res.length + all.length + i)
                .toString(36)
                .toUpperCase(),
            }));
            return [...all, ...branchStrings];
          },
          []
        );
        return [...res, ...mpptStrings];
      }
      const systemStrings = sys.strings.map((c, i) => ({
        id: `string-${res.length + i}`,
        text: (10 + res.length + i).toString(36).toUpperCase(),
        count: c,
      }));
      return [...res, ...systemStrings];
    }, []);
  }, [project]);

  return useMemo(() => [strings, loading], [loading, strings]);
};

export default useGetProjectStringsAndWires;
