import { forwardRef, useMemo } from 'react';
import { Typography } from '@mui/material';

const Text = forwardRef((props, ref) => {
  const { sx, lines, ...rest } = props;

  const customSx = useMemo(() => {
    if (Number.isFinite(lines) && lines > 0) {
      return {
        display: '-webkit-inline-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: lines,
      };
    }
    return {};
  }, [lines]);

  return (
    <Typography
      ref={ref}
      sx={{
        ...sx,
        ...customSx,
      }}
      {...rest}
    />
  );
});

export default Text;
