import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Box, Stack } from '@mui/material';

import { ProjectSubmitButton } from 'views';
import { ProgressBox, Text } from 'components';

import PreviewProjectItem from './PreviewProjectItem';

const PreviewProjectForm = () => {
  const { control, loading, isFirstSaving, onPageClick, selectedPage } =
    useFormContext();

  const { fields, move } = useFieldArray({
    control,
    name: 'metadata',
    keyName: 'field_id',
  });

  const handleDragEnd = useCallback(
    (options) => {
      const { destination, source } = options;

      if (!destination) {
        return;
      }
      move(source.index, destination.index);
    },
    [move]
  );

  return (
    <ProgressBox progress={loading} minHeight={100}>
      <Text variant="subtitle4">Sheets</Text>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="metadata">
          {(provided) => {
            return (
              <Stack
                mt={3}
                spacing={2}
                ref={provided.innerRef}
                sx={{ position: 'relative' }}
                {...provided.droppableProps}
              >
                {fields.map((field, index) => {
                  const { field_id, ...page } = field;

                  return (
                    <PreviewProjectItem
                      key={field_id}
                      page={page}
                      index={index}
                      name={`metadata.${index}`}
                      selected={page.page_number === selectedPage}
                      onClick={() => onPageClick(page.page_number)}
                    />
                  );
                })}

                {provided.placeholder}
              </Stack>
            );
          }}
        </Droppable>
      </DragDropContext>

      <Box mt={4}>
        <ProjectSubmitButton
          label="Save"
          allowContinue={false}
          isFirstSaving={isFirstSaving}
        />
      </Box>
    </ProgressBox>
  );
};

export default PreviewProjectForm;
