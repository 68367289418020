import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Droppable } from 'react-beautiful-dnd';
import { Box, Stack, alpha, styled, useTheme } from '@mui/material';

import { Center, FormLabel, Slip } from 'components';
import StructureCard from './StructureCard';

const Root = styled(Slip)(({ theme }) => ({
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.short,
  }),
}));

const StructureField = (props) => {
  const { label, arrays: ids, dragType, id } = props;
  const { arrays } = useFormContext();
  const theme = useTheme();

  const structureArrays = useMemo(() => {
    return ids
      .map((id) => {
        return arrays.find((arr) => arr.id === id);
      })
      .filter(Boolean);
  }, [arrays, ids]);

  const type = structureArrays?.[0]?.panel_type;

  const highlightColor = useMemo(() => {
    return dragType === type || (dragType && !type)
      ? alpha(theme.palette.success.light, 0.3)
      : undefined;
  }, [theme, dragType, type]);

  const allowDrop = !(dragType && type && type !== dragType);

  return (
    <Droppable droppableId={id} isDropDisabled={!allowDrop}>
      {(provided, { isDraggingOver }) => (
        <Root
          elevation={0}
          bgcolor={
            isDraggingOver
              ? alpha(theme.palette.success.light, 0.7)
              : highlightColor
          }
        >
          <Box my={0.5}>
            <FormLabel>
              <Box color="primary.light">{label}</Box>
            </FormLabel>

            <Stack
              spacing={1}
              mt={1}
              position="relative"
              minHeight={64}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {structureArrays.map((arr, i) => (
                <StructureCard {...arr} key={arr.id} index={i} />
              ))}

              {structureArrays.length === 0 && (
                <Slip
                  width={1}
                  elevation={0}
                  position="absolute"
                  minHeight={64}
                  bgcolor="colors.background_dark"
                >
                  <Center p={2}>
                    <FormLabel>
                      Add arrays to assign them to the structure
                    </FormLabel>
                  </Center>
                </Slip>
              )}

              {provided.placeholder}
            </Stack>
          </Box>
        </Root>
      )}
    </Droppable>
  );
};

export default StructureField;
