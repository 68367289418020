import { useMemo } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { getArray } from 'utils';
import { SYSTEM_TYPES_NAMES } from 'constants';

const SystemDescription = (props) => {
  const { prefix, wte, optimizers, panel, inverter } = props;

  const { control } = useFormContext();

  const system_type = useWatch({ control, name: `${prefix}.system_type` });
  const optimizer_id = useWatch({ control, name: `${prefix}.optimizer_id` });
  const wte_id = useWatch({ control, name: `${prefix}.wte_id` });

  const optimizer = useMemo(
    () => getArray(optimizers).find((opt) => opt.id === optimizer_id),
    [optimizer_id, optimizers]
  );

  const wteItem = useMemo(
    () => getArray(wte).find((w) => w.id === wte_id),
    [wte_id, wte]
  );

  const description = useMemo(() => {
    return [
      `${SYSTEM_TYPES_NAMES[system_type]} System`,
      panel?.label,
      optimizer && `${optimizer.vendor.title} - ${optimizer.title}`,
      inverter?.label,
      wteItem && `${wteItem.vendor.title} - ${wteItem.title}`,
    ]
      .filter(Boolean)
      .join('; ');
  }, [system_type, panel, optimizer, inverter, wteItem]);

  return <Box mt={1}>{description}</Box>;
};

export default SystemDescription;
