import { dev } from 'constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm as useReactHookForm } from 'react-hook-form';

const defProps = {};

const useForm = (props = defProps) => {
  const { resetDelay = 0, ...rest } = props;

  const timeoutRef = useRef(null);
  const [progress, setProgress] = useState(false);

  const form = useReactHookForm({
    mode: 'onTouched',
    shouldUnregister: false,
    ...rest,
  });
  const originalReset = form.reset;

  useEffect(() => {
    if (dev) {
      window.d4pv.debugForm = form.getValues;
    }
    return () => {
      if (dev) {
        delete window.d4pv.debugForm;
      }
    };
  }, [form.getValues]);

  // Because of using complex forms, reset works bad if needed
  // fields has not rendered in time. Make a little delay before
  // reset form to avoid invalid values setting up.
  const reset = useCallback(
    async (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (!resetDelay) {
        return originalReset(...args);
      }
      setProgress(true);

      timeoutRef.current = setTimeout(() => {
        setProgress(false);
        originalReset(...args);
      }, resetDelay);
    },
    [resetDelay, originalReset]
  );

  form.reset = reset;
  form.progress = progress;
  return form;
};

export default useForm;
