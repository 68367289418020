const baseFontSize = 14;

export const fontSize = 14;

export const htmlFontSize = 16;

const coef = fontSize / baseFontSize;

export const pxToRem = (size) => `${(size / htmlFontSize) * coef}rem`;

export const body1 = {
  fontSize: pxToRem(13),
  fontWeight: 500,
  lineHeight: 1.38,
  letterSpacing: '-0.013em',
};

export const body2 = {
  fontSize: pxToRem(13),
  fontWeight: 600,
  lineHeight: 1.38,
  letterSpacing: '-0.013em',
};

export const body3 = {
  fontSize: pxToRem(12),
  fontWeight: 500,
  lineHeight: 1.33,
  letterSpacing: '-0.012em',
};

export const body4 = {
  fontSize: pxToRem(14),
  fontWeight: 400,
};

export const subtitle1 = {
  fontSize: pxToRem(20),
  fontWeight: 700,
  lineHeight: 1.6,
};

export const subtitle2 = {
  fontSize: pxToRem(16),
  fontWeight: 700,
  lineHeight: 1.5,
};

export const subtitle3 = {
  fontSize: pxToRem(16),
  fontWeight: 600,
  lineHeight: 1.13,
};

export const subtitle4 = {
  fontSize: pxToRem(14),
  fontWeight: 700,
};

export const subtitle5 = {
  fontSize: pxToRem(12),
  fontWeight: 700,
};

export const button = {
  fontSize: pxToRem(15),
  fontWeight: 600,
  lineHeight: 1.33,
  textTransform: 'none',
};

export const caption = {
  fontSize: pxToRem(11),
  fontWeight: 400,
  letterSpacing: '-0.01em',
};

export const form_caption = {
  fontSize: pxToRem(10),
  fontWeight: 400,
  letterSpacing: '-0.015em',
};

export const table_caption = {
  fontSize: pxToRem(9),
  fontWeight: 600,
  letterSpacing: '-0.01em',
};
