import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  useGetProjectDetailsQuery,
  useUpdateProjectsArraysMutation,
} from 'store';

import { routes } from 'routes';
import { getArray, getObject } from 'utils';
import { property_image_width, property_image_height } from 'constants';
import { useAuth } from 'hooks';
import { ProjectDetailsLayout } from 'layouts';
import { LayersPreview, ProjectPreview, ProjectSubmitButton } from 'views';
import { Center, Form, Image, ProgressBox, useMessage } from 'components';

import ArraysForm from './ArraysForm';

const ArraysParameters = () => {
  const { project_id } = useParams();
  const navigate = useNavigate();
  const m = useMessage();
  const form = Form.useForm();
  const { premium } = useAuth();

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });
  const [update, { isLoading }] = useUpdateProjectsArraysMutation();

  const loading = isFetching || isLoading;
  const isFirstSaving = getArray(project?.arrays).length === 0;
  const nextStep = useMemo(
    () => routes.pv_system.path({ project_id }),
    [project_id]
  );

  const layers = useMemo(() => {
    return Object.values(getObject(project?.property_plan)).map(
      (l) => `${l}?${Date.now()}`
    );
  }, [project]);

  useEffect(() => {
    form.reset({
      arrays: getArray(project?.arrays),
    });
  }, [form, project]);

  const handleSubmit = useCallback(
    async (body) => {
      try {
        await update({ project_id, body }).unwrap();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [isFirstSaving, m, navigate, nextStep, update, project_id]
  );

  return (
    <ProjectDetailsLayout
      form={form}
      component={Form}
      title="Arrays Parameters"
      onSubmit={handleSubmit}
      body={
        <ProgressBox progress={loading} m={-3} pt={1}>
          <ArraysForm />

          <Box mt={4} mx={3}>
            <ProjectSubmitButton
              nextStep={nextStep}
              isFirstSaving={isFirstSaving}
            />
          </Box>
        </ProgressBox>
      }
    >
      <ProjectPreview>
        {premium && <LayersPreview layers={layers} />}

        {!premium && (
          <Center flexGrow={1}>
            <Image
              src={project?.property_image}
              width={property_image_width}
              height={property_image_height}
            />
          </Center>
        )}
      </ProjectPreview>
    </ProjectDetailsLayout>
  );
};

export default ArraysParameters;
