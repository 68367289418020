import { forwardRef, lazy, useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import usePagination from '@mui/material/usePagination';

import { dev } from 'constants';
import { getFunc } from 'utils';
import TinyButton from 'components/TinyButton';
import Icon from 'components/Icon';

const Pagination = forwardRef((props, ref) => {
  const { page = 1, count = 1, onValue, onChange, boundaryCount = 3 } = props;

  const handleChange = useCallback(
    (e, v) => {
      getFunc(onChange)(e, v);
      getFunc(onValue)(v);
    },
    [onChange, onValue]
  );

  const { items } = usePagination({
    page,
    count,
    boundaryCount,
    onChange: handleChange,
  });
  return (
    <Box>
      <Stack direction="row" spacing={1}>
        {items.map((item, i) => {
          const { disabled, type, page, selected, onClick } = item;

          const buttonProps = {
            key: i,
            onClick,
            disabled,
            children: page,
            variant: 'flat',
            color: 'secondary',
          };
          if (page === null) {
            buttonProps.variant = 'transparent';
            buttonProps.children = '...';
          }
          if (selected) {
            buttonProps.variant = 'contained';
          }
          if (['previous', 'next'].includes(type)) {
            buttonProps.variant = 'transparent';

            if (type === 'previous') {
              buttonProps.children = <Icon.ArrowBack fontSize="xsmall" />;
            }
            if (type === 'next') {
              buttonProps.children = <Icon.ArrowBack fontSize="xsmall" flipX />;
            }
          }
          return <TinyButton {...buttonProps} />;
        })}
      </Stack>
    </Box>
  );
});

if (dev) {
  Pagination.Demo = lazy(() => import('./Pagination.demo'));
}

export default Pagination;
