import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { getArray, getFunc } from 'utils';
import routesTree, { routes } from './routes';
import ProtectedOutlet from './ProtectedOutlet';

const getCustomRoutes = (customRoutes) => {
  const result = getArray(customRoutes).map((route, i) => {
    const { index, path: buildPath, id } = route;

    const path = getFunc(buildPath)();
    const fullPath = id ? routes[id].path() : undefined;
    const nativeChildren = getCustomRoutes(route.children);

    return (
      <Route
        key={i}
        path={path}
        index={index}
        element={<ProtectedOutlet {...route} fullPath={fullPath} />}
      >
        {nativeChildren}
      </Route>
    );
  });
  if (result.length === 0) {
    return null;
  }
  return result;
};

const useCustomRoutes = (customRoutes) => {
  return useMemo(() => getCustomRoutes(customRoutes), [customRoutes]);
};

const ProjectRoutes = (props) => {
  const { children } = props;
  const content = useCustomRoutes(routesTree);

  return (
    <>
      <Routes>{content}</Routes>
      {children}
    </>
  );
};

export default ProjectRoutes;
