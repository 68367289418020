import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { PaperCanvas, ProjectPreview } from 'views';
import { ProgressBox } from 'components';

import Controller from './structures.controller';

const StructuresPreview = () => {
  const { loading, diagramRef, onReady } = useFormContext();

  return (
    <ProjectPreview>
      <ProgressBox height={1} progress={loading}>
        <Box height={1} p={5} overflow="auto">
          <PaperCanvas
            diagramRef={diagramRef}
            controller={Controller}
            onReady={onReady}
          />
        </Box>
      </ProgressBox>
    </ProjectPreview>
  );
};

export default StructuresPreview;
