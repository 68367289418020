import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  memo,
  useImperativeHandle,
  useMemo,
  useCallback,
  lazy,
} from 'react';

import Paper from 'paper';
import { Box, useTheme } from '@mui/material';

import { diagram_width, diagram_height, dev } from 'constants';
import { getFunc, getString, sleep } from 'utils';
import { useToggle } from 'hooks';

/**
 * Backend svglib does not convert paperjs exported svg to pdf as expected. The main
 * reason is:
 *
 * 1. visibility="hidden" attribute ignored
 * To solve this elements with this attribute simply removed from svg.
 *
 * 2. svglib ignores symbols viewBox attribute
 * To solve this viewBox removed from symbols, first 2 viewBox attribute values
 * added to "x" and "y" coordinates of symbol consumers. Also, overflow="visible"
 * added to symbols.
 */
const parseSvg = (element) => {
  const hiddenElements = Array.from(
    element.querySelectorAll('[visibility="hidden"]')
  );
  const symbols = Array.from(element.querySelectorAll('symbol'));
  const offsets = {};

  hiddenElements.forEach((el) => {
    el.remove();
  });
  symbols.forEach((sym) => {
    const id = sym.getAttribute('id');
    const viewBox = sym.getAttribute('viewBox');
    sym.removeAttribute('viewBox');
    sym.setAttribute('overflow', 'visible');

    if (id && viewBox) {
      const [x, y] = getString(viewBox)
        .split(',')
        .slice(0, 2)
        .map((c) => {
          const v = Number.parseFloat(c);
          return Number.isFinite(v) ? v : 0;
        });
      offsets[id] = { x, y };
    }
  });
  Object.entries(offsets).forEach(([id, { x, y }]) => {
    const targets = Array.from(element.querySelectorAll(`[*|href="#${id}"]`));

    targets.forEach((target) => {
      const nativeX = Number.parseFloat(target.getAttribute('x') || 0);
      const nativeY = Number.parseFloat(target.getAttribute('y') || 0);
      const curX = Number.isFinite(nativeX) ? nativeX : 0;
      const curY = Number.isFinite(nativeY) ? nativeY : 0;
      const newX = String(curX - x);
      const newY = String(curY - y);

      target.setAttribute('x', newX);
      target.setAttribute('y', newY);
    });
  });
  return element;
};

const CanvasActor = memo(
  forwardRef((props, ref) => {
    const { children, callback } = props;
    const [inited, toggleInited] = useToggle();
    const rootRef = useRef(null);

    useEffect(() => {
      if (rootRef.current && !inited) {
        toggleInited.on();
        callback(rootRef.current);
      }
    }, [callback, inited, toggleInited]);

    return <Box ref={rootRef}>{children}</Box>;
  })
);

const CanvasStage = memo(
  forwardRef((props, ref) => {
    const [actors, setActors] = useState([]);

    const addActor = useCallback((newActor) => {
      setActors((prev) => [...prev, newActor]);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        addActor,
      }),
      [addActor]
    );

    return (
      <Box width={0} height={0} display="none">
        {actors.map((act, i) => (
          <CanvasActor key={act.id || i} {...act} />
        ))}
      </Box>
    );
  })
);

const PaperCanvas = memo(
  forwardRef((props, ref) => {
    const {
      controller: Controller,
      diagramRef,
      onReady,
      children,
      options,
      ...rest
    } = props;

    const [inited, setInited] = useState(false);
    const [scope, setScope] = useState(null);
    const [controller, setController] = useState(null);
    const [fontInitializer, setFontInitializer] = useState(null);
    const cursorsRef = useRef([]);
    const stageRef = useRef();

    const theme = useTheme();
    const canvasRef = useRef(null);

    const handleAddCursor = useCallback((type) => {
      const { current: canvasElement } = canvasRef;
      const newId = `${Date.now()}-${Math.ceil(Math.random() * 100000)}`;

      cursorsRef.current.push({
        id: newId,
        cursor: type,
      });
      canvasElement.style.cursor = type;
      return newId;
    }, []);

    const handleRemoveCursor = useCallback((id) => {
      const { current: canvasElement } = canvasRef;
      const result = cursorsRef.current.filter((c) => c.id !== id);
      cursorsRef.current = result;

      if (canvasElement) {
        canvasElement.style.cursor =
          result[result.length - 1]?.cursor || 'default';
      }
    }, []);

    const context = useMemo(
      () => ({
        addCursor: handleAddCursor,
        removeCursor: handleRemoveCursor,
      }),
      [handleAddCursor, handleRemoveCursor]
    );

    useEffect(() => {
      const { current: canvasElement } = canvasRef;

      if (canvasElement) {
        const newScope = Paper.setup(canvasElement);
        newScope.settings.insertItems = false;
        newScope.settings.applyMatrix = false;
        setScope(newScope);

        return () => {
          newScope.project.remove();
          setScope(null);
        };
      }
    }, []);

    useEffect(() => {
      if (scope && !inited && !fontInitializer) {
        const copymark1 = new Paper.PointText({
          content: 'Init fonts',
          fontSize: 5,
          fontFamily: 'ArialNarrow',
          fillColor: 'black',
        });
        const copymark2 = new Paper.PointText({
          content: 'Init fonts',
          fontSize: 5,
          fontFamily: 'ArialNarrow',
          fillColor: 'black',
          fontWeight: 'bold',
        });
        setFontInitializer([copymark1, copymark2]);
      }
    }, [fontInitializer, inited, scope]);

    useEffect(() => {
      if (scope && fontInitializer && !inited) {
        scope.project.activeLayer.addChildren(fontInitializer);

        const id = setTimeout(() => {
          scope.project.activeLayer.removeChildren();
          setInited(true);
        }, 100);

        return () => {
          clearTimeout(id);
          scope.project.activeLayer.removeChildren();
        };
      }
    }, [fontInitializer, inited, scope]);

    useEffect(() => {
      if (Controller && scope && inited) {
        const newController = new Controller({
          scope,
          theme,
          context,
          stage: stageRef,
          ...options,
        });
        setController(newController);

        return () => {
          newController.destroy();
          setController(null);
        };
      }
    }, [Controller, scope, theme, inited, context, options]);

    useEffect(() => {
      if (controller) {
        getFunc(onReady)(controller);
      }
    }, [controller, onReady]);

    useImperativeHandle(
      diagramRef,
      () => ({
        scope,
        controller,
        stage: stageRef,
        canvas: canvasRef.current,
        exportSvg: async () => {
          await sleep(200);
          const result = await scope.project.exportSVG();
          return parseSvg(result);
        },
      }),
      [controller, scope]
    );

    return (
      <Box
        ref={ref}
        bgcolor="common.white"
        width={diagram_width + 2}
        height={diagram_height + 2}
        border={`1px dotted ${theme.palette.border.light}`}
        {...rest}
      >
        <CanvasStage ref={stageRef} />

        <Box width={1} height={1} ref={canvasRef} component="canvas" />

        {children}
      </Box>
    );
  })
);

if (dev) {
  PaperCanvas.Demo = lazy(() => import('./PaperCanvas.demo'));
}

export default PaperCanvas;
