import { Group, Path } from 'paper';

import { feetToString, getFunc } from 'utils';
import PaperItem from 'components/Paper/PaperItem';
import PaperAnchor from 'components/Paper/PaperAnchor';
import PaperContentBox from 'components/Paper/PaperContentBox';
import PaperText from 'components/Paper/PaperText';
import PaperArrow from 'components/Paper/PaperArrow';

const defaultPadding = 15;
const my = 6;

const arrowSize = 15;
const arrowGap = 10;
const emptyGap = 20;

const defaultLabelWidth = 60;
const defaultLabelHeight = 26;

class PaperRuler extends PaperItem {
  constructor(options) {
    const { data, stickTo, sticking, angleAlignment, ...opts } = options;
    const element = new Group({ name: 'ruler_group' });

    super({
      element,
      name: 'paper-ruler',
      ...opts,
    });
    this.disableDragging();

    this.onStartAnchorDragStart = this.onStartAnchorDragStart.bind(this);
    this.onEndAnchorDragStart = this.onEndAnchorDragStart.bind(this);
    this.onStartAnchorMove = this.onStartAnchorMove.bind(this);
    this.onEndAnchorMove = this.onEndAnchorMove.bind(this);
    this.onHeightAnchorMove = this.onHeightAnchorMove.bind(this);
    this.onPositionAnchorMove = this.onPositionAnchorMove.bind(this);
    this.onHeightAnchorDrop = this.onHeightAnchorDrop.bind(this);
    this.onPositionAnchorDrop = this.onPositionAnchorDrop.bind(this);
    this.onAxisAnchorDrop = this.onAxisAnchorDrop.bind(this);
    this.onDragStart = this.onDragStart.bind(this);

    this.stickTo = stickTo;
    this.debug = false;
    this.debugs = [];
    this.logs = [];
    this.lines = [];
    this.anchor_drag = false;
    this.label_anchor_drag = false;
    this.sticking = sticking;
    this.angleAlignment = angleAlignment;

    this.data = {
      ...data,
      type: 'ruler',
      height: data.height || 0,
      start: !data.start
        ? null
        : {
            x: data.start.x || 0,
            y: data.start.y || 0,
          },
      end: !data.end
        ? null
        : {
            x: data.end.x || 0,
            y: data.end.y || 0,
          },
      label: {
        anchor: data?.label?.anchor || 'center',
        position: data?.label?.position || 0.5,
        text: data?.label?.text || null,
      },
    };
    this.isNew = !data.start || !data.end;
    this.build();
  }

  build() {
    this.startPoint = this.point(this.data.start);
    this.endPoint = this.point(this.data.end);
    this.height = this.data.height;
    this.label = this.data.label.text;
    this.anchor = this.data.label.anchor;
    this.position = this.data.label.position;
    this.axisAngle = 0;

    this.startAnchor = new PaperAnchor({
      ...this.options,
      data: this.startPoint,
      name: 'start-anchor',
      sticking: this.sticking,
      stickTo: this.stickTo,
      onDrag: this.onStartAnchorMove,
      onDragStart: this.onStartAnchorDragStart,
      onDrop: this.onAxisAnchorDrop,
    });
    this.endAnchor = new PaperAnchor({
      ...this.options,
      data: this.endPoint,
      name: 'end-anchor',
      sticking: this.sticking,
      stickTo: this.stickTo,
      onDrag: this.onEndAnchorMove,
      onDragStart: this.onEndAnchorDragStart,
      onDrop: this.onAxisAnchorDrop,
    });
    this.axis = new Path({
      segments: [this.startPoint, this.endPoint],
    });
    if (this.isNew) {
      this.startAnchor.hide();
      this.disableDragging();
      this.endAnchor.hide();
      this.startAnchor.disableDragging();
      this.endAnchor.disableDragging();
      this.axis.visible = false;
    }
    this.labelAxis = new Path({
      segments: [this.startPoint, this.endPoint],
      strokeWidth: 1,
      strokeColor: 'green',
      dashArray: [1, 5],
      visible: this.debug,
    });
    this.labelText = new PaperText({
      fontSize: 14,
      content: this.data.label.text,
    });

    this.labelBox = new PaperContentBox({
      ...this.options,
      data: {
        x: 50,
        y: 50,
        width: defaultLabelWidth,
        height: defaultLabelHeight,
        visible: false,
      },
    });
    this.labelBox.update();
    this.labelBox.disableDragging();
    this.labelContainer = this.labelBox.children.note_rect;

    this.labelSize = new Path({
      segments: [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
      ],
      closet: true,
      strokeWidth: 1,
      strokeColor: 'green',
      visible: this.debug,
      dashArray: [3, 3],
    });
    this.labelBox.element.addChildren([this.labelSize, this.labelText]);

    this.heightAnchor = new PaperAnchor({
      ...this.options,
      name: 'height-anchor',
      data: this.labelContainer.parent.localToGlobal(
        this.labelContainer.bounds.leftCenter
      ),
      onDragStart: this.onDragStart,
      onDrag: this.onHeightAnchorMove,
      onDrop: this.onHeightAnchorDrop,
    });
    this.positionAnchor = new PaperAnchor({
      ...this.options,
      name: 'position-anchor',
      data: this.labelContainer.parent.localToGlobal(
        this.labelContainer.bounds.topCenter
      ),
      onDragStart: this.onDragStart,
      onDrag: this.onPositionAnchorMove,
      onDrop: this.onPositionAnchorDrop,
    });

    if (this.isNew) {
      this.heightAnchor.hide();
      this.positionAnchor.hide();
      this.heightAnchor.disableDragging();
      this.positionAnchor.disableDragging();
    }
    this.element.addChildren([
      this.startAnchor.element,
      this.endAnchor.element,
      this.axis,
      this.labelAxis,
      this.labelBox.element,
      this.heightAnchor.element,
      this.positionAnchor.element,
    ]);
    this.label = this.data.label.text || '';
    this.adjustLabelSize();

    if (!this.isNew) {
      this.render();
    }
  }

  render() {
    if (this.debug) {
      console.clear();
      this.debugs.forEach((d) => d.remove());
      this.logs.forEach((l) => console.log(l));
      this.logs = [];
      this.debugs = [];
    }
    this.labelBox.show();

    this.axis.segments[0].point = this.startPoint;
    this.axis.segments[1].point = this.endPoint;
    this.axisLength = this.startPoint.getDistance(this.endPoint);

    this.updateLabelAxis();
    this.updateLabel();
    this.updateLines();

    if (this.debug) {
      this.debugs.forEach((d) => {
        this.element.addChild(d);
      });
    }
  }

  updateLines() {
    const start = this.labelAxis.segments[0].point;
    const end = this.labelAxis.segments[1].point;
    const line = this.getLabelLine();
    const [hor, vert] = this.getCurrentPlacement();

    this.lines.forEach((l) => l.remove());
    this.lines = [];

    const axisOpts = {
      strokeWidth: 1,
      strokeColor: this.theme.palette.paper.secondary,
    };
    if (this.isNew && !line) {
      return;
    }
    if (this.labelOffsetAnchor === 'center') {
      const p1 = line.segments[0].point;
      const p2 = line.segments[1].point;

      const d1 = start.getDistance(p1);
      const d2 = start.getDistance(p2);

      const s = d1 > d2 ? p2 : p1;
      const e = d1 > d2 ? p1 : p2;

      const axisLine1 = new Path({
        segments: [s, start],
        ...axisOpts,
      });
      const axisLine2 = new Path({
        segments: [e, end],
        ...axisOpts,
      });
      const arrow1 = new PaperArrow({
        color: axisOpts.strokeColor,
        from: start,
        to: end,
      });
      const arrow2 = new PaperArrow({
        color: axisOpts.strokeColor,
        from: end,
        to: start,
      });
      this.lines.push(axisLine1, axisLine2, arrow1.element, arrow2.element);
      this.element.addChildren(this.lines);
    } else {
      const axisLine = new Path({
        segments: [start, end],
        ...axisOpts,
      });
      this.lines.push(axisLine);
      this.element.addChild(axisLine);

      if (hor === 'center' && vert === 'center') {
        const o1 = this.labelOffsetAnchor === 'start' ? start : end;
        const e1 = this.labelOffsetAnchor === 'start' ? end : start;

        const o2 = this.getClosestPoint(
          o1,
          line.segments[0].point,
          line.segments[1].point
        );
        const e2 = this.getPointOnDistance(
          o1,
          e1,
          arrowSize + arrowGap + emptyGap
        );

        const fromLabelToAxis = new Path({
          segments: [o1, o2],
          ...axisOpts,
        });
        const emptyTail = new Path({
          segments: [e1, e2],
          ...axisOpts,
        });
        const arrow1 = new PaperArrow({
          color: axisOpts.strokeColor,
          from: o2,
          to: o1,
        });
        const arrow2 = new PaperArrow({
          color: axisOpts.strokeColor,
          from: e2,
          to: e1,
        });
        this.lines.push(
          fromLabelToAxis,
          emptyTail,
          arrow1.element,
          arrow2.element
        );
      } else {
        const [, s2, s3] = this.labelSize.segments.map((s) =>
          this.labelSize.localToGlobal(s.point)
        );
        const labelCorner = hor === 'left' ? s3 : s2;
        const labelLineStart = hor === 'left' ? s2 : s3;
        const ap1 = this.getClosestPoint(labelCorner, start, end);
        const ap2 = this.getFarPoint(labelCorner, start, end);
        const fp = this.getPointOnDistance(
          ap1,
          ap2,
          arrowSize + arrowGap + emptyGap
        );

        const emptyTail = new Path({
          segments: [ap2, fp],
          ...axisOpts,
        });
        const labelLineTail = new Path({
          segments: [labelLineStart, labelCorner],
          ...axisOpts,
        });
        const labelTail = new Path({
          segments: [labelCorner, ap1],
          ...axisOpts,
        });
        const arrow1 = new PaperArrow({
          color: axisOpts.strokeColor,
          from: labelCorner,
          to: ap1,
        });
        const arrow2 = new PaperArrow({
          color: axisOpts.strokeColor,
          from: fp,
          to: ap2,
        });
        this.lines.push(
          emptyTail,
          labelLineTail,
          labelTail,
          arrow1.element,
          arrow2.element
        );
      }
    }
    if (this.height) {
      const s = this.point(this.startPoint);
      const e = this.point(this.endPoint);

      const s1 = this.getPointOnDistance(start, s, emptyGap);
      const s2 = this.getPointOnDistance(s, start, emptyGap);
      const e1 = this.getPointOnDistance(end, e, emptyGap);
      const e2 = this.getPointOnDistance(e, end, emptyGap);

      const h1 = new Path({
        segments: [s1, s2],
        ...axisOpts,
      });
      const h2 = new Path({
        segments: [e1, e2],
        ...axisOpts,
      });
      this.lines.push(h1, h2);
    }
    this.element.addChildren(this.lines);
    this.heightAnchor.element.bringToFront();
    this.positionAnchor.element.bringToFront();
    this.startAnchor.element.bringToFront();
    this.endAnchor.element.bringToFront();
  }

  updateLabelAxis() {
    const heightAbs = Math.abs(this.height);
    const heightDirection = this.height < 0 ? -1 : 1;

    const startVector = this.vector(this.startPoint, this.endPoint);
    const endVector = this.vector(this.endPoint, this.startPoint);

    const s1 = this.point(this.startPoint);
    const e1 = this.point(this.endPoint);

    const angleEnd = 90 * heightDirection;
    const angleStart = angleEnd * -1;

    const s2 = s1.add(startVector.normalize(heightAbs).rotate(angleStart));
    const e2 = e1.add(endVector.normalize(heightAbs).rotate(angleEnd));

    this.labelAxis.segments[0].point = s2;
    this.labelAxis.segments[1].point = e2;
  }

  updateLabel() {
    // reset label angle and content
    this.labelText.content = this.label || '';
    this.labelText.rotation = 0;
    this.labelSize.rotation = 0;
    this.labelContainer.rotation = 0;

    // adjust label container size to current content
    this.labelContainer.size.width = Math.max(
      defaultLabelWidth,
      this.labelText.bounds.width + defaultPadding
    );
    this.labelContainer.size.height = Math.max(
      defaultLabelHeight,
      this.labelText.bounds.height + my * 2
    );
    this.adjustLabelSize();

    // calculate and setup actual label angle
    const labelAngle = this.getLabelAngle();
    this.labelContainer.rotation = labelAngle;
    this.labelText.rotation = labelAngle;
    this.labelSize.rotation = labelAngle;

    if (labelAngle === 0) {
      const ra1 = this.labelContainer.parent.localToGlobal(
        this.labelContainer.bounds.leftCenter
      );
      const ra2 = this.labelContainer.parent.localToGlobal(
        this.labelContainer.bounds.rightCenter
      );
      ra1.x -= this.labelContainer.size.width;
      ra2.x += this.labelContainer.size.width;

      const resetAxis = new Path({
        segments: [ra1, ra2],
        strokeWidth: 1,
        dashArray: [1, 3],
        strokeColor: 'silver',
        visible: this.debug,
      });
      const start = this.labelAxis.segments[0].point;
      const end = this.labelAxis.segments[1].point;

      const distanceToStart = ra1.getDistance(start);
      const distanceToEnd = ra1.getDistance(end);
      const close = distanceToStart > distanceToEnd ? end : start;
      const far = distanceToStart > distanceToEnd ? start : end;

      const ra1Distance = close.getDistance(ra1);
      const ra2Distance = close.getDistance(ra2);

      const maxLength = Math.max(ra1Distance, ra2Distance);
      const v = far.subtract(close);
      const p = far.subtract(v.normalize(this.axisLength + maxLength));

      const resetLine = new Path({
        segments: [close, p],
        strokeColor: 'yellow',
        strokeWidth: 1,
        dashArray: [2, 2],
        visible: this.debug,
      });
      if (this.debug) {
        this.debugs.push(resetAxis, resetLine);
      }
      const [newCenter] = resetAxis.getIntersections(resetLine);

      if (newCenter) {
        const newPoint = this.labelContainer.parent.globalToLocal(
          newCenter.point
        );
        this.labelContainer.bounds.center = newPoint;
        this.labelText.bounds.center = newPoint;
        this.labelSize.bounds.center = newPoint;
      }
    }

    // calculate and setup label possition
    const labelPoint = this.getLabelPoint();
    this.labelText.bounds.center = labelPoint;
    this.labelSize.bounds.center = labelPoint;
    this.labelContainer.bounds.center = labelPoint;

    this.adjustLabel();
    this.labelText.bounds.center = this.labelContainer.bounds.center;
    this.labelSize.bounds.center = this.labelContainer.bounds.center;

    if (!this.labelPositionAnchorMove && !this.labelHeightAnchorMove) {
      const [p0, p1, , p3] = this.labelSize.segments;

      const tl = this.labelSize.localToGlobal(p0.point);
      const tr = this.labelSize.localToGlobal(p3.point);
      const bl = this.labelSize.localToGlobal(p1.point);

      const topVector = this.vector(tl, tr);
      const leftVector = this.vector(tl, bl);

      const topCenter = tl.add(topVector.normalize(tl.getDistance(tr) / 2));
      const leftCenter = tl.add(leftVector.normalize(tl.getDistance(bl) / 2));

      this.heightAnchor.element.bounds.center = leftCenter;
      this.positionAnchor.element.bounds.center = topCenter;
    }
  }

  adjustLabel() {
    const line = this.getLabelLine();

    if (!line) {
      return;
    }
    const start = this.point(this.labelAxis.segments[0].point);
    const end = this.point(this.labelAxis.segments[1].point);
    const center = this.labelContainer.parent.localToGlobal(
      this.labelContainer.bounds.center
    );
    const distanceToStart = center.getDistance(start);
    const distanceToEnd = center.getDistance(end);

    const close = distanceToStart > distanceToEnd ? end : start;
    const far = distanceToStart > distanceToEnd ? start : end;

    const labelStart = line.segments[0].point;
    const labelEnd = line.segments[1].point;

    const labelStartToFar = far.getDistance(labelStart);
    const labelEndToFar = far.getDistance(labelEnd);

    if (this.labelOffsetAnchor !== 'center') {
      const target = labelStartToFar < labelEndToFar ? labelStart : labelEnd;
      const hw = target.getDistance(center);
      const centerToClosest = center.getDistance(close);

      if (centerToClosest - hw < arrowSize + arrowGap) {
        const vector = this.vector(far, center);
        const newPoint = close.add(vector.normalize(arrowSize + arrowGap + hw));
        const newCenter = this.labelContainer.parent.globalToLocal(newPoint);
        this.labelContainer.bounds.center = newCenter;
      }
      const [hor, vert] = this.getLabelPlacement();
      this.currentPlacement = [hor, vert];

      if (hor === 'center' && vert === 'center') {
        return;
      }
      const al1 = this.labelContainer.bounds.bottomLeft;
      const al2 = this.labelContainer.bounds.bottomRight;

      const adjustLine = new Path({
        segments: [
          this.labelContainer.parent.localToGlobal(al1),
          this.labelContainer.parent.localToGlobal(al2),
        ],
        strokeWidth: 1,
        strokeColor: 'pink',
        dashArray: [4, 3],
        visible: this.debug,
      });
      if (this.debug) {
        this.debugs.push(adjustLine);
      }
      const offset =
        this.anchor === this.labelOffsetAnchor
          ? this.position + this.labelContainer.size.width
          : arrowSize + arrowGap + this.labelContainer.size.width;

      const startVector = this.vector(start, end);
      const endVector = this.vector(end, start);
      const p = this.labelOffsetAnchor === 'start' ? start : end;
      const v = this.labelOffsetAnchor === 'start' ? startVector : endVector;
      const offsetPoint = p.add(v.normalize(offset * 2).rotate(180));

      const axisContinueLine = new Path({
        segments: [p, this.point(offsetPoint)],
        strokeWidth: 1,
        strokeColor: 'blue',
        dashArray: [2, 2],
        visible: this.debug,
      });
      if (this.debug) {
        this.debugs.push(axisContinueLine);
      }
      const [intersectPoint] = adjustLine.getIntersections(axisContinueLine);

      if (!intersectPoint) {
        return;
      }
      // move label slitly left or right to render tile later
      const newIntersectPoint = this.labelContainer.parent.globalToLocal(
        intersectPoint.point
      );
      const adjustOrigin = hor === 'left' ? 'bottomRight' : 'bottomLeft';
      this.labelContainer.bounds[adjustOrigin] = newIntersectPoint;
      return;
    }
    const targetLabelPoint =
      labelStartToFar > labelEndToFar ? labelStart : labelEnd;

    const fromFarToLabel = far.getDistance(targetLabelPoint);

    if (fromFarToLabel > this.axisLength - arrowSize - arrowGap) {
      const vector = this.vector(close, far);
      const labelLength = labelStart.getDistance(labelEnd);
      const newLength = arrowSize + arrowGap + labelLength / 2;
      const newPoint = close.add(vector.normalize(newLength));
      const np = this.labelContainer.parent.globalToLocal(newPoint);
      this.labelContainer.bounds.center = np;
    }
  }

  getCurrentPlacement() {
    if (this.currentPlacement) {
      return this.currentPlacement;
    }
    return ['center', 'center'];
  }

  getLabelPlacement() {
    let horizontal = 'center';
    let vertical = 'center';
    const aa = Math.abs(this.axisAngle);
    const ca = Math.abs(this.labelContainer.rotation);
    const notHorizontal = aa !== 0 && aa !== 180;

    if (this.labelOffsetAnchor !== 'center' && notHorizontal && ca === 0) {
      const s1 = this.labelAxis.segments[0].point;
      const s2 = this.labelAxis.segments[1].point;
      const p1 = this.labelOffsetAnchor === 'start' ? s1 : s2;
      const p2 = this.labelOffsetAnchor === 'start' ? s2 : s1;
      horizontal = p1.x > p2.x ? 'right' : 'left';
      vertical = p1.y > p2.y ? 'bottom' : 'top';
    }
    return [horizontal, vertical];
  }

  adjustLabelSize() {
    const { bounds } = this.labelContainer;
    const { segments } = this.labelSize;

    segments[0].point = bounds.topLeft;
    segments[1].point = bounds.bottomLeft;
    segments[2].point = bounds.bottomRight;
    segments[3].point = bounds.topRight;
    segments[4].point = bounds.topLeft;

    this.labelSize.bounds.center = bounds.center;
  }

  getLabelPoint() {
    const start = this.point(this.labelAxis.segments[0].point);
    const end = this.point(this.labelAxis.segments[1].point);

    const startVector = this.vector(start, end);
    const endVector = this.vector(end, start);

    // line that cross the label container by axis vector (if exists)
    const labelLine = this.getLabelLine();
    let anchor = this.anchor;

    // width of line that cross the label by axis vector (or horizontal width
    // if axis vector has no intersections with label container)
    const labelWidth = labelLine
      ? labelLine.segments[0].point.getDistance(labelLine.segments[1].point)
      : this.labelContainer.size.width;

    const half = labelWidth / 2;
    const arrowWidth = arrowSize + arrowGap;
    const fullArrowWidth = arrowWidth * 2;

    // if no enough space to place label between anchors, move it outside visually
    if (anchor === 'center' && this.axisLength < labelWidth + fullArrowWidth) {
      anchor = 'start';
    }
    this.labelOffsetAnchor = anchor;

    // calculate point according to current label position
    switch (anchor) {
      case 'start':
      case 'end': {
        // this.position means distance in pixels for outside placement
        const p = anchor === 'start' ? start : end;
        const v = anchor === 'start' ? startVector : endVector;
        const pos =
          this.anchor === 'center' ? arrowWidth + half : this.position;

        const result = p.add(v.normalize(pos).rotate(180));
        return this.labelBox.element.globalToLocal(result);
      }
      case 'center':
      default: {
        // this.position means relative position according to axis length
        let labelDistance = this.axisLength * this.position;

        if (labelDistance - half < arrowWidth) {
          labelDistance = arrowWidth + half;
        } else if (this.axisLength - (labelDistance - half) < arrowWidth) {
          labelDistance = this.axisLength - (arrowWidth + half);
        }
        const result = start.add(startVector.normalize(labelDistance));
        return this.labelBox.element.globalToLocal(result);
      }
    }
  }

  getLabelLine() {
    const start = this.labelAxis.segments[0].point;
    const end = this.labelAxis.segments[1].point;
    const { segments } = this.labelSize;

    const labelSizeSegments = segments.map((s) =>
      this.toGlobal(s.point, this.labelSize)
    );
    const labelSize = new Path({
      segments: labelSizeSegments,
    });
    const center = labelSize.bounds.center;
    const distanceToStart = center.getDistance(start);
    const distanceToEnd = center.getDistance(end);

    const far = distanceToStart > distanceToEnd ? start : end;
    const close = distanceToStart > distanceToEnd ? end : start;
    const maxLength = Math.max(distanceToStart, distanceToEnd);
    const w = this.labelContainer.size.width;
    const vector = far.subtract(close);
    vector.length = maxLength + w * 2;

    const maxPoint = close.subtract(vector);
    const axisAdjustLine = new Path({
      segments: [far, maxPoint],
      strokeWidth: 1,
      strokeColor: 'silver',
      dashArray: [1, 2],
    });
    if (this.debug) {
      this.debugs.push(axisAdjustLine);
    }
    const intersections = labelSize.getIntersections(axisAdjustLine);

    if (intersections.length === 2) {
      const result = new Path({
        segments: intersections.map((curve) => this.point(curve.point)),
        visible: this.debug,
        strokeWidth: 1,
        strokeColor: 'green',
        dashArray: [2, 2],
      });
      if (this.debug) {
        this.debugs.push(result);
      }
      return result;
    }
    return null;
  }

  getLabelText() {
    return feetToString((this.axisLength / 96) * this.data.scale);
  }

  getLabelAngle() {
    const vector = this.vector(this.startPoint, this.endPoint);
    const angle = vector?.angle || 0;
    this.axisAngle = angle;

    const direction = angle < 0 ? -1 : 1;
    const abs = Math.abs(angle);

    if (abs <= 45) {
      return angle;
    }
    if (abs >= 135) {
      return (abs + 180) * direction;
    }
    return 0;
  }

  onViewMouseMove(e) {
    super.onViewMouseMove(e);

    if (!this.isNew) {
      return;
    }
    if (!this.data.start) {
      this.initialAnchorMove(e, this.startAnchor);
    } else if (!this.data.end) {
      if (this.angleAlignment) {
        this.endAnchor.alignAngleFrom(
          this.startAnchor.element.parent.localToGlobal(
            this.startAnchor.element.bounds.center
          )
        );
      } else {
        this.endAnchor.alignAngleFrom(null);
      }
      this.initialAnchorMove(e, this.endAnchor);
      this.label = this.getLabelText();
    }
    this.startPoint = this.point(this.startAnchor.element.bounds.center);
    this.endPoint = this.point(this.endAnchor.element.bounds.center);

    if (this.data.start && !this.data.end) {
      this.render();
    }
  }

  onViewMouseLeave(e) {
    super.onViewMouseLeave(e);

    if (this.isNew) {
      if (!this.data.start) {
        this.startAnchor.hide();
      }
      if (!this.data.end) {
        this.endAnchor.hide();
      }
    }
  }

  onViewClick(e) {
    if (this.label_anchor_drag) {
      this.label_anchor_drag = false;
      return;
    }
    super.onViewClick(e);

    if (!this.data.start) {
      this.data.start = this.startAnchor.element.bounds.center;
      this.axis.visible = true;
      this.startAnchor.show();
      return;
    }
    if (!this.data.end) {
      this.data.end = this.endAnchor.element.bounds.center;
      this.startAnchor.enableDragging();
      this.endAnchor.enableDragging();
      this.heightAnchor.enableDragging();
      this.positionAnchor.enableDragging();
      this.isNew = false;
      this.select();
      getFunc(this.options?.onCreate)(this.id);
      return;
    }
  }

  onClick(e) {
    if (this.anchor_drag) {
      this.anchor_drag = false;
      return;
    }
    super.onClick(e);
  }

  onClickAway(e) {
    if (this.anchor_drag) {
      this.anchor_drag = false;
      return;
    }
    super.onClickAway(e);
  }

  onDragStart() {
    this.anchor_drag = true;
  }

  onStartAnchorDragStart() {
    this.anchor_drag = true;
    this.startAnchor.alignAngleFrom(
      !this.angleAlignment
        ? null
        : this.endAnchor.element.parent.localToGlobal(
            this.endAnchor.element.bounds.center
          )
    );
  }

  onEndAnchorDragStart() {
    this.anchor_drag = true;
    this.endAnchor.alignAngleFrom(
      !this.angleAlignment
        ? null
        : this.startAnchor.element.parent.localToGlobal(
            this.startAnchor.element.bounds.center
          )
    );
  }

  onStartAnchorMove() {
    this.label = this.getLabelText();
    this.heightAnchor.hide();
    this.positionAnchor.hide();
    this.startPoint = this.point(this.startAnchor.element.bounds.center);
    this.render();
  }

  onEndAnchorMove() {
    this.label = this.getLabelText();
    this.heightAnchor.hide();
    this.positionAnchor.hide();
    this.endPoint = this.point(this.endAnchor.element.bounds.center);
    this.render();
  }

  onAxisAnchorDrop() {
    this.heightAnchor.show();
    this.positionAnchor.show();
    this.commitData();
  }

  onHeightAnchorMove() {
    this.labelHeightAnchorMove = true;
    this.label_anchor_drag = true;
    this.startAnchor.hide();
    this.endAnchor.hide();
    this.positionAnchor.hide();

    const marker = this.heightAnchor.element.bounds.center;
    const start = this.startPoint;
    const end = this.endPoint;

    const fromTargetToStart = marker.getDistance(start);
    const fromTargetToEnd = marker.getDistance(end);
    const maxLength = Math.max(
      fromTargetToStart,
      fromTargetToEnd,
      Math.abs(this.height)
    );

    const p1 =
      maxLength === Math.abs(this.height) || maxLength === fromTargetToStart
        ? start
        : end;

    const p2 = p1 === start ? end : start;
    const vector = this.vector(p2, p1);
    vector.length = maxLength + 5;
    const p3 = p1.getDistance(p2) === maxLength ? p2 : p1.subtract(vector);

    const crossLine = new Path.Line(p1, p3);
    const crossPoint = crossLine.getNearestPoint(marker);

    const d1 = this.point(start);
    const d2 = this.point(end);
    const d3 = this.point(marker);

    const v1 = d1.subtract(d2);
    const v2 = d1.subtract(d3);
    const absoluteAngle = v1.getDirectedAngle(v2);
    const heightDirection = absoluteAngle < 0 ? 1 : -1;

    const newHeight = crossPoint.getDistance(marker) * heightDirection;
    this.height = newHeight;
    this.render();
  }

  onHeightAnchorDrop() {
    this.labelHeightAnchorMove = false;
    this.startAnchor.show();
    this.endAnchor.show();
    this.positionAnchor.show();

    if (this.height > -5 && this.height < 5) {
      this.height = 0;
    }
    this.render();
    this.commitData();
  }

  onPositionAnchorMove(e) {
    this.labelPositionAnchorMove = true;
    this.label_anchor_drag = true;
    this.startAnchor.hide();
    this.endAnchor.hide();
    this.heightAnchor.hide();

    const marker = e.point;
    const start = this.point(this.labelAxis.segments[0].point);
    const end = this.point(this.labelAxis.segments[1].point);

    const fromMarkerToStart = marker.getDistance(start);
    const fromMarkerToEnd = marker.getDistance(end);
    const maxLength = Math.max(fromMarkerToStart, fromMarkerToEnd);
    const far = fromMarkerToStart > fromMarkerToEnd ? start : end;
    const close = fromMarkerToStart > fromMarkerToEnd ? end : start;

    const vector = far.subtract(close);
    vector.length = maxLength;
    const maxPoint = close.subtract(vector);
    const crossLine = new Path([far, maxPoint]);
    const newLabelPoint = crossLine.getNearestPoint(marker);

    const farDistance = newLabelPoint.getDistance(far);
    const distanceToStart = newLabelPoint.getDistance(start);
    const distanceToEnd = newLabelPoint.getDistance(end);
    const closeAnchor = distanceToStart < distanceToEnd ? 'start' : 'end';

    const newAnchor = farDistance > this.axisLength ? closeAnchor : 'center';
    const newPosition =
      newAnchor === 'center'
        ? distanceToStart / this.axisLength
        : newAnchor === 'start'
        ? distanceToEnd - this.axisLength
        : distanceToStart - this.axisLength;

    this.position = newPosition;
    this.anchor = newAnchor;
    this.labelOffsetAnchor = null;

    this.render();
  }

  onPositionAnchorDrop() {
    this.startAnchor.show();
    this.endAnchor.show();
    this.heightAnchor.show();
    this.labelPositionAnchorMove = false;

    this.render();
    this.commitData();
  }

  commitData() {
    this.data.label.text = this.label;
    this.data.label.position = this.position;
    this.data.label.anchor = this.anchor;
    this.data.height = this.height;
    this.data.start = this.dataPoint(this.startPoint);
    this.data.end = this.dataPoint(this.endPoint);
  }

  initialAnchorMove(e, anchor) {
    const stickPoint = anchor.getStickPoint(e);
    anchor.show();
    anchor.update(stickPoint || e.point);
  }

  rename(name = this.data.label.text || '') {
    this.data.label.text = name;
    this.label = name;
    this.render();
  }

  enableSticking() {
    this.sticking = true;
    this.startAnchor.enableSticking();
    this.endAnchor.enableSticking();
  }

  disableSticking() {
    this.sticking = false;
    this.startAnchor.disableSticking();
    this.endAnchor.disableSticking();
  }

  enableAngleAlignment() {
    this.angleAlignment = true;
  }

  disableAngleAlignment() {
    this.angleAlignment = false;
  }

  select(...args) {
    this.style(this.isNew ? 'new' : 'selected');
    super.select(...args);
  }

  unselect(...args) {
    this.style();
    super.unselect(...args);
  }

  disableEdit() {
    this.unselect();
    this.labelBox.disableEdit();
  }

  enableEdit() {
    this.labelBox.enableEdit();
  }

  style(type = 'default') {
    switch (type) {
      case 'selected': {
        this.startAnchor.show();
        this.endAnchor.show();
        this.heightAnchor.show();
        this.positionAnchor.show();
        this.labelContainer.opacity = 1;
        break;
      }
      case 'new':
      case 'default':
      default: {
        this.startAnchor.hide();
        this.endAnchor.hide();
        this.heightAnchor.hide();
        this.positionAnchor.hide();
        this.labelContainer.opacity = 0.5;
        break;
      }
    }
  }

  destroy() {
    super.destroy();
  }
}

export default PaperRuler;
