import {
  forwardRef,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import cx from 'classnames';

import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { bold, underline, image } from 'assets';
import { dev } from 'constants';
import { makeClasses, useCombineRefs } from 'hooks';
import { getString, imageFileToBase64 } from 'utils';

import HelperText from 'components/HelperText';
import FormLabel from 'components/FormLabel';
import { slipStyles } from 'components/Slip';
import TextField from 'components/TextField';
import FormControl from 'components/FormControl';

const Editor = makeClasses(
  forwardRef((props, ref) => {
    const {
      classes,
      wrapperClassName,
      toolbarClassName,
      editorClassName,
      allowImages,
      ...rest
    } = props;

    const handleImageUpload = useCallback(async (file) => {
      const link = await imageFileToBase64(file);

      return {
        data: { link },
      };
    }, []);

    const toolbar = useMemo(() => {
      const options = ['inline'];

      if (allowImages) {
        options.push('image');
      }
      const result = {
        options,
        inline: {
          options: ['bold', 'underline'],
          bold: {
            icon: bold,
            className: classes.iconClassName,
          },
          underline: {
            icon: underline,
            className: classes.iconClassName,
          },
        },
        image: {
          icon: image,
          urlEnabled: false,
          previewImage: true,
          alignmentEnabled: false,
          className: classes.iconClassName,
          popupClassName: classes.imagePopupClassName,
          uploadCallback: handleImageUpload,
        },
      };
      return result;
    }, [classes, allowImages, handleImageUpload]);

    return (
      <DraftEditor
        ref={ref}
        {...rest}
        toolbar={toolbar}
        wrapperClassName={cx(wrapperClassName, classes.wrapperClassName)}
        toolbarClassName={cx(toolbarClassName, classes.toolbarClassName)}
        editorClassName={cx(editorClassName, classes.editorClassName)}
      />
    );
  })
)({
  wrapperClassName: ({ theme }) => ({
    ...slipStyles({ theme, elevation: 1 }),
  }),
  imagePopupClassName: ({ theme }) => ({
    top: 15,
    right: 15,
    left: 'unset',
    '& .rdw-image-modal-header': {
      display: 'none',
    },
    '& .rdw-image-modal-size': {
      display: 'none',
    },
    '& .rdw-image-modal-upload-option': {
      fontSize: theme.pxToRem(12),
    },
  }),
  toolbarClassName: ({ theme }) => ({
    marginBottom: 0,
    border: 'none',
    borderBottom: `1px solid ${theme.palette.border.light}`,
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      margin: 0,
    },
  }),
  editorClassName: ({ theme, textAreaMinHeight, uppercase, editorStyles }) => ({
    padding: '0px 15px',
    backgroundColor: theme.palette.common.white,
    minHeight: textAreaMinHeight,
    cursor: 'text',
    textTransform: uppercase ? 'uppercase' : undefined,
    ...editorStyles,
  }),
  iconClassName: ({ theme }) => ({
    border: 'none',
    marginRight: 0,
    marginLeft: 0,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      '& img': {
        border: `1px solid ${theme.palette.border.main}`,
      },
    },
    '& img': {
      transition: theme.transitions.create(),
      border: '1px solid transparent',
      width: 24,
      height: 24,
    },
    '&[aria-selected="true"]': {
      boxShadow: 'none',
      '& img': {
        border: `1px solid ${theme.palette.border.light}`,
        backgroundColor: theme.palette.colors.hover_background,
      },
      '&:hover': {
        '& img': {
          border: `1px solid ${theme.palette.border.main}`,
        },
      },
    },
  }),
});

export const htmlToState = (v) => {
  if (v) {
    const content = htmlToDraft(v);

    if (content) {
      const contentState = ContentState.createFromBlockArray(
        content.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
  }
  return EditorState.createEmpty();
};

export const stateToHtml = (s) => {
  return draftToHtml(convertToRaw(s.getCurrentContent()));
};

const HTMLEditor = forwardRef((props, ref) => {
  const {
    value,
    label,
    error,
    helperText,
    onChange,
    required,
    disabled,
    inputRef,
    editorRef: customEditorRef,
    fullWidth = true,
    hideAsterisk,
    InputLabelProps,
    uppercase,
    textAreaMinHeight = 133,
    reserveHelperTextSpace = true,
    initialFocus,
    editorStyles,
    allowImages,
    ...rest
  } = props;

  const editorRef = useRef(null);
  const handleEditorRef = useCombineRefs(customEditorRef, editorRef);

  useEffect(() => {
    const { current: editorElement } = editorRef;

    if (editorElement && initialFocus) {
      editorElement.focus();
    }
  }, [initialFocus]);

  return (
    <FormControl {...rest} ref={ref} fullWidth={fullWidth}>
      {label && (
        <FormLabel
          error={error}
          required={required}
          disabled={disabled}
          hideAsterisk={hideAsterisk}
          {...InputLabelProps}
        >
          {label}
        </FormLabel>
      )}
      <Editor
        allowImages={allowImages}
        editorStyles={editorStyles}
        editorRef={handleEditorRef}
        editorState={value}
        onEditorStateChange={onChange}
        uppercase={uppercase}
        textAreaMinHeight={textAreaMinHeight}
      />
      <HelperText
        error={error}
        disabled={disabled}
        reserveSpace={reserveHelperTextSpace}
      >
        {helperText}
      </HelperText>
    </FormControl>
  );
});

HTMLEditor.htmlToState = htmlToState;
HTMLEditor.stateToHtml = stateToHtml;

const contentContainer = document.createElement('div');

HTMLEditor.Control = forwardRef((props, ref) => {
  const {
    required,
    rules: customRules,
    defaultValue = htmlToState(),
    ...rest
  } = props;

  const rules = useMemo(() => {
    const result = {
      ...customRules,
      validate: { ...customRules?.validate },
    };

    if (required) {
      result.validate.required = (v) => {
        const val = v ? stateToHtml(v) : v;
        contentContainer.innerHTML = getString(val);
        const content = contentContainer.innerText.trim();
        return content.length > 0 || 'This field is required!';
      };
    }
    return result;
  }, [customRules, required]);

  return (
    <TextField.Control
      ref={ref}
      {...rest}
      rules={rules}
      required={required}
      ControlComponent={HTMLEditor}
      defaultValue={defaultValue}
    />
  );
});

if (dev) {
  HTMLEditor.Demo = lazy(() => import('./HTMLEditor.demo'));
}

export default HTMLEditor;
