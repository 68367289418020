import { useCallback, useMemo, useState } from 'react';
import { Box, IconButton as MuiIconButton, Typography } from '@mui/material';

import { routes } from 'routes';
import { useToggle, withProps } from 'hooks';
import { getArray, getAddressLabel } from 'utils';
import { useGetContractorCompaniesQuery } from 'store';
import { SearchField } from 'views';
import {
  Avatar,
  Button,
  Center,
  DataTable,
  Icon,
  Pagination,
  ProgressBox,
  Ref,
} from 'components';

import DeleteCompanyModal from './DeleteCompanyModal';

const NoLogo = withProps(Icon.StarBox, {
  sx: {
    color: 'secondary.main',
    fontSize: 32,
  },
});
const IconButton = withProps(MuiIconButton, {
  size: 'small',
  sx: { color: 'primary.main' },
});
const rowsPerPage = 10;

const Companies = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [editCompany, setEditCompany] = useState(null);
  const [deleteCompanyModal, toggleDeleteCompanyModal] = useToggle();

  const { data: companies, isFetching } = useGetContractorCompaniesQuery();

  const loading = isFetching;

  const rows = useMemo(
    () =>
      getArray(companies)
        .map((c) => ({
          ...c,
          fullAddress: getAddressLabel(c.address),
        }))
        .filter((c) => {
          if (!search) {
            return true;
          }
          const searchField = [c.name, c.phone, c.fullAddress]
            .filter(Boolean)
            .join(' ');

          return searchField
            .toLowerCase()
            .includes(search.trim().toLowerCase());
        }),
    [companies, search]
  );

  const count = Math.ceil(rows.length / rowsPerPage);

  const visibleRows = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return rows.slice(start, end);
  }, [page, rows]);

  const handleCloseDeleteCompanyModal = useCallback(() => {
    setEditCompany(null);
    toggleDeleteCompanyModal.off();
  }, [toggleDeleteCompanyModal]);

  return (
    <>
      {/* MODALS */}
      <>
        <DeleteCompanyModal
          company={editCompany}
          open={deleteCompanyModal}
          onClose={handleCloseDeleteCompanyModal}
        />
      </>

      <Box p={4} flexGrow={1} display="flex" flexDirection="column">
        <Typography variant="subtitle1">Saved Companies</Typography>

        <Box
          mt={2}
          gap={6}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            replace
            nostyles
            size="medium"
            minWidth={120}
            fullWidth={false}
            component={Ref}
            sx={{ flexShrink: 0 }}
            to={routes.add_company.path()}
          >
            Add Company
          </Button>

          <SearchField value={search} onValue={setSearch} />
        </Box>

        <ProgressBox progress={loading} mt={4} flexGrow={1}>
          <DataTable
            rows={visibleRows}
            columns={[
              {
                field: 'image',
                headerName: 'Logo',
                width: 52,
                renderCell: ({ value }) => {
                  if (!value) {
                    return <NoLogo />;
                  }
                  return (
                    <Avatar
                      src={value}
                      size="small"
                      shape="square"
                      objectFit="contain"
                      bgcolor="transparent"
                      borderColor="transparent"
                    />
                  );
                },
              },
              {
                field: 'name',
                headerName: 'Company Name',
                width: 224,
              },
              {
                field: 'phone',
                headerName: 'Company Phone',
                width: 123,
              },
              {
                field: 'fullAddress',
                headerName: 'Address',
              },
              {
                id: 'actions',
                width: 108,
                cellProps: {
                  sx: { py: 0 },
                },
                renderCell: ({ row }) => {
                  const handleDeleteCompany = () => {
                    setEditCompany(row);
                    toggleDeleteCompanyModal.on();
                  };
                  return (
                    <Box display="flex" justifyContent="flex-end" gap={1}>
                      <IconButton
                        nostyles
                        component={Ref}
                        to={routes.company.path({ company_id: row.id })}
                      >
                        <Icon.Pencil />
                      </IconButton>

                      <IconButton onClick={handleDeleteCompany}>
                        <Icon.Trash />
                      </IconButton>
                    </Box>
                  );
                },
              },
            ]}
          />

          {count > 1 && (
            <Center mt={4}>
              <Pagination page={page} onValue={setPage} count={count} />
            </Center>
          )}
        </ProgressBox>
      </Box>
    </>
  );
};

export default Companies;
