import { useMemo } from 'react';
import { Collapse } from '@mui/material';

import { getArray } from 'utils';
import { SelectField } from 'components';

const RsdField = (props) => {
  const { name, rsd, prefix, visible, ...rest } = props;

  const options = useMemo(() => {
    return getArray(rsd).map((rsd) => ({
      value: rsd.id,
      label: `${rsd.vendor.title} - ${rsd.title}`,
    }));
  }, [rsd]);

  return (
    <Collapse in={visible}>
      <SelectField.Control
        {...rest}
        options={options}
        name={`${prefix}.${name}`}
      />
    </Collapse>
  );
};

export default RsdField;
