import { lazy, useCallback } from 'react';
import {
  Typography,
  Box,
  Collapse,
  IconButton as MuiIconButton,
} from '@mui/material';

import { dev } from 'constants';
import { useToggle, withProps } from 'hooks';
import { Center, Delimiter, Icon } from 'components';

const IconButton = withProps(MuiIconButton, {
  size: 'small',
  color: 'secondary',
});
const Container = withProps(Box, {
  sx: (theme) => ({
    transition: theme.transitions.create(),
  }),
});

const FormArrayItem = (props) => {
  const { title, edit, ableRemove, onRemove, onEdit, form, description } =
    props;

  const [removed, toggleRemoved] = useToggle();

  const handleStartRemove = useCallback(() => {
    toggleRemoved.on();
  }, [toggleRemoved]);

  const handleRemove = useCallback(() => {
    if (removed) {
      onRemove();
    }
  }, [removed, onRemove]);

  return (
    <Collapse in={!removed} appear onExited={handleRemove}>
      <Container bgcolor={edit ? 'common.white' : 'colors.background_main'}>
        <Box mx={3} py={2}>
          <Center justifyContent="space-between">
            <Typography variant="subtitle4">{title}</Typography>

            <Center>
              {!edit && (
                <IconButton onClick={onEdit}>
                  <Icon.Pencil />
                </IconButton>
              )}

              <IconButton onClick={handleStartRemove} disabled={!ableRemove}>
                <Icon.Close />
              </IconButton>
            </Center>
          </Center>

          <Collapse in={edit}>{form}</Collapse>

          <Collapse in={!edit}>{description}</Collapse>
        </Box>

        {!edit && (
          <Box px={3}>
            <Delimiter />
          </Box>
        )}
      </Container>
    </Collapse>
  );
};

if (dev) {
  FormArrayItem.Demo = lazy(() => import('./FormArrayItem.demo'));
}

export default FormArrayItem;
