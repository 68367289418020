import { useCallback, useMemo } from 'react';

import { getFunc } from 'utils';
import {
  useAddCompanyRequirementMutation,
  useUpdateCompanyRequirementMutation,
} from 'store';
import { EditCompanyNoteForm } from 'views';
import { Modal, HTMLEditor, useMessage } from 'components';

const EditRequirementModal = (props) => {
  const { open, note, group, onClose, company_id, ...rest } = props;

  const m = useMessage();
  const [createRequirement, createRequirementState] =
    useAddCompanyRequirementMutation();

  const [updateRequirement, updateRequirementState] =
    useUpdateCompanyRequirementMutation();

  const loading =
    createRequirementState.isLoading || updateRequirementState.isLoading;

  const title = useMemo(
    () => (note ? 'Edit Requirement' : 'Add Requirement'),
    [note]
  );

  const handleClose = useCallback(
    (...args) => {
      getFunc(onClose)(...args);
    },
    [onClose]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      const handler = note ? updateRequirement : createRequirement;

      try {
        const { text, ...restData } = formData;
        const args = { company_id };
        const body = {
          ...restData,
          company_id,
          group: note?.group || group,
        };
        body.text = HTMLEditor.stateToHtml(text);

        if (note) {
          args.requirement_id = note.id;
        }
        await handler({ ...args, body }).unwrap();
        m.success(`Requirement has been ${note ? 'updated' : 'created'}!`);
        handleClose();
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [
      m,
      updateRequirement,
      createRequirement,
      note,
      company_id,
      handleClose,
      group,
    ]
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={title}
      minWidth={644}
      maxWidth={644}
      {...rest}
    >
      <EditCompanyNoteForm
        pt={2}
        allowImages
        note={note}
        reset={!!open}
        loading={loading}
        onSubmit={handleSubmit}
        onCancel={handleClose}
      />
    </Modal>
  );
};

export default EditRequirementModal;
