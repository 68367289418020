import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DragDropContext } from 'react-beautiful-dnd';
import { Box, Stack } from '@mui/material';

import { useWatchField } from 'hooks';
import { getArray } from 'utils';
import { DeincrementField, ProjectSubmitButton } from 'views';
import { Center, FormLabel, ProgressBox } from 'components';

import StructureField from './StructureField';

const StructuresForm = () => {
  const { arrays, loading, structures, project, setValue, onStructuresChange } =
    useFormContext();

  const [dragType, setDragType] = useState(null);

  const structures_number = useWatchField('structures_number');

  const maxStructuresNumber = useMemo(() => {
    return getArray(project?.arrays).length;
  }, [project]);

  const minStructuresNumber = useMemo(() => {
    return structures.reduce((res, str) => {
      return res + !!(str.arrays.length > 0);
    }, 0);
  }, [structures]);

  const handleDragStart = useCallback(
    ({ draggableId }) => {
      const target = arrays.find((arr) => arr.id === draggableId);
      setDragType(target?.panel_type || null);
    },
    [arrays]
  );

  const handleDragEnd = useCallback(
    ({ destination, source, draggableId }) => {
      setDragType(null);

      if (!destination) {
        return;
      }
      const sameDroppable = destination.droppableId === source.droppableId;
      const sameIndex = destination.index === source.index;

      if (sameDroppable && sameIndex) {
        return;
      }
      onStructuresChange(
        structures.map((str) => {
          const newStructure = {
            ...str,
            arrays: [...str.arrays],
          };
          if (str.id === source.droppableId) {
            newStructure.arrays.splice(source.index, 1);
          }
          if (str.id === destination.droppableId) {
            newStructure.arrays.splice(destination.index, 0, draggableId);
          }
          return newStructure;
        })
      );
    },
    [structures, onStructuresChange]
  );

  // Control max/min structures number
  useEffect(() => {
    if (structures_number < minStructuresNumber) {
      setValue('structures_number', minStructuresNumber);
    } else if (structures_number > maxStructuresNumber) {
      setValue('structures_number', maxStructuresNumber);
    }
  }, [setValue, structures_number, minStructuresNumber, maxStructuresNumber]);

  // Create new or remove empty structures on number change
  useEffect(() => {
    if (!loading) {
      if (structures.length < structures_number) {
        // Add
        const newId = `structure-${Date.now()}`;

        onStructuresChange([
          ...structures,
          {
            id: newId,
            value: newId,
            label: `Structure ${structures.length + 1}`,
            arrays: [],
          },
        ]);
      } else if (structures.length > structures_number) {
        // Remove
        const targetIndex = structures.reduce((res, str, i) => {
          return str.arrays.length ? res : i;
        }, null);

        if (Number.isFinite(targetIndex)) {
          const newStructures = structures.filter(
            (str, i) => i !== targetIndex
          );

          onStructuresChange(
            newStructures.map((str, i) => ({
              ...str,
              label: `Structure ${i + 1}`,
            }))
          );
        }
      }
    }
  }, [loading, structures, onStructuresChange, structures_number]);

  return (
    <ProgressBox progress={loading}>
      <Stack spacing={3}>
        <Center justifyContent="space-between" mt={2}>
          <FormLabel>Number of PV Panels</FormLabel>

          <Box width={180}>
            <DeincrementField.Control
              min={minStructuresNumber}
              max={maxStructuresNumber}
              defaultValue={1}
              reserveHelperTextSpace={false}
              name="structures_number"
            />
          </Box>
        </Center>

        <Stack spacing={1}>
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            {structures.map((str) => (
              <StructureField {...str} key={str.id} dragType={dragType} />
            ))}
          </DragDropContext>
        </Stack>
      </Stack>

      <Box mt={4}>
        <ProjectSubmitButton disabled allowContinue={false} />
      </Box>
    </ProgressBox>
  );
};

export default StructuresForm;
