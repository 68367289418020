import { useCallback } from 'react';
import { Box } from '@mui/material';

import { getFunc } from 'utils';
import {
  useRemoveOrganisationMemberMutation,
  useDeleteOrganisationInviteMutation,
} from 'store';
import { Button, Modal, ProgressBox, useMessage } from 'components';

const DeleteUserModal = (props) => {
  const { user, open, onClose, invite, ...rest } = props;

  const m = useMessage();
  const [removeUser, { isLoading }] = useRemoveOrganisationMemberMutation();
  const [deleteInvite, { isLoading: deleteInviteLoading }] =
    useDeleteOrganisationInviteMutation();

  const loading = isLoading || deleteInviteLoading;

  const handleClose = useCallback(
    (...args) => {
      getFunc(onClose)(...args);
    },
    [onClose]
  );

  const handleSubmit = useCallback(async () => {
    const handler = invite ? deleteInvite : removeUser;
    const data = invite ? { invite_id: user.id } : { account_id: user.id };
    const message = invite
      ? 'Invite has been removed!'
      : 'User has been removed!';

    try {
      await handler(data).unwrap();
      m.success(message);
      handleClose();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [m, removeUser, user, invite, deleteInvite, handleClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      {...rest}
      title="Delete User"
      actions={
        <Box display="flex" gap={2}>
          <Button fullWidth={false} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>

          <Button minWidth={200} fullWidth={false} onClick={handleSubmit}>
            Confirm
          </Button>
        </Box>
      }
    >
      <ProgressBox progress={loading}>
        <Box textAlign="center" mt={1}>
          Are you sure you want to delete this user?
        </Box>
      </ProgressBox>
    </Modal>
  );
};

export default DeleteUserModal;
