import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import {
  useGetInviteInformationQuery,
  useCreateAccountViaInviteMutation,
} from 'store';

import { useAuth } from 'hooks';
import { routes } from 'routes';
import { SignUpForm } from 'views';
import { ProgressBox, useMessage, useHandleQueryError } from 'components';

const Invite = () => {
  const { hash_str } = useParams();
  const { progress, user } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();
  const [createAccount, { isLoading }] = useCreateAccountViaInviteMutation();

  const {
    data: invite,
    isFetching,
    ...inviteState
  } = useGetInviteInformationQuery(
    { hash_str },
    {
      skip: !!(progress || user || !hash_str),
    }
  );
  useHandleQueryError(inviteState);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = {
          ...formData,
          invite_id: invite.invite_id,
        };
        delete body.password_repeat;
        delete body.email;
        await createAccount({ body }).unwrap();
        m.success('You have been successfully registered!');
        navigate(routes.sign_in.path());
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, createAccount, invite, navigate]
  );

  if (progress || user || isFetching) {
    return (
      <ProgressBox
        progress={progress}
        size="large"
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {user && (
          <Typography variant="body2">
            This page is not allowed for logged in users.
          </Typography>
        )}
      </ProgressBox>
    );
  }
  return (
    <ProgressBox
      mt={8}
      minHeight={250}
      progress={isFetching || isLoading}
      overflow={isFetching || isLoading ? 'hidden' : undefined}
    >
      {invite && (
        <>
          <Box textAlign="center" mb={3}>
            <Typography variant="subtitle1">
              Sign Up to {invite.organisation}
            </Typography>
          </Box>

          <SignUpForm
            open
            type="member"
            onSubmit={handleSubmit}
            initialValues={invite}
          />
        </>
      )}
    </ProgressBox>
  );
};

export default Invite;
