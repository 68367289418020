import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import AvatarEditor from 'react-avatar-editor';
import { Box, Typography, IconButton as MuiIconButton } from '@mui/material';

import { map } from 'assets';
import { MIME, property_image_width, property_image_height } from 'constants';
import {
  useDeincrement,
  useGetLocationImage,
  useResizeObserver,
  useToggle,
  withProps,
} from 'hooks';

import { ProjectPreview } from 'views';
import {
  Center,
  Image,
  Dropzone,
  Ref,
  Button as OriginalButton,
  Delimiter,
  Icon,
} from 'components';

import MapView from './MapView';

const { IMAGES } = MIME;

const scaleMin = 1;
const scaleMax = 3;
const scaleStep = 0.1;

const zoomStep = 1;
const zoomMin = 16;
const zoomMax = 20;
const zoomDef = 19;

const Button = withProps(OriginalButton, {
  size: 'medium',
  variant: 'outlined',
});
const IconButton = withProps(MuiIconButton, { color: 'primary' });

const canvasToBlob = async (canvas) =>
  new Promise((resolve, reject) => {
    try {
      canvas.toBlob(resolve);
    } catch (ex) {
      reject(ex);
    }
  });

const LocationImagePreview = (props) => {
  const {
    apiRef,
    address,
    image: initialImage,
    onChange: onInitImageChange,
  } = props;

  const mapCenter = useMemo(() => {
    const lng = Number.parseFloat(address?.longitude);
    const lat = Number.parseFloat(address?.latitude);

    if (Number.isFinite(lng) && Number.isFinite(lat)) {
      return { lng, lat };
    }
    return null;
  }, [address]);

  const avatarRef = useRef();
  const containerRef = useRef();
  const dropzoneInputRef = useRef();
  const { refetch: fetchLocationImage } = useGetLocationImage();

  const [marker, toggleMarker] = useToggle(true);
  const [image, setImage] = useState();
  const [center, setCenter] = useState(null);
  const [scale, setScale] = useState(scaleMin);
  const [zoom, setZoom] = useState(zoomDef);
  const [containerSize, setContainerSize] = useState(null);

  const isEmpty = !image && !center;
  const isImage = !isEmpty && !!image;
  const isMap = !isImage && !!center;

  const handleSizeChange = useCallback(() => {
    const { current: containerElement } = containerRef;

    if (containerElement) {
      const rect = containerElement.getBoundingClientRect();
      setContainerSize(rect);
    }
  }, []);

  useResizeObserver(containerRef, handleSizeChange, isImage);

  const borders = useMemo(
    () =>
      !containerSize
        ? 0
        : [
            Math.max((containerSize.width - property_image_width) / 2, 0),
            Math.max((containerSize.height - property_image_height) / 2, 0),
          ],
    [containerSize]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      getImage: async () => {
        if (isImage) {
          const canvasImage = avatarRef.current.getImageScaledToCanvas();
          const imageFile = await canvasToBlob(canvasImage);
          return imageFile;
        }
        if (isMap) {
          const result = await fetchLocationImage({
            zoom,
            location: mapCenter,
          });
          if (result.error) {
            throw result.error;
          }
          return result.data;
        }
        return null;
      },
      locate: () => {
        setImage(null);
        setCenter(mapCenter);
        onInitImageChange(null);
      },
    }),
    [mapCenter, isImage, isMap, zoom, fetchLocationImage, onInitImageChange]
  );

  useEffect(() => {
    setScale(scaleMin);
  }, [image]);

  useEffect(() => {
    setCenter(mapCenter);
  }, [mapCenter]);

  useEffect(() => {
    if (initialImage) {
      setCenter(null);
      setImage(initialImage);
    }
  }, [initialImage]);

  // TOOL BUTTONS HANDLERS
  const handleDeleteImageClick = useCallback(() => {
    setImage(null);
    setCenter(null);
    onInitImageChange(null);
  }, [onInitImageChange]);

  const handleReplaceImageClick = useCallback(() => {
    if (dropzoneInputRef.current) {
      dropzoneInputRef.current.click();
    }
  }, []);

  const [increaseScale, decreaseScale] = useDeincrement(scale, setScale, {
    step: scaleStep,
    min: scaleMin,
    max: scaleMax,
  });
  const [increaseZoom, decreaseZoom] = useDeincrement(zoom, setZoom, {
    step: zoomStep,
    min: zoomMin,
    max: zoomMax,
  });
  const increase = isMap ? increaseZoom : increaseScale;
  const decrease = isMap ? decreaseZoom : decreaseScale;

  // IMAGE HANDLERS
  const handleImageChange = useCallback(
    (images) => {
      const newImage = images?.[0];
      setImage(newImage);
      onInitImageChange(null);
    },
    [onInitImageChange]
  );

  // MAP HANDLERS
  const handleMapChange = useCallback((state) => {
    setZoom(state.zoom);
    setCenter(state.center);
  }, []);

  const increaseDisabled = isMap ? zoom === zoomMax : scale === scaleMax;
  const decreaseDisabled = isMap ? zoom === zoomMin : scale === scaleMin;

  return (
    <ProjectPreview
      tools={
        <>
          {!isEmpty && (
            <>
              <Box px={4} display="flex" gap={2}>
                <Button onClick={handleDeleteImageClick}>Delete Image</Button>

                <Button onClick={handleReplaceImageClick}>Replace Image</Button>
              </Box>

              <Box height={1} display="flex" px={1} alignItems="center">
                <Delimiter mx={1} vertical />

                <IconButton onClick={increase} disabled={increaseDisabled}>
                  <Icon.PlusFilled />
                </IconButton>

                <IconButton onClick={decrease} disabled={decreaseDisabled}>
                  <Icon.MinusFilled />
                </IconButton>

                {isMap && (
                  <IconButton onClick={toggleMarker} size="small">
                    <Icon.Location
                      sx={{
                        fontSize: (theme) => theme.pxToRem(30),
                        color: marker ? 'secondary.main' : undefined,
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            </>
          )}
        </>
      }
    >
      <Center display={!isEmpty ? 'none' : 'flex'} flexGrow={1}>
        <Center gap={4} flexDirection="column">
          <Image height={120} width={120} src={map} />

          <Typography variant="subtitle4">
            Enter address to select aerial image
          </Typography>

          <Box width={430}>
            <Dropzone
              variant="box"
              value={[image].filter(Boolean)}
              accept={[IMAGES]}
              inputRef={dropzoneInputRef}
              onChange={handleImageChange}
              uploadTrigger={
                <Box px={10} textAlign="center">
                  <Typography variant="body3" fontWeight={400}>
                    <span>Drag and drop or</span>
                    <span> </span>
                    <Ref color="secondary.main" fontWeight={700}>
                      Click here
                    </Ref>
                    <span> </span>
                    <span>to upload image from your computer</span>
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Center>
      </Center>

      {isImage && (
        <Center flexGrow={1} overflow="auto" ref={containerRef}>
          <AvatarEditor
            ref={avatarRef}
            image={image}
            scale={scale}
            border={borders}
            width={property_image_width}
            height={property_image_height}
            crossOrigin="anonimous"
          />
        </Center>
      )}

      {isMap && (
        <MapView
          zoom={zoom}
          center={center}
          onChange={handleMapChange}
          marker={marker ? mapCenter : undefined}
        />
      )}
    </ProjectPreview>
  );
};

export default LocationImagePreview;
