import PaperBase from 'components/Paper/PaperBase';

class PaperElement extends PaperBase {
  constructor(options) {
    const { element, ...opts } = options;
    super(opts);
    this.element = element;
    this.children = {};
    this.cursor = null;
  }

  addCursor(cursor) {
    this.removeCursor();
    this.cursor = this.context.addCursor(cursor);
  }

  removeCursor() {
    this.context.removeCursor(this.cursor);
  }

  show() {
    this.element.visible = true;
  }

  hide() {
    this.element.visible = false;
  }

  toggle(v) {
    this.element.visible = !!v;
  }

  toLocal(p, element = this.element) {
    return element.globalToLocal(p);
  }

  toGlobal(p, element = this.element) {
    return element.localToGlobal(p);
  }

  toParentLocal(p, element = this.element) {
    return element.parent.globalToLocal(p);
  }

  toParentGlobal(p, element = this.element) {
    return element.parent.localToGlobal(p);
  }

  destroy(opts, ...args) {
    this.removeCursor();

    if (this.element && !opts?.keepElement) {
      this.element.remove();
    }
    super.destroy(opts, ...args);
  }
}

export default PaperElement;
