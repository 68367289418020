import { forwardRef, lazy } from 'react';
import { styled } from '@mui/material';

import { withProps } from 'hooks';
import { dev } from 'constants';
import {
  List,
  listItemClasses as liClasses,
  listItemTextClasses as litClasses,
  listItemButtonClasses as libClasses,
} from 'components';

const sizes = {
  small: 32,
  medium: 50,
};

const Menu = withProps(List, {
  sx: {
    pt: 0,
    pb: 0,
  },
  ListItemProps: {
    sx: (theme) => ({
      [`&.${liClasses.root}`]: {
        [`& .${litClasses.root}`]: {
          whiteSpace: 'nowrap',
        },
        [`&.${liClasses.selected}`]: {
          backgroundColor: theme.palette.violet.dark,
          [`& .${litClasses.root}`]: {
            [`& .${litClasses.primary}`]: {
              fontWeight: 600,
              letterSpacing: '-0.18px',
            },
          },
          '&:hover': {
            [`& .${libClasses.root}`]: {
              backgroundColor: theme.palette.violet.dark,
            },
          },
        },
      },
    }),
  },
  ListItemButtonProps: {
    sx: (theme) => ({
      '&:hover': {
        backgroundColor: theme.palette.violet.dark,
        [`& .${litClasses.root}`]: {
          [`& .${litClasses.primary}`]: {
            color: 'secondary.light',
          },
        },
      },
    }),
  },
});

const Root = styled(Menu, {
  label: 'ProjectStepsList',
  shouldForwardProp: (prop) => !['dark', 'size'].includes(prop),
})(({ theme, dark, size }) => ({
  [`& .${liClasses.root}`]: {
    backgroundColor: dark ? theme.palette.violet.dark : undefined,

    ...(size === 'small' && {
      [`&.${liClasses.selected}`]: {
        backgroundColor: theme.palette.colors.violet_background,

        '&:hover': {
          backgroundColor: theme.palette.colors.violet_background,

          [`& .${libClasses.root}`]: {
            backgroundColor: theme.palette.colors.violet_background,
          },
        },

        [`& .${litClasses.root}`]: {
          [`& .${litClasses.primary}`]: {
            fontWeight: '500',
          },
        },
      },
    }),

    [`&.${liClasses.disabled}`]: {
      opacity: 1,

      [`& .${libClasses.root}`]: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },

      [`& .${litClasses.root}`]: {
        [`& .${litClasses.primary}`]: {
          color: theme.palette.border.dark,
        },
      },
    },

    [`& .${libClasses.root}`]: {
      height: sizes[size] || size || sizes.medium,
    },
  },
}));

const ProjectStepsList = forwardRef((props, ref) => {
  const { dark, size = 'medium', ...rest } = props;

  return (
    <Root
      ref={ref}
      size={size}
      dark={dark}
      {...rest}
      ListItemTextProps={{
        primaryTypographyProps: {
          sx: (theme) => ({
            color: 'common.white',
            fontWeight: 400,
            fontSize: theme.pxToRem(size === 'medium' ? 12 : 11),
            lineHeight: '12px',
            letterSpacing: 0,
            fontFeatureSettings: '"tnum","tnum"',
          }),
        },
      }}
    />
  );
});

if (dev) {
  ProjectStepsList.Demo = lazy(() => import('./ProjectStepsList.demo'));
}

export default ProjectStepsList;
