import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { SYSTEM_TYPES_LIST } from 'constants';
import { RadioButtons } from 'components';

const SystemTypeField = (props) => {
  const { name, prefix, ...rest } = props;
  const { resetField } = useFormContext();

  const handleChange = useCallback(() => {
    resetField(`${prefix}.panel_id`, {
      defaultValue: '',
    });
    resetField(`${prefix}.wte_id`, {
      defaultValue: '',
    });
    resetField(`${prefix}.inverter_id`, {
      defaultValue: '',
    });
    resetField(`${prefix}.rsd_id`, {
      defaultValue: '',
    });
    resetField(`${prefix}.optimizer_id`, {
      defaultValue: '',
    });
    resetField(`${prefix}.strings`, {
      defaultValue: [],
    });
  }, [prefix, resetField]);

  return (
    <RadioButtons.Control
      {...rest}
      minWidth={0}
      size="medium"
      variant="flat"
      onChange={handleChange}
      options={SYSTEM_TYPES_LIST}
      name={`${prefix}.${name}`}
    />
  );
};

export default SystemTypeField;
