import { useMemo, useState } from 'react';

import { getArray } from 'utils';

const useToggleArray = (initial) => {
  const [array, setArray] = useState(getArray(initial));

  const toggle = useMemo(() => {
    const handler = function (v, value) {
      setArray((prev) => {
        if (typeof value === 'boolean') {
          if (value && !prev.includes(v)) {
            return [...prev, v];
          }
          return [...prev].filter((p) => p !== v);
        }
        if (prev.includes(v)) {
          return [...prev].filter((p) => p !== v);
        }
        return [...prev, v];
      });
    };
    handler.reset = (v) => {
      setArray(getArray(v));
    };
    handler.callback = (v) => () => {
      handler(v);
    };
    return handler;
  }, []);

  return useMemo(() => [array, toggle], [array, toggle]);
};

export default useToggleArray;
