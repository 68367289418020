import { lazy, useCallback, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
} from '@mui/material';

import { getFunc } from 'utils';
import { WIRE_TABLE_COLUMNS, dev } from 'constants';
import { useFormatWireTableSegments, withProps } from 'hooks';
import { Center, Icon, ProgressBox, TinyButton } from 'components';

const headerHeight = 40;
const rowHeight = 35;

const ScrollBox = styled(Box, {
  label: 'ScrollBox',
  shouldForwardProp: (prop) => !['scroll'].includes(prop),
})(({ scroll }) => ({
  [`&::-webkit-scrollbar`]: {
    minHeight: 12,
  },
  ...(scroll && {
    overflowX: 'scroll',
  }),
}));

const Cell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  fontFamily: 'ArialNarrow, sans-serif',
  fontSize: 10,
  verticalAlign: 'bottom',
  whiteSpace: 'nowrap',
  lineHeight: 1.5,
  textAlign: 'center',
  textTransform: 'uppercase',
  borderBottom: 'none',
}));
const HeaderCell = styled(Cell)(({ theme }) => ({
  fontWeight: 600,
  height: headerHeight,
  padding: theme.spacing(0.5),
  color: theme.palette.border.main,
}));
const BodyCell = styled(Cell)(({ theme }) => ({
  minWidth: 35,
  fontSize: 11,
  fontWeight: 400,
  height: rowHeight,
  padding: theme.spacing(1),
  textTransform: 'uppercase',
  boxSizing: 'border-box',
}));
const Row = styled(TableRow)({});

const TBody = styled(TableBody)(({ theme }) => ({
  [`${Row}`]: {
    [`${BodyCell}`]: {
      borderBottom: `1px solid ${theme.palette.border.main}`,
      borderLeft: `1px solid ${theme.palette.border.main}`,

      '&:last-of-type': {
        borderRight: `1px solid ${theme.palette.border.main}`,
      },
    },

    '&:first-of-type': {
      [`${BodyCell}`]: {
        borderTop: `1px solid ${theme.palette.border.main}`,
      },
    },
  },
}));

const EditButton = withProps(TinyButton, {
  size: 'small',
  variant: 'flat',
  sx: (theme) => ({
    color: `${theme.palette.border.main} !important`,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
  }),
});

const voltageDropViewFilters = {
  none: ['voltage_drop_perc', 'voltage_drop', 'voltage', 'length'],
  basic: ['voltage_drop', 'voltage', 'length'],
  advanced: [],
};
const neutralFilters = {
  conductor: ['neutral'],
  separate: [],
};

const WireTableView = (props) => {
  const {
    scroll,
    onEdit,
    loading,
    segments,
    editable,
    noDataText = 'No Data',
    station_id,
    neutral_view,
    voltage_drop_view,
  } = props;

  const rootRef = useRef();
  const [formatted, formatLoading] = useFormatWireTableSegments({
    station_id,
    segments,
  });
  const progress = loading || formatLoading;

  const cols = useMemo(() => {
    return WIRE_TABLE_COLUMNS.filter(
      (col) =>
        !voltage_drop_view ||
        !voltageDropViewFilters[voltage_drop_view].includes(col.field)
    ).filter(
      (col) =>
        !neutral_view || !neutralFilters[neutral_view].includes(col.field)
    );
  }, [neutral_view, voltage_drop_view]);

  const handleEditRow = useCallback(
    (index, row) => (e) => {
      getFunc(onEdit)(e, index, row);
    },
    [onEdit]
  );

  return (
    <Box display="flex" overflow="hidden">
      <Box overflow="hidden">
        <ScrollBox overflow="auto" scroll={scroll} pb={2}>
          <ProgressBox progress={progress} size="small" ref={rootRef}>
            <TableContainer sx={{ overflow: 'hidden', width: 'fit-content' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {cols.map((col) => {
                      const content = Array.isArray(col.label)
                        ? col.label
                        : [col.label];

                      return (
                        <HeaderCell key={col.field}>
                          {content.map((text, i) => (
                            <div key={i}>{text}</div>
                          ))}
                        </HeaderCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TBody>
                  {formatted.map((row, i) => (
                    <Row key={row.id || i}>
                      {cols.map((col) => (
                        <BodyCell key={col.field}>{row[col.field]}</BodyCell>
                      ))}
                    </Row>
                  ))}

                  {formatted.length === 0 && !progress && (
                    <Row>
                      <BodyCell colSpan={cols.length}>{noDataText}</BodyCell>
                    </Row>
                  )}

                  {formatted.length === 0 && progress && (
                    <Row>
                      <BodyCell colSpan={cols.length}>Loading</BodyCell>
                    </Row>
                  )}
                </TBody>
              </Table>
            </TableContainer>
          </ProgressBox>
        </ScrollBox>
      </Box>

      {editable && (
        <Box>
          <Box height={headerHeight} />

          {formatted.map((row, i) => (
            <Center key={i} pl={0.5} height={rowHeight}>
              <EditButton onClick={handleEditRow(i, row)}>
                <Icon.Pencil fontSize="small" />
              </EditButton>
            </Center>
          ))}
        </Box>
      )}
    </Box>
  );
};

if (dev) {
  WireTableView.Demo = lazy(() => import('./WireTableView.demo'));
}

export default WireTableView;
