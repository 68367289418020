import { lazy } from 'react';
import { Typography, Box } from '@mui/material';

import { dev } from 'constants';
import { DropdownButton, Center } from 'components';

const ToolbarDropdown = (props) => {
  const {
    label,
    icon = null,
    children,
    direction = 'row',
    containerWidth = 160,
    ...rest
  } = props;

  return (
    <DropdownButton
      {...rest}
      PaperProps={{
        sx: (theme) => ({
          mt: 2,
          border: `1px solid ${theme.palette.border.light}`,
          overflow: 'unset',
        }),
      }}
      label={
        <Center justifyContent="space-between" gap={1}>
          {icon}

          <Typography variant="body2">{label}</Typography>
        </Center>
      }
    >
      <Box
        p={1}
        gap={2}
        display="flex"
        flexWrap="wrap"
        width={containerWidth}
        flexDirection={direction}
      >
        {children}
      </Box>
    </DropdownButton>
  );
};

if (dev) {
  ToolbarDropdown.Demo = lazy(() => import('./ToolbarDropdown.demo'));
}

export default ToolbarDropdown;
