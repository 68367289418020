import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';

import { useGetContractorCompanyDetailsQuery } from 'store';
import { getString } from 'utils';
import { UploadAvatar } from 'views';
import { PhoneField, TextField } from 'components';

const CompanyDetailsFields = () => {
  const { control, resetField } = useFormContext();
  const company_id = useWatch({ control, name: 'contractor_id' });
  const disable = company_id !== 'new';

  const { data: companyDetails, isFetching } =
    useGetContractorCompanyDetailsQuery(
      { company_id },
      {
        skip: !disable || !company_id,
      }
    );
  useEffect(() => {
    resetField('name', {
      defaultValue: getString(companyDetails?.name),
    });
    resetField('phone', {
      defaultValue: getString(companyDetails?.phone),
    });
    resetField('license', {
      defaultValue: getString(companyDetails?.license),
    });
    resetField('images', {
      defaultValue: [companyDetails?.image]
        .filter(Boolean)
        .map((url) => ({ url })),
    });
  }, [companyDetails, resetField]);

  return (
    <>
      <Box pb={2}>
        <UploadAvatar.Control
          loading={isFetching}
          name="images"
          label="Logo"
          readOnly={disable}
          placeholder="Upload Logo"
          direction="row"
          shape="square"
          size={15}
          avatarProps={{
            objectFit: 'contain',
            bgcolor: 'transparent',
            sx: {
              mr: 2,
              borderColor: (theme) => theme.palette.border.main,
            },
          }}
        />
      </Box>

      <TextField.Control
        required
        name="name"
        label="Company Name"
        disabled={disable}
        loading={isFetching}
      />

      <PhoneField.Control
        required
        name="phone"
        label="Company Phone"
        disabled={disable}
        loading={isFetching}
      />

      <TextField.Control
        required
        name="license"
        label="License Number"
        disabled={disable}
        loading={isFetching}
      />
    </>
  );
};

export default CompanyDetailsFields;
