import { lazy, useCallback, useRef } from 'react';
import { InputAdornment, IconButton } from '@mui/material';

import { dev } from 'constants';
import { useToggle } from 'hooks';
import { Icon, TextField } from 'components';

const SearchField = (props) => {
  const { value, onValue, onChange } = props;

  const [searchFocus, toggleSearchFocus] = useToggle();
  const searchInputRef = useRef();

  const SearchIcon = searchFocus ? Icon.Close : Icon.Search;

  const handleSetFocus = useCallback(() => {
    const { current: searchInputElement } = searchInputRef;

    if (searchInputElement && !searchFocus) {
      searchInputElement.focus();
    }
  }, [searchFocus]);

  return (
    <TextField
      fullWidth
      value={value}
      onChange={onChange}
      onValue={onValue}
      placeholder="Search"
      inputRef={searchInputRef}
      reserveHelperTextSpace={false}
      onFocus={toggleSearchFocus.on}
      onBlur={toggleSearchFocus.off}
      sx={{
        maxWidth: searchFocus ? '100%' : 260,
        transition: (theme) =>
          theme.transitions.create('all', {
            duration: theme.transitions.duration.complex,
          }),
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            size="small"
            sx={{ color: 'secondary.dark' }}
            onClick={handleSetFocus}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

if (dev) {
  SearchField.Demo = lazy(() => import('./SearchField.demo'));
}

export default SearchField;
