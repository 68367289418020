import { Group, Path } from 'paper';

import { diagram_width, diagram_height } from 'constants';
import { Paper } from 'components';

const tableViewHeight = diagram_height * 0.2;

const {
  PaperView,
  PaperElement,
  PaperSvg,
  PaperNote,
  PaperTable,
  PaperDropzone,
  PaperText,
  PaperMark,
  PaperNoteBox,
} = Paper;

class Templates extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'templates_group' });
    super({ element, ...options });
  }

  setTemplates(images, callback) {
    const done = [];
    this.images = images;
    this.element.removeChildren();
    this.element.visible = false;

    const afterUpload = (item) => {
      done.push(item);

      if (done.length === images.length) {
        this.element.children.forEach((child, i) => {
          if (i > 0) {
            child.bounds.bottomLeft =
              this.element.children[i - 1].bounds.bottomRight;
          }
        });
        this.element.visible = true;
        callback && callback();
      }
    };
    const svgItems = images.map((source) => {
      const svgItem = new PaperSvg({
        scope: this.scope,
        theme: this.theme,
      });
      svgItem.upload(source, afterUpload);
      return svgItem;
    });
    const svgElements = svgItems.map((item) => item.element);
    this.element.addChildren(svgElements);
  }
}

class TableView extends PaperView {
  constructor(options) {
    super(options);

    const delimiter = new Path.Line({
      from: [0, tableViewHeight],
      to: [diagram_width, tableViewHeight],
      strokeColor: this.theme.palette.border.light,
      dashArray: [2, 2],
    });
    this.element.addChild(delimiter);
    this.children.delimiter = delimiter;

    const wire_table = new PaperTable(options);
    this.children.wire_table = wire_table;

    this.children.content.addChild(wire_table.element);
    wire_table.element.bounds.topCenter =
      this.children.view_rect.bounds.topCenter;
  }

  updateWireTable(data) {
    this.children.wire_table.update({
      rows: [
        [
          {
            fontWeight: 'bold',
            span: data[0]?.length || 1,
            content: 'Conductor and conduit schedule w/electrical calculations',
          },
        ],
        ...data,
      ],
      padding_x: 7,
      padding_y: 4,
    });
    this.children.wire_table.element.bounds.topCenter =
      this.children.view_rect.bounds.topCenter;

    this.children.wire_table.element.bounds.topCenter.x += 0.5;
    this.children.wire_table.element.bounds.topCenter.y += 0.5;
  }

  disableEdit() {
    this.children.delimiter.visible = false;
  }

  enableEdit() {
    this.children.delimiter.visible = true;
  }
}

class Legend extends PaperNoteBox {
  constructor(options) {
    super(options);
    const linesGroup = new Group({ name: 'lines_group' });
    this.element.addChild(linesGroup);
    this.children.linesGroup = linesGroup;
    this.marks = {};
    this.lines = {};

    linesGroup.bounds.x += 30;
    linesGroup.bounds.y += 15;
    this.element.scale(0.6);
  }

  addMark(mark) {
    if (this.marks[mark.id]) {
      return;
    }
    const markGroup = new Group({ name: 'legend_mark_group' });
    const markText = new PaperText({
      content: 'String'.toUpperCase(),
      fontSize: 12,
      fillColor: '#202020',
    });
    const instance = new PaperMark({
      ...this.options,
      data: {
        ...mark,
        scale: 0.85,
        placed: true,
      },
    });
    this.marks[mark.id] = instance;
    instance.disableDragging();
    markGroup.visible = false;
    markGroup.addChildren([instance.element, markText]);
    markText.bounds.leftCenter = instance.element.bounds.rightCenter;
    markText.bounds.x += 8;
    this.lines[mark.id] = markGroup;
    this.children.linesGroup.addChild(markGroup);
  }

  showMark(id) {
    if (!this.lines[id]) {
      return;
    }
    this.data.visible = true;
    this.lines[id].visible = true;

    Object.entries(this.lines)
      .sort(([name_a], [name_b]) => name_a.localeCompare(name_b))
      .map(([name, element]) => element)
      .filter((element) => element.visible)
      .forEach((element, i) => {
        element.bounds.y = i * 35;
      });

    this.update({
      height: Math.max(this.children.linesGroup.bounds.height + 30, 75),
    });
  }
}

class TemplatesView extends PaperView {
  constructor(options) {
    super(options);

    this.notes = {};
    this.marks = {};
    this.templates = new Templates(options);
    this.legend = new Legend({
      ...options,
      data: {
        x: diagram_width / 3 - 40,
        y: (diagram_height - tableViewHeight) / 3 - 20,
      },
    });
    this.children.content.addChildren([
      this.templates.element,
      this.legend.element,
    ]);
  }

  setTemplates(images, callback) {
    const onTemplatesReady = () => {
      this.templates.element.bounds.bottomCenter =
        this.children.view_rect.bounds.bottomCenter;

      callback && callback();
    };
    this.templates.setTemplates(images, onTemplatesReady);
  }

  addNote(note) {
    const step_x = 40;
    const step_y = 20;
    const start_x = diagram_width / 3;
    const start_y = (diagram_height - tableViewHeight) / 3;
    const offset = Object.values(this.notes).length;

    if (this.notes[note.id]) {
      return;
    }
    const noteInstance = new PaperNote({
      ...this.options,
      data: {
        ...note,
        x: start_x + step_x * offset,
        y: start_y + step_y * offset,
      },
    });
    this.notes[note.id] = noteInstance;
    this.children.content.addChild(noteInstance.element);
    noteInstance.element.scale(0.6);
  }

  removeNote(id) {
    const instance = this.notes[id];
    delete this.notes[id];

    if (instance) {
      instance.destroy();
    }
  }

  addMark(mark) {
    if (this.marks[mark.id]) {
      return;
    }
    const instance = new PaperMark({
      ...this.options,
      data: { ...mark },
      stickTo: this.templates.element,
      stickAnchors: ['topCenter', 'bottomCenter', 'leftCenter', 'rightCenter'],
    });
    this.marks[mark.id] = instance;
    this.children.content.addChild(instance.element);
  }

  disableEdit() {
    this.legend.disableEdit();

    Object.values(this.notes).forEach((note) => {
      note.disableEdit();
    });
  }

  enableEdit() {
    this.legend.enableEdit();

    Object.values(this.notes).forEach((note) => {
      note.enableEdit();
    });
  }
}

class LineDiagramController extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'main' });
    super({ element, ...options });

    this.scope.project.activeLayer.addChild(element);

    const table_view = new TableView({
      width: diagram_width,
      height: tableViewHeight,
      ...options,
    });
    const templates_view = new TemplatesView({
      width: diagram_width,
      height: diagram_height - tableViewHeight,
      ...options,
    });
    const dropzone = new PaperDropzone({
      ...options,
      x: 0,
      y: 0,
      width: diagram_width,
      height: diagram_height - tableViewHeight,
    });
    element.addChildren([
      table_view.element,
      templates_view.element,
      dropzone.element,
    ]);
    templates_view.element.bounds.topCenter =
      table_view.element.bounds.bottomCenter;

    dropzone.element.bounds.topLeft.x = 0;
    dropzone.element.bounds.topLeft.y = tableViewHeight;

    this.children.table_view = table_view;
    this.children.templates_view = templates_view;
    this.children.dropzone = dropzone;
  }

  setTemplates(images, callback) {
    this.children.templates_view.setTemplates(images, callback);
  }

  scaleTable(scale) {
    this.children.table_view.setScale(scale, 'topCenter');
  }

  scaleTemplates(scale) {
    this.children.templates_view.setScale(scale, 'bottomCenter');
  }

  addNote(note) {
    this.children.templates_view.addNote(note);
  }

  removeNote(id) {
    this.children.templates_view.removeNote(id);
  }

  getNote(id) {
    return this.children.templates_view.notes[id];
  }

  getNotes() {
    return Object.values(this.children.templates_view.notes);
  }

  updateWireTable(data) {
    this.children.table_view.updateWireTable(data);
  }

  enableDropzone() {
    this.children.dropzone.enable();
  }

  disableDropzone() {
    this.children.dropzone.disable();
  }

  getDropPoint() {
    return this.children.dropzone.drop_point;
  }

  addMark(mark) {
    const { element, ...data } = mark;

    this.options.stage.current.addActor({
      children: element,
      callback: (el) => {
        const target = el.children[0];

        this.children.templates_view.addMark({
          element: target,
          ...data.mark,
        });
      },
    });
  }

  getMark(id) {
    return this.children.templates_view.marks[id];
  }

  addLegendMark(mark) {
    const { element, ...data } = mark;

    this.options.stage.current.addActor({
      children: element,
      callback: (el) => {
        const target = el.children[0];

        this.children.templates_view.legend.addMark({
          element: target,
          ...data.mark,
        });
      },
    });
  }

  showLegendMark(id) {
    this.children.templates_view.legend.showMark(id);
  }

  updateLegend(data) {
    this.children.templates_view.legend.update(data);
  }

  disableEdit() {
    this.children.table_view.disableEdit();
    this.children.templates_view.disableEdit();
  }

  enableEdit() {
    this.children.table_view.enableEdit();
    this.children.templates_view.enableEdit();
  }

  getData() {
    const { notes, legend, marks } = this.children.templates_view;

    const data = {
      scale: {
        table: this.children.table_view.scale,
        diagram: this.children.templates_view.scale,
      },
      notes: Object.values(notes).map((noteInstance) => ({
        id: noteInstance.data.id,
        type: noteInstance.data.type,
        text: noteInstance.data.text,
        x: noteInstance.data.x,
        y: noteInstance.data.y,
      })),
      legend: {
        x: legend.data.x,
        y: legend.data.y,
      },
      marks: Object.values(marks)
        .filter((m) => m.data.placed)
        .map((m) => ({
          id: m.data.id,
          x: m.data.x,
          y: m.data.y,
          text: m.data.text,
          scale: m.data.scale,
        })),
    };
    return data;
  }

  destroy() {
    if (this.scope?.project?.activeLayer) {
      this.scope.project.activeLayer.removeChildren();
    }
  }
}

export default LineDiagramController;
