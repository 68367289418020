import { forwardRef, lazy, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { Box } from '@mui/material';

import { useForm } from 'hooks';
import { getFunc } from 'utils';
import { dev } from 'constants';

const Form = forwardRef((props, ref) => {
  const { form, onSubmit, children, ...rest } = props;

  const { handleSubmit } = form;

  const makeSubmit = useCallback(
    (...args) => {
      getFunc(onSubmit)(...args);
    },
    [onSubmit]
  );

  return (
    <Box
      ref={ref}
      {...rest}
      component="form"
      onSubmit={handleSubmit(makeSubmit)}
    >
      <FormProvider {...form}>{children}</FormProvider>

      {dev && <DevTool control={form.control} />}
    </Box>
  );
});
Form.useForm = useForm;

if (dev) {
  Form.Demo = lazy(() => import('./Form.demo'));
}

export default Form;
