import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetProjectDetailsQuery,
  useSaveProjectLineDiagramImageMutation,
  useUploadToTemporaryStorageMutation,
} from 'store';

import {
  useToggle,
  useGetProjectNotes,
  useGetProjectTemplates,
  useGetProjectWireTable,
  useGetProjectStringsAndWires,
} from 'hooks';

import { getArray, sleep, svgToFile } from 'utils';
import { routes } from 'routes';
import { NOTES_GROUPS } from 'constants';
import { StringMark, WireMark } from 'views';
import { ProjectDetailsLayout } from 'layouts';
import { Form, useMessage } from 'components';

import LineDiagramForm from './LineDiagramForm';
import LineDiagramPreview from './LineDiagramPreview';
import useGetLineDiagramMetadata from './useGetLineDiagramMetadata.hook';

const LineDiagram = () => {
  const { project_id } = useParams();
  const navigate = useNavigate();

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });
  const [metadata] = useGetLineDiagramMetadata({ project_id });
  const [templates, templatesLoading] = useGetProjectTemplates({ project_id });
  const [{ notes, requirements }, notesLoading] = useGetProjectNotes({
    project_id,
    group: NOTES_GROUPS.LINE_DIAGRAM,
  });
  const [templatesRendering, toggleTemplatesRendering] = useToggle(true);
  const [submitLoading, toggleSubmitLoading] = useToggle();
  const [marksInited, toggleMarksInited] = useToggle();
  const [wireTableInited, toggleWireTableInited] = useToggle();
  const [notesInited, toggleNotesInited] = useToggle();

  const [strings, setStrings] = useState([]);
  const [wires, setWires] = useState([]);

  const [api, setApi] = useState(null);
  const diagramRef = useRef(null);

  const [showErrors, toggleShowErrors] = useToggle();

  const [update, { isLoading }] = useSaveProjectLineDiagramImageMutation();
  const [uploadFile, { isLoading: fileLoading }] =
    useUploadToTemporaryStorageMutation();

  const [wireTable, wireTableLoading] = useGetProjectWireTable({ project_id });
  const [
    { wires: initialWires, strings: initialStrings },
    stringsAndWiresLoading,
  ] = useGetProjectStringsAndWires({ project_id });

  const loading = [
    isFetching,
    templatesLoading,
    notesLoading,
    wireTableLoading,
    templatesRendering,
    stringsAndWiresLoading,
    submitLoading,
    isLoading,
    fileLoading,
  ].some(Boolean);

  const canvasInited = [
    marksInited,
    wireTableInited,
    notesInited,
    !templatesRendering,
    !!api,
  ].every(Boolean);

  const m = useMessage();
  const form = Form.useForm();
  const isFirstSaving = !project?.line_diagram;

  const nextStep = useMemo(() => {
    return routes.electrical_tables.path({ project_id });
  }, [project_id]);

  const marksError = useMemo(
    () => ![...strings, ...wires].every((m) => m.placed),
    [strings, wires]
  );

  // Disable showing errors next to submit button on any form changes
  useEffect(() => {
    if (!loading || showErrors) {
      const sub = form.watch(() => {
        toggleShowErrors.off();
      });
      return () => sub.unsubscribe();
    }
  }, [showErrors, form, loading, toggleShowErrors]);

  // Disable showing errors next to submit button on any marks changes
  useEffect(() => {
    if (strings || wires) {
      toggleShowErrors.off();
    }
  }, [strings, toggleShowErrors, wires]);

  // Setup initial marks after loading
  useEffect(() => {
    setStrings([...initialStrings]);
    setWires([...initialWires]);
  }, [initialStrings, initialWires]);

  // Canvas: import strings and wires
  useEffect(() => {
    if (api && !loading && !marksInited) {
      initialStrings.forEach((string) => {
        api.addMark({
          mark: string,
          element: <StringMark text={string.text} />,
        });
        api.addLegendMark({
          mark: string,
          element: <StringMark text={string.text} />,
        });
      });
      initialWires.forEach((wire) => {
        api.addMark({
          mark: wire,
          element: <WireMark text={wire.text} />,
        });
      });
      toggleMarksInited.on();
    }
  }, [
    initialStrings,
    initialWires,
    loading,
    api,
    marksInited,
    toggleMarksInited,
  ]);

  // Canvas: update wire table
  useEffect(() => {
    if (api && project && !wireTableLoading && !wireTableInited) {
      api.updateWireTable(wireTable);
      toggleWireTableInited.on();
    }
  }, [
    project,
    wireTable,
    wireTableLoading,
    api,
    wireTableInited,
    toggleWireTableInited,
  ]);

  // Canvas: update templates
  useEffect(() => {
    if (api && !templatesLoading && templatesRendering) {
      const images = [
        templates?.system?.image,
        templates?.bos?.image,
        templates?.interconnection?.image,
      ].filter(Boolean);

      if (images.length !== 3) {
        toggleTemplatesRendering.off();
        m.warning('Templates images not found or not fully set up');
        // return;
      }
      api.setTemplates(images, toggleTemplatesRendering.off);
    }
  }, [
    m,
    templates,
    toggleTemplatesRendering,
    api,
    templatesRendering,
    templatesLoading,
  ]);

  // Canvas: update notes
  useEffect(() => {
    if (api && notes && !notesLoading && !notesInited) {
      const allNotes = Object.entries(notes).reduce(
        (res, [type, data]) => [
          ...res,
          ...data.map((note) => ({
            ...note,
            type,
          })),
        ],
        []
      );
      allNotes.forEach((n) => {
        api.addNote(n);
      });
      toggleNotesInited.on();
    }
  }, [notes, notesLoading, api, notesInited, toggleNotesInited]);

  // Prefill data
  useEffect(() => {
    if (canvasInited && metadata) {
      const initCustomNotes = getArray(metadata.notes).filter(
        (n) => n.type === 'custom'
      );

      initCustomNotes.forEach((n) => {
        api.addNote({ ...n });
      });
      getArray(metadata.notes).forEach((n) => {
        const noteInstance = api.getNote(n.id);

        if (noteInstance) {
          noteInstance.update(n);
        }
      });
      setStrings((prev) => {
        return prev.map((str) => {
          const targetMark = getArray(metadata.marks).find(
            (m) => m.id === str.id
          );

          return !targetMark
            ? str
            : {
                ...str,
                ...targetMark,
                placed: true,
              };
        });
      });
      setWires((prev) => {
        return prev.map((wire) => {
          const targetMark = getArray(metadata.marks).find(
            (m) => m.id === wire.id
          );

          return !targetMark
            ? wire
            : {
                ...wire,
                ...targetMark,
                placed: true,
              };
        });
      });
      getArray(metadata.marks).forEach((mark) => {
        const markInstance = api.getMark(mark.id);

        if (markInstance) {
          markInstance.update({
            ...mark,
            placed: true,
          });
        }
        api.showLegendMark(mark.id);
      });
      api.updateLegend({
        visible: true,
        ...metadata.legend,
      });
      form.reset({
        ...metadata.notes_state,
        custom_notes: initCustomNotes,
        scale: {
          table: metadata.scale.table,
          diagram: metadata.scale.diagram,
        },
      });
    }
  }, [form, canvasInited, project, metadata, api]);

  const isValid = useCallback(() => {
    if (marksError) {
      return false;
    }
    return true;
  }, [marksError]);

  const handleSubmit = useCallback(
    async (formData) => {
      if (!isValid()) {
        toggleShowErrors.on();
        return;
      }
      toggleSubmitLoading.on();

      try {
        const { custom_notes, ...notes_state } = formData;
        api.disableEdit();
        await sleep(200);
        const svg = await diagramRef.current.exportSvg();
        const file = svgToFile(svg);
        const file_metadata = {
          version: '2.0.0',
          ...api.getData(),
          notes_state,
        };
        const fileResponse = await uploadFile({
          body: { file },
        }).unwrap();

        const body = {
          file_metadata,
          file_id: fileResponse.file_key,
        };
        await update({ project_id, body }).unwrap();
        api.enableEdit();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
        api.enableEdit();
      }
      toggleSubmitLoading.off();
    },
    [
      api,
      isValid,
      m,
      project_id,
      toggleSubmitLoading,
      update,
      uploadFile,
      isFirstSaving,
      nextStep,
      navigate,
      toggleShowErrors,
    ]
  );

  return (
    <ProjectDetailsLayout
      form={{
        ...form,
        api,
        project_id,
        project,
        loading,
        notes,
        isFirstSaving,
        nextStep,
        templates,
        strings,
        wires,
        diagramRef,
        showErrors,
        marksError,
        requirements,
        onReady: setApi,
        onStringsChange: setStrings,
        onWiresChange: setWires,
      }}
      title="Line Diagram"
      component={Form}
      onSubmit={handleSubmit}
      body={<LineDiagramForm />}
    >
      <LineDiagramPreview />
    </ProjectDetailsLayout>
  );
};

export default LineDiagram;
