import { useMemo, forwardRef, lazy, useCallback, Fragment } from 'react';
import {
  styled,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon as MuiListItemIcon,
  listItemTextClasses,
  listItemClasses as liClasses,
  listItemButtonClasses as libClasses,
  listItemIconClasses as liiClasses,
} from '@mui/material';

import { dev } from 'constants';
import { withProps } from 'hooks';
import { getArray, getFunc } from 'utils';
import Ref from 'components/Ref';

export {
  listItemTextClasses,
  liClasses as listItemClasses,
  libClasses as listItemButtonClasses,
};

export const listItemStyles = ({ theme }) => ({
  [`&.${liClasses.root}`]: {
    color: theme.palette.text.primary,

    [`&.${liClasses.selected}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,

      '&:hover': {
        [`& .${libClasses.root}`]: {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    [`&.${liClasses.disabled}`]: {
      [`& .${libClasses.root}:hover`]: {
        backgroundColor: 'initial',
      },
    },
  },
});

const ListItem = styled(MuiListItem)(listItemStyles);

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  [`&.${liiClasses.root}`]: {
    minWidth: theme.spacing(5),
    color: 'inherit',
  },
}));

const RefItem = withProps(Ref, {
  nostyles: true,
});

const defaultListItemProps = {
  disablePadding: true,
};
const defaultListItemTextProps = {
  primaryTypographyProps: {
    variant: 'body3',
  },
};

const List = forwardRef((props, ref) => {
  const {
    dense = true,
    options,
    children,
    onItemClick,
    ListItemProps,
    ListItemTextProps,
    ListItemButtonProps,
    ...rest
  } = props;

  const listItemProps = useMemo(
    () => ({
      ...defaultListItemProps,
      ...ListItemProps,
    }),
    [ListItemProps]
  );

  const listItemTextProps = useMemo(
    () => ({
      ...defaultListItemTextProps,
      ...ListItemTextProps,
    }),
    [ListItemTextProps]
  );

  const handleItemClick = useCallback(
    (item) => (e) => {
      getFunc(onItemClick)(item, e);
    },
    [onItemClick]
  );

  return (
    <MuiList ref={ref} {...rest} dense={dense}>
      {getArray(options).map((item) => {
        const {
          value,
          label,
          icon,
          to,
          blank,
          absolute,
          children: itemChildren,
          ...itemRest
        } = item;

        const component = to ? RefItem : undefined;
        const refProps = to ? { to, blank, absolute } : {};

        return (
          <Fragment key={value}>
            <ListItem {...listItemProps} {...itemRest}>
              <ListItemButton
                onClick={handleItemClick(item)}
                component={component}
                {...ListItemButtonProps}
                {...refProps}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText {...listItemTextProps} primary={label} />
              </ListItemButton>
            </ListItem>

            {itemChildren}
          </Fragment>
        );
      })}
    </MuiList>
  );
});

if (dev) {
  List.Demo = lazy(() => import('./List.demo'));
}

export default List;
