import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';
import { useCreateAccountsMutation } from 'store';
import { useMessage } from 'components';
import { SignUpForm } from 'views';

const SignUp = (props) => {
  const { open } = props;
  const navigate = useNavigate();

  const m = useMessage();
  const [createAccounts, { isLoading }] = useCreateAccountsMutation();

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = { ...formData };
        delete body.password_repeat;
        await createAccounts({ body }).unwrap();
        m.success('Account has been created!');
        navigate(routes.sign_in.path());
      } catch (ex) {
        m.responseError(ex, 'Something went wrong, cannot sign up...');
      }
    },
    [m, createAccounts, navigate]
  );

  return <SignUpForm open={open} loading={isLoading} onSubmit={handleSubmit} />;
};

export default SignUp;
