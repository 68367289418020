import { useMatch } from 'react-router-dom';

import { PROJECT_STEPS as STEPS } from 'constants';
import { routes } from 'routes';

const useMatchStep = () => {
  const isPropertyInformation = !!useMatch(
    routes[STEPS.PROPERTY_INFORMATION].path()
  );
  const isSiteDesignCriteria = !!useMatch(
    routes[STEPS.SITE_DESIGN_CRITERIA].path()
  );
  const isContractorCompany = !!useMatch(
    routes[STEPS.CONTRACTOR_COMPANY].path()
  );
  const isPreliminaryPlan = !!useMatch(routes[STEPS.PRELIMINARY_PLAN].path());
  const isArraysParameters = !!useMatch(routes[STEPS.ARRAYS_PARAMETERS].path());
  const isPvSystem = !!useMatch(routes[STEPS.PV_SYSTEM].path());
  const isInterconnection = !!useMatch(routes[STEPS.INTERCONNECTION].path());
  const isBalanceOfSystem = !!useMatch(routes[STEPS.BALANCE_OF_SYSTEM].path());
  const isWireTable = !!useMatch(routes[STEPS.WIRE_TABLE].path());
  const isLineDiagram = !!useMatch(routes[STEPS.LINE_DIAGRAM].path());
  const isElectricalTables = !!useMatch(routes[STEPS.ELECTRICAL_TABLES].path());
  const isPlacards = !!useMatch(routes[STEPS.PLACARDS].path());
  const isSitePlan = !!useMatch(routes[STEPS.SITE_PLAN].path());
  const isElectricalPlan = !!useMatch(routes[STEPS.ELECTRICAL_PLAN].path());
  const isStructures = !!useMatch(routes[STEPS.STRUCTURES].path());
  const isAttachmentPlan = !!useMatch(routes[STEPS.ATTACHMENT_PLAN].path());
  const isDatasheet = !!useMatch(routes[STEPS.DATASHEETS].path());
  const isProjectPreview = !!useMatch(routes[STEPS.PROJECT_PREVIEW].path());

  if (isPropertyInformation) {
    return STEPS.PROPERTY_INFORMATION;
  }
  if (isSiteDesignCriteria) {
    return STEPS.SITE_DESIGN_CRITERIA;
  }
  if (isContractorCompany) {
    return STEPS.CONTRACTOR_COMPANY;
  }
  if (isPreliminaryPlan) {
    return STEPS.PRELIMINARY_PLAN;
  }
  if (isArraysParameters) {
    return STEPS.ARRAYS_PARAMETERS;
  }
  if (isPvSystem) {
    return STEPS.PV_SYSTEM;
  }
  if (isInterconnection) {
    return STEPS.INTERCONNECTION;
  }
  if (isBalanceOfSystem) {
    return STEPS.BALANCE_OF_SYSTEM;
  }
  if (isWireTable) {
    return STEPS.WIRE_TABLE;
  }
  if (isLineDiagram) {
    return STEPS.LINE_DIAGRAM;
  }
  if (isElectricalTables) {
    return STEPS.ELECTRICAL_TABLES;
  }
  if (isPlacards) {
    return STEPS.PLACARDS;
  }
  if (isSitePlan) {
    return STEPS.SITE_PLAN;
  }
  if (isElectricalPlan) {
    return STEPS.ELECTRICAL_PLAN;
  }
  if (isStructures) {
    return STEPS.STRUCTURES;
  }
  if (isAttachmentPlan) {
    return STEPS.ATTACHMENT_PLAN;
  }
  if (isDatasheet) {
    return STEPS.DATASHEETS;
  }
  if (isProjectPreview) {
    return STEPS.PROJECT_PREVIEW;
  }
  return null;
};

export default useMatchStep;
