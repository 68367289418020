export const MSP_STRATEGY = {
  EXISTING: 'EXISTING',
  DE_RATE: 'DE-RATE',
  UPGRADE: 'UPGRADE',
};
export const MSP_STRATEGY_NAMES = {
  [MSP_STRATEGY.EXISTING]: 'Existing',
  [MSP_STRATEGY.DE_RATE]: 'De-Rate',
  [MSP_STRATEGY.UPGRADE]: 'Upgrade',
};
export const MSP_STRATEGY_LIST = [
  MSP_STRATEGY.EXISTING,
  MSP_STRATEGY.DE_RATE,
  MSP_STRATEGY.UPGRADE,
].map((value) => ({
  value,
  label: MSP_STRATEGY_NAMES[value],
}));

export const BUS_RATING = {
  A60: 60,
  A100: 100,
  A125: 125,
  A200: 200,
  A225: 225,
  A400: 400,
  A600: 600,
  A800: 800,
  A1200: 1200,
  A1600: 1600,
  A2000: 2000,
};
export const BUS_RATING_NAMES = {
  [BUS_RATING.A60]: '60 A',
  [BUS_RATING.A100]: '100 A',
  [BUS_RATING.A125]: '125 A',
  [BUS_RATING.A200]: '200 A',
  [BUS_RATING.A225]: '225 A',
  [BUS_RATING.A400]: '400 A',
  [BUS_RATING.A600]: '600 A',
  [BUS_RATING.A800]: '800 A',
  [BUS_RATING.A1200]: '1200 A',
  [BUS_RATING.A1600]: '1600 A',
  [BUS_RATING.A2000]: '2000 A',
};
export const BUS_RATING_LIST = [
  BUS_RATING.A60,
  BUS_RATING.A100,
  BUS_RATING.A125,
  BUS_RATING.A200,
  BUS_RATING.A225,
  BUS_RATING.A400,
  BUS_RATING.A600,
  BUS_RATING.A800,
  BUS_RATING.A1200,
  BUS_RATING.A1600,
  BUS_RATING.A2000,
].map((value) => ({
  value,
  label: BUS_RATING_NAMES[value],
}));

export const BREAKER_RATING = {
  A60: 60,
  A90: 90,
  A100: 100,
  A125: 125,
  A200: 200,
  A225: 225,
  A400: 400,
  A600: 600,
  A800: 800,
  A1200: 1200,
  A1600: 1600,
  A2000: 2000,
};
export const BREAKER_RATING_NAMES = {
  [BREAKER_RATING.A60]: '60 A',
  [BREAKER_RATING.A90]: '90 A',
  [BREAKER_RATING.A100]: '100 A',
  [BREAKER_RATING.A125]: '125 A',
  [BREAKER_RATING.A200]: '200 A',
  [BREAKER_RATING.A225]: '225 A',
  [BREAKER_RATING.A400]: '400 A',
  [BREAKER_RATING.A600]: '600 A',
  [BREAKER_RATING.A800]: '800 A',
  [BREAKER_RATING.A1200]: '1200 A',
  [BREAKER_RATING.A1600]: '1600 A',
  [BREAKER_RATING.A2000]: '2000 A',
};
export const BREAKER_RATING_LIST = [
  BREAKER_RATING.A60,
  BREAKER_RATING.A90,
  BREAKER_RATING.A100,
  BREAKER_RATING.A125,
  BREAKER_RATING.A200,
  BREAKER_RATING.A225,
  BREAKER_RATING.A400,
  BREAKER_RATING.A600,
  BREAKER_RATING.A800,
  BREAKER_RATING.A1200,
  BREAKER_RATING.A1600,
  BREAKER_RATING.A2000,
].map((value) => ({
  value,
  label: BREAKER_RATING_NAMES[value],
}));

export const MSP_TYPES = {
  MM_COMBO_PANEL_TOP_BOTTOM_FED: 'MM_COMBO_PANEL_TOP_BOTTOM_FED',
  MM_COMBO_PANEL_CENTER_FED: 'MM_COMBO_PANEL_CENTER_FED',
  SOLAR_READY_MM: 'SOLAR_READY_MM',
  MM_RMA: 'MM_RMA',
  MM_SERVICE_DISCONNECT: 'MM_SERVICE_DISCONNECT',
  MM_TWO_SUB_MAIN: 'MM_TWO_SUB_MAIN',
  MM_BUS_SUB_MAIN: 'MM_BUS_SUB_MAIN',
  MSP_W_MAIN_BREAKER: 'MSP_W_MAIN_BREAKER',
  MSP_W_O_MAIN_BREAKER: 'MSP_W_O_MAIN_BREAKER',
  SOLAR_READY_MSP: 'SOLAR_READY_MSP',
  MSP_SUB_FEED: 'MSP_SUB_FEED',
  MSP_FEED_THROUGH_LUGS: 'MSP_FEED_THROUGH_LUGS',
  SPLIT_BUS_PANEL: 'SPLIT_BUS_PANEL',
};
export const MSP_TYPES_NAMES = {
  [MSP_TYPES.MM_COMBO_PANEL_TOP_BOTTOM_FED]:
    'Meter-main combo panel (top fed or bottom fed)',
  [MSP_TYPES.MM_COMBO_PANEL_CENTER_FED]: 'Meter-main combo panel (center fed)',
  [MSP_TYPES.SOLAR_READY_MM]: 'Solar ready Meter-main',
  [MSP_TYPES.MM_RMA]: 'Meter-main w/RMA',
  [MSP_TYPES.MM_SERVICE_DISCONNECT]: 'Meter-main w/service disconnect only',
  [MSP_TYPES.MM_TWO_SUB_MAIN]: 'Meter-main w/two sub mains only',
  [MSP_TYPES.MM_BUS_SUB_MAIN]: 'Meter-main w/ bus and sub main',
  [MSP_TYPES.MSP_W_MAIN_BREAKER]: 'Main service panel w/main breaker',
  [MSP_TYPES.MSP_W_O_MAIN_BREAKER]: 'Main service panel w/o main breaker',
  [MSP_TYPES.SOLAR_READY_MSP]: 'Solar ready Main service panel',
  [MSP_TYPES.MSP_SUB_FEED]: 'Main service panel w/sub feed lug kit',
  [MSP_TYPES.MSP_FEED_THROUGH_LUGS]: 'Main service panel w/ feed through lugs',
  [MSP_TYPES.SPLIT_BUS_PANEL]: 'Split bus panel w/sub feed',
};
export const MSP_TYPES_LIST = [
  MSP_TYPES.MM_COMBO_PANEL_TOP_BOTTOM_FED,
  MSP_TYPES.MM_COMBO_PANEL_CENTER_FED,
  MSP_TYPES.SOLAR_READY_MM,
  MSP_TYPES.MM_RMA,
  MSP_TYPES.MM_SERVICE_DISCONNECT,
  MSP_TYPES.MM_TWO_SUB_MAIN,
  MSP_TYPES.MM_BUS_SUB_MAIN,
  MSP_TYPES.MSP_W_MAIN_BREAKER,
  MSP_TYPES.MSP_W_O_MAIN_BREAKER,
  MSP_TYPES.SOLAR_READY_MSP,
  MSP_TYPES.MSP_SUB_FEED,
  MSP_TYPES.MSP_FEED_THROUGH_LUGS,
  MSP_TYPES.SPLIT_BUS_PANEL,
].map((value) => ({
  value,
  label: MSP_TYPES_NAMES[value],
}));

export const INTERCONNECTION_STRATEGY = {
  LINE_SIDE: 'LINE_SIDE',
  LOAD_SIDE: 'LOAD_SIDE',
};
export const INTERCONNECTION_STRATEGY_NAMES = {
  [INTERCONNECTION_STRATEGY.LINE_SIDE]: 'Line Side',
  [INTERCONNECTION_STRATEGY.LOAD_SIDE]: 'Load Side',
};
export const INTERCONNECTION_STRATEGY_LIST = [
  INTERCONNECTION_STRATEGY.LINE_SIDE,
  INTERCONNECTION_STRATEGY.LOAD_SIDE,
].map((value) => ({
  value,
  label: INTERCONNECTION_STRATEGY_NAMES[value],
}));

export const POI_LINE_SIDE = {
  LINE_IN_MSP: 'LINE_IN_MSP',
  LINE_SIDE_BREAKER: 'LINE_SIDE_BREAKER',
  METER_MAIN: 'METER_MAIN',
  TAP_BOX: 'TAP_BOX',
  METER: 'METER',
  ATS: 'ATS',
  RMA: 'RMA',
  TGA: 'TGA',
};
export const POI_LINE_SIDE_NAMES = {
  [POI_LINE_SIDE.LINE_IN_MSP]: 'Line Side Tap in Main Service Panel (MSP)',
  [POI_LINE_SIDE.LINE_SIDE_BREAKER]: 'Line (Supply) Side Breaker',
  [POI_LINE_SIDE.METER_MAIN]: 'Meter/Main',
  [POI_LINE_SIDE.TAP_BOX]: 'Tap Box',
  [POI_LINE_SIDE.METER]: 'Meter',
  [POI_LINE_SIDE.ATS]: 'Automatic transfer switch',
  [POI_LINE_SIDE.RMA]: 'Renewable Meter Adapter (RMA, GMA) at Meter',
  [POI_LINE_SIDE.TGA]: 'Two gang meter (RI, WI)',
};
export const POI_LINE_SIDE_LIST = [
  POI_LINE_SIDE.LINE_IN_MSP,
  POI_LINE_SIDE.LINE_SIDE_BREAKER,
  POI_LINE_SIDE.METER_MAIN,
  POI_LINE_SIDE.TAP_BOX,
  POI_LINE_SIDE.METER,
  POI_LINE_SIDE.ATS,
  POI_LINE_SIDE.RMA,
  POI_LINE_SIDE.TGA,
].map((value) => ({
  value,
  label: POI_LINE_SIDE_NAMES[value],
}));

export const POI_LOAD_SIDE = {
  BB_IN_MSP: 'BB_IN_MSP',
  BB_IN_SP: 'BB_IN_SP',
  LST_IN_MSP: 'LST_IN_MSP',
  LST_PROTECTED: 'LST_PROTECTED',
  LST_SP_SERVICE: 'LST_SP_SERVICE',
  LST_SP_LOAD: 'LST_SP_LOAD',
};
export const POI_LOAD_SIDE_NAMES = {
  [POI_LOAD_SIDE.BB_IN_MSP]: 'Backfeed breaker in Main Serivce Panel',
  [POI_LOAD_SIDE.BB_IN_SP]: 'Backfeed breaker in Sub-Panel',
  [POI_LOAD_SIDE.LST_IN_MSP]: 'Load Side Tap in Main Service Panel (MSP)',
  [POI_LOAD_SIDE.LST_PROTECTED]:
    'Protected load side tap (between disconnects)',
  [POI_LOAD_SIDE.LST_SP_SERVICE]: 'Load side tap on Sub-Panel service side',
  [POI_LOAD_SIDE.LST_SP_LOAD]: 'Load side tap on Sub-Panel load side',
};
export const POI_LOAD_SIDE_LIST = [
  POI_LOAD_SIDE.BB_IN_MSP,
  POI_LOAD_SIDE.BB_IN_SP,
  POI_LOAD_SIDE.LST_IN_MSP,
  POI_LOAD_SIDE.LST_PROTECTED,
  POI_LOAD_SIDE.LST_SP_SERVICE,
  POI_LOAD_SIDE.LST_SP_LOAD,
].map((value) => ({
  value,
  label: POI_LOAD_SIDE_NAMES[value],
}));

export const DISCONNECT_TYPES = {
  FUSED: 'fused',
  BREAKER_BOX: 'breaker_box',
  NON_FUSED: 'non_fused',
};
export const DISCONNECT_TYPES_NAMES = {
  [DISCONNECT_TYPES.FUSED]: 'Fused Disconnect',
  [DISCONNECT_TYPES.BREAKER_BOX]: 'Breaker Box',
  [DISCONNECT_TYPES.NON_FUSED]: 'Non Fused Disconnect',
};
export const DISCONNECT_TYPES_LIST = [
  DISCONNECT_TYPES.FUSED,
  DISCONNECT_TYPES.BREAKER_BOX,
  DISCONNECT_TYPES.NON_FUSED,
].map((value) => ({
  value,
  label: DISCONNECT_TYPES_NAMES[value],
}));

export const GROUNDING = {
  GROUND_ROD: 'GROUND_ROD',
  UFER: 'UFER',
  WATER_PIPE: 'WATER_PIPE',
  TWO_GROUND_ROD: 'TWO_GROUND_ROD',
  GROUND_ROD_WATER_PIPE: 'GROUND_ROD_WATER_PIPE',
  GROUND_ROD_UFER: 'GROUND_ROD_UFER',
  UFER_WATER_PIPE: 'UFER_WATER_PIPE',
};
export const GROUNDING_NAMES = {
  [GROUNDING.GROUND_ROD]: 'Ground Rod',
  [GROUNDING.UFER]: 'Ufer',
  [GROUNDING.WATER_PIPE]: 'Water pipe',
  [GROUNDING.TWO_GROUND_ROD]: 'Two ground rods',
  [GROUNDING.GROUND_ROD_WATER_PIPE]: 'Ground rod & water pipe',
  [GROUNDING.GROUND_ROD_UFER]: 'Ground rod & Ufer',
  [GROUNDING.UFER_WATER_PIPE]: 'Ufer & Water pipe',
};
export const GROUNDING_LIST = [
  GROUNDING.GROUND_ROD,
  GROUNDING.UFER,
  GROUNDING.WATER_PIPE,
  GROUNDING.TWO_GROUND_ROD,
  GROUNDING.GROUND_ROD_WATER_PIPE,
  GROUNDING.GROUND_ROD_UFER,
  GROUNDING.UFER_WATER_PIPE,
].map((value) => ({
  value,
  label: GROUNDING_NAMES[value],
}));

export const UTILITIES = {
  OVERHEAD: 'OVERHEAD',
  UNDERGROUND: 'UNDERGROUND',
};
export const UTILITIES_NAMES = {
  [UTILITIES.OVERHEAD]: 'Overhead',
  [UTILITIES.UNDERGROUND]: 'Underground',
};
export const UTILITIES_LIST = [UTILITIES.OVERHEAD, UTILITIES.UNDERGROUND].map(
  (value) => ({
    value,
    label: UTILITIES_NAMES[value],
  })
);
