import { forwardRef, lazy } from 'react';

import { dev } from 'constants';
import Select from 'components/Select';
import TextField from 'components/TextField';

const SelectField = forwardRef((props, ref) => {
  const { Component = Select, ...rest } = props;
  return <TextField ref={ref} Component={Component} {...rest} />;
});

SelectField.Control = forwardRef((props, ref) => {
  const { ControlComponent = SelectField, ...rest } = props;

  return (
    <TextField.Control
      ref={ref}
      ControlComponent={ControlComponent}
      {...rest}
    />
  );
});

if (dev) {
  SelectField.Demo = lazy(() => import('./SelectField.demo'));
}

export default SelectField;
