import { useMemo } from 'react';

import { useGetDisconnectsListQuery } from 'store';
import { DISCONNECT_TYPES } from 'constants';
import { getArray } from 'utils';

const useFetchDisconnects = (props) => {
  const { data: disconnectsFused, isFetching: fusedLoading } =
    useGetDisconnectsListQuery(
      {
        params: {
          disconnect_type: DISCONNECT_TYPES.FUSED,
        },
      },
      props
    );

  const { data: disconnectsBreakerBox, isFetching: breakerBoxLoading } =
    useGetDisconnectsListQuery(
      {
        params: {
          disconnect_type: DISCONNECT_TYPES.BREAKER_BOX,
        },
      },
      props
    );

  const { data: disconnectsNonFused, isFetching: nonFusedLoading } =
    useGetDisconnectsListQuery(
      {
        params: {
          disconnect_type: DISCONNECT_TYPES.NON_FUSED,
        },
      },
      props
    );

  return useMemo(() => {
    return {
      data: [
        ...getArray(disconnectsFused),
        ...getArray(disconnectsBreakerBox),
        ...getArray(disconnectsNonFused),
      ],
      isFetching: fusedLoading || breakerBoxLoading || nonFusedLoading,
    };
  }, [
    breakerBoxLoading,
    disconnectsBreakerBox,
    disconnectsFused,
    disconnectsNonFused,
    fusedLoading,
    nonFusedLoading,
  ]);
};

export default useFetchDisconnects;
