import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetProjectDetailsQuery,
  useSaveProjectSitePlanImageMutation,
  useUploadToTemporaryStorageMutation,
} from 'store';

import {
  useGetProjectEquipments,
  useGetProjectNotes,
  useIsLoading,
  useToggle,
} from 'hooks';

import {
  ENGINEER_SCALES_LIST,
  NOTES_GROUPS,
  ARCHITECT_SCALES,
  SCALE_TYPE,
  DEFAULT_CALLOUTS_LIST,
} from 'constants';

import { routes } from 'routes';
import { getArray, getString, sleep, svgToFile } from 'utils';
import { ProjectDetailsLayout } from 'layouts';
import { ConfirmModal } from 'views';
import { Form, useMessage } from 'components';

import SitePlanForm from './SitePlanForm';
import SitePlanPreview from './SitePlanPreview';
import useGetSitePlanMetadata from './useGetSitePlanMetadata.hook';

const targetLayers = [
  'dimensions',
  'obstructions',
  'property_lines',
  'property_setbacks',
  'pv_panels',
  'road',
  'roof',
];
const ignorePath = '/var/www/projects/design4pv-fs.dev.angleto.com';

const SitePlan = () => {
  const { project_id } = useParams();

  const [api, setApi] = useState(null);
  const [layersRendering, toggleLayersRendering] = useToggle(true);
  const [notesInited, toggleNotesInited] = useToggle();
  const [equipmentsInited, toggleEquipmentsInited] = useToggle();
  const [confirmChangeData, setConfirmChangeData] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [callouts, setCallouts] = useState([...DEFAULT_CALLOUTS_LIST]);
  const [rulers, setRulers] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [submitLoading, toggleSubmitLoading] = useToggle();
  const [canvasChanges, toggleCanvasChanges] = useToggle();

  const diagramRef = useRef(null);
  const [metadata] = useGetSitePlanMetadata({ project_id });

  const queryEquipments = useGetProjectEquipments({ project_id });
  const queryProject = useGetProjectDetailsQuery({ project_id });
  const [{ notes, requirements }, notesLoading] = useGetProjectNotes({
    project_id,
    group: NOTES_GROUPS.SITE_PLAN,
  });
  const [uploadFile, uploadState] = useUploadToTemporaryStorageMutation();
  const [save, saveState] = useSaveProjectSitePlanImageMutation();

  const equipmentsData = queryEquipments.data;
  const project = queryProject.data;
  const form = Form.useForm();
  const m = useMessage();
  const navigate = useNavigate();
  const isFirstSaving = !project?.site_plan;

  const loading = useIsLoading(
    queryEquipments,
    queryProject,
    notesLoading,
    saveState,
    submitLoading,
    uploadState
  );

  const canvasInited = [
    notesInited,
    equipmentsInited,
    !layersRendering,
    !!api,
  ].every(Boolean);

  const references = useMemo(() => {
    return [...equipments, ...callouts];
  }, [equipments, callouts]);

  const allowChange = useMemo(() => {
    const placedRefs = references.filter((r) => r.placed);
    return [...rulers, ...placedRefs].length === 0;
  }, [rulers, references]);

  const nextStep = useMemo(() => {
    return routes.electrical_plan.path({ project_id });
  }, [project_id]);

  const layers = useMemo(() => {
    return getArray(targetLayers)
      .map((name) =>
        getString(project?.property_plan?.[name]).split(ignorePath).join('')
      )
      .filter(Boolean);
  }, [project]);

  // Canvas events callbacks
  useEffect(() => {
    if (api) {
      api.addListener('select', setSelectedItemId);
      api.addListener('change', toggleCanvasChanges.on);

      return () => {
        api.removeListener('select');
        api.removeListener('change');
      };
    }
  }, [api, toggleCanvasChanges]);

  // Initial layers rendering
  useEffect(() => {
    if (layersRendering && !loading && api) {
      if (layers.length < targetLayers.length) {
        m.warning('Not all the layers found, or loading error...');
      }
      api.setLayers(layers, toggleLayersRendering.off);
    }
  }, [m, layersRendering, loading, layers, api, toggleLayersRendering]);

  // Initial form values setup
  useEffect(() => {
    if (project) {
      const initialScaleType = ARCHITECT_SCALES[project.scale]
        ? SCALE_TYPE.ARCHITECT
        : SCALE_TYPE.ENGINEER;

      form.reset({
        scale_type: initialScaleType,
        scale: project?.scale || ENGINEER_SCALES_LIST[0].value,
      });
    }
  }, [form, project]);

  // Canvas: references adding
  // TODO: add references from siteplanpreview instead
  useEffect(() => {
    if (api) {
      references.forEach((ref) => {
        if (ref.placed) {
          api.addReference(ref);
        }
      });
    }
  }, [api, references]);

  // Prefill data
  useEffect(() => {
    if (canvasInited && metadata) {
      const initCustomNotes = getArray(metadata.notes).filter(
        (n) => n.type === 'custom'
      );
      initCustomNotes.forEach((n) => {
        api.addNote({ ...n });
      });
      getArray(metadata.notes).forEach((n) => {
        const noteInstance = api.getNote(n.id);

        if (noteInstance) {
          noteInstance.update(n);
        }
      });
      api.setLayersPosition(metadata.layers?.position);

      const savedEquipments = getArray(metadata.references).filter(
        (ref) => ref.type === 'equipment'
      );
      const savedCallouts = getArray(metadata.references).filter(
        (ref) => ref.type === 'callout'
      );
      setRulers(getArray(metadata.rulers));

      setEquipments((prev) => {
        return prev.map((eq) => {
          const placed = !!savedEquipments.find((seq) => seq.id === eq.id);
          return { ...eq, placed };
        });
      });
      setCallouts((prev) => {
        const newCallouts = prev.map((cal) => {
          const placed = !!savedCallouts.find((scal) => scal.id === cal.id);
          return { ...cal, placed };
        });
        const custom = savedCallouts.filter((cal) => cal.custom);
        return [...newCallouts, ...custom];
      });
      getArray(metadata.rulers).forEach((ruler) => {
        api.addRuler(ruler);
      });
      savedEquipments.forEach((eq) => {
        api.addReference(eq);
      });
      savedCallouts.forEach((cal) => {
        api.addReference(cal);
      });
      form.reset({
        ...metadata.notes_state,
        custom_notes: initCustomNotes,
        scale_type: metadata.scale_type,
        scale: metadata.scale,
        north_angle: metadata.north_angle || 0,
      });
    }
  }, [canvasInited, metadata, api, form]);

  // Canvas: update notes
  useEffect(() => {
    if (api && notes && !notesLoading && !notesInited) {
      const allNotes = Object.entries(notes).reduce(
        (res, [type, data]) => [
          ...res,
          ...data.map((note) => ({
            ...note,
            type,
          })),
        ],
        []
      );
      allNotes.forEach((n) => {
        api.addNote(n);
      });
      toggleNotesInited.on();
    }
  }, [api, notes, notesInited, notesLoading, toggleNotesInited]);

  // Equipments initial setup
  useEffect(() => {
    if (equipmentsData) {
      setEquipments(
        [...equipmentsData].map((eq) => ({
          ...eq,
          id: eq.value,
          type: 'equipment',
        }))
      );
      toggleEquipmentsInited.on();
    }
  }, [equipmentsData, toggleEquipmentsInited]);

  const confirmResetListener = useCallback(
    (setter) =>
      (...args) => {
        if (!allowChange) {
          setConfirmChangeData({ setter, args });
          return;
        }
        setter(...args);
      },
    [allowChange]
  );

  const handleResetForm = useCallback(() => {
    setConfirmChangeData((d) => {
      if (d.setter) {
        setEquipments((prev) =>
          prev.map((p) => ({
            ...p,
            placed: false,
          }))
        );
        setCallouts((prev) =>
          prev.map((p) => ({
            ...p,
            placed: false,
          }))
        );
        setRulers([]);
        api.clearItems();
        d.setter(...d.args);
      }
      return null;
    });
  }, [api]);

  const handleSubmit = useCallback(
    async (formData) => {
      toggleSubmitLoading.on();

      try {
        const {
          custom_notes,
          notes_all,
          notes_company,
          notes_county,
          notes_custom,
          notes_utility,
          ...formState
        } = formData;

        api.disableEdit();
        await sleep(200);
        const svg = await diagramRef.current.exportSvg();
        const file = svgToFile(svg);
        const metadata = api.getData();
        const file_metadata = {
          version: '2.0.0',
          notes_state: {
            notes_all,
            notes_company,
            notes_county,
            notes_custom,
            notes_utility,
          },
          ...formState,
          ...metadata,
        };
        const fileResponse = await uploadFile({
          body: { file },
        }).unwrap();

        const body = {
          file_metadata,
          file_id: fileResponse.file_key,
        };
        await save({ project_id, body }).unwrap();

        api.enableEdit();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
        api.enableEdit();
      }
      toggleSubmitLoading.off();
    },
    [
      m,
      toggleSubmitLoading,
      api,
      save,
      project_id,
      uploadFile,
      navigate,
      isFirstSaving,
      nextStep,
    ]
  );

  return (
    <>
      <ConfirmModal
        open={!!confirmChangeData}
        onConfirm={handleResetForm}
        onClose={() => setConfirmChangeData(null)}
        title="Reset layout?"
        content="While rotating or scaling the image, you may lose markings that you've placed. Please confirm if you still want to rotate or scale the image."
      />

      <ProjectDetailsLayout
        form={{
          ...form,
          api,
          allowChange,
          loading,
          nextStep,
          isFirstSaving,
          notes,
          layers,
          project,
          requirements,
          diagramRef,
          equipments,
          callouts,
          canvasChanges,
          selectedItemId,
          onReady: setApi,
          onSelectItem: setSelectedItemId,
          onEquipmentsChange: setEquipments,
          onCalloutsChange: setCallouts,
          onRulersChange: setRulers,
          changeHandler: confirmResetListener,
        }}
        title="Site Plan"
        component={Form}
        onSubmit={handleSubmit}
        body={<SitePlanForm />}
      >
        <SitePlanPreview />
      </ProjectDetailsLayout>
    </>
  );
};

export default SitePlan;
