import { Group, Path } from 'paper';

import { getArray } from 'utils';
import PaperItem from 'components/Paper/PaperItem';
import PaperContentBox from 'components/Paper/PaperContentBox';
import PaperAnchor from 'components/Paper/PaperAnchor';
import PaperArrow from 'components/Paper/PaperArrow';
import PaperMultilineText from 'components/Paper/PaperMultilineText';

const my = 6;
const minGap = 40;
const defaultPadding = 25;
const defaultLineHeight = 20;

class PaperReference extends PaperItem {
  constructor(options) {
    const { data, stickTo, sticking, angleAlignment, parent, ...opts } =
      options;

    const element = new Group({ name: 'reference_group' });
    const rect = new PaperContentBox({
      ...opts,
      data: {
        visible: true,
      },
    });
    super({
      element,
      name: 'paper-reference',
      anchorElement: rect.element,
      ...opts,
      sticking: null,
    });
    this.onAnchor1AllowDrag = this.onAnchor1AllowDrag.bind(this);
    this.onAnchor2AllowDrag = this.onAnchor2AllowDrag.bind(this);
    this.onAnchor3AllowDrag = this.onAnchor3AllowDrag.bind(this);

    this.onAnchor1Move = this.onAnchor1Move.bind(this);
    this.onAnchor2Move = this.onAnchor2Move.bind(this);
    this.onAnchor3Move = this.onAnchor3Move.bind(this);

    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);

    this.callbacks.onDrop = this.onItemDrop.bind(this);

    this.parent = parent;
    this.anchor_drag = false;
    this.stickTo = stickTo;
    this.angleAlignment = angleAlignment;

    this.data = {
      ...data,
      segments: getArray(data?.segments),
      position: data?.position || 'right',
    };
    const point = this.getLocalPoint(this.data);

    this.text = new PaperMultilineText({
      fontSize: 14,
      content: this.data.label,
      uppercase: true,
    });
    this.rect = rect;
    this.rect.element.addChild(this.text);

    this.text.bounds.topLeft = this.point(0, 0);
    this.rect.element.bounds.topLeft = this.point(0, 0);
    this.rect.update({
      width: this.text.bounds.width + defaultPadding,
      height: Math.max(defaultLineHeight, this.text.bounds.height + my * 2),
    });
    this.rect.disableDragging();
    this.text.bounds.center = this.rect.element.bounds.center;

    const x1 = 0;
    const y1 = 0;
    const x2 = this.rect.element.bounds.bottomRight.x;
    const y2 = this.rect.element.bounds.bottomRight.y;

    this.size = new Path({
      segments: [
        [x1, y1],
        [x1, y2],
        [x2, y2],
        [x2, y1],
        [x1, y1],
      ],
      closed: true,
      visible: true,
    });
    this.element.addChildren([this.size, this.rect.element]);
    this.renderAnchors();
    this.unselect();
    this.element.bounds.x = point.x;
    this.element.bounds.y = point.y;
    this.commitData();
  }

  getLocalPoint(...args) {
    const p = this.point(...args);

    if (this.parent) {
      return this.parent.globalToLocal(p);
    }
    return p;
  }

  getGlobalPoint(...args) {
    const p = this.point(...args);

    if (this.parent) {
      return this.parent.localToGlobal(p);
    }
    return p;
  }

  getSegments() {
    const [s2, s3] = this.data.segments;

    const l1 =
      this.data.position === 'right'
        ? this.size.bounds.rightCenter
        : this.size.bounds.leftCenter;

    const l2 = s2 ? this.getLocalPoint(s2) : this.point(l1);
    const l3 = s3 ? this.getLocalPoint(s3) : this.point(l1);

    if (!s2) {
      l2.x += 50;
    }
    if (!s3) {
      l3.x += 150;
      l3.y += 50;
    }
    l2.y = l1.y;
    return [l1, l2, l3];
  }

  renderAnchors() {
    const [ref1, ref2, ref3] = this.getSegments();

    const anchorOptions = {
      ...this.options,
      anchorElement: null,
      onDragStart: this.onDragStart,
      onDrop: this.onDragEnd,
    };
    this.anchor1 = new PaperAnchor({
      ...anchorOptions,
      name: 'anchor-1',
      dragCursor: 'ew-resize',
      onAllowDrag: this.onAnchor1AllowDrag,
      onDrag: this.onAnchor1Move,
    });
    this.anchor2 = new PaperAnchor({
      ...anchorOptions,
      name: 'anchor-2',
      onAllowDrag: this.onAnchor2AllowDrag,
      onDrag: this.onAnchor2Move,
    });
    this.anchor3 = new PaperAnchor({
      ...anchorOptions,
      name: 'anchor-3',
      stickTo: this.stickTo,
      onAllowDrag: this.onAnchor3AllowDrag,
      onDrag: this.onAnchor3Move,
    });
    this.line1 = new Path.Line({
      from: ref1,
      to: ref2,
      strokeColor: 'black',
    });
    this.line2 = new Path.Line({
      from: ref2,
      to: ref3,
      strokeColor: 'black',
    });
    this.path1 = new Path.Line({
      from: ref1,
      to: ref2,
      strokeColor: 'blue',
      dashArray: [2, 2],
    });
    this.path2 = new Path.Line({
      from: ref2,
      to: ref3,
      strokeColor: 'blue',
      dashArray: [2, 2],
    });
    this.arrow = new PaperArrow({
      ...this.options,
      from: ref2,
      to: ref3,
    });

    this.element.addChildren([
      this.path1,
      this.path2,
      this.line1,
      this.line2,
      this.anchor1.element,
      this.anchor2.element,
      this.anchor3.element,
      this.arrow.element,
    ]);
    this.anchor1.element.bounds.center = this.point(ref1);
    this.anchor2.element.bounds.center = this.point(ref2);
    this.anchor3.element.bounds.center = this.point(ref3);
  }

  update(data = this.data) {
    this.data = { ...this.data, ...data };
  }

  onClick(e) {
    if (this.anchor_drag) {
      this.anchor_drag = false;
      return;
    }
    super.onClick(e);
  }

  onDragStart() {
    this.anchor_drag = true;
  }

  onAnchor1AllowDrag(e) {
    const point = this.toLocal(e.point);
    const a2 = this.anchor2.element.bounds.center;

    if (this.data.position === 'right') {
      return point.x < a2.x - minGap;
    }
    return point.x > a2.x + minGap;
  }

  onAnchor2AllowDrag(e) {
    const point = this.toLocal(e.point);
    const distanceToAnchor3 =
      this.anchor3.element.bounds.center.getDistance(point);
    return distanceToAnchor3 >= minGap;
  }

  onAnchor3AllowDrag(e) {
    const point = this.toLocal(e.point);
    const rectPoint = this.size.getNearestPoint(point);
    const distanceToRect = rectPoint.getDistance(point);
    const distanceToAnchor2 =
      this.anchor2.element.bounds.center.getDistance(point);

    return distanceToRect >= minGap && distanceToAnchor2 >= minGap;
  }

  onAnchor1Move(e, data) {
    data.element.bounds.center.y = this.path1.segments[1].point.y;
    this.path1.segments[0].point = data.element.bounds.center;
  }

  onAnchor2Move(e, data) {
    const p = data.element.bounds.center;
    this.path1.segments[1].point = p;
    this.path2.segments[0].point = p;
  }

  onAnchor3Move(e, data) {
    this.path2.segments[1].point = data.element.bounds.center;
  }

  onDragEnd() {
    const [p1, p2, p3] = this.getPreviousPoints();
    const [n1, n2, n3] = this.getNewPoints();
    this.anchor1.clearCursor();

    const index = [
      !this.equalPoints(p1, n1),
      !this.equalPoints(p2, n2),
      !this.equalPoints(p3, n3),
    ].indexOf(true);

    if (!Number.isFinite(index) || index < 0) {
      return;
    }

    switch (index) {
      case 0: {
        this.line1.segments[0].point = this.point(n1);
        break;
      }
      case 1: {
        const offsetX = p2.x - n2.x;
        const offsetY = p2.y - n2.y;

        this.line1.segments[1].point = n2;
        this.line2.segments[0].point = n2;

        this.line1.segments[0].point.x -= offsetX;
        this.line1.segments[0].point.y -= offsetY;
        break;
      }
      case 2:
      default: {
        this.line2.segments[1].point = n3;
        break;
      }
    }
    const rectOrigin =
      this.data.position === 'right' ? 'rightCenter' : 'leftCenter';
    this.rect.element.bounds[rectOrigin] = this.line1.segments[0].point;
    this.size.bounds.center = this.rect.element.bounds.center;

    this.adjustPosition();

    this.arrow.update({
      from: this.line2.segments[0].point,
      to: this.line2.segments[1].point,
    });
    this.path1.segments[0].point = this.line1.segments[0].point;
    this.path1.segments[1].point = this.line1.segments[1].point;
    this.path2.segments[0].point = this.line2.segments[0].point;
    this.path2.segments[1].point = this.line2.segments[1].point;
    this.anchor1.element.bounds.center = this.line1.segments[0].point;
    this.anchor2.element.bounds.center = this.line2.segments[0].point;
    this.anchor3.element.bounds.center = this.line2.segments[1].point;
    this.commitData();
  }

  onItemDrop() {
    this.commitData();
  }

  adjustPosition() {
    const centerX = this.rect.element.bounds.center.x;
    const endX = this.line2.segments[1].point.x;

    const positionSwapped =
      (this.data.position === 'left' && endX > centerX) ||
      (this.data.position === 'right' && endX <= centerX);

    if (positionSwapped) {
      switch (this.data.position) {
        case 'left': {
          this.data.position = 'right';
          this.line1.segments[0].point = this.rect.element.bounds.rightCenter;
          this.line1.segments[1].point = this.line1.segments[0].point;
          this.line1.segments[1].point.x += minGap;
          this.line2.segments[0].point = this.line1.segments[1].point;
          break;
        }
        case 'right':
        default: {
          this.data.position = 'left';
          this.line1.segments[0].point = this.rect.element.bounds.leftCenter;
          this.line1.segments[1].point = this.line1.segments[0].point;
          this.line1.segments[1].point.x -= minGap;
          this.line2.segments[0].point = this.line1.segments[1].point;
          break;
        }
      }
    }
  }

  getPreviousPoints() {
    const [p1] = this.line1.segments;
    const [p2, p3] = this.line2.segments;
    return [p1.point, p2.point, p3.point];
  }

  getNewPoints() {
    const [p1] = this.path1.segments;
    const [p2, p3] = this.path2.segments;
    return [p1.point, p2.point, p3.point];
  }

  getNewSegments() {
    const [p2, p3] = this.path2.segments;

    return [this.getGlobalPoint(p2.point), this.getGlobalPoint(p3.point)];
  }

  rename(name = this.data.label || '') {
    this.data.label = name;
    this.text.update(name);

    this.rect.update({
      width: this.text.bounds.width + defaultPadding,
      height: Math.max(defaultLineHeight, this.text.bounds.height + my * 2),
    });
    this.text.bounds.center = this.rect.element.bounds.center;

    const rectOrigin =
      this.data.position === 'right' ? 'rightCenter' : 'leftCenter';

    this.rect.element.bounds[rectOrigin] = this.line1.segments[0].point;
    this.size.bounds.center = this.rect.element.bounds.center;

    if (this.size) {
      this.size.remove();
    }
    const x1 = this.rect.element.bounds.topLeft.x;
    const y1 = this.rect.element.bounds.topLeft.y;
    const x2 = this.rect.element.bounds.bottomRight.x;
    const y2 = this.rect.element.bounds.bottomRight.y;

    this.size = new Path({
      segments: [
        [x1, y1],
        [x1, y2],
        [x2, y2],
        [x2, y1],
        [x1, y1],
      ],
      closed: true,
      visible: true,
    });
    this.element.addChild(this.size);
    this.size.bounds.center = this.rect.element.bounds.center;
  }

  enableSticking() {
    this.anchor3.enableSticking();
  }

  disableSticking() {
    this.anchor3.disableSticking();
  }

  enableAngleAlignment() {
    this.angleAlignment = true;
  }

  disableAngleAlignment() {
    this.angleAlignment = false;
  }

  select(...args) {
    this.enableDragging();
    this.style('selected');
    this.path1.visible = true;
    this.path2.visible = true;
    super.select(...args);
  }

  unselect(...args) {
    this.disableDragging();
    this.style();
    this.path2.visible = false;
    this.path2.visible = false;
    super.unselect(...args);
  }

  disableEdit() {
    this.unselect();
    this.rect.disableEdit();
  }

  enableEdit() {
    this.rect.enableEdit();
  }

  commitData() {
    this.anchor1.hide();
    this.anchor2.hide();
    this.anchor3.hide();
    const pos = this.getGlobalPoint(this.element.bounds);

    this.data.x = pos.x;
    this.data.y = pos.y;
    this.data.segments = this.getNewSegments().map((p) => ({
      x: p.x,
      y: p.y,
    }));

    if (this.selected) {
      this.anchor1.show();
      this.anchor2.show();
      this.anchor3.show();
    }
  }

  style(type = 'default') {
    switch (type) {
      case 'selected': {
        this.anchor1.show();
        this.anchor2.show();
        this.anchor3.show();
        this.rect.children.note_rect.opacity = 1;
        break;
      }
      case 'default':
      default: {
        this.anchor1.hide();
        this.anchor2.hide();
        this.anchor3.hide();
        this.rect.children.note_rect.opacity = 0.5;
      }
    }
  }
}

export default PaperReference;
