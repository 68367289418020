import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  VOLTAGE_DROP_LIST,
  NEUTRALS_LIST,
  TERMINAL_TEMP_LIST,
  EGC_METHOD_LIST,
  NEUTRAL_METHOD,
} from 'constants';

import {
  useGetProjectDetailsQuery,
  useSaveProjectWireTableSettingsMutation,
} from 'store';

import { routes } from 'routes';
import { ProjectDetailsLayout } from 'layouts';
import { Form, useMessage } from 'components';

import WireTableForm from './WireTableForm';
import WireTablePreview from './WireTablePreview';

const WireTable = () => {
  const { project_id } = useParams();
  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });
  const [update, { isLoading }] = useSaveProjectWireTableSettingsMutation();
  const navigate = useNavigate();

  const station_id = project?.meteo_station?.id;

  const m = useMessage();
  const form = Form.useForm();

  const loading = isFetching || isLoading;
  const isFirstSaving = !project?.wires_table;
  const nextStep = useMemo(
    () => routes.line_diagram.path({ project_id }),
    [project_id]
  );

  useEffect(() => {
    const data = project?.wires_table;

    form.reset({
      voltage_drop_view: data?.voltage_drop_view || VOLTAGE_DROP_LIST[0].value,
      neutral_view: data?.neutral_view || NEUTRALS_LIST[0].value,
      temperature_rating:
        data?.temperature_rating || TERMINAL_TEMP_LIST[0].value,
      gec_strategy: data?.gec_strategy || EGC_METHOD_LIST[0].value,
      neutral_strategy: data?.neutral_strategy || NEUTRAL_METHOD.CONDUCTOR,
    });
  }, [form, project]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = { ...formData };

        if (body.voltage_drop_view === 'none') {
          delete body.voltage_drop_view;
        }
        await update({ project_id, body }).unwrap();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, update, project_id, navigate, nextStep, isFirstSaving]
  );

  return (
    <ProjectDetailsLayout
      form={form}
      title="Wire Table"
      component={Form}
      onSubmit={handleSubmit}
      body={
        <WireTableForm
          loading={loading}
          nextStep={nextStep}
          project_id={project_id}
          isFirstSaving={isFirstSaving}
        />
      }
    >
      <WireTablePreview station_id={station_id} project_id={project_id} />
    </ProjectDetailsLayout>
  );
};

export default WireTable;
