import { useCallback } from 'react';

import {
  useToggleRequirementVisibilityMutation,
  useDeleteCompanyRequirementMutation,
} from 'store';

import { useToggle } from 'hooks';
import { CompanyNote } from 'views';
import { useMessage } from 'components';

import EditRequirementModal from './EditRequirementModal';

const Requirement = (props) => {
  const { note, company_id } = props;
  const { id: requirement_id } = note;

  const m = useMessage();
  const [editRequirement, toggleEditRequirement] = useToggle();
  const [toggleVisibility, toggleState] =
    useToggleRequirementVisibilityMutation();

  const [deleteRequirement, deleteRequirementState] =
    useDeleteCompanyRequirementMutation();

  const loading = toggleState.isLoading || deleteRequirementState.isLoading;

  const handleToggleVisibility = useCallback(async () => {
    try {
      await toggleVisibility({ company_id, requirement_id }).unwrap();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [company_id, m, requirement_id, toggleVisibility]);

  const handleRemoveRequirement = useCallback(async () => {
    try {
      await deleteRequirement({ company_id, requirement_id }).unwrap();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [company_id, deleteRequirement, m, requirement_id]);

  return (
    <>
      {/* MODALS */}
      <>
        <EditRequirementModal
          note={note}
          open={!!editRequirement}
          company_id={company_id}
          onClose={toggleEditRequirement.off}
        />
      </>

      <CompanyNote
        note={note}
        loading={loading}
        onEditClick={toggleEditRequirement.on}
        onDeleteClick={handleRemoveRequirement}
        onVisibilityClick={handleToggleVisibility}
      />
    </>
  );
};

export default Requirement;
