import { Grid, Typography, Box } from '@mui/material';

import {
  CONDUCTOR_MATERIAL_OPTIONS_LIST,
  CONDUCTOR_SIZE_OPTIONS_LIST,
  CONDUIT_TYPE_OPTIONS_LIST,
  CONDUIT_SIZE_OPTIONS_LIST,
  OCPD_RATING_OPTIONS_LIST,
  MIN_OCPD_RATING_OPTIONS_LIST,
  VOLTAGE_DROP_OPTIONS_LIST,
  NEUTRAL_VIEW_OPTIONS_LIST,
  EGC_CALC_METHOD_OPTIONS_LIST,
  NEUTRAL_CALC_METHOD_OPTIONS_LIST,
} from 'constants';

import { withProps } from 'hooks';
import { SelectField, TextField } from 'components';

const Item = withProps(Grid, {
  item: true,
  xs: 6,
});

const ElectricalWireTablePreferences = (props) => {
  const { readOnly, ...rest } = props;
  const disabled = !readOnly;

  return (
    <Box {...rest}>
      <Typography variant="subtitle2">Electrical: Wire Table</Typography>

      <Grid container mt={1} rowSpacing={1} columnSpacing={4}>
        <Item>
          <SelectField.Control
            readOnly={readOnly}
            name="dc_conductor_material"
            label="DC Conductor Material"
            options={CONDUCTOR_MATERIAL_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            readOnly={readOnly}
            name="ac_conductor_material"
            label="AC Conductor Material"
            options={CONDUCTOR_MATERIAL_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            readOnly={readOnly}
            name="minimum_conductor_size"
            label="Minimum Conductor Size"
            options={CONDUCTOR_SIZE_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            readOnly={readOnly}
            name="minimum_egc_size"
            label="Minimum EGC Size"
            options={CONDUCTOR_SIZE_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            readOnly={readOnly}
            name="dc_conduit_type"
            label="DC Conduit Type"
            options={CONDUIT_TYPE_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            readOnly={readOnly}
            name="ac_conduit_type"
            label="AC Conduit Type"
            options={CONDUIT_TYPE_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            readOnly={readOnly}
            name="min_conduit_size"
            label="Min Conduit Size"
            options={CONDUIT_SIZE_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            disabled={disabled}
            readOnly={readOnly}
            name="ocpd_rating"
            label="OCPD Rating"
            options={OCPD_RATING_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            readOnly={readOnly}
            name="minimum_ocpd_rating"
            label="Minimum OCPD Rating"
            options={MIN_OCPD_RATING_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <TextField.Control
            disabled={disabled}
            readOnly={readOnly}
            name="conduit_fill_percentage"
            label="Conduit Fill Percentage - (2 chars numeric)"
            type="number"
            inputProps={{
              step: 1,
              min: 1,
            }}
          />
        </Item>

        <Item>
          <SelectField.Control
            disabled={disabled}
            readOnly={readOnly}
            name="voltage_drop_view"
            label="Voltage Drop View"
            options={VOLTAGE_DROP_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            disabled={disabled}
            readOnly={readOnly}
            name="neutral_view"
            label="Neutral View"
            options={NEUTRAL_VIEW_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            disabled={disabled}
            readOnly={readOnly}
            name="egc_calculation_method"
            label="EGC Calculation Method"
            options={EGC_CALC_METHOD_OPTIONS_LIST}
          />
        </Item>

        <Item>
          <SelectField.Control
            disabled={disabled}
            readOnly={readOnly}
            name="neutral_calculation_method"
            label="Neutral Calculation Method"
            options={NEUTRAL_CALC_METHOD_OPTIONS_LIST}
          />
        </Item>
      </Grid>
    </Box>
  );
};

export default ElectricalWireTablePreferences;
