import { createSelector, createSlice } from '@reduxjs/toolkit';
import { parseUserRole } from 'constants';

const name = 'profile';

const initialState = {
  user: null,
  role: null,
  premium: false,
  logged_in: !!localStorage.getItem('access_token'),
};

// SELECTORS
const selectRoot = (store) => store[name];

// Has token
const selectIsLoggedIn = createSelector(
  selectRoot,
  (state) => !!state.logged_in
);
// Has VALID token and user profile
const selectIsAuthorized = createSelector(
  selectRoot,
  (state) => !!(state.logged_in && state.user)
);
const selectUser = createSelector(selectRoot, (state) => state.user);
const selectRole = createSelector(selectRoot, (state) => state.role);
const selectPremium = createSelector(selectRoot, (state) => state.premium);

// SLICE
const slice = createSlice({
  name,
  initialState,
  reducers: {
    authorize: (state, { payload }) => {
      const { user } = payload;
      const role = parseUserRole(user?.role);
      state.user = user;
      state.role = role;
      state.premium = !!user?.is_full_access;
    },
    login: (state, { payload }) => {
      const { access_token } = payload;
      localStorage.setItem('access_token', access_token);
      state.logged_in = !!access_token;
    },
    logout: (state) => {
      localStorage.removeItem('access_token');
      state.logged_in = false;
      state.user = null;
      state.premium = false;
    },
  },
});

slice.selectors = {
  selectIsLoggedIn,
  selectIsAuthorized,
  selectUser,
  selectRole,
  selectPremium,
};

export default slice;
