import { useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Stack, Collapse, IconButton } from '@mui/material';

import { getArray, Note } from 'utils';
import { useWatchField, withProps } from 'hooks';
import { ProjectSubmitButton } from 'views';
import {
  Button,
  Center,
  Icon,
  Message,
  ProgressBox,
  SwitchField,
  TextField,
} from 'components';

const CloseIcon = withProps(Icon.Close, {
  sx: {
    fontSize: 12,
    borderRadius: '50%',
    color: 'common.white',
    bgcolor: 'secondary.main',
  },
});

const getNotesSwitchLabel = (label, disabled) => {
  return !disabled ? label : `${label} (No notes available)`;
};

const LineDiagramForm = () => {
  const {
    api,
    notes,
    loading,
    control,
    nextStep,
    setValue,
    showErrors,
    marksError,
    isFirstSaving,
  } = useFormContext();

  const showAllNotes = useWatchField('notes_all');
  const showCountyNotes = useWatchField('notes_county');
  const showUtilityNotes = useWatchField('notes_utility');
  const showCompanyNotes = useWatchField('notes_company');
  const showCustomNotes = useWatchField('notes_custom');
  const customNotesData = useWatchField('custom_notes');

  const {
    fields: customNotes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'custom_notes',
    keyName: 'field_id',
  });

  const countyNotesDisabled = getArray(notes.county).length === 0 && !loading;
  const utilityNotesDisabled = getArray(notes.utility).length === 0 && !loading;
  const companyNotesDisabled = getArray(notes.company).length === 0 && !loading;

  const countyNotesLabel = getNotesSwitchLabel(
    'County Notes',
    countyNotesDisabled
  );
  const utilityNotesLabel = getNotesSwitchLabel(
    'Utility Notes',
    utilityNotesDisabled
  );
  const companyNotesLabel = getNotesSwitchLabel(
    'Company Notes',
    companyNotesDisabled
  );

  // Canvas: update custom notes text
  useEffect(() => {
    if (api) {
      const updateNotesTimeout = setTimeout(() => {
        getArray(customNotesData).forEach((n) => {
          const noteInstance = api.getNote(n.id);

          if (noteInstance) {
            noteInstance.update(n);
          }
        });
      }, 500);

      return () => clearTimeout(updateNotesTimeout);
    }
  }, [customNotesData, api]);

  // Canvas: update notes visibility
  useEffect(() => {
    const notesState = {
      county: showCountyNotes,
      utility: showUtilityNotes,
      company: showCompanyNotes,
      custom: showCustomNotes,
    };
    if (api) {
      api.getNotes().forEach((note) => {
        note.update({
          visible: notesState[note.data.type],
        });
      });
    }
  }, [
    showCompanyNotes,
    showCountyNotes,
    showCustomNotes,
    showUtilityNotes,
    api,
  ]);

  useEffect(() => {
    const isAllNotes = [
      countyNotesDisabled || showCountyNotes,
      utilityNotesDisabled || showUtilityNotes,
      companyNotesDisabled || showCompanyNotes,
      showCustomNotes,
    ].every(Boolean);

    setValue('notes_all', isAllNotes);
  }, [
    companyNotesDisabled,
    countyNotesDisabled,
    setValue,
    showCountyNotes,
    showCompanyNotes,
    showCustomNotes,
    showUtilityNotes,
    utilityNotesDisabled,
  ]);

  const handleAllNotesChange = useCallback(() => {
    if (!countyNotesDisabled) {
      setValue('notes_county', !showAllNotes);
    }
    if (!utilityNotesDisabled) {
      setValue('notes_utility', !showAllNotes);
    }
    if (!companyNotesDisabled) {
      setValue('notes_company', !showAllNotes);
    }
    setValue('notes_custom', !showAllNotes);
  }, [
    countyNotesDisabled,
    utilityNotesDisabled,
    companyNotesDisabled,
    setValue,
    showAllNotes,
  ]);

  const handleAddCustomNote = useCallback(() => {
    const newNote = new Note();

    if (api) {
      api.addNote({
        ...newNote,
        type: 'custom',
        visible: true,
      });
    }
    append(newNote);
  }, [append, api]);

  return (
    <ProgressBox progress={loading}>
      <Stack direction="column" spacing={2}>
        <SwitchField.Control
          name="notes_all"
          label="All Notes"
          onChange={handleAllNotesChange}
        />

        <SwitchField.Control
          name="notes_county"
          label={countyNotesLabel}
          disabled={countyNotesDisabled}
        />

        <SwitchField.Control
          name="notes_utility"
          label={utilityNotesLabel}
          disabled={utilityNotesDisabled}
        />

        <SwitchField.Control
          name="notes_company"
          label={companyNotesLabel}
          disabled={companyNotesDisabled}
        />

        <SwitchField.Control name="notes_custom" label="Custom Notes" />
      </Stack>

      <Collapse in={showCustomNotes}>
        <Box mt={3}>
          {customNotes.map((n, i) => {
            const handleRemoveNote = () => {
              api.removeNote(n.id);
              remove(i);
            };
            return (
              <Box key={n.field_id} mb={1}>
                <TextField.Control
                  required
                  multiline
                  minRows={5}
                  maxRows={10}
                  hideAsterisk
                  name={`custom_notes.${i}.text`}
                  label={
                    <Center justifyContent="space-between">
                      <span>Custom Note {i + 1}</span>

                      <IconButton size="small" onClick={handleRemoveNote}>
                        <CloseIcon />
                      </IconButton>
                    </Center>
                  }
                />
              </Box>
            );
          })}

          <Box mt={2}>
            <Button
              size="medium"
              fullWidth={false}
              variant="outlined"
              onClick={handleAddCustomNote}
            >
              Add note
            </Button>
          </Box>
        </Box>
      </Collapse>

      <Box mt={4}>
        <Collapse in={showErrors && marksError}>
          <Box mb={2}>
            <Message variant="error">String/Wire marks are required!</Message>
          </Box>
        </Collapse>

        <ProjectSubmitButton
          nextStep={nextStep}
          allowContinue={false}
          isFirstSaving={isFirstSaving}
        />
      </Box>
    </ProgressBox>
  );
};

export default LineDiagramForm;
