import { Typography, Box } from '@mui/material';

import { withProps } from 'hooks';
import { PANEL_TYPES_LIST, PANEL_TYPES_NAMES } from 'constants';
import { AdvancedSliderField, DeincrementField, FormArrayItem } from 'views';
import { Center, FormLabel, RadioButtons } from 'components';

const Text = withProps(Typography, {
  variant: 'body3',
  fontWeight: 400,
  display: 'block',
  lineHeight: 1.5,
});

const ArrayItem = (props) => {
  const { name, array, index, edit, ableRemove, onRemove, onEdit } = props;

  return (
    <FormArrayItem
      edit={edit}
      onEdit={onEdit}
      onRemove={onRemove}
      ableRemove={ableRemove}
      title={`Array #${index + 1}`}
      form={
        <Box mt={2}>
          <RadioButtons.Control
            fullWidth
            label="Type"
            minWidth={0}
            size="medium"
            variant="flat"
            options={PANEL_TYPES_LIST}
            defaultValue={array.panel_type}
            name={`${name}.${index}.panel_type`}
          />

          <AdvancedSliderField.Control
            angular
            max={360}
            label="Azimuth"
            name={`${name}.${index}.azimuth`}
            defaultValue={array.azimuth}
          />

          <AdvancedSliderField.Control
            max={90}
            angular
            label="Tilt"
            name={`${name}.${index}.pitch`}
            defaultValue={array.pitch}
          />

          <Center justifyContent="space-between" mt={2}>
            <FormLabel>Number of PV Panels</FormLabel>

            <Box width={180}>
              <DeincrementField.Control
                min={1}
                defaultValue={1}
                reserveHelperTextSpace={false}
                name={`${name}.${index}.panels_number`}
              />
            </Box>
          </Center>
        </Box>
      }
      description={
        <Box mt={2} pb={1}>
          <Text>Type: {PANEL_TYPES_NAMES[array.panel_type]}</Text>

          <Text>
            Azimuth: {array.azimuth}º, Tilt: {array.pitch}º
          </Text>

          <Text>Number of PV Panels: {array.panels_number}</Text>
        </Box>
      }
    />
  );
};

export default ArrayItem;
