import { forwardRef, lazy, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  tabClasses,
  tabsClasses,
  styled,
} from '@mui/material';

import { dev } from 'constants';
import { getArray, getFunc } from 'utils';
import { propagateRefs } from 'hooks';
import Ref from 'components/Ref';

const Root = styled(MuiTabs, {
  label: 'D4PV-Tabs',
  shouldForwardProp: (prop) => !['size', 'uppercase'].includes(prop),
})(({ theme, size, uppercase }) => ({
  [`&.${tabsClasses.root}`]: {
    borderBottom: `1px solid ${theme.palette.border.light}`,
    minHeight: 0,

    [`& .${tabsClasses.indicator}`]: {
      height: theme.spacing(size === 'small' ? 0.25 : 0.5),
      borderTopLeftRadius: theme.spacing(0.5),
      borderTopRightRadius: theme.spacing(0.5),
    },

    [`& .${tabClasses.root}`]: {
      minHeight: 0,
      textTransform: uppercase ? 'uppercase' : undefined,
      padding: theme.spacing(size === 'small' ? 1 : 2),
      fontWeight: size === 'small' ? 500 : 700,
      fontSize: size === 'small' ? theme.pxToRem(11) : undefined,
    },
  },
}));

const Tab = styled(MuiTab, {
  label: 'D4PV-Tab',
})(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    ...theme.typography.body2,
    fontWeight: 700,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [`&:not(.${tabClasses.selected})`]: {
      color: theme.palette.primary.main,
    },
  },
}));

const refProps = {
  nostyles: true,
};

const Tabs = forwardRef((props, ref) => {
  const {
    size,
    value,
    options,
    onValue,
    onChange,
    uppercase,
    textColor = 'secondary',
    indicatorColor = 'secondary',
    ...rest
  } = props;

  const handleChange = useCallback(
    (e, v) => {
      getFunc(onChange)(e, v);
      getFunc(onValue)(v);
    },
    [onChange, onValue]
  );

  return (
    <Root
      ref={ref}
      {...rest}
      size={size}
      value={value}
      uppercase={uppercase}
      onChange={handleChange}
      textColor={textColor}
      indicatorColor={indicatorColor}
    >
      {getArray(options).map(({ label, value, to, ...restProps }) => {
        const component = to ? Ref : undefined;
        const additionalProps = to ? refProps : undefined;

        return (
          <Tab
            key={value}
            {...additionalProps}
            {...restProps}
            to={to}
            label={label}
            value={value}
            component={component}
          />
        );
      })}
    </Root>
  );
});

Tabs.Control = forwardRef((props, ref) => {
  const {
    name,
    rules,
    options,
    onChange: customOnChange,
    defaultValue: customDefaultValue,
    ...rest
  } = props;

  const { control } = useFormContext();

  const defaultValue = useMemo(() => {
    return customDefaultValue || getArray(options)[0]?.value;
  }, [customDefaultValue, options]);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Tabs
          {...rest}
          options={options}
          ref={propagateRefs(field.ref, ref)}
          value={field.value}
          onChange={(e, v) => {
            field.onChange(v);
            getFunc(customOnChange)(e, v);
          }}
        />
      )}
    />
  );
});

if (dev) {
  Tabs.Demo = lazy(() => import('./Tabs.demo'));
}

export default Tabs;
