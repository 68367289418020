// PHASES
export const PHASES = {
  PHASE_1: '1',
  PHASE_3: '3',
};
export const PHASES_NAMES = {
  [PHASES.PHASE_1]: '1ph',
  [PHASES.PHASE_3]: '3ph',
};
export const PHASES_LIST = [PHASES.PHASE_1, PHASES.PHASE_3].map((value) => ({
  value,
  label: PHASES_NAMES[value],
}));

// VOLTAGES
export const SERVICE_VOLTAGES_PHASE_1 = {
  V_240: '240',
};
export const SERVICE_VOLTAGES_PHASE_1_NAMES = {
  [SERVICE_VOLTAGES_PHASE_1.V_240]: '120/240 V',
};
export const SERVICE_VOLTAGES_PHASE_1_LIST = [
  SERVICE_VOLTAGES_PHASE_1.V_240,
].map((value) => ({
  value,
  label: SERVICE_VOLTAGES_PHASE_1_NAMES[value],
}));

export const SERVICE_VOLTAGES_PHASE_3 = {
  V_208: '208',
  V_480: '480',
  V_600: '600',
};
export const SERVICE_VOLTAGES_PHASE_3_NAMES = {
  [SERVICE_VOLTAGES_PHASE_3.V_208]: '120/208 V',
  [SERVICE_VOLTAGES_PHASE_3.V_480]: '277/480 V',
  [SERVICE_VOLTAGES_PHASE_3.V_600]: '600 V',
};
export const SERVICE_VOLTAGES_PHASE_3_LIST = [
  SERVICE_VOLTAGES_PHASE_3.V_208,
  SERVICE_VOLTAGES_PHASE_3.V_480,
  SERVICE_VOLTAGES_PHASE_3.V_600,
].map((value) => ({
  value,
  label: SERVICE_VOLTAGES_PHASE_3_NAMES[value],
}));

// GEC
export const GEC_TYPES = {
  SEPARATE: 'SEPARATE',
  COMBINED: 'COMBINED',
};
export const GEC_TYPES_NAMES = {
  [GEC_TYPES.SEPARATE]: 'Separate GEC',
  [GEC_TYPES.COMBINED]: 'Combined GEC/EGC',
};
export const GEC_TYPES_LIST = [GEC_TYPES.SEPARATE, GEC_TYPES.COMBINED].map(
  (value) => ({
    value,
    label: GEC_TYPES_NAMES[value],
  })
);

export const SYSTEM_TYPES = {
  ACM: 'acm',
  MICRO: 'microinverter',
  STRING: 'string',
  DC_OPTIMIZED: 'dc_optimized',
};
export const SYSTEM_TYPES_NAMES = {
  [SYSTEM_TYPES.ACM]: 'ACM',
  [SYSTEM_TYPES.MICRO]: 'Micro',
  [SYSTEM_TYPES.STRING]: 'String',
  [SYSTEM_TYPES.DC_OPTIMIZED]: 'DC Optimized',
};
export const SYSTEM_TYPES_LIST = [
  SYSTEM_TYPES.ACM,
  SYSTEM_TYPES.MICRO,
  SYSTEM_TYPES.STRING,
  SYSTEM_TYPES.DC_OPTIMIZED,
].map((value) => ({
  value,
  label: SYSTEM_TYPES_NAMES[value],
}));
