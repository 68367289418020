import { toEnum } from 'utils';

export const ACCESS = toEnum({
  ANY: 0,
  GUEST: 1,
  USER: 2,
});

export const PLANS = toEnum({
  ANY: 0,
  FREE: 1,
  PREMIUM: 2,
});
