import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetProjectDetailsQuery,
  useGetPlacardImageQuery,
  useUploadToTemporaryStorageMutation,
  useSaveProjectPlacardsImageMutation,
} from 'store';

import {
  useAuth,
  useGetProjectEquipments,
  useIsLoading,
  useToggle,
} from 'hooks';

import { getArray, getString, sleep, svgToFile } from 'utils';
import { DEFAULT_CALLOUTS_LIST } from 'constants';
import { routes } from 'routes';

import { ProjectDetailsLayout } from 'layouts';
import { ConfirmModal } from 'views';
import { Form, useHandleQueryError, useMessage } from 'components';

import PlacardsForm from './PlacardsForm';
import PlacardsPreview from './PlacardsPreview';

const targetLayers = ['pv_panels', 'roof'];
const ignorePath = '/var/www/projects/design4pv-fs.dev.angleto.com';

const Placards = () => {
  const { project_id } = useParams();
  const diagramRef = useRef(null);
  const m = useMessage();
  const navigate = useNavigate();
  const { premium } = useAuth();

  const [api, setApi] = useState(null);
  const [layersRendering, toggleLayersRendering] = useToggle(true);
  const [placardsRendering, togglePlacardsRendering] = useToggle(true);
  const [equipmentsInited, toggleEquipmentsInited] = useToggle();
  const [confirmChangeData, setConfirmChangeData] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [callouts, setCallouts] = useState([...DEFAULT_CALLOUTS_LIST]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [submitLoading, toggleSubmitLoading] = useToggle();
  const [canvasChanges, toggleCanvasChanges] = useToggle();
  const [view, setView] = useState('main');
  const [elevationImage, setElevationImage] = useState(null);

  const queryProject = useGetProjectDetailsQuery({ project_id });
  const queryEquipments = useGetProjectEquipments({ project_id });
  const queryPlacard = useGetPlacardImageQuery({ project_id });
  const [uploadFile, uploadState] = useUploadToTemporaryStorageMutation();
  const [save, saveState] = useSaveProjectPlacardsImageMutation();

  useHandleQueryError({
    ...queryPlacard,
    type: 'warning',
  });

  const form = Form.useForm({
    defaultValues: {
      house_placard: true,
      plan_size: 1,
    },
  });
  const loading = useIsLoading(
    queryProject,
    queryPlacard,
    queryEquipments,
    submitLoading,
    uploadState,
    saveState
  );
  const canvasInited = [
    equipmentsInited,
    !layersRendering,
    !placardsRendering,
    !!api,
  ].every(Boolean);

  const project = queryProject.data;
  const equipmentsData = queryEquipments.data;
  const isFirstSaving = !project?.placard;
  const placard = queryPlacard.data?.image;

  const nextStep = useMemo(() => {
    if (!premium) {
      // TODO: Redirect to datasheets
      return routes.project_details.path({ project_id });
    }
    return routes.site_plan.path({ project_id });
  }, [premium, project_id]);

  const references = useMemo(() => {
    return [...equipments, ...callouts];
  }, [equipments, callouts]);

  const allowChange = useMemo(() => {
    const placedRefs = references.filter((r) => r.placed);
    return placedRefs.length === 0;
  }, [references]);

  const layers = useMemo(() => {
    return getArray(targetLayers)
      .map((name) =>
        getString(project?.property_plan?.[name]).split(ignorePath).join('')
      )
      .filter(Boolean);
  }, [project]);

  const confirmResetListener = useCallback(
    (setter) =>
      (...args) => {
        if (!allowChange) {
          setConfirmChangeData({ setter, args });
          return;
        }
        setter(...args);
      },
    [allowChange]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      toggleSubmitLoading.on();

      try {
        api.disableEdit();
        await sleep(200);
        const svg = await diagramRef.current.exportSvg();
        const file = svgToFile(svg);
        const metadata = api.getData();

        const file_metadata = {
          version: '2.0.0',
          ...formData,
          ...metadata,
        };
        const fileResponse = await uploadFile({
          body: { file },
        }).unwrap();

        const body = {
          file_metadata,
          file_id: fileResponse.file_key,
        };
        await save({ project_id, body }).unwrap();

        api.enableEdit();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
      }
      toggleSubmitLoading.off();
    },
    [
      m,
      toggleSubmitLoading,
      api,
      uploadFile,
      save,
      project_id,
      isFirstSaving,
      navigate,
      nextStep,
    ]
  );

  const handleResetForm = useCallback(() => {
    setConfirmChangeData((d) => {
      if (d.setter) {
        setEquipments((prev) =>
          prev.map((p) => ({
            ...p,
            placed: false,
          }))
        );
        setCallouts((prev) =>
          prev.map((p) => ({
            ...p,
            placed: false,
          }))
        );
        api.clearItems();
        d.setter(...d.args);
        setSelectedItemId(null);
      }
      return null;
    });
  }, [api]);

  // Prefill
  useEffect(() => {
    if (api && canvasInited && project?.placard?.metadata) {
      const { metadata } = project.placard;

      api.commitData = {
        scale: metadata.scale,
        layers: metadata.layers,
        elevation: metadata.elevation,
        references: metadata.references,
      };
      const savedEquipments = getArray(metadata.references).filter(
        (ref) => ref.type === 'equipment'
      );
      const savedCallouts = getArray(metadata.references).filter(
        (ref) => ref.type === 'callout'
      );
      setEquipments((prev) => {
        return prev.map((eq) => {
          const placed = !!savedEquipments.find((seq) => seq.id === eq.id);
          return { ...eq, placed };
        });
      });
      setCallouts((prev) => {
        const newCallouts = prev.map((cal) => {
          const placed = !!savedCallouts.find((scal) => scal.id === cal.id);
          return { ...cal, placed };
        });
        const custom = savedCallouts.filter((cal) => cal.custom);
        return [...newCallouts, ...custom];
      });
      api.discardEdit();

      [...savedEquipments, ...savedCallouts].forEach((ref) => {
        api.addReference(ref);
      });
      setElevationImage(metadata.elevation?.image || null);

      form.reset({
        plan_size: metadata.plan_size,
        equipment_elevation: metadata.equipment_elevation,
        caution_message: metadata.caution_message,
        house_placard: metadata.house_placard,
        north_angle: metadata.north_angle,
      });
    }
  }, [api, canvasInited, project, form]);

  // Initialize canvas placards preview
  useEffect(() => {
    if (canvasInited) {
      api.commitEdit();
    }
  }, [api, canvasInited]);

  // Canvas events callbacks
  useEffect(() => {
    if (api) {
      api.addListener('select', setSelectedItemId);
      api.addListener('change', toggleCanvasChanges.on);

      return () => {
        api.removeListener('select');
        api.removeListener('change');
      };
    }
  }, [api, toggleCanvasChanges]);

  // Initial layers rendering
  useEffect(() => {
    if (layersRendering && !loading && api) {
      if (layers.length < targetLayers.length) {
        m.warning('Not all the layers found, or loading error...');
      }
      api.setLayers(layers, toggleLayersRendering.off);
    }
  }, [m, layersRendering, loading, layers, api, toggleLayersRendering]);

  // Initial placards rendering
  useEffect(() => {
    if (placardsRendering && !loading && api) {
      if (placard) {
        api.addPlacard(placard, togglePlacardsRendering.off);
        return;
      }
      togglePlacardsRendering.off();
    }
  }, [placardsRendering, loading, api, placard, togglePlacardsRendering]);

  // Equipments initial setup
  useEffect(() => {
    if (equipmentsData) {
      setEquipments(
        [...equipmentsData].map((eq) => ({
          ...eq,
          id: eq.value,
          type: 'equipment',
        }))
      );
      toggleEquipmentsInited.on();
    }
  }, [equipmentsData, toggleEquipmentsInited]);

  // Canvas: references adding
  // TODO: add references from electricalplanpreview instead
  useEffect(() => {
    if (api) {
      references.forEach((ref) => {
        if (ref.placed) {
          api.addReference(ref);
        }
      });
    }
  }, [api, references]);

  return (
    <>
      <ConfirmModal
        open={!!confirmChangeData}
        onConfirm={handleResetForm}
        onClose={() => setConfirmChangeData(null)}
        title="Reset layout?"
        content="While rotating or scaling the image, you may lose markings that you've placed. Please confirm if you still want to rotate or scale the image."
      />

      <ProjectDetailsLayout
        title="Placards"
        component={Form}
        onSubmit={handleSubmit}
        form={{
          ...form,
          allowChange,
          api,
          callouts,
          canvasChanges,
          canvasInited,
          changeHandler: confirmResetListener,
          diagramRef,
          elevationImage,
          equipments,
          isFirstSaving,
          loading,
          nextStep,
          onCalloutsChange: setCallouts,
          onCanvasChange: toggleCanvasChanges.on,
          onEquipmentsChange: setEquipments,
          onReady: setApi,
          onSelectItem: setSelectedItemId,
          onViewChange: setView,
          project_id,
          selectedItemId,
          setElevationImage,
          view,
        }}
        body={<PlacardsForm />}
      >
        <PlacardsPreview />
      </ProjectDetailsLayout>
    </>
  );
};

export default Placards;
