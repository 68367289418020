import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  useGetProjectDetailsQuery,
  useGetProjectElectricalSummaryQuery,
  useGetProjectBillOfMaterialQuery,
  useSaveProjectElectricalTablesImageMutation,
  useUploadToTemporaryStorageMutation,
} from 'store';

import { routes } from 'routes';
import { sleep, svgToFile } from 'utils';
import { ProjectDetailsLayout } from 'layouts';
import { ProjectSubmitButton } from 'views';
import { Form, ProgressBox, SwitchField, useMessage } from 'components';

import ElectricalTablesPreview from './ElectricalTablesPreview';

const ElectricalTables = () => {
  const { project_id } = useParams();
  const navigate = useNavigate();

  const [api, setApi] = useState(null);

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const { data: summaryData, isFetching: summaryLoading } =
    useGetProjectElectricalSummaryQuery({ project_id });

  const { data: bomData, isFetching: bomLoading } =
    useGetProjectBillOfMaterialQuery({ project_id });

  const [update, { isLoading: updateLoading }] =
    useSaveProjectElectricalTablesImageMutation();

  const [uploadFile, { isLoading: fileLoading }] =
    useUploadToTemporaryStorageMutation();

  const m = useMessage();
  const form = Form.useForm();
  const diagramRef = useRef(null);
  const isFirstSaving = !project?.electrical_tables;

  const nextStep = useMemo(() => {
    return routes.site_plan.path({ project_id });
  }, [project_id]);

  const loading =
    isFetching || summaryLoading || bomLoading || updateLoading || fileLoading;

  useEffect(() => {
    if (api && summaryData && bomData && project) {
      const isBomVisible = !!project?.electrical_tables?.metadata?.bom;

      api.updateTables({
        ...summaryData,
        bom: bomData,
      });
      api.toggleBom(isBomVisible);

      form.reset({
        bom: isBomVisible,
      });
    }
  }, [summaryData, api, bomData, project, form]);

  const handleBomToggle = useCallback(
    (v) => {
      api.toggleBom(v);
    },
    [api]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        await sleep(200);
        const svg = await diagramRef.current.exportSvg();
        const file = svgToFile(svg);
        const file_metadata = {
          version: '2.0.0',
          ...formData,
        };
        const fileResponse = await uploadFile({
          body: { file },
        }).unwrap();

        const body = {
          file_metadata,
          file_id: fileResponse.file_key,
        };
        await update({ project_id, body }).unwrap();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, uploadFile, project_id, update, navigate, nextStep, isFirstSaving]
  );

  return (
    <ProjectDetailsLayout
      form={{
        ...form,
        loading,
        diagramRef,
        project_id,
        onReady: setApi,
      }}
      title="Electrical Tables"
      component={Form}
      onSubmit={handleSubmit}
      body={
        <ProgressBox progress={loading}>
          <Box>
            <SwitchField.Control
              name="bom"
              label="Bill of Materials"
              onValue={handleBomToggle}
            />
          </Box>

          <Box mt={4}>
            <ProjectSubmitButton
              nextStep={nextStep}
              isFirstSaving={isFirstSaving}
            />
          </Box>
        </ProgressBox>
      }
    >
      <ElectricalTablesPreview />
    </ProjectDetailsLayout>
  );
};

export default ElectricalTables;
