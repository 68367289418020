import { useEffect, useState } from 'react';

import useMessage from './useMessage.hook';

const useHandleQueryError = (props) => {
  const { error, message, requestId, type } = props;

  const m = useMessage();
  const [stamp, setStamp] = useState(null);

  useEffect(() => {
    if (error && stamp !== requestId) {
      setStamp(requestId);
      m.responseError(error, {
        type,
        text: message,
      });
    }
  }, [error, m, message, requestId, stamp, type]);
};

export default useHandleQueryError;
