import { Box } from '@mui/material';

import { MSP_TYPES_LIST } from 'constants';
import { Delimiter, SelectField } from 'components';

import MspTypeTabs from './MspTypeTabs';
import MainServicePanelField from './MainServicePanelField';
import MainServiceCurrentRatingFields from './MainServiceCurrentRatingFields';

const MspFields = () => {
  return (
    <Box>
      <MspTypeTabs />

      <MainServicePanelField
        name="main_service_panel_id"
        label="Main Service Panel"
        placeholder="Type to search for MSP"
      />

      <MainServiceCurrentRatingFields />

      <Box mt={2}>
        <SelectField.Control
          name="msp_type"
          label="MSP type"
          displayEmpty={false}
          options={MSP_TYPES_LIST}
        />
      </Box>

      <Delimiter mt={1} />
    </Box>
  );
};

export default MspFields;
