import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Page } from 'react-pdf';
import { useFormContext } from 'react-hook-form';
import { Box, Stack } from '@mui/material';

import { selectors } from 'store';
import { useToggle, withProps } from 'hooks';
import { ProjectPreview } from 'views';
import {
  Button,
  Center,
  Icon,
  PdfPreview,
  ProgressBox,
  Ref,
  Text,
} from 'components';

const Link = withProps(Ref, {
  component: 'a',
});

const { selectPreviewCollapsed } = selectors;

const PreviewProjectPreview = () => {
  const { loading, getValues, selectedPage, project_file_url } =
    useFormContext();

  const containerRef = useRef(null);
  const [docLoading, toggleDocLoading] = useToggle();
  const [pages, setPages] = useState(0);
  const [width, setWidth] = useState(0);
  const collapsed = useSelector(selectPreviewCollapsed);

  const displayPage = useMemo(() => {
    if (!Number.isFinite(selectedPage)) {
      return null;
    }
    const pages = getValues('metadata');
    return pages.find((p) => p.page_number === selectedPage);
  }, [selectedPage, getValues]);

  useEffect(() => {
    setPages(0);

    if (displayPage) {
      toggleDocLoading.on();
    }
  }, [displayPage, toggleDocLoading]);

  useEffect(() => {
    const { current: containerElement } = containerRef;

    if (!containerElement) {
      return;
    }
    const listenResize = () => {
      const metrics = containerElement.getBoundingClientRect();
      setWidth(metrics.width - 40);
    };
    window.addEventListener('resize', listenResize);
    listenResize();

    const tid = setTimeout(() => {
      listenResize();
    }, 500);

    return () => {
      clearTimeout(tid);
      window.removeEventListener('resize', listenResize);
    };
  }, [collapsed]);

  const handlePageLoading = useCallback(
    (opts) => {
      const { numPages } = opts;
      toggleDocLoading.off();
      setPages(numPages);
    },
    [toggleDocLoading]
  );

  return (
    <ProjectPreview
      tools={
        <Center mx={4} height={1}>
          <Button
            download
            size="medium"
            variant="outlined"
            disabled={!project_file_url}
            href={project_file_url || undefined}
            blank={!!project_file_url || undefined}
            absolute={!!project_file_url || undefined}
            nostyles={!!project_file_url || undefined}
            component={project_file_url ? Link : undefined}
          >
            <Center gap={0.5}>
              <Icon.Download />
              <span>Download</span>
            </Center>
          </Button>
        </Center>
      }
    >
      <ProgressBox
        p={2}
        height={1}
        overflow="auto"
        ref={containerRef}
        progress={loading || docLoading}
      >
        {!displayPage && (
          <Center height={1}>
            <Text variant="subtitle3" color="border.main">
              No selected page
            </Text>
          </Center>
        )}

        {displayPage && (
          <PdfPreview
            loading={null}
            file={displayPage.page_url}
            onLoadSuccess={handlePageLoading}
          >
            <Stack spacing={2}>
              {new Array(pages).fill(null).map((v, index) => {
                return (
                  <Box
                    border="1px solid transparent"
                    borderColor="border.light"
                    key={`${displayPage.page_url}-${index}`}
                  >
                    <Page
                      scale={1}
                      width={width}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </Box>
                );
              })}
            </Stack>
          </PdfPreview>
        )}
      </ProgressBox>
    </ProjectPreview>
  );
};

export default PreviewProjectPreview;
