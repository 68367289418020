import { useMemo } from 'react';
import { Grid, Box } from '@mui/material';

import { useGetAcSubpanelListQuery } from 'store';
import { BUS_RATING_LIST, BREAKER_RATING_LIST } from 'constants';
import { getArray } from 'utils';
import { useWatchField } from 'hooks';
import { SelectField } from 'components';

import AcSubpanelField from './AcSubpanelField';

const AcCurrentRatingFields = () => {
  const subpanel_panel_type = useWatchField('subpanel_panel_type');
  const subpanel_id = useWatchField('subpanel_id');
  const isVendor = subpanel_panel_type === 'vendor';

  const { data: subpanelList } = useGetAcSubpanelListQuery();

  const subpanel = useMemo(() => {
    return getArray(subpanelList).find((sub) => sub.id === subpanel_id);
  }, [subpanelList, subpanel_id]);

  const breakerOptions = useMemo(() => {
    return BREAKER_RATING_LIST.filter((cur) => {
      return !isVendor || cur.value >= subpanel?.buss_rating;
    });
  }, [subpanel, isVendor]);

  return (
    <Box mt={2}>
      <AcSubpanelField
        name="subpanel_id"
        label="AC Subpanel"
        placeholder="Type to search for AC Subpanel"
      />

      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={6}>
          <SelectField.Control
            label="Subpanel Bus"
            disabled={isVendor}
            displayEmpty={isVendor}
            name="subpanel_data.bus_rating"
            options={BUS_RATING_LIST}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectField.Control
            label="Subpanel Breaker"
            name="subpanel_data.breaker_rating"
            options={breakerOptions}
            disabled={isVendor && !subpanel_id}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectField.Control
            label="Feed by"
            name="subpanel_data.feed_by"
            options={BREAKER_RATING_LIST}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AcCurrentRatingFields;
