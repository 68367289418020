import { forwardRef, lazy, useMemo, useState } from 'react';

import { dev } from 'constants';
import { getArray, getString } from 'utils';
import { useSearchAhjByNameQuery } from 'store';
import { Autocomplete } from 'components';

const AhjField = forwardRef((props, ref) => {
  const { state, disabled: customDisabled, ...rest } = props;

  const [inputValue, setInputValue] = useState('');

  const disabled = customDisabled || !state;

  const body = useMemo(
    () => ({
      state,
      query: inputValue,
    }),
    [inputValue, state]
  );

  const { data, isFetching } = useSearchAhjByNameQuery(
    { body },
    {
      skip: getString(inputValue).length < 3 || disabled,
    }
  );

  const options = useMemo(() => {
    return getArray(data?.ahj_list).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [data]);

  return (
    <Autocomplete
      ref={ref}
      options={options}
      loading={isFetching}
      disabled={disabled}
      inputValue={inputValue}
      onInputValue={setInputValue}
      {...rest}
    />
  );
});

AhjField.Control = forwardRef((props, ref) => {
  const { ControlComponent = AhjField, ...rest } = props;

  return (
    <Autocomplete.Control
      ref={ref}
      ControlComponent={ControlComponent}
      {...rest}
    />
  );
});

if (dev) {
  AhjField.Demo = lazy(() => import('./AhjField.demo'));
}

export default AhjField;
