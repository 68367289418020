import { Box, Stack, Typography } from '@mui/material';

import { UTILITIES_LIST, GROUNDING_LIST } from 'constants';
import { SelectField, TextField } from 'components';

const ServiceFields = () => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle2">Service</Typography>

      <Stack spacing={1} mt={2}>
        <SelectField.Control
          displayEmpty={false}
          name="service_grounding"
          label="Service Grounding"
          options={GROUNDING_LIST}
        />

        <SelectField.Control
          displayEmpty={false}
          name="utility_service"
          label="Utility Service Input"
          options={UTILITIES_LIST}
        />

        <TextField.Control
          name="utility_meter_label"
          label="Utility Meter Number (optional)"
          placeholder="Type serial number"
        />
      </Stack>
    </Box>
  );
};

export default ServiceFields;
