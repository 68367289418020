import { lazy } from 'react';
import { Typography } from '@mui/material';

import { withProps } from 'hooks';
import { dev } from 'constants';
import { Center, Modal, Button as _Button } from 'components';

const Button = withProps(_Button, {
  fullWidth: false,
  minWidth: 165,
});

const ConfirmModal = (props) => {
  const { open, onClose, onConfirm, title, content } = props;

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <Typography variant="body4">{content}</Typography>

      <Center gap={2} mt={6}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <Button onClick={onConfirm}>Confirm</Button>
      </Center>
    </Modal>
  );
};

if (dev) {
  ConfirmModal.Demo = lazy(() => import('./ConfirmModal.demo'));
}

export default ConfirmModal;
