import { useCallback, useMemo } from 'react';
import Dec from 'decimal.js';

const def = {};

const useDeincrement = (value, setter, props = def) => {
  const { step = 1, min = -Infinity, max = Infinity, passArgs } = props;

  const handleIncrement = useCallback(
    (...args) => {
      const _step = new Dec(step);
      const _value = new Dec(value);
      const res = _value.plus(_step).toNumber();
      const additionalArgs = passArgs ? args : [];
      setter(...additionalArgs, Math.min(res, max));
    },
    [setter, step, max, value, passArgs]
  );

  const handleDecrement = useCallback(
    (...args) => {
      const _step = new Dec(step);
      const _value = new Dec(value);
      const res = _value.minus(_step).toNumber();
      const additionalArgs = passArgs ? args : [];
      setter(...additionalArgs, Math.max(res, min));
    },
    [min, passArgs, setter, step, value]
  );

  return useMemo(
    () => [handleIncrement, handleDecrement],
    [handleDecrement, handleIncrement]
  );
};

export default useDeincrement;
