import { useMemo } from 'react';

import { useGetProjectDetailsQuery } from 'store';
import { getArray, getString } from 'utils';

const def = {};

const useGetLineDiagramMetadata = (props = def) => {
  const { project_id } = props;

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const metadata = useMemo(() => {
    if (!project) {
      return null;
    }
    const data = project?.line_diagram?.metadata;

    if (!data) {
      return null;
    }
    const version = getString(data.version);
    const [major = 1 /* , minor, patch */] = version
      .split('.')
      .map((v) => Number.parseInt(v))
      .map((v) => (Number.isFinite(v) ? v : 0));

    if (major < 2) {
      // Transform old versions format to actual one
      return {
        scale: data.scale,
        notes_state: {
          notes_all: !!data.allNotes,
          notes_company: !!data.clientNotes,
          notes_county: !!data.countyNotes,
          notes_utility: !!data.utilityNotes,
          notes_custom: !!data.customNotes,
        },
        notes: getArray(data.notes).map((n) => ({
          id: n.id,
          x: n.x,
          y: n.y,
          text: n.note.text,
          type: n.type === 'client' ? 'company' : n.type,
        })),
        legend: data.legend,
        marks: [
          ...getArray(data.strings).map((str) => ({
            id: `string-${str.id}`,
            text: str.text,
            x: str.x,
            y: str.y,
          })),
          ...getArray(data.wires).map((wire) => ({
            id: `wire-${wire.id}`,
            text: wire.text,
            x: wire.x,
            y: wire.y,
          })),
        ],
      };
    }
    return data;
  }, [project]);

  return useMemo(() => {
    return [metadata, { loading: isFetching }];
  }, [isFetching, metadata]);
};

export default useGetLineDiagramMetadata;
