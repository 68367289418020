import { lazy, useCallback } from 'react';

import { getFunc } from 'utils';
import { useToggle } from 'hooks';
import { dev } from 'constants';
import { ReferenceItem, ToolbarDropdown } from 'views';
import { Draggable, Icon } from 'components';

const PvArraysDropdown = (props) => {
  const { arrays, onDrop, onDragStart, ...rest } = props;
  const [open, toggleOpen] = useToggle();

  const handleDrop = useCallback(
    (equipment, data) => {
      getFunc(onDrop)(equipment, data);
      toggleOpen.off();
    },
    [onDrop, toggleOpen]
  );

  return (
    <ToolbarDropdown
      {...rest}
      open={open}
      onOpen={toggleOpen.on}
      onClose={toggleOpen.off}
      icon={<Icon.PvArray />}
      label="PV Arrays"
      direction="column"
      containerWidth="auto"
    >
      {arrays.map((eq) => (
        <ReferenceItem
          key={eq.value}
          placeholder
          label={eq.label}
          position="relative"
        >
          {!eq.placed && (
            <Draggable
              onDragStart={onDragStart}
              onDrop={(e, data) => handleDrop(eq, data)}
            >
              <ReferenceItem label={eq.label} />
            </Draggable>
          )}
        </ReferenceItem>
      ))}
    </ToolbarDropdown>
  );
};

if (dev) {
  PvArraysDropdown.Demo = lazy(() => import('./PvArraysDropdown.demo'));
}

export default PvArraysDropdown;
