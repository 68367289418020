import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { PaperCanvas, ProjectPreview } from 'views';
import { ProgressBox } from 'components';

import Controller from './electricalTables.controller';

const ElectricalTablesPreview = () => {
  const { loading, onReady, diagramRef } = useFormContext();

  return (
    <ProjectPreview>
      <ProgressBox progress={loading} height={1}>
        <Box height={1} p={5} overflow="auto">
          <PaperCanvas
            onReady={onReady}
            diagramRef={diagramRef}
            controller={Controller}
          />
        </Box>
      </ProgressBox>
    </ProjectPreview>
  );
};

export default ElectricalTablesPreview;
