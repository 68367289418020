import { useMemo } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { Grid, Typography, Stack } from '@mui/material';

import { withProps } from 'hooks';
import { useGetCountyByAhjQuery } from 'store';
import { Center, Modal, ProgressBox } from 'components';

const Caption = withProps(Typography, {
  variant: 'caption',
  color: 'text.label',
});
const Text = withProps(Typography, {
  variant: 'subtitle4',
});

const ApplicableCodesModal = (props) => {
  const { ...rest } = props;

  const { control } = useFormContext();
  const ahj = useWatch({ control, name: 'ahj' });
  const ahj_id = ahj?.value;

  const title = useMemo(
    () => (ahj?.label ? `Effective Codes of ${ahj.label}` : 'Effective Codes'),
    [ahj]
  );

  const { data: county, isFetching } = useGetCountyByAhjQuery(
    { ahj_id },
    {
      skip: !ahj_id,
    }
  );

  return (
    <Modal {...rest} title={title}>
      <ProgressBox progress={isFetching} pt={2} pb={4}>
        {!county && <Center>No data found...</Center>}

        {county && (
          <Grid container rowSpacing={4}>
            <Grid item xs={6}>
              <Stack spacing={1}>
                <Caption>Residential Code Enforced</Caption>

                <Text>{county.residential_code}</Text>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={1}>
                <Caption>Electrical Code Enforced</Caption>

                <Text>{county.electrical_code}</Text>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={1}>
                <Caption>Fire Code Enforced</Caption>

                <Text>{county.fire_code}</Text>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={1}>
                <Caption>Building Code Enforced</Caption>

                <Text>{county.building_code}</Text>
              </Stack>
            </Grid>
          </Grid>
        )}
      </ProgressBox>
    </Modal>
  );
};

export default ApplicableCodesModal;
