import { Controller, useFormContext } from 'react-hook-form';

const ControlValue = (props) => {
  const { name, value } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={() => null}
    />
  );
};

export default ControlValue;
