import { forwardRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';

const makeClasses = (Component, ...preset) => {
  return (styles) => {
    const Draft = styled(Component, ...preset)();

    return Object.entries(styles).reduce((Prev, [cls, styles]) => {
      return styled(
        forwardRef((props, ref) => {
          const { className, classes: clss, ...rest } = props;

          const classes = useMemo(
            () => ({
              ...clss,
              [cls]: className,
            }),
            [clss, className]
          );

          return <Prev classes={classes} ref={ref} {...rest} />;
        })
      )((...stylesProps) =>
        typeof styles === 'function' ? styles(...stylesProps) : styles
      );
    }, Draft);
  };
};

export default makeClasses;
