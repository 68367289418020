export const DEFAULT_CALLOUTS_LIST = [
  {
    type: 'callout',
    id: 'area_of_work',
    value: 'area_of_work',
    label: 'AREA OF WORK',
  },
  {
    type: 'callout',
    id: 'entrance',
    value: 'entrance',
    label: 'ENTRANCE',
  },
];
