import { useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Stack, Typography, styled } from '@mui/material';

import { capitalize } from 'utils';
import { FormLabel, Icon, Slip } from 'components';

const Root = styled(Slip)(() => ({
  cursor: 'grab',
}));

const StructureCard = (props) => {
  const { id, label, panel_type, azimuth, panels_number, index } = props;

  const description = useMemo(() => {
    const type = capitalize(panel_type);
    const az = `${azimuth}º`;
    const panels = `${panels_number} panels`;
    return `${type} ${az}, ${panels}`;
  }, [panel_type, azimuth, panels_number]);

  return (
    <Draggable index={index} draggableId={id} type={panel_type}>
      {(provided) => (
        <Root
          elevation={1}
          minHeight={64}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Stack spacing={1} direction="row">
            <Icon.Moving sx={{ color: 'border.main' }} />

            <Stack py={0.5}>
              <Typography variant="subtitle5">{label}</Typography>
              <FormLabel>{description}</FormLabel>
            </Stack>
          </Stack>
        </Root>
      )}
    </Draggable>
  );
};

export default StructureCard;
