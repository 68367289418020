import { forwardRef, lazy, useMemo } from 'react';
import { Box, ClickAwayListener, Typography, styled } from '@mui/material';

import { useToggle } from 'hooks';
import { getArray } from 'utils';
import { dev } from 'constants';
import { CompanyNote } from 'views';
import { Icon } from 'components';

const Root = styled(Box, {
  label: 'RequirementsRoot',
  shouldForwardProp: (prop) => !['open'].includes(prop),
})(({ theme, open }) => ({
  right: 0,
  top: '50%',
  position: 'absolute',
  transform: `translate(${open ? 0 : 100}%, -50%)`,
  transition: theme.transitions.create(),
  '&:hover': {
    transform: open ? undefined : 'translate(calc(100% - 10px), -50%)',
  },
}));

const ContentContainer = styled(Box, {
  label: 'RequirementsContentContainer',
  shouldForwardProp: (prop) => !['open'].includes(prop),
})(({ theme, open }) => ({
  width: 400,
  height: 500,
  overflow: 'hidden',
  padding: theme.spacing(2, 0, 2, 2),
  transition: theme.transitions.create(),
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.border.light}`,
  borderRightColor: theme.palette.common.white,
  boxShadow: `-5px 0px 8px ${theme.alpha(
    theme.palette.colors.shadow,
    open ? 0.1 : 0
  )}`,
}));

const Trigger = styled(Box, {
  label: 'RequirementsTrigger',
})(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1),
  position: 'absolute',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.border.light}`,
  borderLeftColor: theme.palette.common.white,
  borderTopRightRadius: theme.radius(1),
  borderBottomRightRadius: theme.radius(1),
  left: 1,
  top: 0,
  transform: 'translateX(-100%) rotate(180deg)',
  writingMode: 'vertical-lr',
  boxShadow: `5px 0px 8px ${theme.alpha(theme.palette.colors.shadow, 0.1)}`,
}));

const Content = styled(Box, {
  label: 'RequirementsContent',
  shouldForwardProp: (prop) => ![prop].includes('empty'),
})(({ empty }) => ({
  display: 'flex',
  overflow: 'hidden',
  minHeight: '100%',
  height: '100%',
  width: '100%',
  alignItems: empty ? 'center' : undefined,
  justifyContent: empty ? 'center' : undefined,
}));

const Requirements = forwardRef((props, ref) => {
  const { requirements, ...rest } = props;

  const [open, toggleOpen] = useToggle();

  const content = useMemo(() => {
    if (!requirements) {
      return null;
    }
    return Object.values(requirements).reduce(
      (all, req) => [...all, ...getArray(req)],
      []
    );
  }, [requirements]);

  const isEmpty = getArray(content).length === 0;

  return (
    <ClickAwayListener onClickAway={toggleOpen.off}>
      <Root open={open} ref={ref} {...rest}>
        <ContentContainer open={open}>
          <Trigger onClick={toggleOpen}>
            <Icon.ArrowDown rotate={90 + (open ? 0 : 180)} color="secondary" />
            <Typography variant="subtitle2">Requirements</Typography>
          </Trigger>

          <Content empty={isEmpty}>
            {isEmpty && (
              <Typography variant="subtitle2">No requirements found</Typography>
            )}

            {!isEmpty && (
              <Box overflow="auto" height={1} pr={2} w={1} flexGrow={1}>
                {content.map((c) => (
                  <Box mb={4} key={c.id}>
                    <CompanyNote editable={false} note={c} />
                  </Box>
                ))}
              </Box>
            )}
          </Content>
        </ContentContainer>
      </Root>
    </ClickAwayListener>
  );
});

if (dev) {
  Requirements.Demo = lazy(() => import('./Requirements.demo'));
}

export default Requirements;
