import { forwardRef } from 'react';

const defProps = {};

/**
 * Allows to define default component props. Usage:
 *
 *  const Center = withProps(Box, {
 *    display: 'flex',
 *    justifyContent: 'center',
 *    alignItems: 'center',
 *  });
 *  ...
 *  <Center width="200px" height="150px">Centered text</Center>
 *
 * Predefined props could be rewrited:
 *
 *  <Center justifyContent="space-between">
 *    <div>Content 1</div>
 *    <div>Content 2</div>
 *  </Center
 */
const withProps = (Component, defaultProps = defProps) => {
  const Wrapper = (props, ref) => (
    <Component ref={ref} {...defaultProps} {...props} />
  );
  return forwardRef(Wrapper);
};

export default withProps;
