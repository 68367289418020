import { SYSTEM_TYPES } from 'constants';
import { MSP_TYPES } from 'constants';
import { useMemo } from 'react';
import { useGetWteListQuery } from 'store';
import { useGetProjectDetailsQuery } from 'store';

const def = {};

const METER_MAIN_OPTIONS = [
  MSP_TYPES.MM_COMBO_PANEL_TOP_BOTTOM_FED,
  MSP_TYPES.MM_COMBO_PANEL_CENTER_FED,
  MSP_TYPES.MM_RMA,
  MSP_TYPES.MM_SERVICE_DISCONNECT,
  MSP_TYPES.MM_TWO_SUB_MAIN,
  MSP_TYPES.MM_BUS_SUB_MAIN,
  MSP_TYPES.SOLAR_READY_MM,
];

const useGetProjectEquipments = (props = def) => {
  const { project_id } = props;

  const { data: project, isFetching } = useGetProjectDetailsQuery(
    { project_id },
    {
      skip: !project_id,
    }
  );
  const { data: wteData, isFetching: wteLoading } = useGetWteListQuery();

  const isLoading = [isFetching, wteLoading].some(Boolean);

  const data = useMemo(() => {
    if (isLoading) {
      return null;
    }
    const equipments = [];

    const {
      combiner_data,
      combiner_id,
      pv_meter_data,
      disconnect_id: extra_disconnect_id,
      disconnect_label: extra_disconnect_label,
    } = project.bos;

    const {
      msp_type,
      subpanel_data,
      subpanel_id,
      disconnect_id,
      disconnect_label,
    } = project.interconnection;

    if (!METER_MAIN_OPTIONS.includes(msp_type)) {
      equipments.push(
        {
          value: 'utility_meter',
          label: 'Utility Meter',
        },
        {
          value: 'main_service_panel',
          label: 'Main Service Panel',
        }
      );
    } else {
      equipments.push({
        value: 'main_service_panel',
        label: 'Meter/Main',
      });
    }
    if (subpanel_data || subpanel_id) {
      equipments.push({
        value: 'sub_panel',
        label: 'Sub Panel',
      });
    }
    if (combiner_data || combiner_id) {
      equipments.push({
        value: 'ac_combiner_panel',
        label: 'AC Combiner Panel',
      });
    }
    if (disconnect_id) {
      equipments.push({
        value: 'ac_disconnect',
        label: disconnect_label || 'AC Disconnect',
      });
    }
    if (extra_disconnect_id) {
      equipments.push({
        value: 'extra_ac_disconnect',
        label: extra_disconnect_label || 'AC Disconnect+',
      });
    }
    if (pv_meter_data) {
      equipments.push({
        value: 'pv_meter',
        label: pv_meter_data?.label || 'PV Meter',
      });
    }
    // Assume that all inverters will have the same type
    const { system_type } = project.systems[0];

    if (
      [SYSTEM_TYPES.STRING, SYSTEM_TYPES.DC_OPTIMIZED].includes(system_type)
    ) {
      equipments.push({
        value: project.systems.length === 1 ? 'inverter' : 'inverters',
        label: project.systems.length === 1 ? 'Inverter' : 'Inverters',
      });
    }
    project.systems.forEach((sys, i) => {
      const wte = wteData.find((item) => item.id === sys.wte_id);

      if (wte && wte?.type !== 'MC4_Y') {
        equipments.push({
          value: `wte_${i}`,
          label: wte.title,
        });
      }
    });
    return equipments;
  }, [isLoading, project, wteData]);

  return useMemo(
    () => ({
      data,
      isLoading,
    }),
    [isLoading, data]
  );
};

export default useGetProjectEquipments;
