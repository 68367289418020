import { forwardRef, lazy } from 'react';
import {
  styled,
  Badge as MuiBadge,
  badgeClasses as classes,
} from '@mui/material';

import { dev } from 'constants';

const Root = styled(MuiBadge, {
  label: 'Badge',
  shouldForwardProp: (prop) => !['borderWidth', 'borderColor'].includes(prop),
})(({ borderWidth, borderColor }) => ({
  [`& .${classes.badge}`]: {
    border: `${borderWidth}px solid ${borderColor}`,
  },
}));

const Badge = forwardRef((props, ref) => {
  const { borderWidth = 0, borderColor = 'white', ...rest } = props;

  return (
    <Root
      ref={ref}
      borderWidth={borderWidth}
      borderColor={borderColor}
      {...rest}
    />
  );
});

if (dev) {
  Badge.Demo = lazy(() => import('./Badge.demo'));
}

export default Badge;
