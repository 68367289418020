import { forwardRef, lazy } from 'react';
import {
  FormLabel as MuiFormLabel,
  formLabelClasses as classes,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { dev } from 'constants';
import { getString } from 'utils';

const Root = styled(MuiFormLabel, {
  shouldForwardProp: (prop) => !['hideAsterisk'].includes(prop),
})(({ theme, hideAsterisk }) => ({
  [`&.${classes.root}`]: {
    ...theme.typography.caption,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.colors.label,
    cursor: 'inherit',
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.shortest,
    }),

    [`&.${classes.disabled}`]: {
      opacity: 0.5,
    },

    [`&.${classes.focused}`]: {
      color: theme.palette.primary.main,
    },

    [`&.${classes.error}`]: {
      color: theme.palette.error.light,

      [`&.${classes.focused}`]: {
        color: theme.palette.error.dark,
      },
    },

    [`& .${classes.asterisk}`]: {
      display: hideAsterisk ? 'none' : undefined,
    },
  },
}));

export const formLabelClasses = classes;

const FormLabel = styled(
  forwardRef((props, ref) => {
    const { hideAsterisk, children, readOnly, required, ...rest } = props;

    return (
      <Root
        ref={ref}
        {...rest}
        hideAsterisk={hideAsterisk}
        title={getString(children)}
        required={readOnly ? false : required}
      >
        {children}
      </Root>
    );
  })
)({});

if (dev) {
  FormLabel.Demo = lazy(() => import('./FormLabel.demo'));
}

export default FormLabel;
