import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Grid, Collapse } from '@mui/material';

import {
  useSaveRecalculatedWireSegmentMutation,
  useGetRecalculatedWireSegmentMutation,
  useGetWireGaugeListQuery,
  useGetWireTypeListQuery,
  useGetWireMaterialListQuery,
  useGetWireConduitTypeListQuery,
} from 'store';

import { NEUTRAL_METHOD, EGC_METHOD } from 'constants';
import { useIsLoading, useToggle, withProps } from 'hooks';
import { getArray, getResponseMessage } from 'utils';
import { WireTableView } from 'views';

import {
  Button as _Button,
  Center,
  Modal,
  ProgressBox,
  TextField as _TextField,
  SelectField as _SelectField,
  useMessage,
} from 'components';

const Button = withProps(_Button, {
  minWidth: 170,
  fullWidth: false,
});
const SelectField = withProps(_SelectField, {
  displayEmpty: false,
  noOptionValue: true,
});
const NumberField = withProps(_TextField, {
  type: 'number',
  inputProps: {
    step: 1,
    min: 0,
  },
});

const EditSegmentModal = (props) => {
  const {
    open,
    onClose,
    segment,
    project_id,
    station_id,
    neutral_view,
    voltage_drop_view,
    gec_strategy,
    neutral_strategy,
    temperature_rating,
    ...rest
  } = props;

  const segment_id = segment?.segment_id;
  const m = useMessage();
  const isTrunkCable = segment?.trunk_cable;

  const [initialize, toggleInitialize] = useToggle(true);
  const [source, setSource] = useState(null);
  const [error, setError] = useState(null);
  const [requestQuery, setRequestQuery] = useState(null);
  const [freeze, setFreeze] = useState(false);

  const [gauge, setGauge] = useState('');
  const [wire_type, setWireType] = useState('');
  const [material, setMaterial] = useState('');
  const [length, setLength] = useState('');
  const [lengthValue, setLengthValue] = useState('');
  const [ocpd, setOcpd] = useState('');
  const [gec_wire_gauge, setGecWireGauge] = useState('');
  const [gec_wire_type, setGecWireType] = useState('');
  const [gec_wire_material, setGecWireMaterial] = useState('');
  const [conduit_size, setConduitSize] = useState('');
  const [conduit_type, setConduitType] = useState('');
  const [current_carrying_conductors, setCurrentCarryingConductors] =
    useState('');

  const [current_carrying_conductors_value, setCurrentCarryingConductorsValue] =
    useState('');

  const [trunk_cable, setTrunkCable] = useState('');

  const [gaugeOptions, setGaugeOptions] = useState([]);
  const [wireTypeOptions, setWireTypeOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [ocpdOptions, setOcpdOptions] = useState([]);
  const [gecWireGaugeOptions, setGecWireGaugeOptions] = useState([]);
  const [gecWireTypeOptions, setGecWireTypeOptions] = useState([]);
  const [gecWireMaterialOptions, setGecWireMaterialOptions] = useState([]);
  const [conduitSizeOptions, setConduitSizeOptions] = useState([]);
  const [conduitTypeOptions, setConduitTypeOptions] = useState([]);
  const [trunkCableOptions, setTrunkCableOptions] = useState([]);

  const skip = { skip: !open };

  const gaugeQuery = useGetWireGaugeListQuery(undefined, skip);
  const typeQuery = useGetWireTypeListQuery(undefined, skip);
  const materialQuery = useGetWireMaterialListQuery(undefined, skip);
  const conduitQuery = useGetWireConduitTypeListQuery(undefined, skip);

  const [calcSegment, calcQuery] = useGetRecalculatedWireSegmentMutation();
  const [saveSegment, submitQuery] = useSaveRecalculatedWireSegmentMutation();

  const wireGauge = gaugeQuery.data;
  const wireType = typeQuery.data;
  const wireMaterial = materialQuery.data;
  const conduitTypes = conduitQuery.data;

  const loading = useIsLoading(
    gaugeQuery,
    typeQuery,
    materialQuery,
    conduitQuery,
    initialize,
    calcQuery,
    submitQuery
  );
  const isEgc = gec_strategy === EGC_METHOD.CUSTOM;
  const isOcpd = !!segment?.ocpd;

  const query = useMemo(() => {
    if (!open || !segment_id || initialize) {
      return null;
    }
    return {
      segment_id,
      project_id,
      params: {
        temperature_rating,
        gec_strategy,
        neutral_strategy:
          gec_strategy === EGC_METHOD.CUSTOM
            ? NEUTRAL_METHOD.CONDUCTOR
            : neutral_strategy,
      },
      body: {
        trunk_cable: trunk_cable || undefined,
        gauge,
        wire_type,
        material,
        ocpd,
        length,
        gec_wire_gauge,
        gec_wire_type,
        gec_wire_material,
        conduit_size,
        conduit_type,
        current_carrying_conductors,
      },
    };
  }, [
    trunk_cable,
    conduit_size,
    conduit_type,
    current_carrying_conductors,
    gauge,
    gec_strategy,
    gec_wire_gauge,
    gec_wire_material,
    gec_wire_type,
    initialize,
    length,
    material,
    neutral_strategy,
    ocpd,
    open,
    project_id,
    segment_id,
    temperature_rating,
    wire_type,
  ]);

  useEffect(() => {
    if (open && initialize && segment) {
      setGauge(segment?.conductor?.gauge);
      setWireType(segment?.conductor?.type);
      setMaterial(segment?.conductor?.material);
      setLength(segment?.length);
      setLengthValue(segment?.length);
      setOcpd(segment?.ocpd);
      setGecWireGauge(segment?.ground?.gauge);
      setGecWireType(segment?.ground?.type);
      setGecWireMaterial(segment?.ground?.material);
      setConduitSize(segment?.conduit?.size);
      setConduitType(segment?.conduit?.type);
      setCurrentCarryingConductors(segment?.max_current_carrying_conductors);
      setCurrentCarryingConductorsValue(
        segment?.max_current_carrying_conductors
      );
      setTrunkCable(segment?.trunk_cable?.id || '');
      toggleInitialize.off();
    }
  }, [initialize, open, segment, toggleInitialize]);

  const handleExited = useCallback(() => {
    toggleInitialize.on();
    setSource('');
    setError('');

    setGauge('');
    setWireType('');
    setMaterial('');
    setLength('');
    setLengthValue('');
    setOcpd('');
    setGecWireGauge('');
    setGecWireType('');
    setGecWireMaterial('');
    setConduitSize('');
    setConduitType('');
    setCurrentCarryingConductors('');
    setCurrentCarryingConductorsValue('');
    setTrunkCable('');

    setGaugeOptions([]);
    setWireTypeOptions([]);
    setMaterialOptions([]);
    setOcpdOptions([]);
    setGecWireGaugeOptions([]);
    setGecWireTypeOptions([]);
    setGecWireMaterialOptions([]);
    setConduitSizeOptions([]);
    setConduitTypeOptions([]);
    setTrunkCableOptions([]);
  }, [toggleInitialize]);

  const getSource = useCallback(
    async (data) => {
      try {
        const newSource = await calcSegment(data).unwrap();
        setSource(newSource);
        setError(null);
      } catch (ex) {
        setSource(null);
        setError(ex);
      }
    },
    [calcSegment]
  );

  useEffect(() => {
    setRequestQuery(query);

    if (query !== requestQuery && !freeze && query) {
      getSource(query);
    }
  }, [freeze, getSource, query, requestQuery]);

  useEffect(() => {
    if (!source || !wireType || !wireMaterial || !wireGauge || !conduitTypes) {
      return;
    }
    setFreeze(true);

    const cablesOptions = [...getArray(source?.acceptable_inputs?.cables)].map(
      (value) => ({ value, label: value })
    );
    const gecCableOptions = [
      ...getArray(source?.acceptable_inputs?.gec_cables),
    ].map((value) => ({ value, label: value }));
    const typeOptions = [...getArray(source?.acceptable_inputs?.wire_types)]
      .sort((a, b) => (a > b ? 1 : -1))
      .map((value) => ({ value, label: wireType[value] }));

    const matOptions = [...getArray(source?.acceptable_inputs?.wire_materials)]
      .sort((a, b) => (a > b ? 1 : -1))
      .map((value) => ({ value, label: wireMaterial[value] }));

    const newGaugeOptions = [...cablesOptions];
    const newWireTypeOptions = [...typeOptions];
    const newMaterialOptions = [...matOptions];

    const newOcpdOptions = [...getArray(source?.acceptable_inputs?.ocpds)].map(
      (value) => ({ value, label: value })
    );

    const newGecWireGaugeOptions = [...gecCableOptions];
    const newGecWireTypeOptions = [...typeOptions];
    const newGecWireMaterialOptions = [...matOptions];

    const newConduitSizeOptions = [
      ...getArray(source?.acceptable_inputs?.conduits),
    ].map((value) => ({ value, label: Number(value) }));

    const newConduitTypeOptions = [
      ...getArray(source?.acceptable_inputs?.conduit_types),
    ]
      .sort((a, b) => (a > b ? 1 : -1))
      .map((value) => ({ value, label: conduitTypes[value] }));

    const newTrunkCableOptions = [
      ...getArray(source?.acceptable_inputs?.trunk_cables),
    ].map((tc) => ({ value: tc.id, label: tc.cable_id }));

    setGaugeOptions(newGaugeOptions);
    setWireTypeOptions(newWireTypeOptions);
    setMaterialOptions(newMaterialOptions);
    setOcpdOptions(newOcpdOptions);
    setGecWireGaugeOptions(newGecWireGaugeOptions);
    setGecWireTypeOptions(newGecWireTypeOptions);
    setGecWireMaterialOptions(newGecWireMaterialOptions);
    setConduitSizeOptions(newConduitSizeOptions);
    setConduitTypeOptions(newConduitTypeOptions);
    setTrunkCableOptions(newTrunkCableOptions);

    setGauge(source?.segment?.conductor?.gauge);
    setWireType(source?.segment?.conductor?.type);
    setMaterial(source?.segment?.conductor?.material);
    setLength(source?.segment?.length);
    setLengthValue(source?.segment?.length);
    setOcpd(source?.segment?.ocpd);
    setGecWireGauge(source?.segment?.ground?.gauge);
    setGecWireType(source?.segment?.ground?.type);
    setGecWireMaterial(source?.segment?.ground?.material);
    setConduitSize(source?.segment?.conduit?.size);
    setConduitType(source?.segment?.conduit?.type);
    setCurrentCarryingConductors(
      source?.segment?.max_current_carrying_conductors
    );
    setCurrentCarryingConductorsValue(
      source?.segment?.max_current_carrying_conductors
    );
    setTrunkCable(source?.segment?.trunk_cable?.id || '');

    setTimeout(() => {
      setFreeze(false);
    }, 200);
  }, [conduitTypes, source, wireMaterial, wireType, wireGauge]);

  const handleSubmit = useCallback(async () => {
    try {
      await saveSegment(query).unwrap();
      onClose();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [saveSegment, query, onClose, m]);

  const rows = useMemo(() => {
    if (error) {
      return [];
    }
    return [source?.segment].filter(Boolean).map((s) => ({
      ...s,
      id: segment?.id,
    }));
  }, [error, source, segment]);

  return (
    <Modal
      {...rest}
      open={open}
      minWidth={744}
      onClose={onClose}
      title="Edit Wire Information"
      TransitionProps={{ onExited: handleExited }}
    >
      <ProgressBox progress={loading}>
        <WireTableView
          scroll
          segments={rows}
          loading={loading}
          station_id={station_id}
          neutral_view={neutral_view}
          voltage_drop_view={voltage_drop_view}
          noDataText={getResponseMessage(error) || 'No data'}
        />

        <Box mt={4}>
          <Box>
            <Typography variant="subtitle2">Conductor</Typography>

            <Grid container spacing={3} rowSpacing={1}>
              <Grid item xs={4}>
                <SelectField
                  required
                  label="Size"
                  name="gauge"
                  options={gaugeOptions}
                  value={gauge}
                  onValue={setGauge}
                  disabled={!!isTrunkCable}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectField
                  required
                  label="Type"
                  name="wire_type"
                  options={wireTypeOptions}
                  value={wire_type}
                  onValue={setWireType}
                  disabled={!!isTrunkCable}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectField
                  required
                  label="Material"
                  name="material"
                  options={materialOptions}
                  value={material}
                  onValue={setMaterial}
                  disabled={!!isTrunkCable}
                />
              </Grid>

              <Grid item xs={4}>
                {!isTrunkCable && (
                  <NumberField
                    required
                    label="Length"
                    type="number"
                    name="length"
                    value={lengthValue}
                    onValue={setLengthValue}
                    onBlur={(e) => setLength(e.target.value)}
                    disabled={!!isTrunkCable}
                  />
                )}

                {isTrunkCable && (
                  <SelectField
                    label="Trunk Cable"
                    name="trunk_cable"
                    value={trunk_cable}
                    onValue={setTrunkCable}
                    options={trunkCableOptions}
                  />
                )}
              </Grid>

              <Grid item xs={4}>
                <SelectField
                  label="OCPD"
                  name="ocpd"
                  disabled={!isOcpd || !!isTrunkCable}
                  options={ocpdOptions}
                  placeholder={!isOcpd ? 'N/A' : undefined}
                  value={!isOcpd ? undefined : ocpd}
                  onValue={setOcpd}
                />
              </Grid>
            </Grid>
          </Box>

          <Collapse in={isEgc}>
            <Box mt={2}>
              <Typography variant="subtitle2">EGC</Typography>

              <Grid container spacing={3} rowSpacing={1}>
                <Grid item xs={4}>
                  <SelectField
                    label="Size"
                    name="gec_wire_gauge"
                    options={gecWireGaugeOptions}
                    value={gec_wire_gauge}
                    onValue={setGecWireGauge}
                    rules={{ disabled: !isEgc }}
                    disabled={!!isTrunkCable}
                  />
                </Grid>

                <Grid item xs={4}>
                  <SelectField
                    label="Type"
                    name="gec_wire_type"
                    options={gecWireTypeOptions}
                    value={gec_wire_type}
                    onValue={setGecWireType}
                    rules={{ disabled: !isEgc }}
                    disabled={!!isTrunkCable}
                  />
                </Grid>

                <Grid item xs={4}>
                  <SelectField
                    label="Material"
                    name="gec_wire_material"
                    options={gecWireMaterialOptions}
                    value={gec_wire_material}
                    onValue={setGecWireMaterial}
                    rules={{ disabled: !isEgc }}
                    disabled={!!isTrunkCable}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>

          <Box mt={2}>
            <Typography variant="subtitle2">Conduit</Typography>

            <Grid container spacing={3} rowSpacing={1}>
              <Grid item xs={4}>
                <SelectField
                  required
                  label="Size"
                  name="conduit_size"
                  value={conduit_size}
                  onValue={setConduitSize}
                  options={conduitSizeOptions}
                  disabled={!!isTrunkCable}
                />
              </Grid>

              <Grid item xs={4}>
                <SelectField
                  required
                  label="Type"
                  name="conduit_type"
                  value={conduit_type}
                  onValue={setConduitType}
                  options={conduitTypeOptions}
                  disabled={!!isTrunkCable}
                />
              </Grid>

              <Grid item xs={4} />

              <Grid item xs={8}>
                <NumberField
                  type="number"
                  label="Max Current-Carrying Conductors in Conduit"
                  name="current_carrying_conductors"
                  value={current_carrying_conductors_value}
                  onValue={setCurrentCarryingConductorsValue}
                  onBlur={(e) => setCurrentCarryingConductors(e.target.value)}
                  disabled={!!isTrunkCable}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Center gap={2} mt={6}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button onClick={handleSubmit} disabled={loading || !!error}>
            Submit
          </Button>
        </Center>
      </ProgressBox>
    </Modal>
  );
};

export default EditSegmentModal;
