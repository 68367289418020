import { forwardRef, lazy, useCallback } from 'react';

import { useToggle, withProps } from 'hooks';
import { getFunc } from 'utils';
import { dev } from 'constants';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Center from 'components/Center';
import DropdownPopover from 'components/DropdownPopover';

const Dropdown = withProps(DropdownPopover, {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  PaperProps: {
    border: '1px solid red',
  },
});

const DropdownButton = forwardRef((props, ref) => {
  const {
    children,
    label,
    PaperProps,
    onOpen,
    onClose,
    open,
    disabled,
    popoverProps,
    ...rest
  } = props;

  const [innerOpen, toggleInnerOpen] = useToggle(!!open);

  const handleOnOpen = useCallback(
    (...args) => {
      getFunc(onOpen)(...args);
      toggleInnerOpen.on();
    },
    [onOpen, toggleInnerOpen]
  );

  const handleOnClose = useCallback(
    (...args) => {
      getFunc(onClose)(...args);
      toggleInnerOpen.off();
    },
    [onClose, toggleInnerOpen]
  );

  return (
    <Dropdown
      ref={ref}
      PaperProps={PaperProps}
      open={open}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      {...popoverProps}
      anchor={
        <Button
          {...rest}
          size="medium"
          minWidth={135}
          variant="outlined"
          fullWidth={false}
          disabled={disabled}
        >
          <Center justifyContent="space-between" width={1}>
            {label}

            <Icon.ArrowDown
              sx={{ ml: 1 }}
              color={disabled ? 'border' : 'secondary'}
              rotate={innerOpen ? 180 : 0}
            />
          </Center>
        </Button>
      }
    >
      {children}
    </Dropdown>
  );
});

if (dev) {
  DropdownButton.Demo = lazy(() => import('./DropdownButton.demo'));
}

export default DropdownButton;
