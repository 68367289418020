import { forwardRef, lazy, useMemo } from 'react';

import { dev } from 'constants';
import { useSearchUtilitiesByStateQuery } from 'store';
import { getArray } from 'utils';
import { Autocomplete } from 'components';

const UtilityField = forwardRef((props, ref) => {
  const { state, disabled: customDisabled, ...rest } = props;

  const disabled = customDisabled || !state;

  const { data, isFetching } = useSearchUtilitiesByStateQuery(
    {
      body: { state },
    },
    {
      skip: disabled,
    }
  );

  const options = useMemo(() => {
    return getArray(data?.utilities).map(({ id, utility }) => ({
      value: id,
      label: utility,
    }));
  }, [data]);

  return (
    <Autocomplete
      ref={ref}
      {...rest}
      loading={isFetching}
      disabled={disabled}
      options={options}
    />
  );
});

UtilityField.Control = forwardRef((props, ref) => {
  const { ControlComponent = UtilityField, ...rest } = props;

  return (
    <Autocomplete.Control
      ref={ref}
      ControlComponent={ControlComponent}
      {...rest}
    />
  );
});

if (dev) {
  UtilityField.Demo = lazy(() => import('./UtilityField.demo'));
}

export default UtilityField;
