import { Group } from 'paper';

import PaperElement from 'components/Paper/PaperElement';

class PaperSvg extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'svg_item_group' });
    super({ element, ...options });
  }

  upload(source, callback) {
    this.source = source;
    this.element.removeChildren();

    this.scope.project.importSVG(source, {
      expandShapes: true,
      onLoad: (item) => {
        this.element.addChild(item);
        callback && callback(item);
      },
    });
  }
}

export default PaperSvg;
