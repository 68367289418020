import { useCallback } from 'react';
import { Box, useTheme } from '@mui/material';

import { getFunc } from 'utils';
import { ToolbarDropdown, WireMark } from 'views';
import { useToggle } from 'hooks';
import { Draggable, Icon } from 'components';

const WiresDropdown = (props) => {
  const { wires, onDrop, onDragStart, ...rest } = props;
  const [open, toggleOpen] = useToggle();
  const theme = useTheme();

  const handleDrop = useCallback(
    (wire, data) => {
      getFunc(onDrop)(wire, data);
      toggleOpen.off();
    },
    [onDrop, toggleOpen]
  );

  return (
    <ToolbarDropdown
      {...rest}
      open={open}
      onOpen={toggleOpen.on}
      onClose={toggleOpen.off}
      icon={<Icon.Wires />}
      label="Wires"
    >
      {wires.map((wire) => (
        <Box
          key={wire.id}
          width={30}
          height={30}
          fontSize={0}
          position="relative"
        >
          <WireMark
            text=""
            strokeDasharray="3 3"
            stroke={theme.palette.border.main}
          />
          {!wire.placed && (
            <Draggable
              onDragStart={onDragStart}
              onDrop={(e, data) => handleDrop(wire, data)}
            >
              <WireMark text={wire.text} sx={{ userSelect: 'none' }} />
            </Draggable>
          )}
        </Box>
      ))}
    </ToolbarDropdown>
  );
};

export default WiresDropdown;
