export const {
  NODE_ENV: env,
  REACT_APP_API_URL: api,
  REACT_APP_GOOGLE_API_KEY: google_api_key,
} = process.env;

export const dev =
  env === 'development' ||
  window.location.origin === 'https://design4pv.dev.angleto.com';
export const prod = env === 'production';
export const test = env === 'test';

export const phone_mask = '(999) 999-9999';
export const phone_mask_char = '_';

export const app_bar_logo_width = 190;

export const property_image_width = 1_000;
export const property_image_height = 1_000;

export const diagram_width = 1440;
export const diagram_height = 1020;
