const propagateRefs =
  (...refs) =>
  (el) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(el);
      } else if (ref) {
        ref.current = el;
      }
    });
    return el;
  };

export default propagateRefs;
