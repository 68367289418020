import { lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';

import { getArray, fromRange, getString } from 'utils';
import { useResizeObserver } from 'hooks';
import { dev } from 'constants';
import { Center } from 'components';

const ignorePath = '/var/www/projects/design4pv-fs.dev.angleto.com';

const LayersPreview = (props) => {
  const { scale = 1, layers } = props;

  const containerRef = useRef();
  const contentRef = useRef();

  const [translate, setTranslate] = useState([0, 0]);
  const [move, setMove] = useState(null);
  const [containerMetrics, setContainerMetrics] = useState();
  const [contentMetrics, setContentMetrics] = useState();

  const backgroundImage = useMemo(() => {
    if (getArray(layers).length === 0) {
      return null;
    }
    const urls = layers
      .filter(Boolean)
      .map((l) => `url(${getString(l).split(ignorePath).join('')})`);
    return urls.join(', ');
  }, [layers]);

  const transform = useMemo(() => {
    return `scale(${scale}) translate(${translate
      .map((t) => `${t}px`)
      .join(', ')})`;
  }, [scale, translate]);

  const defineSizes = useCallback(() => {
    const { current: container } = containerRef;
    const { current: content } = contentRef;

    if (!container || !content) {
      return;
    }
    setContainerMetrics(container.getBoundingClientRect());
    setContentMetrics(content.getBoundingClientRect());
  }, [containerRef, contentRef]);

  useResizeObserver(containerRef, defineSizes);
  useResizeObserver(contentRef, defineSizes);

  useEffect(() => {
    const { current: content } = contentRef;

    if (content && scale) {
      setContentMetrics(content.getBoundingClientRect());
    }
  }, [scale, setContentMetrics, contentRef]);

  useEffect(() => {
    const disableMove = () => setMove(null);
    document.addEventListener('mouseup', disableMove);
    return () => document.removeEventListener('mouseup', disableMove);
  }, [setMove]);

  const handleMove = useCallback(
    (e) => {
      if (!move) {
        return;
      }
      const { clientX, clientY } = e;
      const horizontalAllowedSpase =
        (contentMetrics.width - containerMetrics.width) / 2;
      const verticalAllowedSpace =
        (contentMetrics.height - containerMetrics.height) / 2;
      const xOffset = clientX - move.x;
      const yOffset = clientY - move.y;
      const [translateX, translateY] = move.translate;

      setTranslate([
        fromRange(
          translateX + xOffset,
          0 - horizontalAllowedSpase,
          horizontalAllowedSpase
        ),
        fromRange(
          translateY + yOffset,
          0 - verticalAllowedSpace,
          verticalAllowedSpace
        ),
      ]);
    },
    [containerMetrics, contentMetrics, move]
  );

  const handleMoveStart = useCallback(
    (e) => {
      if (scale === 1) {
        setMove(null);
        return;
      }
      setMove({ x: e.clientX, y: e.clientY, translate });
    },
    [scale, translate]
  );

  return (
    <Center
      width={1}
      height={1}
      position="relative"
      overflow="hidden"
      onMouseMove={handleMove}
      onMouseDown={handleMoveStart}
      ref={containerRef}
    >
      <Box
        ref={contentRef}
        sx={{
          width: 1,
          height: 1,
          transform,
          backgroundImage,
          cursor: scale > 1 ? 'move' : 'default',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      />
    </Center>
  );
};

if (dev) {
  LayersPreview.Demo = lazy(() => import('./LayersPreview.demo'));
}

export default LayersPreview;
