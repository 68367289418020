import { forwardRef, lazy } from 'react';
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { getArray, getFunc } from 'utils';
import { dev } from 'constants';

const Table = styled(MuiTable)({
  tableLayout: 'fixed',
});

const HeaderCell = styled(TableCell, {
  label: 'HeaderCell',
})(({ theme }) => ({
  '&:first-of-type': {
    paddingLeft: theme.spacing(2),
  },
  '&:last-of-type': {
    paddingRight: theme.spacing(2),
  },
  padding: theme.spacing(0.5, 1),
  ...theme.typography.tableCaption,
  textTransform: 'uppercase',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.palette.border.main,
  border: `1px solid ${theme.palette.border.main}`,
  borderLeft: 'none',
  borderRight: 'none',
  maxHeight: theme.spacing(4),
  whiteSpace: 'nowrap',
}));

const Cell = styled(TableCell, {
  label: 'Cell',
})(({ theme }) => ({
  ...theme.typography.body1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:first-of-type': {
    paddingLeft: theme.spacing(2),
  },
  '&:last-of-type': {
    paddingRight: theme.spacing(2),
  },
  border: 'none',
  padding: theme.spacing(1),
  maxHeight: theme.spacing(6),
  whiteSpace: 'nowrap',
}));

const Row = styled(TableRow, {
  label: 'Row',
})({});

const TBody = styled(TableBody, {
  label: 'TBody',
})(({ theme }) => ({
  [`${Row}`]: {
    transition: theme.transitions.create(),
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.colors.background_main,
    },
    '&:hover': {
      backgroundColor: theme.palette.colors.hover_background,
    },
  },
}));

const DataTable = forwardRef((props, ref) => {
  const { columns, rows } = props;

  const isEmpty = getArray(rows).length === 0;

  return (
    <TableContainer ref={ref}>
      <Table>
        <TableHead>
          <TableRow>
            {getArray(columns).map((column, columnIndex) => {
              const {
                id,
                field,
                headerName,
                renderCell,
                cellProps,
                width,
                ...columnRest
              } = column;

              const key = id || field || columnIndex;

              return (
                <HeaderCell
                  key={key}
                  {...columnRest}
                  width={width}
                  sx={{
                    width,
                    maxWidth: width,
                  }}
                >
                  {headerName}
                </HeaderCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TBody>
          {getArray(rows).map((row, rowIndex) => {
            return (
              <Row key={rowIndex}>
                {getArray(columns).map((column, columnIndex) => {
                  const { field, renderCell, content, cellProps } = column;

                  const value = field ? row[field] : undefined;
                  const element =
                    getFunc(renderCell)({ value, row }) || content || value;

                  return (
                    <Cell key={columnIndex} {...cellProps}>
                      {element}
                    </Cell>
                  );
                })}
              </Row>
            );
          })}

          {isEmpty && (
            <Row>
              <Cell colSpan={getArray(columns).length}>
                <Box textAlign="center" sx={{ opacity: 0.5 }}>
                  No data
                </Box>
              </Cell>
            </Row>
          )}
        </TBody>
      </Table>
    </TableContainer>
  );
});

if (dev) {
  DataTable.Demo = lazy(() => import('./DataTable.demo'));
}

export default DataTable;
