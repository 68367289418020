import { forwardRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme, Typography, Box } from '@mui/material';

import { selectors, actions } from 'store';
import { withProps } from 'hooks';
import { Icon, ProgressBox, TinyButton } from 'components';

const FORM_WIDTH = 370;

const { selectPreviewCollapsed } = selectors;
const { toggleCollapse } = actions;

const Root = styled(Box, {
  label: 'ProjectDetailsLayout',
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.colors.project_background,
}));

const ProjectFormFrame = styled(Box, {
  label: 'ProjectFormFrame',
})(({ theme }) => ({
  transition: theme.transitions.create(),
}));

const ProjectPreviewFrame = styled(ProgressBox, {
  label: 'ProjectPreviewFrame',
})(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  paddingLeft: theme.spacing(3),
}));

const FormContainer = styled(Box, {
  label: 'FormContainer',
})(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.makeShadow(2),
  borderTopRightRadius: theme.radius(1),
  transition: theme.transitions.create(),
}));

const FormHeader = styled(Box, {
  label: 'FormHeader',
})({ position: 'relative' });

const FormTrigger = styled(Box, {
  label: 'FormTrigger',
})(() => ({
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translate(50%, -50%)',
  zIndex: 1,
}));

const TriggerButton = withProps(TinyButton, {
  size: 'small',
  shape: 'circle',
  variant: 'flat',
  shadow: 2,
});

const TriggerIcon = withProps(Icon.ArrowBack, {
  sx: {
    fontSize: 16,
    color: 'secondary.main',
  },
});

const FormTitleWrapper = styled(Box, {
  label: 'FormTitleWrapper',
})(({ theme }) => ({
  overflow: 'hidden',
  height: 54,
  display: 'flex',
  alignItems: 'center',
  transition: theme.transitions.create(),
  borderBottom: `1px solid ${theme.palette.border.main}`,
}));

const FormTitle = styled(Box, {
  label: 'FormTitle',
})(({ theme }) => ({
  width: FORM_WIDTH,
  whiteSpace: 'nowrap',
  padding: theme.spacing(0, 3),
}));

const FormBody = styled(Box, {
  label: 'FormBody',
})(({ theme }) => ({
  overflow: 'hidden',
  overflowY: 'auto',
  paddingBottom: theme.spacing(4),
  transition: theme.transitions.create(),
}));

const FormBodyContent = styled(Box, {
  label: 'FormBodyContent',
})({ width: FORM_WIDTH });

const ProjectDetailsLayout = forwardRef((props, ref) => {
  const { children, title, body, loading, ...rest } = props;

  const theme = useTheme();
  const collapsed = useSelector(selectPreviewCollapsed);

  const sideWidth = collapsed ? theme.spacing(3) : FORM_WIDTH;

  const handleCollapse = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    toggleCollapse();
  }, []);

  return (
    <Root {...rest} ref={ref}>
      <ProjectFormFrame width={sideWidth}>
        <FormContainer width={sideWidth} mt={2}>
          <FormHeader>
            <FormTrigger>
              <TriggerButton onClick={handleCollapse}>
                <TriggerIcon rotate={collapsed ? 180 : 0} />
              </TriggerButton>
            </FormTrigger>

            <FormTitleWrapper width={sideWidth}>
              <FormTitle>
                <Typography variant="subtitle2">{title}</Typography>
              </FormTitle>
            </FormTitleWrapper>
          </FormHeader>

          <FormBody>
            <FormBodyContent p={3}>{body}</FormBodyContent>
          </FormBody>
        </FormContainer>
      </ProjectFormFrame>

      <ProjectPreviewFrame size="xlarge" progress={loading} py={2} pr={3}>
        {children}
      </ProjectPreviewFrame>
    </Root>
  );
});

export default ProjectDetailsLayout;
