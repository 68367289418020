import { Shape } from 'paper';

import PaperDraggable from 'components/Paper/PaperDraggable';

class PaperAnchor extends PaperDraggable {
  constructor(options = {}) {
    const { data, ...opts } = options;

    const element = new Shape.Circle({
      radius: 5,
      strokeWidth: 1,
      strokeColor: 'blue',
      fillColor: 'white',
    });
    super({ element, ...opts });

    this.data = {
      ...data,
      x: data?.x || 0,
      y: data?.y || 0,
    };
    this.update();
  }

  update(data = this.data) {
    this.data = { ...this.data, ...data };
    this.element.bounds.center = this.point(this.data);
  }
}

export default PaperAnchor;
