import { forwardRef, lazy, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Collapse, Box, IconButton } from '@mui/material';

import { DISCONNECT_TYPES_NAMES, dev } from 'constants';
import { useFetchDisconnects } from 'hooks';
import { Center, Icon, ProgressBox } from 'components';

const DisconnectInfo = forwardRef((props, ref) => {
  const { onEdit, value, description, open } = props;

  const { data: disconnects, isFetching } = useFetchDisconnects({
    skip: !value,
  });

  const targetDisconnect = useMemo(() => {
    return disconnects.find((d) => d.id === value);
  }, [disconnects, value]);

  const title = useMemo(() => {
    if (!targetDisconnect) {
      return null;
    }
    return [
      DISCONNECT_TYPES_NAMES[targetDisconnect.type],
      `${targetDisconnect.vendor.title} - ${targetDisconnect.title}`,
    ].join('; ');
  }, [targetDisconnect]);

  const loading = isFetching;

  return (
    <Collapse in={!!(value && open)}>
      <ProgressBox progress={loading} mt={2} size="xsmall" ref={ref}>
        <Center justifyContent="space-between" alignItems="flex-start">
          <Box mt={1}>
            <Box>{title}</Box>
            <Box>{description}</Box>
          </Box>

          <Box>
            <IconButton size="small" color="secondary" onClick={onEdit}>
              <Icon.Pencil />
            </IconButton>
          </Box>
        </Center>
      </ProgressBox>
    </Collapse>
  );
});

DisconnectInfo.Control = forwardRef((props, ref) => {
  const { name, ...rest } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <DisconnectInfo ref={ref} value={field.value} {...rest} />;
      }}
    />
  );
});

if (dev) {
  DisconnectInfo.Demo = lazy(() => import('./DisconnectInfo.demo'));
}

export default DisconnectInfo;
