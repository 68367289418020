import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetProjectDetailsQuery } from 'store';
import { useIsLoading, useToggle } from 'hooks';
import { getArray, getString } from 'utils';
import { ProjectDetailsLayout } from 'layouts';
import { Form, useMessage } from 'components';

import StructuresForm from './StructuresForm';
import StructuresPreview from './StructuresPreview';

const targetLayers = [
  'attachments',
  'obstructions',
  'pv_panels',
  'racking',
  'roof',
  'structure',
];
const ignorePath = '/var/www/projects/design4pv-fs.dev.angleto.com';

const Structures = () => {
  const { project_id } = useParams();
  const diagramRef = useRef();
  const m = useMessage();

  const [api, setApi] = useState();
  const [layersRendering, toggleLayersRendering] = useToggle(true);
  const [structures, setStructures] = useState([]);
  const [arrays, setArrays] = useState([]);

  const queryProject = useGetProjectDetailsQuery({ project_id });

  const project = queryProject.data;

  const form = Form.useForm({
    defaultValues: {
      structures_number: 1,
    },
  });

  const loading = useIsLoading(queryProject);

  const layers = useMemo(() => {
    return getArray(targetLayers)
      .map((name) =>
        getString(project?.property_plan?.[name]).split(ignorePath).join('')
      )
      .filter(Boolean);
  }, [project]);

  const structureTypes = useMemo(() => {
    return getArray(project?.arrays).reduce((res, arr) => {
      if (!res.includes(arr.panel_type)) {
        return [...res, arr.panel_type];
      }
      return res;
    }, []);
  }, [project]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        console.log(formData);
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m]
  );

  // Initial layers rendering
  useEffect(() => {
    if (layersRendering && !loading && api) {
      if (layers.length < targetLayers.length) {
        m.warning('Not all the layers found, or loading error...');
      }
      api.setLayers(layers, toggleLayersRendering.off);
    }
  }, [m, layersRendering, loading, layers, api, toggleLayersRendering]);

  // Initial arrays setup
  useEffect(() => {
    const newArrays = getArray(project?.arrays).map((arr, i) => ({
      ...arr,
      id: `array_${i}`,
      value: `array_${i}`,
      label: `Array #${i + 1}`,
    }));
    setArrays(newArrays);
  }, [project]);

  // Initial structures setup
  useEffect(() => {
    const newStructures = structureTypes.map((type, i) => ({
      id: `structure_${i}`,
      value: `structure_${i}`,
      label: `Structure ${i + 1}`,
      arrays: arrays
        .filter((arr) => arr.panel_type === type)
        .map((arr) => arr.id),
    }));
    setStructures(newStructures);
  }, [structureTypes, arrays]);

  return (
    <ProjectDetailsLayout
      title="Structures"
      component={Form}
      onSubmit={handleSubmit}
      body={<StructuresForm />}
      form={{
        ...form,
        loading,
        diagramRef,
        project,
        arrays,
        structures,
        project_id,
        onReady: setApi,
        onArraysChange: setArrays,
        onStructuresChange: setStructures,
      }}
    >
      <StructuresPreview />
    </ProjectDetailsLayout>
  );
};

export default Structures;
