import { useCallback } from 'react';

import {
  useToggleNoteVisibilityMutation,
  useDeleteCompanyNoteMutation,
} from 'store';

import { useToggle } from 'hooks';
import { CompanyNote } from 'views';
import { useMessage } from 'components';

import EditNoteModal from './EditNoteModal';

const Note = (props) => {
  const { note, company_id } = props;
  const { id: note_id } = note;

  const m = useMessage();
  const [editNote, toggleEditNote] = useToggle();
  const [toggleVisibility, toggleState] = useToggleNoteVisibilityMutation();
  const [deleteNote, deleteNoteState] = useDeleteCompanyNoteMutation();

  const loading = toggleState.isLoading || deleteNoteState.isLoading;

  const handleToggleVisibility = useCallback(async () => {
    try {
      await toggleVisibility({ company_id, note_id }).unwrap();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [m, note_id, company_id, toggleVisibility]);

  const handleRemoveNote = useCallback(async () => {
    try {
      await deleteNote({ company_id, note_id }).unwrap();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [m, company_id, note_id, deleteNote]);

  return (
    <>
      {/* MODALS */}
      <>
        <EditNoteModal
          note={note}
          open={editNote}
          company_id={company_id}
          onClose={toggleEditNote.off}
        />
      </>

      <CompanyNote
        note={note}
        loading={loading}
        onEditClick={toggleEditNote.on}
        onDeleteClick={handleRemoveNote}
        onVisibilityClick={handleToggleVisibility}
      />
    </>
  );
};

export default Note;
