import {
  forwardRef,
  lazy,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Box } from '@mui/material';

import { dev, ADDRESS_TYPE } from 'constants';
import {
  getAddressLabel,
  getAddressType,
  getArray,
  getFunc,
  getString,
  placeToAddress,
} from 'utils';

import { useToggle } from 'hooks';
import { Autocomplete, Ref, useGoogleApi } from 'components';
import ManualAddressModal from 'views/ManualAddressModal';

const { ADDRESS, PREDICTION /* , PLACE */ } = ADDRESS_TYPE;

const AddressField = forwardRef((props, ref) => {
  const {
    value,
    manual,
    onChange,
    onValue,
    readOnly,
    disabled,
    coords = true,
    coordsRequired = true,
    label: customLabel,
    loading: customLoading,
    hideAsterisk: customHideAsterisk,
    ...rest
  } = props;

  const { loading: apiLoading, autocomplete, places } = useGoogleApi();
  const [searchLoading, toggleSearchLoading] = useToggle(false);
  const [changeLoading, toggleChangeLoading] = useToggle(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [manualModal, toggleManualModal] = useToggle();

  const handleManualClick = useCallback(
    (e) => {
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();

      if (disabled) {
        return;
      }
      toggleManualModal.on();
    },
    [toggleManualModal, disabled]
  );

  const opts = useMemo(
    () => getArray(!value ? options : [value, ...options]),
    [options, value]
  );

  const hideAsterisk = useMemo(
    () => (manual ? true : customHideAsterisk),
    [customHideAsterisk, manual]
  );

  const label = useMemo(() => {
    if (manual && !readOnly) {
      return (
        <Box display="flex" justifyContent="space-between">
          <span>{customLabel}</span>

          <Ref
            noline
            fontWeight={600}
            color={disabled ? 'border.main' : 'secondary.main'}
            onClick={handleManualClick}
          >
            Enter Manually
          </Ref>
        </Box>
      );
    }
    return customLabel;
  }, [manual, readOnly, customLabel, handleManualClick, disabled]);

  const loading = apiLoading || searchLoading || changeLoading || customLoading;

  const fetchOptions = useCallback(
    async (input, callback) => {
      toggleSearchLoading.on();

      autocomplete.getPlacePredictions({ input }, (...args) => {
        toggleSearchLoading.off();
        getFunc(callback)(...args);
      });
    },
    [autocomplete, toggleSearchLoading]
  );

  useEffect(() => {
    if (apiLoading || !autocomplete) {
      return;
    }
    if (!inputValue) {
      setOptions([]);
      return;
    }
    let mounted = true;

    const tid = setTimeout(() => {
      fetchOptions(inputValue, (results) => {
        if (mounted) {
          setOptions(results);
        }
      });
    }, 300);

    return () => {
      clearTimeout(tid);
      mounted = false;
    };
  }, [apiLoading, autocomplete, inputValue, value, fetchOptions]);

  const handleInputChange = useCallback((e, val, reason) => {
    setInputValue(val);
  }, []);

  const handleChange = useCallback(
    async (e, val, reason, details) => {
      toggleChangeLoading.on();
      const type = getAddressType(val);

      switch (type) {
        case PREDICTION: {
          const addressFromPrediction = await new Promise((resolve, reject) => {
            places.getDetails({ placeId: val.place_id }, (placeDetails) => {
              resolve(placeToAddress(placeDetails));
            });
          });
          getFunc(onChange)(e, addressFromPrediction, reason, details);
          getFunc(onValue)(addressFromPrediction);
          break;
        }
        case ADDRESS:
        default: {
          getFunc(onChange)(e, val, reason, details);
          getFunc(onValue)(val);
          break;
        }
      }
      toggleChangeLoading.off();
    },
    [toggleChangeLoading, onChange, onValue, places]
  );

  const handleManualAddressChange = useCallback(
    (address) => {
      getFunc(onChange)(null, address);
      getFunc(onValue)(address);
    },
    [onChange, onValue]
  );

  return (
    <>
      {/* MODALS */}
      <>
        <ManualAddressModal
          address={value}
          open={manualModal}
          coords={coords}
          coordsRequired={coordsRequired}
          onClose={toggleManualModal.off}
          onSubmit={handleManualAddressChange}
        />
      </>

      <Autocomplete
        ref={ref}
        {...rest}
        disabled={disabled}
        label={label}
        readOnly={readOnly}
        hideAsterisk={hideAsterisk}
        value={value || null}
        loading={loading}
        options={opts}
        inputValue={inputValue}
        onChange={handleChange}
        forcePopupIcon={false}
        filterOptions={(opt) => opt}
        onInputChange={handleInputChange}
        getOptionLabel={(opt) => {
          const addressType = getAddressType(opt);

          switch (addressType) {
            case PREDICTION:
              return opt.description;
            case ADDRESS:
              return getAddressLabel(opt);
            default:
              return getString(opt);
          }
        }}
      />
    </>
  );
});

AddressField.Control = forwardRef((props, ref) => {
  const { ControlComponent = AddressField, ...rest } = props;

  return (
    <Autocomplete.Control
      ref={ref}
      {...rest}
      defaultValue={null}
      ControlComponent={ControlComponent}
    />
  );
});

if (dev) {
  AddressField.Demo = lazy(() => import('./AddressField.demo'));
}

export default AddressField;
