import { getString } from 'utils';
import PaperTextArea from 'components/Paper/PaperTextArea';
import PaperNoteBox from 'components/Paper/PaperNoteBox';

class PaperNote extends PaperNoteBox {
  constructor(options) {
    const { data, ...opts } = options;
    super({ data, ...opts });

    const text = new PaperTextArea({
      ...opts,
      data: {
        width: this.data.width - 5 - 25,
        content: getString(this.data.text).toUpperCase(),
      },
    });
    this.children.text = text;
    this.element.addChild(text.element);
    text.element.bounds.topLeft = this.point(25, 12);
  }

  update(data = this.data) {
    this.data = { ...this.data, ...data };

    this.children.text.update({
      content: getString(this.data.text).toUpperCase(),
    });
    super.update({
      height: Math.max(this.children.text.element.bounds.height + 12 + 12, 75),
    });
  }
}

export default PaperNote;
