import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { getArray } from 'utils';
import { useToggle } from 'hooks';
import { NOTES_GROUPS_LIST, NOTES_GROUPS_NAMES, NOTES_LEGACY } from 'constants';
import { useGetContractorCompanyDetailsQuery } from 'store';
import { ConfigurationSubMenu } from 'views';
import { Button, ProgressBox } from 'components';

import Note, { EditNoteModal } from './Note';

const { toLegacy, fromLegacy } = NOTES_LEGACY;

const CompanyNotes = () => {
  const { company_id } = useParams();
  const [addNote, toggleAddNote] = useToggle();
  const [group, setGroup] = useState(toLegacy(NOTES_GROUPS_LIST[0].value));

  const { data: company, isFetching } = useGetContractorCompanyDetailsQuery({
    company_id,
  });

  const title = useMemo(() => {
    return `${NOTES_GROUPS_NAMES[fromLegacy(group)]} Notes`;
  }, [group]);

  const notes = useMemo(
    () =>
      getArray(company?.notes).filter((notesGroup) =>
        [group, fromLegacy(group)].includes(notesGroup.group)
      ),
    [company, group]
  );

  const options = useMemo(
    () =>
      NOTES_GROUPS_LIST.map((opt) => ({
        ...opt,
        value: toLegacy(opt.value),
        selected: toLegacy(opt.value) === group,
      })),
    [group]
  );

  const handleGroupChange = useCallback((item) => {
    setGroup(item.value);
  }, []);

  return (
    <>
      {/* MODALS */}
      <>
        <EditNoteModal
          group={group}
          open={addNote}
          company_id={company_id}
          onClose={toggleAddNote.off}
        />
      </>

      <ProgressBox
        mt={3}
        px={4}
        pb={4}
        flexGrow={1}
        display="flex"
        maxHeight={650}
        progress={isFetching}
      >
        <ConfigurationSubMenu
          options={options}
          onItemClick={handleGroupChange}
        />

        <Box
          ml={3}
          flexGrow={1}
          overflow="hidden"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="subtitle1">{title}</Typography>

          <Box mt={2}>
            <Button
              size="medium"
              minWidth={130}
              fullWidth={false}
              onClick={toggleAddNote.on}
            >
              Add Note
            </Button>
          </Box>

          <Box mt={3} pr={0.5} flexGrow={1} overflow="auto">
            <Box
              sx={{
                borderTop: (theme) => `1px solid ${theme.palette.border.light}`,
              }}
            />

            {notes.map((note) => (
              <Note key={note.id} note={note} company_id={company_id} />
            ))}
          </Box>
        </Box>
      </ProgressBox>
    </>
  );
};

export default CompanyNotes;
