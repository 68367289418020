import { Typography } from '@mui/material';

import { Button, Center, Modal } from 'components';

const AccessWarningModal = (props) => {
  const { open, onClose } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      actions={<Button onClick={onClose}>Close</Button>}
    >
      <Center mt={2}>
        <Typography variant="subtitle2">
          Only admins can edit company information
        </Typography>
      </Center>
    </Modal>
  );
};

export default AccessWarningModal;
