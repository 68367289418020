import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';

import { useChangePasswordMutation } from 'store';
import { routes } from 'routes';
import { MatchPasswords } from 'views';
import { Button, Form, ProgressBox, useMessage } from 'components';

const ResetPassword = () => {
  const { hash } = useParams();

  const form = Form.useForm();
  const { isValid } = form.formState;

  const m = useMessage();
  const navigate = useNavigate();
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = { ...formData, hash };
        delete body.password_repeat;
        await changePassword({ body }).unwrap();
        m.success('Your password has been updated!');
        navigate(routes.sign_in.path());
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, hash, changePassword, navigate]
  );

  return (
    <ProgressBox progress={isLoading}>
      <Form form={form} onSubmit={handleSubmit}>
        <Box mt={4} textAlign="center">
          <Typography variant="subtitle1">Reset Password</Typography>
        </Box>

        <Box mt={3}>
          <Stack spacing={1}>
            <MatchPasswords name="new_password" initialFocus />
          </Stack>
        </Box>

        <Box mt={2}>
          <Button type="submit" disabled={!isValid}>
            Reset Password
          </Button>
        </Box>
      </Form>
    </ProgressBox>
  );
};

export default ResetPassword;
