import { useMatch } from 'react-router-dom';
import { Box, styled, Collapse } from '@mui/material';

import { withProps } from 'hooks';
import { routes } from 'routes';
import { Ref } from 'components';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';

const AuthLink = styled(Ref, {
  label: 'AuthLink',
})(({ theme, inactive }) => ({
  fontWeight: 700,
  cursor: inactive ? 'default' : 'pointer',
  fontSize: theme.pxToRem(inactive ? 40 : 16),
  color: inactive ? 'inherit' : theme.palette.secondary.main,
  transition: theme.transitions.create('all'),
  '&:hover, &:focus, &:focus-visible': {
    outline: 'none',
    ...(!inactive && {
      transform: 'scale(1.2)',
    }),
  },
}));

const FontBaseline = withProps(
  styled(Box)(({ theme }) => ({
    fontSize: theme.pxToRem(40),
  })),
  {
    children: <>&nbsp;</>,
  }
);

const Auth = () => {
  const isSignIn = !!useMatch(routes.sign_in.path());
  const isSignUp = !!useMatch(routes.sign_up.path());
  const isForgotPassword = !!useMatch(routes.forgot_password.path());

  return (
    <>
      <Collapse in={!isForgotPassword}>
        <Box
          mt={6}
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <AuthLink nostyles inactive={isSignIn} to={routes.sign_in.path()}>
            Sign In
          </AuthLink>

          <FontBaseline />

          <AuthLink nostyles inactive={isSignUp} to={routes.sign_up.path()}>
            Sign Up
          </AuthLink>
        </Box>
      </Collapse>

      <Box mt={4}>
        <Collapse in={isSignIn}>
          <SignIn open={isSignIn} />
        </Collapse>

        <Collapse in={isSignUp}>
          <SignUp open={isSignUp} />
        </Collapse>

        <Collapse in={isForgotPassword}>
          <ForgotPassword open={isForgotPassword} />
        </Collapse>
      </Box>
    </>
  );
};

export default Auth;
