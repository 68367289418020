import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors, useGetProfileQuery, api } from 'store';

const {
  selectIsAuthorized,
  selectIsLoggedIn,
  selectRole,
  selectUser,
  selectPremium,
} = selectors;

const { logout, login, authorize } = actions;

const useAuth = () => {
  const dispatch = useDispatch();

  const isAuthorized = useSelector(selectIsAuthorized);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const role = useSelector(selectRole);
  const user = useSelector(selectUser);
  const premium = useSelector(selectPremium);

  const { isLoading } = useGetProfileQuery(undefined, { skip: true });

  const progress = useMemo(() => {
    if (isLoading) {
      return true;
    }
    if (isLoggedIn && !isAuthorized) {
      return true;
    }
    return false;
  }, [isAuthorized, isLoading, isLoggedIn]);

  const clear = useCallback(() => {
    logout();
    dispatch(api.util.resetApiState());
  }, [dispatch]);

  return useMemo(
    () => ({
      isAuthorized,
      isLoggedIn,
      role,
      logout,
      login,
      authorize,
      user,
      progress,
      premium,
      clear,
    }),
    [isAuthorized, isLoggedIn, role, user, progress, premium, clear]
  );
};

export default useAuth;
