import { forwardRef, lazy, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IconButton } from '@mui/material';

import { getFunc } from 'utils';
import { useDeincrement } from 'hooks';
import { dev } from 'constants';
import { Center, Icon } from 'components';

const DecrementButtonDefault = (props) => (
  <IconButton color="primary" size="small" {...props}>
    <Icon.MinusFilled />
  </IconButton>
);
const IncrementButtonDefault = (props) => (
  <IconButton color="primary" size="small" {...props}>
    <Icon.PlusFilled />
  </IconButton>
);

const DeincrementButtons = forwardRef((props, ref) => {
  const {
    min,
    max,
    step,
    value,
    onValue,
    onChange,
    disabled,
    reverse,
    increaseDisabled,
    decreaseDisabled,
    DecrementButton = DecrementButtonDefault,
    IncrementButton = IncrementButtonDefault,
    ...rest
  } = props;

  const handleChange = useCallback(
    (e, v) => {
      getFunc(onChange)(e, v);
      getFunc(onValue)(v);
    },
    [onChange, onValue]
  );

  const [increase, decrease] = useDeincrement(value, handleChange, {
    min,
    max,
    step,
    passArgs: true,
  });

  return (
    <Center
      ref={ref}
      {...rest}
      flexDirection={reverse ? 'row-reverse' : 'reverse'}
    >
      <IncrementButton
        onClick={increase}
        disabled={disabled || increaseDisabled || value === max}
      />
      <DecrementButton
        onClick={decrease}
        disabled={disabled || decreaseDisabled || value === min}
      />
    </Center>
  );
});

DeincrementButtons.Control = forwardRef((props, ref) => {
  const { name, onChange, defaultValue = 0, ...rest } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <DeincrementButtons
          {...rest}
          ref={ref}
          value={field.value}
          onChange={(e, v) => {
            field.onChange(v);
            getFunc(onChange)(e, v);
          }}
        />
      )}
    />
  );
});

if (dev) {
  DeincrementButtons.Demo = lazy(() => import('./DeincrementButtons.demo'));
}

export default DeincrementButtons;
