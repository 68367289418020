import { lazy, useEffect } from 'react';
import { Box } from '@mui/material';

import { dev } from 'constants';
import { HTMLEditor, Form, ProgressBox, TextField, Button } from 'components';

const EditCompanyNoteForm = (props) => {
  const {
    reset,
    note,
    onClose,
    onSubmit,
    onCancel,
    loading,
    allowImages,
    ...rest
  } = props;

  const form = Form.useForm();
  const { isValid } = form.formState;

  useEffect(() => {
    if (reset) {
      form.reset({
        text: HTMLEditor.htmlToState(note?.text),
        reference: note?.reference,
        requested_by: note?.requested_by,
      });
    }
  }, [form, note, reset]);

  return (
    <ProgressBox {...rest} progress={loading}>
      <Form form={form} onSubmit={onSubmit}>
        <HTMLEditor.Control
          uppercase
          required
          name="text"
          allowImages={allowImages}
          initialFocus={!!reset}
          editorStyles={{
            '& img': {
              maxWidth: '50%',
            },
          }}
        />

        <Box display="flex" gap={3} mt={1}>
          <TextField.Control required name="reference" label="Reference" />

          <TextField.Control
            required
            name="requested_by"
            label="Requested By"
          />
        </Box>

        <Box mt={4} display="flex" gap={2} justifyContent="center">
          <Button fullWidth={false} variant="outlined" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            type="submit"
            minWidth={200}
            fullWidth={false}
            disabled={!isValid}
          >
            Save
          </Button>
        </Box>
      </Form>
    </ProgressBox>
  );
};

if (dev) {
  EditCompanyNoteForm.Demo = lazy(() => import('./EditCompanyNoteForm.demo'));
}

export default EditCompanyNoteForm;
