import { useEffect, useMemo } from 'react';

import useToggle from 'hooks/useToggle.hook';
import { getFunc } from 'utils';

const defProps = {};

const scriptsCache = {};

const useLoadScript = (props = defProps) => {
  const { src, onLoad, target = document.querySelector('head') } = props;

  const [loading, toggleLoading] = useToggle(src && !scriptsCache[src]);

  useEffect(() => {
    if (src && !scriptsCache[src] && target) {
      toggleLoading.on();
      scriptsCache[src] = true;

      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.onload = (...args) => {
        toggleLoading.off();
        getFunc(onLoad)(...args);
      };
      script.src = src;
      target.appendChild(script);
    }
  }, [src, target, toggleLoading, onLoad]);

  return useMemo(
    () => ({
      loading,
    }),
    [loading]
  );
};

export default useLoadScript;
