import { Group } from 'paper';

import { diagram_width, diagram_height } from 'constants';
import { getArray } from 'utils';
import { Paper } from 'components';

const { PaperView, PaperElement, PaperLayers } = Paper;

class LayersView extends PaperView {
  constructor(options) {
    super(options);

    this.layers = new PaperLayers(options);
    this.children.content.addChild(this.layers.element);
    this.layers.element.bounds.center = this.children.view_rect.bounds.center;
  }
}

class PlanView extends PaperView {
  constructor(options) {
    super(options);

    this.layers_view = new LayersView(options);

    this.children.content.addChildren([this.layers_view.element]);
  }
}

class StructuresController extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'main' });
    super({ element, ...options });

    this.callbacks = {};
    this.onCanvasMouseUp = this.onCanvasMouseUp.bind(this);
    this.scope.project.activeLayer.addChild(element);

    this.plan = new PlanView({
      width: diagram_width,
      height: diagram_height,
      ...options,
    });
    element.addChildren([this.plan.element]);
    this.scope.view.on('mouseup', this.onCanvasMouseUp);
  }

  setLayers(layers, callback) {
    this.plan.layers_view.layers.setLayers(layers, callback);
  }

  onCanvasMouseUp() {
    getArray(this.callbacks.change).forEach((cb) => {
      cb();
    });
  }

  addListener(event, callback) {
    const listeners = this.callbacks[event] || [];
    listeners.push(callback);
    this.callbacks[event] = listeners;
  }

  removeListener(event, callback) {
    if (!callback) {
      delete this.callbacks[event];
      return;
    }
    this.callbacks[event] = getArray(this.callbacks[event]).filter(
      (cb) => cb !== callback
    );
  }

  disableEdit() {
    // void
  }

  enableEdit() {
    // void
  }

  destroy() {
    if (this.scope?.project?.activeLayer) {
      this.scope.project.activeLayer.removeChildren();
    }
  }
}

export default StructuresController;
