import { Box, Typography } from '@mui/material';

import { withProps } from 'hooks';
import { Button as _Button, Center, Modal } from 'components';

const Button = withProps(_Button, {
  fullWidth: false,
  minWidth: 165,
});

const ConfirmSystemsResetModal = (props) => {
  const { onClose, onConfirm, ...rest } = props;

  return (
    <Modal title="Confirm your action" onClose={onClose} {...rest}>
      <Box>
        <Typography variant="body4">
          This action requires a re-configuration of all systems. Existing
          systems will be removed. Are you sure you want to continue?
        </Typography>
      </Box>

      <Center gap={2} mt={6}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <Button onClick={onConfirm}>Confirm</Button>
      </Center>
    </Modal>
  );
};

export default ConfirmSystemsResetModal;
