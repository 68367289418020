import { Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Icon, Ref } from 'components';

const SettingsLayout = (props) => {
  const { title } = props;

  return (
    <Box display="flex" justifyContent="center">
      <Box mt={3} maxWidth={1172} width={1}>
        <Box>
          <Ref to={-1} noline color="secondary.main">
            <Box display="flex" alignItems="center" gap={0.5}>
              <Icon.ArrowDown rotate={90} />

              <Typography variant="body2">back</Typography>
            </Box>
          </Ref>
        </Box>

        {title && (
          <Box mt={2}>
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
        )}

        <Box mt={2} mb={6}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsLayout;
