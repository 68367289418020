import {
  isRejectedWithValue,
  configureStore,
  bindActionCreators,
} from '@reduxjs/toolkit';

import { dev } from 'constants';

import api, { tags } from './api';
import { profile, projects } from './state';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [profile.name]: profile.reducer,
    [projects.name]: projects.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat([
        ({ dispatch }) =>
          (next) =>
          (action) => {
            // Logout on 401 error status
            if (
              isRejectedWithValue(action) &&
              action?.payload?.status === 401
            ) {
              dispatch(profile.actions.logout());
            }
            if (action.type === profile.actions.logout.type) {
              dispatch(api.util.resetApiState());
            }
            return next(action);
          },
      ]),
});

export const {
  // ACCOUNT
  useCreateAccountsMutation,
  useGetInviteInformationQuery,
  useCreateAccountViaInviteMutation,
  useAuthoriseAccountMutation,
  useSuperviseAccountMutation,
  useVerifyEmailMutation,
  useResetPasswordReqeustMutation,
  useChangePasswordMutation,

  //PROFILE
  useGetProfileQuery,
  useGetOrganisationMembersQuery,
  useInviteOrganisationMemberMutation,
  useGetContractorCompaniesQuery,
  useUpdateProfileMutation,
  useCreateContractorCompanyMutation,
  useAddCompanyNoteMutation,
  useUpdateCompanyNoteMutation,
  useDeleteCompanyNoteMutation,
  useToggleNoteVisibilityMutation,
  useAddCompanyRequirementMutation,
  useUpdateCompanyRequirementMutation,
  useDeleteCompanyRequirementMutation,
  useToggleRequirementVisibilityMutation,
  useGetContractorCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
  useRemoveContractorCompanyMutation,
  useChangeAccountPasswordMutation,
  useDeleteUserImageMutation,
  useUploadUserImageMutation,
  useGetCompanyPreferencesQuery,
  useUpdateCompanyPreferencesMutation,
  useUploadToTemporaryStorageMutation,
  useRemoveCompanyImageMutation,
  useUpdateCompanyProfileImageMutation,
  useChangeAccountRoleMutation,
  useRemoveOrganisationMemberMutation,
  useGetOrganistionInvitesQuery,
  useDeleteOrganisationInviteMutation,

  // ELECTRICAL
  useGetInvertersQuery,
  useGetPanelsQuery,
  useLazyGetPanelsQuery,
  useGetOptimizersQuery,
  useGetInverterDetailsQuery,
  useGetWteListQuery,
  useGetAcCombinerListQuery,
  useGetMainServicePanelListQuery,
  useGetAcSubpanelListQuery,
  useGetDisconnectsListQuery,
  useGetWireGaugeListQuery,
  useGetWireTypeListQuery,
  useGetWireMaterialListQuery,
  useGetWireConduitTypeListQuery,
  useGetRsdListQuery,

  // PROJECTS
  useGetProjectsQuery,
  useCreateProjectsMutation,
  useGetProjectDetailsQuery,
  useDeleteProjectMutation,
  useRenameProjectMutation,
  useUpdateProjectsPropertyInformationMutation,
  useSaveProjectSitePlanImageMutation,
  useUpdateProjectsSitePlanMutation,
  useUpdateProjectsContractorMutation,
  useUpdateProjectsArraysMutation,
  useDuplicateProjectMutation,
  useUpdateProjectPropertyPlanMutation,
  useUpdateProjectPropertyPlanScaleMutation,
  useUpdateProjectSystemsMutation,
  useRemoveProjectSystemsMutation,
  useUpdateProjectInterconnectionMutation,
  useUpdateProjectBosMutation,
  useGetProjectWiresTableQuery,
  useSaveProjectWireTableSettingsMutation,
  useGetRecalculatedWireSegmentMutation,
  useSaveRecalculatedWireSegmentMutation,
  useGetSystemsTemplateImageQuery,
  useGetInterconnectionImageQuery,
  useGetBalanceOfSystemImageQuery,
  useGetElevationImageMutation,
  useGetProjectElectricalSummaryQuery,
  useSaveProjectLineDiagramImageMutation,
  useSaveProjectElectricalTablesImageMutation,
  useSaveProjectElectricalPlanImageMutation,
  useSaveProjectPlacardsImageMutation,
  useGetProjectBillOfMaterialQuery,
  useGetPlacardImageQuery,
  useGetFinalPdfMutation,

  // DIRECTORY
  useSearchAhjByNameQuery,
  useSearchUtilitiesByStateQuery,
  useGetClosestWeatherStationQuery,
  useGetWeatherStationQuery,
  useGetConstructionTypeListQuery,
  useGetCountyDetailsQuery,
  useGetCountyByAhjQuery,
  useLazyGetCountyByAhjQuery,
  useSearchCountyNotesByGroupQuery,
  useSearchCountyRequirementByGroupQuery,
  useSearchUtilityNotesByGroupQuery,
  useSearchUtilityRequirementByGroupQuery,

  // DATASHEETS
  useGetProjectDatasheetsQuery,
  useSaveDatasheetMetadataMutation,
  useUploadAdditionalDatasheetMutation,
  useDeleteAdditionalDatasheetMutation,

  // PREVIEW
  useGetProjectPreviewQuery,
  useUpdateProjectPreviewMetadataMutation,
} = api;

const boundActions = bindActionCreators(
  {
    ...profile.actions,
    ...projects.actions,
  },
  store.dispatch
);

export const actions = { ...boundActions };

export const selectors = {
  ...profile.selectors,
  ...projects.selectors,
};

export { api, tags };

if (dev) {
  window.d4pv = { ...window.d4pv, store };
}

export default store;
