import { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { PROJECT_STEPS, PROJECT_STEPS_LIST } from 'constants';
import { routes } from 'routes';
import { useToggle } from 'hooks';
import {
  Center,
  ControlValue,
  DropdownMenu,
  Icon,
  Slip,
  Text,
  TinyButton,
} from 'components';

import PagePreview from './PagePreview';

const handlePreventClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const PreviewProjectItem = (props) => {
  const { page, index, onClick, selected, name } = props;
  const { project_id } = useFormContext();
  const navigate = useNavigate();

  const [menu, toggleMenu] = useToggle();

  const handleMenuClick = useCallback((e) => {
    handlePreventClick(e);
  }, []);

  const handleOpenMenu = useCallback(
    (e) => {
      handlePreventClick(e);
      toggleMenu.on();
    },
    [toggleMenu]
  );

  const handleCloseMenu = useCallback(
    (e) => {
      handlePreventClick(e);
      toggleMenu.off();
    },
    [toggleMenu]
  );

  const handleMenuItemClick = useCallback(
    (v, e) => {
      handleCloseMenu(e);

      if (v.value !== 'label') {
        navigate(routes[v.value].path({ project_id }));
      }
    },
    [handleCloseMenu, navigate, project_id]
  );

  return (
    <Draggable draggableId={`${page.page_number}`} index={index}>
      {(provided) => {
        return (
          <Slip
            p={2}
            elevation={0}
            ref={provided.innerRef}
            height={221}
            border="1px solid transparent"
            borderColor={selected ? 'secondary.main' : 'border.light'}
            onClick={onClick}
            bgcolor={selected ? 'colors.hover_background' : undefined}
            position="relative"
            sx={{ cursor: 'pointer' }}
            {...provided.draggableProps}
          >
            <Center
              top={0}
              left={0}
              width={1}
              zIndex={1}
              position="absolute"
              justifyContent="space-between"
            >
              <Slip
                elevation={0}
                width={40}
                height={40}
                onClick={handlePreventClick}
                {...provided.dragHandleProps}
              >
                <Icon.Moving sx={{ color: 'border.main' }} />
              </Slip>

              <ControlValue value={page.id} name={`${name}.id`} />

              <DropdownMenu
                open={menu}
                onOpen={handleOpenMenu}
                onClose={handleCloseMenu}
                minWidth={172}
                onItemClick={handleMenuItemClick}
                PaperProps={{ mt: 1 }}
                ListProps={{ dense: false }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchor={
                  <TinyButton
                    variant="flat"
                    shadow={4}
                    onClick={handleMenuClick}
                  >
                    <Icon.DotsVertical fontSize="large" />
                  </TinyButton>
                }
                options={[
                  {
                    disabled: true,
                    value: 'label',
                    label: (
                      <Center>
                        <Text
                          sx={{ textTransform: 'uppercase' }}
                          variant="tableCaption"
                        >
                          Information entered at
                        </Text>
                      </Center>
                    ),
                  },
                  ...PROJECT_STEPS_LIST.filter((s) => {
                    return [
                      PROJECT_STEPS.PROPERTY_INFORMATION,
                      PROJECT_STEPS.SITE_DESIGN_CRITERIA,
                      PROJECT_STEPS.CONTRACTOR_COMPANY,
                      PROJECT_STEPS.PRELIMINARY_PLAN,
                    ].includes(s.value);
                  }),
                ]}
              />
            </Center>

            <PagePreview page={page} />
          </Slip>
        );
      }}
    </Draggable>
  );
};

export default PreviewProjectItem;
