import { useMemo } from 'react';

import { useGetProjectDetailsQuery } from 'store';
import { getArray, getString } from 'utils';

const useGetSitePlanMetadata = (props) => {
  const { project_id } = props;

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const metadata = useMemo(() => {
    if (!project) {
      return null;
    }
    const data = project?.site_plan?.metadata;
    const version = getString(data?.version);
    const [major /* , minor, patch */] = version
      .split('.')
      .map((v) => Number.parseInt(v))
      .map((v) => (Number.isFinite(v) ? v : 0));

    if (major < 2 && data) {
      // TODO: This doesn't work. Get rid on remind.
      return {
        notes_state: {
          notes_all: !!data.allNotesSwitch,
          notes_company: !!data.clientNotesSwitch,
          notes_county: !!data.countyNotesSwitch,
          notes_utility: !!data.utilityNotesSwitch,
          notes_custom: !!data.customNotesSwitch,
        },
        notes: getArray(data.notes).map((n) => ({
          id: n.id,
          x: n.x,
          y: n.y,
          text: n.note.text,
          type: n.type === 'client' ? 'company' : n.type,
        })),
        north_angle: data.northAngle,
        scale_type: getString(data.scaleType).toUpperCase(),
        scale: data.scaleValue,
        layers: {
          position: data.layoutsPosition,
        },
        rulers: getArray(data.sizes).map((size) => ({
          id: size.id,
          start: size.startPoint,
          end: size.endPoint,
          height: size.height,
          label: {
            anchor: size.labelAnchor,
            position: size.labelPosition,
            text: size.labelText,
          },
        })),
        references: getArray(data.references).map((ref) => ({
          id: ref.id,
          type: ref.type,
          placed: ref.placed,
          label: ref.label,
          segments: ref.refSegments,
          x: ref.x,
          y: ref.y,
          value: ref.id,
        })),
      };
    }
    return data;
  }, [project]);

  return useMemo(() => {
    return [
      metadata,
      {
        loading: isFetching,
      },
    ];
  }, [metadata, isFetching]);
};

export default useGetSitePlanMetadata;
