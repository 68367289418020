import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateProjectsMutation } from 'store';
import { getFunc } from 'utils';
import { routes } from 'routes';
import { EditProjectForm } from 'views';
import { Modal, ProgressBox, useMessage } from 'components';

const CreateProjectModal = (props) => {
  const { open, onClose, ...rest } = props;

  const m = useMessage();
  const navigate = useNavigate();
  const [createProject, createProjectState] = useCreateProjectsMutation();

  const loading = createProjectState.isLoading;

  const handleClose = useCallback(
    (...args) => {
      getFunc(onClose)(...args);
    },
    [onClose]
  );

  const handleSubmit = useCallback(
    async (body) => {
      try {
        const response = await createProject({ body }).unwrap();
        m.success('New project has been created!');
        handleClose();
        const { project_id } = response;
        navigate(routes.project_details.path({ project_id }));
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, handleClose, createProject, navigate]
  );

  return (
    <Modal
      {...rest}
      open={open}
      onClose={handleClose}
      title="Create New Project"
    >
      <ProgressBox progress={loading}>
        <EditProjectForm
          active={open}
          onSubmit={handleSubmit}
          onCancel={handleClose}
        />
      </ProgressBox>
    </Modal>
  );
};

export default CreateProjectModal;
