import {
  GuestLayout,
  MainLayout,
  SettingsLayout,
  ConfigurationsLayout,
  CompanyDetailsLayout,
  ProjectLayout,
} from 'layouts';

import {
  PageNotFound,
  Auth,
  Projects,
  Users,
  SignOut,
  Companies,
  Profile,
  EmailVerified,
  ResetPassword,
  Invite,
  AddCompany,
  Company,
  CompanyNotes,
  CompanyRequirements,
  CompanyPreferences,
  PropertyInformation,
  SiteDesignCriteria,
  ContractorCompany,
  PreliminaryPlan,
  ArraysParameters,
  PvSystem,
  Interconnection,
  BalanceOfSystem,
  WireTable,
  LineDiagram,
  ElectricalTables,
  SitePlan,
  ElectricalPlan,
  Placards,
  Structures,
  Datasheets,
  Supervise,
  PreviewProject,
} from 'pages';

import { ROLES, ACCESS, PLANS, dev, PROJECT_STEPS as STEPS } from 'constants';
import { getArray, getFunc } from 'utils';

const { GUEST, ANY, USER } = ACCESS;

const tree = [
  {
    id: 'root',
    path: () => '/',
    redirect: () => routes.projects.path(),
    children: [
      {
        access: GUEST,
        element: <GuestLayout />,
        pending: <GuestLayout loading />,
        children: [
          {
            element: <Auth />,
            children: [
              {
                id: 'sign_in',
                path: () => 'sign-in',
              },
              {
                id: 'sign_up',
                path: () => 'sign-up',
              },
              {
                id: 'forgot_password',
                path: () => 'forgot-password',
              },
            ],
          },
          {
            id: 'email_verified',
            element: <EmailVerified />,
            path: (p) => `email-verified/${p?.hash || ':hash'}`,
          },
          {
            id: 'reset_password',
            element: <ResetPassword />,
            path: (p) => `reset-password/${p?.hash || ':hash'}`,
          },
        ],
      },
      {
        access: USER,
        pending: <GuestLayout loading />,
        fallback: () => routes.sign_in.path(),
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                id: 'projects',
                path: () => 'projects',
                element: <Projects />,
              },
              {
                element: <SettingsLayout title="Configurations" />,
                children: [
                  {
                    id: 'configurations',
                    element: <ConfigurationsLayout />,
                    role: ROLES.OWNER,
                    path: () => 'configurations',
                    redirect: () => routes.users.path(),
                    children: [
                      {
                        id: 'users',
                        element: <Users />,
                        path: () => 'users',
                      },
                      {
                        id: 'companies',
                        element: <Companies />,
                        path: () => 'companies',
                      },
                      {
                        id: 'add_company',
                        element: <AddCompany />,
                        path: () => 'companies/add',
                      },
                      {
                        id: 'company',
                        element: <CompanyDetailsLayout />,
                        path: (p) =>
                          `companies/${p?.company_id || ':company_id'}`,
                        children: [
                          {
                            index: true,
                            element: <Company />,
                          },
                          {
                            id: 'company_notes',
                            element: <CompanyNotes />,
                            path: () => 'notes',
                          },
                          {
                            id: 'company_requirements',
                            element: <CompanyRequirements />,
                            path: () => 'requirements',
                          },
                          {
                            id: 'company_preferences',
                            path: () => 'preferences',
                            children: [
                              {
                                index: true,
                                element: <CompanyPreferences />,
                              },
                              {
                                id: 'edit_company_preferences',
                                element: <CompanyPreferences edit />,
                                path: () => 'edit',
                              },
                            ],
                          },
                          {
                            id: 'edit_company',
                            element: <Company edit />,
                            path: () => 'edit',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                element: <SettingsLayout title="Profile" />,
                children: [
                  {
                    id: 'profile',
                    element: <Profile />,
                    path: () => 'profile',
                  },
                ],
              },
            ],
          },
          {
            id: 'project_details',
            element: <ProjectLayout />,
            path: (p) => `projects/${p?.project_id || ':project_id'}`,
            children: [
              {
                id: STEPS.PROPERTY_INFORMATION,
                path: () => 'property-information',
                element: <PropertyInformation />,
              },
              {
                id: STEPS.SITE_DESIGN_CRITERIA,
                path: () => 'site-design-criteria',
                element: <SiteDesignCriteria />,
              },
              {
                id: STEPS.CONTRACTOR_COMPANY,
                path: () => 'contractor-company',
                element: <ContractorCompany />,
              },
              {
                id: STEPS.PRELIMINARY_PLAN,
                path: () => 'preliminary-plan',
                element: <PreliminaryPlan />,
                plan: PLANS.PREMIUM,
                fallback: (p) => routes.project_details.path(p),
              },
              {
                id: STEPS.ARRAYS_PARAMETERS,
                path: () => 'arrays-parameters',
                element: <ArraysParameters />,
              },
              {
                id: STEPS.PV_SYSTEM,
                path: () => 'pv-system',
                element: <PvSystem />,
              },
              {
                id: STEPS.INTERCONNECTION,
                path: () => 'interconnection',
                element: <Interconnection />,
              },
              {
                id: STEPS.BALANCE_OF_SYSTEM,
                path: () => 'balance-of-system',
                element: <BalanceOfSystem />,
              },
              {
                id: STEPS.WIRE_TABLE,
                path: () => 'wire-table',
                element: <WireTable />,
              },
              {
                id: STEPS.LINE_DIAGRAM,
                path: () => 'line-diagram',
                element: <LineDiagram />,
              },
              {
                id: STEPS.ELECTRICAL_TABLES,
                path: () => 'electrical-tables',
                element: <ElectricalTables />,
              },
              {
                id: STEPS.PLACARDS,
                path: () => 'placards',
                element: <Placards />,
              },
              {
                id: STEPS.SITE_PLAN,
                path: () => 'site-plan',
                element: <SitePlan />,
                plan: PLANS.PREMIUM,
                fallback: (p) => routes.project_details.path(p),
              },
              {
                id: STEPS.ELECTRICAL_PLAN,
                path: () => 'electrical-plan',
                element: <ElectricalPlan />,
                plan: PLANS.PREMIUM,
                fallback: (p) => routes.project_details.path(p),
              },
              {
                id: STEPS.STRUCTURES,
                path: () => 'structures',
                element: <Structures />,
                plan: PLANS.PREMIUM,
                fallback: (p) => routes.project_details.path(p),
              },
              {
                id: STEPS.ATTACHMENT_PLAN,
                path: () => 'attachment-plan',
                element: <div>Attachment Plan</div>,
                plan: PLANS.PREMIUM,
                fallback: (p) => routes.project_details.path(p),
              },
              {
                id: STEPS.DATASHEETS,
                path: () => 'datasheets',
                element: <Datasheets />,
                fallback: (p) => routes.project_details.path(p),
              },
              {
                id: STEPS.PROJECT_PREVIEW,
                path: () => 'project_preview',
                element: <PreviewProject />,
                fallback: (p) => routes.project_details.path(p),
              },
            ],
          },
          {
            element: <GuestLayout />,
            children: [
              {
                id: 'sign_out',
                element: <SignOut />,
                path: () => 'sign-out',
              },
            ],
          },
        ],
      },
      {
        access: ANY,
        element: <GuestLayout />,
        children: [
          {
            id: 'invite',
            element: <Invite />,
            path: (p) => `accounts/invite/${p?.hash_str || ':hash_str'}`,
          },
          {
            id: 'supervise',
            element: <Supervise />,
            path: (p) => `supervision_provider/${p?.token || ':token'}`,
          },
          {
            id: 'not_found',
            element: <PageNotFound />,
            path: () => '*',
          },
        ],
      },
    ],
  },
];

const buildRoutes = (routesTree, path) => {
  const result = getArray(routesTree).reduce((result, route) => {
    const levelPath = (params) => {
      const currentLevelPath = getFunc(route.path)(params) || '';
      const previousLevelPath = getFunc(path)(params) || '';
      const needDelimiter = !(
        previousLevelPath.endsWith('/') || currentLevelPath.endsWith('/')
      );
      const delimiter = needDelimiter ? '/' : '';
      return `${previousLevelPath}${delimiter}${currentLevelPath}`;
    };
    const innerRoutes = buildRoutes(route.children, levelPath);
    const currentRoute = {};

    if (route.id) {
      currentRoute[route.id] = { path: levelPath };
    }
    return { ...result, ...innerRoutes, ...currentRoute };
  }, {});

  return result;
};

export const routes = buildRoutes(tree);

if (dev) {
  window.d4pv = {
    ...window.d4pv,
    routes: Object.entries(routes).reduce(
      (res, [key, build]) => ({
        ...res,
        [key]: build.path(),
      }),
      {}
    ),
  };
}

export default tree;
