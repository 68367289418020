import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { useGetProjectDetailsQuery, useUpdateProjectBosMutation } from 'store';
import { BUS_RATING_LIST, LOCATIONS_LIST } from 'constants';
import { routes } from 'routes';
import { useToggle } from 'hooks';
import { ProjectDetailsLayout } from 'layouts';
import { ProjectSubmitButton } from 'views';
import { Form, ProgressBox, useMessage } from 'components';

import BalanceOfSystemPreview from './BalanceOfSystemPreview';
import CombinerPanelFields from './CombinerPanelFields';
import DisconnectFields from './DisconnectFields';
import ProductionMeterFields from './ProductionMeterFields';

const BalanceOfSystem = () => {
  const { project_id } = useParams();
  const [reseted, toggleReseted] = useToggle();
  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });
  const [update, { isLoading }] = useUpdateProjectBosMutation();

  const m = useMessage();
  const form = Form.useForm({
    resetDelay: !project ? 500 : 0,
  });
  const { reset, progress } = form;

  const loading = isFetching || isLoading || progress;
  const isFirstSaving = !project?.bos;
  const nextStep = useMemo(
    () => routes.wire_table.path({ project_id }),
    [project_id]
  );

  useEffect(() => {
    const data = project?.bos;

    if (reseted || !data) {
      return;
    }
    toggleReseted.on();

    reset({
      combiner_enabled: !!data?.combiner_data,
      combiner_panel_type: !data?.combiner_id ? 'generic' : 'vendor',
      combiner_id: data?.combiner_id || '',
      combiner_data: {
        bus_rating: data?.combiner_data?.bus_rating || BUS_RATING_LIST[0].value,
        breaker_rating: data?.combiner_data?.breaker_rating || '',
      },

      disconnect_enabled: !!data?.disconnect_id,
      disconnect_id: data?.disconnect_id || '',
      disconnect_label: data?.disconnect_label || '',

      pv_meter_enabled: !!data?.pv_meter_data,
      pv_meter_data: {
        pv_meter_location:
          data?.pv_meter_data?.location || LOCATIONS_LIST[0].value,
        pv_meter_rating: data?.pv_meter_data?.rating || '',
        pv_meter_exposure_type: data?.pv_meter_data?.exposure_type || '',
        pv_meter_meter_type: data?.pv_meter_data?.meter_type || '',
        pv_meter_label: data?.pv_meter_data?.label || '',
      },
    });
  }, [reset, project, reseted, toggleReseted]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = { ...formData };

        delete body.disconnect_enabled;
        delete body.pv_meter_enabled;
        delete body.combiner_panel_type;

        if (!formData.combiner_enabled) {
          delete body.combiner_id;
          delete body.combiner_data;
        } else {
          if (!formData.combiner_data?.breaker_rating) {
            delete body.combiner_data?.breaker_rating;
          }
        }
        if (!formData.disconnect_enabled) {
          delete body.disconnect_id;
          delete body.disconnect_label;
        }
        if (!formData.pv_meter_enabled) {
          delete body.pv_meter_data;
        } else {
          if (!body.pv_meter_data.pv_meter_rating) {
            body.pv_meter_data.pv_meter_rating = null;
          }
        }
        await update({ project_id, body }).unwrap();

        m.success('Project has been updated!');
        toggleReseted.off();
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [update, project_id, m, toggleReseted]
  );

  return (
    <ProjectDetailsLayout
      form={form}
      title="Balance of System"
      component={Form}
      onSubmit={handleSubmit}
      body={
        <ProgressBox progress={loading}>
          <CombinerPanelFields />
          <DisconnectFields />
          <ProductionMeterFields />

          <Box mt={4}>
            <ProjectSubmitButton
              nextStep={nextStep}
              isFirstSaving={isFirstSaving}
            />
          </Box>
        </ProgressBox>
      }
    >
      <BalanceOfSystemPreview project_id={project_id} />
    </ProjectDetailsLayout>
  );
};

export default BalanceOfSystem;
