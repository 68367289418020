import { Group, Shape } from 'paper';

import PaperElement from 'components/Paper/PaperElement';

class PaperView extends PaperElement {
  constructor(options) {
    const { width, height, ...opts } = options;
    const element = new Group({ name: 'view_group' });
    super({ element, ...opts });

    this.width = width;
    this.height = height;

    this.scale = 1;
    this.scaleFactor = 1;
    this.widthFactor = this.width;

    this.children.view_rect = new Shape.Rectangle({
      name: 'view_rect',
      point: [0, 0],
      size: [this.width, this.height],
    });
    this.children.content = new Group({ name: 'view_content' });

    this.element.addChildren([this.children.view_rect, this.children.content]);
    this.element.clipped = true;
  }

  setScale(scale, origin = 'center') {
    if (!scale) {
      return;
    }
    this.scale = scale;
    const newWidth = this.width * scale;
    const newFactor = newWidth / this.widthFactor;

    this.scaleFactor = newFactor;
    this.widthFactor = newWidth;

    this.children.content.scale(
      this.scaleFactor,
      this.children.view_rect.bounds[origin]
    );
  }

  resize(size = {}) {
    const { width = this.width, height = this.height } = size;

    const newScale = width / this.width;
    this.children.view_rect.size.width = width;
    this.children.view_rect.size.height = height;
    this.setScale(newScale);
    this.width = width;
    this.height = height;
  }
}

export default PaperView;
