import { forwardRef, lazy, useMemo } from 'react';

import { useGetClosestWeatherStationQuery } from 'store';
import { getArray } from 'utils';
import { dev } from 'constants';
import { SelectField } from 'components';

const WeatherStationField = forwardRef((props, ref) => {
  const {
    disabled: customDisabled,
    latitude,
    longitude,
    value,
    ...rest
  } = props;

  const query = useMemo(() => {
    if (latitude && longitude) {
      return {
        body: {
          latitude,
          longitude,
        },
      };
    }
    return null;
  }, [latitude, longitude]);

  const { data, isFetching } = useGetClosestWeatherStationQuery(query, {
    skip: !query,
  });

  const disabled = customDisabled || isFetching || !query;

  const options = useMemo(() => {
    return getArray(data?.ashrae_temps).map((opt) => ({
      value: opt.id,
      label: !opt.distance
        ? opt.station
        : `${opt.station} (${opt.distance.toFixed(1)}mi)`,
    }));
  }, [data]);

  return (
    <SelectField
      ref={ref}
      {...rest}
      value={value}
      options={options}
      disabled={disabled}
      displayEmpty={false}
      label="Weather Station"
      placeholder="Select Weather Station"
    />
  );
});

WeatherStationField.Control = forwardRef((props, ref) => {
  const { ControlComponent = WeatherStationField, ...rest } = props;

  return (
    <SelectField.Control
      ref={ref}
      ControlComponent={ControlComponent}
      {...rest}
    />
  );
});

if (dev) {
  WeatherStationField.Demo = lazy(() => import('./WeatherStationField.demo'));
}

export default WeatherStationField;
