import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Page } from 'react-pdf';
import { Box, Stack } from '@mui/material';

import { selectors } from 'store';
import { useToggle } from 'hooks';
import { ProjectPreview } from 'views';
import { Center, PdfPreview, ProgressBox, Text } from 'components';

const { selectPreviewCollapsed } = selectors;

// NOTE: cache not used because file changes happened without url changes
const DatasheetsPreview = () => {
  const { loading, getValues, selectedDatasheet } = useFormContext();

  const containerRef = useRef(null);
  const [pages, setPages] = useState(0);
  const [docLoading, toggleDocLoading] = useToggle();
  const [width, setWidth] = useState(0);
  const collapsed = useSelector(selectPreviewCollapsed);

  const displayDatasheet = useMemo(() => {
    if (!selectedDatasheet) {
      return null;
    }
    const datasheets = getValues('metadata');
    return datasheets.find((ds) => ds.equipment_id === selectedDatasheet);
  }, [selectedDatasheet, getValues]);

  useEffect(() => {
    setPages(0);

    if (displayDatasheet) {
      toggleDocLoading.on();
    }
  }, [displayDatasheet, toggleDocLoading]);

  useEffect(() => {
    const { current: containerElement } = containerRef;

    if (!containerElement) {
      return;
    }
    const listenResize = () => {
      const metrics = containerElement.getBoundingClientRect();
      setWidth(metrics.width - 40);
    };
    window.addEventListener('resize', listenResize);
    listenResize();

    const tid = setTimeout(() => {
      listenResize();
    }, 500);

    return () => {
      clearTimeout(tid);
      window.removeEventListener('resize', listenResize);
    };
  }, [collapsed]);

  const handleDatasheetLoading = useCallback(
    (opts) => {
      const { numPages } = opts;
      toggleDocLoading.off();
      setPages(numPages);
    },
    [toggleDocLoading]
  );

  return (
    <ProjectPreview>
      <ProgressBox
        p={2}
        height={1}
        overflow="auto"
        ref={containerRef}
        progress={loading || docLoading}
      >
        {!displayDatasheet && (
          <Center height={1}>
            <Text variant="subtitle3" color="border.main">
              No selected datasheet
            </Text>
          </Center>
        )}

        {displayDatasheet && (
          <PdfPreview
            loading={null}
            file={displayDatasheet.file_url}
            onLoadSuccess={handleDatasheetLoading}
          >
            <Stack spacing={2}>
              {new Array(pages).fill(null).map((v, index) => {
                return (
                  <Box
                    border="1px solid transparent"
                    borderColor="border.light"
                    key={`${displayDatasheet.file_url}-${index}`}
                  >
                    <Page
                      scale={1}
                      width={width}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </Box>
                );
              })}
            </Stack>
          </PdfPreview>
        )}
      </ProgressBox>
    </ProjectPreview>
  );
};

export default DatasheetsPreview;
