import { useCallback, useMemo } from 'react';

import {
  useLazyGetPanelsQuery,
  useGetProjectDetailsQuery,
  useUpdateProjectSystemsMutation,
  useRemoveProjectSystemsMutation,
} from 'store';

import { getArray } from 'utils';
import { useToggle } from 'hooks';
import { SYSTEM_TYPES } from 'constants';

const def = {};

const getStrings = (sys) => {
  if (sys.system_type !== SYSTEM_TYPES.STRING) {
    return getArray(sys.strings).map(({ value }) => value);
  }
  return getArray(sys.strings).reduce(
    (res, mppt, i) => ({
      ...res,
      [i + 1]: getArray(mppt.value).map(({ value }) => value),
    }),
    {}
  );
};

const getSystemNumber = (sys, nums) => {
  if (Number.isFinite(sys.system_number)) {
    return sys.system_number;
  }
  let limit = nums.length + 1;
  let result = 0;

  while (nums.includes(result) && limit > 0) {
    limit -= 1;
    result += 1;
  }
  nums.push(result);
  return result;
};

const useSubmitSystems = (props = def) => {
  const { project_id } = props;

  const { data: project } = useGetProjectDetailsQuery({ project_id });

  const [loading, toggleLoading] = useToggle();
  const [getPanels] = useLazyGetPanelsQuery();
  const [postSystems] = useUpdateProjectSystemsMutation();
  const [removeSystem] = useRemoveProjectSystemsMutation();

  const submit = useCallback(
    async (formData) => {
      toggleLoading.on();

      try {
        const { phase, gec, service_voltage, systems } = formData;

        const originalSystemsIds = getArray(project?.systems).map(
          (sys) => sys.id
        );
        const systemsIds = systems.map((sys) => sys.id);
        const removedSystemsIds = originalSystemsIds.filter(
          (id) => !systemsIds.includes(id)
        );

        await Promise.all(
          removedSystemsIds.map(async (system_id) => {
            return await removeSystem({
              project_id,
              system_id,
              skipInvalidation: true,
            }).unwrap();
          })
        );
        const system_numbers = systems
          .filter((sys) => Number.isFinite(sys.system_number))
          .map((sys) => sys.system_number);

        const result = systems.map(async (sys, i) => {
          const {
            panel_id,
            rsd_id,
            wte_id,
            system_type,
            inverter_id: original_inverter_id,
            optimizer_id,
          } = sys;

          let inverter_id = original_inverter_id;

          if (system_type === SYSTEM_TYPES.ACM) {
            const panels = await getPanels({ panel_type: 'ACM' }).unwrap();
            const targetPanel = panels.find((p) => p.id === panel_id);
            inverter_id = targetPanel?.inverter_id;
          }
          const fullSystem = {
            gec,
            rsd_id,
            wte_id,
            panel_id,
            inverter_id,
            optimizer_id,
            service_voltage,
            strings: getStrings(sys),
            phase: Number.parseInt(phase),
            system_number: getSystemNumber(sys, system_numbers),
          };
          if (!rsd_id) {
            delete fullSystem.rsd_id;
          }
          return fullSystem;
        });
        const body = await Promise.all(result);
        const response = await postSystems({ project_id, body }).unwrap();
        return response;
      } finally {
        toggleLoading.off();
      }
    },
    [toggleLoading, postSystems, project_id, getPanels, project, removeSystem]
  );

  return useMemo(
    () => [
      submit,
      {
        isFetching: loading,
      },
    ],
    [submit, loading]
  );
};

export default useSubmitSystems;
