import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { PANEL_TYPES_LIST } from 'constants';
import { withProps } from 'hooks';
import { Center, Ref } from 'components';

import ArrayItem from './ArrayItem';

const AddArrayButtonContainer = withProps(Center, {
  mt: 4,
  mx: 3,
  py: 2,
  sx: (theme) => ({
    borderTop: `1px dashed ${theme.palette.secondary.main}`,
    borderBottom: `1px dashed ${theme.palette.secondary.main}`,
  }),
});

const ArraysForm = () => {
  const { control } = useFormContext();

  const [editArray, setEditArray] = useState();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'arrays',
  });

  const createEmptyArray = useCallback(() => {
    return {
      pitch: 0,
      azimuth: 0,
      panels_number: 1,
      panel_type: PANEL_TYPES_LIST[0].value,
    };
  }, []);

  const handleAddArray = useCallback(() => {
    append(createEmptyArray());
    setEditArray(fields.length);
  }, [append, createEmptyArray, fields]);

  useEffect(() => {
    if (fields.length === 0) {
      append(createEmptyArray());
      setEditArray(0);
    }
  }, [append, createEmptyArray, fields]);

  return (
    <Box>
      {fields.map((array, i) => {
        const handleRemove = () => {
          if (editArray > i) {
            setEditArray(editArray - 1);
          } else if (editArray === i) {
            setEditArray(null);
          }
          remove(i);
        };
        return (
          <ArrayItem
            key={array.id}
            array={array}
            index={i}
            name="arrays"
            edit={editArray === i}
            ableRemove={fields.length > 1}
            onEdit={() => setEditArray(i)}
            onRemove={handleRemove}
          />
        );
      })}

      <AddArrayButtonContainer>
        <Typography variant="body2" color="secondary.main">
          <Ref onClick={handleAddArray}>+ Add Array</Ref>
        </Typography>
      </AddArrayButtonContainer>
    </Box>
  );
};

export default ArraysForm;
