import { forwardRef, lazy } from 'react';
import {
  Tooltip as MuiTooltip,
  tooltipClasses as classes,
} from '@mui/material';

import { makeClasses } from 'hooks';
import { dev } from 'constants';

const Root = makeClasses(MuiTooltip, {
  shouldForwardProp: (prop) => !['contentMaxWidth'].includes(prop),
})({
  popper: ({ theme, contentMaxWidth }) => ({
    [`&.${classes.popper}`]: {
      [`& .${classes.tooltip}`]: {
        color: theme.palette.text.label,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.makeShadow(1),
        padding: theme.spacing(2),
        maxWidth: contentMaxWidth,
      },
    },
  }),
});

const Tooltip = forwardRef((props, ref) => {
  const { contentMaxWidth = 250, placement = 'right-start', ...rest } = props;

  return (
    <Root
      ref={ref}
      contentMaxWidth={contentMaxWidth}
      placement={placement}
      {...rest}
    />
  );
});

if (dev) {
  Tooltip.Demo = lazy(() => import('./Tooltip.demo'));
}

export default Tooltip;
