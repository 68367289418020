import { useCallback, useEffect } from 'react';
import { Typography, Box } from '@mui/material';

import { useGetProfileQuery, useUpdateProfileMutation } from 'store';
import { Button, Form, ProgressBox, TextField, useMessage } from 'components';

const OrganizationInformation = () => {
  const m = useMessage();
  const form = Form.useForm();
  const { reset, formState } = form;
  const { isValid, isDirty } = formState;

  const { data: user, isFetching } = useGetProfileQuery();
  const [updateProfile, updateProfileState] = useUpdateProfileMutation();

  const loading = isFetching || updateProfileState.isLoading;

  useEffect(() => {
    if (!user) {
      return;
    }
    reset({
      organisation: user?.organisation?.name,
    });
  }, [reset, user]);

  const handleCancel = useCallback(() => {
    reset();
  }, [reset]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = {
          ...formData,
          first_name: user.first_name,
          last_name: user.last_name,
        };
        await updateProfile({ body }).unwrap();
        m.success('Organisation name has been updated!');
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, updateProfile, user]
  );

  return (
    <ProgressBox progress={loading}>
      <Typography variant="subtitle2">Organization Information</Typography>

      <Form mt={5} form={form} onSubmit={handleSubmit}>
        <TextField.Control
          required
          name="organisation"
          label="Organization Name"
          defaultValue={user?.organisation?.name}
          placeholder="Type your organization name"
        />

        <Box pt={3} display="flex" gap={1}>
          <Button
            variant="outlined"
            fullWidth={false}
            disabled={!isDirty}
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            minWidth={200}
            fullWidth={false}
            disabled={!isValid || !isDirty}
          >
            Save
          </Button>
        </Box>
      </Form>
    </ProgressBox>
  );
};

export default OrganizationInformation;
