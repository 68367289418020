import { forwardRef, lazy, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { dev } from 'constants';
import { getFunc, getString, rules as defaultRules } from 'utils';
import { propagateRefs } from 'hooks';

import FormLabel from 'components/FormLabel';
import HelperText from 'components/HelperText';
import Input from 'components/Input';
import FormControl from 'components/FormControl';

const TextField = forwardRef((props, ref) => {
  const {
    label,
    error,
    fullWidth = true,
    disabled,
    required,
    helperText,
    hideAsterisk,
    InputLabelProps,
    InputProps,
    readOnly,
    reserveHelperTextSpace = true,
    sx,
    Component = Input,
    ...rest
  } = props;

  return (
    <FormControl fullWidth={fullWidth} ref={ref} sx={sx}>
      {label && (
        <FormLabel
          error={error}
          readOnly={readOnly}
          required={required}
          disabled={disabled}
          hideAsterisk={hideAsterisk}
          {...InputLabelProps}
        >
          {label}
        </FormLabel>
      )}

      <Component
        error={error}
        disabled={disabled}
        readOnly={readOnly}
        {...rest}
        {...InputProps}
      />

      <HelperText
        error={error}
        disabled={disabled}
        reserveSpace={reserveHelperTextSpace}
      >
        {helperText}
      </HelperText>
    </FormControl>
  );
});

const defaultControlChangeHandler = (field) => (e) => {
  field.onChange(e);
};

TextField.Control = forwardRef((props, ref) => {
  const {
    name,
    type,
    error,
    onBlur,
    onChange,
    inputRef,
    required,
    helperText,
    shouldUnregister,
    defaultValue = '',
    rules: customRules,
    controlChangeHandler = defaultControlChangeHandler,
    ControlComponent = TextField,
    ...rest
  } = props;

  const { control } = useFormContext();

  const rules = useMemo(() => {
    const result = {
      ...customRules,
      validate: { ...customRules?.validate },
    };

    if (required) {
      result.required = defaultRules.required;
    }
    if (type === 'email') {
      result.validate.email = defaultRules.email;
    }
    return result;
  }, [customRules, required, type]);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={getString(defaultValue)}
      render={({ field, fieldState }) => {
        return (
          <ControlComponent
            ref={ref}
            {...rest}
            type={type}
            name={field.name}
            value={field.value}
            required={required}
            error={!!(error || fieldState.error)}
            inputRef={propagateRefs(inputRef, field.ref)}
            helperText={helperText || fieldState.error?.message}
            onChange={(...args) => {
              controlChangeHandler(field)(...args);
              getFunc(onChange)(...args);
            }}
            onBlur={(...args) => {
              field.onBlur(...args);
              getFunc(onBlur)(...args);
            }}
          />
        );
      }}
    />
  );
});

if (dev) {
  TextField.Demo = lazy(() => import('./TextField.demo'));
}

export default TextField;
