import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { routes } from 'routes';
import { useGetContractorCompanyDetailsQuery } from 'store';
import { CompanyDetails } from 'views';

const Company = (props) => {
  const { edit } = props;
  const { company_id } = useParams();
  const { data: company, isFetching } = useGetContractorCompanyDetailsQuery({
    company_id,
  });

  return (
    <Box mt={3} flexGrow={1} px={4} pb={4}>
      <CompanyDetails
        readOnly={!edit}
        company={company}
        loading={isFetching}
        back={routes.company.path({ company_id })}
      />
    </Box>
  );
};

export default Company;
