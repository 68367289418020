import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';

import { getResponseMessage } from 'utils';
import Message from 'components/Message';

export const messagesTypes = ['default', 'success', 'error', 'info', 'warning'];

const useMessage = function (defaultVariant = 'success') {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const renderMessage = useCallback(
    (variant) => (key, text) =>
      (
        <Message variant={variant} onClose={() => closeSnackbar(key)}>
          {text}
        </Message>
      ),
    [closeSnackbar]
  );

  const message = useMemo(() => {
    const messanger = function (text, variant = defaultVariant, config = {}) {
      enqueueSnackbar(text, {
        ...config,
        variant,
        content: renderMessage(variant),
      });
    };
    messanger.default = (text, ...rest) => messanger(text, 'default', ...rest);
    messanger.success = (text, ...rest) => messanger(text, 'success', ...rest);
    messanger.error = (text, ...rest) => messanger(text, 'error', ...rest);
    messanger.info = (text, ...rest) => messanger(text, 'info', ...rest);
    messanger.warning = (text, ...rest) => messanger(text, 'warning', ...rest);
    messanger.responseError = (ex, opts, ...rest) => {
      let text = 'Something went wrong...';
      let type = 'error';

      if (opts && typeof opts === 'object') {
        if (opts.text) {
          text = opts.text;
        }
        if (opts.type) {
          type = opts.type;
        }
      } else if (typeof opts === 'string') {
        text = opts;
      }
      console.error(ex);
      messanger[type](getResponseMessage(ex) || text, ...rest);
    };
    messanger.warn = messanger.warning;
    return messanger;
  }, [enqueueSnackbar, defaultVariant, renderMessage]);

  return message;
};
useMessage.types = messagesTypes;

export default useMessage;
