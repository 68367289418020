import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Collapse } from '@mui/material';

import { getArray } from 'utils';
import { Autocomplete } from 'components';

const InverterField = (props) => {
  const { name, prefix, visible, inverters, ...rest } = props;

  const { resetField } = useFormContext();

  const options = useMemo(() => {
    return getArray(inverters).map((inverter) => ({
      value: inverter.id,
      label: `${inverter.vendor.title} - ${inverter.title}`,
    }));
  }, [inverters]);

  const handleInverterChange = useCallback(() => {
    resetField(`${prefix}.strings`, {
      defaultValue: [],
    });
  }, [prefix, resetField]);

  return (
    <Collapse in={visible}>
      <Autocomplete.Control
        {...rest}
        required
        valueAsId
        onChange={handleInverterChange}
        options={options}
        name={`${prefix}.${name}`}
        rules={{ disabled: !visible }}
      />
    </Collapse>
  );
};

export default InverterField;
