import { Group, Shape, Path } from 'paper';

import PaperElement from 'components/Paper/PaperElement';
import Text from 'components/Paper/PaperText';

const compass_outer_size = 56;
const compass_size = 32;

class PaperCompass extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'compass_group' });
    super({ element, ...options });

    this.rect = new Shape.Rectangle({
      point: [0, 0],
      size: [compass_size, compass_size],
      strokeColor: 'black',
    });
    this.rect_outer = new Shape.Rectangle({
      point: [0, 0],
      size: [compass_outer_size, compass_outer_size],
    });
    this.line_vertical = new Path.Line({
      from: [0, 0],
      to: [0, compass_size],
      strokeColor: 'black',
    });
    this.line_horizontal = new Path.Line({
      from: [0, 0],
      to: [compass_size, 0],
      strokeColor: 'black',
    });
    this.line_accent = new Path.Line({
      from: [0, 0],
      to: [0, compass_size / 2],
      strokeWidth: 3,
      strokeColor: 'black',
    });
    this.text_west = new Text({ content: 'W', fontSize: 8 });
    this.text_east = new Text({ content: 'E', fontSize: 8 });
    this.text_south = new Text({ content: 'S', fontSize: 8 });
    this.text_north = new Text({ content: 'N', fontSize: 11 });

    element.addChildren([
      this.rect,
      this.rect_outer,
      this.line_vertical,
      this.line_horizontal,
      this.line_accent,
      this.text_west,
      this.text_east,
      this.text_south,
      this.text_north,
    ]);
    this.rect.bounds.center = this.rect_outer.bounds.center;
    this.line_vertical.bounds.center = this.rect.bounds.center;
    this.line_horizontal.bounds.center = this.rect.bounds.center;
    this.line_accent.bounds.topCenter = this.rect.bounds.topCenter;
    this.text_west.bounds.leftCenter = this.rect_outer.bounds.leftCenter;
    this.text_east.bounds.rightCenter = this.rect_outer.bounds.rightCenter;
    this.text_south.bounds.bottomCenter = this.rect_outer.bounds.bottomCenter;
    this.text_north.bounds.topCenter = this.rect_outer.bounds.topCenter;
  }
}

export default PaperCompass;
