import { Group } from 'paper';

import { getFunc } from 'utils';
import PaperSvg from 'components/Paper/PaperSvg';
import PaperDraggable from 'components/Paper/PaperDraggable';

class PaperMark extends PaperDraggable {
  constructor(options) {
    const { data, ...opts } = options;
    const element = new Group({ name: 'mark_group' });
    super({
      element,
      onDrop: (...args) => {
        getFunc(options.onDrop)(...args);
        this.data.x = element.bounds.x;
        this.data.y = element.bounds.y;
      },
      ...opts,
    });
    element.visible = false;

    this.data = {
      ...data,
      x: data.x || 0,
      y: data.y || 0,
      scale: data.scale || 0.5,
    };
    const mark = new PaperSvg(opts);
    element.addChild(mark.element);

    mark.upload(this.data.element, () => {
      mark.element.scale(this.data.scale);
      this.update();
    });
  }

  update(data = this.data) {
    this.data = { ...this.data, ...data };
    this.element.visible = !!this.data.placed;

    if (this.element.parent) {
      this.element.bounds.topLeft = this.point(this.data.x, this.data.y);
    }
  }
}

export default PaperMark;
