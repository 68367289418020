import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  useGetProjectDetailsQuery,
  useUpdateProjectPropertyPlanScaleMutation,
  useUpdateProjectPropertyPlanMutation,
} from 'store';

import { routes } from 'routes';
import { SCALES_LIST } from 'constants';
import { Form, useMessage } from 'components';

import EmptyPlan from './EmptyPlan';
import PreliminaryPlanPreview from './PreliminaryPlanPreview';

const PreliminaryPlan = () => {
  const { project_id } = useParams();
  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });
  const [update, { isLoading }] = useUpdateProjectPropertyPlanScaleMutation();
  const [, { isLoading: templateLoading }] =
    useUpdateProjectPropertyPlanMutation({
      fixedCacheKey: 'preliminary_plan',
    });
  const templateRef = useRef();

  const navigate = useNavigate();
  const m = useMessage();
  const form = Form.useForm();

  const loading = isFetching || isLoading || templateLoading;
  const isFirstSaving = !project?.scale;
  const nextStep = useMemo(
    () => routes.arrays_parameters.path({ project_id }),
    [project_id]
  );

  useEffect(() => {
    // images pathes always stays the same, even after new template loading, this is the way to
    // ignore browser cache
    const propertyPlan = !project?.property_plan
      ? undefined
      : Object.entries(project.property_plan).reduce(
          (acc, [name, value]) => ({
            ...acc,
            [name]: `${value}?${Date.now()}`,
          }),
          {}
        );

    form.reset({
      scale: project?.scale || SCALES_LIST[0].value,
      layers: propertyPlan,
    });
  }, [form, project]);

  const handleDeleteTemplate = useCallback(() => {
    form.setValue('layers', null);
  }, [form]);

  const handleReplaceTemplate = useCallback(() => {
    if (templateRef.current) {
      templateRef.current.click();
    }
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const { scale } = formData;

        await update({
          project_id,
          body: { scale },
        }).unwrap();

        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [update, project_id, m, isFirstSaving, navigate, nextStep]
  );

  if (isFetching) {
    return null;
  }
  return (
    <Form form={form} flexGrow={1} width={1} height={1} onSubmit={handleSubmit}>
      <EmptyPlan
        loading={loading}
        project_id={project_id}
        templateRef={templateRef}
      />

      <PreliminaryPlanPreview
        loading={loading}
        project_id={project_id}
        nextStep={nextStep}
        isFirstSaving={isFirstSaving}
        onDelete={handleDeleteTemplate}
        onReplace={handleReplaceTemplate}
      />
    </Form>
  );
};

export default PreliminaryPlan;
