import { useCallback } from 'react';
import { Box, useTheme } from '@mui/material';

import { getOwnerFullName } from 'utils';
import { useToggle, withProps } from 'hooks';
import { useDuplicateProjectMutation } from 'store';
import { PROJECTS_VIEW, PROJECTS_CACHE_KEY } from 'constants';
import { Avatar, DropdownMenu, Icon, TinyButton, useMessage } from 'components';

import DeleteProjectModal from './DeleteProjectModal';
import RenameProjectModal from './RenameProjectModal';

const { GRID } = PROJECTS_VIEW;

const ImageButtons = withProps(Box, {
  gap: 2,
  display: 'flex',
  alignItems: 'center',
});

const ButtonMenu = withProps(DropdownMenu, {
  minWidth: 150,
  ListProps: {
    dense: false,
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
});

const DUPLICATE = 'DUPLICATE';
const RENAME = 'RENAME';
const REMOVE = 'REMOVE';

const ProjectCard = (props) => {
  const { project, view } = props;

  const { id: project_id } = project;
  const fullName = getOwnerFullName(project);

  const m = useMessage();
  const theme = useTheme();
  const [removeModal, toggleRemoveModal] = useToggle();
  const [renameModal, toggleRenameModal] = useToggle();

  const [duplicateProject] = useDuplicateProjectMutation({
    fixedCacheKey: PROJECTS_CACHE_KEY,
  });

  const handleDuplicateProject = useCallback(async () => {
    try {
      await duplicateProject({ project_id }).unwrap();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [m, duplicateProject, project_id]);

  const handleAction = useCallback(
    (option) => {
      switch (option.value) {
        case REMOVE:
          return toggleRemoveModal.on();
        case DUPLICATE:
          return handleDuplicateProject();
        case RENAME:
          return toggleRenameModal.on();
        default:
          return console.log(option);
      }
    },
    [toggleRemoveModal, handleDuplicateProject, toggleRenameModal]
  );

  return (
    <>
      {/* MODALS */}
      <>
        <DeleteProjectModal
          project={project}
          open={removeModal}
          onClose={toggleRemoveModal.off}
        />

        <RenameProjectModal
          project={project}
          open={renameModal}
          onClose={toggleRenameModal.off}
        />
      </>

      <ImageButtons flexDirection={view === GRID ? 'column' : 'row-reverse'}>
        <ButtonMenu
          onItemClick={handleAction}
          PaperProps={{
            mt: 1,
            border: `1px solid ${theme.palette.border.light}`,
          }}
          anchor={
            <TinyButton variant="flat">
              <Icon.DotsVertical fontSize="large" />
            </TinyButton>
          }
          options={[
            {
              value: DUPLICATE,
              label: 'Duplicate',
            },
            {
              value: RENAME,
              label: 'Rename',
              divider: true,
            },
            {
              value: REMOVE,
              label: 'Delete',
            },
          ]}
        />

        {fullName && (
          <Avatar
            size="small"
            name={fullName}
            fontSizeFactor={0.9}
            borderColor="transparent"
            sx={{ fontWeight: 700 }}
          />
        )}
      </ImageButtons>
    </>
  );
};

export default ProjectCard;
