import { forwardRef, lazy, useEffect, useRef } from 'react';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

import { dev } from 'constants';
import { useCombineRefs } from 'hooks';

const Ripple = forwardRef((props, ref) => {
  const { show } = props;

  const rippleRef = useRef(null);
  const handleRef = useCombineRefs(ref, rippleRef);

  useEffect(() => {
    const { current: ripple } = rippleRef;

    if (ripple) {
      if (show) {
        ripple.pulsate();
      } else {
        ripple.stop({});
      }
      return () => {
        ripple.stop({});
      };
    }
  }, [show]);

  return <TouchRipple center ref={handleRef} />;
});

if (dev) {
  Ripple.Demo = lazy(() => import('./Ripple.demo'));
}

export default Ripple;
