import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Typography, Stack } from '@mui/material';

import {
  useGetPanelsQuery,
  useGetRsdListQuery,
  useGetWteListQuery,
  useGetInvertersQuery,
  useGetOptimizersQuery,
  useGetWeatherStationQuery,
  useGetInverterDetailsQuery,
} from 'store';

import { getArray } from 'utils';
import { SYSTEM_TYPES_LIST, SYSTEM_TYPES } from 'constants';
import { FormArrayItem } from 'views';
import { HelperText } from 'components';

import SystemTypeField from './SystemTypeField';
import PanelsField from './PanelsField';
import WteSelectField from './WteSelectField';
import InverterField from './InverterField';
import RsdField from './RsdField';
import DcOptimizerField from './DcOptimizerField';
import SystemMeta from './SystemMeta';
import SystemDescription from './SystemDescription';
import SystemStrings from './SystemStrings';

const { ACM, STRING, DC_OPTIMIZED } = SYSTEM_TYPES;

const spacing = { mt: 1 };

const SystemItem = (props) => {
  const { name, index, edit, system, ableRemove, onRemove, onEdit } = props;

  const prefix = `${name}.${index}`;
  const { control, station_id, getFieldState, setValue } = useFormContext();
  const systemErrors = getFieldState(prefix).error;

  const phase = useWatch({ control, name: 'phase' });
  const rated_voltage = useWatch({ control, name: 'service_voltage' });
  const system_type = useWatch({ control, name: `${prefix}.system_type` });
  const panel_id = useWatch({ control, name: `${prefix}.panel_id` });
  const original_inverter_id = useWatch({
    control,
    name: `${prefix}.inverter_id`,
  });
  const optimizer_id = useWatch({ control, name: `${prefix}.optimizer_id` });

  const isAcm = system_type === ACM;
  const isString = system_type === STRING;
  const isOptimizer = system_type === DC_OPTIMIZED;

  // STATION
  const { data: station } = useGetWeatherStationQuery(
    { station_id },
    {
      skip: !station_id,
    }
  );

  // PANELS LIST
  const panelsSubject = isAcm ? 'AC' : 'PV';

  const panelsQuery = useMemo(
    () => ({
      panel_type: isAcm ? 'ACM' : 'REGULAR',
    }),
    [isAcm]
  );

  const { data: panelsList, isFetching: panelsListLoading } = useGetPanelsQuery(
    { params: panelsQuery }
  );

  const panelItem = useMemo(
    () => getArray(panelsList).find((p) => p.id === panel_id),
    [panelsList, panel_id]
  );

  const inverter_id = original_inverter_id || panelItem?.inverter_id;

  // RSD LIST
  const { data: rsdList, isFetching: rsdListLoading } = useGetRsdListQuery(
    undefined,
    { skip: !isString }
  );

  // DC OPTIMIZER LIST
  const optimizersQuery = useMemo(() => {
    if (!panel_id || !station || !isOptimizer) {
      return null;
    }
    return {
      phase,
      panel_id,
      t_min: station.temp_min,
      t_max: station.temp_max,
    };
  }, [phase, panel_id, station, isOptimizer]);

  const { data: optimizersList, isFetching: optimizersListLoading } =
    useGetOptimizersQuery(
      { params: optimizersQuery },
      { skip: !optimizersQuery }
    );

  // INVERTERS LIST
  const invertersQuery = useMemo(() => {
    if (!panel_id || !station || isAcm) {
      return null;
    }
    return {
      phase,
      panel_id,
      rated_voltage,
      t_min: station.temp_min,
      t_max: station.temp_max,
      inverter_type: !isAcm ? system_type : undefined,
    };
  }, [panel_id, station, isAcm, phase, rated_voltage, system_type]);

  const { data: invertersList, isFetching: invertersListLoading } =
    useGetInvertersQuery({ params: invertersQuery }, { skip: !invertersQuery });

  useEffect(() => {
    if (invertersList && !invertersListLoading && panel_id && inverter_id) {
      const inOptions = invertersList.some((inv) => inv.id === inverter_id);

      if (!inOptions) {
        setValue(`${prefix}.inverter_id`, null);
      }
    }
  }, [
    invertersList,
    invertersListLoading,
    panel_id,
    inverter_id,
    setValue,
    prefix,
  ]);

  // WTE
  const { data: wteList, isFetching: wteListLoading } = useGetWteListQuery();

  // INVERTER DETAILS
  const inverterQuery = useMemo(() => {
    if (!inverter_id || (isOptimizer && !optimizer_id)) {
      return null;
    }
    if (!panel_id || !station) {
      return null;
    }
    return {
      panel_id,
      inverter_id,
      params: {
        optimizer_id,
        mount: 'pitched',
        t_min: station.temp_min,
        t_max: station.temp_max,
      },
    };
  }, [inverter_id, isOptimizer, optimizer_id, panel_id, station]);

  const { data: inverterDetails } = useGetInverterDetailsQuery(inverterQuery, {
    skip: !inverterQuery,
  });

  return (
    <FormArrayItem
      edit={edit}
      ableRemove={ableRemove}
      onRemove={onRemove}
      onEdit={onEdit}
      title={
        <Stack spacing={1} direction="row" alignItems="baseline">
          <span>System #{index + 1}</span>

          <HelperText error>
            {systemErrors && (
              <Typography component="span">Has Errors</Typography>
            )}
          </HelperText>
        </Stack>
      }
      form={
        <Box display="flex" flexDirection="column" mt={1}>
          <SystemTypeField
            label="Type"
            prefix={prefix}
            name="system_type"
            defaultValue={system?.system_type || SYSTEM_TYPES_LIST[0].value}
          />

          <PanelsField
            sx={spacing}
            name="panel_id"
            prefix={prefix}
            panels={panelsList}
            loading={panelsListLoading}
            label={`${panelsSubject} Panel`}
            defaultValue={system?.panel_id || ''}
            placeholder={`Type to search for ${panelsSubject} module`}
          />

          <RsdField
            label="RSD"
            sx={spacing}
            name="rsd_id"
            rsd={rsdList}
            prefix={prefix}
            visible={isString}
            loading={rsdListLoading}
            defaultValue={system.rsd_id || ''}
            placeholder="Type to search for RSD"
          />

          <DcOptimizerField
            sx={spacing}
            prefix={prefix}
            name="optimizer_id"
            label="DC Optimizer"
            disabled={!panel_id}
            visible={isOptimizer}
            optimizers={optimizersList}
            loading={optimizersListLoading}
            placeholder="Select DC Optimizer"
            defaultValue={system.optimizer_id || ''}
          />

          <InverterField
            sx={spacing}
            prefix={prefix}
            label="Inverter"
            visible={!isAcm}
            name="inverter_id"
            inverters={invertersList}
            loading={invertersListLoading}
            disabled={isOptimizer ? !optimizer_id : !panel_id}
            placeholder="Type to search for inverter model"
            defaultValue={system.inverter_id || ''}
          />

          <WteSelectField
            sx={spacing}
            wte={wteList}
            loading={wteListLoading}
            placeholder="Select WTE"
            name={`${prefix}.wte_id`}
            label="Wire Transition Enclousure"
            defaultValue={system?.wte_id || ''}
          />

          <SystemMeta mt={2} prefix={prefix} inverter={inverterDetails} />

          <SystemStrings prefix={prefix} inverter={inverterDetails} />
        </Box>
      }
      description={
        <SystemDescription
          prefix={prefix}
          wte={wteList}
          panel={panelItem}
          inverter={inverterDetails}
          optimizers={optimizersList}
        />
      }
    />
  );
};

export default SystemItem;
