import { Box, Grid } from '@mui/material';

import { useToggle } from 'hooks';
import { Ref, TextField } from 'components';
import AddressField from 'views/AddressField';
import StatesField from 'views/StatesField';

const ContractorCompanyAddress = (props) => {
  const { readOnly } = props;
  const [manual, toggleManual] = useToggle();

  return (
    <Grid container columnSpacing={4} rowSpacing={0}>
      {(!manual || readOnly) && (
        <Grid item xs={8}>
          <AddressField.Control
            required
            readOnly={readOnly}
            name="address"
            hideAsterisk
            onChange={(e, val) => {
              if (val) {
                toggleManual.on();
              }
            }}
            label={
              <Box display="flex" justifyContent="space-between">
                <span>Search Address {readOnly ? '' : '*'}</span>

                {!readOnly && (
                  <Ref
                    noline
                    fontWeight={600}
                    onClick={toggleManual}
                    color="secondary.main"
                  >
                    Enter Manually
                  </Ref>
                )}
              </Box>
            }
          />
        </Grid>
      )}

      {manual && !readOnly && (
        <>
          <Grid item xs={8}>
            <TextField.Control
              required
              hideAsterisk
              name="address.address_line1"
              label={
                <Box display="flex" justifyContent="space-between">
                  <span>Address Line 1 *</span>

                  <Ref
                    noline
                    fontWeight={600}
                    onClick={toggleManual}
                    color="secondary.main"
                  >
                    Search Location
                  </Ref>
                </Box>
              }
            />
          </Grid>

          <Grid item xs={4}>
            <TextField.Control
              label="Address Line 2"
              name="address.address_line2"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField.Control required label="City" name="address.city" />
          </Grid>

          <Grid item xs={4}>
            <StatesField.Control required label="State" name="address.state" />
          </Grid>

          <Grid item xs={4}>
            <TextField.Control required label="ZIP" name="address.zip_code" />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ContractorCompanyAddress;
