import { useWatch, useFormContext } from 'react-hook-form';

import { WeatherStationField } from 'views';

const WeatherStation = (props) => {
  const { name } = props;
  const { control } = useFormContext();
  const address = useWatch({ control, name: 'address' });

  return (
    <WeatherStationField.Control
      required
      name={name}
      latitude={address?.latitude}
      longitude={address?.longitude}
    />
  );
};

export default WeatherStation;
