import { darken, alpha } from '@mui/material';
import { createTheme } from '@mui/material/styles';

// Material UI dependency
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Project dependency
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';

import '@fontsource/montserrat/100-italic.css';
import '@fontsource/montserrat/200-italic.css';
import '@fontsource/montserrat/300-italic.css';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/500-italic.css';
import '@fontsource/montserrat/600-italic.css';
import '@fontsource/montserrat/700-italic.css';
import '@fontsource/montserrat/800-italic.css';
import '@fontsource/montserrat/900-italic.css';

import * as fonts from 'assets/fonts';

import { dev } from 'constants';

import * as colors from './colors';
import * as typography from './typography';
import * as shadows from './shadows';
import * as utils from './styleUtils';

const { makeShadow } = shadows;
const { pxToRem, fontSize, htmlFontSize } = typography;

const scrollWidth = 6;

const buildTheme = (props = {}) => {
  const { spacing = 8, borderRadius = 4 } = props;

  const radiusFactor = borderRadius / spacing;

  const theme = createTheme({
    utils,
    alpha,
    darken,
    spacing,
    pxToRem,
    makeShadow,
    typography: {
      fontSize,
      htmlFontSize,
      body1: typography.body1,
      body2: typography.body2,
      body3: typography.body3,
      body4: typography.body4,
      subtitle1: typography.subtitle1,
      subtitle2: typography.subtitle2,
      subtitle3: typography.subtitle3,
      subtitle4: typography.subtitle4,
      subtitle5: typography.subtitle5,
      button: typography.button,
      caption: typography.caption,
      formCaption: typography.form_caption,
      tableCaption: typography.table_caption,
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    palette: {
      colors,
      primary: {
        light: colors.primary_light,
        main: colors.primary_main,
        dark: colors.primary_dark,
        contrastText: colors.primary_contrast_text,
      },
      secondary: {
        light: colors.secondary_light,
        main: colors.secondary_main,
        dark: colors.secondary_dark,
        contrastText: colors.secondary_contrast_text,
      },
      violet: {
        light: colors.violet_light,
        main: colors.violet_main,
        dark: colors.violet_dark,
      },
      error: {
        light: colors.error_light,
        main: colors.error_main,
        dark: colors.error_dark,
      },
      text: {
        primary: colors.primary_main,
        secondary: colors.primary_light,
        disabled: colors.disabled,
        label: colors.label,
        placeholder: colors.placeholder,
      },
      border: {
        light: colors.border_light,
        main: colors.border_main,
        dark: colors.border_dark,
      },
      action: {
        active: colors.action_active,
      },
      paper: {
        primary: colors.paper_primary,
        secondary: colors.paper_secondary,
      },
    },
    radius: (...args) => {
      const modifiedArgs = [...args].map((n) =>
        Number.isFinite(n) ? n * radiusFactor : n
      );
      return theme.spacing(...modifiedArgs);
    },
    transitions: {
      easing: {
        linear: 'linier()',
      },
    },
    shape: {
      spacing,
      borderRadius,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          html, body, #root {
            overflow: hidden;
            width: 100%;
            height: 100%;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
            -webkit-box-shadow: 0 0 0px 1000px ${
              colors.autofill_background
            } inset;
          }
          input[data-autocompleted] {
            background-color: transparent !important;
            -webkit-box-shadow: 0 0 0px 1000px ${
              colors.autofill_background
            } inset;
          }
          input:-internal-autofill-selected {
            background-color: transparent !important;
            -webkit-box-shadow: 0 0 0px 1000px ${
              colors.autofill_background
            } inset;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0px 1000px ${
              colors.autofill_background
            } inset;
          }

          *:not(body)::-webkit-scrollbar {
            width: ${scrollWidth}px;
            max-height: ${scrollWidth}px;
            border-radius: ${scrollWidth}px;
            background-color: ${colors.scroll_background};
          }
          *:not(body)::-webkit-scrollbar:hover {
            background-color: ${darken(colors.scroll_background, 0.1)};
          }
          *:not(body)::-webkit-scrollbar-track {
            border-radius: ${scrollWidth}px;
            background-color: ${colors.scroll_background};
          }
          *:not(body)::-webkit-scrollbar-thumb {
            border-radius: ${scrollWidth}px;
            background-color: ${colors.scroll_main};
          }
          *:not(body)::-webkit-scrollbar-thumb:hover {
            background-color: ${darken(colors.scroll_main, 0.1)};
          }

          @font-face {
            font-family: "ArialNarrow";
            src: local("ArialNarrow"), url("${
              fonts.arialnarrow
            }") format("truetype");
            font-weight: normal;
            font-style: normal;
          }
          @font-face {
            font-family: "ArialNarrow";
            src: local("ArialNarrow"), url("${
              fonts.arialnarrowitalic
            }") format("truetype");
            font-weight: normal;
            font-style: italic;
          }
          @font-face {
            font-family: "ArialNarrow";
            src: local("ArialNarrow"), url("${
              fonts.arialnarrowbold
            }") format("truetype");
            font-weight: bold;
            font-style: normal;
          }
          @font-face {
            font-family: "ArialNarrow";
            src: local("ArialNarrow"), url("${
              fonts.arialnarrowitalicbold
            }") format("truetype");
            font-weight: bold;
            font-style: italic;
          }
        `,
      },
    },
  });
  return theme;
};

const themes = {
  light: buildTheme(),
};

if (dev) {
  window.d4pv = {
    ...window.d4pv,
    themes,
  };
}

export { makeShadow, pxToRem };

export default themes;
