import { useWatch, useFormContext } from 'react-hook-form';

import { UtilityField } from 'views';

const UtilitySearch = (props) => {
  const { name } = props;
  const { control } = useFormContext();
  const address = useWatch({ control, name: 'address' });
  const state = address?.state;

  return (
    <UtilityField.Control
      name={name}
      label="Utility"
      state={state}
      forcePopupIcon={false}
      placeholder="Type to search utility"
    />
  );
};

export default UtilitySearch;
