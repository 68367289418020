import { useCallback, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { MIME } from 'constants';
import {
  Modal,
  Dropzone,
  Ref,
  TextField,
  TinyButton,
  Icon,
  Button,
} from 'components';

const AddDatasheetModal = (props) => {
  const { open, onClose, onSubmit, replace } = props;

  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    const [f] = files;

    if (f) {
      setTitle(f.name);
    }
  }, [files]);

  useEffect(() => {
    if (open) {
      setFiles([]);
      setTitle('');
    }
  }, [open]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      title,
      file: files[0],
    });
  }, [files, onSubmit, title]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={replace ? 'Replace custom datasheet' : 'Add custom datasheet'}
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            disabled={files.length === 0 || title.length < 3}
          >
            {replace ? 'Replace' : 'Add'}
          </Button>
        </>
      }
    >
      {files.length === 0 && (
        <Dropzone
          fullWidth
          variant="box"
          accept={[MIME.PDF]}
          value={files}
          onChange={setFiles}
          uploadTrigger={
            <Box px={5} textAlign="center">
              <Typography variant="body3" fontWeight={400}>
                <span>Drag and drop or</span>
                <span> </span>
                <Ref color="secondary.main" fontWeight={700}>
                  Click here
                </Ref>
                <span> </span>
                <span>to upload Datasheet</span>
              </Typography>
            </Box>
          }
        />
      )}

      {files.length > 0 && (
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField fullWidth label="Title" value={title} onValue={setTitle} />

          <TinyButton
            variant="transparent"
            shape="circle"
            color="error"
            onClick={() => setFiles([])}
          >
            <Icon.Trash />
          </TinyButton>
        </Stack>
      )}
    </Modal>
  );
};

export default AddDatasheetModal;
