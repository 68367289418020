import { phone_mask } from 'constants';
import { getArray, getString } from './helpers';
import {
  validateEmail,
  validatePasswordLength,
  validatePasswordNumbers,
  validatePasswordRegister,
} from './validators';

export const required = 'This field is required!';

export const email = (v) => {
  return !!validateEmail(v) || 'Email is invalid';
};

export const password = (v) => {
  const isValidLength = validatePasswordLength(v);
  const isValidNums = validatePasswordNumbers(v);
  const isValidRegister = validatePasswordRegister(v);

  if (!isValidLength) {
    return 'Password must be at least 8 characters';
  }
  if (!isValidNums) {
    return 'Password must contain at least 2 numbers';
  }
  if (!isValidRegister) {
    return 'Password must contain both lower and uppercase characters';
  }
  return true;
};
const numberPower = phone_mask.match(/\d/gim).length;

export const phone = (v) => {
  const length = getArray(getString(v).match(/\d/gim)).length;
  return numberPower === length || length === 0 || 'Wrong phone number!';
};
