import { Group, Path } from 'paper';

import PaperElement from 'components/Paper/PaperElement';

const defaultArrowSize = 15;
const defaultArrowAngle = 15;
const defaultArrowColor = 'black';

class PaperArrow extends PaperElement {
  constructor(options = {}) {
    const element = new Group({ name: 'arrow_grou' });
    super({ element, ...options });
    this.update(options);
  }

  update(options = this.options) {
    this.options = { ...this.options, ...options };
    this.element.removeChildren();

    const size = options.size || defaultArrowSize;
    const angle = options.angle || defaultArrowAngle;
    const color = options.color || defaultArrowColor;

    const start = this.point(options.from);
    const end = this.point(options.to);
    const vector = start.subtract(end);
    const head = vector.normalize(size);

    const p1 = end;
    const p2 = p1.add(head.rotate(+angle));
    const p3 = p1.add(head.rotate(-angle));

    const arrow = new Path({
      segments: [p1, p2, p3],
      closed: true,
      fillColor: color,
    });
    this.element.addChild(arrow);
  }
}

export default PaperArrow;
