import { useMemo } from 'react';

import {
  useGetContractorCompanyDetailsQuery,
  useSearchCountyRequirementByGroupQuery,
  useSearchUtilityRequirementByGroupQuery,
  useSearchUtilityNotesByGroupQuery,
  useSearchCountyNotesByGroupQuery,
  useGetProjectDetailsQuery,
} from 'store';

import { getArray } from 'utils';
import { NOTES_LEGACY } from 'constants';

const getNotesResult = (a, b) => {
  if (!a || !b) {
    return [];
  }
  const ids = [];
  const result = [...a, ...b];

  return result.filter((item) => {
    if (!ids.includes(item.id)) {
      ids.push(item.id);
      return true;
    }
    return false;
  });
};

const useGetProjectNotes = (props) => {
  const { project_id, group } = props;
  const legacy_group = NOTES_LEGACY.toLegacy(group) || group;

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const { data: company, isFetching: companyLoading } =
    useGetContractorCompanyDetailsQuery(
      { company_id: project?.contractor?.id },
      { skip: !project?.contractor?.id }
    );

  const { data: countyNotes, isFetching: countyNotesLoading } =
    useSearchCountyNotesByGroupQuery(
      {
        county_id: project?.county?.id,
        params: { group },
      },
      { skip: !project?.county?.id }
    );

  const { data: countyRequirements, isFetching: countyRequirementsLoading } =
    useSearchCountyRequirementByGroupQuery(
      {
        county_id: project?.county?.id,
        params: { group },
      },
      { skip: !project?.county?.id }
    );

  const { data: utilityNotes, isFetching: utilityNotesLoading } =
    useSearchUtilityNotesByGroupQuery(
      {
        utility_id: project?.utility?.id,
        params: { group },
      },
      { skip: !project?.utility?.id }
    );

  const { data: utilityRequirements, isFetching: utilityRequirementsLoading } =
    useSearchUtilityRequirementByGroupQuery(
      {
        utility_id: project?.utility?.id,
        params: { group },
      },
      { skip: !project?.utility?.id }
    );

  // LEGACY
  const { data: countyNotesLegacy, isFetching: countyNotesLoadingLegacy } =
    useSearchCountyNotesByGroupQuery(
      {
        county_id: project?.county?.id,
        params: {
          group: legacy_group,
        },
      },
      { skip: !project?.county?.id }
    );

  const {
    data: countyRequirementsLegacy,
    isFetching: countyRequirementsLoadingLegacy,
  } = useSearchCountyRequirementByGroupQuery(
    {
      group: legacy_group,
      county_id: project?.county?.id,
    },
    { skip: !project?.county?.id }
  );

  const { data: utilityNotesLegacy, isFetching: utilityNotesLoadingLegacy } =
    useSearchUtilityNotesByGroupQuery(
      {
        utility_id: project?.utility?.id,
        params: {
          group: legacy_group,
        },
      },
      { skip: !project?.utility?.id }
    );

  const {
    data: utilityRequirementsLegacy,
    isFetching: utilityRequirementsLoadingLegacy,
  } = useSearchUtilityRequirementByGroupQuery(
    {
      utility_id: project?.utility?.id,
      params: {
        group: legacy_group,
      },
    },
    { skip: !project?.utility?.id }
  );

  const loading = [
    isFetching,
    countyNotesLoading,
    countyRequirementsLoading,
    utilityNotesLoading,
    utilityRequirementsLoading,
    companyLoading,
    countyNotesLoadingLegacy,
    countyRequirementsLoadingLegacy,
    utilityNotesLoadingLegacy,
    utilityRequirementsLoadingLegacy,
  ].some(Boolean);

  const notes = useMemo(
    () => ({
      county: getNotesResult(countyNotes, countyNotesLegacy),
      utility: getNotesResult(utilityNotes, utilityNotesLegacy),
      company: getArray(company?.notes)
        .filter((n) => [group, legacy_group].includes(n.group))
        .filter((n) => n.visibility),
    }),
    [
      countyNotes,
      utilityNotes,
      company,
      group,
      countyNotesLegacy,
      utilityNotesLegacy,
      legacy_group,
    ]
  );

  const requirements = useMemo(
    () => ({
      county: getNotesResult(countyRequirements, countyRequirementsLegacy),
      utility: getNotesResult(utilityRequirements, utilityRequirementsLegacy),
      company: getArray(company?.requirements)
        .filter((r) => [group, legacy_group].includes(r.group))
        .filter((r) => r.visibility),
    }),
    [
      countyRequirements,
      utilityRequirements,
      company,
      group,
      legacy_group,
      countyRequirementsLegacy,
      utilityRequirementsLegacy,
    ]
  );

  return useMemo(
    () => [{ notes, requirements }, loading],
    [notes, requirements, loading]
  );
};

export default useGetProjectNotes;
