import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { valuesToOptions } from 'utils';
import { BUS_RATING } from 'constants';
import { Tabs } from 'components';

const tabs = valuesToOptions(['generic', 'vendor']);

const AcTypeTabs = () => {
  const { resetField } = useFormContext();

  const handleTabChange = useCallback(
    (e, v) => {
      resetField('subpanel_id', {
        defaultValue: '',
      });
      resetField('subpanel_data.bus_rating', {
        defaultValue: v === 'vendor' ? '' : BUS_RATING.A60,
      });
      resetField('subpanel_data.breaker_rating', {
        defaultValue: '',
      });
    },
    [resetField]
  );

  return (
    <Tabs.Control
      uppercase
      size="small"
      options={tabs}
      name="subpanel_panel_type"
      defaultValue="generic"
      onChange={handleTabChange}
    />
  );
};

export default AcTypeTabs;
