import { ADDRESS_TYPE } from 'constants';
import { getArray, isObject } from 'utils/helpers';

const { ADDRESS, PREDICTION, PLACE } = ADDRESS_TYPE;

const addressFields = [
  'address_line1',
  'address_line2',
  'city',
  'state',
  'zip_code',
];

const composeAddress = (...args) => {
  return getArray(args)
    .filter((item) => !!item)
    .join(' ');
};

export const getAddressType = (address) => {
  if (!isObject(address)) {
    return;
  }
  if (address?.place_id && typeof address?.description === 'string') {
    return PREDICTION;
  }
  if (address?.address_components) {
    return PLACE;
  }
  const keys = Object.keys(address);

  if (addressFields.every((field) => keys.includes(field))) {
    return ADDRESS;
  }
};

export const placeToAddress = (place) => {
  const data = getArray(place?.address_components);
  const [route] = data.filter((c) => c.types.includes('route'));
  const [city] = data.filter((c) => c.types.includes('locality'));
  const [street] = data.filter((c) => c.types.includes('street_number'));
  const [postalCode] = data.filter((c) => c.types.includes('postal_code'));
  const [adminArea1] = data.filter((c) =>
    c.types.includes('administrative_area_level_1')
  );
  const [adminArea2] = data.filter((c) =>
    c.types.includes('administrative_area_level_2')
  );

  return {
    address_line1: composeAddress(street?.long_name, route?.long_name),
    address_line2: composeAddress(adminArea2?.long_name),
    city: composeAddress(city?.long_name),
    state: composeAddress(adminArea1?.short_name),
    zip_code: composeAddress(postalCode?.long_name),
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    // placeId: place.place_id
  };
};

export const getAddressLabel = (address) => {
  if (!address) {
    return '';
  }
  return [
    address.address_line1,
    address.address_line2,
    address.city,
    address.state,
    address.zip_code,
  ]
    .filter(Boolean)
    .join(', ');
};
