import { Shape } from 'paper';

import PaperMouseEvents from 'components/Paper/PaperMouseEvents';

class PaperDropzone extends PaperMouseEvents {
  constructor(options) {
    const { x, y, width, height, ...opts } = options;

    const element = new Shape.Rectangle({
      point: [x, y],
      size: [width, height],
      fillColor: opts.theme.palette.success.light,
      opacity: 0.05,
      visible: false,
    });
    super({ element, ...opts });

    this.drop_point = null;

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);

    element.on('mouseenter', this.onMouseEnter);
    element.on('mouseleave', this.onMouseLeave);
    element.on('mousemove', this.onMouseMove);
  }

  onMouseEnter() {
    this.toggleHighlight(true);
  }

  onMouseLeave() {
    this.toggleHighlight(false);
    this.drop_point = null;
  }

  onMouseMove(e) {
    this.drop_point = this.element.visible
      ? // ? this.element.localToGlobal(e.point)
        e.point
      : null;
  }

  enable() {
    this.element.visible = true;
  }

  disable() {
    this.element.visible = false;
    this.drop_point = null;
  }

  toggleHighlight(turn) {
    this.element.opacity = turn ? 0.2 : 0.05;
  }
}

export default PaperDropzone;
