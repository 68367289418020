import { lazy, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { dev } from 'constants';
import { useWatchField } from 'hooks';
import { PasswordField } from 'components';

const MatchPasswords = (props) => {
  const { name = 'password', initialFocus, label } = props;

  const password = useWatchField(name);
  const { trigger } = useFormContext();

  const triggerMatchPasswordField = useCallback(() => {
    trigger('password_repeat');
  }, [trigger]);

  return (
    <>
      <PasswordField.Control
        required
        name={name}
        type="password"
        hideAsterisk={!label}
        autoComplete="new-password"
        initialFocus={initialFocus}
        placeholder="Create your password"
        label={
          label || (
            <Box display="flex" justifyContent="space-between">
              <span>Password *</span>
              <span>(Password must be at least 8 characters)</span>
            </Box>
          )
        }
        rules={{
          deps: ['password_repeat'],
        }}
      />

      <PasswordField.Control
        type="password"
        label="Repeat Password"
        autoComplete="off"
        name="password_repeat"
        placeholder="Repeat your password"
        onChange={triggerMatchPasswordField}
        rules={{
          validate: {
            matchPassword: (v) => {
              return v === password || 'Passwords do not match';
            },
          },
        }}
      />
    </>
  );
};

if (dev) {
  MatchPasswords.Demo = lazy(() => import('./MatchPasswords.demo'));
}

export default MatchPasswords;
