import { forwardRef, lazy, useCallback } from 'react';

import { dev } from 'constants';
import { useDeincrement, withProps } from 'hooks';
import { fromRange, getFunc } from 'utils';
import {
  Center,
  FormControl,
  FormLabel,
  HelperText,
  Icon,
  Input,
  TextField,
  TinyButton as _TinyButton,
} from 'components';

const TinyButton = withProps(_TinyButton, {
  variant: 'flat',
});

const DeincrementField = forwardRef((props, ref) => {
  const {
    min = -Infinity,
    max = Infinity,
    step = 1,
    fullWidth = true,
    onChange,
    onValue,
    disabled,
    label,
    error,
    value,
    required,
    helperText,
    hideAsterisk,
    reserveHelperTextSpace = true,
    InputLabelProps,
    InputProps,
    inputRef,
    initialFocus,
    ...rest
  } = props;

  const handleChange = useCallback(
    (e, v) => {
      e.preventDefault();
      getFunc(onChange)(null, v);
      getFunc(onValue)(v);
    },
    [onChange, onValue]
  );

  const handleInputChange = useCallback(
    (e) => {
      const v = fromRange(+e.target.value, min, max);
      getFunc(onChange)(e, v);
      getFunc(onValue)(v);
    },
    [max, min, onChange, onValue]
  );

  const [increase, decrease] = useDeincrement(value, handleChange, {
    min,
    max,
    step,
    passArgs: true,
  });

  return (
    <FormControl ref={ref} fullWidth={fullWidth} {...rest}>
      {label && (
        <FormLabel
          error={error}
          required={required}
          disabled={disabled}
          hideAsterisk={hideAsterisk}
          {...InputLabelProps}
        >
          {label}
        </FormLabel>
      )}

      <Center gap={1}>
        <TinyButton onClick={decrease} disabled={disabled || value === min}>
          <Icon.Minus sx={{ fontSize: 16 }} />
        </TinyButton>

        <Input
          fullWidth
          value={value}
          type="number"
          initialFocus={initialFocus}
          inputRef={inputRef}
          inputProps={{ min, max, step }}
          onChange={handleInputChange}
          {...InputProps}
        />

        <TinyButton onClick={increase} disabled={disabled || value === max}>
          <Icon.Plus sx={{ fontSize: 16 }} />
        </TinyButton>
      </Center>

      <HelperText
        error={error}
        disabled={disabled}
        reserveSpace={reserveHelperTextSpace}
      >
        {helperText}
      </HelperText>
    </FormControl>
  );
});

const defaultControlChangeHandler = (field) => (e, v) => {
  field.onChange(v);
};

DeincrementField.Control = forwardRef((props, ref) => {
  const {
    defaultValue = 0,
    ControlComponent = DeincrementField,
    controlChangeHandler = defaultControlChangeHandler,
    ...rest
  } = props;

  return (
    <TextField.Control
      ref={ref}
      defaultValue={defaultValue}
      ControlComponent={ControlComponent}
      controlChangeHandler={controlChangeHandler}
      {...rest}
    />
  );
});

if (dev) {
  DeincrementField.Demo = lazy(() => import('./DeincrementField.demo'));
}

export default DeincrementField;
