import { isValidDateObject } from 'utils/helpers';

export const dateToString = (d) => {
  const v = d instanceof Date ? d : new Date(d);

  if (!isValidDateObject(v)) {
    return '';
  }
  return v.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const NumberValueFormatter = function (options = {}) {
  const { decimalsMax: initDecimalsMax = 3 } = options;

  return (v, opts = {}) => {
    const { decimalsMax = initDecimalsMax } = opts;
    return numberValueFormatter(v, { decimalsMax });
  };
};

export const numberValueFormatter = (v, options = {}) => {
  const { decimalsMax = 3 } = options;
  const value = Number.parseFloat(v);

  if (!Number.isFinite(value)) {
    return null;
  }
  return value.toLocaleString('en-US', {
    maximumFractionDigits: decimalsMax,
  });
};
