import { useMemo } from 'react';

import {
  GEC_TYPES,
  PHASES_LIST,
  SERVICE_VOLTAGES_PHASE_1_LIST,
  SYSTEM_TYPES,
} from 'constants';

import { getArray } from 'utils';

import { createEmptySystem } from './SystemsForm';

const getStrings = (sys) => {
  if (sys.system_type === SYSTEM_TYPES.STRING) {
    return Object.values(sys.strings).map((_, i) => ({
      type: 'mppt',
      value: sys.strings[i + 1].map((v) => ({
        type: 'string',
        value: v,
      })),
    }));
  }
  return sys.strings.map((str) => ({
    type: 'string',
    value: str,
  }));
};

const useRefillForm = (project) => {
  return useMemo(() => {
    const projectSystems = getArray(project?.systems);
    const systems =
      projectSystems.length === 0 ? [createEmptySystem()] : projectSystems;
    const [firstSystem] = systems;

    return {
      gec: firstSystem?.gec || GEC_TYPES.COMBINED,
      phase: String(firstSystem?.phase || PHASES_LIST[0].value),
      service_voltage:
        firstSystem?.service_voltage || SERVICE_VOLTAGES_PHASE_1_LIST[0].value,
      systems: systems.map((sys) => ({
        ...createEmptySystem(),
        id: sys.id,
        strings: getStrings(sys),
        rsd_id: sys.rsd_id || '',
        wte_id: sys.wte_id || '',
        system_type: sys.system_type,
        panel_id: sys.panel_id || '',
        system_number: sys.system_number,
        inverter_id: sys.inverter_id || '',
        optimizer_id: sys.optimizer_id || '',
      })),
    };
  }, [project]);
};

export default useRefillForm;
