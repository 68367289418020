import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, useMatch, useParams } from 'react-router-dom';

import { useGetProfileQuery } from 'store';
import { ACCESS, PLANS } from 'constants';
import { getFunc } from 'utils';
import { useAuth } from 'hooks';
import { useHandleQueryError } from 'components';
import { routes } from 'routes';

const { ANY, GUEST, USER } = ACCESS;

const ProtectedOutlet = (props) => {
  const {
    id,
    role,
    debug,
    element,
    pending,
    redirect,
    fallback,
    fullPath,
    access = ANY,
    plan = PLANS.ANY,
    path: pathBuilder,
  } = props;

  const path = getFunc(pathBuilder)();
  const params = useParams();
  const match = useMatch({
    path: fullPath || '',
    exact: true,
  });
  if (debug) {
    console.log('ROUTE >>>', id, fullPath || path);
  }
  const {
    isAuthorized,
    isLoggedIn,
    logout,
    authorize,
    premium,
    role: userRole,
    progress,
  } = useAuth();

  const { data, error, isLoading, isSuccess, ...authState } =
    useGetProfileQuery(undefined, {
      skip: !isLoggedIn,
    });
  useHandleQueryError({ error, ...authState });

  const userAccess = useMemo(() => {
    if (isAuthorized) {
      return USER;
    }
    return progress ? ANY : GUEST;
  }, [isAuthorized, progress]);

  const premiumStatus = useMemo(() => {
    if (isAuthorized && premium) {
      return PLANS.PREMIUM;
    }
    return progress ? PLANS.ANY : PLANS.FREE;
  }, [isAuthorized, premium, progress]);

  const hasAccess = !access || access === userAccess || access === ANY;
  const hasRole = !role || userRole === role;
  const hasPlan = !plan || premiumStatus === plan;

  const fallbackRoute = useMemo(
    () => getFunc(fallback)(params) || routes.root.path(),
    [fallback, params]
  );
  useEffect(() => {
    if (isLoggedIn && error?.status === 401) {
      logout();
    }
  }, [isLoggedIn, error, logout]);

  useEffect(() => {
    if (isLoggedIn && !isAuthorized && data && isSuccess) {
      authorize({ user: data });
    }
  }, [data, isAuthorized, isLoggedIn, isSuccess, authorize]);

  const waitForAuth = progress && access !== ANY;

  if (typeof redirect === 'function' && !!match) {
    if (debug) {
      console.log('>>> route redirect', redirect);
      console.log('');
    }
    return <Navigate to={redirect()} replace />;
  }
  if (waitForAuth) {
    if (debug) {
      console.log('>>> waiting for auth', pending);
      console.log('');
    }
    return pending || null;
  }
  if (!hasAccess || !hasRole || !hasPlan) {
    if (debug) {
      console.log('>>> no access, redirect', fallbackRoute);
      console.log('');
    }
    return <Navigate to={fallbackRoute} replace />;
  }
  if (debug) {
    console.log('>>> render element', props);
    console.log('');
  }
  return element || <Outlet />;
};

export default ProtectedOutlet;
