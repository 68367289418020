import { useCallback } from 'react';
import { Box, Typography } from '@mui/material';

import { getFunc } from 'utils';
import { withProps } from 'hooks';
import { useDeleteProjectMutation } from 'store';
import {
  Button as OriginalButton,
  Modal,
  ProgressBox,
  useMessage,
} from 'components';

const Button = withProps(OriginalButton, {
  fullWidth: false,
  minWidth: 165,
});

const DeleteProjectModal = (props) => {
  const { open, project, onClose, ...rest } = props;

  const m = useMessage();
  const [deleteProject, { isLoading }] = useDeleteProjectMutation();
  const { id: project_id } = project;

  const handleDelete = useCallback(async () => {
    try {
      await deleteProject({ project_id }).unwrap();
      m.success('Project has been deleted!');
      getFunc(onClose)();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [m, project_id, deleteProject, onClose]);

  return (
    <Modal open={open} onClose={onClose} title="Delete Project" {...rest}>
      <ProgressBox progress={isLoading}>
        <Box display="flex" justifyContent="center" mt={1}>
          <Typography variant="body2">
            Are you sure you want to delete "{project.title}" project?
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" gap={2} mt={6} mb={1}>
          <Button variant="outlined" onClick={onClose}>
            No, Cancel
          </Button>

          <Button onClick={handleDelete}>Yes, Delete</Button>
        </Box>
      </ProgressBox>
    </Modal>
  );
};

export default DeleteProjectModal;
