import { Group, Shape } from 'paper';

import {
  diagram_width,
  diagram_height,
  HOUSE_PLACARDS_WIDTH,
  HOUSE_PLACARDS_MARGIN_X,
  HOUSE_PLACARDS_MARGIN_Y,
} from 'constants';

import { getArray, getFunc, getString } from 'utils';
import { Paper } from 'components';

const padding = 30;
const edit_size = diagram_height - 60;
const preview_size = HOUSE_PLACARDS_WIDTH;

const {
  PaperView,
  PaperElement,
  PaperReference,
  PaperDropzone,
  PaperLayers,
  PaperText,
  PaperTextArea,
  PaperCompass,
} = Paper;

class LayersView extends PaperView {
  constructor(options) {
    const { ...opts } = options;
    super(opts);

    this.layers = new PaperLayers(opts);

    this.children.content.addChildren([this.layers.element]);
    this.layers.element.bounds.center = this.children.view_rect.bounds.center;
  }

  addLayers(layers, callback) {
    const afterUpload = (item) => {
      this.layers.fit(this.children.view_rect, 'contain');
      callback(item);
    };
    this.layers.addLayers(layers, afterUpload);
  }
}

class EditView extends PaperView {
  constructor(options) {
    super({
      ...options,
      width: edit_size,
      height: edit_size,
    });
    const center = this.point(diagram_width / 2, diagram_height / 2);
    this.element.bounds.center = center;
    this.element.bounds.x += 0.5;
    this.element.bounds.y += 0.5;

    this.items = {};

    this.rect = new Shape.Rectangle({
      size: [edit_size, edit_size],
      strokeWidth: 1,
      strokeColor: 'black',
    });
    this.layers_view = new LayersView({
      ...options,
      width: edit_size,
      height: edit_size,
    });
    this.compass = new PaperCompass(options);
    this.compass.element.scale(3);
    this.compass.element.bounds.bottomLeft = this.rect.bounds.bottomLeft;
    this.compass.element.bounds.x += 20;
    this.compass.element.bounds.y -= 20;

    this.dropzone = new PaperDropzone({
      ...options,
      x: 0,
      y: 0,
      width: edit_size,
      height: edit_size,
    });
    this.children.content.addChildren([
      this.layers_view.element,
      this.dropzone.element,
      this.rect,
      this.compass.element,
    ]);
  }

  addReference(ref) {
    if (this.items[ref.id]) {
      return;
    }
    const { onSelect, sticking, ...reference } = ref;

    const refInstance = new PaperReference({
      ...this.options,
      parent: this.children.content,
      sticking,
      stickTo: this.layers_view.element,
      onSelect,
      id: ref.id,
      data: {
        ...reference,
      },
    });
    this.items[ref.id] = refInstance;
    this.children.content.addChild(refInstance.element);
    refInstance.select({ new: true });
  }

  removeItem(id) {
    const instance = this.items[id];
    delete this.items[id];

    if (instance) {
      instance.destroy();
    }
  }
}

class PreviewView extends PaperView {
  constructor(options) {
    super({
      ...options,
      width: preview_size,
      height: preview_size,
    });
    this.message = 'Test';

    const center = this.point(
      diagram_width - HOUSE_PLACARDS_MARGIN_X - preview_size / 2,
      HOUSE_PLACARDS_MARGIN_Y + preview_size / 2
    );
    this.element.bounds.center = center;
    this.element.bounds.x += 0.5;
    this.element.bounds.y += 0.5;

    this.container = new Group({ name: 'preview_container' });

    this.rect = new Shape.Rectangle({
      size: [preview_size, preview_size],
      strokeWidth: 1,
      strokeColor: 'black',
    });
    this.title = new PaperText({
      fontSize: 36,
      content: '!CAUTION!',
    });
    this.title.bounds.topCenter = this.rect.bounds.topCenter;
    this.title.bounds.y += 15;

    this.text = new PaperTextArea({
      ...options,
      data: {
        fontSize: 20,
        align: 'center',
        width: preview_size - 30,
        content: this.message.toUpperCase(),
      },
    });
    this.updateMessage();

    this.children.content.addChildren([
      this.container,
      this.rect,
      this.title,
      this.text.element,
    ]);
  }

  updateMessage(text = this.message) {
    this.message = getString(text).toUpperCase();

    this.text.update({
      content: this.message,
    });
    this.text.element.bounds.topCenter = this.title.bounds.bottomCenter;
    this.text.element.y += 10;
  }
}

class PlacardsView extends PaperView {
  constructor(options) {
    const rect_width =
      diagram_width - padding - preview_size - HOUSE_PLACARDS_MARGIN_X * 2;
    const rect_height = diagram_height - padding * 2;

    super({
      ...options,
      width: rect_width,
      height: rect_height,
    });
    this.element.bounds.topLeft.x = padding;
    this.element.bounds.topLeft.y = padding;
    this.layers = new PaperLayers(options);

    this.children.content.addChildren([this.layers.element]);
    this.layers.element.bounds.center = this.children.view_rect.bounds.center;
  }

  addLayers(layers, callback) {
    const afterUpload = (item) => {
      this.layers.fit(this.children.view_rect);
      callback(item);
    };
    this.layers.addLayers(layers, afterUpload);
  }
}

class ElevationView extends PaperView {
  constructor(options) {
    super({
      ...options,
      width: preview_size,
      height: preview_size,
    });
    this.image = null;

    const center = this.point(
      diagram_width - HOUSE_PLACARDS_MARGIN_X - preview_size / 2,
      diagram_height - HOUSE_PLACARDS_MARGIN_Y - preview_size / 2
    );
    this.element.bounds.center = center;
    this.element.bounds.x += 0.5;
    this.element.bounds.y += 0.5;

    this.preview = new PaperLayers({
      ...options,
      width: preview_size,
      height: preview_size,
    });
    this.preview.element.bounds.center = center;

    this.rect = new Shape.Rectangle({
      size: [preview_size, preview_size],
      strokeWidth: 1,
      strokeColor: 'black',
    });
    this.children.content.addChildren([this.preview.element, this.rect]);
  }

  addElevationImage(image, cb) {
    this.image = image;

    const afterUpload = (item) => {
      this.preview.fit(this.children.view_rect);
      getFunc(cb)(item);
    };
    this.preview.addLayers([image], afterUpload);
  }

  removeElevationImage() {
    this.image = null;
    this.preview.clearLayers();
  }

  show() {
    if (!this.image) {
      return;
    }
    super.show();
  }

  hide() {
    super.hide();
  }
}

class PlacardsController extends PaperElement {
  constructor(options) {
    const { premium, ...opts } = options;
    const element = new Group({ name: 'main' });
    super({ element, ...opts });

    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.commitData = {};

    this.callbacks = {};
    this.updateNorthAngle = this.updateNorthAngle.bind(this);
    this.onCanvasMouseUp = this.onCanvasMouseUp.bind(this);
    this.scope.project.activeLayer.addChild(element);

    this.sticking = false;
    this.angleAlignment = false;

    this.edit_view = new EditView({ ...opts });
    this.preview = new PreviewView({ ...opts });
    this.placards = new PlacardsView({ ...opts });
    this.elevation = new ElevationView({ ...opts });
    this.elevation.hide();
    this.elevation_visible = false;

    this.edit_view.hide();

    if (!premium) {
      this.preview.hide();
    }
    element.addChildren([
      this.edit_view.element,
      this.preview.element,
      this.placards.element,
      this.elevation.element,
    ]);
    this.scope.view.on('mouseup', this.onCanvasMouseUp);
  }

  setCautionText(text) {
    this.preview.updateMessage(getString(text).toUpperCase());
  }

  setElevationImage(image) {
    this.elevation.addElevationImage(image);
  }

  removeElevationImage() {
    this.elevation.removeElevationImage();
  }

  commitEdit() {
    const { layers, scale } = this.edit_view.layers_view;
    const layersPosition = layers.element.position;
    const angle = layers.element.rotation;

    this.commitData = {
      scale,
      layers: {
        angle,
        position: {
          x: layersPosition.x,
          y: layersPosition.y,
        },
      },
      elevation: {
        image: this.elevation.image,
      },
      references: this.getItems().map((item) => ({
        id: item.data.id,
        type: item.data.type,
        label: item.data.label,
        custom: !!item.data.custom,
        position: item.data.position,
        segments: item.data.segments,
        x: item.data.x,
        y: item.data.y,
        placed: true,
        value: item.data.id,
      })),
    };
    this.unselectItem();
    const editVisibility = this.edit_view.element.visible;
    this.edit_view.show();
    this.edit_view.rect.visible = false;

    const clone = this.edit_view.element.clone({
      insert: false,
      deep: true,
    });
    this.edit_view.rect.visible = true;
    this.preview.container.removeChildren();
    this.preview.container.addChild(clone);
    this.preview.container.bounds.center = this.preview.rect.bounds.center;
    this.preview.container.fitBounds(this.preview.rect.bounds);
    this.edit_view.element.visible = editVisibility;
  }

  discardEdit() {
    const { layers } = this.edit_view.layers_view;
    const d = this.commitData;

    layers.element.position = this.point(d?.layers?.position);
    layers.element.rotation = d?.layers?.angle;
    this.edit_view.layers_view.setScale(d.scale);
    this.edit_view.compass.element.rotation = d?.layers?.angle;

    this.clearItems();

    d.references.forEach((item) => {
      this.addReference(item);
    });
  }

  getCommitData() {
    return { ...this.commitData };
  }

  addPlacard(placard, callback) {
    this.placards.addLayers([placard], callback);
  }

  showHousePlacard() {
    this.preview.show();
  }

  hideHousePlacard() {
    this.preview.hide();
  }

  showElevation() {
    this.elevation_visible = true;
    this.elevation.show();
  }

  hideElevation() {
    this.elevation_visible = false;
    this.elevation.hide();
  }

  setHousePlacardsMode(mode) {
    switch (mode) {
      case 'preview': {
        this.edit_view.hide();
        this.preview.show();
        this.placards.show();

        if (this.elevation_visible) {
          this.elevation.show();
        }
        break;
      }
      case 'edit':
      default: {
        this.preview.hide();
        this.edit_view.show();
        this.placards.hide();
        this.elevation.hide();
        break;
      }
    }
  }

  updateNorthAngle(v) {
    this.edit_view.layers_view.layers.element.rotation = v;
    this.edit_view.compass.element.rotation = v;
  }

  setLayers(layers, callback) {
    this.edit_view.layers_view.addLayers(layers, callback);
  }

  setLayersScale(scale) {
    this.edit_view.layers_view.setScale(scale);
  }

  setLayersPosition(p) {
    this.edit_view.layers_view.layers.element.position = this.point(p);
  }

  togglePanTool(v) {
    this.edit_view.layers_view.layers.toggleDragging(v);
  }

  enableDropzone() {
    this.edit_view.dropzone.enable();
  }

  disableDropzone() {
    this.edit_view.dropzone.disable();
  }

  getDropPoint() {
    return this.edit_view.dropzone.drop_point;
  }

  addReference(ref) {
    this.edit_view.addReference({
      ...ref,
      sticking: this.sticking,
      angleAlignment: this.angleAlignment,
      onSelect: this.handleItemSelect,
    });
  }

  getItem(id) {
    return this.edit_view.items[id];
  }

  getItems() {
    return Object.values(this.edit_view.items);
  }

  clearItems() {
    const ids = Object.keys(this.edit_view.items);

    ids.forEach((id) => {
      this.removeItem(id);
    });
  }

  removeItem(id) {
    this.edit_view.removeItem(id);
  }

  selectItem(id) {
    this.edit_view.items[id]?.select();
  }

  unselectItem(id) {
    if (id) {
      this.edit_view.items[id]?.unselect();
      return;
    }
    Object.values(this.edit_view.items).forEach((item) => {
      item.unselect();
    });
  }

  enableSticking() {
    this.sticking = true;

    Object.values(this.edit_view.items).forEach((item) => {
      item.enableSticking();
    });
  }

  disableSticking() {
    this.sticking = false;

    Object.values(this.edit_view.items).forEach((item) => {
      item.disableSticking();
    });
  }

  enableAngleAlignment() {
    this.angleAlignment = true;

    Object.values(this.edit_view.items).forEach((item) => {
      item.enableAngleAlignment();
    });
  }

  disableAngleAlignment() {
    this.angleAlignment = false;

    Object.values(this.edit_view.items).forEach((item) => {
      item.disableAngleAlignment();
    });
  }

  handleItemSelect(id) {
    if (id) {
      Object.entries(this.edit_view.items).forEach(([item_id, instance]) => {
        if (item_id !== id) {
          instance.unselect({ silent: true });
        }
      });
    }
    getArray(this.callbacks.select).forEach((cb) => {
      getFunc(cb)(id);
    });
  }

  onCanvasMouseUp() {
    getArray(this.callbacks.change).forEach((cb) => {
      cb();
    });
  }

  addListener(event, callback) {
    const listeners = this.callbacks[event] || [];
    listeners.push(callback);
    this.callbacks[event] = listeners;
  }

  removeListener(event, callback) {
    if (!callback) {
      delete this.callbacks[event];
      return;
    }
    this.callbacks[event] = getArray(this.callbacks[event]).filter(
      (cb) => cb !== callback
    );
  }

  disableEdit() {
    this.getItems().forEach((item) => {
      item.disableEdit();
    });
  }

  enableEdit() {
    this.getItems().forEach((item) => {
      item.enableEdit();
    });
  }

  getData() {
    return {
      ...this.commitData,
      elevation: {
        image: this.elevation.image,
      },
    };
  }

  destroy() {
    if (this.scope?.project?.activeLayer) {
      this.scope.project.activeLayer.removeChildren();
    }
  }
}

export default PlacardsController;
