const SITE_PLAN = 'SITE_PLAN';
const ELECTRICAL_PLAN = 'ELECTRICAL_PLAN';
const ATTACHMENT_PLAN = 'ATTACHMENT_PLAN';
const LINE_DIAGRAM = 'LINE_DIAGRAM';
const ASSEMBLY_DETAILS = 'ASSEMBLY_DETAILS';

export const NOTES_GROUPS = {
  SITE_PLAN,
  ELECTRICAL_PLAN,
  ATTACHMENT_PLAN,
  LINE_DIAGRAM,
  ASSEMBLY_DETAILS,
};
export const NOTES_GROUPS_NAMES = {
  [NOTES_GROUPS.SITE_PLAN]: 'Site Plan',
  [NOTES_GROUPS.ELECTRICAL_PLAN]: 'Electrical Plan',
  [NOTES_GROUPS.ATTACHMENT_PLAN]: 'Solar Attachment Plan',
  [NOTES_GROUPS.LINE_DIAGRAM]: 'Line Diagram',
  [NOTES_GROUPS.ASSEMBLY_DETAILS]: 'Assembly Details',
};
export const NOTES_GROUPS_LIST = [
  SITE_PLAN,
  ELECTRICAL_PLAN,
  ATTACHMENT_PLAN,
  LINE_DIAGRAM,
  ASSEMBLY_DETAILS,
].map((value) => ({
  value,
  label: NOTES_GROUPS_NAMES[value],
}));

export const NOTES_TO_LEGACY = {
  [NOTES_GROUPS.SITE_PLAN]: 'SitePlan',
  [NOTES_GROUPS.ELECTRICAL_PLAN]: 'ElectricalPlan',
  [NOTES_GROUPS.ATTACHMENT_PLAN]: 'AttachmentPlan',
  [NOTES_GROUPS.LINE_DIAGRAM]: 'LineDiagram',
  [NOTES_GROUPS.ASSEMBLY_DETAILS]: 'AssemblyDetails',
};
export const NOTES_FROM_LEGACY = {
  SitePlan: NOTES_GROUPS.SITE_PLAN,
  ElectricalPlan: NOTES_GROUPS.ELECTRICAL_PLAN,
  AttachmentPlan: NOTES_GROUPS.ATTACHMENT_PLAN,
  LineDiagram: NOTES_GROUPS.LINE_DIAGRAM,
  AssemblyDetails: NOTES_GROUPS.ASSEMBLY_DETAILS,
};

export const NOTES_LEGACY = {
  toLegacy: (v) => NOTES_TO_LEGACY[v],
  fromLegacy: (v) => NOTES_FROM_LEGACY[v],
};
