import { useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import { useGetContractorCompanyDetailsQuery } from 'store';
import { AddressField } from 'views';

const CompanyAddress = () => {
  const { control, resetField } = useFormContext();
  const company_id = useWatch({ control, name: 'contractor_id' });

  const { data: companyDetails, isFetching } =
    useGetContractorCompanyDetailsQuery(
      { company_id },
      {
        skip: !company_id || company_id === 'new',
      }
    );

  useEffect(() => {
    resetField('address', {
      defaultValue: companyDetails?.address || null,
    });
  }, [resetField, companyDetails]);

  return (
    <AddressField.Control
      manual
      required
      fullWidth
      name="address"
      coords={false}
      loading={isFetching}
      label="Search address *"
      placeholder="Search..."
      disabled={company_id !== 'new'}
    />
  );
};

export default CompanyAddress;
