import {
  forwardRef,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormHelperText,
  Collapse,
  styled,
  formHelperTextClasses as classes,
  Box,
} from '@mui/material';

import { dev } from 'constants';

const Root = styled(Box, {
  label: 'HelperText',
  shouldForwardProp: (prop) => !['reserveSpace'].includes(prop),
})(({ theme, reserveSpace, minHeight }) => ({
  minHeight: reserveSpace ? theme.spacing(2) : minHeight,
}));

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  [`&.${classes.root}`]: {
    ...theme.typography.formCaption,
    margin: theme.spacing(0),
    lineHeight: theme.spacing(2),
    [`&.${classes.disabled}`]: {
      opacity: 0.5,
    },
  },
}));

const HelperText = forwardRef((props, ref) => {
  const { children, reserveSpace, ...rest } = props;

  const [current, setCurrent] = useState(children);

  const content = useMemo(() => {
    return !current && children ? children : current;
  }, [current, children]);

  useEffect(() => {
    if (!current && children) {
      setCurrent(children);
    }
    if (current && children && current !== children) {
      setCurrent(children);
    }
  }, [children, current]);

  const handleExited = useCallback(() => {
    setCurrent(children);
  }, [children]);

  return (
    <Root ref={ref} reserveSpace={reserveSpace}>
      <Collapse onExited={handleExited} in={!!children} easing="linier">
        <StyledHelperText {...rest}>{content}</StyledHelperText>
      </Collapse>

      {reserveSpace && (
        <Collapse in={!children} easing="linier">
          <StyledHelperText {...rest}>&nbsp;</StyledHelperText>
        </Collapse>
      )}
    </Root>
  );
});

if (dev) {
  HelperText.Demo = lazy(() => import('./HelperText.demo'));
}

export default HelperText;
