import { forwardRef, lazy, useCallback, useMemo } from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  styled,
  selectClasses as classes,
  menuClasses,
  menuItemClasses,
  Box,
} from '@mui/material';

import { dev } from 'constants';
import { withProps } from 'hooks';
import { getArray, getFunc } from 'utils';

import Input, { inputClasses } from 'components/Input';
import Icon from 'components/Icon';
import Slip from 'components/Slip';

const Root = styled(MuiSelect, {
  label: 'D4PV-Select',
})(({ theme, readOnly, disabled }) => ({
  [`&.${inputClasses.root}`]: {
    [`& .${classes.select}`]: {
      cursor: readOnly ? 'text' : undefined,
      paddingRight: theme.spacing(5),
    },
    [`& > .${classes.icon}`]: {
      opacity: readOnly ? 0 : 1,
      color: disabled
        ? theme.palette.border.main
        : theme.palette.secondary.main,
    },
  },
}));

const ToggleIcon = withProps(Icon.ArrowDown, {
  sx: {
    color: (theme) => theme.palette.secondary.main,
  },
});
const TransparentToggleIcon = withProps(Box, {
  sx: {
    opacity: 0,
  },
});

const Select = forwardRef((props, ref) => {
  const {
    value,
    loading,
    options,
    onChange,
    onValue,
    readOnly,
    placeholder,
    elevation = 4,
    onBeforeChange,
    fullWidth = true,
    displayEmpty = true,
    noOptionValue = false,
    emptyOptionLabel = 'None',
    ...rest
  } = props;

  const emptyOption = useMemo(
    () => ({
      value: '',
      label: readOnly ? (
        '-'
      ) : (
        <Box component="em" sx={{ opacity: 0.5 }}>
          {emptyOptionLabel}
        </Box>
      ),
    }),
    [emptyOptionLabel, readOnly]
  );

  const opts = useMemo(() => {
    if (!displayEmpty) {
      return getArray(options);
    }
    return [emptyOption, ...getArray(options)];
  }, [displayEmpty, emptyOption, options]);

  const optsDictionary = useMemo(() => {
    return opts.reduce(
      (res, { value, label }) => ({
        ...res,
        [value]: label,
      }),
      {}
    );
  }, [opts]);

  const val = useMemo(() => {
    if (!noOptionValue) {
      return value;
    }
    const targetOption = opts.find((opt) => opt.value === value);

    if (!targetOption) {
      return '';
    }
    return value;
  }, [noOptionValue, opts, value]);

  const handleRenderValue = useCallback(
    (v) => {
      if (!v && placeholder) {
        return (
          <Box color="text.placeholder" component="span">
            {placeholder}
          </Box>
        );
      }
      if (optsDictionary[v]) {
        return optsDictionary[v];
      }
      return v;
    },
    [optsDictionary, placeholder]
  );

  const handleChange = useCallback(
    (e) => {
      const allowChange = getFunc(onBeforeChange)(e);

      if (allowChange !== false) {
        getFunc(onChange)(e);
        getFunc(onValue)(e.target.value);
      }
    },
    [onChange, onValue, onBeforeChange]
  );

  return (
    <Root
      ref={ref}
      value={val}
      loading={loading}
      input={<Input />}
      readOnly={readOnly}
      fullWidth={fullWidth}
      onChange={handleChange}
      IconComponent={loading ? TransparentToggleIcon : ToggleIcon}
      displayEmpty
      renderValue={handleRenderValue}
      MenuProps={{
        PaperProps: {
          component: Slip,
          sx: {
            p: 0,
            maxHeight: '300px',
            boxShadow: (theme) => theme.makeShadow(elevation),
          },
        },
        MenuListProps: {
          sx: (theme) => ({
            [`&.${menuClasses.list}`]: {
              [`& .${menuItemClasses.root}`]: {
                [`&.${menuItemClasses.focusVisible}`]: {
                  backgroundColor: theme.palette.colors.hover_background,
                },
                height: '40px',
                '&:hover': {
                  backgroundColor: theme.palette.colors.hover_background,
                },
              },
            },
          }),
        },
      }}
      {...rest}
    >
      {opts.map((opt) => (
        <MenuItem key={opt.value} value={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
    </Root>
  );
});

if (dev) {
  Select.Demo = lazy(() => import('./Select.demo'));
}

export default Select;
