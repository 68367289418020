import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Collapse, IconButton as MuiIconButton } from '@mui/material';
import RulerIcon from '@mui/icons-material/Straighten';
import RenameIcon from '@mui/icons-material/EditNote';

import {
  SCALE_TYPE,
  SCALE_VALUES,
  ENGINEER_SCALES_LIST,
  ARCHITECT_SCALES_LIST,
} from 'constants';

import { useToggle, useWatchField, withProps } from 'hooks';
import { getString } from 'utils';
import {
  DeincrementButtons,
  PaperCanvas,
  ProjectPreview,
  QuickConfirmTextField,
  Requirements,
  CalloutsDropdown,
  EquipmentsDropdown,
} from 'views';

import { Center, Delimiter, Icon, ProgressBox, TinyButton } from 'components';

import Controller from './sitePlan.controller';

const IconButton = withProps(MuiIconButton, {
  size: 'small',
});

const getScaleOptions = (type) => {
  switch (type) {
    case SCALE_TYPE.ENGINEER:
      return ENGINEER_SCALES_LIST;
    case SCALE_TYPE.ARCHITECT:
      return ARCHITECT_SCALES_LIST;
    default:
      return [];
  }
};

const SitePlanPreview = () => {
  const {
    api,
    loading,
    requirements,
    diagramRef,
    onReady,
    setValue,
    equipments,
    callouts,
    onEquipmentsChange,
    onCalloutsChange,
    onRulersChange,
    changeHandler,
    selectedItemId,
    onSelectItem,
  } = useFormContext();

  const previewRef = useRef(null);
  const [panTool, togglePanTool] = useToggle();
  const [assignTool, toggleAssignTool] = useToggle(true);
  const [angleTool, toggleAngleTool] = useToggle(true);
  const [newRuler, setNewRuler] = useState(null);
  const [rename, toggleRename] = useToggle();
  const [renameValue, setRenameValue] = useState('');

  const rulerTool = !!newRuler;

  const ableRename = useMemo(() => {
    if (!selectedItemId) {
      return false;
    }
    const instance = api?.getItem(selectedItemId);

    if (!instance) {
      return false;
    }
    if (instance.data.type === 'ruler') {
      return true;
    }
    if (instance.data.type === 'callout' && instance.data.custom) {
      return true;
    }
    return false;
  }, [api, selectedItemId]);

  const scale = useWatchField('scale');
  const scale_type = useWatchField('scale_type');

  const scaleOptions = useMemo(() => {
    return getScaleOptions(scale_type);
  }, [scale_type]);

  const scaleValue = useMemo(() => {
    const scaleIndex = scaleOptions.findIndex((opt) => opt.value === scale);
    return Math.max(scaleIndex, 0);
  }, [scaleOptions, scale]);

  const handleScaleChange = useCallback(
    (v) => {
      changeHandler(setValue)('scale', scaleOptions[v].value);
    },
    [setValue, scaleOptions, changeHandler]
  );

  const handleReferenceDrop = useCallback(
    (ref, { offset }) => {
      if (api) {
        const dropPoint = api.getDropPoint();
        api.disableDropzone();

        if (dropPoint) {
          // TODO
          const handler =
            ref.type === 'equipment' ? onEquipmentsChange : onCalloutsChange;
          const prev = ref.type === 'equipment' ? equipments : callouts;
          togglePanTool.off();

          handler(
            prev.map((p) => {
              if (p.value === ref.value) {
                return {
                  ...ref,
                  placed: true,
                  x: dropPoint.x - offset.x,
                  y: dropPoint.y - offset.y,
                };
              }
              return p;
            })
          );
        }
      }
    },
    [
      api,
      equipments,
      callouts,
      onEquipmentsChange,
      onCalloutsChange,
      togglePanTool,
    ]
  );

  const handleReferenceDrag = useCallback(() => {
    if (api) {
      api.enableDropzone();
    }
  }, [api]);

  const handleCreateCallout = useCallback(
    (label) => {
      onCalloutsChange((prev) => [
        ...prev,
        {
          label,
          type: 'callout',
          custom: true,
          placed: false,
          value: `callout-${Date.now()}`,
          id: `callout-${Date.now()}`,
        },
      ]);
    },
    [onCalloutsChange]
  );

  const handleRulerClick = useCallback(
    (e) => {
      e.preventDefault();
      togglePanTool.off();

      setNewRuler((prev) => {
        if (prev) {
          api.removeItem(prev);
          return null;
        }
        return `ruler-${Date.now()}`;
      });
    },
    [api, togglePanTool]
  );

  const handleClickAway = useCallback(() => {
    if (selectedItemId) {
      api.unselectItem();
    }
  }, [api, selectedItemId]);

  const handleRemoveSelectedItem = useCallback(() => {
    const id = selectedItemId;
    const data = api.getItem(id)?.data;

    if (['equipment', 'callout'].includes(data.type)) {
      const handler =
        data.type === 'equipment' ? onEquipmentsChange : onCalloutsChange;

      handler((prev) =>
        prev.map((item) => {
          return item.id !== data.id
            ? item
            : {
                ...item,
                placed: false,
                x: 0,
                y: 0,
              };
        })
      );
    }
    if (data.type === 'ruler') {
      onRulersChange((prev) => prev.filter((r) => r.id !== data.id));
    }
    api.removeItem(id);
    onSelectItem(null);
  }, [
    api,
    onSelectItem,
    selectedItemId,
    onRulersChange,
    onCalloutsChange,
    onEquipmentsChange,
  ]);

  const handleRemoveCallout = useCallback(
    (c) => {
      onCalloutsChange((prev) => prev.filter((cal) => cal.id !== c.id));
    },
    [onCalloutsChange]
  );

  const handleRenameItem = useCallback(
    (v) => {
      const label = getString(v).toUpperCase();
      const instance = api.getItem(selectedItemId);
      instance.rename(label);

      if (instance.data.type === 'callout') {
        onCalloutsChange((prev) => {
          return prev.map((c) => {
            return c.id !== instance.data.id ? c : { ...c, label };
          });
        });
      }
    },
    [api, selectedItemId, onCalloutsChange]
  );

  // reset rename value on toggle
  useEffect(() => {
    if (rename) {
      setRenameValue('');
    }
  }, [rename]);

  // toggle pan tool
  useEffect(() => {
    if (api) {
      api.togglePanTool(panTool);
    }
  }, [panTool, api]);

  // ruler tool
  useEffect(() => {
    const { current: previewElement } = previewRef;

    if (rulerTool && previewElement) {
      const newRulerId = newRuler;

      api.addRuler({
        id: newRulerId,
        scale: SCALE_VALUES[scale],
        onCreate: () => {
          setNewRuler(null);
          const newRuler = api.getItem(newRulerId);
          onRulersChange((prevRulers) => [...prevRulers, newRuler.data]);
        },
      });

      const removeIfExists = () => {
        const instance = api.getItem(newRulerId);
        instance?.isNew && api.removeItem(newRulerId);
        setNewRuler(null);
      };

      const listenRulerClick = (e) => {
        const isInsidePreview = previewElement.contains(e.target);
        !isInsidePreview && removeIfExists();
      };
      const listenEsc = (e) => {
        e.keyCode === 27 && removeIfExists();
      };
      document.addEventListener('mousedown', listenRulerClick);
      document.addEventListener('keydown', listenEsc);

      return () => {
        document.removeEventListener('mousedown', listenRulerClick);
        document.removeEventListener('keydown', listenEsc);
      };
    }
  }, [rulerTool, api, newRuler, scale, onRulersChange]);

  // unselect items on escape key
  useEffect(() => {
    const listenGlobalEsc = (e) => {
      if (e.keyCode === 27 && selectedItemId) {
        api.unselectItem();
      }
    };
    const listenGlobalDel = (e) => {
      if (e.keyCode === 46 && selectedItemId) {
        handleRemoveSelectedItem();
      }
    };
    document.addEventListener('keydown', listenGlobalEsc);
    document.addEventListener('keydown', listenGlobalDel);

    return () => {
      document.removeEventListener('keydown', listenGlobalEsc);
      document.removeEventListener('keydown', listenGlobalDel);
    };
  }, [selectedItemId, api, handleRemoveSelectedItem]);

  // toggle sticking tool
  useEffect(() => {
    if (api) {
      assignTool ? api.enableSticking() : api.disableSticking();
    }
  }, [api, assignTool]);

  // toggle angle tool
  useEffect(() => {
    if (api) {
      angleTool ? api.enableAngleAlignment() : api.disableAngleAlignment();
    }
  }, [api, angleTool]);

  return (
    <ProjectPreview
      ref={previewRef}
      onClickAway={handleClickAway}
      tools={
        <Center mx={4} height={1} flexGrow={1} justifyContent="space-between">
          <Center gap={1}>
            <EquipmentsDropdown
              equipments={equipments}
              disabled={loading || rulerTool}
              onDrop={handleReferenceDrop}
              onDragStart={handleReferenceDrag}
            />
            <CalloutsDropdown
              callouts={callouts}
              disabled={loading || rulerTool}
              onDrop={handleReferenceDrop}
              onDragStart={handleReferenceDrag}
              onCreate={handleCreateCallout}
              onRemove={handleRemoveCallout}
            />
            <TinyButton
              onClick={handleRulerClick}
              variant="outlined"
              disabled={loading}
              color="secondary"
            >
              <RulerIcon
                sx={{ color: rulerTool ? 'secondary.main' : undefined }}
              />
            </TinyButton>
          </Center>

          <Center height={1} mr={-2}>
            <Delimiter vertical mx={1} />

            <IconButton
              onClick={toggleAngleTool}
              disabled={loading || !assignTool}
              color={angleTool ? 'secondary' : 'primary'}
            >
              <Icon.RightAngle />
            </IconButton>

            <IconButton
              disabled={loading}
              onClick={toggleAssignTool}
              color={assignTool ? 'secondary' : 'primary'}
            >
              <Icon.Assign />
            </IconButton>

            <Delimiter vertical mx={1} />

            <QuickConfirmTextField
              open={rename}
              value={renameValue}
              onValue={setRenameValue}
              orientation="horizontal"
              onClose={toggleRename.off}
              onConfirm={handleRenameItem}
            />

            <Collapse orientation="horizontal" in={!rename}>
              <IconButton
                color="primary"
                onClick={toggleRename}
                disabled={!ableRename || loading || rulerTool}
              >
                <RenameIcon />
              </IconButton>
            </Collapse>

            <IconButton
              color="primary"
              disabled={!selectedItemId}
              onClick={handleRemoveSelectedItem}
            >
              <Icon.Trash />
            </IconButton>

            <Delimiter vertical mx={1} />

            <IconButton
              onClick={changeHandler(togglePanTool)}
              color={panTool ? 'secondary' : 'primary'}
              disabled={loading || rulerTool}
            >
              <Icon.Hand />
            </IconButton>

            <DeincrementButtons
              min={0}
              max={scaleOptions.length - 1}
              value={scaleValue}
              onValue={handleScaleChange}
              disabled={loading || rulerTool}
            />
          </Center>
        </Center>
      }
    >
      <ProgressBox progress={loading} height={1}>
        <Box height={1} p={5} overflow="auto">
          <PaperCanvas
            diagramRef={diagramRef}
            controller={Controller}
            onReady={onReady}
          />
        </Box>
      </ProgressBox>

      <Requirements requirements={requirements} />
    </ProjectPreview>
  );
};

export default SitePlanPreview;
