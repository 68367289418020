import {
  Children,
  cloneElement,
  forwardRef,
  lazy,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { propagateRefs } from 'hooks';
import { getFunc } from 'utils';
import { dev } from 'constants';

const Draggable = forwardRef((props, ref) => {
  const { children, onDragStart, onDrag, onDrop } = props;

  const target = Children.only(children);
  const rootRef = useRef(null);

  useEffect(() => {
    const { current: element } = rootRef;

    if (element) {
      element.style.position = 'absolute';
      element.style.top = 0;
      element.style.left = 0;
      element.style.cursor = 'move';

      let move = null;
      let offset = null;
      const initialZIndex = element.style.zIndex;

      const updateElementPosition = (x = 0, y = 0) => {
        element.style.left = `${x}px`;
        element.style.top = `${y}px`;
      };
      const listenMouseDown = (e) => {
        const coords = element.getBoundingClientRect();
        move = [e.clientX, e.clientY];
        offset = {
          x: move[0] - coords.x,
          y: move[1] - coords.y,
        };
        element.style.zIndex = 10;
        element.style.cursor = 'grabbing';
        getFunc(onDragStart)();
      };
      const listenMouseMove = (e) => {
        if (!move) {
          return;
        }
        const newX = e.clientX - move[0];
        const newY = e.clientY - move[1];
        updateElementPosition(newX, newY);
        getFunc(onDrag)();
      };
      const listenMouseUp = (e) => {
        const wasDrag = !!move;
        move = null;
        element.style.zIndex = initialZIndex;
        element.style.cursor = 'move';

        if (wasDrag) {
          getFunc(onDrop)(e, { offset });
        }
      };

      element.addEventListener('mousedown', listenMouseDown);
      window.addEventListener('mousemove', listenMouseMove);
      window.addEventListener('mouseup', listenMouseUp);

      return () => {
        element.removeEventListener('mousedown', listenMouseDown);
        window.removeEventListener('mousemove', listenMouseMove);
        window.removeEventListener('mouseup', listenMouseUp);

        move = null;
      };
    }
  }, [onDragStart, onDrag, onDrop]);

  return useMemo(() => {
    return cloneElement(target, {
      ref: propagateRefs(rootRef, target.ref, ref),
    });
  }, [ref, target]);
});

if (dev) {
  Draggable.Demo = lazy(() => import('./Draggable.demo'));
}

export default Draggable;
