import { useMemo } from 'react';

import {
  useGetBalanceOfSystemImageQuery,
  useGetInterconnectionImageQuery,
  useGetSystemsTemplateImageQuery,
} from 'store';

const useGetProjectTemplates = (props) => {
  const { project_id } = props;

  const { data: system, isFetching: systemLoading } =
    useGetSystemsTemplateImageQuery({ project_id });

  const { data: interconnection, isFetching: interconnectionLoading } =
    useGetInterconnectionImageQuery({ project_id });

  const { data: bos, isFetching: bosLoading } = useGetBalanceOfSystemImageQuery(
    { project_id }
  );

  const loading = systemLoading || interconnectionLoading || bosLoading;

  return useMemo(
    () => [
      {
        bos,
        system,
        interconnection,
      },
      loading,
    ],
    [bos, interconnection, loading, system]
  );
};

export default useGetProjectTemplates;
