import { lazy } from 'react';
import { Box } from '@mui/material';

import { dev } from 'constants';
import { withProps } from 'hooks';

const Center = withProps(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

if (dev) {
  Center.Demo = lazy(() => import('./Center.demo'));
}

export default Center;
