import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import {
  useChangeAccountRoleMutation,
  useInviteOrganisationMemberMutation,
} from 'store';

import { getFunc, getUserFullName } from 'utils';
import { ROLES } from 'constants';

import {
  Button,
  Form,
  Modal,
  ProgressBox,
  TextField,
  RadioButtons,
  Slip,
  Avatar,
  useMessage,
} from 'components';

const formId = 'edit-user-form';

const EditUserModal = (props) => {
  const { user, open, onClose, ...rest } = props;

  const m = useMessage();
  const form = Form.useForm();
  const { isValid } = form.formState;
  const [title, setTitle] = useState('Edit User');

  const [inviteMember, { isLoading }] = useInviteOrganisationMemberMutation();
  const [changeRole, { isLoading: changeRoleLoading }] =
    useChangeAccountRoleMutation();

  const loading = isLoading || changeRoleLoading;

  useEffect(() => {
    if (open) {
      setTitle(user ? 'Edit User' : 'Add User');
    }
  }, [open, user]);

  useEffect(() => {
    if (open) {
      form.reset(user);
    }
  }, [form, open, user]);

  const handleClose = useCallback(
    (...args) => {
      getFunc(onClose)(...args);
    },
    [onClose]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      const handler = user ? changeRole : inviteMember;
      const args = {};
      const body = !user
        ? formData
        : {
            account_id: user.id,
            role: formData.role,
          };

      if (user) {
        args.account_id = user.id;
      }
      try {
        await handler({ ...args, body }).unwrap();
        const successMessage = user
          ? 'Role has been changed!'
          : 'The invitation was successfully sent!';

        m.success(successMessage);
        getFunc(onClose)();
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [inviteMember, m, onClose, changeRole, user]
  );

  return (
    <Modal
      {...rest}
      open={open}
      title={title}
      onClose={handleClose}
      actions={
        <Box display="flex" gap={2}>
          <Button fullWidth={false} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            type="submit"
            form={formId}
            minWidth={200}
            fullWidth={false}
            disabled={!isValid}
          >
            Confirm
          </Button>
        </Box>
      }
    >
      <ProgressBox progress={loading}>
        <Form id={formId} form={form} onSubmit={handleSubmit} mt={2}>
          {user && (
            <Slip mb={2} display="flex" alignItems="center" gap={1}>
              <Avatar size={4} src={user.image} name={getUserFullName(user)} />
              <Typography variant="body2">{getUserFullName(user)}</Typography>
            </Slip>
          )}
          {!user && (
            <TextField.Control
              required
              name="email"
              type="email"
              label="User Email"
            />
          )}

          <Box mt={1} mb={2}>
            <RadioButtons.Control
              required
              reserveHelperTextSpace={false}
              label="User Type"
              name="role"
              size="medium"
              defaultValue={2}
              options={[
                {
                  value: ROLES.MEMBER,
                  label: 'User',
                },
                {
                  value: ROLES.OWNER,
                  label: 'Admin',
                },
              ]}
            />
          </Box>
        </Form>
      </ProgressBox>
    </Modal>
  );
};

export default EditUserModal;
