import { Group } from 'paper';

import { getString } from 'utils';
import PaperElement from 'components/Paper/PaperElement';
import PaperText from 'components/Paper/PaperText';

class PaperTextArea extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'text_area_group' });
    super({ element, ...options });

    this.data = {
      ...options.data,
      width: options.data.width || 200,
      content: getString(options.data.content),
    };
    this.update();
  }

  update(data = this.data) {
    this.data = {
      ...this.data,
      ...data,
    };
    const {
      fontSize = 12,
      align = 'left',
      width,
      fillColor = '#202020',
    } = this.data;

    const fragments = this.data.content
      .split(/\s/gim)
      .map((c) => c.trim())
      .filter(Boolean)
      .map((c, i, arr) => (i === arr.length - 1 ? c : `${c} `))
      .map(
        (content) =>
          new PaperText({
            content,
            fontSize,
            fillColor,
          })
      );

    this.element.removeChildren();
    this.element.addChildren(fragments);

    const rows = fragments.reduce(
      (res, textItem) => {
        const lastRow = res[res.length - 1];
        const lastRowWidth = lastRow.reduce(
          (r, item) => r + item.bounds.width,
          0
        );

        if (textItem.bounds.width + lastRowWidth > width) {
          return [...res, [textItem]];
        }
        lastRow.push(textItem);
        return res;
      },
      [[]]
    );

    const scheme = rows.map((row) => {
      return row.reduce((r, item) => r + item.bounds.width, 0);
    });

    rows.forEach((row, rowIndex) => {
      row.forEach((textItem, itemIndex) => {
        if (itemIndex === 0) {
          const ml = (width - scheme[rowIndex]) / 2;

          if (rowIndex === 0) {
            const x = align === 'left' ? 0 : ml;
            textItem.bounds.topLeft = this.point(x, 0);
            return;
          }
          const firstItemOfPreviousRow = rows[rowIndex - 1][0];
          textItem.bounds.topLeft = firstItemOfPreviousRow.bounds.bottomLeft;

          if (align === 'center') {
            textItem.bounds.topLeft.x += ml;
          }
          return;
        }
        const previousItem = row[itemIndex - 1];
        textItem.bounds.bottomLeft = previousItem.bounds.bottomRight;
      });
    });
  }
}

export default PaperTextArea;
