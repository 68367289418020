import { forwardRef, lazy } from 'react';

import { dev } from 'constants';
import { SvgElement } from 'components';

const size = 32;

const getPoints = (s, x, y) => {
  return new Array(7)
    .fill(null)
    .map((p, i) => {
      const angleDeg = 60 * i - 30;
      const angleRad = (Math.PI / 180) * angleDeg;
      const radius = s / 2 - 1;
      const p1 = Math.round(s / 2 + radius * Math.cos(angleRad)) + x - s / 2;
      const p2 = Math.round(s / 2 + radius * Math.sin(angleRad)) + y - s / 2;
      return [p1, p2].join(',');
    })
    .join(' ');
};
const points = getPoints(size, size / 2, size / 2);

const WireMark = forwardRef((props, ref) => {
  const {
    text,
    stroke = 'black',
    strokeDasharray = undefined,
    ...rest
  } = props;

  return (
    <SvgElement ref={ref} width={size} height={size} {...rest}>
      <polygon
        fill="white"
        stroke={stroke}
        strokeWidth={1}
        points={points}
        strokeDasharray={strokeDasharray}
      />

      <text x={12} y={21} fontSize={14} fontFamily="ArialNarrow">
        {text}
      </text>
    </SvgElement>
  );
});

if (dev) {
  WireMark.Demo = lazy(() => import('./WireMark.demo'));
}

export default WireMark;
