import { moving } from 'assets';

import PaperContentBox from 'components/Paper/PaperContentBox';
import PaperSvg from 'components/Paper/PaperSvg';

class PaperNoteBox extends PaperContentBox {
  constructor(options) {
    const { data, ...opts } = options;
    super({ data, ...opts });
    this.children.handle_icon = new PaperSvg(opts);

    this.children.handle_icon.upload(moving, () => {
      this.children.handle_icon.element.bounds.topLeft = { x: 0, y: 4 };

      const filledItems = this.children.handle_icon.element.getItems({
        match: (child) => !!child.fillColor,
      });
      filledItems.forEach((fi) => {
        fi.fillColor = this.theme.palette.border.main;
      });
      this.update();
    });
    this.element.addChild(this.children.handle_icon.element);
  }

  disableEdit() {
    this.children.handle_icon.element.visible = false;
    super.disableEdit();
  }

  enableEdit() {
    this.children.handle_icon.element.visible = true;
    super.enableEdit();
  }
}

export default PaperNoteBox;
