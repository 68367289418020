import { lazy, useMemo } from 'react';
import { IconButton, styled, Box } from '@mui/material';

import { useGetProfileQuery } from 'store';
import { routes } from 'routes';
import { getUserFullName } from 'utils';
import { useToggle } from 'hooks';
import { ROLES, app_bar_logo_width, dev } from 'constants';
import { logo_black, logo_light } from 'assets';
import { Access } from 'views';
import {
  Icon,
  Image,
  ProgressBox,
  Avatar,
  Ref,
  DropdownMenu,
} from 'components';

const Root = styled(Box, {
  label: 'AppBar',
})(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  height: theme.spacing(7),
  minHeight: theme.spacing(7),
  backgroundColor: theme.palette.common.white,
}));

const LogoBox = styled(Box, {
  label: 'LogoBox',
  shouldForwardProp: (prop) => !['colorTheme'].includes(prop),
})(({ theme, colorTheme }) => ({
  width: app_bar_logo_width,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRight: (() => {
    switch (colorTheme) {
      case 'violet':
        return 'none';
      default:
        return `1px solid ${theme.palette.border.light}`;
    }
  })(),
  borderBottom: (() => {
    switch (colorTheme) {
      case 'violet':
        return `1px solid ${theme.palette.violet.light}`;
      default:
        return `1px solid ${theme.palette.border.light}`;
    }
  })(),
  backgroundColor: (() => {
    switch (colorTheme) {
      case 'violet':
        return theme.palette.violet.main;
      default:
        return theme.palette.common.white;
    }
  })(),
}));

const ToolBox = styled(Box, {
  label: 'ToolBox',
})(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3),
  borderBottom: `1px solid ${theme.palette.border.light}`,
}));

const AppBar = (props) => {
  const { children, logoTheme = 'white' } = props;
  const { data, isFetching } = useGetProfileQuery();

  const [profileMenu, toggleProfileMenu] = useToggle();
  const [faq, toggleFaq] = useToggle();

  const logoSource = useMemo(() => {
    switch (logoTheme) {
      case 'violet':
        return logo_light;
      default:
        return logo_black;
    }
  }, [logoTheme]);

  return (
    <Root>
      <LogoBox colorTheme={logoTheme}>
        <Ref to={routes.root.path()} nostyles>
          <Image src={logoSource} height={33} />
        </Ref>
      </LogoBox>

      <ToolBox>
        {/* ORGANISATION NAME */}
        <Box>{children}</Box>

        {/* TOOLS */}
        <Box>
          <Access role={ROLES.OWNER}>
            <IconButton
              nostyles
              color="primary"
              component={Ref}
              to={routes.configurations.path()}
            >
              <Icon.Cog />
            </IconButton>
          </Access>

          <DropdownMenu
            open={faq}
            onOpen={toggleFaq.on}
            onClose={toggleFaq.off}
            onItemClick={toggleFaq.off}
            anchor={
              <IconButton color={faq ? 'secondary' : 'primary'}>
                <Icon.QuestionFilled />
              </IconButton>
            }
            options={[
              {
                value: 'create_project',
                label: 'How to create project?',
                divider: true,
              },
              {
                value: 'add_ticket',
                label: 'Add Ticket',
              },
            ]}
          />

          <DropdownMenu
            minWidth={150}
            open={profileMenu}
            onOpen={toggleProfileMenu.on}
            onClose={toggleProfileMenu.off}
            onItemClick={toggleProfileMenu.off}
            anchor={
              <IconButton size="small" edge="end">
                <ProgressBox progress={isFetching} size="xsmall">
                  <Avatar name={getUserFullName(data)} src={data?.image} />
                </ProgressBox>
              </IconButton>
            }
            options={[
              {
                value: 'profile',
                label: 'View Profile',
                to: routes.profile.path(),
              },
              {
                value: 'signout',
                label: 'Sign Out',
                to: routes.sign_out.path(),
              },
            ]}
          />

          <IconButton
            edge="start"
            size="small"
            color="secondary"
            onClick={toggleProfileMenu}
          >
            <Icon.ArrowDown rotate={profileMenu ? 180 : 0} />
          </IconButton>
        </Box>
      </ToolBox>
    </Root>
  );
};

if (dev) {
  AppBar.Demo = lazy(() => import('./AppBar.demo'));
}

export default AppBar;
