import { Group } from 'paper';

import { diagram_width, SYSTEM_TYPES } from 'constants';
import { getArray, getString } from 'utils';
import { Paper } from 'components';

const { PaperElement, PaperTable } = Paper;

const spacing = 10;

const frame_left = diagram_width * 0.4;
const frame_right = diagram_width - frame_left - spacing;
const frame_right_left = frame_right * 0.6 - spacing;
const frame_right_right = frame_right - (frame_right_left + spacing);

const modules_columns = [
  'Ref.',
  'Qty.',
  'Make and model',
  'PMAX',
  'PTC',
  'ISC',
  'IMP',
  'VOC',
  'VMP',
  'Temp. coeff. of VOC',
  'Fuse Rating',
];
const inverters_columns = [
  'Ref.',
  'Qty',
  'Make and model',
  ['AC', 'Voltage'],
  'Ground',
  ['OCPD', 'Rating'],
  ['Rated', 'Power'],
  ['Max Output', 'Current'],
  ['Max Input', 'Current'],
  ['Max Input', 'Voltage'],
  ['CEC Weighted', 'Efficiency'],
];
const disconnects_columns = [
  'Ref.',
  'Qty.',
  'Make and model',
  'Rated Current',
  'Max Rated Voltage',
];
const ocpd_columns = ['Ref.', 'Qty', 'Rated Current', 'Max Voltage'];
const bom_columns = [
  'Category',
  'Make',
  'Model Number',
  'Ref',
  'Qty',
  'Unit',
  'Qty/Unit',
  'Description',
];

const bom_order_priority = [
  'PV MODULE',
  'INVERTER',
  'POWER OPTIMIZER',
  'DISCONNECT',
  'AC COMBINER PANEL',
  'PRODUCTION METER',
  'MONITORING',
  'WIRING',
  'WIREWAY',
  'OCPD',
  'TRANSITION ENCLOSURE',
  'MISC ELECTRICAL EQUIPMENT',
];

class SummaryTable extends PaperTable {
  constructor(options) {
    super({
      ...options,
      data: {
        width: frame_left,
      },
    });
  }

  update(data) {
    const systems = getArray(data?.systems);
    const totals = data?.totals;

    if (systems.length === 0) {
      return;
    }
    const [firstSystem] = systems;
    const { type } = firstSystem;

    const tableData = this.composeData(type, systems, totals);

    super.update({
      rows: tableData,
    });
  }

  composeData(type, data, totals) {
    switch (type) {
      case SYSTEM_TYPES.DC_OPTIMIZED:
        return this.composeDcOptimizedData(data, totals);
      case SYSTEM_TYPES.STRING:
        return this.composeStringData(data, totals);
      case SYSTEM_TYPES.ACM:
      case SYSTEM_TYPES.MICRO:
      default:
        return this.composeMicroinverterData(data, totals);
    }
  }

  composeStringData(data, totals) {
    const strings = data.reduce(
      (res, inverter) => [...res, ...inverter.mppt_ports],
      []
    );

    const span = strings.length;

    const spanCell = (content, s = span) => ({
      content,
      span: s,
    });

    // prettier-ignore
    const result = [
      [
        spanCell('System Summary', span + 1),
      ],
      [
        '',
        ...data.map((inverter, i) => (
          spanCell(`Inverter #${i + 1}`, inverter.mppt_ports.length)
        )),
      ],
      [
        '',
        ...strings.map((string, i) => ['String', `#${i + 1}`]),
      ],
      [
        'Modules in series',
        ...strings.map((string) => string.modules_in_series),
      ],
      [
        'Array VMP',
        ...strings.map((string) => string.array_voltage_mp),
      ],
      [
        'Array IMP',
        ...strings.map((string) => string.array_current_mp),
      ],
      [
        'Array VOC',
        ...strings.map((string) => string.array_voltage_oc),
      ],
      [
        'Array Max VOC',
        ...strings.map((string) => string.array_max_voltage),
      ],
      [
        'Array ISC',
        ...strings.map((string) => string.array_current_sc),
      ],
      [
        'Array STC Power',
        ...data.map((inverter) => (
          spanCell(inverter.array_stc_power, inverter.mppt_ports.length)
        )),
      ],
      [
        'Array PTC Power',
        ...data.map((inverter) => (
          spanCell(inverter.array_ptc_power, inverter.mppt_ports.length)
        )),
      ],
      [
        'Max AC Current',
        ...data.map((inverter) => (
          spanCell(inverter.max_ac_current, inverter.mppt_ports.length)
        )),
      ],
      [
        'Max AC Power',
        ...data.map((inverter) => (
          spanCell(inverter.max_ac_power, inverter.mppt_ports.length)
        )),
      ],
      [
        'Derated (CEC) AC Power',
        ...data.map((inverter) => (
          spanCell(inverter.derated_ac_power, inverter.mppt_ports.length)
        )),
      ],
    ];
    if (totals) {
      // prettier-ignore
      return [
        ...result,
        [
          'Total STC Power',
          spanCell(totals.array_stc_power, strings.length),
        ],
        [
          'Total PTC Power',
          spanCell(totals.array_ptc_power, strings.length),
        ],
        [
          'Max AC Current',
          spanCell(totals.max_ac_current, strings.length),
        ],
        [
          'Max AC Power',
          spanCell(totals.max_ac_power, strings.length),
        ],
        [
          'Derated (CEC) AC Power',
          spanCell(totals.derated_ac_power, strings.length),
        ],
      ];
    }
    return result;
  }

  composeDcOptimizedData(data, totals) {
    const strings = data.reduce(
      (res, inverter) => [...res, ...inverter.strings],
      []
    );

    const span = strings.length;

    const spanCell = (content, s = span) => ({
      content,
      span: s,
    });

    // prettier-ignore
    const result = [
      [
        spanCell('System Summary', span + 1),
      ],
      [
        '',
        ...data.map((inverter, i) => (
          spanCell(`Inverter #${i + 1}`, inverter.strings.length)
        )),
      ],
      [
        '',
        ...strings.map((string, i) => ['String', `#${i + 1}`]),
      ],
      [
        'Powerbox Max Output Current',
        ...strings.map((string) => string.powerbox_max_output_current),
      ],
      [
        'Optimizers in series',
        ...strings.map((string) => string.optimizers_in_series),
      ],
      [
        'Nominal String Voltage',
        ...strings.map((string) => string.nominal_string_voltage),
      ],
      [
        'Array Operating Current',
        ...strings.map((string) => string.array_operating_current),
      ],
      [
        'Array STC Power',
        ...data.map((inverter) => (
          spanCell(inverter.array_stc_power, inverter.strings.length)
        )),
      ],
      [
        'Array PTC Power',
        ...data.map((inverter) => (
          spanCell(inverter.array_ptc_power, inverter.strings.length)
        )),
      ],
      [
        'Max AC Current',
        ...data.map((inverter) => (
          spanCell(inverter.max_ac_current, inverter.strings.length)
        )),
      ],
      [
        'Max AC Power',
        ...data.map((inverter) => (
          spanCell(inverter.max_ac_power, inverter.strings.length)
        )),
      ],
      [
        'Derated (CEC) AC Power',
        ...data.map((inverter) => (
          spanCell(inverter.derated_ac_power, inverter.strings.length)
        )),
      ],
      [
        'Total STC Power',
        ...data.map((inverter) => (
          spanCell(inverter.array_stc_power, inverter.strings.length)
        )),
      ],
    ];
    if (totals) {
      // prettier-ignore
      return [
        ...result,
        [
          'Total PTC Power',
          spanCell(totals.array_ptc_power, span),
        ],
        [
          'Max AC Current',
          spanCell(totals.max_ac_current, span),
        ],
        [
          'Max AC Power',
          spanCell(totals.max_ac_power),
        ],
        [
          'Derated (CEC) AC Power',
          spanCell(totals.derated_ac_power),
        ],
      ];
    }
    return result;
  }

  composeMicroinverterData(data) {
    const [source] = data;
    const span = source.branches.length;

    const spanCell = (content, s = span) => ({
      content,
      span: s,
    });
    // prettier-ignore
    return [
      [
        spanCell('System Summary', span + 1),
      ],
      [
        '',
        ...source.branches.map((branch, i) => `Branch #${i + 1}`),
      ],
      [
        'Inverters per branch',
        ...source.branches.map((branch) => branch.inverters_per_branch),
      ],
      [
        'Max AC Current',
        ...source.branches.map((branch) => branch.max_ac_current),
      ],
      [
        'Max AC Output Power',
        ...source.branches.map((branch) => branch.max_ac_power),
      ],
      [
        'Array STC Power',
        spanCell(source.array_stc_power),
      ],
      [
        'Array PTC Power',
        spanCell(source.array_ptc_power),
      ],
      [
        'Max AC Current',
        spanCell(source.max_ac_current),
      ],
      [
        'Max AC Power',
        spanCell(source.max_ac_power),
      ],
      [
        'Derated (CEC) AC Power',
        spanCell(source.derated_ac_power),
      ],
    ];
  }
}

class ModulesTable extends PaperTable {
  constructor(options) {
    super({
      ...options,
      data: {
        width: frame_right - 1,
      },
    });
  }

  update(data) {
    const spanCell = (content, s = modules_columns.length) => ({
      content,
      span: s,
    });

    // prettier-ignore
    super.update({
      scheme: {
        0: 65,
        1: 65,
      },
      rows: [
        [spanCell('Modules')],
        [...modules_columns],
        ...getArray(data).map((module) => ([
          module.ref,
          module.qty,
          module.make_and_model,
          module.pmax,
          module.ptc,
          module.isc,
          module.imp,
          module.voc,
          module.vmp,
          module.coef,
          module.fuse_rating,
        ])),
      ],
    });
  }
}

class InvertersTable extends PaperTable {
  constructor(options) {
    super({
      ...options,
      data: {
        width: frame_right - 1,
      },
    });
  }

  update(data) {
    const spanCell = (content, s = inverters_columns.length) => ({
      content,
      span: s,
    });

    // prettier-ignore
    super.update({
      scheme: {
        0: 65,
        1: 65,
      },
      rows: [
        [spanCell('Inverters')],
        [...inverters_columns],
        ...getArray(data).map((inverter) => ([
          inverter.ref,
          inverter.qty,
          inverter.make_and_model,
          inverter.ac_voltage,
          inverter.ground,
          inverter.ocpd_rating,
          inverter.rated_power,
          inverter.max_output_current,
          inverter.max_input_current,
          inverter.max_input_voltage,
          inverter.cec_weighted_eff,
        ])),
      ],
    });
  }
}

class DisconnectsTable extends PaperTable {
  constructor(options) {
    super({
      ...options,
      data: {
        width: frame_right_left,
      },
    });
  }

  update(data) {
    const spanCell = (content, s = disconnects_columns.length) => ({
      content,
      span: s,
    });

    // prettier-ignore
    super.update({
      scheme: {
        0: 65,
        1: 65,
      },
      rows: [
        [spanCell('Disconnects')],
        [...disconnects_columns],
        ...getArray(data).map((disconnect) => ([
          disconnect.ref,
          disconnect.qty,
          disconnect.make_and_model,
          disconnect.rated_current,
          disconnect.max_rated_voltage,
        ])),
      ],
    });
  }
}

class StationTable extends PaperTable {
  constructor(options) {
    super({
      ...options,
      data: {
        width: frame_right_left,
      },
    });
  }

  update(data) {
    // prettier-ignore
    super.update({
      scheme: {
        0: 130,
      },
      rows: [
        [
          'Ashrae Extreme Low',
          data?.max_temp,
        ],
        [
          'Ashrae 2% high',
          data?.max_temp,
        ],
      ],
    });
  }
}

class OcpdsTable extends PaperTable {
  constructor(options) {
    super({
      ...options,
      data: {
        width: frame_right_right,
      },
    });
  }

  update(data) {
    const spanCell = (content, s = ocpd_columns.length) => ({
      content,
      span: s,
    });

    // prettier-ignore
    super.update({
      rows: [
        [spanCell('OCPDs')],
        [...ocpd_columns],
        ...getArray(data).map((ocpd) => ([
          ocpd.ref,
          ocpd.qty,
          ocpd.rated_current,
          ocpd.max_voltage,
        ])),
      ],
    });
  }
}

class BomTable extends PaperTable {
  constructor(options) {
    super({
      ...options,
      data: {
        width: diagram_width - 1,
      },
    });
  }

  update(data) {
    const spanCell = (content, s = bom_columns.length) => ({
      content,
      span: s,
    });

    const dataSorted = getArray(data)
      .map(({ category, ...rest }) => ({
        ...rest,
        category: getString(category).toUpperCase(),
      }))
      .sort((item_a, item_b) => {
        const a = item_a.category;
        const b = item_b.category;

        if (bom_order_priority.includes(a) || bom_order_priority.includes(b)) {
          const index_a = bom_order_priority.includes(a)
            ? bom_order_priority.indexOf(a)
            : Infinity;

          const index_b = bom_order_priority.includes(b)
            ? bom_order_priority.indexOf(b)
            : Infinity;

          return index_a < index_b ? -1 : 1;
        }
        return a.localeCompare(b);
      });

    // prettier-ignore
    super.update({
      padding_x: 15,
      rows: [
        [spanCell('Bill of Material')],
        [...bom_columns],
        ...dataSorted.map((b) => ([
          b.category,
          b.make,
          b.model,
          b.ref,
          b.qty,
          b.unit,
          b.unit_qty,
          {
            content: b.description,
            align: 'left',
          },
        ])),
      ],
    });
  }
}

class ElectricalTablesController extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'main' });
    element.visible = false;
    super({ element, ...options });

    this.summary = new SummaryTable(options);
    this.modules = new ModulesTable(options);
    this.inverters = new InvertersTable(options);
    this.disconnects = new DisconnectsTable(options);
    this.station = new StationTable(options);
    this.ocpds = new OcpdsTable(options);
    this.bom = new BomTable(options);

    this.scope.project.activeLayer.addChild(element);

    element.addChildren([
      this.summary.element,
      this.modules.element,
      this.inverters.element,
      this.disconnects.element,
      this.station.element,
      this.ocpds.element,
      this.bom.element,
    ]);
    this.bom.hide();
  }

  updateTables(data) {
    const summary = data?.summary;
    const modules = getArray(data?.modules);
    const inverters = getArray(data?.inverters);
    const disconnects = getArray(data?.disconnects);
    const ocpds = getArray(data?.ocpds);
    const bom = getArray(data?.bom?.items);

    this.summary.update(summary);
    this.modules.update(modules);
    this.inverters.update(inverters);
    this.disconnects.update(disconnects);
    this.station.update(data?.station);
    this.ocpds.update(ocpds);
    this.bom.update(bom);

    this.modules.toggle(modules.length > 0);
    this.inverters.toggle(inverters.length > 0);
    this.disconnects.toggle(disconnects.length > 0);
    this.ocpds.toggle(ocpds.length > 0);

    this.summary.element.bounds.x = 0.5;
    this.summary.element.bounds.y = 0.5;

    let frame_left_y = this.summary.element.bounds.bottomCenter.y;
    let frame_right_y;
    let frame_right_left_y = 0;
    let frame_right_right_y = 0;
    let bom_y = 0;

    if (modules.length > 0) {
      this.modules.element.bounds.x = frame_left + spacing + 0.5;
      this.modules.element.bounds.y = 0.5;
      frame_right_y = this.modules.element.bounds.bottomCenter.y + spacing;
    }
    if (inverters.length > 0) {
      this.inverters.element.bounds.x = frame_left + spacing + 0.5;
      this.inverters.element.bounds.y = frame_right_y;
      frame_right_y = this.inverters.element.bounds.bottomCenter.y + spacing;
    }
    frame_right_left_y = frame_right_y;
    frame_right_right_y = frame_right_y;

    if (ocpds.length > 0) {
      this.ocpds.element.bounds.x =
        frame_left + spacing - 0.5 + frame_right_left + spacing;
      this.ocpds.element.bounds.y = frame_right_y;
      frame_right_right_y = this.ocpds.element.bounds.bottomCenter.y + spacing;
    }
    if (disconnects.length > 0) {
      this.disconnects.element.bounds.x = frame_left + spacing + 0.5;
      this.disconnects.element.bounds.y = frame_right_y;
      frame_right_left_y =
        this.disconnects.element.bounds.bottomCenter.y + spacing;
    }
    this.station.element.bounds.x = frame_left + spacing + 0.5;
    this.station.element.bounds.y = frame_right_left_y;

    bom_y =
      Math.max(frame_right_left_y, frame_right_right_y, frame_left_y) +
      spacing * 3;

    this.bom.element.bounds.x = 0.5;
    this.bom.element.bounds.y = bom_y;
    this.element.visible = true;
  }

  toggleBom(visibility) {
    this.bom.toggle(!!visibility);
  }

  destroy() {
    if (this.scope?.project?.activeLayer) {
      this.scope.project.activeLayer.removeChildren();
    }
  }
}

export default ElectricalTablesController;
