export const LAYERS = {
  ATTACHMENTS: 'attachments',
  DIMENSIONS: 'dimensions',
  FIRE_SETBACKS: 'fire_setbacks',
  OBSTRUCTIONS: 'obstructions',
  PROPERTY_LINES: 'property_lines',
  PROPERTY_SETBACKS: 'property_setbacks',
  PV_PANELS: 'pv_panels',
  RACKING: 'racking',
  ROAD: 'road',
  ROOF: 'roof',
  STRUCTURE: 'structure',
};

export const LAYERS_NAMES = {
  [LAYERS.ATTACHMENTS]: 'Attachments',
  [LAYERS.DIMENSIONS]: 'Dimensions',
  [LAYERS.FIRE_SETBACKS]: 'Fire Setbacks',
  [LAYERS.OBSTRUCTIONS]: 'Obstructions',
  [LAYERS.PROPERTY_LINES]: 'Property Lines',
  [LAYERS.PROPERTY_SETBACKS]: 'Property Setbacks',
  [LAYERS.PV_PANELS]: 'PV Panels',
  [LAYERS.RACKING]: 'Racking',
  [LAYERS.ROAD]: 'Road',
  [LAYERS.ROOF]: 'Roof',
  [LAYERS.STRUCTURE]: 'Structure',
};

export const LAYERS_LIST = [
  LAYERS.ATTACHMENTS,
  LAYERS.DIMENSIONS,
  LAYERS.FIRE_SETBACKS,
  LAYERS.OBSTRUCTIONS,
  LAYERS.PROPERTY_LINES,
  LAYERS.PROPERTY_SETBACKS,
  LAYERS.PV_PANELS,
  LAYERS.RACKING,
  LAYERS.ROAD,
  LAYERS.ROOF,
  LAYERS.STRUCTURE,
].map((value) => ({
  value,
  label: LAYERS_NAMES[value],
}));
