import { forwardRef, lazy, useCallback, useMemo } from 'react';

import { dev } from 'constants';
import { getFunc } from 'utils';
import { useToggle } from 'hooks';
import DropdownPopover from 'components/DropdownPopover';
import List from 'components/List';

const DropdownMenu = forwardRef((props, ref) => {
  const {
    open: customOpen,
    onOpen,
    onClose,
    children,
    options,
    ListProps,
    PaperProps,
    minWidth = 100,
    onItemClick,
    closeOnItemClick = true,
    ...rest
  } = props;

  const [open, toggleOpen] = useToggle(!!customOpen);

  const handleItemClick = useCallback(
    (...args) => {
      if (closeOnItemClick) {
        toggleOpen.off();
      }
      getFunc(onItemClick)(...args);
    },
    [closeOnItemClick, onItemClick, toggleOpen]
  );

  const handleOpen = useCallback(
    (...args) => {
      toggleOpen.on();
      getFunc(onOpen)(...args);
    },
    [onOpen, toggleOpen]
  );

  const handleClose = useCallback(
    (...args) => {
      toggleOpen.off();
      getFunc(onClose)(...args);
    },
    [onClose, toggleOpen]
  );

  const openState = useMemo(() => {
    return typeof customOpen === 'boolean' ? customOpen : open;
  }, [customOpen, open]);

  return (
    <DropdownPopover
      ref={ref}
      {...rest}
      open={openState}
      onOpen={handleOpen}
      onClose={handleClose}
      PaperProps={{
        ...PaperProps,
        p: 0,
        sx: { minWidth },
      }}
    >
      <List options={options} onItemClick={handleItemClick} {...ListProps} />
    </DropdownPopover>
  );
});

if (dev) {
  DropdownMenu.Demo = lazy(() => import('./DropdownMenu.demo'));
}

export default DropdownMenu;
