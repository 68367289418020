import { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { getArray } from 'utils';
import { useWatchField } from 'hooks';
import { useGetProjectWiresTableQuery } from 'store';
import { ProjectPreview, WireTableView } from 'views';

import EditSegmentModal from './EditSegmentModal';

const WireTablePreview = (props) => {
  const { project_id, station_id } = props;

  const [editSegment, setEditSegment] = useState(null);

  const neutral_view = useWatchField('neutral_view');
  const voltage_drop_view = useWatchField('voltage_drop_view');

  const gec_strategy = useWatchField('gec_strategy');
  const neutral_strategy = useWatchField('neutral_strategy');
  const temperature_rating = useWatchField('temperature_rating');

  const tableQuery = useMemo(
    () => ({
      project_id,
      params: {
        gec_strategy,
        neutral_strategy,
        temperature_rating,
      },
    }),
    [project_id, temperature_rating, gec_strategy, neutral_strategy]
  );

  const { data: wireTable, isFetching: tableLoading } =
    useGetProjectWiresTableQuery(tableQuery);

  const segments = useMemo(
    () =>
      getArray(wireTable?.segments).map((s, i) => ({
        id: i + 1,
        ...s,
      })),
    [wireTable]
  );

  const handleEditSegment = useCallback(
    (e, segmentIndex) => {
      e.preventDefault();
      setEditSegment(segments[segmentIndex]);
    },
    [segments]
  );

  const handleCloseModal = useCallback(() => {
    setEditSegment(null);
  }, []);

  return (
    <>
      <EditSegmentModal
        open={!!editSegment}
        project_id={project_id}
        segment={editSegment}
        station_id={station_id}
        onClose={handleCloseModal}
        neutral_view={neutral_view}
        neutral_strategy={neutral_strategy}
        temperature_rating={temperature_rating}
        voltage_drop_view={voltage_drop_view}
        gec_strategy={gec_strategy}
      />

      <ProjectPreview fullHeight={false}>
        <Box pb={5} pt={4} pl={8} pr={5} overflow="hidden" display="flex">
          <WireTableView
            editable
            segments={segments}
            loading={tableLoading}
            station_id={station_id}
            onEdit={handleEditSegment}
            neutral_view={neutral_view}
            voltage_drop_view={voltage_drop_view}
          />
        </Box>
      </ProjectPreview>
    </>
  );
};

export default WireTablePreview;
