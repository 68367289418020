import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography, Collapse, Box } from '@mui/material';

import { BUS_RATING_LIST, BREAKER_RATING_LIST, BUS_RATING } from 'constants';
import { useGetAcCombinerListQuery } from 'store';
import { useWatchField } from 'hooks';
import { getArray, valuesToOptions } from 'utils';
import {
  Autocomplete,
  Center,
  Delimiter,
  SelectField,
  Switch,
  Tabs,
} from 'components';

const tabs = valuesToOptions(['generic', 'vendor']);

const CombinerPanelFields = () => {
  const { resetField, setValue } = useFormContext();

  const combiner_enabled = useWatchField('combiner_enabled');
  const combiner_panel_type = useWatchField('combiner_panel_type');
  const combiner_id = useWatchField('combiner_id');
  const isVendor = combiner_panel_type === 'vendor';

  const { data: combiners, isFetching } = useGetAcCombinerListQuery(undefined, {
    skip: !isVendor,
  });

  const getPanel = useCallback(
    (id) => {
      return getArray(combiners).find((c) => c.id === id);
    },
    [combiners]
  );

  const panel = useMemo(() => {
    return getPanel(combiner_id);
  }, [combiner_id, getPanel]);

  const combinerOptions = useMemo(() => {
    return getArray(combiners).map((c) => ({
      value: c.id,
      label: `${c.vendor.title} - ${c.title}`,
    }));
  }, [combiners]);

  const breakerOptions = useMemo(() => {
    return BREAKER_RATING_LIST.filter(
      (r) => !isVendor || r.value >= panel?.buss_rating
    );
  }, [isVendor, panel]);

  const handleTabChange = useCallback(
    (e, v) => {
      resetField('combiner_id', {
        defaultValue: '',
      });
      resetField('combiner_data.bus_rating', {
        defaultValue: v === 'vendor' ? '' : BUS_RATING.A60,
      });
      resetField('combiner_data.breaker_rating', {
        defaultValue: '',
      });
    },
    [resetField]
  );

  const handlePanelChange = useCallback(
    (e, v) => {
      const targetPanel = getPanel(v);
      setValue('combiner_data.bus_rating', targetPanel?.buss_rating || '');
      setValue('combiner_data.breaker_rating', '');
    },
    [getPanel, setValue]
  );

  return (
    <Box mt={2}>
      <Center justifyContent="space-between">
        <Typography variant="subtitle2">AC Combiner Panel</Typography>

        <Switch.Control name="combiner_enabled" />
      </Center>

      <Collapse in={combiner_enabled}>
        <Box mt={2}>
          <Tabs.Control
            uppercase
            size="small"
            options={tabs}
            defaultValue="generic"
            name="combiner_panel_type"
            onChange={handleTabChange}
          />
        </Box>

        <Collapse in={isVendor}>
          <Box mt={2}>
            <Autocomplete.Control
              required
              valueAsId
              name="combiner_id"
              label="AC Combiner"
              loading={isFetching}
              options={combinerOptions}
              onChange={handlePanelChange}
              placeholder="Type to search for AC Combiner"
              rules={{ disabled: !isVendor || !combiner_enabled }}
            />
          </Box>
        </Collapse>

        <Center mt={2} justifyContent="space-between" gap={2}>
          <SelectField.Control
            disabled={isVendor}
            label="Bus Rating"
            displayEmpty={isVendor}
            name="combiner_data.bus_rating"
            options={BUS_RATING_LIST}
          />

          <SelectField.Control
            label="Main Breaker"
            name="combiner_data.breaker_rating"
            options={breakerOptions}
            disabled={isVendor && !panel}
          />
        </Center>
      </Collapse>

      <Delimiter mt={2} />
    </Box>
  );
};

export default CombinerPanelFields;
