import { forwardRef, lazy, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { dev } from 'constants';
import { propagateRefs } from 'hooks';
import { getArray, getFunc, rules as defaultRules } from 'utils';

import FormLabel from 'components/FormLabel';
import Button from 'components/Button';
import FormControl from 'components/FormControl';
import HelperText from 'components/HelperText';

const RadioButtons = forwardRef((props, ref) => {
  const {
    size,
    value,
    equal,
    onChange,
    onValue,
    options,
    spacing = 1,
    label,
    error,
    direction,
    helperText,
    disabled,
    required,
    hideAsterisk,
    onFocus,
    onBlur,
    requried,
    minWidth = 90,
    onBeforeChange,
    reserveHelperTextSpace = true,
    variant = 'outlined',
    ...rest
  } = props;

  const handleChange = useCallback(
    (button) => (e) => {
      const allowChange = getFunc(onBeforeChange)(e, button);

      if (allowChange === false) {
        return;
      }
      getFunc(onChange)(e, button);
      getFunc(onValue)(button.value);
    },
    [onChange, onValue, onBeforeChange]
  );

  return (
    <FormControl ref={ref} {...rest} error={error} disabled={disabled}>
      {label && (
        <FormLabel
          error={error}
          disabled={disabled}
          required={required}
          hideAsterisk={hideAsterisk}
        >
          {label}
        </FormLabel>
      )}
      <Box
        display="flex"
        gap={spacing}
        flexWrap="nowrap"
        flexDirection={direction}
      >
        {getArray(options).map((button) => {
          return (
            <Button
              size={size}
              minWidth={minWidth}
              key={button.value}
              name={button.value}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={handleChange(button)}
              variant={value === button.value ? 'contained' : variant}
              sx={{
                flexGrow: 1,
                flexBasis: !equal ? undefined : 100 / options.length,
                width: 'auto',
              }}
            >
              {button.label}
            </Button>
          );
        })}
      </Box>

      <HelperText
        error={error}
        disabled={disabled}
        reserveSpace={reserveHelperTextSpace}
      >
        {helperText}
      </HelperText>
    </FormControl>
  );
});

RadioButtons.Control = forwardRef((props, ref) => {
  const {
    name,
    onValue,
    required,
    onFocus,
    onBlur,
    rules: customRules,
    defaultValue = null,
    ...rest
  } = props;

  const { control } = useFormContext();

  const rules = useMemo(() => {
    const result = {
      ...customRules,
      validate: { ...customRules?.validate },
    };
    if (required) {
      result.required = defaultRules.required;
    }
    return result;
  }, [customRules, required]);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioButtons
          {...rest}
          required={required}
          value={field.value}
          ref={propagateRefs(ref, field.ref)}
          onValue={(...args) => {
            field.onChange(...args);
            getFunc(onValue)(...args);
          }}
          onBlur={(...args) => {
            field.onBlur(...args);
            getFunc(onBlur)(...args);
          }}
        />
      )}
    />
  );
});

if (dev) {
  RadioButtons.Demo = lazy(() => import('./RadioButtons.demo'));
}

export default RadioButtons;
