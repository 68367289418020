const useIsLoading = (...args) => {
  return args.some((a) => {
    if (typeof a === 'boolean') {
      return a;
    }
    return a?.isFetching || a?.isLoading;
  });
};

export default useIsLoading;
