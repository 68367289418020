import { Group } from 'paper';

import { diagram_width, diagram_height } from 'constants';
import { getArray, getFunc } from 'utils';
import { Paper } from 'components';

const caption_height = 80;
const plan_height = diagram_height - caption_height;

const {
  PaperView,
  PaperElement,
  PaperNote,
  PaperReference,
  PaperDropzone,
  PaperRuler,
  PaperCaption,
  PaperLayers,
} = Paper;

class LayersView extends PaperView {
  constructor(options) {
    super(options);

    this.layers = new PaperLayers(options);
    this.children.content.addChild(this.layers.element);
    this.layers.element.bounds.center = this.children.view_rect.bounds.center;
  }
}

class CaptionView extends PaperView {
  constructor(options) {
    super(options);

    this.caption = new PaperCaption({
      ...options,
      data: {
        page: '01',
        label: 'Site Plan',
      },
    });

    this.children.content.addChildren([this.caption.element]);
    this.caption.element.bounds.center.x =
      this.children.view_rect.bounds.center.x + 0.5;

    this.caption.element.bounds.center.y =
      this.children.view_rect.bounds.center.y + 0.5;
  }
}

class PlanView extends PaperView {
  constructor(options) {
    super(options);

    this.notes = {};
    this.items = {};
    this.layers_view = new LayersView(options);

    this.dropzone = new PaperDropzone({
      ...options,
      x: 0,
      y: 0,
      width: diagram_width,
      height: plan_height,
    });
    this.children.content.addChildren([
      this.layers_view.element,
      this.dropzone.element,
    ]);
  }

  addNote(note) {
    const step_x = 40;
    const step_y = 20;
    const start_x = diagram_width / 3;
    const start_y = diagram_height / 3;
    const offset = Object.values(this.notes).length;

    if (this.notes[note.id]) {
      return;
    }
    const noteInstance = new PaperNote({
      ...this.options,
      data: {
        ...note,
        x: start_x + step_x * offset,
        y: start_y + step_y * offset,
      },
    });
    this.notes[note.id] = noteInstance;
    this.children.content.addChild(noteInstance.element);
  }

  removeNote(id) {
    const instance = this.notes[id];
    delete this.notes[id];

    if (instance) {
      instance.destroy();
    }
  }

  addReference(ref) {
    if (this.items[ref.id]) {
      return;
    }
    const { onSelect, sticking, ...reference } = ref;

    const refInstance = new PaperReference({
      ...this.options,
      sticking,
      stickTo: this.layers_view.layers.element,
      onSelect,
      id: ref.id,
      parent: this.children.content,
      data: {
        ...reference,
      },
    });
    this.items[ref.id] = refInstance;
    this.children.content.addChild(refInstance.element);
    refInstance.select({ new: true });
  }

  addRuler(r) {
    if (this.items[r.id]) {
      return;
    }
    const { onSelect, onCreate, sticking, angleAlignment, ...ruler } = r;

    const rulerInstance = new PaperRuler({
      ...this.options,
      sticking,
      angleAlignment,
      stickTo: this.layers_view.layers.element,
      onSelect,
      onCreate,
      id: ruler.id,
      data: { ...ruler },
    });
    this.items[r.id] = rulerInstance;
    this.children.content.addChild(rulerInstance.element);
    rulerInstance.select();
  }

  removeItem(id) {
    const instance = this.items[id];
    delete this.items[id];

    if (instance) {
      instance.destroy();
    }
  }
}

class SitePlanController extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'main' });
    super({ element, ...options });

    this.handleItemSelect = this.handleItemSelect.bind(this);

    this.callbacks = {};
    this.updateNorthAngle = this.updateNorthAngle.bind(this);
    this.onCanvasMouseUp = this.onCanvasMouseUp.bind(this);
    this.scope.project.activeLayer.addChild(element);

    this.sticking = false;
    this.angleAlignment = false;

    this.caption = new CaptionView({
      width: diagram_width,
      height: caption_height,
      ...options,
    });
    this.plan = new PlanView({
      width: diagram_width,
      height: plan_height,
      ...options,
    });
    element.addChildren([this.plan.element, this.caption.element]);
    this.caption.element.bounds.topLeft.y = plan_height;

    this.scope.view.on('mouseup', this.onCanvasMouseUp);
  }

  updateNorthAngle(v) {
    this.caption.caption.compass.element.rotation = v;
    this.plan.layers_view.layers.element.rotation = v;
  }

  updateScaleMeta(data) {
    this.caption.caption.meta.update(data);
  }

  setLayers(layers, callback) {
    this.plan.layers_view.layers.setLayers(layers, callback);
  }

  setLayersScale(scale) {
    this.plan.layers_view.setScale(scale);
  }

  setLayersPosition(p) {
    this.plan.layers_view.layers.element.position = this.point(p);
  }

  togglePanTool(v) {
    this.plan.layers_view.layers.toggleDragging(v);
  }

  addNote(note) {
    this.plan.addNote(note);
  }

  removeNote(id) {
    this.plan.removeNote(id);
  }

  getNote(id) {
    return this.plan.notes[id];
  }

  getNotes() {
    return Object.values(this.plan.notes);
  }

  enableDropzone() {
    this.plan.dropzone.enable();
  }

  disableDropzone() {
    this.plan.dropzone.disable();
  }

  getDropPoint() {
    return this.plan.dropzone.drop_point;
  }

  addReference(ref) {
    this.plan.addReference({
      ...ref,
      sticking: this.sticking,
      angleAlignment: this.angleAlignment,
      onSelect: this.handleItemSelect,
    });
  }

  addRuler(ruler) {
    this.plan.addRuler({
      ...ruler,
      sticking: this.sticking,
      angleAlignment: this.angleAlignment,
      onSelect: this.handleItemSelect,
    });
  }

  getItem(id) {
    return this.plan.items[id];
  }

  getItems() {
    return Object.values(this.plan.items);
  }

  clearItems() {
    const ids = Object.keys(this.plan.items);

    ids.forEach((id) => {
      this.removeItem(id);
    });
  }

  removeItem(id) {
    this.plan.removeItem(id);
  }

  selectItem(id) {
    this.plan.items[id]?.select();
  }

  unselectItem(id) {
    if (id) {
      this.plan.items[id]?.unselect();
      return;
    }
    Object.values(this.plan.items).forEach((item) => {
      item.unselect();
    });
  }

  enableSticking() {
    this.sticking = true;

    Object.values(this.plan.items).forEach((item) => {
      item.enableSticking();
    });
  }

  disableSticking() {
    this.sticking = false;

    Object.values(this.plan.items).forEach((item) => {
      item.disableSticking();
    });
  }

  enableAngleAlignment() {
    this.angleAlignment = true;

    Object.values(this.plan.items).forEach((item) => {
      item.enableAngleAlignment();
    });
  }

  disableAngleAlignment() {
    this.angleAlignment = false;

    Object.values(this.plan.items).forEach((item) => {
      item.disableAngleAlignment();
    });
  }

  handleItemSelect(id) {
    if (id) {
      Object.entries(this.plan.items).forEach(([item_id, instance]) => {
        if (item_id !== id) {
          instance.unselect({ silent: true });
        }
      });
    }
    getArray(this.callbacks.select).forEach((cb) => {
      getFunc(cb)(id);
    });
  }

  onCanvasMouseUp() {
    getArray(this.callbacks.change).forEach((cb) => {
      cb();
    });
  }

  addListener(event, callback) {
    const listeners = this.callbacks[event] || [];
    listeners.push(callback);
    this.callbacks[event] = listeners;
  }

  removeListener(event, callback) {
    if (!callback) {
      delete this.callbacks[event];
      return;
    }
    this.callbacks[event] = getArray(this.callbacks[event]).filter(
      (cb) => cb !== callback
    );
  }

  disableEdit() {
    this.getNotes().forEach((n) => {
      n.disableEdit();
    });
    this.getItems().forEach((item) => {
      item.disableEdit();
    });
  }

  enableEdit() {
    this.getNotes().forEach((n) => {
      n.enableEdit();
    });
    this.getItems().forEach((item) => {
      item.enableEdit();
    });
  }

  getData() {
    const notes = this.getNotes();
    const layersPosition = this.plan.layers_view.layers.element.position;

    const data = {
      notes: Object.values(notes).map((noteInstance) => ({
        id: noteInstance.data.id,
        type: noteInstance.data.type,
        text: noteInstance.data.text,
        x: noteInstance.data.x,
        y: noteInstance.data.y,
      })),
      references: this.getItems()
        .filter((item) => item.data.type !== 'ruler')
        .map((item) => ({
          id: item.data.id,
          type: item.data.type,
          label: item.data.label,
          custom: !!item.data.custom,
          position: item.data.position,
          segments: item.data.segments,
          x: item.data.x,
          y: item.data.y,
          placed: true,
          value: item.data.id,
        })),
      rulers: this.getItems()
        .filter((item) => item.data.type === 'ruler')
        .map((r) => ({
          id: r.data.id,
          height: r.data.height,
          scale: r.data.scale,
          start: {
            x: r.data.start.x,
            y: r.data.start.y,
          },
          end: {
            x: r.data.end.x,
            y: r.data.end.y,
          },
          label: {
            anchor: r.data.label.anchor,
            position: r.data.label.position,
            text: r.data.label.text,
          },
        })),
      layers: {
        position: {
          x: layersPosition.x,
          y: layersPosition.y,
        },
      },
    };

    return data;
  }

  destroy() {
    if (this.scope?.project?.activeLayer) {
      this.scope.project.activeLayer.removeChildren();
    }
  }
}

export default SitePlanController;
