import { useWatch, useFormContext } from 'react-hook-form';

import { AhjField } from 'views';

const AhjSearch = (props) => {
  const { name } = props;
  const { control } = useFormContext();
  const address = useWatch({ control, name: 'address' });

  return (
    <AhjField.Control
      required
      name={name}
      label="AHJ"
      state={address?.state}
      forcePopupIcon={false}
      placeholder="Type to search AHJ"
    />
  );
};

export default AhjSearch;
