import { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';

import { ROLES } from 'constants';
import { withProps } from 'hooks';
import { Access } from 'views';
import { Slip } from 'components';

import ChangePassword from './ChangePassword';
import ProfileInformation from './ProfileInformation';
import OrganizationInformation from './OrganizationInformation';

const gap = 3.5;
const minHeight = 454;
const orgHeight = 290;

const Card = withProps(Slip, {
  p: 4,
});

const Profile = () => {
  const theme = useTheme();

  const pasHeight = useMemo(
    () => minHeight - orgHeight - theme.shape.spacing * gap,
    [theme]
  );

  return (
    <Box gap={gap} display="flex" alignItems="flex-start">
      <Card minHeight={minHeight} flexGrow={1} width={0.5}>
        <ProfileInformation />
      </Card>

      <Box
        width={0.5}
        gap={gap}
        flexGrow={1}
        display="flex"
        minHeight={minHeight}
        flexDirection="column"
      >
        <Access role={ROLES.OWNER}>
          <Card minHeight={orgHeight}>
            <OrganizationInformation />
          </Card>
        </Access>

        <Card minHeight={pasHeight}>
          <ChangePassword />
        </Card>
      </Box>
    </Box>
  );
};

export default Profile;
