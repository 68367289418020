import { Grid } from '@mui/material';

import { useDuplicateProjectMutation } from 'store';
import { PROJECTS_VIEW, PROJECTS_CACHE_KEY } from 'constants';
import { useToggle } from 'hooks';
import { ProjectCard } from 'views';
import { ProgressBox } from 'components';

import CreateProjectModal from './CreateProjectModal';

const { GRID } = PROJECTS_VIEW;

const ProjectsList = (props) => {
  const { view, projects, loading } = props;

  const [createProject, toggleCreateProject] = useToggle();
  const [, duplicateProjectState] = useDuplicateProjectMutation({
    fixedCacheKey: PROJECTS_CACHE_KEY,
  });

  const size = view === GRID ? 4 : 12;
  const progress = loading || duplicateProjectState.isLoading;

  return (
    <>
      {/* MODALS */}
      <>
        <CreateProjectModal
          open={createProject}
          onClose={toggleCreateProject.off}
        />
      </>

      <ProgressBox progress={progress} type="blur" size="xlarge">
        <Grid container columnSpacing={4} rowSpacing={3}>
          <Grid item xs={size}>
            <ProjectCard create view={view} onClick={toggleCreateProject.on} />
          </Grid>

          {projects.map((project) => (
            <Grid item xs={size} key={project.id}>
              <ProjectCard view={view} project={project} />
            </Grid>
          ))}
        </Grid>
      </ProgressBox>
    </>
  );
};

export default ProjectsList;
