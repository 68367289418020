import { Controller, useWatch, useFormContext } from 'react-hook-form';

import LocationImagePreview from './LocationImagePreview';

const PropertyInformationPreview = (props) => {
  const { previewApiRef, name } = props;
  const { control } = useFormContext();
  const address = useWatch({ control, name: 'address' });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocationImagePreview
          address={address}
          image={field.value}
          apiRef={previewApiRef}
          onChange={field.onChange}
        />
      )}
    />
  );
};

export default PropertyInformationPreview;
