import { useFormContext, useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Icon, Ref } from 'components';

const LocateButton = (props) => {
  const { ...rest } = props;

  const { control } = useFormContext();
  const address = useWatch({ control, name: 'address' });

  const longitude = address?.longitude;
  const latitude = address?.latitude;
  const disabled = !longitude || !latitude;

  return (
    <Ref.Container
      gap={1}
      top={-4}
      display="inline-flex"
      alignItems="center"
      position="relative"
      color={disabled ? 'border.main' : 'secondary.main'}
      sx={{
        pointerEvents: disabled ? 'none' : undefined,
      }}
      {...rest}
    >
      <Icon.Location />

      <Ref>
        <Typography variant="body2">Locate</Typography>
      </Ref>
    </Ref.Container>
  );
};

export default LocateButton;
