export const PANEL_TYPES = {
  PITCHED: 'pitched',
  FLAT: 'flat',
  GROUND: 'ground',
  POLE: 'pole',
};
export const PANEL_TYPES_NAMES = {
  [PANEL_TYPES.PITCHED]: 'Pitched',
  [PANEL_TYPES.FLAT]: 'Flat',
  [PANEL_TYPES.GROUND]: 'Ground',
  [PANEL_TYPES.POLE]: 'Pole',
};
export const PANEL_TYPES_LIST = [
  PANEL_TYPES.PITCHED,
  PANEL_TYPES.FLAT,
  PANEL_TYPES.GROUND,
  PANEL_TYPES.POLE,
].map((value) => ({
  value,
  label: PANEL_TYPES_NAMES[value],
}));
