import { forwardRef, lazy, useMemo } from 'react';
import { Box, IconButton, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Icon } from 'components';
import { dev } from 'constants';

const CloseButton = styled(Box, {
  label: 'CloseButton',
})(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(50%, -50%)',
  transition: theme.transitions.create(),
}));

const Root = styled(Box, {
  label: 'ReferenceItem',
  shouldForwardProp: (prop) => !['placeholder'].includes(prop),
})(({ theme, placeholder }) => ({
  fontSize: 12,
  fontFamily: 'ArialNarrow',
  display: 'inline-flex',
  flexGrow: 1,
  textTransform: 'uppercase',
  gap: 1,
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  width: '100%',
  position: 'relative',
  userSelect: 'none',
  transition: theme.transitions.create('outline-color'),
  outlineWidth: 2,
  outlineStyle: 'dashed',
  outlineColor: placeholder
    ? alpha(theme.palette.border.light, 0.5)
    : theme.palette.border.main,
  '&:hover': {
    outlineColor: placeholder
      ? theme.palette.border.light
      : theme.palette.border.dark,

    [`${CloseButton}`]: {
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
  },
  [`${CloseButton}`]: {
    opacity: 0.5,
  },
}));

const ReferenceItem = forwardRef((props, ref) => {
  const {
    label,
    children,
    onRemove,
    placeholder,
    allowRemove,
    color: initialColor = 'primary.main',
    Icon: IconComponent = Icon.AngularArrow,
    ...rest
  } = props;

  const color = placeholder ? 'transparent' : initialColor;

  const content = useMemo(() => {
    if (Array.isArray(label)) {
      return label;
    }
    if (label) {
      return [label];
    }
    return [];
  }, [label]);

  return (
    <Root
      ref={ref}
      py={1}
      pl={0.5}
      pr={1.5}
      color={color}
      placeholder={placeholder}
      {...rest}
    >
      {IconComponent && (
        <IconComponent
          sx={{ color: placeholder ? 'transparent' : 'border.main' }}
        />
      )}

      <div>
        {content.map((text, i) => (
          <Box component="div" whiteSpace="nowrap" mr={0.5} key={i}>
            {text}
          </Box>
        ))}

        {children}
      </div>

      {allowRemove && (
        <CloseButton>
          <IconButton
            size="small"
            onClick={onRemove}
            sx={(theme) => ({
              color: 'primary.light',
              transition: theme.transitions.create(),
              '&:hover': {
                color: 'primary.main',
              },
            })}
          >
            <Icon.Close fontSize="small" />
          </IconButton>
        </CloseButton>
      )}
    </Root>
  );
});

if (dev) {
  ReferenceItem.Demo = lazy(() => import('./ReferenceItem.demo'));
}

export default ReferenceItem;
