import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Collapse, Stack as MuiStack } from '@mui/material';

import { useAuth, useWatchField, withProps } from 'hooks';
import { getArray } from 'utils';
import { AdvancedSliderField, ProjectSubmitButton } from 'views';
import { Button, ProgressBox, SwitchField, TextField } from 'components';

const Stack = withProps(MuiStack, {
  width: 1,
  direction: 'column',
  alignItems: 'flex-start',
});

const PlacardsForm = () => {
  const {
    allowChange,
    api,
    canvasChanges,
    changeHandler,
    isFirstSaving,
    loading,
    nextStep,
    onCalloutsChange,
    onEquipmentsChange,
    onViewChange,
    setValue,
    view,
  } = useFormContext();

  const { premium } = useAuth();

  const plan_size = useWatchField('plan_size');
  const north_angle = useWatchField('north_angle');
  const caution_message = useWatchField('caution_message');

  const [commitData, setCommitData] = useState(null);

  const handleCancelEdit = useCallback(() => {
    api.discardEdit();
    api.setHousePlacardsMode('preview');
    onViewChange('main');

    if (commitData) {
      setValue('plan_size', commitData.plan_size);
      setValue('north_angle', commitData.north_angle);
    }
    const refs = getArray(api.getCommitData().references);

    onCalloutsChange((prev) => {
      return prev.map((cal) => {
        const canvasItem = refs.find((ref) => ref.id === cal.id);

        return {
          ...cal,
          placed: !!canvasItem,
        };
      });
    });
    onEquipmentsChange((prev) => {
      return prev.map((eq) => {
        const canvasItem = refs.find((ref) => ref.id === eq.id);

        return {
          ...eq,
          placed: !!canvasItem,
        };
      });
    });
  }, [
    onViewChange,
    api,
    commitData,
    setValue,
    onCalloutsChange,
    onEquipmentsChange,
  ]);

  const handleSaveEdit = useCallback(() => {
    api.commitEdit();
    api.setHousePlacardsMode('preview');
    onViewChange('main');
    setCommitData(null);
  }, [api, onViewChange]);

  const handleAngleValue = useCallback(
    (e, v) => {
      if (!allowChange) {
        changeHandler(setValue)('north_angle', v);
        return false;
      }
    },
    [allowChange, changeHandler, setValue]
  );

  const handleScaleValue = useCallback(
    (e, v) => {
      if (!allowChange) {
        changeHandler(setValue)('plan_size', v);
        return false;
      }
    },
    [changeHandler, allowChange, setValue]
  );

  const handleElevationView = useCallback(
    (v) => {
      v ? api.showElevation() : api.hideElevation();
    },
    [api]
  );

  // Commit current form data before edit
  useEffect(() => {
    if (view === 'house_placards' && !commitData) {
      setCommitData({ plan_size, north_angle });
    }
  }, [view, plan_size, north_angle, commitData]);

  // Update plan_size
  useEffect(() => {
    if (api) {
      api.setLayersScale(plan_size);
    }
  }, [plan_size, api]);

  // Update north angle
  useEffect(() => {
    if (api) {
      api.updateNorthAngle(north_angle);
    }
  }, [api, north_angle]);

  // Update caution message
  useEffect(() => {
    if (api) {
      api.setCautionText(caution_message);
    }
  }, [api, caution_message]);

  return (
    <ProgressBox progress={loading}>
      <Collapse in={view === 'main'}>
        <Stack spacing={2}>
          {premium && (
            <SwitchField.Control name="house_placard" label="House Placard" />
          )}
          <SwitchField.Control
            name="equipment_elevation"
            label="Equipment Elevation"
            onValue={handleElevationView}
          />

          {premium && (
            <TextField.Control
              multiline
              minRows={5}
              maxRows={10}
              name="caution_message"
              label="Caution Message"
            />
          )}
        </Stack>

        <Box mt={4}>
          <ProjectSubmitButton
            nextStep={nextStep}
            isFirstSaving={isFirstSaving}
            allowContinue={!canvasChanges}
          />
        </Box>
      </Collapse>

      <Collapse in={view === 'house_placards'}>
        <Stack spacing={3}>
          <AdvancedSliderField.Control
            angular
            max={360}
            name="north_angle"
            label="North Mark Rotation"
            onBeforeChange={handleAngleValue}
          />

          <AdvancedSliderField.Control
            max={2}
            min={0.5}
            step={0.01}
            name="plan_size"
            label="Plan Size"
            onBeforeChange={handleScaleValue}
          />

          <Stack spacing={1}>
            <Button fullWidth variant="outlined" onClick={handleCancelEdit}>
              Cancel
            </Button>

            <Button fullWidth onClick={handleSaveEdit}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Collapse>
    </ProgressBox>
  );
};

export default PlacardsForm;
