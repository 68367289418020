import { lazy, useCallback, useEffect } from 'react';
import { Box, Grid } from '@mui/material';

import { getFunc } from 'utils';
import { dev } from 'constants';
import StatesField from 'views/StatesField';
import { Button, Center, Form, Modal, TextField } from 'components';

const ManualAddressModal = (props) => {
  const { open, address, onClose, onSubmit, coords, coordsRequired, ...rest } =
    props;

  const form = Form.useForm();
  const { isValid } = form.formState;

  useEffect(() => {
    if (open) {
      form.reset({ ...address });
    }
  }, [open, form, address]);

  const handleSubmit = useCallback(
    (formData) => {
      getFunc(onSubmit)(formData);
      getFunc(onClose)();
    },
    [onSubmit, onClose]
  );

  return (
    <Modal
      {...rest}
      open={open}
      onClose={onClose}
      title="Enter Address Manually"
    >
      <Form form={form} onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField.Control
            required
            initialFocus={!!open}
            name="address_line1"
            label="Address Line #1"
          />

          <TextField.Control name="address_line2" label="Address Line #2" />

          <TextField.Control required name="city" label="City" />

          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={6}>
              <TextField.Control required name="zip_code" label="ZIP" />
            </Grid>

            <Grid item xs={6}>
              <StatesField.Control required name="state" label="State" />
            </Grid>

            {coords && (
              <>
                <Grid item xs={6}>
                  <TextField.Control
                    type="number"
                    name="latitude"
                    label="Latitude"
                    required={!!coordsRequired}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField.Control
                    type="number"
                    name="longitude"
                    label="Longitude"
                    required={!!coordsRequired}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        <Center mt={5}>
          <Button
            type="submit"
            minWidth={165}
            fullWidth={false}
            disabled={!isValid}
          >
            Save
          </Button>
        </Center>
      </Form>
    </Modal>
  );
};

if (dev) {
  ManualAddressModal.Demo = lazy(() => import('./ManualAddressModal.demo'));
}

export default ManualAddressModal;
