import { emailRegex } from './regex';
import { getString } from './helpers';

export const validateEmail = (v) => {
  const val = getString(v);
  return val.match(emailRegex);
};

export const validatePasswordLength = (v) => {
  const val = getString(v);
  return val.length >= 8;
};

export const validatePasswordNumbers = (v) => {
  const val = getString(v);
  const numsInString = val.replace(/\D/g, '');
  return numsInString.length >= 2;
};

export const validatePasswordRegister = (v) => {
  const val = getString(v);
  const hasUpperCase = /[A-Z]/.test(val);
  const hasLowerCase = /[a-z]/.test(val);
  return hasUpperCase && hasLowerCase;
};

export const validatePassword = (v) => {
  return validatePasswordLength(v);
};
