import { lazy, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Stack, Typography } from '@mui/material';

import {
  useCreateContractorCompanyMutation,
  useUploadToTemporaryStorageMutation,
  useUpdateCompanyDetailsMutation,
  useRemoveCompanyImageMutation,
  useUpdateCompanyProfileImageMutation,
} from 'store';

import { routes } from 'routes';
import { dev } from 'constants';
import UploadAvatar from 'views/UploadAvatar';
import { emptyFieldsToNulls, filterObjectFields } from 'utils';
import {
  Button,
  Form,
  Icon,
  PhoneField,
  ProgressBox,
  Ref,
  TextField,
  TinyButton,
  useMessage,
} from 'components';

import ContractorCompanyAddress from './ContractorCompanyAddress';

const CompanyDetails = (props) => {
  const {
    company,
    readOnly,
    back = -1,
    loading: outerLoading,
    ...rest
  } = props;

  const m = useMessage();
  const form = Form.useForm();
  const { isValid } = form.formState;
  const navigate = useNavigate();

  const [createCompany, { isLoading }] = useCreateContractorCompanyMutation();
  const [uploadFile, { isLoading: fileLoading }] =
    useUploadToTemporaryStorageMutation();

  const [updateCompany, { isLoading: updateLoading }] =
    useUpdateCompanyDetailsMutation();

  const [removeImage, { isLoading: removeLoading }] =
    useRemoveCompanyImageMutation();

  const [updateImage, { isLoading: updateImageLoading }] =
    useUpdateCompanyProfileImageMutation();

  const loading =
    isLoading ||
    fileLoading ||
    outerLoading ||
    updateLoading ||
    removeLoading ||
    updateImageLoading;

  useEffect(() => {
    if (company) {
      form.reset({
        images: [company.image].filter(Boolean).map((url) => ({ url })),
        name: company.name,
        phone: company.phone,
        license: company.license,
        pm_name: company.pm_name,
        pm_phone: company.pm_phone,
        address: company.address,
      });
    }
  }, [form, company, readOnly]);

  const handleSubmitNew = useCallback(
    async (formData) => {
      try {
        const { images, ...restData } = formData;
        const [file] = images;
        const body = {
          ...emptyFieldsToNulls(restData),
          address: filterObjectFields(restData.address, [
            'city',
            'state',
            'zip_code',
            'address_line1',
            'address_line2',
          ]),
        };
        if (file) {
          const response = await uploadFile({
            body: { file },
          }).unwrap();

          body.image = response.file_key;
        }
        await createCompany({ body }).unwrap();
        m.success('Company has been created!');
        navigate(back || -1);
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, uploadFile, createCompany, navigate, back]
  );

  const handleUpdateExisting = useCallback(
    async (formData) => {
      try {
        const company_id = company.id;
        const skipInvalidation = true;
        const { images, ...restData } = formData;
        const [file] = images;
        const body = {
          ...emptyFieldsToNulls(restData),
          address: filterObjectFields(restData.address, [
            'city',
            'state',
            'zip_code',
            'address_line1',
            'address_line2',
          ]),
        };

        const isRemovedImage = !!(company.image && !file);
        const isNewImage = file instanceof File;

        if (isRemovedImage) {
          await removeImage({ company_id, skipInvalidation }).unwrap();
        }
        if (isNewImage) {
          await updateImage({
            company_id,
            skipInvalidation,
            body: { file },
          }).unwrap();
        }
        await updateCompany({ company_id, body }).unwrap();
        m.success('Company details has been updated!');
        navigate(back);
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [company, m, removeImage, updateImage, updateCompany, navigate, back]
  );

  const handleSubmit = useCallback(
    (formData) => {
      if (!company && !readOnly) {
        return handleSubmitNew(formData);
      }
      if (company && !readOnly) {
        return handleUpdateExisting(formData);
      }
    },
    [handleSubmitNew, company, readOnly, handleUpdateExisting]
  );

  return (
    <ProgressBox progress={loading} {...rest}>
      <Form form={form} onSubmit={handleSubmit}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <UploadAvatar.Control
            readOnly={readOnly}
            name="images"
            label="Logo"
            placeholder="Upload Logo"
            direction="row"
            shape="square"
            size={15}
            initialFocus
            avatarProps={{
              objectFit: 'contain',
              bgcolor: 'transparent',
              sx: {
                mr: 2,
                borderColor: (theme) => theme.palette.border.main,
              },
            }}
          />

          {!!(readOnly && company) && (
            <TinyButton
              nostyles
              variant="flat"
              component={Ref}
              to={routes.edit_company.path({ company_id: company?.id })}
            >
              <Icon.Pencil />
            </TinyButton>
          )}
        </Box>

        <Grid mt={1} container columnSpacing={4} rowSpacing={3}>
          <Grid item xs={4}>
            <TextField.Control
              required
              name="name"
              label="Company Name"
              readOnly={readOnly}
            />
          </Grid>

          <Grid item xs={4}>
            <PhoneField.Control
              required
              name="phone"
              label="Company Phone"
              readOnly={readOnly}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField.Control
              required
              name="license"
              label="License Number"
              readOnly={readOnly}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField.Control
              name="pm_name"
              label="Project Manager Full Name"
              readOnly={readOnly}
            />
          </Grid>

          <Grid item xs={4}>
            <PhoneField.Control
              name="pm_phone"
              label="Project Manager Phone Number"
              readOnly={readOnly}
            />
          </Grid>
        </Grid>

        <Box
          pt={3}
          mt={2}
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.border.light}`,
          }}
        >
          <Typography variant="subtitle4">
            Contractor Company Address
          </Typography>

          <Box mt={3}>
            <ContractorCompanyAddress readOnly={readOnly} />
          </Box>

          {!readOnly && (
            <Stack direction="row" spacing={2} mt={3} pb={1}>
              <Button
                replace
                nostyles
                to={back}
                component={Ref}
                fullWidth={false}
                variant="outlined"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                fullWidth={false}
                minWidth={200}
                disabled={!isValid}
              >
                Save
              </Button>
            </Stack>
          )}
        </Box>
      </Form>
    </ProgressBox>
  );
};

if (dev) {
  CompanyDetails.Demo = lazy(() => import('./CompanyDetails.demo'));
}

export default CompanyDetails;
