import { forwardRef, lazy, useCallback } from 'react';
import { styled, Box, ClickAwayListener } from '@mui/material';

import { getFunc } from 'utils';
import { dev } from 'constants';

const Root = styled(Box, {
  label: 'ProjectPreview',
  shouldForwardProp: (prop) => !['fullHeight'].includes(prop),
})(({ theme, fullHeight }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: fullHeight ? '100%' : undefined,
  borderRadius: theme.radius(1),
  backgroundColor: theme.palette.common.white,
}));

const Toolbox = styled(Box, {
  label: 'PreviewToolBox',
})(({ theme }) => ({
  height: 54,
  minHeight: 54,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopLeftRadius: theme.radius(1),
  borderTopRightRadius: theme.radius(1),
  border: `1px solid ${theme.palette.border.light}`,
}));

const ProjectPreview = forwardRef((props, ref) => {
  const {
    tools,
    children,
    hideToolbox,
    onClickAway,
    fullHeight = true,
  } = props;

  const handleClickAway = useCallback(
    (...args) => {
      getFunc(onClickAway)(...args);
    },
    [onClickAway]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Root ref={ref} fullHeight={fullHeight}>
        {!hideToolbox && <Toolbox>{tools}</Toolbox>}

        {children}
      </Root>
    </ClickAwayListener>
  );
});

if (dev) {
  ProjectPreview.Demo = lazy(() => import('./ProjectPreview.demo'));
}

export default ProjectPreview;
