import { forwardRef, lazy, useCallback, useMemo } from 'react';

import { STATES, dev } from 'constants';
import { getString, getFunc } from 'utils';
import { Autocomplete } from 'components';

const StatesField = forwardRef((props, ref) => {
  const { value, onChange, onValue, ...rest } = props;

  const handleChange = useCallback(
    (e, val, reason, details) => {
      getFunc(onChange)(e, val, reason, details);
      getFunc(onValue)(val?.value);
    },
    [onChange, onValue]
  );

  const currentValue = useMemo(
    () => STATES.find((state) => state.value === value) || null,
    [value]
  );

  return (
    <Autocomplete
      ref={ref}
      {...rest}
      value={currentValue}
      onChange={handleChange}
      options={STATES}
      getOptionLabel={(opt) => getString(opt?.label)}
      isOptionEqualToValue={(opt, val) => opt?.value === val?.value}
    />
  );
});

StatesField.Control = forwardRef((props, ref) => {
  const { ...rest } = props;

  return (
    <Autocomplete.Control
      ref={ref}
      {...rest}
      ControlComponent={StatesField}
      controlChangeHandler={(field) => (e, val, reason, details) => {
        field.onChange(val?.value || null);
      }}
    />
  );
});

if (dev) {
  StatesField.Demo = lazy(() => import('./StatesField.demo'));
}

export default StatesField;
