import { forwardRef, lazy } from 'react';
import {
  Button as MuiButton,
  buttonClasses as classes,
  styled,
} from '@mui/material';

import { dev } from 'constants';

const Root = styled(MuiButton, {
  shouldForwardProp: (prop) =>
    !['minWidth', 'flat', 'dashed', 'shadow', 'fontColor'].includes(prop),
})(({ theme, minWidth, flat, dashed, shadow, color, fontColor }) => ({
  [`&.${classes.root}`]: {
    minWidth,
    boxShadow: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette[fontColor]?.main || fontColor,
    textDecoration: 'unset !important',

    [`&.${classes.sizeLarge}`]: {
      height: 48,
    },
    [`&.${classes.sizeMedium}`]: {
      height: 40,
      ...theme.typography.body2,
    },
    [`&.${classes.contained}`]: {
      color: theme.palette.common.white,
      '&:hover': {
        boxShadow: theme.makeShadow(2, theme.palette[color].main, 1),
        backgroundColor: theme.palette[color].main,
      },
      '&:focus': {
        backgroundColor: theme.palette[color].dark,
      },

      [`&.${classes.disabled}`]: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.colors.disabled_background,
      },
    },
    [`&.${classes.outlined}`]: {
      backgroundColor: theme.palette.common.white,
      boxShadow: !flat ? undefined : theme.makeShadow(shadow),
      borderColor: flat ? 'white' : undefined,
      borderStyle: dashed ? 'dashed' : undefined,
      textDecoration: dashed ? 'underline' : undefined,

      '&:hover': {
        borderColor: flat
          ? theme.palette.colors.hover_background
          : theme.palette[color].main,
        backgroundColor: dashed
          ? undefined
          : theme.palette.colors.hover_background,
      },
      '&:focus': {
        borderColor: flat
          ? theme.palette.common.white
          : theme.palette[color].dark,
        backgroundColor: theme.palette.common.white,
      },

      [`&.${classes.disabled}`]: {
        color: theme.palette.border.main,
        borderColor: theme.palette.colors.border_hover,
      },
    },
  },
}));

const Button = forwardRef((props, ref) => {
  const {
    minWidth = 120,
    size = 'large',
    color = 'secondary',
    variant = 'contained',
    fullWidth = true,
    fontColor = 'primary',
    // works only for variant === 'flat'
    shadow = 5,
    ...rest
  } = props;

  const flat = variant === 'flat';
  const dashed = variant === 'dashed';
  const buttonVariant = ['flat', 'dashed'].includes(variant)
    ? 'outlined'
    : variant;

  return (
    <Root
      ref={ref}
      {...rest}
      size={size}
      color={color}
      flat={flat}
      dashed={dashed}
      fontColor={fontColor}
      variant={buttonVariant}
      minWidth={minWidth}
      fullWidth={fullWidth}
      shadow={shadow}
    />
  );
});

if (dev) {
  Button.Demo = lazy(() => import('./Button.demo'));
}

export const buttonClasses = classes;

export default Button;
