import GoogleMap from 'google-map-react';
import { Box } from '@mui/material';

import {
  property_image_width,
  property_image_height,
  google_api_key,
} from 'constants';

import { Center } from 'components';

import Marker from './Marker';

const zoomMin = 16;
const zoomMax = 20;

const defaultMapCenter = {
  lat: null,
  lng: null,
};

const defaultMapOptions = (maps) => ({
  zoomMax,
  zoomMin,
  clickableIcons: false,
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  zoomControl: false,
  scrollwheel: false,
  fullscreenControl: false,
  mapTypeId: maps.MapTypeId.SATELLITE,
  panControl: false,
  rotateControl: false,
  streetViewControl: false,
  tilt: 0,
});

const defaultMapKeys = {
  key: google_api_key,
  language: 'en',
  libraries: ['places'],
};

const MapView = (props) => {
  const { center, onChange, marker, zoom } = props;

  return (
    <Center flexGrow={1}>
      <Box width={property_image_width} height={property_image_height}>
        <GoogleMap
          zoom={zoom}
          center={center || defaultMapCenter}
          onChange={onChange}
          options={defaultMapOptions}
          bootstrapURLKeys={defaultMapKeys}
        >
          {marker && <Marker {...marker} />}
        </GoogleMap>
      </Box>
    </Center>
  );
};

export default MapView;
