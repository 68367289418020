const PROPERTY_INFORMATION = 'PropertyInformation';
const DESIGN_CRITERIA = 'SiteDesignCriteria';
const CONTRACTOR_COMPANY = 'ContractorCompany';
const PRELIMINARY_PLAN = 'PreliminaryPlan';
const ARRAY_PARAMETERS = 'ArrayParameters';
const PV_SYSTEM = 'PVSystem';
const INTERCONNECTION = 'Interconnection';
const SYSTEM_BALANCE = 'SystemBalance';
const WIRE_TABLE = 'WireTable';
const LINE_DIAGRAM = 'LineDiagram';
const ELECTRICAL_TABLES = 'ElectricalTables';
const PLACARDS = 'Placards';
const SITE_PLAN = 'SitePlan';
const ELECTRICAL_PLAN = 'ElectricalPlan';
const STRUCTURES = 'Structures';
const DATASHEETS = 'Datasheets';

export const REQUIREMENTS_GROUPS = {
  PROPERTY_INFORMATION,
  DESIGN_CRITERIA,
  CONTRACTOR_COMPANY,
  PRELIMINARY_PLAN,
  ARRAY_PARAMETERS,
  PV_SYSTEM,
  INTERCONNECTION,
  SYSTEM_BALANCE,
  WIRE_TABLE,
  LINE_DIAGRAM,
  ELECTRICAL_TABLES,
  PLACARDS,
  SITE_PLAN,
  ELECTRICAL_PLAN,
  STRUCTURES,
  DATASHEETS,
};

export const REQUIREMENTS_GROUPS_NAMES = {
  [`${PROPERTY_INFORMATION}`]: 'Property Information',
  [`${DESIGN_CRITERIA}`]: 'Design Criteria',
  [`${CONTRACTOR_COMPANY}`]: 'Contractor Company',
  [`${PRELIMINARY_PLAN}`]: 'Preliminary Plan',
  [`${ARRAY_PARAMETERS}`]: 'Array Parameters',
  [`${PV_SYSTEM}`]: 'PV System',
  [`${INTERCONNECTION}`]: 'Interconnection',
  [`${SYSTEM_BALANCE}`]: 'System Balance',
  [`${WIRE_TABLE}`]: 'Wire Table',
  [`${LINE_DIAGRAM}`]: 'Line Diagram',
  [`${ELECTRICAL_TABLES}`]: 'Electrical Tables',
  [`${SITE_PLAN}`]: 'Site Plan',
  [`${ELECTRICAL_PLAN}`]: 'Electrical Plan',
  [`${PLACARDS}`]: 'Placards',
  [`${STRUCTURES}`]: 'Structures',
  [`${DATASHEETS}`]: 'Datasheets',
};

export const REQUIREMENTS_GROUPS_LIST = [
  PROPERTY_INFORMATION,
  DESIGN_CRITERIA,
  CONTRACTOR_COMPANY,
  PRELIMINARY_PLAN,
  ARRAY_PARAMETERS,
  PV_SYSTEM,
  INTERCONNECTION,
  SYSTEM_BALANCE,
  WIRE_TABLE,
  LINE_DIAGRAM,
  ELECTRICAL_TABLES,
  PLACARDS,
  SITE_PLAN,
  ELECTRICAL_PLAN,
  STRUCTURES,
  DATASHEETS,
].map((value) => ({
  value,
  label: REQUIREMENTS_GROUPS_NAMES[value],
}));
