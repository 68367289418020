import { lazy, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { dev } from 'constants';
import { Button } from 'components';

const ProjectSubmitButton = (props) => {
  const {
    isFirstSaving,
    nextStep,
    ignoreIsValid,
    allowContinue = true,
    debug,
    disabled: nativeDisabled,
    label: customLabel = 'Save and Continue',
  } = props;

  const navigate = useNavigate();
  const { formState } = useFormContext();
  const { isDirty, isValid, dirtyFields } = formState;

  const isDirtyFields = useMemo(() => {
    return Object.values(dirtyFields).length > 0;
  }, [dirtyFields]);

  const isTrullyDirty = isDirty || isDirtyFields;

  const isContinue = [
    !debug,
    !isFirstSaving,
    !isTrullyDirty,
    allowContinue,
  ].every(Boolean);

  const disabledContitions = [!debug, !isContinue, !isValid, !ignoreIsValid];
  const disabled =
    disabledContitions.every(Boolean) ||
    (!isContinue && !isTrullyDirty && isValid);

  const label = isContinue ? 'Continue' : customLabel;
  const type = !isContinue ? 'submit' : 'button';

  const handleClick = useCallback(() => {
    if (isContinue && nextStep && !debug) {
      navigate(nextStep);
    }
  }, [isContinue, navigate, nextStep, debug]);

  return (
    <Button
      type={type}
      disabled={disabled || nativeDisabled}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

if (dev) {
  ProjectSubmitButton.Demo = lazy(() => import('./ProjectSubmitButton.demo'));
}

export default ProjectSubmitButton;
