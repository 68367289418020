import { forwardRef, lazy, useCallback, useEffect, useRef } from 'react';
import { Box, ClickAwayListener, Collapse } from '@mui/material';

import { useCombineRefs, useToggle } from 'hooks';
import { getFunc } from 'utils';
import { dev } from 'constants';
import { ConfirmTextField } from 'components';

const QuickConfirmTextField = forwardRef((props, ref) => {
  const {
    open,
    onClose,
    orientation,
    maxWidth,
    minWidth = 150,
    onEntered,
    value,
    inputRef,
    reserveHelperTextSpace = false,
    onCancel,
    onConfirm,
    timeout = 100,
    confirmDisabled,
    ...rest
  } = props;

  const innerInputRef = useRef();
  const handleInputRef = useCombineRefs(inputRef, innerInputRef);
  const [visible, toggleVisible] = useToggle();

  const isConfirmDisabled =
    typeof confirmDisabled === 'boolean' ? confirmDisabled : !value;

  const handleClose = useCallback(() => {
    if (open && visible) {
      toggleVisible.off();
      onClose();
    }
  }, [open, visible, onClose, toggleVisible]);

  const handleEntered = useCallback(
    (...args) => {
      getFunc(onEntered)(...args);
      innerInputRef.current?.focus();
      toggleVisible.on();
    },
    [onEntered, toggleVisible]
  );

  const handleCancel = useCallback(() => {
    getFunc(onCancel)();
    handleClose();
  }, [handleClose, onCancel]);

  const handleConfirm = useCallback(() => {
    if (!isConfirmDisabled) {
      getFunc(onConfirm)(value);
      toggleVisible.off();
      handleClose();
    }
  }, [onConfirm, toggleVisible, isConfirmDisabled, handleClose, value]);

  // hide input on esc
  useEffect(() => {
    if (open && visible) {
      const listenEsc = (e) => {
        if (e.keyCode === 27) {
          e.preventDefault();
          e.stopPropagation();
          handleCancel();
        }
        if (e.keyCode === 13) {
          e.preventDefault();
          e.stopPropagation();
          handleConfirm();
        }
      };
      document.addEventListener('keydown', listenEsc, true);

      return () => {
        document.removeEventListener('keydown', listenEsc, true);
      };
    }
  }, [open, handleCancel, handleConfirm, visible]);

  return (
    <Collapse
      ref={ref}
      in={open}
      timeout={timeout}
      orientation={orientation}
      onEntered={handleEntered}
    >
      <Box maxWidth={maxWidth} minWidth={minWidth}>
        <ClickAwayListener onClickAway={handleClose}>
          <ConfirmTextField
            value={value}
            reserveHelperTextSpace={reserveHelperTextSpace}
            confirmDisabled={isConfirmDisabled}
            inputRef={handleInputRef}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            {...rest}
          />
        </ClickAwayListener>
      </Box>
    </Collapse>
  );
});

if (dev) {
  QuickConfirmTextField.Demo = lazy(() =>
    import('./QuickConfirmTextField.demo')
  );
}

export default QuickConfirmTextField;
