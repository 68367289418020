import { lazy } from 'react';

import { dev } from 'constants';
import { useAuth } from 'hooks';

const Access = (props) => {
  const { children, role } = props;
  const { role: userRole, user } = useAuth();

  if (role && !!user && userRole !== role) {
    return null;
  }
  return children;
};

if (dev) {
  Access.Demo = lazy(() => import('./Access.demo'));
}

export default Access;
