import { forwardRef, lazy, useMemo } from 'react';
import { styled, Box } from '@mui/material';

import { getString } from 'utils';
import { dev } from 'constants';
import Icon from 'components/Icon';
import Image from 'components/Image';
import ProgressBox from 'components/ProgressBox';

const sizes = {
  xsmall: 3,
  small: 4,
  medium: 5,
  large: 6,
  xlarge: 7,
};

const Root = styled(ProgressBox, {
  label: 'Avatar',
  shouldForwardProp: (prop) =>
    !['size', 'shape', 'fontSizeFactor'].includes(prop),
})(({ theme, size, borderColor, shape, fontSizeFactor }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: shape === 'circle' ? '50%' : '0',
  border: '1px solid white',
  borderColor,
  width: theme.spacing(sizes[size] || size),
  height: theme.spacing(sizes[size] || size),
  ...theme.typography.body3,
  fontSize: theme.spacing(((sizes[size] || size) / 2.2) * fontSizeFactor),
}));

const Avatar = forwardRef((props, ref) => {
  const {
    src,
    name,
    children,
    shape = 'circle',
    bgcolor = 'secondary.dark',
    color = 'common.white',
    size = 'medium',
    objectFit,
    fontSizeFactor = 1,
    ...rest
  } = props;

  const content = useMemo(() => {
    if (!name) {
      if (!children) {
        return <Icon.User />;
      }
      return getString(children).slice(0, 2).toUpperCase();
    }
    const parts = getString(name)
      .toUpperCase()
      .split(' ')
      .map(([char]) => char);
    const [f, l] = parts;
    const result = [f, l].filter(Boolean).join('');
    return result;
  }, [name, children]);

  return (
    <Root
      {...rest}
      size={size}
      ref={ref}
      color={color}
      bgcolor={bgcolor}
      shape={shape}
      fontSizeFactor={fontSizeFactor}
    >
      <Box zIndex={1} position="absolute" display="flex" alignItems="center">
        {content}
      </Box>

      <Image
        src={src}
        width={1}
        height={1}
        zIndex={2}
        position="relative"
        objectFit={objectFit}
      />
    </Root>
  );
});

if (dev) {
  Avatar.Demo = lazy(() => import('./Avatar.demo'));
}

export default Avatar;
