import { Group, Path } from 'paper';

import { getArray } from 'utils';
import PaperElement from 'components/Paper/PaperElement';
import PaperText from 'components/Paper/PaperText';

class PaperTable extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'table_group' });
    const defaultPadding = 3;

    super({ element, ...options });

    this.data = {
      ...options.data,
      padding: options.data?.pading || defaultPadding,
      padding_x:
        options.data?.padding_x || options.data?.padding || defaultPadding,
      padding_y:
        options.data?.padding_y || options.data?.padding || defaultPadding,
    };
    this.update();
  }

  update(data = this.data) {
    this.data = {
      ...this.data,
      ...data,
    };
    const rows = getArray(this.data?.rows);
    const customSizes = { ...(this.data?.scheme || {}) };
    const padX = this.data?.padding_x;
    const padY = this.data?.padding_y;
    const width = this.data?.width;
    const x = this.data?.x || 0;
    const y = this.data?.y || 0;

    const rowsGroup = new Group({ name: 'rows_group' });
    const bordersGroup = new Group({ name: 'borders_group' });

    this.element.removeChildren();
    this.element.addChildren([rowsGroup, bordersGroup]);

    if (rows.length === 0) {
      return;
    }
    const rowsGroups = rows.map((row, row_index) => {
      const rowGroup = new Group({ name: `row_${row_index + 1}_group` });

      const cellsGroups = row.map((c = '', cell_index) => {
        const getCell = () => {
          const isContent =
            ['string', 'number'].includes(typeof c) || Array.isArray(c);
          return isContent ? { content: c } : c;
        };
        const cell = getCell();
        const { content, span = 1, width: cellWidth, ...cellStyles } = cell;

        if (!customSizes[cell_index] && cellWidth) {
          customSizes[cell_index] = cellWidth;
        }
        const cellGroup = new Group({
          data: {
            span,
            content,
            width: cellWidth,
            ...cellStyles,
          },
          name: `cell_${cell_index + 1}_group`,
        });
        const parsedContent = Array.isArray(content) ? content : [content];
        const cellContent = parsedContent
          .map((c) => String(c || ''))
          .map((c) => c.toUpperCase());

        const textItems = cellContent.map(
          (c) =>
            new PaperText({
              content: c,
              fontSize: 10,
              ...cellStyles,
            })
        );
        cellGroup.addChildren(textItems);

        textItems.forEach((ti) => {
          ti.bounds.x = 0;
          ti.bounds.y = 0;
        });
        textItems.forEach((t, i, arr) => {
          const prev = arr[i - 1];
          t.bounds.center.x = cellGroup.bounds.center.x;
          t.bounds.y = prev?.bounds?.bottomCenter?.y || 0;
        });
        return cellGroup;
      });
      rowGroup.addChildren(cellsGroups);
      return rowGroup;
    });
    rowsGroup.addChildren(rowsGroups);

    const guitters = rowsGroups[0].children.reduce((res, cell) => {
      return res + cell.data.span;
    }, 0);

    const sizes = new Array(guitters).fill(0).map((s, i) => {
      if (customSizes[i] > 0) {
        return customSizes[i];
      }
      return s;
    });

    rowsGroups.forEach((row) => {
      row.children.reduce((g, cell) => {
        const { span = 1 } = cell.data;
        const commonWidth = cell.bounds.width + padX * 2;
        const averageWidth = commonWidth / span;

        for (let i = 0; i < span; i += 1) {
          if (sizes[g + i] < averageWidth) {
            sizes[g + i] = averageWidth;
          }
        }
        return g + span;
      }, 0);
    });

    const totalWidth = sizes.reduce((res, s) => res + s, 0);

    const customSizesColumnsNumber = Object.values(customSizes).filter(
      (s) => s > 0
    ).length;

    if (width > totalWidth) {
      const additional =
        (width - totalWidth) / (sizes.length - customSizesColumnsNumber);

      sizes.forEach((s, i) => {
        if (customSizes[i] > 0) {
          return;
        }
        sizes[i] = s + additional;
      });
    }

    const scheme = sizes.reduce((total, w, i) => [...total, total[i] + w], [0]);

    const offsets = [];
    const borders = [];

    rowsGroups.forEach((row, i) => {
      const rowHeight = getArray(row.children).reduce((h, cell) => {
        return Math.max(h, cell.bounds.height);
      }, 0);

      getArray(row.children).reduce((g, cell, k) => {
        const { span = 1, align = 'center' } = cell.data;
        const yOffset = i === 0 ? 0 : offsets[i - 1];

        const w = scheme[g + span] - scheme[g];
        const h = yOffset + rowHeight + padY * 2 - yOffset;
        const x1 = scheme[g];
        const y1 = yOffset;
        const x2 = x1 + w;
        const y2 = y1 + h;

        const p1 = { x: x1, y: y1 };
        const p2 = { x: x2, y: y1 };
        const p3 = { x: x1, y: y2 };
        const p4 = { x: x2, y: y2 };
        const p0 = {
          x: x1 + w / 2,
          y: y1 + h / 2,
        };
        if (i === 0) {
          borders.push([p1, p2]);
        }
        if (k === 0) {
          borders.push([p1, p3]);
        }
        borders.push([p2, p4], [p3, p4]);

        // Alignment of text cell
        switch (align) {
          case 'left': {
            cell.bounds.center = p0;
            cell.bounds.leftCenter.x = x1 + padX;
            break;
          }
          case 'right': {
            cell.bounds.rightCenter.x = x2;
            cell.bounds.leftCenter.x = x1 - padX;
            break;
          }
          case 'center':
          default: {
            cell.bounds.center = p0;
            break;
          }
        }
        if (!offsets[i]) {
          offsets[i] = y1 + h;
        }
        return g + span;
      }, 0);
    });

    const borderItems = borders.map((segments) => {
      return new Path({
        segments,
        strokeWidth: 1,
        strokeColor: 'black',
      });
    });
    bordersGroup.addChildren(borderItems);

    this.element.bounds.x = x;
    this.element.bounds.y = y;
  }
}

export default PaperTable;
