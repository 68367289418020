import { useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { withProps } from 'hooks';
import { SYSTEM_TYPES_LIST } from 'constants';
import { Center, Ref } from 'components';

import SystemItem from './SystemItem';

export const createEmptySystem = () => {
  return {
    id: null,
    system_number: null,
    system_type: SYSTEM_TYPES_LIST[0].value,
    panel_id: '',
    inverter_id: '',
    wte_id: '',
    rsd_id: '',
    optimizer_id: '',
    strings: [],
  };
};

const AddArrayButtonContainer = withProps(Center, {
  mt: 4,
  mx: 3,
  py: 2,
  sx: (theme) => ({
    borderTop: `1px dashed ${theme.palette.secondary.main}`,
    borderBottom: `1px dashed ${theme.palette.secondary.main}`,
  }),
});

const SystemsForm = () => {
  const { control } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'systems',
    keyName: 'field_id',
  });
  const [editSystem, setEditSystem] = useState(0);

  const handleAddSystem = useCallback(() => {
    append(createEmptySystem());
    setEditSystem(fields.length);
  }, [append, fields]);

  return (
    <Box mx={-3}>
      {fields.map((system, i) => {
        const handleRemove = () => {
          if (editSystem > i) {
            setEditSystem(editSystem - 1);
          } else if (editSystem === i) {
            setEditSystem(null);
          }
          remove(i);
        };
        return (
          <SystemItem
            key={system.field_id}
            system={system}
            index={i}
            name="systems"
            ableRemove={fields.length > 1}
            onRemove={handleRemove}
            onEdit={() => setEditSystem(i)}
            edit={editSystem === i}
          />
        );
      })}

      <AddArrayButtonContainer>
        <Typography variant="body2" color="secondary.main">
          <Ref onClick={handleAddSystem}>+ Add System</Ref>
        </Typography>
      </AddArrayButtonContainer>
    </Box>
  );
};

export default SystemsForm;
