import { useCallback, useEffect } from 'react';
import { Typography, Box, Collapse } from '@mui/material';

import { routes } from 'routes';
import { useResetPasswordReqeustMutation } from 'store';
import {
  Button,
  Form,
  ProgressBox,
  Ref,
  TextField,
  useMessage,
} from 'components';

const ForgotPassword = (props) => {
  const { open } = props;

  const form = Form.useForm();
  const { isValid } = form.formState;
  const m = useMessage();

  const [resetPassword, { isSuccess, isLoading }] =
    useResetPasswordReqeustMutation();

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [form, open]);

  const handleSubmit = useCallback(
    async (body) => {
      try {
        await resetPassword({ body }).unwrap();
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, resetPassword]
  );

  return (
    <ProgressBox progress={isLoading}>
      <Collapse in={!isSuccess}>
        <Form form={form} onSubmit={handleSubmit}>
          <Box textAlign="center" mt={4}>
            <Typography variant="subtitle1">Recover Password</Typography>
          </Box>

          <Box mt={4}>
            <TextField.Control
              type="email"
              name="email"
              label="Email"
              initialFocus={!!open}
              placeholder="Type your email"
            />
          </Box>

          <Box mt={3}>
            <Button type="submit" disabled={!isValid}>
              Recover Password
            </Button>
          </Box>

          <Box mt={3} textAlign="center">
            <Ref to={routes.sign_in.path()} color="secondary.main">
              <Typography variant="body2">Back to sign in</Typography>
            </Ref>
          </Box>
        </Form>
      </Collapse>

      <Collapse in={isSuccess}>
        <Box textAlign="center" mt={5}>
          <Box>
            <Typography variant="subtitle1">Thank you!</Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="body2">
              If you have an account, we will email you link to reset password
            </Typography>
          </Box>

          <Box mt={6}>
            <Button component={Ref} to={routes.sign_in.path()} nostyles>
              Back to Sign In
            </Button>
          </Box>
        </Box>
      </Collapse>
    </ProgressBox>
  );
};

export default ForgotPassword;
