import { getString, toEnum } from 'utils';

export const ROLES = toEnum({
  OWNER: 1,
  MEMBER: 2,
});

export const parseUserRole = (role) => {
  const [, currentRole] = getString(role).split('.');
  return ROLES[currentRole];
};
