import { forwardRef, lazy } from 'react';
import { Box } from '@mui/material';

import { dev } from 'constants';

const SvgElement = forwardRef((props, ref) => {
  const { children, width, height, ...rest } = props;

  return (
    <Box
      component="svg"
      ref={ref}
      {...rest}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </Box>
  );
});

if (dev) {
  SvgElement.Demo = lazy(() => import('./SvgElement.demo'));
}

export default SvgElement;
