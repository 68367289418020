import { Point } from 'paper';

class PaperBase {
  constructor(options) {
    const { scope, context, theme, name } = options;
    this.scope = scope;
    this.theme = theme;
    this.context = context;
    this.options = options;
    this.name = name;
  }

  destroy() {
    return null;
  }

  point(opt_x, opt_y) {
    const x = opt_x?.x || opt_x?.[0] || opt_x;
    const y = opt_x?.y || opt_x?.[1] || opt_y;
    return new Point(x, y);
  }

  dataPoint(opt_x, opt_y) {
    const p = this.point(opt_x, opt_y);

    return {
      x: p.x,
      y: p.y,
    };
  }

  equalPoints(p1, p2) {
    return p1.x === p2.x && p1.y === p2.y;
  }

  vector(start, end) {
    return this.point(end).subtract(this.point(start));
  }

  getPointOnDistance(a, b, distance) {
    const start = this.point(a);
    const end = this.point(b);

    const vector = start.subtract(end);
    const d = start.getDistance(end);

    const p = start.subtract(vector.normalize(d + distance));
    return this.point(p);
  }

  getClosestPoint(target, ...points) {
    return points.reduce((prev, p) => {
      const currentDistance = target.getDistance(p);
      const prevDistance = prev ? target.getDistance(prev) : Infinity;
      return currentDistance < prevDistance ? p : prev;
    }, null);
  }

  getFarPoint(target, ...points) {
    return points.reduce((prev, p) => {
      const currentDistance = target.getDistance(p);
      const prevDistance = prev ? target.getDistance(prev) : -Infinity;
      return currentDistance > prevDistance ? p : prev;
    }, null);
  }
}

export default PaperBase;
