import { useCallback, useRef, useState } from 'react';
import { Page } from 'react-pdf';

import { Center, PdfPreview, Slip } from 'components';

const PagePreview = (props) => {
  const { page } = props;

  const rootRef = useRef(null);
  const docRef = useRef(null);

  const [size, setSize] = useState();
  const [origin, setOrigin] = useState();

  const handleCalculateSize = useCallback(() => {
    const { current: rootElement } = rootRef;
    const { current: docElement } = docRef;

    if (docElement && rootElement) {
      const rootSize = rootElement.getBoundingClientRect();
      const docSize = docElement.getBoundingClientRect();

      const { width, height } = rootSize;
      const { width: canvasWidth, height: canvasHeight } = docSize;

      const newOrigin = canvasWidth > canvasHeight ? 'width' : 'height';
      const newSize =
        newOrigin === 'height'
          ? width > height
            ? height
            : width
          : width > height
          ? width
          : height;

      setOrigin(newOrigin);
      setSize(newSize);
    }
  }, []);

  return (
    <Slip
      width={1}
      height={1}
      maxWidth={1}
      maxHeight={1}
      overflow="hidden"
      ref={rootRef}
      component={Center}
    >
      <PdfPreview loading={null} file={page.page_url} inputRef={docRef}>
        <Page
          scale={1}
          pageNumber={1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          onRenderSuccess={handleCalculateSize}
          width={size && origin === 'width' ? size : undefined}
          height={size && origin === 'height' ? size : undefined}
        />
      </PdfPreview>
    </Slip>
  );
};

export default PagePreview;
