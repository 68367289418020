import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Collapse, styled } from '@mui/material';

import {
  PROJECT_VOLUMES as VOLS,
  PROJECT_VOLUMES_LIST,
  PROJECT_VOLUMES_STEPS,
  PROJECT_STEPS,
} from 'constants';

import { routes } from 'routes';
import { useAuth, useToggleArray, withProps } from 'hooks';
import { ProjectStepsList } from 'views';
import { Button, Icon, Modal } from 'components';

import StepsSubMenu, { ICON_SIZE } from './StepsSubMenu';

const IconWrapper = styled(Box, {
  label: 'IconWrapper',
  shouldForwardProp: (prop) => !['completed', 'disabled'].includes(prop),
})(({ theme, completed, disabled }) => ({
  width: ICON_SIZE,
  height: ICON_SIZE,
  position: 'relative',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  border: `1px solid ${
    disabled ? theme.palette.border.dark : theme.palette.secondary.main
  }`,
  backgroundImage: !completed
    ? undefined
    : 'linear-gradient(to bottom, #f94849, #fbd62a)',
}));

const Line = withProps(Box, {
  width: '1px',
  position: 'absolute',
  backgroundColor: 'secondary.main',
  left: 'calc(50% - 0.5px)',
  sx: (theme) => ({
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
    }),
  }),
});

const VolumeLine = styled(Line, {
  label: 'VolumeLine',
  shouldForwardProp: (prop) => !['selected', 'disabled'].includes(prop),
})(({ theme, selected, disabled }) => ({
  bottom: selected ? -21 : -11,
  height: selected ? 20 : 0,
  backgroundColor: disabled
    ? theme.palette.border.dark
    : theme.palette.secondary.main,
}));

const volumeIcons = {
  [VOLS.GENERAL_DETAILS]: Icon.GeneralDetails,
  [VOLS.ELECTRICAL_DETAILS]: Icon.ElectricalDetails,
  [VOLS.STRUCTURAL_DETAILS]: Icon.StructuralDetails,
  [VOLS.DATASHEETS]: Icon.Datasheets,
  [VOLS.PROJECT_PREVIEW]: Icon.ProjectPreview,
};

const ProjectStepsMenu = (props) => {
  const { step, volume, latestStep, latestVolume, project_id } = props;
  const { premium } = useAuth();
  const navigate = useNavigate();

  const [lockedOption, setLockedOption] = useState(null);
  const [volumesState, toggleVolumesState] = useToggleArray();

  useEffect(() => {
    toggleVolumesState.reset();

    if (volume) {
      toggleVolumesState(volume);
    }
  }, [toggleVolumesState, volume]);

  const availableVolumes = useMemo(() => {
    const result = [];

    if (!latestVolume) {
      return result;
    }
    PROJECT_VOLUMES_LIST.some(({ value }) => {
      result.push(value);
      return value === latestVolume;
    });
    return result;
  }, [latestVolume]);

  const completedVolumes = useMemo(
    () => availableVolumes.slice(0, availableVolumes.length - 1),
    [availableVolumes]
  );

  const handlePremiumClick = useCallback(
    (opt) => {
      if (!premium) {
        setLockedOption(opt);
      }
    },
    [premium]
  );

  const options = useMemo(() => {
    return PROJECT_VOLUMES_LIST.map((opt, i) => {
      const VolumeIcon = volumeIcons[opt.value];
      const selected = volumesState.includes(opt.value);
      const childrenOptions = PROJECT_VOLUMES_STEPS[opt.value];
      const completed = completedVolumes.includes(opt.value);
      const bgcolor = selected ? 'violet.dark' : 'violet.main';
      const currentVolume = availableVolumes.includes(opt.value);
      const disabled = !completed && !currentVolume;

      const iconColor = disabled
        ? 'border.dark'
        : completed
        ? bgcolor
        : 'secondary.main';

      const handleClick = () => {
        if (childrenOptions.length > 0) {
          toggleVolumesState(opt.value);
          return;
        }
        if (PROJECT_STEPS[opt.value]) {
          navigate(routes[PROJECT_STEPS[opt.value]].path({ project_id }));
        }
      };
      return {
        ...opt,
        selected,
        disabled,
        label: `${i + 1}. ${opt.label}`,
        onClick: handleClick,
        icon: (
          <IconWrapper completed={completed} disabled={disabled}>
            <VolumeIcon sx={{ color: iconColor }} />

            {childrenOptions.length > 0 && (
              <VolumeLine
                selected={selected}
                disabled={
                  !completed && !currentVolume && latestVolume !== opt.value
                }
              />
            )}
          </IconWrapper>
        ),
        children: (
          <Collapse in={selected}>
            <StepsSubMenu
              step={step}
              latestStep={latestStep}
              completed={completed}
              project_id={project_id}
              options={childrenOptions}
              onPremiumClick={handlePremiumClick}
            />
          </Collapse>
        ),
      };
    });
  }, [
    volumesState,
    completedVolumes,
    availableVolumes,
    latestVolume,
    step,
    latestStep,
    project_id,
    handlePremiumClick,
    toggleVolumesState,
    navigate,
  ]);

  return (
    <>
      <Modal
        open={!!lockedOption}
        title={`Cannot access the "${lockedOption}"`}
        onClose={() => setLockedOption(null)}
        actions={<Button onClick={() => setLockedOption(null)}>OK</Button>}
      >
        This option is available in the paid version, please contact the system
        administrator to learn more about it.
      </Modal>

      <ProjectStepsList options={options} />
    </>
  );
};

export default ProjectStepsMenu;
