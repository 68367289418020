import { useMemo } from 'react';

import { useGetConstructionTypeListQuery } from 'store';
import { getArray } from 'utils';
import { SelectField } from 'components';

const ConstructionTypeSelect = (props) => {
  const { ...rest } = props;
  const { data: constructionTypes, isFetching } =
    useGetConstructionTypeListQuery();

  const options = useMemo(() => {
    return getArray(constructionTypes).map((ct) => ({
      value: ct.id,
      label: ct.title,
    }));
  }, [constructionTypes]);

  return (
    <SelectField.Control loading={isFetching} options={options} {...rest} />
  );
};

export default ConstructionTypeSelect;
