import { useMemo } from 'react';

import { getArray } from 'utils';
import { Autocomplete } from 'components';

const PanelsField = (props) => {
  const { name, prefix, panels, ...rest } = props;

  const options = useMemo(() => {
    return getArray(panels).map((panel) => ({
      value: panel.id,
      label: `${panel.vendor.title} - ${panel.title}`,
    }));
  }, [panels]);

  return (
    <Autocomplete.Control
      required
      valueAsId
      name={`${prefix}.${name}`}
      options={options}
      {...rest}
    />
  );
};

export default PanelsField;
