import {
  createContext,
  forwardRef,
  lazy,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import { dev } from 'constants';
import { useCombineRefs, useToggle } from 'hooks';
import Ripple from 'components/Ripple';

const hoverFactor = 0.85;

const Root = styled(Box, {
  label: 'Ref',
  shouldForwardProp: (prop) =>
    !['nostyles', 'inactive', 'noline', 'hovered'].includes(prop),
})(({ theme, nostyles, inactive, noline, hovered }) => ({
  position: 'relative',
  display: 'inline-flex',
  textDecoration: 'none',
  pointerEvents: inactive ? 'none' : undefined,
  '&:hover, &:focus, &:focus-visible': {
    outline: 'none',
  },
  ...(!nostyles && {
    cursor: 'pointer',
    position: 'relative',
    filter: hovered ? `brightness(${hoverFactor})` : `brightness(1)`,
    transition: theme.transitions.create('all'),
    ...(!noline && {
      '&::after': {
        content: '""',
        width: '100%',
        position: 'absolute',
        bottom: theme.pxToRem(2),
        height: theme.pxToRem(1),
        filter: 'brightness(1)',
        backgroundColor: 'currentColor',
        transform: hovered ? 'scale(0.75)' : 'scale(1)',
        transition: theme.transitions.create('filter, transform'),
      },
    }),
    '&:hover, &:focus, &:focus-visible': {
      outline: 'none',
      filter: `brightness(${hoverFactor})`,
      ...(!noline && {
        '&::after': {
          filter: `brightness(${hoverFactor})`,
          transform: 'scale(0.75)',
        },
      }),
    },
  }),
}));

const RefContainerRoot = styled(Box, {
  label: 'RefContainer',
})(() => ({ cursor: 'pointer' }));

const RefContainerContext = createContext();
RefContainerContext.displayName = 'RefContainerContext';

const RefContainer = forwardRef((props, ref) => {
  const { children, ...rest } = props;

  const [hover, toggleHover] = useToggle();

  const rootRef = useRef();
  const handleRef = useCombineRefs(ref, rootRef);

  useEffect(() => {
    const { current: rootElement } = rootRef;

    if (rootElement) {
      const listenMouseEnter = () => {
        toggleHover.on();
      };
      const listenMouseLeave = () => {
        toggleHover.off();
      };
      rootElement.addEventListener('mouseenter', listenMouseEnter);
      rootElement.addEventListener('mouseleave', listenMouseLeave);

      return () => {
        rootElement.removeEventListener('mouseenter', listenMouseEnter);
        rootElement.removeEventListener('mouseleave', listenMouseLeave);
      };
    }
  }, [toggleHover]);

  const contextData = useMemo(
    () => ({
      hover,
    }),
    [hover]
  );

  return (
    <RefContainerRoot ref={handleRef} {...rest}>
      <RefContainerContext.Provider value={contextData}>
        {children}
      </RefContainerContext.Provider>
    </RefContainerRoot>
  );
});

const Ref = forwardRef((props, ref) => {
  const {
    to,
    color = 'inherit',
    children,
    blank,
    absolute,
    noline,
    nostyles,
    useTabFocus,
    hovered: customHovered,
    component: customComponent,
    ...rest
  } = props;

  const rootRef = useRef();
  const handleRootRef = useCombineRefs(ref, rootRef);
  const [focus, toggleFocus] = useToggle();
  const contextData = useContext(RefContainerContext);

  const outerHovered = contextData?.hover;
  const hovered = customHovered || outerHovered;

  const component = useMemo(() => {
    if (customComponent) {
      return customComponent;
    }
    if (absolute) {
      return 'a';
    }
    if (to) {
      return Link;
    }
    return 'span';
  }, [absolute, customComponent, to]);

  const defaults = useMemo(() => {
    const result = {};

    if (component === 'a') {
      if (to) {
        result.href = to;
      }
      if (blank) {
        result.target = '_blank';
        result.rel = 'noreferrer noopener';
      }
    }
    if (component === Link) {
      if (to) {
        result.to = to;
      }
      if (blank) {
        result.target = '_blank';
        result.rel = 'noreferrer noopener';
      }
    }
    return result;
  }, [component, blank, to]);

  useEffect(() => {
    const { current: rootElement } = rootRef;

    if (rootElement && useTabFocus) {
      const listenFocus = () => {
        toggleFocus.on();
      };
      const listenBlur = () => {
        toggleFocus.off();
      };
      rootElement.addEventListener('focus', listenFocus);
      rootElement.addEventListener('blur', listenBlur);

      return () => {
        rootElement.removeEventListener('focus', listenFocus);
        rootElement.removeEventListener('blur', listenBlur);
      };
    }
  }, [toggleFocus, useTabFocus]);

  return (
    <Root
      tabIndex="0"
      ref={handleRootRef}
      color={color}
      component={component}
      nostyles={nostyles}
      noline={noline}
      hovered={hovered}
      {...defaults}
      {...rest}
    >
      {children}

      {useTabFocus && <Ripple show={focus} />}
    </Root>
  );
});

Ref.Container = RefContainer;

if (dev) {
  Ref.Demo = lazy(() => import('./Ref.demo'));
}

export default Ref;
