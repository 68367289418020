import { Grid, Typography, Box } from '@mui/material';

import { withProps } from 'hooks';
import { SelectField } from 'components';
import { AC_DISCONNECT_BRAND_NAME_OPTIONS_LIST } from 'constants';

const Item = withProps(Grid, {
  item: true,
  xs: 6,
});

const ElectricalBalanceOfSystemPreferences = (props) => {
  const { readOnly, ...rest } = props;
  const disabled = !readOnly;

  return (
    <Box {...rest}>
      <Typography variant="subtitle2">Electrical: Balance of System</Typography>

      <Grid container mt={1} rowSpacing={1} columnSpacing={4}>
        <Item>
          <SelectField.Control
            disabled={disabled}
            readOnly={readOnly}
            name="ac_disconnect_brand_name"
            label="AC Disconnect Brand Name"
            options={AC_DISCONNECT_BRAND_NAME_OPTIONS_LIST}
          />
        </Item>
      </Grid>
    </Box>
  );
};

export default ElectricalBalanceOfSystemPreferences;
