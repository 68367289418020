import {
  cloneElement,
  forwardRef,
  lazy,
  useEffect,
  useMemo,
  useState,
} from 'react';

import convert from 'react-from-dom';

import { dev } from 'constants';

const cache = {};
const requests = {};

const makeRequest = async (src) => {
  const response = await fetch(src);
  const content = await response.text();
  cache[src] = content;
  requests[src] = null;
  return content;
};

const getRequest = (src) => {
  if (!src) {
    return null;
  }
  if (requests[src]) {
    return requests[src];
  }
  const request = makeRequest(src);
  requests[src] = request;
  return request;
};

const getContent = async (src) => {
  if (cache[src]) {
    return cache[src];
  }
  return getRequest(src);
};

const SvgLoader = forwardRef((props, ref) => {
  const { src, ...rest } = props;

  const [content, setContent] = useState(cache[src] || null);

  const element = useMemo(() => {
    return content ? convert(content) : null;
  }, [content]);

  useEffect(() => {
    let mounted = true;

    getContent(src).then((responseContent) => {
      if (mounted) {
        setContent(responseContent);
      }
    });
    return () => {
      mounted = false;
    };
  }, [src]);

  if (element) {
    return cloneElement(element, {
      ref,
      ...element.props,
      ...rest,
      children: element.props.children,
    });
  }
  return element;
});

if (dev) {
  SvgLoader.Demo = lazy(() => import('./SvgLoader.demo'));
}

export default SvgLoader;
