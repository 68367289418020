import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  useGetProjectDetailsQuery,
  useUpdateProjectInterconnectionMutation,
} from 'store';

import {
  MSP_STRATEGY_LIST,
  BUS_RATING,
  MSP_TYPES_LIST,
  INTERCONNECTION_STRATEGY_LIST,
  POI_LINE_SIDE_LIST,
  GROUNDING_LIST,
  UTILITIES_LIST,
} from 'constants';

import { routes } from 'routes';
import { ProjectDetailsLayout } from 'layouts';
import { ProjectSubmitButton } from 'views';
import { Form, ProgressBox, RadioButtons, Tile, useMessage } from 'components';

import InterconnectionPreview from './InterconnectionPreview';
import MspFields from './MspFields';
import AcSubpanelFields from './AcSubpanelFields';
import InterconnectionFields from './InterconnectionFields';
import UtilityAcDisconnectFields from './UtilityAcDisconnectFields';
import ServiceFields from './ServiceFields';

const Interconnection = () => {
  const { project_id } = useParams();
  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });
  const [update, { isLoading }] = useUpdateProjectInterconnectionMutation();

  const m = useMessage();
  const form = Form.useForm();

  const loading = isFetching || isLoading;
  const isFirstSaving = !project?.interconnection;
  const nextStep = useMemo(
    () => routes.balance_of_system.path({ project_id }),
    [project_id]
  );

  useEffect(() => {
    const data = project?.interconnection;

    form.reset({
      msp_panel_type: data?.main_service_panel_id ? 'vendor' : 'generic',
      msp_strategy: data?.msp_strategy || MSP_STRATEGY_LIST[0].value,
      main_service_panel_id: data?.main_service_panel_id || '',
      main_service_panel_data: {
        bus_rating: data?.main_service_panel_data?.bus_rating || BUS_RATING.A60,
        breaker_rating: data?.main_service_panel_data?.breaker_rating || '',
      },
      msp_type: data?.msp_type || MSP_TYPES_LIST[0].value,
      subpanel_enabled: !!data?.subpanel_data,
      subpanel_strategy: data?.subpanel_strategy || MSP_STRATEGY_LIST[0].value,
      subpanel_panel_type: data?.subpanel_id ? 'vendor' : 'generic',
      subpanel_id: data?.subpanel_id || '',
      subpanel_data: {
        bus_rating: data?.subpanel_data?.bus_rating || BUS_RATING.A60,
        breaker_rating: data?.subpanel_data?.breaker_rating || '',
        feed_by: data?.subpanel_data?.feed_by || '',
      },
      interconnection_strategy:
        data?.interconnection_strategy ||
        INTERCONNECTION_STRATEGY_LIST[0].value,
      poi: data?.poi || POI_LINE_SIDE_LIST[0].value,
      disconnect: !!data?.disconnect_id,
      disconnect_id: data?.disconnect_id || '',
      disconnect_label: data?.disconnect_label || '',
      service_grounding: data?.service_grounding || GROUNDING_LIST[0].value,
      utility_service: data?.utility_service || UTILITIES_LIST[0].value,
      utility_meter_label: data?.utility_meter_label || '',
    });
  }, [form, project]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const body = { ...formData };
        delete body.msp_panel_type;
        delete body.subpanel_panel_type;
        delete body.disconnect;

        if (!formData.subpanel_enabled) {
          delete body.subpanel_strategy;
          delete body.subpanel_id;
          delete body.subpanel_data;
        }
        if (formData.subpanel_panel_type === 'generic') {
          delete body.subpanel_id;
        }
        if (formData.msp_panel_type === 'generic') {
          delete body.main_service_panel_id;
        }
        if (!formData.main_service_panel_data?.breaker_rating) {
          delete body.main_service_panel_data?.breaker_rating;
        }
        if (!formData.main_service_panel_data?.breaker_rating) {
          delete body.main_service_panel_data?.breaker_rating;
        }
        if (!formData.subpanel_data?.breaker_rating) {
          delete body.subpanel_data?.breaker_rating;
        }
        if (!formData.subpanel_data?.feed_by) {
          delete body.subpanel_data?.feed_by;
        }
        if (!formData.disconnect) {
          delete body.disconnect_id;
          delete body.disconnect_label;
        }
        await update({ project_id, body });
        m.success('Project has been updated!');
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, update, project_id]
  );

  return (
    <ProjectDetailsLayout
      form={form}
      title="Interconnection"
      component={Form}
      onSubmit={handleSubmit}
      body={
        <ProgressBox progress={loading}>
          <Tile
            label="Calculated PV OCPD Rating"
            value={project?.pv_system_ocpd || '-'}
          />

          <Box mt={4}>
            <RadioButtons.Control
              fullWidth
              variant="flat"
              size="medium"
              spacing={2}
              label="Strategy for MSP"
              name="msp_strategy"
              options={MSP_STRATEGY_LIST}
            />
          </Box>

          <MspFields />
          <AcSubpanelFields />
          <InterconnectionFields />
          <UtilityAcDisconnectFields />
          <ServiceFields />

          <Box mt={4}>
            <ProjectSubmitButton
              nextStep={nextStep}
              isFirstSaving={isFirstSaving}
            />
          </Box>
        </ProgressBox>
      }
    >
      <InterconnectionPreview project_id={project_id} />
    </ProjectDetailsLayout>
  );
};

export default Interconnection;
