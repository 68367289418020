import { Box, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { routes } from 'routes';
import { homepage_background, logo_black } from 'assets';
import { BackgroundBox, Image, Ref, Slip, Spinner } from 'components';

const Root = styled(BackgroundBox, {
  label: 'GuestLayout',
})(({ theme }) => ({
  display: 'flex',

  width: '100vw',
  minWidth: '100vw',
  maxWidth: '100vw',
  minHeight: '100vh',
  height: '100%',
  maxHeight: '100%',
  overflow: 'auto',

  paddingBottom: theme.spacing(10),
}));

const GuestLayout = (props) => {
  const { loading } = props;

  return (
    <Root
      transition={1000}
      src={homepage_background}
      imageProps={{
        position: 'fixed',
        sx: {
          backgroundColor: 'colors.background_main',
        },
      }}
    >
      <Box p="15vh" pl="15vw">
        <Slip
          px={8}
          py={5}
          minHeight={400}
          minWidth={512}
          maxWidth={512}
          elevation={3}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="center">
            <Ref to={routes.root.path()} nostyles>
              <Image src={logo_black} height={33} />
            </Ref>
          </Box>

          {!loading && <Outlet />}

          {loading && (
            <Box
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size="xlarge" />
            </Box>
          )}
        </Slip>
      </Box>
    </Root>
  );
};

export default GuestLayout;
