import { useMemo } from 'react';
import { Box, Grid, Collapse } from '@mui/material';

import {
  VOLTAGE_DROP_LIST,
  NEUTRALS_LIST,
  TERMINAL_TEMP_LIST,
  EGC_METHOD_LIST,
  NEUTRAL_METHOD_LIST,
  EGC_METHOD,
} from 'constants';

import { useGetProjectWiresTableQuery } from 'store';
import { useWatchField, withProps } from 'hooks';
import { ProjectSubmitButton } from 'views';
import { ProgressBox, RadioButtons as _RadioButtons, Tile } from 'components';

const RadioButtons = withProps(_RadioButtons.Control, {
  size: 'medium',
  variant: 'flat',
  fullWidth: true,
});

const WireTableForm = (props) => {
  const { loading, nextStep, isFirstSaving, project_id } = props;

  const gec_strategy = useWatchField('gec_strategy');
  const neutral_strategy = useWatchField('neutral_strategy');
  const temperature_rating = useWatchField('temperature_rating');
  const isNeutralStrategy = gec_strategy !== EGC_METHOD.CONDUCTOR;

  const tableQuery = useMemo(
    () => ({
      project_id,
      params: {
        gec_strategy,
        temperature_rating,
        neutral_strategy: isNeutralStrategy ? neutral_strategy : null,
      },
    }),
    [
      project_id,
      temperature_rating,
      gec_strategy,
      neutral_strategy,
      isNeutralStrategy,
    ]
  );

  const { data: wireTable, isFetching: tableLoading } =
    useGetProjectWiresTableQuery(tableQuery);

  const dc_vd = wireTable?.dc_voltage_drop;
  const ac_vd = wireTable?.ac_voltage_drop;
  const progress = loading || tableLoading;

  return (
    <ProgressBox progress={progress}>
      <Grid container spacing={0} columnSpacing={2}>
        <Grid item xs={6}>
          <Tile label="DC VD" value={dc_vd ? `${dc_vd}%` : '-'} />
        </Grid>

        <Grid item xs={6}>
          <Tile label="AC VD" value={ac_vd ? `${ac_vd}%` : '-'} />
        </Grid>
      </Grid>

      <Box mt={4}>
        <RadioButtons
          name="voltage_drop_view"
          label="Voltage Drop View"
          options={VOLTAGE_DROP_LIST}
        />
      </Box>

      <Grid container my={0} spacing={0}>
        <Grid item xs={8}>
          <Box mt={1}>
            <RadioButtons
              direction="column"
              name="neutral_view"
              label="Neutral View"
              options={NEUTRALS_LIST}
            />
          </Box>

          <Box mt={1}>
            <RadioButtons
              name="temperature_rating"
              label="Terminals Temperature Rating"
              options={TERMINAL_TEMP_LIST}
            />
          </Box>

          <Box mt={1}>
            <RadioButtons
              direction="column"
              name="gec_strategy"
              label="EGC Calculation Method"
              options={EGC_METHOD_LIST}
            />
          </Box>
        </Grid>
      </Grid>

      <Collapse in={isNeutralStrategy}>
        <Box mt={1}>
          <RadioButtons
            equal
            name="neutral_strategy"
            options={NEUTRAL_METHOD_LIST}
            label="Neutral Calculation Method"
          />
        </Box>
      </Collapse>

      <Box mt={4}>
        <ProjectSubmitButton
          nextStep={nextStep}
          isFirstSaving={isFirstSaving}
        />
      </Box>
    </ProgressBox>
  );
};

export default WireTableForm;
