import { Controller, useFormContext } from 'react-hook-form';
import { Draggable } from 'react-beautiful-dnd';
import { useCallback, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { useToggle, useWatchField } from 'hooks';
import {
  DropdownMenu,
  Center,
  Icon,
  Slip,
  TinyButton,
  Text,
  ControlValue,
} from 'components';

const handlePreventClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const DatasheetItem = (props) => {
  const { datasheet, index, name, onClick, selected, onReplace, onDelete } =
    props;
  const { control, getValues, watch } = useFormContext();

  const [displayIndex, setDisplayIndex] = useState(index);
  const [customMenu, toggleCustomMenu] = useToggle();

  const visible = useWatchField(`${name}.${index}.visible`);
  const isCustom = useWatchField(`${name}.${index}.is_custom`);
  const status = useWatchField(`${name}.${index}.status`);

  useEffect(() => {
    const w = watch((vals, { name: fieldName }) => {
      if (fieldName.startsWith('metadata')) {
        const newDisplayIndex = vals.metadata.reduce((res, v, i) => {
          if (i > index) {
            return res;
          }
          const actualStatus = getValues(`${name}.${i}.status`);

          if (actualStatus === 'deleted') {
            return res;
          }
          return res + 1;
        }, 0);

        setDisplayIndex(newDisplayIndex);
      }
    });
    return () => {
      w.unsubscribe();
    };
  }, [watch, datasheet, name, getValues, index]);

  const handleMenuClose = useCallback(
    (e) => {
      handlePreventClick(e);
      toggleCustomMenu.off();
    },
    [toggleCustomMenu]
  );

  const handleReplace = useCallback(
    (e) => {
      handleMenuClose(e);
      onReplace();
    },
    [handleMenuClose, onReplace]
  );

  const handleDelete = useCallback(
    (e) => {
      handleMenuClose(e);
      onDelete();
    },
    [handleMenuClose, onDelete]
  );

  return (
    <Draggable draggableId={datasheet.equipment_id} index={index}>
      {(provided) => {
        return (
          <Center
            width={1}
            ref={provided.innerRef}
            sx={{
              cursor: 'pointer',
              display: status === 'deleted' ? 'none' : 'flex',
            }}
            onClick={onClick}
            {...provided.draggableProps}
          >
            <Box width={32} flexShrink={0}>
              <Typography variant="subtitle4">#{displayIndex}</Typography>
            </Box>

            <ControlValue
              name={`${name}.${index}.equipment_id`}
              value={datasheet.equipment_id}
            />

            <ControlValue
              name={`${name}.${index}.is_custom`}
              value={datasheet.is_custom}
            />

            <ControlValue
              name={`${name}.${index}.equipment_title`}
              value={datasheet.equipment_title}
            />

            <ControlValue
              name={`${name}.${index}.file_url`}
              value={datasheet.file_url}
            />

            <ControlValue
              name={`${name}.${index}.status`}
              value={datasheet.status}
            />

            <Slip
              flexGrow={1}
              minHeight={46}
              component={Center}
              justifyContent="space-between"
              elevation={selected ? 0 : 1}
              sx={(theme) => ({
                border: `2px solid transparent`,
                borderColor: selected
                  ? theme.palette.secondary.main
                  : 'transparent',
              })}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Center
                  {...provided.dragHandleProps}
                  onClick={handlePreventClick}
                >
                  <Icon.Moving sx={{ color: 'border.main' }} />
                </Center>

                <Text
                  lines={1}
                  variant="body2"
                  title={datasheet.equipment_title}
                  color={visible === false ? 'border.main' : 'primary.main'}
                >
                  {datasheet.equipment_title}
                </Text>
              </Stack>

              <Stack direction="row" alignItems="center" sizing={0}>
                <Controller
                  control={control}
                  name={`${name}.${index}.visible`}
                  defaultValue={datasheet.visible}
                  render={({ field }) => {
                    const { value, onChange } = field;

                    const handleToggle = (e) => {
                      handlePreventClick(e);
                      onChange(!value);
                    };
                    return (
                      <TinyButton
                        shape="circle"
                        size="small"
                        variant="transparent"
                        onClick={handleToggle}
                      >
                        {value && <Icon.VisibilityOn />}

                        {!value && <Icon.VisibilityOff />}
                      </TinyButton>
                    );
                  }}
                />

                {isCustom && (
                  <DropdownMenu
                    open={customMenu}
                    onOpen={toggleCustomMenu.on}
                    onClose={handleMenuClose}
                    PaperProps={{ mt: 1 }}
                    minWidth={142}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    anchor={
                      <TinyButton
                        size="small"
                        shape="circle"
                        variant="transparent"
                        onClick={handlePreventClick}
                      >
                        <Icon.DotsVertical
                          fontSize="large"
                          sx={{
                            color: customMenu
                              ? 'secondary.main'
                              : 'primary.main',
                          }}
                        />
                      </TinyButton>
                    }
                    options={[
                      {
                        value: 'replace',
                        label: 'Replace',
                        onClick: handleReplace,
                      },
                      {
                        value: 'delete',
                        label: 'Delete',
                        onClick: handleDelete,
                      },
                    ]}
                  />
                )}
              </Stack>
            </Slip>
          </Center>
        );
      }}
    </Draggable>
  );
};

export default DatasheetItem;
