export const isObject = (v) => {
  return !!v && !Array.isArray(v) && typeof v === 'object';
};

export const getArray = (v) => (Array.isArray(v) ? v : []);

export const getObject = (v) => (!isObject(v) ? {} : v);

export const getString = (v) => (typeof v === 'string' ? v : '');

export const getFunc =
  (v) =>
  (...args) =>
    typeof v === 'function' ? v(...args) : undefined;

export const getInt = (v, def = 0) =>
  Number.isFinit(v) ? Number.parseInt(v, 10) : def;

export const getFloat = (v, def = 0) =>
  Number.isFinite(v) ? Number.parseFloat(v, 10) : def;

export const filterObjectFields = (obj, fields) => {
  return getArray(fields).reduce(
    (res, field) => ({
      ...res,
      [field]: obj?.[field],
    }),
    {}
  );
};

export const emptyFieldsToNulls = (obj) => {
  if (!isObject(obj)) {
    return obj;
  }
  return Object.entries(obj).reduce(
    (res, [key, value]) => ({
      ...res,
      [key]: !value ? null : value,
    }),
    {}
  );
};

export const removeEmptyFields = (obj) => {
  if (!isObject(obj)) {
    return obj;
  }
  return Object.entries(obj).reduce((res, [key, value]) => {
    if (value) {
      return {
        ...res,
        [key]: value,
      };
    }
    return res;
  }, {});
};

export const fromRange = (v, min = -Infinity, max = Infinity) => {
  const val = getFloat(v);
  return Math.max(min, Math.min(max, val));
};

export const toEnum = (o) => {
  if (!isObject(o)) {
    return {};
  }
  return Object.entries(o).reduce(
    (res, [key, value]) => ({
      ...res,
      [key]: value,
      [value]: key,
    }),
    {}
  );
};

export const toFormData = (data) => {
  const formData = new FormData();

  if (isObject(data)) {
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });
  }
  return formData;
};

export const capitalize = (s) => {
  const val = getString(s).toLowerCase();
  return `${val[0].toUpperCase()}${val.slice(1)}`;
};

export const valueToLabel = (v) => {
  return getString(v).toLowerCase().split('_').map(capitalize).join(' ');
};

export const valueToOption = (value) => {
  const label = valueToLabel(value);
  return { value, label };
};

export const valuesToOptions = (opts) => {
  return getArray(opts).map(valueToOption);
};

export const isValidDateObject = (v) => {
  if (!(v instanceof Date)) {
    return false;
  }
  const stamp = v.getTime();

  if (!stamp || stamp <= 0) {
    return false;
  }
  return Number.isFinite(stamp);
};

export const imageFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (ex) => {
      reject(ex);
    };
  });
};

export const getResponseMessage = (res) => getString(res?.data?.detail);

export const sleep = (t = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, t);
  });

export const svgToFile = (element) => {
  const serializer = new XMLSerializer();
  const stringData = serializer.serializeToString(element);
  const blob = new Blob([stringData], { type: 'image/svg+xml' });
  const file = new File([blob], 'linediagram');
  return file;
};

export const feetToString = (f) => {
  const feet = Math.floor(f);
  const inch = Math.round((f - feet) * 12);
  return `${feet}'-${inch}"`;
};

export const serializeParams = (params = {}) => {
  const result = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    const isEmpty = !value || (Array.isArray(value) && value.length === 0);

    if (isEmpty) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((v) => {
        result.append(key, v);
      });
    } else {
      result.append(key, value);
    }
  });
  return result;
};

export const snakeCaseToCamelCase = (name, cap) => {
  return getString(name)
    .toLowerCase()
    .split('_')
    .map((part, i) => {
      const first = getString(part[0]);
      const rest = getString(part.slice(1)).toLowerCase();

      return [
        i > 0 || cap ? first.toUpperCase() : first.toLowerCase(),
        rest,
      ].join('');
    })
    .join('');
};

export const downloadLink = (src, name) => {
  const link = document.createElement('a');
  link.href = src;
  link.download = name || src;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
};
