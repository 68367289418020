import { forwardRef, useCallback, useMemo, useRef, lazy } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Typography, Stack, styled } from '@mui/material';

import { getFunc } from 'utils';
import { MIME, dev } from 'constants';
import { propagateRefs, withProps } from 'hooks';
import { Avatar, Dropzone, Icon, ProgressBox, Ref } from 'components';

const Center = withProps(Box, {
  gap: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  sx: {
    left: (theme) => theme.spacing(-1),
  },
});

const Root = styled(Box, {
  label: 'UploadAvatar',
})({});

const { IMAGES } = MIME;

const UploadAvatar = forwardRef((props, ref) => {
  const {
    loading,
    value,
    onChange,
    readOnly,
    direction = 'column',
    placeholder = 'Upload Photo',
    shape,
    size = 12,
    avatarProps,
    ...rest
  } = props;

  const inputRef = useRef();

  const avatar = useMemo(() => {
    const v = value?.[0];

    if (!v) {
      return null;
    }
    if (v instanceof File) {
      return URL.createObjectURL(v);
    }
    if (v.url) {
      return v.url;
    }
    return null;
  }, [value]);

  const handleChange = useCallback(
    (v) => {
      getFunc(onChange)(v);
    },
    [onChange]
  );

  const handleRemove = useCallback(() => {
    const v = [];
    getFunc(onChange)(v);
  }, [onChange]);

  const handleReplace = useCallback(() => {
    const { current: inputElement } = inputRef;

    if (inputElement) {
      inputElement.click();
    }
  }, []);

  return (
    <Root
      ref={ref}
      minWidth={120}
      alignItems="center"
      display="inline-flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box overflow="hidden">
        <Dropzone
          {...rest}
          value={value}
          accept={[IMAGES]}
          inputRef={inputRef}
          readOnly={readOnly}
          onChange={handleChange}
          uploadTrigger={
            <ProgressBox
              gap={1}
              progress={loading}
              display="flex"
              alignItems="center"
              color="secondary.main"
              flexDirection="column"
            >
              {!readOnly && (
                <>
                  <Icon.StarBox />
                  <Ref tabIndex={-1}>
                    <Typography variant="body2">{placeholder}</Typography>
                  </Ref>
                </>
              )}

              {readOnly && (
                <Icon.StarBox
                  sx={{ fontSize: (theme) => theme.spacing(size) }}
                />
              )}
            </ProgressBox>
          }
        >
          {avatar && (
            <Stack
              spacing={2}
              alignItems={direction === 'column' ? 'center' : 'flex-start'}
              color="secondary.main"
              direction={direction}
            >
              <Avatar
                progress={loading}
                src={avatar}
                {...avatarProps}
                size={size}
                shape={shape}
              />

              {!readOnly && (
                <Stack spacing={2}>
                  <Center>
                    <Icon.Reload sx={{ fontSize: 16 }} />

                    <Ref onClick={handleReplace}>
                      <Typography variant="body2">Replace</Typography>
                    </Ref>
                  </Center>

                  <Center>
                    <Icon.Close sx={{ fontSize: 16 }} />

                    <Ref onClick={handleRemove}>
                      <Typography variant="body2">Remove</Typography>
                    </Ref>
                  </Center>
                </Stack>
              )}
            </Stack>
          )}
        </Dropzone>
      </Box>
    </Root>
  );
});

const defValue = [];

UploadAvatar.Control = forwardRef((props, ref) => {
  const {
    name,
    defaultValue = defValue,
    onChange,
    onBlur,
    inputRef,
    ...rest
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <UploadAvatar
          value={field.value}
          ref={ref}
          inputRef={propagateRefs(inputRef, field.ref)}
          onChange={(...args) => {
            field.onChange(...args);
            getFunc(onChange)(...args);
          }}
          onBlur={(...args) => {
            field.onBlur(...args);
            getFunc(onBlur)(...args);
          }}
          {...rest}
        />
      )}
    />
  );
});

if (dev) {
  UploadAvatar.Demo = lazy(() => import('./UploadAvatar.demo'));
}

export default UploadAvatar;
