import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';

import {
  SCALE_TYPE,
  SCALE_TYPE_LIST,
  ENGINEER_SCALES_LIST,
  ARCHITECT_SCALES_LIST,
  SCALE_VALUES,
} from 'constants';

import { useWatchField, withProps } from 'hooks';
import { AdvancedSliderField, ProjectSubmitButton } from 'views';
import { Note, feetToString, getArray } from 'utils';
import {
  Button,
  Center,
  Icon,
  Switch,
  ProgressBox,
  RadioButtons,
  SelectField,
  SwitchField,
  TextField,
} from 'components';

const CloseIcon = withProps(Icon.Close, {
  sx: {
    fontSize: 12,
    borderRadius: '50%',
    color: 'common.white',
    bgcolor: 'secondary.main',
  },
});

const getScaleOptions = (type) => {
  switch (type) {
    case SCALE_TYPE.ENGINEER:
      return ENGINEER_SCALES_LIST;
    case SCALE_TYPE.ARCHITECT:
      return ARCHITECT_SCALES_LIST;
    default:
      return [];
  }
};

const getNotesSwitchLabel = (label, disabled) => {
  return !disabled ? label : `${label} (No notes available)`;
};

const ElectricalPlanForm = () => {
  const {
    api,
    project,
    resetField,
    loading,
    nextStep,
    isFirstSaving,
    notes,
    setValue,
    control,
    canvasChanges,
    allowChange,
    changeHandler,
  } = useFormContext();

  const scale = useWatchField('scale');
  const scale_type = useWatchField('scale_type');
  const north_angle = useWatchField('north_angle');
  const showAllNotes = useWatchField('notes_all');
  const showCountyNotes = useWatchField('notes_county');
  const showUtilityNotes = useWatchField('notes_utility');
  const showCompanyNotes = useWatchField('notes_company');
  const showCustomNotes = useWatchField('notes_custom');
  const customNotesData = useWatchField('custom_notes');
  const fireClearance = useWatchField('fire_clearance');

  const {
    fields: customNotes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'custom_notes',
    keyName: 'field_id',
  });

  const scaleOptions = useMemo(() => {
    return getScaleOptions(scale_type);
  }, [scale_type]);

  const countyNotesDisabled = getArray(notes.county).length === 0 && !loading;
  const utilityNotesDisabled = getArray(notes.utility).length === 0 && !loading;
  const companyNotesDisabled = getArray(notes.company).length === 0 && !loading;

  const countyNotesLabel = getNotesSwitchLabel(
    'County Notes',
    countyNotesDisabled
  );
  const utilityNotesLabel = getNotesSwitchLabel(
    'Utility Notes',
    utilityNotesDisabled
  );
  const companyNotesLabel = getNotesSwitchLabel(
    'Company Notes',
    companyNotesDisabled
  );

  // Update scale
  useEffect(() => {
    if (api) {
      const scaleValue = SCALE_VALUES[scale];
      const scaleOption = scaleOptions.find((o) => o.value === scale);

      const v1 = '0';
      const v2 = feetToString(scaleValue / 2);
      const v3 = feetToString(scaleValue);

      const scaleOrigin = project?.scale;
      const scaleOriginValue = SCALE_VALUES[scaleOrigin];
      const layersScale = scaleOriginValue / scaleValue;

      api.updateScaleMeta({
        scaleBarValues: [v1, v2, v3],
        scaleLabel: scaleOption?.label,
      });
      api.setLayersScale(layersScale);
    }
  }, [api, scale, scaleOptions, project]);

  // Update fire clearance
  useEffect(() => {
    if (api) {
      api.toggleFireClearance(fireClearance);
    }
  }, [api, fireClearance]);

  // Update north angle
  useEffect(() => {
    if (api) {
      api.updateNorthAngle(north_angle);
    }
  }, [api, north_angle]);

  // Canvas: update custom notes text
  useEffect(() => {
    if (api) {
      const updateNotesTimeout = setTimeout(() => {
        getArray(customNotesData).forEach((n) => {
          const noteInstance = api.getNote(n.id);

          if (noteInstance) {
            noteInstance.update(n);
          }
        });
      }, 500);

      return () => clearTimeout(updateNotesTimeout);
    }
  }, [customNotesData, api]);

  // Canvas: update notes visibility
  useEffect(() => {
    const notesState = {
      county: showCountyNotes,
      utility: showUtilityNotes,
      company: showCompanyNotes,
      custom: showCustomNotes,
    };
    if (api) {
      api.getNotes().forEach((note) => {
        note.update({
          visible: notesState[note.data.type],
        });
      });
    }
  }, [
    showCompanyNotes,
    showCountyNotes,
    showCustomNotes,
    showUtilityNotes,
    api,
  ]);

  useEffect(() => {
    const isAllNotes = [
      countyNotesDisabled || showCountyNotes,
      utilityNotesDisabled || showUtilityNotes,
      companyNotesDisabled || showCompanyNotes,
      showCustomNotes,
    ].every(Boolean);

    setValue('notes_all', isAllNotes);
  }, [
    companyNotesDisabled,
    countyNotesDisabled,
    setValue,
    showCountyNotes,
    showCompanyNotes,
    showCustomNotes,
    showUtilityNotes,
    utilityNotesDisabled,
  ]);

  const handleAllNotesChange = useCallback(() => {
    if (!countyNotesDisabled) {
      setValue('notes_county', !showAllNotes);
    }
    if (!utilityNotesDisabled) {
      setValue('notes_utility', !showAllNotes);
    }
    if (!companyNotesDisabled) {
      setValue('notes_company', !showAllNotes);
    }
    setValue('notes_custom', !showAllNotes);
  }, [
    countyNotesDisabled,
    utilityNotesDisabled,
    companyNotesDisabled,
    setValue,
    showAllNotes,
  ]);

  const handleScaleTypeValue = useCallback(
    (v) => {
      const newOptions = getScaleOptions(v);

      resetField('scale', {
        defaultValue: newOptions[0].value,
      });
    },
    [resetField]
  );

  const handleAddCustomNote = useCallback(() => {
    const newNote = new Note();

    if (api) {
      api.addNote({
        ...newNote,
        type: 'custom',
        visible: true,
      });
    }
    append(newNote);
  }, [append, api]);

  const handleAngleValue = useCallback(
    (e, v) => {
      if (!allowChange) {
        changeHandler(setValue)('north_angle', v);
        return false;
      }
    },
    [allowChange, changeHandler, setValue]
  );

  const handleScaleValue = useCallback(
    (e) => {
      if (!allowChange) {
        changeHandler(setValue)('scale', e.target.value);
        return false;
      }
    },
    [allowChange, changeHandler, setValue]
  );

  const handleScaleTypeChange = useCallback(
    (e, v) => {
      if (!allowChange) {
        const handleChange = (value) => {
          setValue('scale_type', value);
          handleScaleTypeValue(value);
        };
        changeHandler(handleChange)(v.value);
        return false;
      }
    },
    [allowChange, changeHandler, setValue, handleScaleTypeValue]
  );

  return (
    <ProgressBox progress={loading}>
      <Stack spacing={1} direction="column" alignItems="flex-start">
        <RadioButtons.Control
          size="medium"
          variant="flat"
          name="scale_type"
          label="Scale Type"
          options={SCALE_TYPE_LIST}
          onValue={handleScaleTypeValue}
          onBeforeChange={handleScaleTypeChange}
        />

        <SelectField.Control
          name="scale"
          label="Scale"
          displayEmpty={false}
          options={scaleOptions}
          onBeforeChange={handleScaleValue}
        />

        <AdvancedSliderField.Control
          angular
          max={360}
          name="north_angle"
          label="North Mark Rotation"
          onBeforeChange={handleAngleValue}
        />
      </Stack>

      <Center justifyContent="space-between" my={2}>
        <Typography variant="subtitle2">Fire Clearance</Typography>
        <Switch.Control name="fire_clearance" />
      </Center>

      <Stack spacing={2} direction="column">
        <Typography variant="subtitle2">Notes</Typography>

        <SwitchField.Control
          name="notes_all"
          label="All Notes"
          onChange={handleAllNotesChange}
        />

        <SwitchField.Control
          name="notes_county"
          label={countyNotesLabel}
          disabled={countyNotesDisabled}
        />

        <SwitchField.Control
          name="notes_utility"
          label={utilityNotesLabel}
          disabled={utilityNotesDisabled}
        />

        <SwitchField.Control
          name="notes_company"
          label={companyNotesLabel}
          disabled={companyNotesDisabled}
        />

        <SwitchField.Control name="notes_custom" label="Custom Notes" />
      </Stack>

      <Collapse in={showCustomNotes}>
        <Box mt={3}>
          {customNotes.map((n, i) => {
            const handleRemoveNote = () => {
              api.removeNote(n.id);
              remove(i);
            };
            return (
              <Box key={n.field_id} mb={1}>
                <TextField.Control
                  required
                  multiline
                  minRows={5}
                  maxRows={10}
                  hideAsterisk
                  name={`custom_notes.${i}.text`}
                  label={
                    <Center justifyContent="space-between">
                      <span>Custom Note {i + 1}</span>

                      <IconButton size="small" onClick={handleRemoveNote}>
                        <CloseIcon />
                      </IconButton>
                    </Center>
                  }
                />
              </Box>
            );
          })}

          <Box mt={2}>
            <Button
              size="medium"
              fullWidth={false}
              variant="outlined"
              onClick={handleAddCustomNote}
            >
              Add note
            </Button>
          </Box>
        </Box>
      </Collapse>

      <Box mt={4}>
        <ProjectSubmitButton
          nextStep={nextStep}
          allowContinue={!canvasChanges}
          isFirstSaving={isFirstSaving}
        />
      </Box>
    </ProgressBox>
  );
};

export default ElectricalPlanForm;
