import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

import {
  useGetProjectDetailsQuery,
  useGetContractorCompaniesQuery,
  useUpdateProjectsContractorMutation,
  useCreateContractorCompanyMutation,
  useUploadToTemporaryStorageMutation,
} from 'store';

import { useAuth, useIsLoading, withProps } from 'hooks';
import { routes } from 'routes';
import {
  emptyFieldsToNulls,
  filterObjectFields,
  getArray,
  getString,
  removeEmptyFields,
} from 'utils';

import { ROLES, property_image_width, property_image_height } from 'constants';
import { ProjectDetailsLayout } from 'layouts';
import { ProjectPreview, ProjectSubmitButton } from 'views';
import {
  Center,
  Form,
  Image,
  PhoneField,
  ProgressBox,
  SelectField,
  TextField,
  useMessage,
  Delimiter as OriginalDelimiter,
} from 'components';

import CompanyDetailsFields from './CompanyDetailsFields';
import CompanyEditMessage from './CompanyEditMessage';
import CompanyAddress from './CompanyAddress';

const Delimiter = withProps(OriginalDelimiter, {
  my: 2,
});

const ContractorCompany = () => {
  const { project_id } = useParams();
  const navigate = useNavigate();
  const m = useMessage();
  const { role, premium } = useAuth();

  const queryProject = useGetProjectDetailsQuery({ project_id });
  const [updateContractor, updateQuery] = useUpdateProjectsContractorMutation();
  const [createCompany, createQuery] = useCreateContractorCompanyMutation();
  const [uploadFile, uploadQuery] = useUploadToTemporaryStorageMutation();

  const project = queryProject.data;

  const companiesParams = useMemo(() => {
    return {
      state: project?.address?.state,
    };
  }, [project?.address?.state]);

  const queryCompanies = useGetContractorCompaniesQuery(
    { params: companiesParams },
    {
      skip: !project,
    }
  );
  const companies = queryCompanies.data;
  const form = Form.useForm();
  const loading = useIsLoading(
    queryProject,
    queryCompanies,
    updateQuery,
    createQuery,
    uploadQuery
  );
  const isFirstSaving = !project?.contractor;
  const nextStep = useMemo(() => {
    if (!premium) {
      return routes.arrays_parameters.path({ project_id });
    }
    return routes.preliminary_plan.path({ project_id });
  }, [premium, project_id]);

  const companyOptions = useMemo(() => {
    return [
      role === ROLES.OWNER && {
        value: 'new',
        label: 'New Company',
      },
      ...getArray(companies).map((c) => ({
        value: c.id,
        label: c.name,
      })),
    ].filter(Boolean);
  }, [companies, role]);

  useEffect(() => {
    const currentValues = form.getValues();

    form.reset({
      ...currentValues,
      contractor_id: project?.contractor?.id || 'new',
      pm_name: getString(project?.pm_name),
      pm_phone: getString(project?.pm_phone),
    });
  }, [form, project]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const { contractor_id, pm_phone, pm_name, ...companyData } = formData;

        const body = removeEmptyFields({
          contractor_id,
          pm_phone,
          pm_name,
        });
        if (contractor_id === 'new') {
          const { images, ...restData } = companyData;
          const [file] = images;
          const body = {
            ...emptyFieldsToNulls(restData),
            address: filterObjectFields(restData.address, [
              'city',
              'state',
              'zip_code',
              'address_line1',
              'address_line2',
            ]),
          };
          if (file) {
            const fileResponse = await uploadFile({
              body: { file },
            }).unwrap();

            body.image = fileResponse.file_key;
          }
          const response = await createCompany({ body }).unwrap();
          body.contractor_id = response.company_id;
        }
        await updateContractor({ project_id, body }).unwrap();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [
      createCompany,
      isFirstSaving,
      m,
      navigate,
      nextStep,
      project_id,
      updateContractor,
      uploadFile,
    ]
  );

  return (
    <ProjectDetailsLayout
      form={form}
      component={Form}
      title="Contractor Company"
      onSubmit={handleSubmit}
      body={
        <ProgressBox progress={loading}>
          <Stack spacing={1} alignItems="flex-start">
            <SelectField.Control
              name="contractor_id"
              label="Company"
              defaultValue="new"
              displayEmpty={false}
              options={companyOptions}
              initialFocus
            />

            <CompanyDetailsFields />

            <TextField.Control
              name="pm_name"
              label="Project Manager Full Name"
              rules={{
                minLength: {
                  value: 2,
                  message: 'PM Name must be at least 2 characters length',
                },
              }}
            />

            <PhoneField.Control
              name="pm_phone"
              label="Project Manager Phone Number"
            />

            <CompanyEditMessage />
          </Stack>

          <Delimiter />

          <Stack spacing={1} alignItems="flex-start">
            <Typography variant="subtitle2">
              Contractor Company Address
            </Typography>

            <CompanyAddress />
          </Stack>

          <Box mt={4}>
            <ProjectSubmitButton
              nextStep={nextStep}
              isFirstSaving={isFirstSaving}
            />
          </Box>
        </ProgressBox>
      }
    >
      <ProjectPreview>
        <Center flexGrow={1}>
          <Image
            src={project?.property_image}
            width={property_image_width}
            height={property_image_height}
          />
        </Center>
      </ProjectPreview>
    </ProjectDetailsLayout>
  );
};

export default ContractorCompany;
