import { Group, Shape } from 'paper';

import { getArray } from 'utils';
import PaperSvg from 'components/Paper/PaperSvg';
import PaperDraggable from 'components/Paper/PaperDraggable';

class PaperLayers extends PaperDraggable {
  constructor(options) {
    const element = new Group({ name: 'layers_group' });
    super({ element, ...options });

    this.layers_container = new Group({ name: 'layers_container_group' });
    this.rect = new Shape.Rectangle({
      point: [0, 0],
      size: [0, 0],
      fillColor: 'white',
    });
    element.addChildren([this.rect, this.layers_container]);
    this.disableDragging();
  }

  setLayers(layers, callback) {
    const done = [];
    this.layers = getArray(layers);
    this.layers_container.removeChildren();
    this.element.visible = false;

    const afterUpload = (item) => {
      done.push(item);

      if (done.length === layers.length) {
        this.layers_container.children.forEach((child) => {
          child.bounds.center = this.rect.bounds.center;
        });
        this.element.visible = true;
        this.rect.size.width = this.layers_container.bounds.width;
        this.rect.size.height = this.layers_container.bounds.height;
        this.rect.bounds.center = this.layers_container.bounds.center;
        callback && callback(item);
      }
    };
    const svgItems = this.layers.map((layer) => {
      const svgItem = new PaperSvg({
        scope: this.scope,
        theme: this.theme,
      });
      svgItem.upload(layer, afterUpload);
      return svgItem;
    });
    const svgElements = svgItems.map((item) => item.element);
    this.layers_container.addChildren(svgElements);
  }

  addLayers(layers, callback) {
    this.setLayers([...getArray(this.layers), ...getArray(layers)], callback);
  }

  clearLayers() {
    this.layers_container.removeChildren();
    this.layers = null;
  }

  fit(element, type = 'fill') {
    const center = this.toGlobal(element.bounds.center, element.parent);
    this.element.bounds.center = this.element.parent.globalToLocal(center);

    this.layers_container.children.forEach((child) => {
      if (type === 'fill') {
        child.bounds.width = element.bounds.width;
        child.bounds.height = element.bounds.height;
      }
      if (type === 'contain') {
        if (child.bounds.width >= child.bounds.height) {
          const ratio = child.bounds.width / child.bounds.height;
          child.bounds.width = element.bounds.width;
          child.bounds.height = child.bounds.width / ratio;
        } else {
          const ratio = child.bounds.height / child.bounds.width;
          child.bounds.height = element.bounds.height;
          child.bounds.width = child.bounds.height / ratio;
        }
      }
      if (type === 'cover') {
        if (child.bounds.height >= child.bounds.width) {
          const ratio = child.bounds.width / child.bounds.height;
          child.bounds.width = element.bounds.width;
          child.bounds.height = child.bounds.width / ratio;
        } else {
          const ratio = child.bounds.height / child.bounds.width;
          child.bounds.height = element.bounds.height;
          child.bounds.width = child.bounds.height / ratio;
        }
      }
      child.bounds.center = child.parent.globalToLocal(center);
    });
    this.rect.size.width = this.layers_container.bounds.width;
    this.rect.size.height = this.layers_container.bounds.height;
    this.rect.bounds.center = this.layers_container.bounds.center;
  }
}

export default PaperLayers;
