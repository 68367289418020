import { forwardRef, lazy, useCallback } from 'react';
import { Box } from '@mui/material';

import { dev } from 'constants';
import { getFunc, fromRange } from 'utils';
import {
  Center,
  FormControl,
  FormLabel,
  HelperText,
  Input,
  Slider,
  TextField,
} from 'components';

const AdvancedSliderField = forwardRef((props, ref) => {
  const {
    label,
    error,
    value: val,
    min = 0,
    max = 100,
    step = 1,
    onChange,
    onValue,
    disabled,
    fullWidth = true,
    required,
    helperText,
    hideAsterisk,
    inputRef,
    initialFocus,
    reserveHelperTextSpace = true,
    InputLabelProps,
    onBeforeChange,
    angular,
    ...rest
  } = props;

  const value = fromRange(val, min, max);

  const handleSliderChange = useCallback(
    (e, v, ...args) => {
      const allowChange = getFunc(onBeforeChange)(e, v, ...args);

      if (allowChange === false) {
        return;
      }
      getFunc(onChange)(e, v, ...args);
      getFunc(onValue)(v);
    },
    [onChange, onValue, onBeforeChange]
  );

  const handleInputChange = useCallback(
    (e) => {
      const v = fromRange(+e.target.value, min, max);
      const allowChange = getFunc(onBeforeChange)(e, v);

      if (allowChange === false) {
        return;
      }
      getFunc(onChange)(e, v);
      getFunc(onValue)(v);
    },
    [max, min, onChange, onValue, onBeforeChange]
  );

  return (
    <FormControl ref={ref} fullWidth={fullWidth} {...rest}>
      {label && (
        <FormLabel
          error={error}
          required={required}
          disabled={disabled}
          hideAsterisk={hideAsterisk}
          {...InputLabelProps}
        >
          {label}
        </FormLabel>
      )}

      <Center pl={1} gap={6} justifyContent="space-between">
        <Slider
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleSliderChange}
        />

        <Box minWidth={80}>
          <Input
            fullWidth
            type="number"
            value={value}
            inputRef={inputRef}
            initialFocus={initialFocus}
            onChange={handleInputChange}
            inputProps={{ min, max, step }}
            endAdornment={
              <>
                {angular && (
                  <Box component="span" fontSize={16} fontWeight={400}>
                    º
                  </Box>
                )}
              </>
            }
          />
        </Box>
      </Center>

      <HelperText
        error={error}
        disabled={disabled}
        reserveSpace={reserveHelperTextSpace}
      >
        {helperText}
      </HelperText>
    </FormControl>
  );
});

const defaultControlChangeHandler = (field) => (e, v) => {
  field.onChange(v);
};

AdvancedSliderField.Control = forwardRef((props, ref) => {
  const {
    ControlComponent = AdvancedSliderField,
    controlChangeHandler = defaultControlChangeHandler,
    ...rest
  } = props;

  return (
    <TextField.Control
      ref={ref}
      ControlComponent={ControlComponent}
      controlChangeHandler={controlChangeHandler}
      {...rest}
    />
  );
});

if (dev) {
  AdvancedSliderField.Demo = lazy(() => import('./AdvancedSliderField.demo'));
}

export default AdvancedSliderField;
