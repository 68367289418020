import { forwardRef, lazy, useMemo } from 'react';
import { IconButton, InputAdornment } from '@mui/material';

import { dev } from 'constants';
import { useToggle } from 'hooks';
import { rules as defaultRules } from 'utils';

import TextField from 'components/TextField';
import Icon from 'components/Icon';

const PasswordField = forwardRef((props, ref) => {
  const { type = 'password', ...rest } = props;

  const [showPassword, toggleShowPassword] = useToggle(type !== 'password');

  const endAdornment = useMemo(
    () => (
      <InputAdornment position="end">
        <IconButton
          edge="end"
          size="small"
          onClick={toggleShowPassword}
          aria-label="toggle password visibility"
          sx={{ color: 'primary.main' }}
        >
          {showPassword ? <Icon.VisibilityOff /> : <Icon.VisibilityOn />}
        </IconButton>
      </InputAdornment>
    ),
    [toggleShowPassword, showPassword]
  );

  return (
    <TextField
      ref={ref}
      {...rest}
      type={showPassword ? 'text' : 'password'}
      endAdornment={endAdornment}
    />
  );
});

PasswordField.Control = forwardRef((props, ref) => {
  const { rules: customRules, innerValidation = true, ...rest } = props;

  const rules = useMemo(() => {
    const result = {
      ...customRules,
      validate: {
        ...customRules?.validate,
      },
    };
    if (innerValidation) {
      result.validate.password = defaultRules.password;
    }
    return result;
  }, [customRules, innerValidation]);

  return (
    <TextField.Control
      ref={ref}
      {...rest}
      rules={rules}
      ControlComponent={PasswordField}
    />
  );
});

if (dev) {
  PasswordField.Demo = lazy(() => import('./PasswordField.demo'));
}

export default PasswordField;
