import { useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { useVerifyEmailMutation } from 'store';
import { routes } from 'routes';
import { ProgressBox, useMessage } from 'components';

const EmailVerified = () => {
  const { hash } = useParams();

  const m = useMessage();
  const navigate = useNavigate();
  const [verifyEmail, { isError }] = useVerifyEmailMutation();

  const handleVerifyEmail = useCallback(async () => {
    try {
      const body = { hash };
      await verifyEmail({ body }).unwrap();
      m.success('Email has been verified!');
      navigate(routes.sign_in.path());
    } catch (ex) {
      m.responseError(ex);
    }
  }, [hash, verifyEmail, m, navigate]);

  useEffect(() => {
    handleVerifyEmail();
  }, [handleVerifyEmail]);

  return (
    <ProgressBox
      progress={!isError}
      flexGrow={1}
      overflow="hidden"
      display="flex"
    >
      {isError && (
        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">Email is not verified...</Typography>
        </Box>
      )}
    </ProgressBox>
  );
};

export default EmailVerified;
