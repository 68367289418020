import { forwardRef, lazy } from 'react';
import Box from '@mui/material/Box';

import { dev } from 'constants';
import Image from 'components/Image';

// TODO: Think about objectPosition (origin)
const BackgroundBox = forwardRef((props, ref) => {
  const {
    position = 'relative',
    objectFit,
    src,
    transition,
    children,
    imageProps,
    contentProps,
    ...rest
  } = props;

  return (
    <Box {...rest} ref={ref} position={position}>
      <Box position="relative" zIndex={1} width={1} {...contentProps}>
        {children}
      </Box>

      <Image
        top={0}
        left={0}
        src={src}
        width="100%"
        height="100%"
        position="absolute"
        objectFit={objectFit}
        transition={transition}
        {...imageProps}
      />
    </Box>
  );
});

if (dev) {
  BackgroundBox.Demo = lazy(() => import('./BackgroundBox.demo'));
}

export default BackgroundBox;
