import { forwardRef, lazy } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

import { useImagePreload, withProps } from 'hooks';
import { dev } from 'constants';

const ImgBox = withProps(Box, {
  component: 'img',
});

const Img = styled(ImgBox, {
  label: 'Image',
  shouldForwardProp: (prop) =>
    !['transition', 'loading', 'error', 'objectFit'].includes(prop),
})((props) => {
  const {
    theme,
    loading,
    error,
    src,
    transition,
    transform,
    opacity,
    objectFit,
  } = props;

  return {
    objectFit,
    transform,
    opacity: !error && (loading || !src) ? 0 : opacity || 1,
    transition:
      !transition || !src
        ? 'none'
        : theme.transitions.create(['all'], {
            duration: (() => {
              if (Number.isFinite(transition)) {
                return transition;
              }
              if (theme.transitions.duration[transition]) {
                return theme.transitions.duration[transition];
              }
              return theme.transitions.duration.shortest;
            })(),
          }),
  };
});

/**
 * The same as native <img> tag, but allows to avoid partical image rendering effect. Take a look
 * at useImagePreload hook description.
 * Also it allow to show image right after loading with a smooth opacity transition.
 * You can disable transition passing [transition=false], or change transition duration passing
 * [transition=<NUMBER_OF_MILISECONDS>]
 *
 * TODO: Think about loading process visualization
 * TODO: Fallback image
 */
const Image = forwardRef((props, ref) => {
  const {
    objectFit = 'cover',
    width = 'auto',
    height = 'auto',
    src: link,
    transition = true,
    alt = 'No description',
    overflow = 'hidden',
    delay,
    ...rest
  } = props;

  const { src, error, loading } = useImagePreload({
    delay,
    src: link,
  });

  return (
    <Img
      overflow={overflow}
      height={height}
      width={width}
      ref={ref}
      src={src}
      objectFit={objectFit}
      alt={alt}
      error={error}
      loading={loading}
      transition={transition}
      {...rest}
    />
  );
});

if (dev) {
  Image.Demo = lazy(() => import('./Image.demo'));
}

export default Image;
