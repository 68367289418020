import {
  Children,
  cloneElement,
  forwardRef,
  lazy,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import { Popover as MuiPopover } from '@mui/material';

import { dev } from 'constants';
import { getFunc } from 'utils';
import { makeClasses, propagateRefs, useToggle, withProps } from 'hooks';
import Slip from 'components/Slip';

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const ClearPopover = withProps(MuiPopover, {
  elevation: 0,
});

const StyledPopover = makeClasses(ClearPopover, {
  shouldForwardProp: (prop) => !['elevation', 'passEvents'].includes(prop),
})({
  root: ({ passEvents }) => ({
    pointerEvents: passEvents ? 'none' : undefined,
  }),
  paper: ({ theme, elevation, passEvents }) => ({
    boxShadow: theme.makeShadow(elevation),
    pointerEvents: passEvents ? 'all' : undefined,
  }),
});

const DropdownPopover = forwardRef((props, ref) => {
  const {
    anchor,
    onOpen,
    onClose,
    PaperProps,
    elevation = 4,
    open: customOpen,
    anchorOrigin = defaultAnchorOrigin,
    passEvents,
    ...rest
  } = props;

  const triggerRef = useRef();
  const [open, toggleOpen] = useToggle(!!customOpen);

  const openState = useMemo(() => {
    return typeof customOpen === 'boolean' ? customOpen : open;
  }, [customOpen, open]);

  const trigger = useMemo(() => {
    const targetChilds = Children.toArray(anchor);

    if (targetChilds.length > 1) {
      console.error(
        '[Popover]: only one node allowed as a target (first one will be used)'
      );
    }
    return (
      targetChilds[0] &&
      cloneElement(targetChilds[0], {
        ref: propagateRefs(triggerRef, targetChilds[0].ref),
      })
    );
  }, [anchor]);

  const handleClose = useCallback(
    (...args) => {
      toggleOpen.off();
      getFunc(onClose)(...args);
    },
    [toggleOpen, onClose]
  );

  useLayoutEffect(() => {
    const { current: triggerElement } = triggerRef;

    if (triggerElement) {
      const listenClick = (...args) => {
        toggleOpen.on();
        getFunc(onOpen)(...args);
      };
      triggerElement.addEventListener('click', listenClick);

      return () => {
        triggerElement.removeEventListener('click', listenClick);
      };
    }
  }, [toggleOpen, onOpen]);

  if (!trigger) {
    return null;
  }
  return (
    <>
      {trigger}

      <StyledPopover
        ref={ref}
        anchorOrigin={anchorOrigin}
        passEvents={passEvents}
        {...rest}
        open={openState}
        onClose={handleClose}
        anchorEl={triggerRef?.current}
        elevation={elevation}
        PaperProps={{
          ...PaperProps,
          component: Slip,
        }}
      />
    </>
  );
});

if (dev) {
  DropdownPopover.Demo = lazy(() => import('./DropdownPopover.demo'));
}

export default DropdownPopover;
