import { forwardRef, lazy, useMemo } from 'react';
import { SvgIcon } from '@mui/material';

import { dev } from 'constants';
import * as icons from 'assets/icons';
import { snakeCaseToCamelCase } from 'utils';
import { withProps } from 'hooks';
import SvgLoader from 'components/SvgLoader';

const IconComponents = {};

const createIconComponent = (src) => {
  const InlineIcon = withProps(SvgLoader, { src });

  /**
   * @prop {number} [rotate=0] - Rotate icon
   * @prop {boolean} [flipX] - Flip horizontaly
   * @prop {boolean} [flipY] - Flip vertically
   */
  return forwardRef((props, ref) => {
    const { sx, rotate = 0, flipX, flipY, ...rest } = props;

    const transform = useMemo(() => {
      return [
        `rotate(${rotate}deg)`,
        flipX && 'scaleX(-1)',
        flipY && 'scaleY(-1)',
      ]
        .filter(Boolean)
        .join(' ');
    }, [rotate, flipX, flipY]);

    return (
      <SvgIcon
        {...rest}
        ref={ref}
        component={InlineIcon}
        sx={{
          transform,
          transition: (theme) =>
            theme.transitions.create(['transform'], {
              duration: theme.transitions.duration.shortest,
            }),
          ...sx,
        }}
      />
    );
  });
};

const Icon = forwardRef((props, ref) => {
  const { name, ...rest } = props;
  const TargetIcon = IconComponents[name];

  return !TargetIcon || !name ? null : <TargetIcon {...rest} ref={ref} />;
});

Object.entries(icons).forEach(([name, path]) => {
  const ComponentName = snakeCaseToCamelCase(name, true);
  const Component = createIconComponent(path);
  IconComponents[name] = Component;
  Icon[ComponentName] = Component;
});

if (dev) {
  Icon.Demo = lazy(() => import('./Icon.demo'));
}

export default Icon;
