import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { useToggle, useWatchField } from 'hooks';
import { DisconnectModal, DisconnectInfo } from 'views';
import { Center, Delimiter, Switch } from 'components';

const DisconnectFields = () => {
  const { setValue } = useFormContext();

  const [disconnectModal, toggleDisconnectModal] = useToggle();

  const disconnect = useWatchField('disconnect_enabled');
  const disconnect_id = useWatchField('disconnect_id');
  const disconnect_label = useWatchField('disconnect_label');

  const handleCloseModal = useCallback(() => {
    if (!disconnect_id) {
      setValue('disconnect_enabled', false);
      setValue('disconnect_id', '');
      setValue('disconnect_label', '');
    }
    toggleDisconnectModal.off();
  }, [disconnect_id, setValue, toggleDisconnectModal]);

  const handleSubmitModal = useCallback(
    (newData) => {
      setValue('disconnect_id', newData.disconnect_id, {
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue('disconnect_label', newData.disconnect_label, {
        shouldDirty: true,
        shouldTouch: true,
      });
      toggleDisconnectModal.off();
    },
    [toggleDisconnectModal, setValue]
  );

  return (
    <>
      <DisconnectModal
        open={disconnectModal}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        data={{
          disconnect,
          disconnect_id,
          disconnect_label,
        }}
      />

      <Box mt={2}>
        <Center justifyContent="space-between">
          <Typography variant="subtitle2">Extra AC Disconnect</Typography>

          <Switch.Control name="disconnect_enabled" />
        </Center>

        <DisconnectInfo.Control
          open={disconnect}
          name="disconnect_id"
          description={disconnect_label}
          onEdit={toggleDisconnectModal.on}
        />

        <Delimiter mt={2} />
      </Box>
    </>
  );
};

export default DisconnectFields;
