import { useMemo } from 'react';

import { getArray } from 'utils';
import { SelectField } from 'components';

const WteSelectField = (props) => {
  const { wte: wteList, ...rest } = props;

  const options = useMemo(() => {
    return getArray(wteList).map((wte) => ({
      value: wte.id,
      label: `${wte.vendor.title} - ${wte.title}`,
    }));
  }, [wteList]);

  return (
    <SelectField.Control
      {...rest}
      required
      displayEmpty={false}
      options={options}
    />
  );
};

export default WteSelectField;
