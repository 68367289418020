import { useCallback } from 'react';
import { Box } from '@mui/material';

import { getFunc } from 'utils';
import { useRemoveContractorCompanyMutation } from 'store';
import { Modal, Button, ProgressBox, useMessage } from 'components';

const DeleteCompanyModal = (props) => {
  const { open, company, onClose, ...rest } = props;

  const m = useMessage();
  const [removeCompany, { isLoading }] = useRemoveContractorCompanyMutation();

  const handleClose = useCallback(() => {
    getFunc(onClose)();
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    try {
      await removeCompany({ company_id: company?.id }).unwrap();
      m.success('Company has been remove!');
      handleClose();
    } catch (ex) {
      m.responseError(ex);
    }
  }, [m, removeCompany, handleClose, company]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      {...rest}
      title="Delete Company"
      actions={
        <Box display="flex" gap={2}>
          <Button fullWidth={false} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>

          <Button minWidth={200} fullWidth={false} onClick={handleSubmit}>
            Confirm
          </Button>
        </Box>
      }
    >
      <ProgressBox progress={isLoading}>
        <Box textAlign="center" mt={1}>
          Are you sure you want to delete this company?
        </Box>
      </ProgressBox>
    </Modal>
  );
};

export default DeleteCompanyModal;
