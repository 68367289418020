import { Group } from 'paper';

import { getString } from 'utils';
import PaperText from 'components/Paper/PaperText';

function PaperMultilineText(options) {
  const { content: initContent, uppercase, ...rest } = options;
  const container = new Group({ name: 'paper-multiline-text-group' });

  function update(content) {
    container.removeChildren();
    const texts = Array.isArray(content) ? content : [content];

    texts.forEach((t) => {
      container.addChild(
        new PaperText({
          ...rest,
          content: uppercase ? getString(t).toUpperCase() : t,
        })
      );
    });
    container.children.forEach((t, i, all) => {
      if (i > 0) {
        t.bounds.topLeft = all[i - 1].bounds.bottomLeft;
      }
    });
  }
  container.update = update;
  update(initContent);
  return container;
}

export default PaperMultilineText;
