import { forwardRef, lazy, useMemo } from 'react';
import { IconButton as MuiIconButton, InputAdornment } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { withProps } from 'hooks';
import { dev } from 'constants';
import TextField from 'components/TextField';

const createIconStyles = (color) => (theme) => ({
  color: `${color}.light`,
  transition: theme.transitions.create(),
  '&:hover': {
    color: `${color}.main`,
  },
});

const IconButton = withProps(MuiIconButton, {
  size: 'small',
  edge: 'end',
});

const ConfirmTextField = forwardRef((props, ref) => {
  const { onConfirm, onCancel, confirmDisabled, ...rest } = props;

  const endAdornment = useMemo(() => {
    return (
      <InputAdornment position="end">
        <IconButton
          disabled={confirmDisabled}
          onClick={onConfirm}
          sx={createIconStyles('success')}
        >
          <CheckIcon />
        </IconButton>

        <IconButton onClick={onCancel} sx={createIconStyles('error')}>
          <CancelIcon />
        </IconButton>
      </InputAdornment>
    );
  }, [onConfirm, confirmDisabled, onCancel]);

  return <TextField ref={ref} {...rest} endAdornment={endAdornment} />;
});

ConfirmTextField.Control = forwardRef((props, ref) => {
  const { ControlComponent = ConfirmTextField, ...rest } = props;

  return (
    <TextField.Control
      ref={ref}
      ControlComponent={ControlComponent}
      {...rest}
    />
  );
});

if (dev) {
  ConfirmTextField.Demo = lazy(() => import('./ConfirmTextField.demo'));
}

export default ConfirmTextField;
