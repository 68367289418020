// GROUNDING ELECTRODE CONDUCTOR
const GEC_EGC_COMBINED = 'GEC/EGC Combined';
const GEC_EGC_SEPARATED = 'GEC&EGC separated';

const GROUNDING_ELECTRODE_CONDUCTOR_OPTIONS_NAMES = {
  [`${GEC_EGC_COMBINED}`]: GEC_EGC_COMBINED,
  [`${GEC_EGC_SEPARATED}`]: GEC_EGC_SEPARATED,
};
export const GROUNDING_ELECTRODE_CONDUCTOR_OPTIONS_LIST = [
  GEC_EGC_COMBINED,
  GEC_EGC_SEPARATED,
].map((value) => ({
  value,
  label: GROUNDING_ELECTRODE_CONDUCTOR_OPTIONS_NAMES[value],
}));

// AC DISCONNECT BRAND NAME
const SQUARE_D = 'Square D';
const EATON = 'Eaton';
const CUTTLER_HUMMER = 'Cuttler Hummer';
const SIEMENS = 'Siemens';
const GENERAL_ELECTRIC = 'General Electrci';

const AC_DISCONNECT_BRAND_NAME_OPTIONS_NAMES = {
  [`${SQUARE_D}`]: SQUARE_D,
  [`${EATON}`]: EATON,
  [`${CUTTLER_HUMMER}`]: CUTTLER_HUMMER,
  [`${SIEMENS}`]: SIEMENS,
  [`${GENERAL_ELECTRIC}`]: GENERAL_ELECTRIC,
};
export const AC_DISCONNECT_BRAND_NAME_OPTIONS_LIST = [
  SQUARE_D,
  EATON,
  CUTTLER_HUMMER,
  SIEMENS,
  GENERAL_ELECTRIC,
].map((value) => ({
  value,
  label: AC_DISCONNECT_BRAND_NAME_OPTIONS_NAMES[value],
}));

// CONDUCTOR MATERIAL
const COOPER = 1;
const ALUMINIUM = 2;
const STEEL = 3;

const CONDUCTOR_MATERIAL_OPTIONS_NAMES = {
  [`${COOPER}`]: 'Cooper',
  [`${ALUMINIUM}`]: 'Aluminium',
  [`${STEEL}`]: 'Steel',
};
export const CONDUCTOR_MATERIAL_OPTIONS_LIST = [COOPER, ALUMINIUM, STEEL].map(
  (value) => ({
    value,
    label: CONDUCTOR_MATERIAL_OPTIONS_NAMES[value],
  })
);

// CONDUCTOR SIZE
const AWG_14 = '14 AWG';
const AWG_12 = '12 AWG';
const AWG_10 = '10 AWG';
const AWG_8 = '8 AWG';
const AWG_6 = '6 AWG';
const AWG_4 = '4 AWG';
const AWG_3 = '3 AWG';
const AWG_2 = '2 AWG';
const AWG_1 = '1 AWG';
const AWG_1_0 = '1/O AWG';
const AWG_2_0 = '2/O AWG';
const AWG_3_0 = '3/O AWG';
const AWG_4_0 = '4/O AWG';
const KCMIL_250 = '250 kcmil';
const KCMIL_300 = '300 kcmil';
const KCMIL_350 = '350 kcmil';
const KCMIL_400 = '400 kcmil';
const KCMIL_500 = '500 kcmil';
const KCMIL_600 = '600 kcmil';
const KCMIL_700 = '700 kcmil';
const KCMIL_750 = '750 kcmil';
const KCMIL_800 = '800 kcmil';
const KCMIL_900 = '900 kcmil';
const KCMIL_1000 = '1000 kcmil';
const KCMIL_1250 = '1250 kcmil';
const KCMIL_1500 = '1500 kcmil';
const KCMIL_1750 = '1750 kcmil';
const KCMIL_2000 = '2000 kcmil';

const CONDUCTOR_SIZE_OPTIONS_NAMES = {
  [`${AWG_14}`]: AWG_14,
  [`${AWG_12}`]: AWG_12,
  [`${AWG_10}`]: AWG_10,
  [`${AWG_8}`]: AWG_8,
  [`${AWG_6}`]: AWG_6,
  [`${AWG_4}`]: AWG_4,
  [`${AWG_3}`]: AWG_3,
  [`${AWG_2}`]: AWG_2,
  [`${AWG_1}`]: AWG_1,
  [`${AWG_1_0}`]: AWG_1_0,
  [`${AWG_2_0}`]: AWG_2_0,
  [`${AWG_3_0}`]: AWG_3_0,
  [`${AWG_4_0}`]: AWG_4_0,
  [`${KCMIL_250}`]: KCMIL_250,
  [`${KCMIL_300}`]: KCMIL_300,
  [`${KCMIL_350}`]: KCMIL_350,
  [`${KCMIL_400}`]: KCMIL_400,
  [`${KCMIL_500}`]: KCMIL_500,
  [`${KCMIL_600}`]: KCMIL_600,
  [`${KCMIL_700}`]: KCMIL_700,
  [`${KCMIL_750}`]: KCMIL_750,
  [`${KCMIL_800}`]: KCMIL_800,
  [`${KCMIL_900}`]: KCMIL_900,
  [`${KCMIL_1000}`]: KCMIL_1000,
  [`${KCMIL_1250}`]: KCMIL_1250,
  [`${KCMIL_1500}`]: KCMIL_1500,
  [`${KCMIL_1750}`]: KCMIL_1750,
  [`${KCMIL_2000}`]: KCMIL_2000,
};
export const CONDUCTOR_SIZE_OPTIONS_LIST = [
  AWG_14,
  AWG_12,
  AWG_10,
  AWG_8,
  AWG_6,
  AWG_4,
  AWG_3,
  AWG_2,
  AWG_1,
  AWG_1_0,
  AWG_2_0,
  AWG_3_0,
  AWG_4_0,
  KCMIL_250,
  KCMIL_300,
  KCMIL_350,
  KCMIL_400,
  KCMIL_500,
  KCMIL_600,
  KCMIL_700,
  KCMIL_750,
  KCMIL_800,
  KCMIL_900,
  KCMIL_1000,
  KCMIL_1250,
  KCMIL_1500,
  KCMIL_1750,
  KCMIL_2000,
].map((value) => ({
  value,
  label: CONDUCTOR_SIZE_OPTIONS_NAMES[value],
}));

// CONDUIT TYPE
const EMT = 1;
const EMT_AL = 2;
const FMC = 3;
const LFNC = 4;
const LFMC = 5;
const RMC = 6;
const PVC_40 = 7;
const PVC_80 = 8;
const FREE_AIR = 9;

const CONDUIT_TYPE_OPTIONS_NAMES = {
  [`${EMT}`]: 'EMT',
  [`${EMT_AL}`]: 'EMT AL',
  [`${FMC}`]: 'FMC',
  [`${LFNC}`]: 'LFNC',
  [`${LFMC}`]: 'LFMC',
  [`${RMC}`]: 'RMC',
  [`${PVC_40}`]: 'PVC 40',
  [`${PVC_80}`]: 'PVC 80',
  [`${FREE_AIR}`]: 'FREE AIR',
};
export const CONDUIT_TYPE_OPTIONS_LIST = [
  EMT,
  EMT_AL,
  FMC,
  LFNC,
  LFMC,
  RMC,
  PVC_40,
  PVC_80,
  FREE_AIR,
].map((value) => ({
  value,
  label: CONDUIT_TYPE_OPTIONS_NAMES[value],
}));

// CONDUIT SIZE
const CONDUIT_0_5 = '0.5';
const CONDUIT_0_75 = '0.75';
const CONDUIT_1 = '1';
const CONDUIT_1_25 = '1.25';
const CONDUIT_1_5 = '1.5';
const CONDUIT_2 = '2';
const CONDUIT_2_5 = '2.5';
const CONDUIT_3 = '3';
const CONDUIT_3_5 = '3.5';
const CONDUIT_4 = '4';

const CONDUIT_SIZE_OPTIONS_NAMES = {
  [`${CONDUIT_0_5}`]: CONDUIT_0_5,
  [`${CONDUIT_0_75}`]: CONDUIT_0_75,
  [`${CONDUIT_1}`]: CONDUIT_1,
  [`${CONDUIT_1_25}`]: CONDUIT_1_25,
  [`${CONDUIT_1_5}`]: CONDUIT_1_5,
  [`${CONDUIT_2}`]: CONDUIT_2,
  [`${CONDUIT_2_5}`]: CONDUIT_2_5,
  [`${CONDUIT_3}`]: CONDUIT_3,
  [`${CONDUIT_3_5}`]: CONDUIT_3_5,
  [`${CONDUIT_4}`]: CONDUIT_4,
};
export const CONDUIT_SIZE_OPTIONS_LIST = [
  CONDUIT_0_5,
  CONDUIT_0_75,
  CONDUIT_1,
  CONDUIT_1_25,
  CONDUIT_1_5,
  CONDUIT_2,
  CONDUIT_2_5,
  CONDUIT_3,
  CONDUIT_3_5,
  CONDUIT_4,
].map((value) => ({
  value,
  label: CONDUIT_SIZE_OPTIONS_NAMES[value],
}));

// OCPD RATING
const OCPD_RATING_MULTIPLE_OF_5 = 'multiple of 5';
const OCPD_RATING_MULTIPLE_OF_10 = 'multiple of 10';

const OCPD_RATING_OPTIONS_NAMES = {
  [`${OCPD_RATING_MULTIPLE_OF_5}`]: 'Multiple of 5',
  [`${OCPD_RATING_MULTIPLE_OF_10}`]: 'Multiple of 10',
};
export const OCPD_RATING_OPTIONS_LIST = [
  OCPD_RATING_MULTIPLE_OF_5,
  OCPD_RATING_MULTIPLE_OF_10,
].map((value) => ({
  value,
  label: OCPD_RATING_OPTIONS_NAMES[value],
}));

// MIN OCPD RATING
const OCPD_RATING_10 = '10';
const OCPD_RATING_15 = '15';
const OCPD_RATING_20 = '20';

const MIN_OCPD_RATING_OPTIONS_NAMES = {
  [`${OCPD_RATING_10}`]: '10A',
  [`${OCPD_RATING_15}`]: '15A',
  [`${OCPD_RATING_20}`]: '20A',
};
export const MIN_OCPD_RATING_OPTIONS_LIST = [
  OCPD_RATING_10,
  OCPD_RATING_15,
  OCPD_RATING_20,
].map((value) => ({
  value,
  label: MIN_OCPD_RATING_OPTIONS_NAMES[value],
}));

// VOLTAGE DROP
const VOLTAGE_DROP_WITH_VD = 'With VD';
const VOLTAGE_DROP_ADVANCED_VD = 'Advanced VD';

const VOLTAGE_DROP_OPTIONS_NAMES = {
  [`${VOLTAGE_DROP_WITH_VD}`]: VOLTAGE_DROP_WITH_VD,
  [`${VOLTAGE_DROP_ADVANCED_VD}`]: VOLTAGE_DROP_ADVANCED_VD,
};
export const VOLTAGE_DROP_OPTIONS_LIST = [
  VOLTAGE_DROP_WITH_VD,
  VOLTAGE_DROP_ADVANCED_VD,
].map((value) => ({
  value,
  label: VOLTAGE_DROP_OPTIONS_NAMES[value],
}));

// NEUTRAL VIEW
const NEUTRAL_VIEW_COMBINED = 'Combined with Conductor';
const NEUTRAL_VIEW_SEPARATE = 'As a Separate Column';

const NEUTRAL_VIEW_OPTIONS_NAMES = {
  [`${NEUTRAL_VIEW_COMBINED}`]: NEUTRAL_VIEW_COMBINED,
  [`${NEUTRAL_VIEW_SEPARATE}`]: NEUTRAL_VIEW_SEPARATE,
};
export const NEUTRAL_VIEW_OPTIONS_LIST = [
  NEUTRAL_VIEW_COMBINED,
  NEUTRAL_VIEW_SEPARATE,
].map((value) => ({
  value,
  label: NEUTRAL_VIEW_OPTIONS_NAMES[value],
}));

// CALCULATION METHODS
const CALC_METHOD_AS_CONDUCTOR = 'Same as conductor';
const CALC_METHOD_NEC_250_122 = 'Per NEC 250.122';
const CALC_METHOD_AS_EGC = 'Same as EGC';

// EGC CALCULATION METHOD
const EGC_CALC_METHOD_OPTIONS_NAMES = {
  [`${CALC_METHOD_AS_CONDUCTOR}`]: CALC_METHOD_AS_CONDUCTOR,
  [`${CALC_METHOD_NEC_250_122}`]: CALC_METHOD_NEC_250_122,
};
export const EGC_CALC_METHOD_OPTIONS_LIST = [
  CALC_METHOD_AS_CONDUCTOR,
  CALC_METHOD_NEC_250_122,
].map((value) => ({
  value,
  label: EGC_CALC_METHOD_OPTIONS_NAMES[value],
}));

// NEUTRAL CALCULATION METHODS
const NEUTRAL_CALC_METHOD_OPTIONS_NAMES = {
  [`${CALC_METHOD_AS_CONDUCTOR}`]: CALC_METHOD_AS_CONDUCTOR,
  [`${CALC_METHOD_AS_EGC}`]: CALC_METHOD_AS_EGC,
};
export const NEUTRAL_CALC_METHOD_OPTIONS_LIST = [
  CALC_METHOD_AS_CONDUCTOR,
  CALC_METHOD_AS_EGC,
].map((value) => ({
  value,
  label: NEUTRAL_CALC_METHOD_OPTIONS_NAMES[value],
}));

// BOOLEAN OPTIONS
const YES = 'Yes';
const NO = 'No';

const BOOLEAN_OPTIONS_NAMES = {
  [`${YES}`]: YES,
  [`${NO}`]: NO,
};
export const BOOLEAN_OPTIONS_LIST = [YES, NO].map((value) => ({
  value,
  label: BOOLEAN_OPTIONS_NAMES[value],
}));
