import { PointText, Matrix } from 'paper';

import { getString } from 'utils';

const matrix = new Matrix();
matrix.scale(0.92, 1);

function PaperText(props = {}) {
  const { content, fontFamily = 'ArialNarrow', ...rest } = props;

  const parsedContent = (() => {
    const v = getString(content);

    if (v.includes('<') && v.includes('>')) {
      const div = document.createElement('div');
      div.innerHTML = v;
      return div.outerText;
    }
    return v;
  })();

  const result = new PointText({
    fontFamily,
    content: parsedContent,
    matrix,
    ...rest,
  });

  return result;
}

export default PaperText;
