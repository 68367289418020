import { useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  Stack,
  Typography,
  IconButton as MuiIconButton,
} from '@mui/material';

import { withProps } from 'hooks';
import { Button as _Button, Center, Delimiter, Icon, Slip } from 'components';

import Strings from './Strings';

const Button = withProps(_Button, {
  fullWidth: false,
  minWidth: 200,
  variant: 'outlined',
});
const IconButton = withProps(MuiIconButton, {
  size: 'small',
  color: 'secondary',
});

const createEmptyMppt = () => ({
  type: 'mppt',
  value: [],
});

const Mppt = (props) => {
  const { min, max, count, label, name, mpptCount } = props;

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
    control,
    keyName: 'field_id',
    shouldUnregister: true,
  });

  const handleAddMppt = useCallback(() => {
    append(createEmptyMppt());
  }, [append]);

  useEffect(() => {
    if (fields.length === 0) {
      handleAddMppt();
    }
  }, [handleAddMppt, fields]);

  return (
    <Box>
      <Stack spacing={2}>
        {fields.map((field, i) => {
          const handleRemove = () => {
            remove(i);
          };
          return (
            <Slip key={field.field_id} elevation={5} p={0}>
              <Box>
                <Center justifyContent="space-between" px={2} py={1}>
                  <Typography variant="subtitle4">MPPT #{i + 1}</Typography>

                  <IconButton
                    onClick={handleRemove}
                    disabled={fields.length === 1}
                  >
                    <Icon.Close />
                  </IconButton>
                </Center>

                <Delimiter />

                <Box p={2} pb={4}>
                  <Strings
                    mppt
                    min={min}
                    max={max}
                    count={count}
                    label={label}
                    name={`${name}.${i}.value`}
                  />
                </Box>
              </Box>
            </Slip>
          );
        })}
      </Stack>

      <Center mt={2}>
        <Button disabled={fields.length >= mpptCount} onClick={handleAddMppt}>
          Add MPPT
        </Button>
      </Center>
    </Box>
  );
};

export { Strings };

export default Mppt;
