import { useCallback, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Stack } from '@mui/material';

import {
  PHASES,
  PHASES_LIST,
  SERVICE_VOLTAGES_PHASE_1_LIST,
  SERVICE_VOLTAGES_PHASE_3_LIST,
  GEC_TYPES_LIST,
} from 'constants';

import { getArray } from 'utils';
import { Center, RadioButtons, SelectField } from 'components';

import ConfirmSystemsResetModal from './ConfirmSystemsResetModal';
// TODO: Refactor to get empty system in other way
import { createEmptySystem } from '../SystemsForm';

const getServiceVoltageOptions = (p) => {
  switch (p) {
    case PHASES.PHASE_1:
      return SERVICE_VOLTAGES_PHASE_1_LIST;
    case PHASES.PHASE_3:
      return SERVICE_VOLTAGES_PHASE_3_LIST;
    default:
      return [
        ...SERVICE_VOLTAGES_PHASE_1_LIST,
        ...SERVICE_VOLTAGES_PHASE_3_LIST,
      ];
  }
};

const CommonSystemConfigFields = (props) => {
  const { onSystemReset } = props;

  const { getFieldState, setValue, control, getValues } = useFormContext();
  const [confirmValueSet, setConfirmValueSet] = useState(null);
  const phase = useWatch({ control, name: 'phase' });

  const handlePhaseChange = useCallback(
    (e, v) => {
      setValue('service_voltage', getServiceVoltageOptions(v.value)[0].value);
    },
    [setValue]
  );

  const areSystemsHaveChanges = useCallback(() => {
    const { isDirty } = getFieldState('systems');
    const systems = getValues('systems');
    const emptySystem = createEmptySystem();

    if (isDirty || systems.length > 1) {
      return true;
    }
    const [system] = systems;

    return Object.entries(emptySystem).some(([name, value]) => {
      if (Array.isArray(value)) {
        return value.length !== getArray(system[name])?.length;
      }
      if (!system[name]) {
        return false;
      }
      return system[name] !== value;
    });
  }, [getFieldState, getValues]);

  const handlePhaseBeforeChange = useCallback(
    (e, v) => {
      const changes = areSystemsHaveChanges();

      if (changes) {
        setConfirmValueSet({
          callback: () => {
            setValue('phase', v.value);
            setValue(
              'service_voltage',
              getServiceVoltageOptions(v.value)[0].value
            );
          },
        });
        return false;
      }
    },
    [areSystemsHaveChanges, setValue]
  );

  const handleServiceVoltageBeforeChange = useCallback(
    (e) => {
      const changes = areSystemsHaveChanges();

      if (changes) {
        setConfirmValueSet({
          callback: () => setValue('service_voltage', e.target.value),
        });
        return false;
      }
    },
    [areSystemsHaveChanges, setValue]
  );

  const hanldeSystemResetConfirm = useCallback(() => {
    confirmValueSet.callback();
    setConfirmValueSet(null);
    onSystemReset();
  }, [confirmValueSet, onSystemReset]);

  const handleSystemResetCancel = useCallback(() => {
    setConfirmValueSet(null);
  }, []);

  const serviceVoltageOptions = useMemo(() => {
    return getServiceVoltageOptions(phase);
  }, [phase]);

  return (
    <>
      <ConfirmSystemsResetModal
        open={!!confirmValueSet}
        onClose={handleSystemResetCancel}
        onConfirm={hanldeSystemResetConfirm}
      />

      <Stack spacing={2}>
        <Center justifyContent="space-between" gap={3}>
          <RadioButtons.Control
            required
            fullWidth
            name="phase"
            minWidth={0}
            size="medium"
            variant="flat"
            label="Grid Type"
            options={PHASES_LIST}
            onChange={handlePhaseChange}
            onBeforeChange={handlePhaseBeforeChange}
          />

          <SelectField.Control
            label="Service Voltage"
            name="service_voltage"
            displayEmpty={false}
            options={serviceVoltageOptions}
            onBeforeChange={handleServiceVoltageBeforeChange}
          />
        </Center>

        <SelectField.Control
          name="gec"
          displayEmpty={false}
          options={GEC_TYPES_LIST}
          label="Grounding Electrode Conductor"
        />
      </Stack>
    </>
  );
};

export default CommonSystemConfigFields;
