import { forwardRef, lazy, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';

import { dev } from 'constants';
import { getString } from 'utils';
import { useCombineRefs } from 'hooks';

const HTMLBox = forwardRef((props, ref) => {
  const { innerHTML, children, ...rest } = props;

  const rootRef = useRef(null);
  const handleRef = useCombineRefs(ref, rootRef);

  const content = useMemo(
    () => getString(innerHTML || children),
    [innerHTML, children]
  );

  useEffect(() => {
    const { current: rootElement } = rootRef;

    if (rootElement) {
      rootElement.innerHTML = content;
    }
  }, [content]);

  return <Box ref={handleRef} {...rest} />;
});

if (dev) {
  HTMLBox.Demo = lazy(() => import('./HTMLBox.demo'));
}

export default HTMLBox;
