import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';

import { SYSTEM_TYPES } from 'constants';
import { Tile } from 'components';

const { STRING, DC_OPTIMIZED } = SYSTEM_TYPES;

const SystemMeta = (props) => {
  const { prefix, inverter, ...rest } = props;

  const { control, availablePanels } = useFormContext();
  const system_type = useWatch({ control, name: `${prefix}.system_type` });

  const subjects = system_type === STRING ? 'Strings/MPPT' : 'Strings';
  const isStringType = [STRING, DC_OPTIMIZED].includes(system_type);

  const maxStringCount = inverter?.max_string_count || '-';
  const maxMpptQty = inverter?.mppt_qty || '-';
  const qnty =
    system_type === STRING ? `${maxStringCount}/${maxMpptQty}` : maxStringCount;

  return (
    <Box {...rest} gap={1} display="flex" justifyContent="space-between">
      {isStringType && <Tile label={`Available ${subjects}`} value={qnty} />}

      <Tile label="Available PV Panels" value={availablePanels || '-'} />

      {isStringType && <Tile label="Inverter Oversize" value="240%" />}
    </Box>
  );
};

export default SystemMeta;
