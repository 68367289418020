import { Outlet } from 'react-router-dom';
import { styled, Box, Typography } from '@mui/material';

import { useGetProfileQuery } from 'store';
import { AppBar } from 'views';
import { ProgressBox } from 'components';

const Root = styled(Box, {
  label: 'MainLayout',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  width: '100vw',
  minWidth: '100vw',
  maxWidth: '100vw',

  height: '100vh',
  minHeight: '100vh',
  maxHeight: '100vh',

  overflow: 'hidden',
  backgroundColor: theme.palette.colors.background_main,
}));

const MainLayout = () => {
  const { data, isFetching } = useGetProfileQuery();

  return (
    <Root>
      <AppBar>
        <ProgressBox progress={isFetching} size="xsmall">
          <Typography variant="subtitle1">
            {data?.organisation?.name || '. . .'}
          </Typography>
        </ProgressBox>
      </AppBar>

      <Box
        height={1}
        flexGrow={1}
        overflow="auto"
        display="flex"
        justifyContent="center"
      >
        <Box flexGrow={1} width={1}>
          <Outlet />
        </Box>
      </Box>
    </Root>
  );
};

export default MainLayout;
