import { lazy, useMemo } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { styled } from '@mui/material/styles';

import { routes } from 'routes';
import { useAuth, withProps } from 'hooks';
import { PROJECTS_VIEW, dev } from 'constants';
import { getAddressLabel, getProjectProgress, dateToString } from 'utils';
import { BackgroundBox, ProgressLine, Ref, Slip } from 'components';

import ProjectCardButtons from './ProjectCardButtons';

const Caption = styled(Box, {
  label: 'CardCaption',
})(({ theme }) => ({
  gap: 2,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.secondary.main,
  transition: theme.transitions.create(),
}));

const Card = styled(Slip, {
  label: 'ProjectCard',
  shouldForwardProp: (prop) => !['create'].includes(prop),
})(({ theme, create }) => ({
  display: 'flex',
  overflow: 'hidden',
  cursor: create ? 'pointer' : undefined,
  padding: theme.spacing(0),
  '&:hover': {
    boxShadow: theme.makeShadow(1, theme.palette.border.main),
    [`& ${Caption}`]: {
      color: theme.palette.secondary.dark,
    },
  },
}));

const DetailsContainer = withProps(Box, {
  py: 2,
  px: 3,
  flexGrow: 1,
});

const GridDetailsContainer = withProps(DetailsContainer, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const TableDetailsContainer = withProps(DetailsContainer, {
  gap: 4,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '2fr 2fr 3fr',
});

const ImageContentContainer = withProps(Box, {
  height: 1,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
});

const { TABLE, GRID } = PROJECTS_VIEW;

const ProjectCard = (props) => {
  const { project, view, create, ...rest } = props;
  const project_id = project?.id;

  const { premium } = useAuth();

  const height = view === TABLE ? 104 : 445;
  const progress = getProjectProgress(project, premium);
  const ProjectDetailsContainer =
    view === GRID ? GridDetailsContainer : TableDetailsContainer;

  const updated = useMemo(() => {
    if (!project) {
      return null;
    }
    const date = dateToString(project.date_modified);
    return `Updated ${date} by ${project.updated_by}`;
  }, [project]);

  return (
    <Card
      elevation={0}
      create={create}
      height={height}
      flexDirection={view === GRID ? 'column' : 'row'}
      {...rest}
    >
      {create && (
        <Caption flexDirection={view === TABLE ? 'row' : 'column'}>
          <AddRoundedIcon sx={{ fontSize: 41 }} />

          <Box maxWidth={view === TABLE ? undefined : 200} textAlign="center">
            <Typography variant="subtitle1" fontSize={24}>
              Create New Project
            </Typography>
          </Box>
        </Caption>
      )}

      {!create && project && (
        <>
          <BackgroundBox
            src={project.property_image}
            width={view === TABLE ? 145 : 1}
            minWidth={view === TABLE ? 145 : undefined}
            height={view === TABLE ? 1 : 260}
            contentProps={{ height: 1 }}
          >
            <ImageContentContainer
              {...rest}
              width={view === GRID ? 1 : undefined}
              sx={(theme) => ({
                [`${
                  view === GRID ? 'borderBottom' : 'borderRight'
                }`]: `1px solid ${theme.palette.border.light}`,
              })}
            >
              {!project.property_image && (
                <Typography variant="caption" color="border.main">
                  No image
                </Typography>
              )}
              {view === GRID && (
                <Box position="absolute" p={2} top={0} right={0}>
                  <ProjectCardButtons project={project} view={view} />
                </Box>
              )}
            </ImageContentContainer>
          </BackgroundBox>

          <ProjectDetailsContainer
            nostyles
            useTabFocus
            component={Ref}
            to={
              !create ? routes.project_details.path({ project_id }) : undefined
            }
          >
            {/* TITLE & DESCRIPTION */}
            <Box>
              <Typography variant="subtitle2">{project.title}</Typography>

              <Typography variant="body1">{project.description}</Typography>
            </Box>

            {/* ADDRESS */}
            <Box>
              <Typography>
                {getAddressLabel(project.address) || <>&nbsp;</>}
              </Typography>
            </Box>

            {/* PROGRESS */}
            <Stack spacing={0.5}>
              {view === TABLE && (
                <Typography variant="caption" color="text.label">
                  {progress}% Complete
                </Typography>
              )}

              <ProgressLine
                size={view === GRID ? 'small' : 'medium'}
                value={progress}
              />

              {view === GRID && (
                <Typography variant="caption" color="text.label">
                  {progress}% Complete
                </Typography>
              )}

              <Typography variant="caption" color="text.label">
                {updated}
              </Typography>
            </Stack>
          </ProjectDetailsContainer>

          {view === TABLE && (
            <Box
              minWidth={100}
              height={1}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mr={2}
            >
              <ProjectCardButtons project={project} view={view} />
            </Box>
          )}
        </>
      )}
    </Card>
  );
};

if (dev) {
  ProjectCard.Demo = lazy(() => import('./ProjectCard.demo'));
}

export default ProjectCard;
