import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Collapse } from '@mui/material';

import { getArray } from 'utils';
import { SelectField } from 'components';

// TODO: Better to move handlers and field resets out of these components
const DcOptimizerField = (props) => {
  const { name, prefix, visible, disabled, optimizers, ...rest } = props;

  const { resetField } = useFormContext();

  const options = useMemo(() => {
    return getArray(optimizers).map((opt) => ({
      value: opt.id,
      label: `${opt.vendor.title} - ${opt.title}`,
    }));
  }, [optimizers]);

  const handleChange = useCallback(() => {
    resetField(`${prefix}.inverter_id`);
    resetField(`${prefix}.strings`, {
      defaultValue: [],
    });
  }, [resetField, prefix]);

  return (
    <Collapse in={visible}>
      <SelectField.Control
        {...rest}
        required
        onChange={handleChange}
        options={options}
        disabled={disabled}
        displayEmpty={false}
        name={`${prefix}.${name}`}
        rules={{ disabled: !visible }}
      />
    </Collapse>
  );
};

export default DcOptimizerField;
