import { lazy, useMemo } from 'react';
import { Box } from '@mui/material';

import {
  useGetSystemsTemplateImageQuery,
  useGetBalanceOfSystemImageQuery,
  useGetInterconnectionImageQuery,
} from 'store';

import { pv_system } from 'assets';
import { useGetProjectDetailsQuery } from 'store';
import { dev } from 'constants';
import { useIsLoading, withProps } from 'hooks';
import { getArray } from 'utils';
import { Center, Image } from 'components';

import SystemCard from './SystemCard';

const ImagePlacer = withProps(Box, {
  top: 165,
  position: 'absolute',
});

const SystemCards = (props) => {
  const { current, project_id } = props;

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const isSystemsStepComplete = getArray(project?.systems).length > 0;
  const isBosStepComplete = !!project?.bos;
  const isInterconnectionStepComplete = !!project?.interconnection;

  const timestamp = useMemo(() => {
    if (project) {
      return `${Date.now()}`;
    }
    return '';
  }, [project]);

  const querySystemImage = useGetSystemsTemplateImageQuery(
    { project_id },
    {
      skip: !isSystemsStepComplete,
    }
  );
  const queryBosImage = useGetBalanceOfSystemImageQuery(
    { project_id },
    {
      skip: !isBosStepComplete,
    }
  );
  const queryInterconnectionImage = useGetInterconnectionImageQuery(
    { project_id },
    {
      skip: !isInterconnectionStepComplete,
    }
  );

  const systemImage = useMemo(() => {
    return !querySystemImage.data
      ? undefined
      : {
          ...querySystemImage.data,
          image: `${querySystemImage.data.image}?${timestamp}`,
        };
  }, [querySystemImage.data, timestamp]);

  const bosImage = useMemo(() => {
    return !queryBosImage.data
      ? undefined
      : {
          ...queryBosImage.data,
          image: `${queryBosImage.data.image}?${timestamp}`,
        };
  }, [queryBosImage.data, timestamp]);

  const interconnectionImage = useMemo(() => {
    return !queryInterconnectionImage.data
      ? undefined
      : {
          ...queryInterconnectionImage.data,
          image: `${queryInterconnectionImage.data.image}?${timestamp}`,
        };
  }, [queryInterconnectionImage.data, timestamp]);

  const loading = useIsLoading(
    querySystemImage,
    queryBosImage,
    queryInterconnectionImage,
    isFetching
  );

  return (
    <Box display="inline-block" m={5}>
      <Center justifyContent="stretch">
        <SystemCard
          title="PV System"
          loading={loading}
          active={current === 'pv_system'}
          complete={isSystemsStepComplete}
          image={!querySystemImage.error && systemImage}
        >
          <ImagePlacer>
            <Image src={pv_system} />
          </ImagePlacer>
        </SystemCard>

        <SystemCard
          title="BOS"
          loading={loading}
          active={current === 'bos'}
          borders={current !== 'bos'}
          complete={isBosStepComplete}
          image={!queryBosImage.error && bosImage}
        />

        <SystemCard
          title="Interconnection"
          loading={loading}
          active={current === 'interconnection'}
          complete={isInterconnectionStepComplete}
          image={!queryInterconnectionImage.error && interconnectionImage}
        />
      </Center>
    </Box>
  );
};

if (dev) {
  SystemCards.Demo = lazy(() => import('./SystemCards.demo'));
}

export default SystemCards;
