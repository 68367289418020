import { lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';

import { useGetDisconnectsListQuery } from 'store';
import { DISCONNECT_TYPES_LIST, dev } from 'constants';
import { getArray } from 'utils';
import { useFetchDisconnects, useIsLoading } from 'hooks';
import {
  Autocomplete,
  Button,
  Center,
  Modal,
  RadioButtons,
  TextField,
} from 'components';

const DisconnectModal = (props) => {
  const { open: customOpen, onClose, onSubmit, data } = props;

  const disconnect = data?.disconnect;
  const disconnect_id = data?.disconnect_id;
  const disconnect_label = data?.disconnect_label;

  const [type, setType] = useState(DISCONNECT_TYPES_LIST[0].value);
  const [newDisconnectId, setNewDisconnectId] = useState('');
  const [newDisconnectLabel, setNewDisconnectLabel] = useState('');

  const open = (disconnect && !disconnect_id) || customOpen;
  const skip = { skip: !open };
  const query = {
    params: { disconnect_type: type },
  };
  const queryDisconnectsList = useGetDisconnectsListQuery(query, skip);
  const queryDisconnects = useFetchDisconnects(skip);

  const disconnects = queryDisconnectsList.data;
  const allDisconnects = queryDisconnects.data;
  const loading = useIsLoading(queryDisconnects, queryDisconnectsList);

  const target = useMemo(() => {
    return allDisconnects.find((d) => d.id === disconnect_id);
  }, [allDisconnects, disconnect_id]);

  const options = useMemo(() => {
    return getArray(disconnects).map((dis) => ({
      value: dis.id,
      label: `${dis.vendor.title} - ${dis.title}`,
    }));
  }, [disconnects]);

  useEffect(() => {
    if (open) {
      setType(target?.type || DISCONNECT_TYPES_LIST[0].value);
      setNewDisconnectId(disconnect_id || '');
      setNewDisconnectLabel(disconnect_label || '');
    }
  }, [disconnect_id, disconnect_label, open, target]);

  const handleTypeChange = useCallback((v) => {
    setType(v);
    setNewDisconnectId('');
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit({
      disconnect_id: newDisconnectId,
      disconnect_label: newDisconnectLabel,
    });
  }, [onSubmit, newDisconnectId, newDisconnectLabel]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Utility AC Disconnect Configuration"
    >
      <Stack spacing={2} mt={2}>
        <RadioButtons
          label="Type"
          value={type}
          size="medium"
          variant="flat"
          spacing={2}
          fullWidth
          onValue={handleTypeChange}
          options={DISCONNECT_TYPES_LIST}
        />

        <Autocomplete
          required
          valueAsId
          options={options}
          loading={loading}
          label="Disconnect Model"
          onValue={setNewDisconnectId}
          value={newDisconnectId || null}
          placeholder="Type to search disconnect model"
        />

        <TextField
          label="Disconnect Label"
          value={newDisconnectLabel}
          onValue={setNewDisconnectLabel}
        />
      </Stack>

      <Center mt={6}>
        <Button
          minWidth={200}
          fullWidth={false}
          onClick={handleSubmit}
          disabled={!newDisconnectId}
        >
          Save
        </Button>
      </Center>
    </Modal>
  );
};

if (dev) {
  DisconnectModal.Demo = lazy(() => import('./DisconnectModal.demo'));
}

export default DisconnectModal;
