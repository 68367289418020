import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  IconButton as MuiIconButton,
  Typography,
  Stack,
} from '@mui/material';

import { SCALES_LIST, LAYERS_LIST } from 'constants';
import { getArray } from 'utils';
import { useDeincrement, useToggleArray, withProps } from 'hooks';
import { ProjectDetailsLayout } from 'layouts';
import { ProjectPreview, ProjectSubmitButton, LayersPreview } from 'views';
import {
  Button as OriginalButton,
  Delimiter,
  Icon,
  ProgressBox,
  SelectField,
  Switch,
  Tooltip,
} from 'components';

const Button = withProps(OriginalButton, {
  size: 'medium',
  variant: 'outlined',
});
const IconButton = withProps(MuiIconButton, { color: 'primary' });

const WarningIcon = withProps(Icon.ExclamationFilled, {
  sx: {
    fontSize: 14,
    color: 'error.main',
    transition: (theme) => theme.transitions.create(),
    '&:hover': {
      color: 'error.dark',
    },
  },
});

const scale_min = 1;
const scale_max = 2;
const scale_step = 0.1;

const PreliminaryPlanPreview = (props) => {
  const {
    nextStep,
    isFirstSaving,
    loading,
    layers,
    value,
    onChange,
    onDelete,
    onReplace,
  } = props;

  const [scale, setScale] = useState(scale_min);
  const [checkedLayers, toggleLayers] = useToggleArray(
    LAYERS_LIST.map((l) => l.value)
  );
  const { setValue } = useFormContext();

  const [increaseScale, decreaseScale] = useDeincrement(scale, setScale, {
    max: scale_max,
    min: scale_min,
    step: scale_step,
  });

  const disableIncrease = scale === scale_max;
  const disableDecrease = scale === scale_min;

  const handleChange = useCallback(
    (e, ...args) => {
      onChange(e, ...args);

      setTimeout(() => {
        // Workaround strange bug when formState isDirty field does not changed while
        // field state changes as well
        setValue('scale', e.target.value, {
          shouldDirty: true,
        });
      }, 0);
    },
    [onChange, setValue]
  );

  const visibleLayers = useMemo(() => {
    return LAYERS_LIST.reduce((acc, l) => {
      if (checkedLayers.includes(l.value)) {
        return {
          ...acc,
          [l.value]: layers[l.value],
        };
      }
      return acc;
    }, {});
  }, [layers, checkedLayers]);

  useEffect(() => {
    getArray(LAYERS_LIST).forEach((l) => {
      if (!layers[l.value]) {
        toggleLayers(l.value, false);
      }
    });
  }, [layers, toggleLayers]);

  return (
    <ProjectDetailsLayout
      title="Preliminary Plan"
      body={
        <ProgressBox progress={loading}>
          <SelectField
            initialFocus
            value={value}
            displayEmpty={false}
            options={SCALES_LIST}
            onChange={handleChange}
            label="Preliminary Plan Scale from the Document"
          />

          <Delimiter my={2} />

          <Box mt={3}>
            <Typography variant="subtitle2">Layers Check</Typography>

            <Stack spacing={2} mt={2}>
              {LAYERS_LIST.map((layer) => (
                <Box key={layer.value} display="flex" alignItems="center">
                  <Switch
                    disabled={!layers[layer.value]}
                    checked={checkedLayers.includes(layer.value)}
                    onChange={toggleLayers.callback(layer.value)}
                  />

                  <Typography mx={2}>{layer.label}</Typography>

                  {!layers[layer.value] && (
                    <Tooltip title="Layer is not found">
                      <WarningIcon />
                    </Tooltip>
                  )}
                </Box>
              ))}
            </Stack>
          </Box>

          <Box mt={4}>
            <ProjectSubmitButton
              nextStep={nextStep}
              isFirstSaving={isFirstSaving}
              ignoreIsValid={isFirstSaving}
            />
          </Box>
        </ProgressBox>
      }
    >
      <ProjectPreview
        tools={
          <>
            <Box px={4} display="flex" gap={2}>
              <Button onClick={onDelete}>Delete Image</Button>

              <Button onClick={onReplace}>Replace Image</Button>
            </Box>

            <Box height={1} display="flex" px={1} alignItems="center">
              <Delimiter mx={1} vertical />

              <IconButton disabled={disableIncrease} onClick={increaseScale}>
                <Icon.PlusFilled />
              </IconButton>

              <IconButton disabled={disableDecrease} onClick={decreaseScale}>
                <Icon.MinusFilled />
              </IconButton>
            </Box>
          </>
        }
      >
        <LayersPreview scale={scale} layers={Object.values(visibleLayers)} />
      </ProjectPreview>
    </ProjectDetailsLayout>
  );
};

export default PreliminaryPlanPreview;
