import { forwardRef, lazy, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Switch as MuiSwitch,
  styled,
  switchClasses as classes,
} from '@mui/material';

import { dev } from 'constants';
import { getFunc } from 'utils';
import { propagateRefs } from 'hooks';

const thumb_size = 16;
const track_height = 24;
const track_width = 40;

const Root = styled(MuiSwitch, {
  label: 'D4PV-Switch',
})(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: 0,
    width: track_width,
    height: track_height,
    borderRadius: track_height / 2,

    [`& .${classes.switchBase}`]: {
      padding: (track_height - thumb_size) / 2,

      [`& .${classes.thumb}`]: {
        height: thumb_size,
        width: thumb_size,
      },

      [`&.${classes.disabled}`]: {
        [`& + .${classes.track}`]: {
          opacity: 0.5,
        },
        [`& .${classes.thumb}`]: {
          backgroundColor: theme.palette.common.white,
          boxShadow: 'none',
        },
      },

      [`&.${classes.checked}`]: {
        transform: `translateX(${track_width - thumb_size - 8}px)`,

        [`&.${classes.disabled}`]: {
          [`& + .${classes.track}`]: {
            opacity: 0.5,
            backgroundColor: theme.palette.secondary.light,
          },
        },

        [`& + .${classes.track}`]: {
          opacity: 1,
          backgroundColor: theme.palette.secondary.main,
        },

        [`& .${classes.thumb}`]: {
          color: theme.palette.common.white,
        },
      },
    },
    [`& .${classes.track}`]: {
      opacity: 1,
      backgroundColor: theme.palette.colors.disabled_background,
      boxShadow: 'inset 0 1px 2px 0 rgba(0, 25, 94, 0.2)',
      borderRadius: track_height / 2,
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
}));

const Switch = forwardRef((props, ref) => {
  const { onValue, onChange, color = 'secondary', ...rest } = props;

  const handleMapChange = useCallback(
    (e) => {
      getFunc(onChange)(e);
      getFunc(onValue)(!!e.target.checked);
    },
    [onChange, onValue]
  );

  return <Root ref={ref} color={color} onChange={handleMapChange} {...rest} />;
});

Switch.Control = forwardRef((props, ref) => {
  const {
    name,
    rules,
    onBlur,
    onValue,
    inputRef,
    defaultValue,
    shouldUnregister,
    ControlComponent = Switch,
    ...rest
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={!!defaultValue}
      render={({ field }) => (
        <ControlComponent
          {...rest}
          ref={ref}
          inputRef={propagateRefs(inputRef, field.ref)}
          checked={field.value}
          onValue={(v) => {
            field.onChange(v);
            getFunc(onValue)(v);
          }}
          onBlur={(...args) => {
            field.onBlur(...args);
            getFunc(onBlur)(...args);
          }}
        />
      )}
    />
  );
});

if (dev) {
  Switch.Demo = lazy(() => import('./Switch.demo'));
}

export default Switch;
