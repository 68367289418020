import { useCallback } from 'react';

/**
 * Allows to combine react refs into one ref (both case: object or function).
 * Usage example:
 *
 *  const Component = forwardRef((props, ref) => {
 *    const rootRef = useRef(null);
 *    const handleRef = useCombineRefs(ref, rootRef);
 *
 *    return (
 *      <div ref={handleRef} />
 *    );
 *  });
 */
const useCombineRefs = (...refs) => {
  return useCallback(
    (el) => {
      refs.forEach((ref) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(el);
            return;
          }
          ref.current = el;
        }
        return el;
      });
    },
    [refs]
  );
};

export default useCombineRefs;
