import PaperElement from 'components/Paper/PaperElement';

class PaperMouseEvents extends PaperElement {
  constructor(options) {
    super(options);

    this.onViewClick = this.onViewClick.bind(this);
    this.onClickAway = this.onClickAway.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onViewMouseMove = this.onViewMouseMove.bind(this);
    this.onViewMouseLeave = this.onViewMouseLeave.bind(this);

    this.element.on('click', this.onClick);
    this.scope.view.on('click', this.onViewClick);
    this.scope.view.on('mousemove', this.onViewMouseMove);
    this.scope.view.on('mouseleave', this.onViewMouseLeave);
  }

  onViewClick(e) {
    const localPoint = this.element.parent.globalToLocal(e.point);

    if (!this.element.contains(localPoint)) {
      this.onClickAway(e);
    }
  }

  onViewMouseMove() {
    // void
  }

  onViewMouseLeave() {
    // void
  }

  onClickAway() {
    // void
  }

  onClick() {
    // void
  }

  isOver(point, el = this.element) {
    const p = this.point(point);
    return el.contains(el.parent.globalToLocal(p));
  }

  destroy(...args) {
    this.element?.off('click', this.onClick);
    this.scope?.view?.off('click', this.onViewClick);
    this.scope?.view?.off('mousemove', this.onViewMouseMove);
    super.destroy(...args);
  }
}

export default PaperMouseEvents;
