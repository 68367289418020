import { useCallback } from 'react';
import { Box } from '@mui/material';

import { getFunc } from 'utils';
import { useToggle } from 'hooks';
import { StringMark, ToolbarDropdown } from 'views';
import { Icon, Draggable } from 'components';

const StringsDropdown = (props) => {
  const { strings, onDrop, onDragStart, ...rest } = props;
  const [open, toggleOpen] = useToggle();

  const handleDrop = useCallback(
    (string, data) => {
      getFunc(onDrop)(string, data);
      toggleOpen.off();
    },
    [onDrop, toggleOpen]
  );

  return (
    <ToolbarDropdown
      {...rest}
      open={open}
      onOpen={toggleOpen.on}
      onClose={toggleOpen.off}
      icon={<Icon.Strings />}
      label="Strings"
    >
      {strings.map((str) => (
        <Box
          key={str.id}
          width={30}
          height={30}
          borderRadius="50%"
          position="relative"
          fontSize={0}
          sx={{
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: 'border.main',
          }}
        >
          {!str.placed && (
            <Draggable
              onDragStart={onDragStart}
              onDrop={(e, data) => handleDrop(str, data)}
            >
              <StringMark
                text={str.text}
                sx={{
                  userSelect: 'none',
                  transform: 'translate(-2px, -2px)',
                }}
              />
            </Draggable>
          )}
        </Box>
      ))}
    </ToolbarDropdown>
  );
};

export default StringsDropdown;
