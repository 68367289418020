import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { getFunc } from 'utils';

const useResizeObserver = (targetRef, callback, ...deps) => {
  const [element, setElement] = useState();

  const observer = useMemo(() => {
    return new ResizeObserver(getFunc(callback));
  }, [callback]);

  useEffect(() => {
    setElement(targetRef?.current);
  }, [targetRef, deps]);

  useLayoutEffect(() => {
    if (element && observer) {
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
  }, [observer, element]);
};

export default useResizeObserver;
