import {
  forwardRef,
  lazy,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { useDropzone } from 'react-dropzone';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, styled, Typography } from '@mui/material';

import { dev } from 'constants';
import { getArray, getFunc } from 'utils';
import { propagateRefs, useToggle } from 'hooks';

import Ref from 'components/Ref';
import Ripple from 'components/Ripple';
import FormLabel from 'components/FormLabel';
import FormControl from 'components/FormControl';

const DropBox = styled(Box, {
  label: 'DropBox',
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})(({ theme, border, variant }) => ({
  cursor: 'pointer',
  borderRadius: theme.radius(0.5),
  border: (() => {
    if (border) {
      return border;
    }
    switch (variant) {
      case 'box':
        return `2px dashed ${theme.palette.border.light}`;
      case 'avatar':
      default:
        return `1px dashed ${theme.palette.secondary.light}`;
    }
  })(),
  transition: theme.transitions.create('border'),
  position: 'relative',
  overflow: 'hidden',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    // borderColor: theme.palette.secondary.dark,
    borderColor: (() => {
      switch (variant) {
        case 'box':
          return theme.palette.border.main;
        case 'avatar':
        default:
          return theme.palette.secondary.dark;
      }
    })(),
  },
  '&:focus': {
    outline: 'none',
  },
}));

const RippleBox = styled(Box, {
  label: 'RippleBox',
})(({ theme }) => ({
  top: '50%',
  left: '50%',
  width: '115%',
  height: '115%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  color: theme.palette.border.main,
}));

const Dropzone = forwardRef((props, ref) => {
  const {
    variant = 'avatar',
    label,
    complete,
    accept,
    readOnly,
    disabled,
    maxFiles = 1,
    multiple = false,
    image,
    value,
    minHeight = 120,
    minWidth = 120,
    onChange,
    inputRef,
    onFocus,
    onBlur,
    helperText,
    initialFocus,
    uploadTrigger = 'Upload',
    children,
    component,
    fullWidth: customFullWidth,
    ...rest
  } = props;

  const [focus, setFocus] = useState(false);
  const [dialog, toggleDialog] = useToggle(false);

  const max = useMemo(() => (!multiple ? 1 : maxFiles), [maxFiles, multiple]);

  const dis = useMemo(
    () => (complete && getArray(value).length >= max ? true : disabled),
    [value, max, complete, disabled]
  );

  const fullWidth = useMemo(() => {
    if (typeof customFullWidth === 'boolean') {
      return customFullWidth;
    }
    return variant === 'box';
  }, [customFullWidth, variant]);

  const acceptFormats = useMemo(() => {
    return getArray(accept).reduce(
      (res, mime) => ({
        ...res,
        [mime]: [],
      }),
      {}
    );
  }, [accept]);

  const handleDropAccepted = useCallback(
    (files) => {
      const result = !complete
        ? [...files]
        : [...getArray(value), ...files].slice(0, max);

      if (typeof onChange === 'function') {
        onChange(result);
        setFocus(false);
      }
    },
    [complete, onChange, value, max]
  );

  const {
    getRootProps,
    getInputProps,
    inputRef: dzInputRef,
    rootRef: dzRootRef,
  } = useDropzone({
    multiple,
    maxFiles,
    disabled: dis || readOnly,
    accept: acceptFormats,
    onDropAccepted: handleDropAccepted,
    onDropRejected: console.log,
    onFileDialogOpen: toggleDialog.on,
    onFileDialogCancel: toggleDialog.off,
  });

  useEffect(() => {
    const { current: input } = dzRootRef;

    if (input && initialFocus) {
      input.focus();
    }
  }, [dzRootRef, initialFocus]);

  useImperativeHandle(inputRef, () => dzInputRef.current, [dzInputRef]);

  const handleOnFocus = useCallback(
    (...args) => {
      if (dialog) {
        return;
      }
      setFocus(true);
      getFunc(onFocus)(...args);
    },
    [onFocus, dialog]
  );

  const handleOnBlur = useCallback(
    (...args) => {
      if (dialog) {
        return;
      }
      setFocus(false);
      getFunc(onBlur)(...args);
    },
    [onBlur, dialog]
  );

  const rootProps = getRootProps({
    disabled: dis,
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
    tabIndex: 0,
  });
  const inputProps = getInputProps();

  return (
    <FormControl
      fullWidth={fullWidth}
      component={Box}
      ref={ref}
      display="inline-flex"
      flexDirection="column"
      {...rest}
    >
      {label && <FormLabel readOnly={readOnly}>{label}</FormLabel>}

      <input {...inputProps} />

      {!component && (
        <DropBox
          variant={variant}
          minWidth={children ? 0 : minWidth}
          minHeight={children ? 0 : minHeight}
          opacity={children ? 0 : 1}
          maxHeight={children ? 0 : undefined}
          border={children || readOnly ? 'none' : undefined}
          {...rootProps}
        >
          {!children && (
            <>
              {uploadTrigger || (
                <Ref color="secondary.main">
                  <Typography variant="body2">Upload</Typography>
                </Ref>
              )}
            </>
          )}

          {!children && (
            <RippleBox>
              <Ripple show={focus} />
            </RippleBox>
          )}
        </DropBox>
      )}

      {!component && children}

      {component && component(rootProps)}
    </FormControl>
  );
});

const defValue = [];

Dropzone.Control = forwardRef((props, ref) => {
  const {
    name,
    inputRef,
    rules: customRules,
    defaultValue = defValue,
    onChange,
    onBlur,
    ...rest
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={customRules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Dropzone
          ref={ref}
          value={field.value}
          inputRef={propagateRefs(inputRef, field.ref)}
          onChange={(...args) => {
            field.onChange(...args);
            getFunc(onChange)(...args);
          }}
          onBlur={(...args) => {
            field.onBlur(...args);
            getFunc(onBlur)(...args);
          }}
          {...rest}
        />
      )}
    />
  );
});

if (dev) {
  Dropzone.Demo = lazy(() => import('./Dropzone.demo'));
}

export default Dropzone;
