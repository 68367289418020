import { forwardRef, lazy, useCallback } from 'react';
import {
  Slider as MuiSlider,
  sliderClasses as classes,
  styled,
} from '@mui/material';

import { dev } from 'constants';
import { getFunc } from 'utils';

const Root = styled(MuiSlider, {
  label: 'D4PV-Slider',
})(({ theme }) => ({
  [`&.${classes.root}`]: {
    [`& .${classes.rail}`]: {
      opacity: 1,
      backgroundColor: theme.palette.colors.disabled_background,
    },

    [`& .${classes.track}`]: {
      borderWidth: 0,
    },

    [`& .${classes.thumb}`]: {
      width: 16,
      height: 16,

      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
}));

const Slider = forwardRef((props, ref) => {
  const { color = 'secondary', onChange, onValue, ...rest } = props;

  const handleChange = useCallback(
    (e, v, ...args) => {
      getFunc(onChange)(e, v, ...args);
      getFunc(onValue)(v);
    },
    [onChange, onValue]
  );

  return <Root ref={ref} color={color} onChange={handleChange} {...rest} />;
});

if (dev) {
  Slider.Demo = lazy(() => import('./Slider.demo'));
}

export default Slider;
