import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import DevTabs from 'react-dev-tabs';

import Routes from 'routes';
import themes from 'styles';
import store from 'store';
import { dev } from 'constants';
import { GoogleApi, Messages } from 'components';

const devTabs = [
  {
    type: 'components',
    label: 'Components',
    variant: 'inside',
    modules: () => import('components'),
  },
  {
    type: 'components',
    label: 'Views',
    variant: 'inside',
    modules: () => import('views'),
  },
  {
    size: 150,
    type: 'assets',
    label: 'Icons',
    modules: () => import('assets/icons'),
  },
  {
    type: 'assets',
    label: 'Images',
    modules: () => import('assets/images'),
  },
];

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={themes.light}>
        <CssBaseline />

        <GoogleApi>
          <Messages>
            <Routes>
              <DevTabs visible={dev} tabs={devTabs} />
            </Routes>
          </Messages>
        </GoogleApi>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

export default <App />;
