import { forwardRef, lazy, useCallback } from 'react';
import InputMask from 'react-input-mask';

import { dev } from 'constants';
import { getFunc } from 'utils';
import TextField from 'components/TextField';

const MaskField = forwardRef((props, ref) => {
  const {
    mask,
    maskChar = null,
    value,
    onChange,
    onValue,
    disabled,
    inputRef,
    readOnly,
    ...rest
  } = props;

  const handleChange = useCallback(
    (e) => {
      getFunc(onChange)(e);
      getFunc(onValue)(e.target.value);
    },
    [onChange, onValue]
  );

  return (
    <InputMask
      ref={ref}
      mask={mask}
      maskChar={maskChar}
      value={value}
      readOnly={readOnly}
      onChange={handleChange}
      disabled={disabled}
      {...rest}
    >
      {(inputProps) => (
        <TextField
          readOnly={readOnly}
          disabled={disabled}
          inputRef={inputRef}
          {...inputProps}
        />
      )}
    </InputMask>
  );
});

MaskField.Control = forwardRef((props, ref) => {
  const { ControlComponent = MaskField, ...rest } = props;

  return (
    <TextField.Control
      ref={ref}
      ControlComponent={ControlComponent}
      {...rest}
    />
  );
});

if (dev) {
  MaskField.Demo = lazy(() => import('./MaskField.demo.jsx'));
}

export default MaskField;
