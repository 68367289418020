import { forwardRef, lazy } from 'react';
import { Box, styled } from '@mui/material';

import { dev } from 'constants';

const Root = styled(Box, {
  label: 'Delimiter',
  shouldForwardProp: (prop) => !['vertical'].includes(prop),
})(({ theme, vertical }) => ({
  border: `0px solid ${theme.palette.border.light}`,
  borderBottomWidth: vertical ? 0 : 1,
  borderLeftWidth: vertical ? 1 : 0,
  width: vertical ? '1px' : '100%',
  height: vertical ? '100%' : '1px',
}));

const Delimiter = forwardRef((props, ref) => {
  const { vertical = false, ...rest } = props;

  return <Root ref={ref} {...rest} vertical={vertical} />;
});

if (dev) {
  Delimiter.Demo = lazy(() => import('./Delimiter.Demo'));
}

export default Delimiter;
