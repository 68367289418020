import { forwardRef, lazy } from 'react';
import { IconButton, Box, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { withProps } from 'hooks';
import { dev } from 'constants';

const MessageBox = withProps(Box, {
  p: 2,
  borderRadius: 1,
  minWidth: 300,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Root = styled(MessageBox, {
  label: 'Message',
})(({ theme, variant }) => ({
  color: theme.palette?.[variant]?.contrastText || theme.palette.text.primary,
  backgroundColor: !theme.palette[variant]
    ? theme.palette.grey[400]
    : theme.palette[variant].light,

  borderLeft: `4px solid transparent`,
  borderLeftColor: !theme.palette[variant]
    ? theme.palette.grey[700]
    : theme.palette[variant].dark,
}));

const Message = forwardRef((props, ref) => {
  const { variant = 'default', onClose, children } = props;

  return (
    <Root variant={variant} ref={ref}>
      <Box minHeight={30} display="flex" alignItems="center">
        <Typography variant="body2" component="div">
          {children}
        </Typography>
      </Box>

      {typeof onClose === 'function' && (
        <IconButton onClick={onClose} size="small">
          <CloseIcon
            fontSize="small"
            sx={{
              color: (theme) =>
                theme.palette?.[variant]?.contrastText ||
                theme.palette.text.primary,
            }}
          />
        </IconButton>
      )}
    </Root>
  );
});

if (dev) {
  Message.Demo = lazy(() => import('./Message.demo'));
}

export default Message;
