export const WIND_EXPOSURE = {
  TYPE_A: 'A',
  TYPE_B: 'B',
  TYPE_C: 'C',
  TYPE_D: 'D',
};
export const WIND_EXPOSURE_NAMES = {
  [WIND_EXPOSURE.TYPE_A]: WIND_EXPOSURE.TYPE_A,
  [WIND_EXPOSURE.TYPE_B]: WIND_EXPOSURE.TYPE_B,
  [WIND_EXPOSURE.TYPE_C]: WIND_EXPOSURE.TYPE_C,
  [WIND_EXPOSURE.TYPE_D]: WIND_EXPOSURE.TYPE_D,
};
export const WIND_EXPOSURE_LIST = [
  WIND_EXPOSURE.TYPE_A,
  WIND_EXPOSURE.TYPE_B,
  WIND_EXPOSURE.TYPE_C,
  WIND_EXPOSURE.TYPE_D,
].map((value) => ({
  value,
  label: WIND_EXPOSURE_NAMES[value],
}));

export const OCCUPANCY = {
  TYPE_1: 1,
  TYPE_2: 2,
  TYPE_3: 3,
  TYPE_4: 4,
  TYPE_5: 5,
};
export const OCCUPANCY_NAMES = {
  [OCCUPANCY.TYPE_1]: 'I',
  [OCCUPANCY.TYPE_2]: 'II',
  [OCCUPANCY.TYPE_3]: 'III',
  [OCCUPANCY.TYPE_4]: 'IV',
  [OCCUPANCY.TYPE_5]: 'V',
};
export const OCCUPANCY_LIST = [
  OCCUPANCY.TYPE_1,
  OCCUPANCY.TYPE_2,
  OCCUPANCY.TYPE_3,
  OCCUPANCY.TYPE_4,
  OCCUPANCY.TYPE_5,
].map((value) => ({
  value,
  label: OCCUPANCY_NAMES[value],
}));
