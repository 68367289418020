import { alpha } from '@mui/material';
import { Group } from 'paper';

import { fire_clearance } from 'assets';
import { diagram_width, diagram_height } from 'constants';
import { getArray, getFunc } from 'utils';
import { Paper } from 'components';

const caption_height = 80;
const plan_height = diagram_height - caption_height;

const {
  PaperView,
  PaperElement,
  PaperNote,
  PaperReference,
  PaperDropzone,
  PaperRuler,
  PaperCaption,
  PaperLayers,
  PaperNoteBox,
  PaperSvg,
  PaperText,
  PaperMark,
  PaperMouseEvents,
  PaperItem,
} = Paper;

class FireClearance extends PaperNoteBox {
  constructor({ data, ...opts }) {
    super({ data, ...opts });

    const contentGroup = new Group({ name: 'fire-clearance-content-group' });

    this.fire_clearance = new PaperSvg(opts);
    this.label = new PaperText({
      content: 'FIRE CLEARANCE',
      fontSize: 18,
    });
    this.fire_clearance.upload(fire_clearance, () => {
      this.update();
    });
    this.element.addChild(contentGroup);

    contentGroup.addChildren([this.fire_clearance.element, this.label]);
    this.update();
  }

  update() {
    const p = this.point(this.children.note_rect.bounds.topLeft);

    this.label.bounds.topLeft = {
      x: p.x + 25,
      y: p.y + 20,
    };
    this.fire_clearance.element.bounds.leftCenter =
      this.label.bounds.leftCenter;
    this.label.bounds.leftCenter.x +=
      this.fire_clearance.element.bounds.width + 15;
    const width =
      this.fire_clearance.element.bounds.width + this.label.bounds.width;
    const height = Math.max(
      this.fire_clearance.element.bounds.height,
      this.label.bounds.height
    );
    super.update({
      width: width + 25 * 2 + 20,
      height: height + 20 * 2,
    });
  }
}

class StringPlace extends PaperItem {
  constructor(options) {
    const { data, element, ...opts } = options;
    super({ element, ...opts });

    this.mark = new PaperMark({
      ...opts,
      data,
    });
    this.angle = 0;
    this.data = data;
    this.mark.show();
    this.mark.disableDragging();
    this.disableDragging();
    this.disableSelecting();

    const listenMarkClick = (e) => {
      if (this.selectable) {
        this.selected ? this.unselect() : this.select();
      }
    };
    this.mark.element.on('click', listenMarkClick);
  }

  setAngle(v) {
    this.angle = v;
    this.mark.element.rotation = 0 - v;
  }

  onViewMouseMove(e) {
    super.onViewMouseMove(e);

    if (this.selectable) {
      const isOver = this.isOver(e.point);

      if (isOver) {
        this.addCursor('pointer');
        this.element.selected = true;
        return;
      }
    }
    this.removeCursor();
    this.element.selected = false;
  }

  select(...args) {
    super.select(...args);
    this.style('select');
  }

  unselect(...args) {
    super.unselect(...args);
    this.style();
  }

  style(type = 'default') {
    switch (type) {
      case 'select': {
        this.element.strokeColor = 'blue';
        this.element.fillColor = alpha(this.theme.palette.secondary.main, 0.1);
        break;
      }
      case 'default':
      default: {
        this.element.strokeColor = this.element.data.strokeColor;
        this.element.fillColor = this.element.data.fillColor;
        break;
      }
    }
  }

  disableEdit() {
    this.style();
  }

  enableEdit() {
    //
  }

  destroy() {
    this.style();
    this.mark.destroy();
    super.destroy({ keepElement: true });
    this.element.selected = false;
    getFunc(this.options.onDestroy)(this.data.index);
  }
}

class StringsArea extends PaperMouseEvents {
  constructor(options) {
    const { onStringsAreaClick, ...opts } = options;
    const element = new Group({ name: 'strings_area_group' });
    super({ element, ...opts });
    this.layer = null;
    this.rects = [];
    this.places = null;
    this.enabled = false;
    this.onStringsAreaClick = onStringsAreaClick;
    this.marks = {};

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter() {
    if (this.enabled) {
      this.addCursor('pointer');
    }
  }

  onMouseLeave() {
    this.removeCursor();
  }

  setLayer(layer) {
    this.layer = layer;
    const rects = getArray(layer.children[1].children);
    this.rects = rects;
    this.places = rects.length;
    this.angle = 0;

    this.layer.on('mouseenter', this.onMouseEnter);
    this.layer.on('mouseleave', this.onMouseLeave);

    rects.forEach((rect, i) => {
      rect.data = {
        fillColor: 'rgba(255, 255, 255, 0.01)',
        strokeColor: rect.strokeColor,
      };
      this.handleRectEvent(rect, i);
    });
  }

  handleRectEvent(rect, index) {
    const mouseEnter = () => {
      const placed = !!this.marks[index];

      if (this.enabled) {
        rect.fillColor = placed
          ? rect.data.fillColor
          : alpha(this.theme.palette.success.light, 0.5);

        rect.strokeColor = placed ? rect.data.strokeColor : 'blue';
      }
    };
    const mouseLeave = () => {
      const placed = !!this.marks[index];

      if (this.enabled) {
        rect.fillColor = !placed
          ? alpha(this.theme.palette.success.light, 0.2)
          : rect.data.fillColor;

        rect.strokeColor = !placed ? 'blue' : rect.data.strokeColor;
      }
    };
    const click = () => {
      if (!this.marks[index]) {
        getFunc(this.onStringsAreaClick)(index);
        rect.fillColor = rect.data.fillColor;
      }
    };
    rect.on('mouseenter', mouseEnter);
    rect.on('mouseleave', mouseLeave);
    rect.on('click', click);
  }

  enableArea() {
    this.enabled = true;

    this.rects.forEach((rect, index) => {
      if (!this.marks[index]) {
        rect.strokeColor = 'blue';
        rect.fillColor = alpha(this.theme.palette.success.light, 0.2);
      }
    });
    Object.values(this.marks).forEach((mark) => {
      mark.disableSelecting();
    });
  }

  disableArea() {
    this.enabled = false;

    this.rects.forEach((rect) => {
      rect.strokeColor = rect.data.strokeColor;
      rect.fillColor = rect.data.fillColor;
    });
    Object.values(this.marks).forEach((mark) => {
      mark.enableSelecting();
    });
  }

  setAngle(v) {
    this.angle = v;

    Object.values(this.marks).forEach((mark) => {
      mark.setAngle(v);
    });
  }

  addString(string) {
    const { index, element, onSelect, onCreate, ...str } = string;

    if (this.marks[index]) {
      return;
    }
    const rect = this.rects[index];
    const instance = new StringPlace({
      ...this.options,
      element: rect,
      onSelect,
      id: str.id,
      onDestroy: (i) => {
        delete this.marks[i];
      },
      data: {
        index,
        element,
        ...str,
      },
    });
    this.layer.parent.addChild(instance.mark.element);
    instance.mark.element.bounds.center = rect.bounds.center;
    this.marks[index] = instance;
    instance.setAngle(this.angle);
    getFunc(onCreate)(instance);
    return instance;
  }
}

class LayersView extends PaperView {
  constructor(options) {
    const { onStringsAreaClick, ...opts } = options;
    super(opts);

    this.strings_area = new StringsArea({ ...opts, onStringsAreaClick });
    this.layers = new PaperLayers(opts);
    this.children.content.addChildren([
      this.layers.element,
      this.strings_area.element,
    ]);
    this.layers.element.bounds.center = this.children.view_rect.bounds.center;
    this.panels = null;
  }

  addLayers(layers, callback) {
    this.layers.addLayers(layers, callback);
  }

  addPanels(panels, callback) {
    const handleAddPanels = (item) => {
      this.panels = item;
      this.strings_area.setLayer(item);
      callback();
    };
    this.layers.addLayers([panels], handleAddPanels);
  }
}

class CaptionView extends PaperView {
  constructor(options) {
    super(options);

    this.caption = new PaperCaption({
      ...options,
      data: {
        page: '02',
        label: 'Electrical Plan',
      },
    });

    this.children.content.addChildren([this.caption.element]);
    this.caption.element.bounds.center.x =
      this.children.view_rect.bounds.center.x + 0.5;

    this.caption.element.bounds.center.y =
      this.children.view_rect.bounds.center.y + 0.5;
  }
}

class PlanView extends PaperView {
  constructor(options) {
    const { onStringsAreaClick, ...opts } = options;
    super(opts);

    this.notes = {};
    this.items = {};
    this.layers_view = new LayersView({ ...opts, onStringsAreaClick });

    this.fire_clearance = new FireClearance({
      ...opts,
      data: {
        x: diagram_width - 320,
        y: 50,
      },
    });
    this.fire_clearance.show();

    this.dropzone = new PaperDropzone({
      ...opts,
      x: 0,
      y: 0,
      width: diagram_width,
      height: plan_height,
    });
    this.children.content.addChildren([
      this.layers_view.element,
      this.dropzone.element,
      this.fire_clearance.element,
    ]);
  }

  addNote(note) {
    const step_x = 40;
    const step_y = 20;
    const start_x = diagram_width / 3;
    const start_y = diagram_height / 3;
    const offset = Object.values(this.notes).length;

    if (this.notes[note.id]) {
      return;
    }
    const noteInstance = new PaperNote({
      ...this.options,
      data: {
        ...note,
        x: start_x + step_x * offset,
        y: start_y + step_y * offset,
      },
    });
    this.notes[note.id] = noteInstance;
    this.children.content.addChild(noteInstance.element);
  }

  removeNote(id) {
    const instance = this.notes[id];
    delete this.notes[id];

    if (instance) {
      instance.destroy();
    }
  }

  addReference(ref) {
    if (this.items[ref.id]) {
      return;
    }
    const { onSelect, sticking, ...reference } = ref;

    const refInstance = new PaperReference({
      ...this.options,
      sticking,
      stickTo: this.layers_view.layers.element,
      onSelect,
      id: ref.id,
      parent: this.children.content,
      data: {
        ...reference,
      },
    });
    this.items[ref.id] = refInstance;
    this.children.content.addChild(refInstance.element);
    refInstance.select({ new: true });
  }

  addRuler(r) {
    if (this.items[r.id]) {
      return;
    }
    const { onSelect, onCreate, sticking, angleAlignment, ...ruler } = r;

    const rulerInstance = new PaperRuler({
      ...this.options,
      sticking,
      angleAlignment,
      stickTo: this.layers_view.layers.element,
      onSelect,
      onCreate,
      id: ruler.id,
      data: { ...ruler },
    });
    this.items[r.id] = rulerInstance;
    this.children.content.addChild(rulerInstance.element);
    rulerInstance.select();
  }

  addString(string) {
    if (this.items[string.id]) {
      return;
    }
    const instance = this.layers_view.strings_area.addString(string);
    this.items[string.id] = instance;
  }

  removeItem(id) {
    const instance = this.items[id];
    delete this.items[id];

    if (instance) {
      instance.destroy();
    }
  }
}

class ElectricalPlanController extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'main' });
    super({ element, ...options });

    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleStringsAreaClick = this.handleStringsAreaClick.bind(this);

    this.callbacks = {};
    this.updateNorthAngle = this.updateNorthAngle.bind(this);
    this.onCanvasMouseUp = this.onCanvasMouseUp.bind(this);
    this.scope.project.activeLayer.addChild(element);

    this.sticking = false;
    this.angleAlignment = false;

    this.caption = new CaptionView({
      width: diagram_width,
      height: caption_height,
      ...options,
    });
    this.plan = new PlanView({
      width: diagram_width,
      height: plan_height,
      onStringsAreaClick: this.handleStringsAreaClick,
      ...options,
    });
    element.addChildren([this.plan.element, this.caption.element]);
    this.caption.element.bounds.topLeft.y = plan_height;

    this.scope.view.on('mouseup', this.onCanvasMouseUp);
  }

  enableStringsArea() {
    this.plan.layers_view.strings_area.enableArea();
  }

  disableStringsArea() {
    this.plan.layers_view.strings_area.disableArea();
  }

  addString(string) {
    const { index, element, ...str } = string;

    this.options.stage.current.addActor({
      children: element,
      callback: (el) => {
        const target = el.children[0];

        this.plan.addString({
          index,
          element: target,
          onSelect: this.handleItemSelect,
          ...str,
        });
      },
    });
  }

  updateNorthAngle(v) {
    this.caption.caption.compass.element.rotation = v;
    this.plan.layers_view.layers.element.rotation = v;
    this.plan.layers_view.strings_area.setAngle(v);
  }

  updateScaleMeta(data) {
    this.caption.caption.meta.update(data);
  }

  setLayers(layers, callback) {
    this.plan.layers_view.addLayers(layers, callback);
  }

  setPanelsLayer(layer, callback) {
    this.plan.layers_view.addPanels(layer, callback);
  }

  setLayersScale(scale) {
    this.plan.layers_view.setScale(scale);
  }

  setLayersPosition(p) {
    this.plan.layers_view.layers.element.position = this.point(p);
  }

  togglePanTool(v) {
    this.plan.layers_view.layers.toggleDragging(v);
  }

  toggleFireClearance(v) {
    const fc = this.plan.fire_clearance;
    v ? fc.show() : fc.hide();
  }

  setFireClearancePosition(p) {
    this.plan.fire_clearance.element.position = this.point(p);
  }

  addNote(note) {
    this.plan.addNote(note);
  }

  removeNote(id) {
    this.plan.removeNote(id);
  }

  getNote(id) {
    return this.plan.notes[id];
  }

  getNotes() {
    return Object.values(this.plan.notes);
  }

  enableDropzone() {
    this.plan.dropzone.enable();
  }

  disableDropzone() {
    this.plan.dropzone.disable();
  }

  getDropPoint() {
    return this.plan.dropzone.drop_point;
  }

  addReference(ref) {
    this.plan.addReference({
      ...ref,
      sticking: this.sticking,
      angleAlignment: this.angleAlignment,
      onSelect: this.handleItemSelect,
    });
  }

  addRuler(ruler) {
    this.plan.addRuler({
      ...ruler,
      sticking: this.sticking,
      angleAlignment: this.angleAlignment,
      onSelect: this.handleItemSelect,
    });
  }

  getItem(id) {
    return this.plan.items[id];
  }

  getItems() {
    return Object.values(this.plan.items);
  }

  clearItems() {
    const ids = Object.keys(this.plan.items);

    ids.forEach((id) => {
      this.removeItem(id);
    });
  }

  removeItem(id) {
    this.plan.removeItem(id);
  }

  selectItem(id) {
    this.plan.items[id]?.select();
  }

  unselectItem(id) {
    if (id) {
      this.plan.items[id]?.unselect();
      return;
    }
    Object.values(this.plan.items).forEach((item) => {
      item.unselect();
    });
  }

  enableSticking() {
    this.sticking = true;

    Object.values(this.plan.items).forEach((item) => {
      item.enableSticking();
    });
  }

  disableSticking() {
    this.sticking = false;

    Object.values(this.plan.items).forEach((item) => {
      item.disableSticking();
    });
  }

  enableAngleAlignment() {
    this.angleAlignment = true;

    Object.values(this.plan.items).forEach((item) => {
      item.enableAngleAlignment();
    });
  }

  disableAngleAlignment() {
    this.angleAlignment = false;

    Object.values(this.plan.items).forEach((item) => {
      item.disableAngleAlignment();
    });
  }

  handleStringsAreaClick(index) {
    getArray(this.callbacks.stringsareaclick).forEach((cb) => {
      getFunc(cb)(index);
    });
  }

  handleItemSelect(id) {
    if (id) {
      Object.entries(this.plan.items).forEach(([item_id, instance]) => {
        if (item_id !== id) {
          instance.unselect({ silent: true });
        }
      });
    }
    getArray(this.callbacks.select).forEach((cb) => {
      getFunc(cb)(id);
    });
  }

  onCanvasMouseUp() {
    getArray(this.callbacks.change).forEach((cb) => {
      cb();
    });
  }

  addListener(event, callback) {
    const listeners = this.callbacks[event] || [];
    listeners.push(callback);
    this.callbacks[event] = listeners;
  }

  removeListener(event, callback) {
    if (!callback) {
      delete this.callbacks[event];
      return;
    }
    this.callbacks[event] = getArray(this.callbacks[event]).filter(
      (cb) => cb !== callback
    );
  }

  disableEdit() {
    this.getNotes().forEach((n) => {
      n.disableEdit();
    });
    this.getItems().forEach((item) => {
      item.disableEdit();
    });
  }

  enableEdit() {
    this.getNotes().forEach((n) => {
      n.enableEdit();
    });
    this.getItems().forEach((item) => {
      item.enableEdit();
    });
  }

  getData() {
    const notes = this.getNotes();
    const layersPosition = this.plan.layers_view.layers.element.position;
    const fireClearancePosition = this.plan.fire_clearance.element.position;

    const data = {
      notes: Object.values(notes).map((noteInstance) => ({
        id: noteInstance.data.id,
        type: noteInstance.data.type,
        text: noteInstance.data.text,
        x: noteInstance.data.x,
        y: noteInstance.data.y,
      })),
      references: this.getItems()
        .filter((item) => item.data.type !== 'ruler')
        .filter((item) => item.data.type !== 'string')
        .map((item) => ({
          id: item.data.id,
          type: item.data.type,
          label: item.data.label,
          custom: !!item.data.custom,
          position: item.data.position,
          segments: item.data.segments,
          x: item.data.x,
          y: item.data.y,
          placed: true,
          value: item.data.id,
        })),
      rulers: this.getItems()
        .filter((item) => item.data.type === 'ruler')
        .map((r) => ({
          id: r.data.id,
          height: r.data.height,
          scale: r.data.scale,
          start: {
            x: r.data.start.x,
            y: r.data.start.y,
          },
          end: {
            x: r.data.end.x,
            y: r.data.end.y,
          },
          label: {
            anchor: r.data.label.anchor,
            position: r.data.label.position,
            text: r.data.label.text,
          },
        })),
      layers: {
        position: {
          x: layersPosition.x,
          y: layersPosition.y,
        },
      },
      fire_clearance: {
        position: {
          x: fireClearancePosition.x,
          y: fireClearancePosition.y,
        },
      },
    };

    return data;
  }

  destroy() {
    if (this.scope?.project?.activeLayer) {
      this.scope.project.activeLayer.removeChildren();
    }
  }
}

export default ElectricalPlanController;
