export const inputAutofillSelectors = (root = 'input') => {
  return [
    `${root}:-webkit-autofill`,
    `${root}:-webkit-autofill:hover`,
    `${root}:-webkit-autofill:focus`,
    `${root}:-webkit-autofill:active`,
    `${root}[data-autocompleted]`,
    `${root}:-internal-autofill-selected`,
  ].join(', ');
};
