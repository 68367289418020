import { forwardRef, lazy } from 'react';

import { dev } from 'constants';
import { SvgElement } from 'components';

const size = 32;
const strokeWidth = 1;
const center = size / 2;
const radius = center - strokeWidth;

const StringMark = forwardRef((props, ref) => {
  const { text, fill = 'white', ...rest } = props;

  return (
    <SvgElement ref={ref} width={size} height={size} {...rest}>
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill={fill}
        stroke="black"
        strokeWidth={strokeWidth}
      />

      <text x={12} y={21} fontSize={14} fontFamily="ArialNarrow">
        {text}
      </text>
    </SvgElement>
  );
});

if (dev) {
  StringMark.Demo = lazy(() => import('./StringMark.demo'));
}

export default StringMark;
