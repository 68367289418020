import { useMemo } from 'react';
import { Box, styled } from '@mui/material';

import { routes } from 'routes';
import { getArray } from 'utils';
import { PREMIUM_STEPS } from 'constants';
import { useAuth, withProps } from 'hooks';
import { ProjectStepsList } from 'views';
import { Icon } from 'components';

export const ICON_SIZE = 32;
export const MARK_SIZE = 11;

const IconWrapper = withProps(Box, {
  width: ICON_SIZE,
  height: ICON_SIZE,
  display: 'flex',
  component: 'span',
  alignItems: 'center',
  justifyContent: 'center',
});

const PremiumIconWrapper = withProps(Box, {
  sx: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    borderRadius: '50%',
    bgcolor: 'violet.dark',
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 0,
    border: '1px solid transparent',
    borderColor: 'border.dark',
    zIndex: 10,
  },
});

const LockIcon = withProps(Icon.Lock, {
  sx: {
    fontSize: 14,
    color: 'border.dark',
    marginBottom: '1px',
  },
});

const Mark = withProps(Box, {
  width: MARK_SIZE,
  height: MARK_SIZE,
  component: 'span',
  borderRadius: '50%',
  backgroundColor: 'transparent',
  border: '2px solid transparent',
});

const StepMark = styled(Mark, {
  label: 'StepMark',
  shouldForwardProp: (prop) =>
    !['disabled', 'completed', 'premium'].includes(prop),
})(({ theme, disabled, completed, premium }) => ({
  position: 'relative',
  borderColor: (() => {
    if (premium) {
      return 'transparent';
    }
    if (disabled) {
      return theme.palette.border.dark;
    }
    return theme.palette.secondary.main;
  })(),
  backgroundColor: (() => {
    if (premium) {
      return 'transparent';
    }
    if (disabled) {
      return theme.palette.border.dark;
    }
    if (completed) {
      return theme.palette.secondary.main;
    }
  })(),
}));

const Line = withProps(Box, {
  width: '1px',
  height: ICON_SIZE - MARK_SIZE,
  component: 'span',
  position: 'absolute',
  left: 'calc(50% - 0.5px)',
  top: 0 - (ICON_SIZE + 2 - MARK_SIZE),
});

const StepLine = styled(Line, {
  label: 'StepLine',
  shouldForwardProp: (prop) => !['disabled'].includes(prop),
})(({ theme, disabled }) => ({
  backgroundColor: disabled
    ? theme.palette.border.dark
    : theme.palette.secondary.main,
}));

const StepsSubMenu = (props) => {
  const { options, completed, step, latestStep, project_id, onPremiumClick } =
    props;

  const { premium } = useAuth();

  const isActiveVolume = useMemo(
    () => options.some(({ value }) => value === latestStep),
    [options, latestStep]
  );

  const completedSteps = useMemo(() => {
    const result = [];

    options.some(({ value }) => {
      if (completed) {
        result.push(value);
        return false;
      }
      if (!isActiveVolume) {
        return true;
      }
      result.push(value);
      return value === latestStep;
    });
    return result;
  }, [options, completed, isActiveVolume, latestStep]);

  const opts = useMemo(() => {
    return getArray(options).map((opt, i) => {
      const disabled = !completedSteps.includes(opt.value);
      const isPremium = PREMIUM_STEPS.includes(opt.value) && !premium;
      const to =
        disabled || isPremium
          ? undefined
          : routes[opt.value].path({ project_id });

      const isCompleted =
        completedSteps.includes(opt.value) && opt.value !== step;

      const handlePremiumClick = () => {
        onPremiumClick(opt.label);
      };
      return {
        ...opt,
        to,
        disabled,
        selected: !disabled && !isCompleted,
        onClick: isPremium ? handlePremiumClick : undefined,
        icon: (
          <IconWrapper>
            <StepMark
              disabled={disabled}
              completed={isCompleted}
              premium={isPremium}
            >
              {i > 0 && <StepLine disabled={disabled} />}

              {isPremium && (
                <PremiumIconWrapper>
                  <LockIcon />
                </PremiumIconWrapper>
              )}
            </StepMark>
          </IconWrapper>
        ),
      };
    });
  }, [options, completedSteps, step, project_id, onPremiumClick, premium]);

  return <ProjectStepsList dark size="small" options={opts} />;
};

export default StepsSubMenu;
