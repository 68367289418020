import { useCallback, useEffect } from 'react';
import { Box, Typography, Stack } from '@mui/material';

import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useDeleteUserImageMutation,
  useUploadUserImageMutation,
} from 'store';

import { UploadAvatar } from 'views';
import { Button, Form, ProgressBox, TextField, useMessage } from 'components';

const ProfileInformation = () => {
  const m = useMessage();
  const form = Form.useForm();
  const { reset } = form;
  const { isValid, isDirty } = form.formState;

  const { data: user, isFetching, refetch } = useGetProfileQuery();
  const [updateProfile, updateProfileState] = useUpdateProfileMutation();
  const [deleteImage, deleteImageState] = useDeleteUserImageMutation();
  const [uploadImage, uploadImageState] = useUploadUserImageMutation();

  const loading =
    isFetching ||
    updateProfileState.isLoading ||
    deleteImageState.isLoading ||
    uploadImageState.isLoading;

  const handleCancel = useCallback(() => {
    form.reset();
  }, [form]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const avatar = [];

    if (user.image) {
      avatar.push({ url: user.image });
    }
    reset({
      avatar,
      last_name: user.last_name,
      first_name: user.first_name,
      email: user.email,
    });
  }, [user, reset]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const skipInvalidation = true;
        const { avatar, ...body } = formData;
        const [file] = avatar;
        const responses = [];

        if (file instanceof File) {
          responses.push(
            uploadImage({
              skipInvalidation,
              body: { file },
            }).unwrap()
          );
        }
        if (!file && user.image) {
          responses.push(deleteImage({ skipInvalidation }).unwrap());
        }
        responses.push(updateProfile({ skipInvalidation, body }).unwrap());

        await Promise.all(responses);
        await refetch();
        m.success('Profile has been updated!');
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [deleteImage, m, updateProfile, uploadImage, user, refetch]
  );

  return (
    <ProgressBox progress={loading}>
      <Typography variant="subtitle2">Profile Information</Typography>

      <Form
        mt={5}
        form={form}
        display="flex"
        gap={5}
        onSubmit={handleSubmit}
        alignItems="flex-start"
      >
        <UploadAvatar.Control defaultValue={[]} name="avatar" />

        <Box flexGrow={1}>
          <Stack spacing={1}>
            <TextField.Control
              required
              name="first_name"
              label="First Name"
              placeholder="Goward"
              defaultValue={user?.first_name}
            />

            <TextField.Control
              required
              name="last_name"
              label="Last Name"
              placeholder="Cooper"
              defaultValue={user?.last_name}
            />

            <TextField.Control
              required
              name="email"
              type="email"
              label="Email"
              placeholder="goward.cooper@gmail.com"
              defaultValue={user?.email}
            />

            <Box pt={1} display="flex" gap={1}>
              <Button
                variant="outlined"
                fullWidth={false}
                minWidth={100}
                disabled={!isDirty}
                onClick={handleCancel}
              >
                Cancel
              </Button>

              <Button type="submit" disabled={!isValid || !isDirty}>
                Save
              </Button>
            </Box>
          </Stack>
        </Box>
      </Form>
    </ProgressBox>
  );
};

export default ProfileInformation;
