import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import {
  useGetProjectDetailsQuery,
  useLazyGetCountyByAhjQuery,
  useUploadToTemporaryStorageMutation,
  useUpdateProjectsPropertyInformationMutation,
} from 'store';

import { routes } from 'routes';
import { getString } from 'utils';
import { useToggle, withProps } from 'hooks';
import { AddressField, ProjectSubmitButton } from 'views';
import { ProjectDetailsLayout } from 'layouts';
import {
  Form,
  ProgressBox,
  Ref,
  TextField,
  Delimiter as OriginalDelimiter,
  useMessage,
} from 'components';

import WeatherStation from './WeatherStation';
import AhjSearch from './AhjSearch';
import EmptyAhjWarning from './EmptyAhjWarning';
import UtilitySearch from './UtilitySearch';
import LocateButton from './LocateButton';
import ApplicableCodesModal from './ApplicableCodesModal';
import PropertyInformationPreview from './PropertyInformationPreview';

const FormSection = withProps(Box, {
  display: 'flex',
  flexDirection: 'column',
});
const Delimiter = withProps(OriginalDelimiter, {
  my: 2,
});

const PropertyInformation = () => {
  const { project_id } = useParams();
  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const navigate = useNavigate();
  const m = useMessage();
  const form = Form.useForm();

  const [getCountyByAhj] = useLazyGetCountyByAhjQuery();
  const [uploadFile] = useUploadToTemporaryStorageMutation();
  const [updateInformation] = useUpdateProjectsPropertyInformationMutation();

  const [submitLoading, toggleSubmitLoading] = useToggle();
  const [apCodesModal, toggleApCodesModal] = useToggle();
  const previewApiRef = useRef();

  const loading = submitLoading || isFetching;
  const isFirstSaving = !project?.address;
  const nextStep = useMemo(
    () => routes.site_design_criteria.path({ project_id }),
    [project_id]
  );

  useEffect(() => {
    const ahj = project?.county?.ahj_dictionary;
    const utility = project?.utility;

    form.reset({
      owner_first_name: getString(project?.owner_first_name),
      owner_last_name: getString(project?.owner_last_name),
      address: project?.address,
      meteo_station_id: project?.meteo_station?.id,
      ahj: !ahj
        ? null
        : {
            value: ahj.id,
            label: ahj.name,
          },
      utility: !utility
        ? null
        : {
            value: utility.id,
            label: utility.utility,
          },
      parcel_number: getString(project?.parcel_number),
      image: project?.property_image,
    });
  }, [form, project]);

  const handleLocateClick = useCallback(() => {
    previewApiRef.current.locate();
  }, []);

  const handleSubmit = useCallback(
    async ({ ahj, utility, ...formData }) => {
      toggleSubmitLoading.on();

      try {
        const county = await getCountyByAhj({ ahj_id: ahj.value }).unwrap();
        const image = await previewApiRef.current.getImage();

        const body = {
          ...formData,
          county_id: county.id,
          utility_id: utility?.value || null,
          property_image: null,
        };
        delete body.image;

        if (image) {
          const response = await uploadFile({
            body: {
              file: image,
            },
          }).unwrap();

          body.property_image = response.file_key;
        }
        await updateInformation({ project_id, body }).unwrap();
        m.success('Project has been updated!');

        if (isFirstSaving) {
          navigate(nextStep);
        }
      } catch (ex) {
        m.responseError(ex);
      }
      toggleSubmitLoading.off();
    },
    [
      m,
      getCountyByAhj,
      toggleSubmitLoading,
      uploadFile,
      updateInformation,
      project_id,
      isFirstSaving,
      navigate,
      nextStep,
    ]
  );

  return (
    <ProjectDetailsLayout
      form={form}
      component={Form}
      title="Property Information"
      onSubmit={handleSubmit}
      body={
        <>
          {/* MODALS */}
          <>
            <ApplicableCodesModal
              open={apCodesModal}
              onClose={toggleApCodesModal.off}
            />
          </>

          <ProgressBox progress={loading}>
            <FormSection gap={2}>
              <TextField.Control
                required
                initialFocus
                name="owner_first_name"
                label="Homeowner First Name"
                placeholder="Add First Name"
              />

              <TextField.Control
                required
                name="owner_last_name"
                label="Homeowner Last Name"
                placeholder="Add Last Name"
              />
            </FormSection>

            <Delimiter />

            <Typography variant="subtitle2" my={1}>
              Address
            </Typography>

            <FormSection gap={2}>
              <Box>
                <AddressField.Control
                  manual
                  required
                  name="address"
                  label="Search address *"
                  placeholder="Search..."
                />

                <LocateButton onClick={handleLocateClick} />
              </Box>

              <WeatherStation name="meteo_station_id" />
            </FormSection>

            <Delimiter />

            <Typography variant="subtitle2" my={1}>
              Authorities
            </Typography>

            <FormSection>
              <AhjSearch name="ahj" />
              <EmptyAhjWarning />
            </FormSection>

            <FormSection gap={2} mt={2}>
              <UtilitySearch name="utility" />

              <Box mb={1}>
                <Ref color="secondary.main" onClick={toggleApCodesModal.on}>
                  <Typography variant="body2">View Applicable Codes</Typography>
                </Ref>
              </Box>

              <TextField.Control
                required
                name="parcel_number"
                label="Assessor's Parcel Number"
              />
            </FormSection>

            <Box mt={4}>
              <ProjectSubmitButton
                nextStep={nextStep}
                isFirstSaving={isFirstSaving}
              />
            </Box>
          </ProgressBox>
        </>
      }
    >
      <PropertyInformationPreview name="image" previewApiRef={previewApiRef} />
    </ProjectDetailsLayout>
  );
};

export default PropertyInformation;
