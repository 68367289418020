import { useCallback, useMemo, useState } from 'react';
import {
  Typography,
  Box,
  Stack,
  IconButton as MuiIconButton,
} from '@mui/material';

import {
  useGetOrganisationMembersQuery,
  useGetOrganistionInvitesQuery,
} from 'store';

import { useAuth, useToggle, withProps } from 'hooks';
import { parseUserRole, ROLES } from 'constants';
import { getArray, getUserFullName } from 'utils';
import { SearchField } from 'views';
import {
  Button,
  ProgressBox,
  DataTable,
  Avatar,
  Icon,
  Center,
  Pagination,
} from 'components';

import EditUserModal from './EditUserModal';
import DeleteUserModal from './DeleteUserModal';

const IconButton = withProps(MuiIconButton, {
  size: 'small',
  sx: { color: 'primary.main' },
});
const rowsPerPage = 10;

const Users = () => {
  const [search, setSearch] = useState('');
  const [editUser, setEditUser] = useState(null);
  const [page, setPage] = useState(1);
  const [editUserModal, toggleEditUserModal] = useToggle();
  const [deleteUserModal, toggleDeleteUserModal] = useToggle();

  const { data: members, isFetching } = useGetOrganisationMembersQuery();
  const { data: invites, isFetching: invitesFetching } =
    useGetOrganistionInvitesQuery();
  const { user } = useAuth();

  const loading = isFetching || invitesFetching;

  const rows = useMemo(() => {
    const invitedMembers = getArray(invites).map((inv) => ({
      invite: true,
      ...inv,
    }));
    const users = getArray(members).map((member) => ({
      current: member.id === user.id,
      ...member,
    }));
    const result = [...users, ...invitedMembers].map((member) => {
      const role = parseUserRole(member.role);
      const type = (() => {
        if (member.invite) {
          return '';
        }
        switch (role) {
          case ROLES.OWNER:
            return 'Admin';
          case ROLES.MEMBER:
          default:
            return 'User';
        }
      })();

      return {
        ...member,
        role,
        type,
        name: getUserFullName(member),
      };
    });
    return result.filter((user) => {
      if (!search) {
        return true;
      }
      const searchField = [user.first_name, user.last_name, user.email]
        .filter(Boolean)
        .join(' ');

      return searchField.toLowerCase().includes(search.trim().toLowerCase());
    });
  }, [members, invites, user, search]);

  const count = Math.ceil(rows.length / rowsPerPage);

  const visibleRows = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return rows.slice(start, end);
  }, [page, rows]);

  const handleEditUserModalClose = useCallback(() => {
    toggleEditUserModal.off();
    setEditUser(null);
  }, [toggleEditUserModal]);

  const handleDeleteUserModalClose = useCallback(() => {
    toggleDeleteUserModal.off();
    setEditUser(null);
  }, [toggleDeleteUserModal]);

  return (
    <>
      {/* MODALS */}
      <>
        <EditUserModal
          user={editUser}
          open={editUserModal}
          onClose={handleEditUserModalClose}
        />

        <DeleteUserModal
          user={editUser}
          open={deleteUserModal}
          invite={editUser?.invite}
          onClose={handleDeleteUserModalClose}
        />
      </>

      <Box p={4} flexGrow={1} display="flex" flexDirection="column">
        <Typography variant="subtitle1">Users</Typography>

        <Box
          mt={2}
          gap={6}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            size="medium"
            minWidth={120}
            fullWidth={false}
            onClick={toggleEditUserModal.on}
          >
            Add User
          </Button>

          <SearchField value={search} onValue={setSearch} />
        </Box>

        <ProgressBox progress={loading} mt={4} flexGrow={1}>
          <DataTable
            rows={visibleRows}
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                width: 246,
                renderCell: ({ value, row }) => {
                  return (
                    <Stack direction="row" spacing={1} alignItems="center">
                      {row.invite && (
                        <>
                          <Avatar
                            size={4}
                            bgcolor="transparent"
                            color="border.main"
                            sx={{ borderColor: 'border.main' }}
                          />

                          <Typography variant="body3" color="border.main">
                            waiting for confirmation...
                          </Typography>
                        </>
                      )}

                      {!row.invite && (
                        <>
                          <Avatar src={row.image} name={value} size={4} />

                          <Typography variant="body2">{value}</Typography>
                        </>
                      )}
                    </Stack>
                  );
                },
              },
              {
                field: 'type',
                headerName: 'Role',
                width: 149,
              },
              {
                field: 'email',
                headerName: 'Email',
              },
              {
                id: 'actions',
                width: 108,
                cellProps: {
                  sx: { py: 0 },
                },
                renderCell: ({ row }) => {
                  if (row.current) {
                    return null;
                  }
                  const handleEditUser = () => {
                    setEditUser(row);
                    toggleEditUserModal.on();
                  };
                  const handleDeleteUser = () => {
                    setEditUser(row);
                    toggleDeleteUserModal(row);
                  };
                  return (
                    <Box display="flex" justifyContent="flex-end" gap={1}>
                      {!row.current && (
                        <>
                          {!row.invite && (
                            <IconButton onClick={handleEditUser}>
                              <Icon.Pencil />
                            </IconButton>
                          )}

                          <IconButton onClick={handleDeleteUser}>
                            <Icon.Trash />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  );
                },
              },
            ]}
          />

          {count > 1 && (
            <Center mt={4}>
              <Pagination page={page} onValue={setPage} count={count} />
            </Center>
          )}
        </ProgressBox>
      </Box>
    </>
  );
};

export default Users;
