import { useEffect } from 'react';
import { Box } from '@mui/material';

import { actions } from 'store';
import { Spinner } from 'components';

const { logout } = actions;

const SignOut = () => {
  useEffect(() => {
    logout();
  }, []);

  return (
    <Box
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="xlarge" />
    </Box>
  );
};

export default SignOut;
