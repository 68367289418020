import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid, Box, IconButton } from '@mui/material';

import { Button, Center, Icon, SelectField } from 'components';

const createEmptyString = (value = '') => {
  return {
    value,
    type: 'string',
  };
};

const Strings = (props) => {
  const { min, max, name, count, label, mppt } = props;

  const { control, setValue, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
    control,
    keyName: 'field_id',
    shouldUnregister: true,
  });
  const options = useMemo(
    () =>
      new Array(max - min + 1).fill(null).map((v, index) => ({
        value: index + min,
        label: index + min,
      })),
    [max, min]
  );

  const handleAddString = useCallback(() => {
    if (mppt && fields.length > 0) {
      const firstValue = getValues(`${name}.0.value`);
      return append(createEmptyString(firstValue));
    }
    append(createEmptyString());
  }, [append, fields, getValues, mppt, name]);

  useEffect(() => {
    if (fields.length === 0) {
      handleAddString();
    }
  }, [fields, handleAddString]);

  const handleChangeFirst = useCallback(
    (e) => {
      const v = e.target.value;

      fields.forEach((f, i) => {
        if (i > 0) {
          setValue(`${name}.${i}.value`, v);
        }
      });
    },
    [fields, name, setValue]
  );

  return (
    <Grid container spacing={2} rowSpacing={1} alignItems="center">
      {fields.map((field, i) => {
        const handleRemove = () => {
          remove(i);
        };
        return (
          <Grid item xs={6} key={field.field_id}>
            <SelectField.Control
              required
              hideAsterisk
              onChange={i === 0 && mppt ? handleChangeFirst : undefined}
              displayEmpty={false}
              disabled={i > 0 && mppt}
              defaultValue=""
              options={options}
              name={`${name}.${i}.value`}
              label={
                <Center justifyContent="space-between" alignItems="flex-start">
                  <span>
                    {label} #{i + 1} *
                  </span>

                  <Box position="relative">
                    <Box position="absolute" top={-2} right={0}>
                      <IconButton
                        size="small"
                        onClick={handleRemove}
                        disabled={fields.length === 1}
                      >
                        <Icon.Close
                          sx={{
                            fontSize: 12,
                            borderRadius: '50%',
                            color: 'common.white',
                            bgcolor:
                              fields.length === 1
                                ? 'border.light'
                                : 'secondary.main',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Center>
              }
            />
          </Grid>
        );
      })}

      <Grid item xs={6}>
        <Button
          size="medium"
          variant="dashed"
          onClick={handleAddString}
          disabled={fields.length >= count}
        >
          Add String
        </Button>
      </Grid>
    </Grid>
  );
};

export default Strings;
