import { Path } from 'paper';

import { getArray, getFunc } from 'utils';
import PaperMouseEvents from 'components/Paper/PaperMouseEvents';

class PaperDraggable extends PaperMouseEvents {
  constructor(options) {
    super(options);
    this.drag = null;
    this.stickTo = options.stickTo;
    this.sticking =
      typeof options.sticking === 'boolean'
        ? options.sticking
        : !!options.stickTo;

    this.stickAnchors = options.stickAnchors;
    this.alignAnglePoint = null;
    this.callbacks = {
      onDragStart: options.onDragStart,
      onDrag: options.onDrag,
      onDrop: options.onDrop,
      onAllowDrag: options.onAllowDrag,
    };
    this.draggable = true;
    this.dragCursor = options.dragCursor || 'move';
    this.grabCursor = options.grabCursor || 'grabbing';

    this.anchorElement = options.anchorElement || this.element;

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseDrag = this.onMouseDrag.bind(this);

    this.anchorElement.on('mousedown', this.onMouseDown);
    this.anchorElement.on('mouseup', this.onMouseUp);
    this.anchorElement.on('mouseenter', this.onMouseEnter);
    this.anchorElement.on('mouseleave', this.onMouseLeave);
    this.anchorElement.on('mousedrag', this.onMouseDrag);
  }

  onMouseDown(e) {
    if (!this.draggable) {
      return;
    }
    e.stopPropagation();

    this.drag = {
      point: e.point,
      position: this.toParentGlobal(this.element.position),
    };
    this.clearCursor();
    this.addCursor(this.grabCursor);

    if (this.callbacks.onDragStart) {
      this.callbacks.onDragStart(e, this.getEventData());
    }
  }

  onMouseUp() {
    this.drag = null;

    if (!this.draggable) {
      return;
    }
    this.clearCursor();
    this.addCursor(this.dragCursor);
    getFunc(this.callbacks.onDrop)();
  }

  onMouseEnter() {
    if (this.drag || !this.draggable) {
      return;
    }
    this.clearCursor();
    this.addCursor(this.dragCursor);
  }

  onMouseLeave() {
    if (this.drag || !this.draggable) {
      return;
    }
    this.clearCursor();
  }

  onMouseDrag(e) {
    if (!this.draggable) {
      return;
    }
    const offsetX = this.drag.point.x - e.point.x;
    const offsetY = this.drag.point.y - e.point.y;

    const newPoint = this.point({
      x: this.drag.position.x - offsetX,
      y: this.drag.position.y - offsetY,
    });
    this.element.position = this.toParentLocal(newPoint);
    const stickPoint = this.getStickPoint(e);

    if (stickPoint) {
      newPoint.x = stickPoint.x;
      newPoint.y = stickPoint.y;
    }
    let allowDrag;

    if (this.callbacks.onAllowDrag) {
      allowDrag = this.callbacks.onAllowDrag(e, this.getEventData());
    }
    e.stopPropagation();

    if (allowDrag === false) {
      return;
    }
    this.element.position = this.toParentLocal(newPoint);

    if (this.callbacks.onDrag) {
      this.callbacks.onDrag(e, this.getEventData());
    }
  }

  getStickPoint(e) {
    if (!this.stickTo || e.modifiers.command || !this.sticking) {
      return null;
    }
    const anchors = this.stickAnchors || [e.point];

    if (!this.testPoints) {
      this.testPoints = [];
    }
    getArray(this.testPoints).forEach((item) => {
      item.remove();
    });

    const hit = anchors
      .map((anchor) => {
        const point =
          typeof anchor !== 'string'
            ? anchor
            : this.toParentGlobal(this.element.bounds[anchor]);

        const hits = this.stickTo.hitTestAll(
          this.stickTo.parent.globalToLocal(point),
          {
            fill: false,
            stroke: true,
            tolerance: 20,
            segments: true,
          }
        );
        const hitResult =
          hits.length === 0
            ? null
            : hits
                .map((hr) => {
                  const owner = hr.item.parent || this.stickTo.parent;
                  const globalPoint = owner.localToGlobal(hr.point);
                  const distance = globalPoint.getDistance(point);
                  return { ...hr, distance, globalPoint };
                })
                .reduce(
                  (winner, hr) =>
                    !winner ? hr : winner.distance > hr.distance ? hr : winner,
                  null
                );

        const hitPoint = hitResult?.globalPoint || null;

        return {
          anchor,
          hitPoint,
          hitTest: hitResult,
          distance: hitResult?.distance,
        };
      })
      .reduce((winner, h) => {
        return !winner ? h : winner.distance > h.distance ? h : winner;
      }, null);

    const offset = { x: 0, y: 0 };

    if (hit?.hitPoint) {
      if (typeof hit.anchor === 'string') {
        const ap = this.toParentGlobal(this.element.bounds[hit.anchor]);
        offset.x = this.toParentGlobal(this.element.position).x - ap.x;
        offset.y = this.toParentGlobal(this.element.position).y - ap.y;
      }
    }
    if (!hit?.hitPoint) {
      return null;
    }
    const stickResult = this.point({
      x: hit.hitPoint.x + offset.x,
      y: hit.hitPoint.y + offset.y,
    });

    const result = this.calculateAngleAlignmentPoint(stickResult, hit.hitTest);
    return result;
  }

  calculateAngleAlignmentPoint(p, hit) {
    if (!this.alignAnglePoint) {
      return p;
    }
    const startPoint = this.alignAnglePoint;
    const location =
      hit.type === 'segment' ? hit.segment.location : hit.location;

    if (location?.curve) {
      const { segment1: s1, segment2: s2 } = location.curve;

      if (s1 && s2) {
        const p1 = hit.item.localToGlobal(s1.point);
        const p2 = hit.item.localToGlobal(s2.point);
        const v2 = p2.subtract(p);
        const touchLine = new Path([p1, p2]);
        const anglePoint = touchLine.getNearestPoint(startPoint);

        if (
          !this.equalPoints(anglePoint, p1) &&
          !this.equalPoints(anglePoint, p2)
        ) {
          const angleVector = startPoint.subtract(anglePoint);
          const apAngle = Math.round(angleVector.getAngle(v2) * 100) / 100;

          if (apAngle === 90) {
            const apDistance = anglePoint.getDistance(p);

            if (apDistance <= 8) {
              return anglePoint;
            }
          }
        }
      }
    }
    return p;
  }

  disableDragging() {
    this.draggable = false;
  }

  enableDragging() {
    this.draggable = true;
  }

  toggleDragging(v) {
    this.draggable = !!v;
  }

  clearCursor() {
    this.removeCursor();
  }

  getEventData() {
    return {
      element: this.element,
      anchor: this.anchorElement,
    };
  }

  alignAngleFrom(point) {
    this.alignAnglePoint = point;
  }

  enableSticking() {
    this.sticking = true;
  }

  disableSticking() {
    this.sticking = false;
  }

  destroy(...args) {
    if (this.anchorElement) {
      this.anchorElement.off('mousedown', this.onMouseDown);
      this.anchorElement.off('mouseup', this.onMouseUp);
      this.anchorElement.off('mouseenter', this.onMouseEnter);
      this.anchorElement.off('mouseleave', this.onMouseLeave);
      this.anchorElement.off('mousedrag', this.onMouseDrag);
    }
    super.destroy(...args);
  }
}

export default PaperDraggable;
