import {
  forwardRef,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  styled,
  OutlinedInput,
  InputAdornment,
  outlinedInputClasses as classes,
  inputAdornmentClasses as adornmentClasses,
} from '@mui/material';

import { getFunc } from 'utils';
import { dev } from 'constants';
import { useCombineRefs } from 'hooks';
import Spinner from 'components/Spinner';

export const inputClasses = classes;

export const inputStyles = ({ theme, readOnly, spins }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: readOnly ? 'transparent' : theme.palette.common.white,

    // Resets
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      WebkitAppearance: spins ? undefined : 'none',
      MozAppearance: spins ? undefined : 'textfield',
    },

    // Regular
    [`& .${classes.input}`]: {
      minHeight: '18px',
      ...theme.typography.body3,
      [`&.${classes.inputSizeSmall}`]: {
        [`&:not(.${classes.inputMultiline})`]: {
          maxHeight: '18px',
        },
      },
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.text.placeholder,
        fontWeight: theme.typography.body3.fontWeight,
        letterSpacing: theme.typography.body3.letterSpacing,
      },
      '&[type="password"]': {
        fontWeight: 900,
        letterSpacing: theme.pxToRem(2),
      },
      // [`&.${classes.inputMultiline}`]: {
      //   resize: 'vertical',
      // },
    },
    [`& .${classes.inputSizeSmall}`]: {
      padding: theme.spacing(
        1.5 - 1 / theme.shape.spacing,
        readOnly ? 0 : 2 - 1 / theme.shape.spacing
      ),
    },
    [`& .${classes.notchedOutline}`]: {
      borderWidth: 1,
      borderColor: readOnly ? 'transparent' : theme.palette.border.light,
      transition: theme.transitions.create('border-color', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    [`& .${adornmentClasses.root}`]: {
      transition: theme.transitions.create(),
      opacity: 0.5,
      '&:focus, :hover': {
        opacity: 1,
      },
    },
    '&:hover': {
      [`& .${classes.notchedOutline}`]: {
        borderColor: readOnly ? 'transparent' : theme.palette.border.main,
      },
      [`& .${adornmentClasses.root}`]: {
        opacity: 1,
      },
    },

    [`&:has(${theme.utils.inputAutofillSelectors(`.${classes.input}`)})`]: {
      backgroundColor: theme.palette.colors.autofill_background,
      [`& .${classes.input}`]: {
        boxShadow: 'none',
      },
    },

    // Disabled
    [`&.${classes.disabled}`]: {
      backgroundColor: theme.palette.colors.disabled_input,
      [`& .${classes.notchedOutline}`]: {
        borderColor: readOnly ? 'transparent' : theme.palette.border.light,
      },
    },

    // Focused
    [`&.${classes.focused}`]: {
      [`& .${classes.input}`]: {
        '&::placeholder': {
          opacity: 0.3,
        },
      },
      [`& .${classes.notchedOutline}`]: {
        borderWidth: 1,
        borderColor: readOnly ? 'transparent' : theme.palette.primary.light,
      },
      [`& .${adornmentClasses.root}`]: {
        opacity: 1,
      },
      // Focused + hover
      '&:hover': {
        [`& .${classes.notchedOutline}`]: {
          borderColor: readOnly ? 'transparent' : theme.palette.primary.main,
        },
      },
    },

    // Error
    [`&.${classes.error}`]: {
      [`& .${classes.notchedOutline}`]: {
        borderColor: readOnly ? 'transparent' : theme.palette.error.light,
      },
      // Error + hover
      '&:hover': {
        [`& .${classes.notchedOutline}`]: {
          borderColor: readOnly ? 'transparent' : theme.palette.error.main,
        },
      },
      // Error + focused
      [`&.${classes.focused}`]: {
        [`& .${classes.notchedOutline}`]: {
          borderColor: readOnly ? 'transparent' : theme.palette.error.dark,
        },
      },
    },
  },
});

const StyledInput = styled(OutlinedInput, {
  label: 'Input',
  shouldForwardProp: (prop) => !['spins'].includes(prop),
})(inputStyles);

const Input = forwardRef((props, ref) => {
  const {
    spins = false,
    size = 'small',
    onValue,
    loading,
    onChange,
    readOnly,
    placeholder,
    initialFocus,
    inputRef: customInputRef,
    endAdornment: customEndAdornment,
    ...rest
  } = props;

  const inputRef = useRef();
  const handleInputRef = useCombineRefs(customInputRef, inputRef);

  const endAdornment = useMemo(() => {
    if (loading) {
      return (
        <InputAdornment position="end">
          <Spinner size="xsmall" />
        </InputAdornment>
      );
    }
    return customEndAdornment;
  }, [customEndAdornment, loading]);

  const handleChange = useCallback(
    (e) => {
      getFunc(onChange)(e);
      getFunc(onValue)(e.target.value);
    },
    [onChange, onValue]
  );

  useEffect(() => {
    const { current: inputElement } = inputRef;

    if (inputElement && initialFocus) {
      inputElement.focus();
    }
  }, [initialFocus]);

  return (
    <StyledInput
      ref={ref}
      size={size}
      spins={spins}
      readOnly={readOnly}
      onChange={handleChange}
      inputRef={handleInputRef}
      endAdornment={endAdornment}
      placeholder={readOnly ? '-' : placeholder}
      {...rest}
    />
  );
});

if (dev) {
  Input.Demo = lazy(() => import('./Input.demo'));
}

export default Input;
