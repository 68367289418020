import { forwardRef, lazy } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { spinner } from 'assets';
import { dev } from 'constants';
import Image from 'components/Image';

const Root = styled(Box, {
  label: 'Spinner',
  shouldForwardProp: (prop) => !['animate'].includes(prop),
})(({ animate }) => ({
  '@keyframes d4pv-spinner-rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  overflow: 'hidden',
  display: 'inline-flex',
  '& > img': {
    animation: animate ? 'd4pv-spinner-rotation 0.8s infinite ease' : undefined,
  },
}));

const sizes = {
  xlarge: 72,
  large: 60,
  medium: 48,
  small: 36,
  xsmall: 24,
};

const Spinner = forwardRef((props, ref) => {
  const { size = 'medium', animate = true, ...rest } = props;

  const s = sizes[size] || size;

  return (
    <Root {...rest} ref={ref} width={s} height={s} animate={animate}>
      <Image src={spinner} width={1} height={1} />
    </Root>
  );
});

if (dev) {
  Spinner.Demo = lazy(() => import('./Spinner.demo'));
}

export default Spinner;
