import { useMemo } from 'react';
import { Stack, IconButton, Box, Typography } from '@mui/material';
import { useParams, Outlet, useMatch } from 'react-router-dom';

import { routes } from 'routes';
import { valueToOption } from 'utils';
import { useGetContractorCompanyDetailsQuery } from 'store';
import { Icon, ProgressBox, Ref, Tabs } from 'components';

const GENERAL = 'general_information';
const NOTES = 'notes';
const REQUIREMENTS = 'requirements';
const PREFERENCES = 'preferences';

const CompanyDetailsLayout = () => {
  const { company_id } = useParams();
  const { data: company, isFetching } = useGetContractorCompanyDetailsQuery({
    company_id,
  });

  const isNotes = !!useMatch({
    end: false,
    path: routes.company_notes.path(),
  });
  const isRequirements = !!useMatch({
    end: false,
    path: routes.company_requirements.path(),
  });
  const isPreferences = !!useMatch({
    end: false,
    path: routes.company_preferences.path(),
  });

  const tab = useMemo(() => {
    if (isNotes) {
      return NOTES;
    }
    if (isRequirements) {
      return REQUIREMENTS;
    }
    if (isPreferences) {
      return PREFERENCES;
    }
    return GENERAL;
  }, [isNotes, isPreferences, isRequirements]);

  return (
    <Box flexGrow={1} display="flex" flexDirection="column">
      <Stack direction="row" alignItems="center" spacing={1} px={4} pt={4}>
        <IconButton
          replace
          nostyles
          component={Ref}
          to={routes.companies.path()}
        >
          <Icon.ArrowBack sx={{ color: 'secondary.main' }} />
        </IconButton>

        <ProgressBox size="xsmall" progress={isFetching} textAlign="center">
          <Typography variant="subtitle1">
            {company?.name || '. . .'}
          </Typography>
        </ProgressBox>
      </Stack>

      <Tabs
        sx={{ px: 4 }}
        value={tab}
        options={[
          {
            ...valueToOption(GENERAL),
            to: routes.company.path({ company_id }),
          },
          {
            ...valueToOption(NOTES),
            to: routes.company_notes.path({ company_id }),
          },
          {
            ...valueToOption(REQUIREMENTS),
            to: routes.company_requirements.path({ company_id }),
          },
          {
            ...valueToOption(PREFERENCES),
            to: routes.company_preferences.path({ company_id }),
          },
        ]}
      />

      <Outlet />
    </Box>
  );
};

export default CompanyDetailsLayout;
