import { Icon } from 'components';

const Marker = () => {
  return (
    <Icon.Location
      sx={{
        stroke: 'black',
        strokeWidth: '1px',
        position: 'relative',
        color: 'secondary.main',
        fontSize: (theme) => theme.pxToRem(65),
        transform: 'translateX(-50%) translateY(-95%)',
      }}
    />
  );
};

export default Marker;
