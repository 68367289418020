import { Collapse, Typography, Box } from '@mui/material';

import { useWatchField } from 'hooks';
import { MSP_STRATEGY_LIST } from 'constants';
import { Center, Delimiter, RadioButtons, Switch } from 'components';

import AcTypeTabs from './AcTypeTabs';
import AcCurrentRatingFields from './AcCurrentRatingFields';

const AcSubpanelFields = () => {
  const subpanel_enabled = useWatchField('subpanel_enabled');

  return (
    <Box mt={2}>
      <Center justifyContent="space-between">
        <Typography variant="subtitle2">AC Subpanel</Typography>

        <Switch.Control name="subpanel_enabled" />
      </Center>

      <Collapse in={subpanel_enabled}>
        <Box mt={2}>
          <RadioButtons.Control
            fullWidth
            spacing={2}
            size="medium"
            variant="flat"
            name="subpanel_strategy"
            options={MSP_STRATEGY_LIST}
            label="Strategy for Subpanel"
          />
        </Box>

        <AcTypeTabs />

        <AcCurrentRatingFields />
      </Collapse>

      <Delimiter mt={2} />
    </Box>
  );
};

export default AcSubpanelFields;
