import {
  POI_LINE_SIDE_LIST,
  POI_LOAD_SIDE_LIST,
  INTERCONNECTION_STRATEGY,
} from 'constants';

import { useWatchField } from 'hooks';
import { SelectField } from 'components';

const PoiField = (props) => {
  const { ...rest } = props;

  const interconnection_strategy = useWatchField('interconnection_strategy');

  const options =
    interconnection_strategy === INTERCONNECTION_STRATEGY.LOAD_SIDE
      ? POI_LOAD_SIDE_LIST
      : POI_LINE_SIDE_LIST;

  return (
    <SelectField.Control {...rest} displayEmpty={false} options={options} />
  );
};

export default PoiField;
