import { Controller, useFormContext } from 'react-hook-form';

import { useWatchField } from 'hooks';
import { SCALES_LIST } from 'constants';
import PreliminaryPlanForm from './PreliminaryPlanForm';

const PreliminaryPlanPreview = (props) => {
  const { nextStep, isFirstSaving, loading, onDelete, onReplace } = props;
  const { control } = useFormContext();
  const layers = useWatchField('layers');

  return (
    <Controller
      name="scale"
      defaultValue={SCALES_LIST[0].value}
      control={control}
      render={({ field }) => {
        if (!layers) {
          return null;
        }
        return (
          <PreliminaryPlanForm
            onDelete={onDelete}
            onReplace={onReplace}
            layers={layers}
            nextStep={nextStep}
            isFirstSaving={isFirstSaving}
            loading={loading}
            value={field.value}
            onChange={field.onChange}
          />
        );
      }}
    />
  );
};

export default PreliminaryPlanPreview;
