import {
  PROJECT_VOLUMES_STEPS,
  PROJECT_STEPS_LIST,
  PROJECT_STEPS as STEPS,
  SYSTEM_TYPES,
} from 'constants';

import { getString, getArray } from './helpers';

const totalSteps = PROJECT_STEPS_LIST.length;

export const getOwnerFullName = (project) => {
  return [
    getString(project?.owner_first_name),
    getString(project?.owner_last_name),
  ]
    .join(' ')
    .trim();
};

export const getProjectAvailablePanelsCount = (project) => {
  return getArray(project?.arrays).reduce((res, array) => {
    return res + array.panels_number;
  }, 0);
};

export const getProjectConfiguredPanelsCount = (project) => {
  const stringsSum = getArray(project?.systems).reduce((total, sys) => {
    const sum = getArray(sys?.strings).reduce((res, str) => {
      if (str?.type === 'mppt' || sys?.system_type === SYSTEM_TYPES.STRING) {
        const values = Array.isArray(str) ? str : getArray(str.value);

        return (
          res +
          values.reduce((t, v) => {
            return (Number.isFinite(v) ? v : v.value) + t;
          }, 0)
        );
      }
      const num = Number.isFinite(str) ? str : str.value;
      return res + num;
    }, 0);

    return total + sum;
  }, 0);

  return stringsSum;
};

export const getProjectStep = (project, premium) => {
  if (!project?.address) {
    return STEPS.PROPERTY_INFORMATION;
  }
  if (!project?.occupancy) {
    return STEPS.SITE_DESIGN_CRITERIA;
  }
  if (!project?.contractor) {
    return STEPS.CONTRACTOR_COMPANY;
  }
  if (!project?.scale && premium) {
    return STEPS.PRELIMINARY_PLAN;
  }
  if (getArray(project?.arrays).length === 0) {
    return STEPS.ARRAYS_PARAMETERS;
  }
  if (getArray(project?.systems).length === 0) {
    return STEPS.PV_SYSTEM;
  }

  const availablePanels = getProjectAvailablePanelsCount(project);
  const configuredPanels = getProjectConfiguredPanelsCount(project);

  if (availablePanels !== configuredPanels) {
    return STEPS.PV_SYSTEM;
  }
  if (!project?.interconnection) {
    return STEPS.INTERCONNECTION;
  }
  if (!project?.bos) {
    return STEPS.BALANCE_OF_SYSTEM;
  }
  if (!project?.wires_table) {
    return STEPS.WIRE_TABLE;
  }
  if (!project?.line_diagram) {
    return STEPS.LINE_DIAGRAM;
  }
  if (!project?.electrical_tables) {
    return STEPS.ELECTRICAL_TABLES;
  }
  if (!project?.placard) {
    return STEPS.PLACARDS;
  }
  if (!project?.site_plan && premium) {
    return STEPS.SITE_PLAN;
  }
  if (!project?.electrical_plan && premium) {
    return STEPS.ELECTRICAL_PLAN;
  }
  if (!project?.structures && premium) {
    return STEPS.STRUCTURES;
  }
  if (!project?.attachment_plan && premium) {
    return STEPS.ATTACHMENT_PLAN;
  }
  if (!project?.datasheets) {
    return STEPS.DATASHEETS;
  }
  return STEPS.PROJECT_PREVIEW;
};

export const getProjectVolume = (project, premium) => {
  const currentStep = getProjectStep(project, premium);

  const result = Object.entries(PROJECT_VOLUMES_STEPS).reduce(
    (res, [volume, stepsList]) => {
      if (res) {
        return res;
      }
      const currentVolume = stepsList.some(
        (step) => step.value === currentStep
      );
      const volumeAsStep =
        STEPS[volume] === currentStep && stepsList.length === 0;
      return currentVolume || volumeAsStep ? volume : res;
    },
    null
  );
  return result;
};

export const getStepVolume = (step) => {
  return Object.entries(PROJECT_VOLUMES_STEPS).reduce((res, [name, steps]) => {
    if (res) {
      return res;
    }
    const isCurrentVolume = steps.some(({ value }) => value === step);
    const isVolumeAsStep = steps.length === 0 && STEPS[name] === step;
    return isCurrentVolume || isVolumeAsStep ? name : res;
  }, null);
};

export const getProjectProgress = (project, premium) => {
  const propertyInformationDone = !!project?.address;
  const designCriteriaDone = !!project?.occupancy;
  const contractorCompanyDone = !!project?.contractor;
  const preliminaryPlan = !!project?.scale;
  const arraysParametersDone = getArray(project?.arrays).length > 0;
  const pvSystemDone = getArray(project?.systems).length > 0;
  const interconnectionDone = !!project?.interconnection;
  const bosDone = !!project?.bos;
  const wireTableDone = !!project?.wires_table;
  const lineDiagramDone = !!project?.line_diagram;
  const electricalTablesDone = !!project?.electrical_tables;
  const placardsDone = !!project?.placard;
  const sitePlanDone = !!project?.site_plan;
  const electricalPlanDone = !!project?.electrical_plan;
  const structuresDone = !!project?.structures;
  const attachmentPlanDone = false;
  const datasheetsDone = !!project?.datasheets;
  const previewDone = !!project?.preview;

  let excludeSteps = 0;

  const steps = [
    +propertyInformationDone,
    +designCriteriaDone,
    premium && +preliminaryPlan,
    +contractorCompanyDone,
    +arraysParametersDone,
    +pvSystemDone,
    +interconnectionDone,
    +bosDone,
    +wireTableDone,
    +lineDiagramDone,
    +electricalTablesDone,
    +placardsDone,
    premium && +sitePlanDone,
    premium && +electricalPlanDone,
    premium && +structuresDone,
    premium && +attachmentPlanDone,
    +datasheetsDone,
    +previewDone,
  ].filter((s) => {
    if (typeof s === 'boolean') {
      excludeSteps += 1;
      return false;
    }
    return true;
  });

  const points = steps.reduce((r, p) => r + p, 0);
  return Math.ceil((points / (totalSteps - excludeSteps)) * 100);
};
