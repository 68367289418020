import { useCallback, useEffect, useState } from 'react';
import { PaperCanvas, ProjectPreview } from 'views';
import { useFormContext } from 'react-hook-form';
import { Typography, Box } from '@mui/material';

import { useWatchField, withProps } from 'hooks';
import { DeincrementButtons, Requirements } from 'views';
import { Center, Delimiter, ProgressBox, TextField } from 'components';

import StringsDropdown from './StringsDropdown';
import WiresDropdown from './WiresDropdown';
import Controller from './lineDiagram.controller';

const ScaleValue = withProps(Typography, {
  variant: 'body2',
  textAlign: 'center',
  sx: {
    minWidth: '20px',
  },
});

const scale_step_min = 0.1;
const scale_step_max = 0.5;
const scale_min = 0.5;
const scale_max = 3;

const ScaleButtons = withProps(DeincrementButtons.Control, {
  min: scale_min,
  max: scale_max,
  defaultValue: 1,
});

const LineDiagramPreview = () => {
  const {
    api,
    diagramRef,
    loading,
    onStringsChange,
    onWiresChange,
    strings,
    wires,
    onReady,
    requirements,
  } = useFormContext();

  const [scaleStep, setScaleStep] = useState(scale_step_min);
  const tableScale = useWatchField('scale.table') || 1;
  const diagramScale = useWatchField('scale.diagram') || 1;

  // Canvas: update scale
  useEffect(() => {
    if (api) {
      api.scaleTemplates(diagramScale);
      api.scaleTable(tableScale);
    }
  }, [diagramScale, tableScale, api]);

  const handleStringDrop = useCallback(
    (string, { offset }) => {
      if (api) {
        const dropPoint = api.getDropPoint();
        api.disableDropzone();

        if (dropPoint) {
          onStringsChange(
            strings.map((str) =>
              str.id !== string.id
                ? str
                : {
                    ...string,
                    placed: true,
                  }
            )
          );
          const mark = api.getMark(string.id);
          const newPoint = mark.element.parent.globalToLocal(
            mark.point({
              x: dropPoint.x - offset.x,
              y: dropPoint.y - offset.y,
            })
          );
          mark.update({
            placed: true,
            x: newPoint.x,
            y: newPoint.y,
          });
          api.showLegendMark(string.id);
        }
      }
    },
    [onStringsChange, strings, api]
  );

  const handleWireDrop = useCallback(
    (wire, { offset }) => {
      if (api) {
        const dropPoint = api.getDropPoint();
        api.disableDropzone();

        if (dropPoint) {
          onWiresChange(
            wires.map((w) =>
              w.id !== wire.id
                ? w
                : {
                    ...wire,
                    placed: true,
                  }
            )
          );
          const mark = api.getMark(wire.id);
          const newPoint = mark.element.parent.globalToLocal(
            mark.point({
              x: dropPoint.x - offset.x,
              y: dropPoint.y - offset.y,
            })
          );
          mark.update({
            placed: true,
            x: newPoint.x,
            y: newPoint.y,
          });
        }
      }
    },
    [onWiresChange, wires, api]
  );

  const handleMarkDragStart = useCallback(() => {
    if (api) {
      api.enableDropzone();
    }
  }, [api]);

  return (
    <ProjectPreview
      tools={
        <Center mx={4} justifyContent="space-between" flexGrow={1} height={1}>
          <Center gap={2}>
            <StringsDropdown
              strings={strings}
              disabled={loading}
              onDrop={handleStringDrop}
              onDragStart={handleMarkDragStart}
            />

            <WiresDropdown
              wires={wires}
              disabled={loading}
              onDrop={handleWireDrop}
              onDragStart={handleMarkDragStart}
            />
          </Center>

          <Center gap={2} height={1}>
            <Delimiter vertical />

            <Center gap={1} height={1}>
              <Typography>Step:</Typography>

              <TextField
                sx={{ maxWidth: '50px' }}
                reserveHelperTextSpace={false}
                type="number"
                value={scaleStep}
                onValue={setScaleStep}
                disabled={loading}
                inputProps={{
                  step: 0.1,
                  min: scale_step_min,
                  max: scale_step_max,
                }}
              />
            </Center>

            <Delimiter vertical />

            <Center gap={1} height={1}>
              <Typography>Table Scale:</Typography>
              <ScaleValue>{tableScale}</ScaleValue>

              <ScaleButtons
                name="scale.table"
                step={scaleStep}
                disabled={loading}
              />
            </Center>

            <Delimiter vertical />

            <Center gap={1} height={1}>
              <Typography>Diagram Scale:</Typography>
              <ScaleValue>{diagramScale}</ScaleValue>

              <ScaleButtons
                name="scale.diagram"
                step={scaleStep}
                disabled={loading}
              />
            </Center>
          </Center>
        </Center>
      }
    >
      <ProgressBox progress={loading} height={1}>
        <Box height={1} p={5} overflow="auto">
          <PaperCanvas
            diagramRef={diagramRef}
            controller={Controller}
            onReady={onReady}
          />
        </Box>

        <Requirements requirements={requirements} />
      </ProgressBox>
    </ProjectPreview>
  );
};

export default LineDiagramPreview;
