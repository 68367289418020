import { useCallback } from 'react';

import { useRenameProjectMutation } from 'store';
import { getFunc } from 'utils';
import { EditProjectForm } from 'views';
import { Modal, ProgressBox, useMessage } from 'components';

const RenameProjectModal = (props) => {
  const { open, project, onClose, ...rest } = props;

  const m = useMessage();
  const [renameProject, renameProjectState] = useRenameProjectMutation();
  const { id: project_id } = project;

  const loading = renameProjectState.isLoading;

  const handleClose = useCallback(
    (...args) => {
      getFunc(onClose)(...args);
    },
    [onClose]
  );

  const handleSubmit = useCallback(
    async (body) => {
      try {
        await renameProject({ project_id, body }).unwrap();
        m.success('Project details has been updated!');
        handleClose();
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [m, handleClose, renameProject, project_id]
  );

  return (
    <Modal {...rest} open={open} onClose={handleClose} title="Rename Project">
      <ProgressBox progress={loading}>
        <EditProjectForm
          active={open}
          project={project}
          onSubmit={handleSubmit}
          onCancel={handleClose}
        />
      </ProgressBox>
    </Modal>
  );
};

export default RenameProjectModal;
