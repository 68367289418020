import { useMemo } from 'react';
import { useMatch, Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { routes } from 'routes';
import { withProps } from 'hooks';
import { Slip, List, Icon, listItemClasses } from 'components';

const minHeight = 560;

const Menu = withProps(List, {
  dense: false,
  ListItemProps: {
    sx: {
      [`&.${listItemClasses.root}`]: {
        color: (theme) => theme.palette.text.label,
      },
    },
  },
  ListItemTextProps: {
    primaryTypographyProps: {
      variant: 'subtitle2',
    },
  },
  ListItemButtonProps: {
    sx: {
      py: 1.5,
      px: 3,
    },
  },
});

const ConfigurationsLayout = () => {
  const isUsers = !!useMatch({
    end: false,
    path: routes.users.path(),
  });
  const isCompanies = !!useMatch({
    end: false,
    path: routes.companies.path(),
  });

  const options = useMemo(() => {
    return [
      {
        value: 'users',
        label: 'Users',
        icon: <Icon.Users />,
        selected: isUsers,
        to: routes.users.path(),
      },
      {
        value: 'companies',
        label: 'Saved Companies',
        icon: <Icon.Companies />,
        selected: isCompanies,
        to: routes.companies.path(),
      },
    ];
  }, [isCompanies, isUsers]);

  return (
    <Box display="flex" gap={3.5} alignItems="flex-start">
      <Box
        width={272}
        minWidth={272}
        minHeight={minHeight}
        bgcolor="colors.background_dark"
      >
        <Menu options={options} />
      </Box>

      <Box flexGrow={1}>
        <Slip p={0} display="flex" flexDirection="column" minHeight={minHeight}>
          <Outlet />
        </Slip>
      </Box>
    </Box>
  );
};

export default ConfigurationsLayout;
