import { getFunc } from 'utils';
import PaperDraggable from 'components/Paper/PaperDraggable';

class PaperItem extends PaperDraggable {
  constructor(options) {
    const { onSelect, id, ...opts } = options;
    super(opts);
    this.selected = false;
    this.selectable = true;
    this.pointer_cursor_id = null;
    this.prevent_click = false;
    this.callbacks = {
      onSelect,
    };
    this.id = id;
  }

  onMouseEnter(e) {
    if (!this.selected && this.selectable) {
      this.clearCursor();
      this.pointer_cursor_id = this.addCursor('pointer');
    }
    super.onMouseEnter(e);
  }

  onMouseLeave(e) {
    this.removeCursor(this.pointer_cursor_id);
    super.onMouseLeave(e);
  }

  onMouseDown(e) {
    super.onMouseDown(e);
  }

  onMouseUp(e) {
    if (this.drag) {
      this.prevent_click = true;
    }
    super.onMouseUp(e);
  }

  onClick(e) {
    if (this.prevent_click) {
      this.prevent_click = false;
      return;
    }
    if (this.selectable) {
      this.selected ? this.unselect() : this.select();
    }
    super.onClick(e);
  }

  onClickAway() {
    if (this.selectable) {
      this.unselect();
    }
    super.onClickAway();
  }

  select(opts = {}) {
    if (this.selected) {
      return;
    }
    this.selected = true;
    this.clearCursor();

    // TODO: parent class method could change in future (!)
    if (this.draggable && !opts.new) {
      super.onMouseEnter();
    }
    if (!opts.silent) {
      getFunc(this.callbacks.onSelect)(this.id);
    }
  }

  unselect(opts = {}) {
    if (!this.selected) {
      return;
    }
    this.selected = false;

    if (!opts.silent) {
      getFunc(this.callbacks.onSelect)(null);
    }
  }

  clearCursor() {
    if (this.pointer_cursor_id) {
      this.removeCursor(this.pointer_cursor_id);
      this.pointer_cursor_id = null;
    }
    super.clearCursor();
  }

  enableSelecting() {
    this.selectable = true;
  }

  disableSelecting() {
    this.selectable = false;
  }

  destroy(...args) {
    super.destroy(...args);
  }
}

export default PaperItem;
