import { lazy } from 'react';
import { Box } from '@mui/material';

import { dev } from 'constants';
import { getArray } from 'utils';
import { withProps } from 'hooks';
import { List, listItemClasses, listItemButtonClasses } from 'components';

const Menu = withProps(List, {
  ListItemProps: {
    sx: {
      [`&.${listItemClasses.root}`]: {
        color: (theme) => theme.palette.text.label,
        [`&.${listItemClasses.selected}`]: {
          backgroundColor: (theme) =>
            theme.palette.colors.select_secondary_background,
          '&:hover': {
            backgroundColor: (theme) =>
              theme.palette.colors.select_secondary_background,
            [`& .${listItemButtonClasses.root}`]: {
              backgroundColor: (theme) =>
                theme.palette.colors.select_secondary_background,
            },
          },
        },
        '&:hover': {
          color: (theme) => theme.palette.primary.main,
          backgroundColor: 'transparent',
          [`& .${listItemButtonClasses.root}`]: {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  ListItemTextProps: {
    primaryTypographyProps: {
      variant: 'subtitle4',
    },
  },
  ListItemButtonProps: {
    sx: {
      py: 1.5,
      px: 3,
    },
  },
});

const ConfigurationSubMenu = (props) => {
  const { options, onItemClick, ...rest } = props;

  return (
    <Box
      {...rest}
      overflow="auto"
      minWidth={208}
      sx={{
        border: (theme) => `1px solid ${theme.palette.border.light}`,
      }}
    >
      <Menu onItemClick={onItemClick} options={getArray(options)} />
    </Box>
  );
};

if (dev) {
  ConfigurationSubMenu.Demo = lazy(() => import('./ConfigurationSubMenu.demo'));
}

export default ConfigurationSubMenu;
