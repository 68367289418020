import { lazy } from 'react';
import { dev } from 'constants';

import PaperBase from './PaperBase';
import PaperElement from './PaperElement';
import PaperView from './PaperView';
import PaperDropzone from './PaperDropzone';
import PaperSvg from './PaperSvg';
import PaperText from './PaperText';
import PaperTextArea from './PaperTextArea';
import PaperTable from './PaperTable';
import PaperMouseEvents from './PaperMouseEvents';
import PaperDraggable from './PaperDraggable';
import PaperMark from './PaperMark';
import PaperContentBox from './PaperContentBox';
import PaperNoteBox from './PaperNoteBox';
import PaperNote from './PaperNote';
import PaperItem from './PaperItem';
import PaperReference from './PaperReference';
import PaperAnchor from './PaperAnchor';
import PaperArrow from './PaperArrow';
import PaperRuler from './PaperRuler';
import PaperCompass from './PaperCompass';
import PaperCaption from './PaperCaption';
import PaperLayers from './PaperLayers';
import PaperMultilineText from './PaperMultilineText';

export {
  PaperBase,
  PaperElement,
  PaperView,
  PaperDropzone,
  PaperSvg,
  PaperText,
  PaperTextArea,
  PaperTable,
  PaperMouseEvents,
  PaperDraggable,
  PaperMark,
  PaperContentBox,
  PaperNoteBox,
  PaperNote,
  PaperItem,
  PaperReference,
  PaperAnchor,
  PaperArrow,
  PaperRuler,
  PaperCompass,
  PaperCaption,
  PaperLayers,
  PaperMultilineText,
};

const Paper = {
  PaperBase,
  PaperElement,
  PaperView,
  PaperDropzone,
  PaperSvg,
  PaperText,
  PaperTextArea,
  PaperTable,
  PaperMouseEvents,
  PaperDraggable,
  PaperMark,
  PaperContentBox,
  PaperNoteBox,
  PaperNote,
  PaperItem,
  PaperReference,
  PaperAnchor,
  PaperArrow,
  PaperRuler,
  PaperCompass,
  PaperCaption,
  PaperLayers,
  PaperMultilineText,
};

if (dev) {
  Paper.Demo = lazy(() => import('./Paper.demo.jsx'));
}

export default Paper;
