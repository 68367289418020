export class Note {
  id = 'cd7f07d6-950a-49d1-89b3-b953e66a0773';
  group = 'LineDiagram';
  date_updated = '2023-02-13T14:47:30.113225';
  updated_by = 'Bohdan Chernovol';
  reference = 'Ref';
  requested_by = 'Dev';
  text = '<p>Note content</p>\n';
  visibility = true;
  type = 'custom';

  constructor(note) {
    this.id = note?.id || `${Date.now()}`;
    this.group = note?.group;
    this.date_updated = note?.date_updated;
    this.updated_by = note?.updated_by;
    this.reference = note?.reference;
    this.requested_by = note?.requested_by;
    this.text = note?.text || '';
    this.visibility = !!note?.visibility;
    this.type = note?.type || 'custom';
  }
}
