export const VOLTAGE_DROP = {
  NONE: 'none',
  BASIC: 'basic',
  ADVANCED: 'advanced',
};
export const VOLTAGE_DROP_NAMES = {
  [VOLTAGE_DROP.NONE]: 'None',
  [VOLTAGE_DROP.BASIC]: 'With VD',
  [VOLTAGE_DROP.ADVANCED]: 'Advanced VD',
};
export const VOLTAGE_DROP_LIST = [
  VOLTAGE_DROP.NONE,
  VOLTAGE_DROP.BASIC,
  VOLTAGE_DROP.ADVANCED,
].map((value) => ({
  value,
  label: VOLTAGE_DROP_NAMES[value],
}));

export const NEUTRALS = {
  CONDUCTOR: 'conductor',
  SEPARATE: 'separate',
};
export const NEUTRALS_NAMES = {
  [NEUTRALS.CONDUCTOR]: 'Combined with Conductor',
  [NEUTRALS.SEPARATE]: 'As a Separate Column',
};
export const NEUTRALS_LIST = [NEUTRALS.CONDUCTOR, NEUTRALS.SEPARATE].map(
  (value) => ({
    value,
    label: NEUTRALS_NAMES[value],
  })
);

export const TERMINAL_TEMP = {
  T75: 75,
  T60: 60,
};
export const TERMINAL_TEMP_NAMES = {
  [TERMINAL_TEMP.T75]: '75ºC',
  [TERMINAL_TEMP.T60]: '60ºC',
};
export const TERMINAL_TEMP_LIST = [TERMINAL_TEMP.T75, TERMINAL_TEMP.T60].map(
  (value) => ({
    value,
    label: TERMINAL_TEMP_NAMES[value],
  })
);

export const EGC_METHOD = {
  CONDUCTOR: 'conductor',
  NEC: 'nec',
  CUSTOM: 'custom',
};
export const EGC_METHOD_NAMES = {
  [EGC_METHOD.CONDUCTOR]: 'Same as conductor',
  [EGC_METHOD.NEC]: 'Per NEC 250.122',
  [EGC_METHOD.CUSTOM]: 'Custom',
};
export const EGC_METHOD_LIST = [
  EGC_METHOD.CONDUCTOR,
  EGC_METHOD.NEC,
  EGC_METHOD.CUSTOM,
].map((value) => ({
  value,
  label: EGC_METHOD_NAMES[value],
}));

export const NEUTRAL_METHOD = {
  CONDUCTOR: 'conductor',
  GEC: 'gec',
};
export const NEUTRAL_METHOD_NAMES = {
  [NEUTRAL_METHOD.CONDUCTOR]: 'Same as conductor',
  [NEUTRAL_METHOD.GEC]: 'Same as EGC',
};
export const NEUTRAL_METHOD_LIST = [
  NEUTRAL_METHOD.CONDUCTOR,
  NEUTRAL_METHOD.GEC,
].map((value) => ({
  value,
  label: NEUTRAL_METHOD_NAMES[value],
}));

export const OCPD = {
  OCPD_5: 5,
  OCPD_10: 10,
  OCPD_15: 15,
  OCPD_20: 20,
  OCPD_25: 25,
  OCPD_30: 30,
  OCPD_35: 35,
  OCPD_40: 40,
  OCPD_45: 45,
  OCPD_50: 50,
  OCPD_60: 60,
  OCPD_70: 70,
  OCPD_80: 80,
  OCPD_90: 90,
  OCPD_100: 100,
  OCPD_125: 125,
  OCPD_150: 150,
  OCPD_175: 175,
  OCPD_200: 200,
  OCPD_225: 225,
  OCPD_250: 250,
  OCPD_300: 300,
  OCPD_350: 350,
  OCPD_400: 400,
  OCPD_450: 450,
  OCPD_500: 500,
  OCPD_600: 600,
  OCPD_700: 700,
  OCPD_800: 800,
  OCPD_1000: 1000,
  OCPD_1200: 1200,
  OCPD_1600: 1600,
  OCPD_2000: 2000,
};
export const OCPD_NAMES = Object.entries(OCPD).reduce(
  (res, [name, value]) => ({
    ...res,
    [value]: value,
  }),
  {}
);

export const OCPD_LIST = [
  OCPD.OCPD_5,
  OCPD.OCPD_10,
  OCPD.OCPD_15,
  OCPD.OCPD_20,
  OCPD.OCPD_25,
  OCPD.OCPD_30,
  OCPD.OCPD_35,
  OCPD.OCPD_40,
  OCPD.OCPD_45,
  OCPD.OCPD_50,
  OCPD.OCPD_60,
  OCPD.OCPD_70,
  OCPD.OCPD_80,
  OCPD.OCPD_90,
  OCPD.OCPD_100,
  OCPD.OCPD_125,
  OCPD.OCPD_150,
  OCPD.OCPD_175,
  OCPD.OCPD_200,
  OCPD.OCPD_225,
  OCPD.OCPD_250,
  OCPD.OCPD_300,
  OCPD.OCPD_350,
  OCPD.OCPD_400,
  OCPD.OCPD_450,
  OCPD.OCPD_500,
  OCPD.OCPD_600,
  OCPD.OCPD_700,
  OCPD.OCPD_800,
  OCPD.OCPD_1000,
  OCPD.OCPD_1200,
  OCPD.OCPD_1600,
  OCPD.OCPD_2000,
].map((value) => ({
  value,
  label: OCPD_NAMES[value],
}));

export const CONDUIT_DIAMETERS = {
  D_0_5: '0.5',
  D_0_75: '0.75',
  D_1_0: '1.0',
  D_1_25: '1.25',
  D_1_5: '1.5',
  D_2_0: '2.0',
  D_2_5: '2.5',
  D_3_0: '3.0',
  D_3_5: '3.5',
  D_4_0: '4.0',
};
export const CONDUIT_DIAMETERS_NAMES = Object.entries(CONDUIT_DIAMETERS).reduce(
  (res, [name, value]) => ({
    ...res,
    [value]: value,
  }),
  {}
);

export const CONDUIT_DIAMETERS_LIST = [
  CONDUIT_DIAMETERS.D_0_5,
  CONDUIT_DIAMETERS.D_0_75,
  CONDUIT_DIAMETERS.D_1_0,
  CONDUIT_DIAMETERS.D_1_25,
  CONDUIT_DIAMETERS.D_1_5,
  CONDUIT_DIAMETERS.D_2_0,
  CONDUIT_DIAMETERS.D_2_5,
  CONDUIT_DIAMETERS.D_3_0,
  CONDUIT_DIAMETERS.D_3_5,
  CONDUIT_DIAMETERS.D_4_0,
].map((value) => ({
  value,
  label: CONDUIT_DIAMETERS_NAMES[value],
}));

export const WIRE_TABLE_COLUMNS = [
  {
    field: 'id',
    label: 'Id',
  },
  {
    field: 'typical',
    label: 'Typical',
  },
  {
    field: 'conductor',
    label: 'Conductor',
  },
  {
    field: 'conduit',
    label: 'Conduit',
  },
  {
    field: 'conductors_count',
    label: ['Current-carrying', 'Conductors in conduit'],
  },
  {
    field: 'ocpd',
    label: 'OCPD',
  },
  {
    field: 'neutral',
    label: 'Neutral',
  },
  {
    field: 'egc',
    label: 'EGC',
  },
  {
    field: 'temp_corr_factor',
    label: ['Temp. Corr.', 'Factor'],
  },
  {
    field: 'conduit_fill_factor',
    label: ['Conduit Fill', 'Factor'],
  },
  {
    field: 'cont_current',
    label: ['Cont', 'Current'],
  },
  {
    field: 'max_current',
    label: ['Max Current', '(125%)'],
  },
  {
    field: 'base_amp',
    label: ['Base', 'Amp'],
  },
  {
    field: 'derated_amp',
    label: ['Derated', 'Amp'],
  },
  {
    field: 'temp_rating',
    label: ['Term. Temp.', 'Rating'],
  },
  {
    field: 'amp_terminal',
    label: ['Amp', 'Terminal'],
  },
  {
    field: 'voltage',
    label: 'Voltage',
  },
  {
    field: 'voltage_drop',
    label: ['Voltage', 'Drop, V'],
  },
  {
    field: 'voltage_drop_perc',
    label: ['Voltage', 'Drop, %'],
  },
  {
    field: 'length',
    label: 'Length',
  },
];
