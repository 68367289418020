import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetProjectDetailsQuery,
  useGetProjectDatasheetsQuery,
  useUploadAdditionalDatasheetMutation,
  useDeleteAdditionalDatasheetMutation,
  useSaveDatasheetMetadataMutation,
} from 'store';

import { routes } from 'routes';
import { getArray } from 'utils';
import { useIsLoading, useToggle } from 'hooks';
import { ProjectDetailsLayout } from 'layouts';
import { Form, useMessage } from 'components';

import DatasheetsForm from './DatasheetsForm';
import DatasheetsPreview from './DatasheetsPreview';

const Datasheets = () => {
  const { project_id } = useParams();
  const m = useMessage();
  const navigate = useNavigate();

  const [initiated, toggleInitiated] = useToggle();
  const [selectedDatasheet, setSelectedDatasheet] = useState(null);

  const queryProject = useGetProjectDetailsQuery({ project_id });
  const queryDatasheets = useGetProjectDatasheetsQuery({ project_id });
  const [upload, queryUpload] = useUploadAdditionalDatasheetMutation();
  const [remove, queryRemove] = useDeleteAdditionalDatasheetMutation();
  const [update, queryUpdate] = useSaveDatasheetMetadataMutation();

  const loading = useIsLoading(
    queryProject,
    queryDatasheets,
    queryUpdate,
    queryUpload,
    queryRemove
  );
  const form = Form.useForm();
  const project = queryProject.data;
  const datasheets = useMemo(() => {
    if (!project || !queryDatasheets.data) {
      return [];
    }
    if (project && !Array.isArray(project?.datasheets)) {
      return getArray(queryDatasheets.data);
    }
    return project.datasheets.map((pd) => {
      const relatedDatasheet = queryDatasheets.data.find((ds) => {
        return ds.equipment_id === pd.id;
      });
      return {
        ...relatedDatasheet,
        visible: pd.visible,
      };
    }, []);
  }, [project, queryDatasheets]);

  const isFirstSaving = !project?.datasheets;

  const nextStep = useMemo(() => {
    return routes.project_preview.path({ project_id });
  }, [project_id]);

  useEffect(() => {
    if (!initiated && !loading && datasheets.length) {
      form.reset({
        metadata: datasheets,
      });
      toggleInitiated.on();
    }
  }, [
    datasheets,
    datasheets.length,
    form,
    initiated,
    loading,
    toggleInitiated,
  ]);

  const handleDatasheetSelect = useCallback((id) => {
    setSelectedDatasheet((prev) => {
      return prev === id ? null : id;
    });
  }, []);

  const handleUploadDatasheets = useCallback(
    async (items) => {
      const responses = items.map((item) => {
        return upload({
          skipInvalidation: true,
          project_id,
          datasheet_title: item.equipment_title,
          body: {
            datasheet: item.file,
          },
        }).unwrap();
      });
      return await Promise.all(responses);
    },
    [project_id, upload]
  );

  const handleDeleteDatasheets = useCallback(
    async (items) => {
      const responses = items.map((item) => {
        return remove({
          skipInvalidation: true,
          project_id,
          datasheet_id: item.equipment_id,
        }).unwrap();
      });
      return await Promise.all(responses);
    },
    [project_id, remove]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        console.log(formData);

        const metadata = formData.metadata.map((v, index) => {
          return { ...v, index };
        });

        const newDatasheets = metadata.filter((v) => {
          return v.status === 'new';
        });
        const deletedDatasheets = metadata.filter((v) => {
          return (
            v.status === 'deleted' &&
            datasheets.some((d) => {
              return d.equipment_id === v.equipment_id;
            })
          );
        });
        const reqNew = handleUploadDatasheets(newDatasheets);
        const reqDel = handleDeleteDatasheets(deletedDatasheets);
        const [resNew] = await Promise.all([reqNew, reqDel]);

        resNew.forEach((d, i) => {
          const index = newDatasheets[i].index;
          metadata[index].equipment_id = d.id;
        });
        const newMetadata = metadata
          .filter((d) => {
            return d.status !== 'deleted';
          })
          .map((d) => {
            return {
              id: d.equipment_id,
              visible: d.visible,
            };
          });

        await update({
          project_id,
          body: {
            metadata: newMetadata,
          },
        }).unwrap();

        navigate(nextStep);
      } catch (ex) {
        m.responseError(ex);
      }
    },
    [
      datasheets,
      handleDeleteDatasheets,
      handleUploadDatasheets,
      m,
      navigate,
      nextStep,
      project_id,
      update,
    ]
  );
  return (
    <ProjectDetailsLayout
      title="Datasheets"
      component={Form}
      onSubmit={handleSubmit}
      body={<DatasheetsForm />}
      form={{
        ...form,
        loading,
        nextStep,
        datasheets,
        isFirstSaving,
        selectedDatasheet,
        onDatasheetClick: handleDatasheetSelect,
      }}
    >
      <DatasheetsPreview />
    </ProjectDetailsLayout>
  );
};

export default Datasheets;
