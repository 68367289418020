import { lazy, useEffect } from 'react';
import { Box } from '@mui/material';

import { withProps } from 'hooks';
import { dev } from 'constants';
import { Button as OriginalButton, Form, TextField } from 'components';

const Button = withProps(OriginalButton, {
  fullWidth: false,
  minWidth: 165,
});

const EditProjectForm = (props) => {
  const { active, onSubmit, onCancel, project } = props;

  const form = Form.useForm();
  const { isValid } = form.formState;

  useEffect(() => {
    if (active) {
      form.reset({
        project_title: project?.title,
        description: project?.description,
      });
    }
  }, [active, form, project]);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" gap={1}>
        <TextField.Control
          required
          initialFocus={!!active}
          name="project_title"
          label="Project name"
        />

        <TextField.Control name="description" label="Project description" />
      </Box>

      <Box display="flex" gap={2} justifyContent="center" mt={3}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>

        <Button type="submit" disabled={!isValid}>
          Create
        </Button>
      </Box>
    </Form>
  );
};

if (dev) {
  EditProjectForm.Demo = lazy(() => import('./EditProjectForm.demo'));
}

export default EditProjectForm;
