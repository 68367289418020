import { lazy, useCallback, useEffect, useState } from 'react';
import { Box, Collapse as MuiCollapse } from '@mui/material';

import { useToggle, withProps } from 'hooks';
import { getArray, getFunc } from 'utils';
import { dev } from 'constants';
import { QuickConfirmTextField, ReferenceItem, ToolbarDropdown } from 'views';
import { Icon, Draggable } from 'components';

const PlusIcon = withProps(Icon.Plus, {
  fontSize: 'small',
});
const Collapse = withProps(MuiCollapse, {
  timeout: 0,
});

const CalloutsDropdown = (props) => {
  const { callouts, onDrop, onDragStart, onCreate, onRemove, ...rest } = props;
  const [open, toggleOpen] = useToggle();
  const [createNew, toggleCreateNew] = useToggle();
  const [newName, setNewName] = useState('');

  const handleToggleCreateNew = useCallback(() => {
    toggleCreateNew();
    setNewName('');
  }, [toggleCreateNew]);

  const handleConfirmCreateNew = useCallback(
    (v) => {
      getFunc(onCreate)(v);
    },
    [onCreate]
  );

  const handleDrop = useCallback(
    (callout, data) => {
      getFunc(onDrop)(callout, data);
      toggleOpen.off();
    },
    [onDrop, toggleOpen]
  );

  // toggle input off if dropdown has been closed
  useEffect(() => {
    if (!open) {
      toggleCreateNew.off();
      setNewName('');
    }
  }, [open, toggleCreateNew]);

  return (
    <ToolbarDropdown
      {...rest}
      open={open}
      onOpen={toggleOpen.on}
      onClose={toggleOpen.off}
      icon={<Icon.Callouts />}
      label="Callouts"
      direction="column"
      containerWidth="auto"
    >
      {getArray(callouts).map((callout) => (
        <ReferenceItem
          key={callout.value || callout.id}
          placeholder
          label={callout.label}
          position="relative"
          allowRemove={callout.custom && !callout.placed}
          onRemove={() => getFunc(onRemove)(callout)}
        >
          {!callout.placed && (
            <Draggable
              onDragStart={onDragStart}
              onDrop={(e, data) => handleDrop(callout, data)}
            >
              <ReferenceItem label={callout.label} />
            </Draggable>
          )}
        </ReferenceItem>
      ))}

      <Box>
        <Collapse in={!createNew}>
          <ReferenceItem
            px={1}
            Icon={PlusIcon}
            justifyContent="center"
            onClick={handleToggleCreateNew}
            sx={(theme) => ({
              cursor: 'pointer',
              '& svg': {
                transition: theme.transitions.create(),
              },
              '&:hover svg': {
                color: 'border.dark',
              },
            })}
          />
        </Collapse>

        <QuickConfirmTextField
          open={createNew}
          value={newName}
          timeout={0}
          onValue={setNewName}
          maxWidth={createNew ? 300 : 100}
          onClose={toggleCreateNew.off}
          onConfirm={handleConfirmCreateNew}
        />
      </Box>
    </ToolbarDropdown>
  );
};

if (dev) {
  CalloutsDropdown.Demo = lazy(() => import('./CalloutsDropdown.demo'));
}

export default CalloutsDropdown;
