import { Group, Shape, Path } from 'paper';

import { getArray, getString } from 'utils';
import PaperElement from 'components/Paper/PaperElement';
import PaperCompass from 'components/Paper/PaperCompass';
import Text from 'components/Paper/PaperText';

const caption_cell_size = 50;
const caption_meta_line_size = 220;

const scale_bar_width = 96;
const scale_bar_height = 7;

class ScaleBar extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'scale_bar_group' });
    super({ element, ...options });

    this.rect = new Shape.Rectangle({
      point: [0, 0],
      size: [scale_bar_width, scale_bar_height],
      strokeColor: 'black',
    });
    const bar_small = new Shape.Rectangle({
      point: [0, 0],
      size: [scale_bar_width / 4, scale_bar_height / 2],
    });
    const bar_large = new Shape.Rectangle({
      point: [0, 0],
      size: [scale_bar_width / 2, scale_bar_height / 2],
    });
    const bar1 = bar_small.clone();
    const bar2 = bar_small.clone();
    bar2.fillColor = 'black';

    const bar3 = bar2.clone();
    const bar4 = bar_small.clone();
    const bar5 = bar_large.clone();
    const bar6 = bar_large.clone();
    bar6.fillColor = 'black';

    element.addChildren([this.rect, bar1, bar2, bar3, bar4, bar5, bar6]);
    bar2.bounds.topCenter = bar1.bounds.bottomCenter;
    bar3.bounds.leftCenter = bar1.bounds.rightCenter;
    bar4.bounds.topCenter = bar3.bounds.bottomCenter;
    bar5.bounds.leftCenter = bar3.bounds.rightCenter;
    bar6.bounds.leftCenter = bar4.bounds.rightCenter;
  }
}

class ScaleBarValues extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'scale_bar_values_group' });
    const { data, ...opts } = options;

    super({ element, ...opts });

    this.data = { ...data };

    this.rect = new Shape.Rectangle({
      point: [0, 0],
      size: [scale_bar_width, scale_bar_height],
    });
    this.rect_outer = new Shape.Rectangle({
      point: [0, 0],
      size: [scale_bar_width + 50, scale_bar_height],
    });
    this.value1 = new Text({ content: '', fontSize: 8 });
    this.value2 = new Text({ content: '', fontSize: 8 });
    this.value3 = new Text({ content: '', fontSize: 8 });

    element.addChildren([
      this.rect_outer,
      this.rect,
      this.value1,
      this.value2,
      this.value3,
    ]);
    this.update();
  }

  update(data = this.data) {
    this.data = { ...this.data, ...data };

    const [v1 = '-', v2 = '-', v3 = '-'] = getArray(this.data.values);

    this.value1.content = v1;
    this.value2.content = v2;
    this.value3.content = v3;

    this.rect.bounds.center = this.rect_outer.bounds.center;
    this.value1.bounds.bottomCenter = this.rect.bounds.bottomLeft;
    this.value2.bounds.bottomCenter = this.rect.bounds.bottomCenter;
    this.value3.bounds.bottomCenter = this.rect.bounds.bottomRight;
  }
}

class CaptionPage extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'caption_page_group' });
    const { data, ...opts } = options;
    super({ element, ...opts });

    this.data = {
      page: '00',
      ...data,
    };
    this.page_rect = new Shape.Rectangle({
      point: [0, 0],
      size: [caption_cell_size, caption_cell_size],
      strokeColor: 'black',
    });
    this.page_text = new Text({
      content: this.data.page,
      fontSize: 24,
    });

    element.addChildren([this.page_rect, this.page_text]);
    this.update();
  }

  update(data = this.data) {
    this.data = { ...this.data, ...data };
    this.page_text.content = this.data.page;
    this.page_text.bounds.center = this.page_rect.bounds.center;
  }
}

class CaptionMeta extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'caption_meta_group' });
    const { data, ...opts } = options;
    super({ element, ...opts });

    this.data = {
      label: 'Page',
      scaleLabel: '-',
      scaleBarValues: ['-', '-', '-'],
      ...data,
    };
    this.rect = new Shape.Rectangle({
      point: [0, 0],
      size: [caption_meta_line_size, caption_cell_size],
    });
    this.delimiter = Path.Line({
      from: [0, 0],
      to: [caption_meta_line_size, 0],
      strokeColor: 'black',
    });
    this.label = new Text({
      content: this.data.label,
      fontSize: 22,
    });
    this.scale_bar = new ScaleBar({ ...opts });

    this.scale_bar_values = new ScaleBarValues({
      ...opts,
      data: {
        values: this.data.scaleBarValues,
      },
    });
    this.scale_label = new Text({
      fontSize: 9,
      content: this.data.scaleLabel,
    });
    element.addChildren([
      this.rect,
      this.delimiter,
      this.label,
      this.scale_label,
      this.scale_bar.element,
      this.scale_bar_values.element,
    ]);
    this.update();
  }

  update(data = this.data) {
    this.data = { ...this.data, ...data };

    this.scale_bar_values.update({
      values: this.data.scaleBarValues,
    });
    this.scale_label.content = this.data.scaleLabel;
    this.scale_label.bounds.topLeft = this.rect.bounds.leftCenter;
    this.scale_label.bounds.topLeft.x += 5;
    this.scale_label.bounds.topLeft.y += 5;

    this.label.content = getString(this.data.label).toUpperCase();
    this.label.bounds.topCenter = this.rect.bounds.topCenter;
    this.delimiter.bounds.leftCenter = this.rect.bounds.leftCenter;

    this.scale_bar.element.bounds.bottomRight = this.rect.bounds.bottomRight;
    this.scale_bar.element.bounds.topRight.x -= 5;
    this.scale_bar.element.bounds.topRight.y -= 5;

    this.scale_bar_values.element.bounds.bottomCenter =
      this.scale_bar.element.bounds.topCenter;
  }
}

class PaperCaption extends PaperElement {
  constructor(options) {
    const element = new Group({ name: 'caption_group' });
    const { data, ...opts } = options;
    super({ element, ...opts });

    this.data = {
      ...data,
    };
    this.page = new CaptionPage({
      ...opts,
      data: {
        page: this.data.page,
      },
    });
    this.meta = new CaptionMeta({
      ...opts,
      data: {
        label: this.data.label,
      },
    });
    this.compass = new PaperCompass({ ...opts });

    element.addChildren([
      this.page.element,
      this.meta.element,
      this.compass.element,
    ]);
    this.meta.element.bounds.leftCenter = this.page.element.bounds.rightCenter;
    this.compass.element.bounds.leftCenter =
      this.meta.element.bounds.rightCenter;

    this.compass.element.bounds.leftCenter.x += 20;
  }
}

export default PaperCaption;
