import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Collapse, Box } from '@mui/material';

import { useGetAcSubpanelListQuery } from 'store';
import { getArray } from 'utils';
import { useWatchField } from 'hooks';
import { Autocomplete } from 'components';

const AcSubpanelField = (props) => {
  const { ...rest } = props;
  const { setValue } = useFormContext();

  const subpanel_enabled = useWatchField('subpanel_enabled');
  const subpanel_panel_type = useWatchField('subpanel_panel_type');
  const isVendor = subpanel_panel_type === 'vendor';

  const { data: subpanelList, isFetching } = useGetAcSubpanelListQuery();

  const options = useMemo(() => {
    return getArray(subpanelList).map((subpanel) => ({
      value: subpanel.id,
      label: `${subpanel.vendor.title} - ${subpanel.title}`,
    }));
  }, [subpanelList]);

  const handlePanelChange = useCallback(
    (e, v) => {
      const panel = getArray(subpanelList).find((p) => p.id === v);
      setValue('subpanel_data.bus_rating', panel?.buss_rating || '');
      setValue('subpanel_data.breaker_rating', '');
    },
    [setValue, subpanelList]
  );

  return (
    <Collapse in={isVendor}>
      <Box mb={1}>
        <Autocomplete.Control
          {...rest}
          required
          valueAsId
          options={options}
          loading={isFetching}
          onChange={handlePanelChange}
          rules={{ disabled: !isVendor || !subpanel_enabled }}
        />
      </Box>
    </Collapse>
  );
};

export default AcSubpanelField;
