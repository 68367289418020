import { forwardRef, lazy, useMemo } from 'react';
import { styled } from '@mui/material';

import { dev } from 'constants';
import { withProps } from 'hooks';
import Slip from 'components/Slip';
import NativeButton, { buttonClasses as classes } from 'components/Button';

const sizing = {
  small: '32px',
  medium: '40px',
  large: '48px',
};

const Button = styled(NativeButton, {
  label: 'TinyButton',
  shouldForwardProp: (prop) => !['shape', 'transparent'].includes(prop),
})(({ theme, size, shape, variant, transparent }) => ({
  [`&.${classes.root}`]: {
    maxWidth: sizing[size] || size,
    maxHeight: sizing[size] || size,
    minWidth: sizing[size] || size,
    minHeight: sizing[size] || size,
    width: sizing[size] || size,
    height: sizing[size] || size,
    padding: theme.spacing(0.5),
    borderRadius: shape === 'circle' ? '50%' : undefined,
    backgroundColor: (() => {
      if (transparent) {
        return 'transparent !important';
      }
      if (variant !== 'contained') {
        return theme.palette.common.white;
      }
      return undefined;
    })(),
    borderColor: transparent ? 'transparent' : undefined,
    '&:hover, &:focus': {
      borderColor: transparent ? 'transparent !important' : undefined,
      backgroundColor: (() => {
        if (transparent) {
          return 'transparent !important';
        }
        if (variant !== 'contained') {
          return `${theme.palette.colors.hover_background} !important`;
        }
        return undefined;
      })(),
    },
  },
}));

const TinyButton = forwardRef((props, ref) => {
  const {
    color = 'primary',
    shape = 'sqare',
    size = 'medium',
    variant: customVariant = 'outlined',
    component: customComponent = 'button',
    ...rest
  } = props;

  const component = useMemo(() => {
    return withProps(Slip, {
      elevation: 3,
      component: customComponent,
    });
  }, [customComponent]);

  const variant = useMemo(() => {
    return customVariant === 'transparent' ? 'outlined' : customVariant;
  }, [customVariant]);

  const transparent = customVariant === 'transparent';

  return (
    <Button
      ref={ref}
      {...rest}
      color={color}
      size={size}
      shape={shape}
      variant={variant}
      component={component}
      transparent={transparent}
    />
  );
});

if (dev) {
  TinyButton.Demo = lazy(() => import('./TinyButton.demo'));
}

export default TinyButton;
