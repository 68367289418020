export const LOCATIONS = {
  BETWEEN_INVERTER_AND_DISCONNECT: 'Between Inverter and Disconnect',
  BETWEEN_DISCONNECT_AND_POI: 'Between Disconnect and Point of Interconnection',
};
export const LOCATIONS_NAMES = {
  [LOCATIONS.BETWEEN_INVERTER_AND_DISCONNECT]:
    LOCATIONS.BETWEEN_INVERTER_AND_DISCONNECT,
  [LOCATIONS.BETWEEN_DISCONNECT_AND_POI]: LOCATIONS.BETWEEN_DISCONNECT_AND_POI,
};
export const LOCATIONS_LIST = [
  LOCATIONS.BETWEEN_INVERTER_AND_DISCONNECT,
  LOCATIONS.BETWEEN_DISCONNECT_AND_POI,
].map((value) => ({
  value,
  label: LOCATIONS_NAMES[value],
}));

export const METER_RATING = {
  METER_80: 80,
  METER_100: 100,
  METER_200: 200,
  METER_400: 400,
};
export const METER_RATING_NAMES = {
  [METER_RATING.METER_80]: METER_RATING.METER_80,
  [METER_RATING.METER_100]: METER_RATING.METER_100,
  [METER_RATING.METER_200]: METER_RATING.METER_200,
  [METER_RATING.METER_400]: METER_RATING.METER_400,
};
export const METER_RATING_LIST = [
  METER_RATING.METER_80,
  METER_RATING.METER_100,
  METER_RATING.METER_200,
  METER_RATING.METER_400,
].map((value) => ({
  value,
  label: METER_RATING_NAMES[value],
}));

export const METER_TYPES = {
  FORM_25: 'Form 25',
  CT_METER: 'CT meter',
};
export const METER_TYPES_NAMES = {
  [METER_TYPES.FORM_25]: METER_TYPES.FORM_25,
  [METER_TYPES.CT_METER]: METER_TYPES.CT_METER,
};
export const METER_TYPES_LIST = [METER_TYPES.FORM_25, METER_TYPES.CT_METER].map(
  (value) => ({
    value,
    label: METER_TYPES_NAMES[value],
  })
);

export const METER_ENCLOSURE = {
  MILBANK: 'Milbank',
};
export const METER_ENCLOSURE_NAMES = {
  [METER_ENCLOSURE.MILBANK]: METER_ENCLOSURE.MILBANK,
};
export const METER_ENCLOSURE_LIST = [METER_ENCLOSURE.MILBANK].map((value) => ({
  value,
  label: METER_ENCLOSURE_NAMES[value],
}));
