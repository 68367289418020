import { alpha, rgbToHex, lighten, darken } from '@mui/material';

const factor = 0.35;

const white = '#ffffff';
const border = '#b2beca';

export const violet_main = '#3f3d71';
export const violet_light = '#6d7195';
export const violet_dark = '#35326a';
export const violet_background = '#29275e';

export const background_main = rgbToHex(lighten(border, 0.9));
export const background_dark = rgbToHex(lighten(border, 0.8));
export const shadow = rgbToHex('rgb(0, 25, 94)');

export const primary_main = '#00254d';
export const primary_light = rgbToHex(lighten(primary_main, factor));
export const primary_dark = rgbToHex(darken(primary_main, factor));
export const primary_contrast_text = white;

export const secondary_main = '#ffb40a';
export const secondary_light = rgbToHex(lighten(secondary_main, factor));
export const secondary_dark = '#f5ab02';
export const secondary_contrast_text = white;

export const error_main = '#ff0015';
export const error_light = rgbToHex(lighten(error_main, factor));
export const error_dark = rgbToHex(darken(error_main, factor));

export const disabled = border;
export const action_active = rgbToHex(darken(border, 0.15));

export const label = rgbToHex(lighten(primary_main, 0.5));
export const placeholder = rgbToHex(lighten(primary_main, 0.7));

export const border_light = rgbToHex(lighten(border, 0.6));
export const border_main = border;
export const border_dark = '#78779b';

export const progress_background = rgbToHex(alpha(white, 0.8));
export const project_background = '#f8f9fa';
export const backdrop_background = rgbToHex('rgba(78, 76, 123, 0.5)');
export const autofill_background = rgbToHex(alpha(secondary_light, 0.06));
export const hover_background = rgbToHex(lighten(secondary_main, 0.9));
export const select_secondary_background = rgbToHex(
  lighten(secondary_main, 0.8)
);
export const disabled_background = rgbToHex(lighten(border, 0.5));
export const disabled_input = '#f5f5f5';

export const scroll_main = '#97a2b4';
export const scroll_background = '#ced4de';

export const paper_primary = 'black';
export const paper_secondary = 'blue';
