import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Collapse,
  IconButton as MuiIconButton,
  Typography,
} from '@mui/material';
import RenameIcon from '@mui/icons-material/EditNote';

import { useGetElevationImageMutation } from 'store';
import { folder } from 'assets';
import {
  HOUSE_PLACARDS_MARGIN_Y,
  HOUSE_PLACARDS_MARGIN_X,
  HOUSE_PLACARDS_WIDTH,
  HOUSE_PLACARDS_HEIGHT,
} from 'constants';

import {
  useAuth,
  useIsLoading,
  useToggle,
  useWatchField,
  withProps,
} from 'hooks';

import { getArray, getString } from 'utils';
import {
  DeincrementButtons,
  PaperCanvas,
  ProjectPreview,
  QuickConfirmTextField,
  CalloutsDropdown,
  EquipmentsDropdown,
} from 'views';

import {
  Center,
  Delimiter,
  Icon,
  ProgressBox,
  TinyButton,
  Dropzone,
  Image,
  Ref,
  useMessage,
} from 'components';

import Controller from './placards.controller';

const IconButton = withProps(MuiIconButton, {
  size: 'small',
});

const PlacardsPreview = () => {
  const {
    api,
    callouts,
    changeHandler,
    diagramRef,
    elevationImage,
    equipments,
    loading,
    onCalloutsChange,
    onCanvasChange,
    onEquipmentsChange,
    onReady,
    onSelectItem,
    onViewChange,
    project_id,
    selectedItemId,
    setElevationImage,
    setValue,
    view,
  } = useFormContext();

  const previewRef = useRef(null);
  const [panTool, togglePanTool] = useToggle();
  const [assignTool, toggleAssignTool] = useToggle(true);
  const [angleTool, toggleAngleTool] = useToggle(true);
  const [rename, toggleRename] = useToggle();
  const [renameValue, setRenameValue] = useState('');

  const [uploadElevation, uploadState] = useGetElevationImageMutation();
  const m = useMessage();
  const { premium } = useAuth();

  const elevationLoading = useIsLoading(uploadState);

  const housePlacard = useWatchField('house_placard');
  const scale = useWatchField('plan_size');
  const equipmentElevation = useWatchField('equipment_elevation');

  const panToolEnabled = panTool && view === 'house_placards';

  const options = useMemo(() => {
    return { premium };
  }, [premium]);

  const ableRename = useMemo(() => {
    if (!selectedItemId) {
      return false;
    }
    const instance = api?.getItem(selectedItemId);

    if (instance?.data?.type === 'ruler') {
      return true;
    }
    if (instance?.data?.type === 'callout' && instance?.data?.custom) {
      return true;
    }
    return false;
  }, [api, selectedItemId]);

  const handleElevationFile = useCallback(
    async (files) => {
      const [file] = getArray(files);

      if (file) {
        try {
          const response = await uploadElevation({
            project_id,
            body: { file },
          }).unwrap();

          setElevationImage(response.image);
          onCanvasChange();
        } catch (ex) {
          m.responseError(ex);
        }
      }
    },
    [m, uploadElevation, project_id, onCanvasChange, setElevationImage]
  );

  const handleScaleChange = useCallback(
    (v) => {
      changeHandler(setValue)('plan_size', v);
    },
    [setValue, changeHandler]
  );

  const handleReferenceDrop = useCallback(
    (ref, { offset }) => {
      if (api) {
        const dropPoint = api.getDropPoint();
        api.disableDropzone();

        const handlers = {
          equipment: onEquipmentsChange,
          callout: onCalloutsChange,
        };
        const prevs = {
          equipment: equipments,
          callout: callouts,
        };
        if (dropPoint) {
          const handler = handlers[ref.type];
          const prev = prevs[ref.type];
          togglePanTool.off();

          handler(
            prev.map((p) => {
              if (p.value === ref.value) {
                return {
                  ...ref,
                  placed: true,
                  x: dropPoint.x - offset.x,
                  y: dropPoint.y - offset.y,
                };
              }
              return p;
            })
          );
        }
      }
    },
    [
      api,
      equipments,
      callouts,
      onEquipmentsChange,
      onCalloutsChange,
      togglePanTool,
    ]
  );

  const handleReferenceDrag = useCallback(() => {
    if (api) {
      api.enableDropzone();
    }
  }, [api]);

  const handleCreateCallout = useCallback(
    (label) => {
      onCalloutsChange((prev) => [
        ...prev,
        {
          label,
          type: 'callout',
          custom: true,
          placed: false,
          value: `callout-${Date.now()}`,
          id: `callout-${Date.now()}`,
        },
      ]);
    },
    [onCalloutsChange]
  );

  const handleClickAway = useCallback(() => {
    if (selectedItemId) {
      api.unselectItem();
    }
  }, [api, selectedItemId]);

  const handleRemoveSelectedItem = useCallback(() => {
    const id = selectedItemId;
    const data = api.getItem(id)?.data;

    const handlers = {
      equipment: onEquipmentsChange,
      callout: onCalloutsChange,
    };
    if (['equipment', 'callout'].includes(data.type)) {
      const handler = handlers[data.type];

      handler((prev) =>
        prev.map((item) => {
          return item.id !== data.id
            ? item
            : {
                ...item,
                placed: false,
                x: 0,
                y: 0,
              };
        })
      );
    }
    api.removeItem(id);
    onSelectItem(null);
  }, [api, onSelectItem, selectedItemId, onCalloutsChange, onEquipmentsChange]);

  const handleRemoveCallout = useCallback(
    (c) => {
      onCalloutsChange((prev) => prev.filter((cal) => cal.id !== c.id));
    },
    [onCalloutsChange]
  );

  const handleRenameItem = useCallback(
    (v) => {
      const label = getString(v).toUpperCase();
      const instance = api.getItem(selectedItemId);
      instance.rename(label);

      if (instance.data.type === 'callout') {
        onCalloutsChange((prev) => {
          return prev.map((c) => {
            return c.id !== instance.data.id ? c : { ...c, label };
          });
        });
      }
    },
    [api, selectedItemId, onCalloutsChange]
  );

  const handleEditHousePlacardsClick = useCallback(
    (e) => {
      e.preventDefault();
      onViewChange('house_placards');
      api.setHousePlacardsMode('edit');
    },
    [onViewChange, api]
  );

  const handleRemoveElevationImage = useCallback(() => {
    onCanvasChange();
    setElevationImage(null);
  }, [onCanvasChange, setElevationImage]);

  // set elevation image
  useEffect(() => {
    if (api) {
      if (elevationImage) {
        api.setElevationImage(elevationImage);

        if (equipmentElevation) {
          api.showElevation();
        }
        return;
      }
      api.removeElevationImage();
      api.hideElevation();
    }
  }, [api, elevationImage, equipmentElevation]);

  // toggle house placard
  useEffect(() => {
    if (api) {
      housePlacard && premium ? api.showHousePlacard() : api.hideHousePlacard();
    }
  }, [api, housePlacard, premium]);

  // reset rename value on toggle
  useEffect(() => {
    if (rename) {
      setRenameValue('');
    }
  }, [rename]);

  // toggle pan tool
  useEffect(() => {
    if (api) {
      api.togglePanTool(panToolEnabled);
    }
  }, [panToolEnabled, api]);

  // unselect items on escape key
  useEffect(() => {
    const listenGlobalEsc = (e) => {
      if (e.keyCode === 27 && selectedItemId) {
        api.unselectItem();
      }
    };
    const listenGlobalDel = (e) => {
      if (e.keyCode === 46 && selectedItemId) {
        handleRemoveSelectedItem();
      }
    };
    document.addEventListener('keydown', listenGlobalEsc);
    document.addEventListener('keydown', listenGlobalDel);

    return () => {
      document.removeEventListener('keydown', listenGlobalEsc);
      document.removeEventListener('keydown', listenGlobalDel);
    };
  }, [selectedItemId, api, handleRemoveSelectedItem]);

  // toggle sticking tool
  useEffect(() => {
    if (api) {
      assignTool ? api.enableSticking() : api.disableSticking();
    }
  }, [api, assignTool]);

  // toggle angle tool
  useEffect(() => {
    if (api) {
      angleTool ? api.enableAngleAlignment() : api.disableAngleAlignment();
    }
  }, [api, angleTool]);

  return (
    <ProjectPreview
      ref={previewRef}
      onClickAway={handleClickAway}
      tools={
        <>
          {view === 'house_placards' && (
            <Center
              mx={4}
              height={1}
              flexGrow={1}
              justifyContent="space-between"
            >
              <Center gap={1}>
                <EquipmentsDropdown
                  equipments={equipments}
                  disabled={loading}
                  onDrop={handleReferenceDrop}
                  onDragStart={handleReferenceDrag}
                />
                <CalloutsDropdown
                  callouts={callouts}
                  disabled={loading}
                  onDrop={handleReferenceDrop}
                  onDragStart={handleReferenceDrag}
                  onCreate={handleCreateCallout}
                  onRemove={handleRemoveCallout}
                />
              </Center>

              <Center height={1} mr={-2}>
                <Delimiter vertical mx={1} />

                <IconButton
                  onClick={toggleAngleTool}
                  disabled={loading || !assignTool}
                  color={angleTool ? 'secondary' : 'primary'}
                >
                  <Icon.RightAngle />
                </IconButton>

                <IconButton
                  disabled={loading}
                  onClick={toggleAssignTool}
                  color={assignTool ? 'secondary' : 'primary'}
                >
                  <Icon.Assign />
                </IconButton>

                <Delimiter vertical mx={1} />

                <QuickConfirmTextField
                  open={rename}
                  value={renameValue}
                  onValue={setRenameValue}
                  orientation="horizontal"
                  onClose={toggleRename.off}
                  onConfirm={handleRenameItem}
                />

                <Collapse orientation="horizontal" in={!rename}>
                  <IconButton
                    color="primary"
                    onClick={toggleRename}
                    disabled={!ableRename || loading}
                  >
                    <RenameIcon />
                  </IconButton>
                </Collapse>

                <IconButton
                  color="primary"
                  disabled={!selectedItemId}
                  onClick={handleRemoveSelectedItem}
                >
                  <Icon.Trash />
                </IconButton>

                <Delimiter vertical mx={1} />

                <IconButton
                  onClick={changeHandler(togglePanTool)}
                  color={panToolEnabled ? 'secondary' : 'primary'}
                  disabled={loading}
                >
                  <Icon.Hand />
                </IconButton>

                <DeincrementButtons
                  min={0.5}
                  max={2}
                  step={0.1}
                  value={scale}
                  onValue={handleScaleChange}
                  disabled={loading}
                />
              </Center>
            </Center>
          )}
        </>
      }
    >
      <ProgressBox progress={loading} height={1}>
        <Box height={1} p={5} overflow="auto">
          <PaperCanvas
            diagramRef={diagramRef}
            controller={Controller}
            onReady={onReady}
            position="relative"
            options={options}
          >
            {view === 'main' && housePlacard && premium && (
              <Box
                top={HOUSE_PLACARDS_MARGIN_Y + 15}
                right={HOUSE_PLACARDS_MARGIN_X + 15}
                position="absolute"
              >
                <TinyButton
                  size="small"
                  variant="flat"
                  onClick={handleEditHousePlacardsClick}
                >
                  <Icon.Pencil />
                </TinyButton>
              </Box>
            )}

            {view === 'main' && equipmentElevation && !elevationImage && (
              <Box
                width={HOUSE_PLACARDS_WIDTH}
                height={HOUSE_PLACARDS_HEIGHT}
                right={HOUSE_PLACARDS_MARGIN_X}
                bottom={HOUSE_PLACARDS_MARGIN_Y}
                position="absolute"
              >
                <ProgressBox height={1} progress={elevationLoading}>
                  <Dropzone
                    variant="box"
                    height={1}
                    minHeight={1}
                    onChange={handleElevationFile}
                    uploadTrigger={
                      <Center flexDirection="column" gap={3} px={6}>
                        <Image width={75} height={75} src={folder} />

                        <Typography variant="subtitle2" textAlign="center">
                          Upload Equipment Elevation Drawing
                        </Typography>

                        <Box px={8}>
                          <Typography
                            variant="body2"
                            fontWeight={400}
                            textAlign="center"
                          >
                            <span>Drag and drop or</span>
                            <span> </span>
                            <Ref color="secondary.main" fontWeight={700}>
                              Click here
                            </Ref>
                            <span> </span>
                            <span>to upload file</span>
                          </Typography>
                        </Box>
                      </Center>
                    }
                  />
                </ProgressBox>
              </Box>
            )}

            {view === 'main' && equipmentElevation && elevationImage && (
              <Box
                bottom={HOUSE_PLACARDS_MARGIN_Y + HOUSE_PLACARDS_HEIGHT - 15}
                right={HOUSE_PLACARDS_MARGIN_X + 15}
                position="absolute"
                sx={{ transform: 'translateY(100%)' }}
              >
                <TinyButton
                  size="small"
                  variant="flat"
                  onClick={handleRemoveElevationImage}
                >
                  <Icon.Trash />
                </TinyButton>
              </Box>
            )}
          </PaperCanvas>
        </Box>
      </ProgressBox>
    </ProjectPreview>
  );
};

export default PlacardsPreview;
