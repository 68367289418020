import { Group, Shape } from 'paper';

import { getFunc } from 'utils';
import PaperDraggable from 'components/Paper/PaperDraggable';

class PaperContentBox extends PaperDraggable {
  constructor(options) {
    const { data, ...opts } = options;
    const element = new Group({ name: 'content_box' });
    super({
      element,
      ...opts,
      onDrop: (...args) => {
        getFunc(opts.onDrop)(...args);
        this.data.x = element.bounds.x;
        this.data.y = element.bounds.y;
      },
    });
    element.visible = false;
    this.edit = true;

    this.data = {
      ...data,
      x: data.x || 0,
      y: data.y || 0,
      width: Number.isFinite(data.width) ? data.width : 200,
      height: Number.isFinite(data.height) ? data.height : 75,
    };
    const note_rect = new Shape.Rectangle({
      point: [0, 0],
      size: [this.data.width, this.data.height],
      radius: 4,
      dashArray: [4, 4],
      strokeWidth: 2,
    });
    this.children.note_rect = note_rect;
    element.addChild(note_rect);
    this.style();
  }

  onMouseEnter(...args) {
    super.onMouseEnter(...args);
    this.style('hover');
  }

  onMouseLeave(...args) {
    super.onMouseLeave(...args);

    if (!this.drag) {
      this.style();
    }
  }

  update(data = this.data) {
    this.data = { ...this.data, ...data };
    const note_rect = this.children.note_rect.clone();
    note_rect.size.height = Number.isFinite(this.data.height)
      ? this.data.height
      : 75;
    note_rect.size.width = Number.isFinite(this.data.width)
      ? this.data.width
      : 200;
    note_rect.bounds.topLeft = this.children.note_rect.bounds.topLeft;
    this.children.note_rect.replaceWith(note_rect);
    this.children.note_rect = note_rect;
    this.element.visible = !!this.data.visible;
    this.element.bounds.x = this.data.x;
    this.element.bounds.y = this.data.y;
  }

  style(type = 'default') {
    const { note_rect } = this.children;
    const { palette } = this.theme;

    if (!this.edit) {
      return;
    }
    switch (type) {
      case 'hover': {
        note_rect.strokeColor = palette.border.dark;
        break;
      }
      case 'default':
      default: {
        note_rect.fillColor = palette.common.white;
        note_rect.strokeColor = palette.border.main;
        break;
      }
    }
  }

  disableEdit() {
    this.edit = false;
    this.disableDragging();
    this.children.note_rect.strokeColor = 'transparent';
  }

  enableEdit() {
    this.edit = true;
    this.enableDragging();
    this.style();
  }
}

export default PaperContentBox;
