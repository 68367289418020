import { useCallback, useEffect, useState } from 'react';
import { ClickAwayListener, useTheme } from '@mui/material';

import { useToggle } from 'hooks';
import { getArray, getFunc } from 'utils';
import { StringMark, ToolbarDropdown } from 'views';
import { Icon, Badge } from 'components';

const StringsSelectDropdown = (props) => {
  const {
    strings,
    onAllowClose,
    selectedStringId,
    onSelectedStringIdChange,
    ...rest
  } = props;

  const theme = useTheme();
  const [open, toggleOpen] = useToggle();
  const [hovered, setHovered] = useState(null);

  const handleClose = useCallback(() => {
    toggleOpen.off();
  }, [toggleOpen]);

  const handleOpen = useCallback(() => {
    toggleOpen();
  }, [toggleOpen]);

  const handleClickAway = useCallback(
    (e) => {
      if (open) {
        const allowClose = getFunc(onAllowClose)(e);

        if (allowClose === false) {
          return;
        }
        toggleOpen.off();
      }
    },
    [toggleOpen, onAllowClose, open]
  );

  const handleStringClick = useCallback(
    (str) => () => {
      onSelectedStringIdChange((prev) => {
        return prev === str.id ? null : str.id;
      });
    },
    [onSelectedStringIdChange]
  );

  const handleStringMouseEnter = useCallback(
    (str) => () => {
      setHovered(str.id);
    },
    []
  );

  useEffect(() => {
    if (!open) {
      onSelectedStringIdChange(null);
    }
  }, [open, onSelectedStringIdChange]);

  useEffect(() => {
    if (selectedStringId) {
      const targetString = strings.find((str) => str.id === selectedStringId);
      const left = targetString.count - targetString.places.length;

      if (left < 1) {
        const freeString = strings.find((str) => {
          return (
            str.id !== selectedStringId && str.count - str.places.length > 0
          );
        });
        onSelectedStringIdChange(freeString?.id || null);

        if (!freeString) {
          toggleOpen.off();
        }
      }
    }
  }, [selectedStringId, strings, onSelectedStringIdChange, toggleOpen]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <ToolbarDropdown
          {...rest}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          icon={<Icon.Strings />}
          label="Strings"
          popoverProps={{
            hideBackdrop: true,
            passEvents: true,
            disablePortal: true,
          }}
        >
          {getArray(strings).map((str) => {
            const { secondary } = theme.palette;
            const left = str.count - str.places.length;
            const hoverColor =
              hovered === str.id && left > 0 ? secondary.light : undefined;

            const selectColor =
              selectedStringId === str.id ? secondary.main : undefined;

            return (
              <Badge
                key={str.id}
                badgeContent={left}
                color="secondary"
                overlap="circular"
                borderWidth={1}
              >
                <StringMark
                  text={str.text}
                  sx={{
                    cursor: left > 0 ? 'pointer' : 'default',
                    opacity: left > 0 ? 1 : 0.3,
                  }}
                  onClick={handleStringClick(str)}
                  onMouseEnter={handleStringMouseEnter(str)}
                  onMouseLeave={() => setHovered(null)}
                  fill={selectColor || hoverColor}
                />
              </Badge>
            );
          })}
        </ToolbarDropdown>
      </div>
    </ClickAwayListener>
  );
};

export default StringsSelectDropdown;
