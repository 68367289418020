import { useMemo } from 'react';

import { getArray } from 'utils';
import { useGetProjectWiresTableQuery, useGetProjectDetailsQuery } from 'store';
import useGetProjectStrings from 'hooks/useGetProjectStrings.hook';

const def = {};

const useGetProjectStringsAndWires = (props = def) => {
  const { project_id } = props;

  const { data: project, isFetching } = useGetProjectDetailsQuery({
    project_id,
  });

  const wireTableQuery = useMemo(
    () => ({
      project_id,
      params: {
        gec_strategy: project?.wires_table?.gec_strategy,
        neutral_strategy: project?.wires_table?.neutral_strategy,
        temperature_rating: project?.wires_table?.temperature_rating,
      },
    }),
    [project, project_id]
  );

  const { data: wireTable, isFetching: wireTableLoading } =
    useGetProjectWiresTableQuery(wireTableQuery, {
      skip: !project,
    });

  const [strings, stringsLoading] = useGetProjectStrings({ project_id });

  const loading = isFetching || wireTableLoading || stringsLoading;

  // Get project wires (for dropdown)
  const wires = useMemo(() => {
    return getArray(wireTable?.segments).reduce((res, segment, i) => {
      const marks = new Array(segment.typical).fill(null).map((w, wi) => ({
        id: `wire-${res.length + wi}`,
        text: `${i + 1}`,
      }));
      return [...res, ...marks];
    }, []);
  }, [wireTable]);

  return useMemo(
    () => [{ strings, wires }, loading],
    [loading, strings, wires]
  );
};

export default useGetProjectStringsAndWires;
