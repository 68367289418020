import {
  createContext,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLoadScript, useToggle } from 'hooks';
import { google_api_key, dev } from 'constants';

export const GoogleApiContext = createContext();
GoogleApiContext.displayName = 'GoogleApiContext';

const url = `https://maps.googleapis.com/maps/api/js?key=${google_api_key}&libraries=places&language=en&callback=onGoogleMapsLoaded`;

const GoogleApi = (props) => {
  const { children } = props;

  const [loading, toggleLoading] = useToggle(true);
  const [autocomplete, setAutocomplete] = useState(null);
  const [places, setPlaces] = useState(null);

  const handleLoading = useCallback(() => {
    if (window.google) {
      const newAutocomplete =
        new window.google.maps.places.AutocompleteService();

      const newPlaces = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
      setAutocomplete(newAutocomplete);
      setPlaces(newPlaces);
    }
  }, []);

  if (!window.onGoogleMapsLoaded) {
    window.onGoogleMapsLoaded = (...args) => {
      if (dev) {
        console.log('GoogleMaps loaded!', ...args);
      }
      delete window.onGoogleMapsLoaded;
    };
  }

  useLoadScript({
    src: url,
    onLoad: handleLoading,
  });

  useEffect(() => {
    if (autocomplete) {
      toggleLoading.off();
    }
  }, [autocomplete, toggleLoading]);

  const data = useMemo(
    () => ({
      loading,
      autocomplete,
      places,
    }),
    [loading, autocomplete, places]
  );

  return (
    <GoogleApiContext.Provider value={data}>
      {children}
    </GoogleApiContext.Provider>
  );
};
GoogleApi.Context = GoogleApiContext;

if (dev) {
  GoogleApi.Demo = lazy(() => import('./GoogleApi.demo'));
}

export default GoogleApi;
