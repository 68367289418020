import { lazy, useMemo } from 'react';
import { Box, Typography, IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/material';

import { withProps } from 'hooks';
import { dateToString } from 'utils';
import { dev } from 'constants';
import { HTMLBox, Icon, ProgressBox, Ref } from 'components';

const IconButton = withProps(MuiIconButton, {
  sx: {
    color: 'primary.main',
  },
});
const Caption = withProps(Typography, {
  variant: 'caption',
  fontWeight: 500,
  color: 'text.label',
  component: 'span',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});
const Content = styled(HTMLBox)(() => ({
  '& img': {
    maxWidth: '50%',
  },
}));

const CompanyNote = (props) => {
  const {
    note,
    loading,
    onEditClick,
    onVisibilityClick,
    onDeleteClick,
    editable = true,
  } = props;

  const visible = !!note?.visibility;
  const opacity = visible ? 1 : 0.5;

  const updated = useMemo(() => {
    if (!note.date_updated || !note.updated_by) {
      return null;
    }
    const date = dateToString(note.date_updated);
    return `Updated ${date} by ${note.updated_by}`;
  }, [note]);

  return (
    <ProgressBox
      pt={1}
      pb={2}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.border.light}`,
      }}
      progress={loading}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Caption sx={{ opacity }}>{updated}</Caption>

        {editable && (
          <Box whiteSpace="nowrap">
            <IconButton onClick={onEditClick}>
              <Icon.Pencil />
            </IconButton>

            <IconButton onClick={onVisibilityClick}>
              {visible && <Icon.VisibilityOn />}

              {!visible && <Icon.VisibilityOff />}
            </IconButton>

            <IconButton onClick={onDeleteClick}>
              <Icon.Trash />
            </IconButton>
          </Box>
        )}
      </Box>

      <Content textTransform="uppercase" sx={{ opacity }}>
        {note.text}
      </Content>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ opacity }}
      >
        <Caption>
          <Box component="span" mr={1}>
            Reference:
          </Box>

          <Ref noline color="secondary.main" fontWeight={700}>
            {note.reference || '-'}
          </Ref>
        </Caption>

        <Caption>
          <Box component="span" mr={1}>
            Requested by:
          </Box>

          <Ref noline color="secondary.main" fontWeight={700}>
            {note.requested_by || '-'}
          </Ref>
        </Caption>
      </Box>
    </ProgressBox>
  );
};

if (dev) {
  CompanyNote.Demo = lazy(() => import('./CompanyNote.demo'));
}

export default CompanyNote;
