import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography, Box } from '@mui/material';

import {
  INTERCONNECTION_STRATEGY_LIST,
  INTERCONNECTION_STRATEGY,
  POI_LINE_SIDE_LIST,
  POI_LOAD_SIDE_LIST,
} from 'constants';

import { Delimiter, RadioButtons } from 'components';

import PoiField from './PoiField';

const InterconnectionFields = () => {
  const { resetField } = useFormContext();

  const handleStrategyChange = useCallback(
    (e, v) => {
      const opts =
        v.value === INTERCONNECTION_STRATEGY.LOAD_SIDE
          ? POI_LOAD_SIDE_LIST
          : POI_LINE_SIDE_LIST;

      resetField('poi', {
        defaultValue: opts[0].value,
      });
    },
    [resetField]
  );

  return (
    <Box mt={2}>
      <Typography variant="subtitle2">Interconnection</Typography>

      <Box mt={2}>
        <RadioButtons.Control
          spacing={2}
          size="medium"
          variant="flat"
          onChange={handleStrategyChange}
          name="interconnection_strategy"
          label="Interconnection Strategy"
          options={INTERCONNECTION_STRATEGY_LIST}
        />
      </Box>

      <Box mt={2}>
        <PoiField name="poi" label="Point of Interconnection" />
      </Box>

      <Delimiter mt={2} />
    </Box>
  );
};

export default InterconnectionFields;
