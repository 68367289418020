import { forwardRef, lazy } from 'react';
import {
  LinearProgress,
  linearProgressClasses as classes,
  styled,
} from '@mui/material';

import { dev } from 'constants';

const sizes = {
  small: 4,
  medium: 6,
  large: 8,
};

const Root = styled(LinearProgress, {
  label: 'ProgressLine',
  shouldForwardProp: (prop) => !['size'].includes(prop),
})(({ theme, size }) => ({
  [`&.${classes.root}`]: {
    height: sizes[size] || size || sizes.small,
    borderRadius: sizes[size] || size || sizes.small,
    backgroundColor: theme.palette.border.light,
  },
}));

const ProgressLine = forwardRef((props, ref) => {
  const {
    size = 'medium',
    color = 'secondary',
    variant = 'determinate',
    ...rest
  } = props;

  return (
    <Root size={size} ref={ref} color={color} variant={variant} {...rest} />
  );
});

if (dev) {
  ProgressLine.Demo = lazy(() => import('./ProgressLine.demo'));
}

export default ProgressLine;
